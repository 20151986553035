import { formatDuration, intervalToDuration } from 'date-fns';

const getDurationFromNow = (toDate: Date) => {
    const duration = intervalToDuration({
        start: new Date(),
        end: toDate,
    });

    return formatDuration(duration);
};

export default getDurationFromNow;
