import { FACTOR_TITLE } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { BaseMarkdown } from '@amzn/event-engine-js-utils';
import i18Strings from 'constants/i18n-strings';
import React, { Fragment } from 'react';
import getDisplayValue from 'utils/get-display-value';

import EventImpactStatus from '../EventImpactStatus';
import { EventReadinessTableItem, ImpactFactorI18nStrings } from './types';

export enum ColumnID {
    TITLE = 'title',
    IMPACT = 'impact',
    MESSAGE = 'message',
    GENERAL_MESSAGE = 'general_message',
}

export const columnFieldMap: Record<ColumnID, keyof EventReadinessTableItem> = {
    [ColumnID.TITLE]: 'title',
    [ColumnID.IMPACT]: 'impact',
    [ColumnID.MESSAGE]: 'message',
    [ColumnID.GENERAL_MESSAGE]: 'generalMessage',
};

interface ColumnDefinition
    extends TableProps.ColumnDefinition<EventReadinessTableItem>,
        Partial<CollectionPreferencesProps.VisibleContentOption> {}

export const IMPACT_FACTOR_I18N_STRINGS: ImpactFactorI18nStrings = {
    [FACTOR_TITLE.ACCOUNT_SOURCE_DEFINED]: {
        title: i18Strings.eventReadinessTable.factors.accountSource.title,
        message: i18Strings.eventReadinessTable.factors.accountSource.message,
    },
    [FACTOR_TITLE.EVENT_APPROVAL]: {
        title: i18Strings.eventReadinessTable.factors.eventApproval.title,
        message: i18Strings.eventReadinessTable.factors.eventApproval.message,
    },
    [FACTOR_TITLE.EVENT_PERMISSIONS]: {
        title: i18Strings.eventReadinessTable.factors.eventPermissions.title,
        message:
            i18Strings.eventReadinessTable.factors.eventPermissions.message,
    },
    [FACTOR_TITLE.PUBLISHING_TYPE]: {
        title: i18Strings.eventReadinessTable.factors.publishingType.title,
        message: i18Strings.eventReadinessTable.factors.publishingType.message,
    },
    [FACTOR_TITLE.CONTENT_STATE]: {
        title: i18Strings.eventReadinessTable.factors.contentState.title,
        message: i18Strings.eventReadinessTable.factors.contentState.message,
    },
};

const ImpactStatusValue = ({ impact }: EventReadinessTableItem) => (
    <EventImpactStatus value={impact} />
);
const getMessageValue = (message?: string) =>
    message ? (
        <TextContent>
            <BaseMarkdown components={{ p: Fragment }} linkTarget="_blank">
                {message}
            </BaseMarkdown>
        </TextContent>
    ) : (
        getDisplayValue()
    );

export const columnDefinitions: ColumnDefinition[] = [
    {
        id: ColumnID.TITLE,
        header: i18Strings.eventReadinessTable.columns.title,
        cell: ({ title }) => IMPACT_FACTOR_I18N_STRINGS[title].title,
    },
    {
        id: ColumnID.IMPACT,
        header: i18Strings.eventReadinessTable.columns.impact,
        cell: ImpactStatusValue,
    },
    {
        id: ColumnID.MESSAGE,
        header: i18Strings.eventReadinessTable.columns.message,
        cell: ({ message }) => getMessageValue(message),
    },
    {
        id: ColumnID.GENERAL_MESSAGE,
        header: i18Strings.eventReadinessTable.columns.message,
        cell: ({ title }) =>
            getMessageValue(IMPACT_FACTOR_I18N_STRINGS[title].message),
    },
];
