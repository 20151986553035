import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { useRequest } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { AWSError } from 'aws-sdk/lib/error';
import React, { createContext, useContext } from 'react';
import EEContentCatalog from 'services/ee-content-catalog';
import EEEvents from 'services/ee-events';

export type UserFeatures = EventEngineContentCatalogService.UserFeatures &
    EventEngineEventsService.UserFeatures;

interface UserData {
    userId: string;
    features: EventEngineContentCatalogService.UserFeatures &
        EventEngineEventsService.UserFeatures;
}

export interface UserContextValue {
    data?: UserData;
    isLoading: boolean;
    isError?: AWSError;
}

export const UserContext = createContext<UserContextValue>(
    {} as UserContextValue
);

export const useUserContext = () => useContext(UserContext);

/**
 * Exposes UserData to entire application.
 * This context must be placed under EEContentCatalogClientProvider.
 * The getUser service only needs to be called once for the lifetime of
 * the application. This context will allow other components (e.g. FeatureFlag)
 * to consume UserData without creating another network request.
 * @param {React.PropsWithChildren<React.ReactNode>} props
 * @returns {React.Context<UserContextValue>.Provider}
 */
const UserProvider = ({
    children,
}: React.PropsWithChildren<React.ReactNode>) => {
    const contentCatalogUserData = useRequest<
        typeof EEContentCatalog.getUser,
        AWSError
    >(EEContentCatalog.getUser, {
        context: EEContentCatalog,
        apiRequestName: 'UserProvider EEContentCatalog.getUser',
    });
    const eventsUserData = useRequest<typeof EEEvents.getUser, AWSError>(
        EEEvents.getUser,
        {
            context: EEEvents,
            apiRequestName: 'UserProvider EEEvents.getUser',
        }
    );
    const data = contentCatalogUserData.data && eventsUserData.data?.user;
    const isLoading =
        contentCatalogUserData.isLoading || contentCatalogUserData.isLoading;
    const isError =
        contentCatalogUserData.isError || contentCatalogUserData.isError;

    return (
        <UserContext.Provider
            value={{
                data: data
                    ? {
                          userId: data.userId,
                          features: {
                              ...contentCatalogUserData.data?.features,
                              ...eventsUserData.data?.user?.features,
                          },
                      }
                    : undefined,
                isLoading,
                isError,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
