import { UseTableOptions } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { AWSError } from 'aws-sdk/lib/error';
import { ReactNode } from 'react';

import { ColumnId } from './config';

export enum TableAction {
    CREATE_WORKSHOP = 'create_workshop',
    EDIT_WORKSHOP = 'edit_workshop',
    CREATE_EVENT = 'create_event',
    VIEW_WORKSHOP_DETAILS = 'view_workshop_details',
}

export enum CollectionFilter {
    WORKSHOP_STATE = 'workshop_state_filter',
    EVENT_READINESS = 'event_readiness_filter',
}

export enum DetailLinkBehavior {
    /**
     * Links will open content details in a modal
     */
    MODAL = 'modal',
    /**
     * Links will navigate to a details page
     */
    NAVIGATION = 'navigation',
}

export interface WorkshopCatalogTableProps {
    id: string;
    columnDefinitions: ReadonlyArray<ColumnId>;
    data?: EventEngineContentCatalogService.ContentData[];
    actions?: TableAction[];
    collectionSelectFilters?: UseTableOptions<
        WorkshopCatalogTableItem
    >['collectionSelectFilters'];
    basePath?: string; // Used as base for any links (e.g. details page)
    title?: string;
    description?: string;
    toolsContent?: ReactNode;
    refetch?: () => void;
    loading?: boolean;
    error?: AWSError;
    detailLinkBehavior?: DetailLinkBehavior;
    onSelectionChange?: (
        contents: EventEngineContentCatalogService.ContentData[]
    ) => void;
    selectedItems?: Pick<
        EventEngineContentCatalogService.ContentData,
        'contentId'
    >[];
    visibleContent?: ReadonlyArray<ColumnId>;
}

export enum AttributeName {
    TOPICS = 'Topics',
    AWS_SERVICES = 'AWS services',
    COMPLEXITY_LEVEL = 'Complexity level',
    RECOMMENDED_DURATION = 'Recommended duration',
    INDUSTRY_VERTICALS = 'Industry vertical',
    ENGAGEMENT_MODELS = 'Engagement model',
}

export enum AttributeValueKey {
    STRING_LIST_VALUE = 'stringListValue',
    STRING_VALUE = 'stringValue',
    BOOLEAN_VALUE = 'booleanValue',
    INTEGER_VALUE = 'integerValue',
}

export interface WorkshopCatalogTableItem
    extends EventEngineContentCatalogService.ContentData {
    attributeTopics: string[];
    attributeAWSServices: string[];
    attributeComplexityLevel: string;
    attributeRecommendedDuration: string;
    attributeIndustryVerticals: string[];
    attributeEngagementModels: string[];
    publishedType?: string;
    isAccountSourceDefined: boolean;
    isAccountSourceDefinedDisplay: string;
    eventReadinessDisplayState: string;
}
