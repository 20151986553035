/**
 * Compute minimum account requirement based on participant forecast, team size, and central account requirement
 *
 * @param {number} participantForecast
 * @param {number} [teamSize=1]
 * @param {boolean|number} [centralAccounts=]
 * @returns {number}
 */
const getMinAccountRequirement = (
    participantForecast: number,
    teamSize = 1,
    centralAccounts?: boolean | number
) => {
    const min = Math.ceil(participantForecast / teamSize);

    if (centralAccounts === undefined || centralAccounts === false) {
        return min;
    }

    // if a boolean is specified, assume 1 central account.
    // in the future, we may support multiple central accounts
    return min + (centralAccounts === true ? 1 : centralAccounts);
};

export default getMinAccountRequirement;
