import { SEND_QUOTA_OPERATION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import AWSLogo from 'assets/images/aws-logo.png';
import EventEngineClassicLogo from 'assets/images/ee-classic-logo.png';
import React from 'react';
import cssClassNames from 'utils/css-class-names';

import styles from './TransferGraphic.module.scss';

export interface TransferGraphicProps {
    operation?: EventEngineEventsService.SendQuotaOperation;
}

const TransferGraphic = ({
    operation = SEND_QUOTA_OPERATION.GIVE,
}: TransferGraphicProps) => (
    <div className={cssClassNames(styles.graphic, styles[operation])}>
        <img
            className={cssClassNames(styles.ee, styles.logo)}
            src={EventEngineClassicLogo}
            draggable={false}
            alt="Event Engine Classic logo"
        />
        <div className={styles.arrows}>
            {Array.from({ length: 4 }, (_, i) => (
                <Icon key={i} name="angle-right" variant="link" />
            ))}
        </div>
        <img
            className={cssClassNames(
                styles.ws,
                styles.logo,
                styles.morePadding
            )}
            src={AWSLogo}
            draggable={false}
            alt="Workshop Studio logo"
        />
    </div>
);

export default TransferGraphic;
