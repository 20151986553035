import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Form from '@amzn/awsui-components-react/polaris/form';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    CheckboxField,
    FormikField,
    useLazyRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import EEContentCatalog from 'services/ee-content-catalog';

import { i18nStrings } from '../../constants';
import schema, { FieldName, SchemaType } from './schema';

export interface GuardrailsFormProps {
    version: number;
    onSuccess: () => void;
}

const GuardrailsForm: FC<GuardrailsFormProps> = ({
    version,
    onSuccess,
    children,
}) => {
    const { showNotification } = useContext(NotificationsContext);
    const [
        createGuardrailsStatus,
        {
            data: createGuardrailStatusResponse,
            isError: createGuardrailStatusError,
            isLoading: createGuardrailStatusLoading,
        },
    ] = useLazyRequest<
        typeof EEContentCatalog.createGuardrailsStatus,
        AWSError
    >(EEContentCatalog.createGuardrailsStatus, {});
    const [validateOnChange, setValidateOnChange] = useState(false);
    const formik = useFormik({
        validationSchema: schema,
        initialValues: {},
        validateOnChange,
        validateOnBlur: false,
        onSubmit: () => {
            createGuardrailsStatus(version);
        },
    });

    useEffect(() => {
        createGuardrailStatusResponse && onSuccess();
    }, [createGuardrailStatusResponse]);

    useEffect(() => {
        showNotification(
            i18nStrings.guardrails.notificationAcknowledgementRequired
        );
    }, []);

    return (
        <FormikProvider value={formik}>
            <Form
                errorText={createGuardrailStatusError?.message}
                actions={
                    <Button
                        disabled={!formik.isValid}
                        loading={createGuardrailStatusLoading}
                        onClick={() => {
                            setValidateOnChange(true);
                            formik.handleSubmit();
                        }}
                        variant="primary">
                        {i18nStrings.guardrails.actionContinue}
                    </Button>
                }>
                <Container
                    header={
                        <Header variant="h2">
                            {i18nStrings.guardrails.title}
                        </Header>
                    }>
                    <SpaceBetween direction="vertical" size="l">
                        {children}
                        <FormikField<SchemaType>
                            name={FieldName.ACKNOWLEDGE}
                            formFieldProps={{
                                children: (
                                    <CheckboxField<SchemaType>
                                        name={FieldName.ACKNOWLEDGE}
                                        checkboxProps={{
                                            disabled: createGuardrailStatusLoading,
                                            children:
                                                i18nStrings.guardrails
                                                    .acknowledgeCheckbox,
                                        }}
                                    />
                                ),
                            }}
                        />
                    </SpaceBetween>
                </Container>
            </Form>
        </FormikProvider>
    );
};

export default GuardrailsForm;
