/**
 * Copy of all of the CloudWatch Metrics from our app
 */
export enum CloudWatchMetric {
    // Metric that gets sent when the React app has been initialized with an authorized user
    AUTHENTICATED_APP_INITIALIZED = 'AUTHENTICATED_APP_INITIALIZED',
    // Metric that is sent when a new route is fetched from Embedded content
    EMBEDDED_CONTENT_ROUTE_NAVIGATION = 'EMBEDDED_CONTENT_ROUTE_NAVIGATION',
    // Metric that gets sent when the Event Engine 2.0 documentation failed to load
    EVENT_ENGINE_DOCUMENTATION_PAGE_FAILED = 'EVENT_ENGINE_DOCUMENTATION_PAGE_FAILED',
    // Metric that gets sent when a user submits support request
    SUPPORT_REQUEST_WORKSHOP_STUDIO = 'SUPPORT_REQUEST_WORKSHOP_STUDIO',
}
