import Popover from '@amzn/awsui-components-react/polaris/popover';
import { formatString } from '@amzn/event-engine-js-utils';
import EventReadinessTable from 'components/EventReadinessTable';
import { ColumnID } from 'components/EventReadinessTable/config';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';

import { EventImpactingFactorsProps } from './types';

const visibleColumns = [ColumnID.TITLE, ColumnID.GENERAL_MESSAGE];

const EventImpactingFactors = ({ value }: EventImpactingFactorsProps) => {
    const items = value.factors ? Object.values(value.factors) : [];
    const label = formatString(i18nStrings.eventImpactingFactors, items.length);

    return items.length ? (
        <Popover
            content={
                <EventReadinessTable
                    items={items}
                    visibleColumns={visibleColumns}
                    variant="compact"
                />
            }
            dismissButton={false}
            triggerType="text"
            size="large">
            {label}
        </Popover>
    ) : (
        <>{label}</>
    );
};

export default EventImpactingFactors;
