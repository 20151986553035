import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button, {
    ButtonProps,
} from '@amzn/awsui-components-react/polaris/button';
import Modal, { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';

export interface UnsavedChangesModalProps {
    onConfirm: ButtonProps['onClick'];
    onDismiss: ModalProps['onDismiss'];
    onDecline: ButtonProps['onClick'];
    modalHeaderText?: string;
    onConfirmText?: string;
    onDeclineText?: string;
    modalContent?: JSX.Element;
}

const UnsavedChangesModal = ({
    onConfirm,
    onDecline,
    onDismiss,
    modalHeaderText = 'Unsaved changes',
    onConfirmText = 'Leave',
    onDeclineText = 'Stay',
    modalContent = (
        <Alert type="warning">
            Are you sure that you want to cancel? The changes that you made will
            not be saved.
        </Alert>
    ),
}: UnsavedChangesModalProps) => {
    return (
        <Modal
            closeAriaLabel="Close"
            onDismiss={onDismiss}
            visible={true}
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid="unsaved-confirm"
                            variant="link"
                            onClick={onConfirm}>
                            {onConfirmText}
                        </Button>
                        <Button
                            data-testid="unsaved-cancel"
                            variant="primary"
                            onClick={onDecline}>
                            {onDeclineText}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={modalHeaderText}>
            {modalContent}
        </Modal>
    );
};

export default UnsavedChangesModal;
