import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { EVENT_STATE_STEP_ID_LOOKUP } from '../constants';
import { EventTransition, TimelineStepID } from '../types';

const getActiveStepID = (
    {
        stateRuntime,
        stateInfrastructure,
    }: Pick<
        EventEngineEventsService.EventData,
        'stateRuntime' | 'stateInfrastructure'
    >,
    { provision, start }: EventTransition
) => {
    if (provision.loading) {
        return TimelineStepID.Provisioning;
    } else if (start.loading) {
        return TimelineStepID.Event;
    }

    return (
        EVENT_STATE_STEP_ID_LOOKUP[stateRuntime] ||
        EVENT_STATE_STEP_ID_LOOKUP[stateInfrastructure]
    );
};

export default getActiveStepID;
