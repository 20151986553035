import { EventField } from '@amzn/event-engine-js-utils';
import { ValidationError } from 'yup';

import isFieldEnabled from './is-field-enabled';

const isFieldValid = (
    context: Record<string, any> | undefined = {},
    field: EventField,
    validation: boolean | ValidationError
) => {
    const { editMatrix } = context;

    if (!editMatrix) {
        return validation;
    }

    return isFieldEnabled(editMatrix, field) ? validation : true;
};

export default isFieldValid;
