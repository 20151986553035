/* istanbul ignore file */
import { CredentialRoles } from '@amzn/event-engine-js-utils';
import {
    ApproveReviewRequest,
    BatchCreateContentAssetStaticUrlsRequest,
    BatchCreateContentAssetStaticUrlsResponse,
    BatchDeleteContentAssetStaticUrlsRequest,
    BatchSyncContentAssetStaticUrlsRequest,
    CancelContentDeletionRequest,
    CreateContentRequest,
    GetContentAssetStaticUrlRequest,
    GetContentBuildRequest,
    GetQuotaRequest,
    GetReviewRequest,
    ListContentAssetsRequest,
    ListContentAssetStaticUrlsRequest,
    ListContentBuildsRequest,
    ListContentRelationsRequest,
    ListContentsRequest,
    ListQuotaHistoryRequest,
    PublishContentRequest,
    ScheduleContentDeletionRequest,
    UpdateContentBuildPinnedStatusRequest,
    UpdateContentPermissionsRequest,
    UpdateContentRequest,
    UpdateReviewRequest,
    UserData,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import EventEngineClientFactory from 'contexts/EventEngineClientFactory';
import { USER_ID_SELF } from 'utils/config';

const contentCatalogClient = EventEngineClientFactory.getClientSingleton(
    'EEContentCatalogClient'
);

// In-memory response cache
const cache: { [cacheKey: string]: any } = {};

// @ts-ignore
const getEEContentCatalogClient = async (role: CredentialRoles = 'Tenant') => {
    return contentCatalogClient.getClient(role);
};

export const defaultContentTemplateId = '605abe26-d014-c72e-3df9-deb267e73756';

export default {
    getContentTemplate,
    getContent,
    getContents,
    updateContent,
    createContent,
    getContentCredentials,
    getContentPermissions,
    updateContentPermissions,
    getContentBuilds,
    getContentBuild,
    getReview,
    getReviews,
    updateReview,
    approveReview,
    getUser,
    publishContent,
    listQuotaHistory,
    scheduleContentDeletion,
    cancelContentDeletion,
    getContentRelations,
    getQuota,
    updateContentBuildPinnedStatus,
    getGuardrailsStatus,
    createGuardrailsStatus,
    getContentAssetStaticUrls,
    getContentAssetStaticUrl,
    batchSyncContentAssetStaticUrls,
    batchDeleteContentAssetStaticUrls,
    getContentAssets,
    batchCreateContentAssetStaticUrls,
};

///////////////// Implementation

async function getContentTemplate(
    contentTemplateId = defaultContentTemplateId
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getContentTemplate({ contentTemplateId })
        .promise();
}

async function getContent(contentId: string) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.getContent({ contentId }).promise();
}

async function getContents(params: ListContentsRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.listContents(params).promise();
}

async function updateContent(params: UpdateContentRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.updateContent(params).promise();
}

async function createContent(
    params: Omit<CreateContentRequest, 'contentTemplateId'>
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .createContent({
            contentTemplateId: defaultContentTemplateId,
            ...params,
        })
        .promise();
}

async function getContentCredentials(contentId: string) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getContentCredentials({ contentId })
        .promise();
}

async function getContentPermissions(contentId: string) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getContentPermissions({ contentId })
        .promise();
}

async function updateContentPermissions(
    params: UpdateContentPermissionsRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .updateContentPermissions(params)
        .promise();
}

async function getContentBuilds(params: ListContentBuildsRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.listContentBuilds(params).promise();
}

async function getContentBuild(params: GetContentBuildRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.getContentBuild(params).promise();
}

async function getReview(params: GetReviewRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getReview(params)
        .promise()
        .then(({ review }) => review);
}

async function getReviews(contentId: string) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .listReviews({
            contentId,
        })
        .promise();
}

async function updateReview(params: UpdateReviewRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .updateReview(params)
        .promise()
        .then(({ review }) => review);
}

async function approveReview(params: ApproveReviewRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.approveReview(params).promise();
}

async function getUser(userId = USER_ID_SELF) {
    const cachedResponse = getCachedResponse<UserData>('getUser', userId);
    if (cachedResponse) {
        return cachedResponse;
    }
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getUser({
            userId,
        })
        .promise()
        .then(({ user }) => {
            setResponseCache('getUser', userId, user);
            return user;
        });
}

async function publishContent(params: PublishContentRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.publishContent(params).promise();
}

async function listQuotaHistory(params: ListQuotaHistoryRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.listQuotaHistory(params).promise();
}

async function getQuota(params: GetQuotaRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.getQuota(params).promise();
}

async function scheduleContentDeletion(params: ScheduleContentDeletionRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .scheduleContentDeletion(params)
        .promise();
}

async function cancelContentDeletion(params: CancelContentDeletionRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.cancelContentDeletion(params).promise();
}

async function getContentRelations(params: ListContentRelationsRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.listContentRelations(params).promise();
}

async function updateContentBuildPinnedStatus(
    params: UpdateContentBuildPinnedStatusRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .updateContentBuildPinnedStatus(params)
        .promise();
}

async function getGuardrailsStatus() {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.getGuardrailsStatus().promise();
}

async function createGuardrailsStatus(version: number) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .createGuardrailsStatus({
            version,
        })
        .promise();
}

async function getContentAssetStaticUrls(
    params: ListContentAssetStaticUrlsRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .listContentAssetStaticUrls(params)
        .promise();
}

async function getContentAssetStaticUrl(
    params: GetContentAssetStaticUrlRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .getContentAssetStaticUrl(params)
        .promise();
}

async function batchSyncContentAssetStaticUrls(
    params: BatchSyncContentAssetStaticUrlsRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .batchSyncContentAssetStaticUrls(params)
        .promise();
}

async function batchDeleteContentAssetStaticUrls(
    params: BatchDeleteContentAssetStaticUrlsRequest
) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .batchDeleteContentAssetStaticUrls(params)
        .promise();
}

///////// Utility for in-memory caching

function getCachedResponse<T = any>(cacheKey: string, params: any) {
    return cache[cacheKey]?.[getRequestKey(params)] as T;
}

function setResponseCache(cacheKey: string, params: any, response: any) {
    cache[cacheKey] = {
        ...cache[cacheKey],
        [getRequestKey(params)]: response,
    };
}

function getRequestKey(params: any) {
    return btoa(JSON.stringify(params));
}

async function getContentAssets(params: ListContentAssetsRequest) {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient.listContentAssets(params).promise();
}

async function batchCreateContentAssetStaticUrls(
    params: BatchCreateContentAssetStaticUrlsRequest
): Promise<BatchCreateContentAssetStaticUrlsResponse | undefined> {
    const eeContentCatalogClient = await getEEContentCatalogClient();
    return await eeContentCatalogClient
        .batchCreateContentAssetStaticUrls(params)
        .promise();
}
