import {
    CONTENT_PUBLISH_STATE,
    CONTENT_STATE_TYPES,
} from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

export const LIST_CONTENT_DATA: ContentData[] = [
    {
        eventReadiness: {
            summary: 'ready',
            factors: {
                accountSourceDefined: {
                    title: 'account_source_defined',
                    impact: 'ready',
                },
                publishingType: {
                    title: 'publishing_type',
                    impact: 'ready',
                },
                contentState: {
                    title: 'content_state',
                    impact: 'ready',
                },
            },
        },
        contentSpecSummary: {
            accessibleRegions: {
                optional: ['sa-east-1', 'eu-north-1'],
                recommended: ['us-east-2'],
                required: ['us-west-2'],
            },
            deployableRegions: {
                required: ['ap-southeast-1'],
                optional: [],
                recommended: [],
            },
            minAccessibleRegions: 2,
            maxAccessibleRegions: 3,
            accountSources: [
                EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
                EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED,
            ],
        },
        attributes: [
            {
                name: 'Topics',
                value: {
                    stringListValue: ['Analytics', 'Compute'],
                },
            },
            {
                name: 'AWS services',
                value: {
                    stringListValue: ['Amazon Detective'],
                },
            },
            {
                name: 'Complexity level',
                value: {
                    stringValue: '200',
                },
            },
            {
                name: 'Recommended duration',
                value: {
                    stringValue: '7',
                },
            },
            {
                name: 'Industry vertical',
                value: {
                    stringValue: 'Telecommunications',
                },
            },
            {
                name: 'Engagement model',
                value: {
                    stringListValue: ['Gameday'],
                },
            },
        ],
        buildType: 'embedded',
        contentId: 'd8eb7eb7-9b4c-421a-81e1-96740bce4609',
        contentTemplateId: '605abe26-d014-c72e-3df9-deb267e73756',
        createdAt: new Date('2021-04-26T20:49:40.000Z'),
        lastPublishedAt: new Date('2021-04-26T20:49:40.000Z'),
        description: 'This is a test',
        lastUpdatedAt: new Date('2021-04-26T20:58:17.000Z'),
        primaryOwner: 'jcortezj@amazon.com',
        publishedBuildId: '71106766-094a-441a-b0b0-0f1cac87eaf8',
        publishedBuildUrl:
            'https://jcortezj-sandbox.content.eventengine.run/workshops/71106766-094a-441a-b0b0-0f1cac87eaf8',
        requiredReviews: ['8619ee78-05d5-4464-ad67-b5afbdb1c8f7'],
        state: CONTENT_STATE_TYPES.CREATE_COMPLETE,
        title: 'EE-2102-Test',
        publishState: CONTENT_PUBLISH_STATE.PUBLISHED,
        repositoryName: 'ee-2102-test',
    },
];
