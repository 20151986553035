import { IMPACT_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useModal } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import EventReadinessTable from 'components/EventReadinessTable';
import { ColumnID } from 'components/EventReadinessTable/config';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';

interface UseEventReadinessModalOptions {
    onConfirm: (content: EventEngineContentCatalogService.ContentData) => void;
    content?: EventEngineContentCatalogService.ContentData;
}

const visibleColumns = [ColumnID.TITLE, ColumnID.IMPACT, ColumnID.MESSAGE];

const useEventReadinessModal = ({
    onConfirm,
    content,
}: UseEventReadinessModalOptions) => {
    const eventReadiness = content?.eventReadiness;
    const blocked = eventReadiness?.summary === IMPACT_TYPES.BLOCKED;
    const { showModal, modalComponent, hideModal } = useModal({
        modalHeader: blocked
            ? i18nStrings.createEventAction.eventReadinessBlockedModal.header
            : i18nStrings.createEventAction.eventReadinessRestrictedModal
                  .header,
        actions: {
            tertiary: {
                text: i18nStrings.close,
                onClick: () => hideModal(),
            },
            ...(blocked
                ? {}
                : {
                      primary: {
                          text: i18nStrings.createEventAction.createEvent,
                          onClick: () => content && onConfirm(content),
                      },
                  }),
        },
        content: (
            <SpaceBetween size="l">
                <div>
                    {blocked
                        ? i18nStrings.createEventAction
                              .eventReadinessBlockedModal.content
                        : i18nStrings.createEventAction
                              .eventReadinessRestrictedModal.content}
                </div>
                {eventReadiness && (
                    <EventReadinessTable
                        items={Object.values(eventReadiness.factors).filter(
                            ({ impact }) => impact !== IMPACT_TYPES.READY
                        )}
                        visibleColumns={visibleColumns}
                        variant="compact"
                    />
                )}
            </SpaceBetween>
        ),
    });

    return {
        showModal,
        modalComponent,
    };
};

export default useEventReadinessModal;
