import {
    EventData,
    TeamData,
    TeamList,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { AWSError } from 'aws-sdk';
import { ISettings } from 'services/app-settings';

export interface EventTeamsTableProps {
    error?: AWSError;
    event?: EventData;
    loading?: boolean;
    refetch?: (preserveCache?: boolean) => Promise<void>;
    teams?: TeamList;
}

export interface TeamDataTableItem extends TeamData {
    name: string;
    displayState: string;
    deploymentDuration?: string;
    participantCount?: number;
    joinEventUrl?: string;
}

export enum TestID {
    ButtonActions = 'BUTTON_ACTIONS',
    ButtonRefresh = 'BUTTON_REFRESH',
    AccessAWSAccount = 'ACCESS_AWS_ACCOUNT',
}

export enum ActionID {
    TerminateTeam = 'TERMINATE_TEAM',
}

export interface ProcessDataOptions {
    settings: ISettings;
}
