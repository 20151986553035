/**--GENERATED FILE DO NOT MODIFY--**/
export enum CONTENT_BUILD_LOG_CODE {
    ERROR_READING_CONTENTSPEC = 'ERROR_READING_CONTENTSPEC',
    ERROR_PARSING_CONTENTSPEC = 'ERROR_PARSING_CONTENTSPEC',
    UNSUPPORTED_CONTENTSPEC_VERSION = 'UNSUPPORTED_CONTENTSPEC_VERSION',
    MISSING_CONTENT_SPEC_VERSION = 'MISSING_CONTENT_SPEC_VERSION',
    MISSING_CONTENT_SPEC_DEFAULT_LOCALE_CODE = 'MISSING_CONTENT_SPEC_DEFAULT_LOCALE_CODE',
    MISSING_CONTENT_DIRECTORY = 'MISSING_CONTENT_DIRECTORY',
    UNSUPPORTED_LOCALE_CODE = 'UNSUPPORTED_LOCALE_CODE',
    MISSING_INDEX_MARKDOWN = 'MISSING_INDEX_MARKDOWN',
    INVALID_DIRECTORY_NAME = 'INVALID_DIRECTORY_NAME',
    INVALID_MARKDOWN_FILE_NAME = 'INVALID_MARKDOWN_FILE_NAME',
    INVALID_MARKDOWN_LOCALE_CODE = 'INVALID_MARKDOWN_LOCALE_CODE',
    MISSING_MARKDOWN_FRONTMATTER = 'MISSING_MARKDOWN_FRONTMATTER',
    ERROR_PARSING_MARKDOWN_FRONTMATTER = 'ERROR_PARSING_MARKDOWN_FRONTMATTER',
    MISSING_MARKDOWN_FRONTMATTER_TITLE = 'MISSING_MARKDOWN_FRONTMATTER_TITLE',
    ERROR_CONTENT_SPEC_EXCEEDS_MAX_BYTE_SIZE = 'ERROR_CONTENT_SPEC_EXCEEDS_MAX_BYTE_SIZE',
    EXCEEDED_NESTED_NAVIGATION_DEPTH = 'EXCEEDED_NESTED_NAVIGATION_DEPTH',
    UNECESSARY_ACCOUNT_CONFIG_PROVIDED = 'UNECESSARY_ACCOUNT_CONFIG_PROVIDED',
    FAILED_AWS_ACCOUNT_CONFIG_VALIDATION = 'FAILED_AWS_ACCOUNT_CONFIG_VALIDATION',
    ACCESS_ANALYZER_FINDING_ERROR = 'ACCESS_ANALYZER_FINDING_ERROR',
    ACCESS_ANALYZER_FINDING_SECURITYWARNING = 'ACCESS_ANALYZER_FINDING_SECURITYWARNING',
    ACCESS_ANALYZER_FINDING_WARNING = 'ACCESS_ANALYZER_FINDING_WARNING',
    ACCESS_ANALYZER_FINDING_SUGGESTION = 'ACCESS_ANALYZER_FINDING_SUGGESTION',
    MANAGED_POLICY_NOT_FOUND = 'MANAGED_POLICY_NOT_FOUND',
    DUPLICATE_TRUSTED_PRINCIPAL = 'DUPLICATE_TRUSTED_PRINCIPAL',
    FAILED_INFRASTRUCTURE_VALIDATION = 'FAILED_INFRASTRUCTURE_VALIDATION',
    INFRASTRUCTURE_VALIDATION_ERROR = 'INFRASTRUCTURE_VALIDATION_ERROR',
    INFRASTRUCTURE_VALIDATION_WARNING = 'INFRASTRUCTURE_VALIDATION_WARNING',
    INFRASTRUCTURE_VALIDATION_INFO = 'INFRASTRUCTURE_VALIDATION_INFO',
    ASSET_VALIDATION_ERROR = 'ASSET_VALIDATION_ERROR',
    ASSET_VALIDATION_WARNING = 'ASSET_VALIDATION_WARNING',
    ASSET_VALIDATION_INFO = 'ASSET_VALIDATION_INFO',
}

export enum CONTENT_PUBLISH_STATE {
    PUBLISHED = 'published',
    UNPUBLISHED = 'unpublished',
    UNPUBLISHING = 'unpublishing',
    PUBLISHING = 'publishing',
    FAILED = 'failed',
}

export enum CONTENT_BUILD_STATE {
    CREATED = 'created',
    RUNNING = 'running',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum CONTENT_BUILD_LOG_SEVERITY {
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
}

export enum CONTENT_BUILD_PRUNING_STATE {
    PRUNED = 'pruned',
    NOT_PRUNED = 'not_pruned',
    FAILED = 'failed',
}

export enum CONTENT_STATE_TYPES {
    CREATE_IN_PROGRESS = 'create_in_progress',
    CREATE_COMPLETE = 'create_complete',
    CREATE_FAILED = 'create_failed',
    SCHEDULED_FOR_DELETE = 'scheduled_for_delete',
    REPLICA_READONLY = 'replica_readonly',
}

export enum QUOTA_ID {
    CONTENT = 'content',
}

export enum SYNC_STATUS {
    OUT_OF_SYNC = 'out_of_sync',
    SYNCING = 'syncing',
    COMPLETED = 'completed',
    FAILED = 'failed',
    UNSYNCED = 'unsynced',
}

export enum SCAN_STATUS {
    PENDING = 'pending',
    SCANNING = 'scanning',
    COMPLETED = 'completed',
    FAILED = 'failed',
    AWAITING_SCAN = 'awaiting_scan',
}

export enum SCAN_RESULT {
    FAIL = 'fail',
    PASS = 'pass',
}

export enum IMPACT_TYPES {
    BLOCKED = 'blocked',
    READY = 'ready',
    RESTRICTED = 'restricted',
}

export enum FACTOR_TITLE {
    ACCOUNT_SOURCE_DEFINED = 'account_source_defined',
    EVENT_APPROVAL = 'event_approval',
    EVENT_PERMISSIONS = 'event_permissions',
    PUBLISHING_TYPE = 'publishing_type',
    CONTENT_STATE = 'content_state',
}
