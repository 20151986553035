import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface TerminateTeamProps {
    onDismiss: () => void;
    onUpdateSuccess: () => void;
    team: EventEngineEventsService.TeamData;
    event: EventEngineEventsService.EventData;
    isVisible?: boolean;
}

export enum NotificationID {
    UPDATE_RESPONSE = 'TERMINATE_TEAM_UPDATE_RESPONSE',
}
