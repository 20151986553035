import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { ValueWithLabel } from '@amzn/event-engine-js-utils';
import React, { useMemo } from 'react';
import getDisplayValue from 'utils/get-display-value';

import { i18nStrings } from '../../../../../../../../../constants';
import { STATUS_MAP } from './constants';
import getTeamsStats from './utils/get-teams-stats';

export interface EventTeamsOverviewContainerProps {
    defaultExpanded?: boolean;
    teams?: EventEngineEventsService.TeamList;
}

const EventTeamsOverviewContainer = ({
    defaultExpanded,
    teams,
}: EventTeamsOverviewContainerProps) => {
    const stats = useMemo(() => getTeamsStats(teams), [teams]);
    const state = STATUS_MAP[stats.state];

    return (
        <ExpandableSection
            headerText={i18nStrings.events.teams.overviewContainer.title}
            defaultExpanded={defaultExpanded}
            variant="container">
            <ColumnLayout columns={4} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .state
                        }>
                        <StatusIndicator type={state.type}>
                            {state.label}
                        </StatusIndicator>
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .halted
                        }>
                        {getDisplayValue(stats.halted)}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .queued
                        }>
                        {getDisplayValue(stats.queued)}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .failed
                        }>
                        {getDisplayValue(stats.failed)}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .inProgress
                        }>
                        {getDisplayValue(stats.inProgress)}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .terminated
                        }>
                        {getDisplayValue(stats.terminated)}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .success
                        }>
                        {getDisplayValue(stats.success)}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.overviewContainer.values
                                .averageDeploymentDuration
                        }>
                        {getDisplayValue(stats.averageDeploymentDuration)}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </ExpandableSection>
    );
};

export default EventTeamsOverviewContainer;
