import { TEAM_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { TeamList } from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { STATUS_MAP } from 'pages/Events/EventDetails/components/EventTeamState/EventTeamState.constants';
import getFormattedDuration from 'utils/get-formatted-duration';

import { ProcessDataOptions, TeamDataTableItem } from '../types';

const processData = (data: TeamList = [], options?: ProcessDataOptions) => {
    const res: TeamDataTableItem[] = [];

    data.forEach((team) => {
        const processedTeam: Partial<TeamDataTableItem> = {
            ...team,
        };

        processedTeam.displayState =
            STATUS_MAP[team.state as TEAM_STATE]?.label || team.state;
        if (processedTeam.participantIds) {
            processedTeam.participantCount =
                processedTeam.participantIds.length;
        }

        if (options?.settings.eventPortalOrigin) {
            processedTeam.joinEventUrl = `${options.settings.eventPortalOrigin}/join?access-code=${team.teamCode}`;
        }

        if (team.deploymentBeginAt && team.deploymentEndAt) {
            processedTeam.deploymentDuration = getFormattedDuration(
                differenceInMilliseconds(
                    team.deploymentEndAt,
                    team.deploymentBeginAt
                )
            );
        }

        res.push(processedTeam as TeamDataTableItem);
    });

    return res;
};

export default processData;
