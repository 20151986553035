import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { AWSError } from 'aws-sdk/lib/error';

export type StackDeploymentEventsTableItem = EventEngineEventsService.TeamStackFailedEvent;

export interface StackDeploymentEventsTableProps {
    stack?:
        | EventEngineEventsService.TeamStack
        | EventEngineEventsService.CloudFormationDeploymentStack;
    error?: AWSError;
    loading?: boolean;
    additionalActions?: JSX.Element[];
    storageScope?: string;
    onRefresh?: () => void;
}

export enum TestID {
    BUTTON_REFRESH = 'button_refresh',
}
