import { TEAM_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';

import { i18nStrings } from '../../../../../constants';
import { StatusConfig } from './EventTeamState.interface';

export const STATUS_MAP: Record<TEAM_STATE, StatusConfig> = {
    [TEAM_STATE.PROVISION_QUEUED]: {
        type: 'pending',
        label: i18nStrings.events.teams.states.provisionQueued,
    },
    [TEAM_STATE.PROVISION_IN_PROGRESS]: {
        type: 'in-progress',
        label: i18nStrings.events.teams.states.provisionInProgress,
    },
    [TEAM_STATE.PROVISION_SUCCESS]: {
        type: 'success',
        label: i18nStrings.events.teams.states.provisionSuccess,
    },
    [TEAM_STATE.PROVISION_FAILED]: {
        type: 'error',
        label: i18nStrings.events.teams.states.provisionFailed,
    },
    [TEAM_STATE.DEPLOYMENT_QUEUED]: {
        type: 'pending',
        label: i18nStrings.events.teams.states.deploymentQueued,
    },
    [TEAM_STATE.DEPLOYMENT_IN_PROGRESS]: {
        type: 'in-progress',
        label: i18nStrings.events.teams.states.deploymentInProgress,
    },
    [TEAM_STATE.DEPLOYMENT_HALTED]: {
        type: 'stopped',
        label: i18nStrings.events.teams.states.deploymentHalted,
    },
    [TEAM_STATE.DEPLOYMENT_SUCCESS]: {
        type: 'success',
        label: i18nStrings.events.teams.states.deploymentSuccess,
    },
    [TEAM_STATE.DEPLOYMENT_FAILED]: {
        type: 'error',
        label: i18nStrings.events.teams.states.deploymentFailed,
    },
    [TEAM_STATE.TERMINATE_IN_PROGRESS]: {
        type: 'in-progress',
        label: i18nStrings.events.teams.states.terminateInProgress,
    },
    [TEAM_STATE.TERMINATE_FAILED]: {
        type: 'error',
        label: i18nStrings.events.teams.states.terminateFailed,
    },
    [TEAM_STATE.TERMINATE_SUCCESS]: {
        type: 'success',
        label: i18nStrings.events.teams.states.terminateSuccess,
    },
};

export const UNKNOWN_STATE: StatusConfig = {
    type: 'stopped',
};
