import Alert from '@amzn/awsui-components-react/polaris/alert';
import { LoadingComponent, useRequest } from '@amzn/event-engine-js-utils';
import {
    breadcrumbsState,
    contentTypeState,
    navigationOpenState,
    toolsState,
} from 'atoms';
import { AWSError } from 'aws-sdk/lib/error';
import { routes } from 'components/AppRoutes/routes.constants';
import { RouteParams } from 'components/AppRoutes/routes.constants';
import ErrorPageController from 'components/ErrorPageController';
import HelpPanel from 'components/HelpPanel';
import NotificationsProvider from 'contexts/NotificationsProvider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ErrorMessage, MessageTypes } from 'services';
import EEEvents, { defaultEventTemplateId } from 'services/ee-events';

import { i18nStrings } from '../../../constants';
import { generateCustomAttributes } from '../CreateEvent/utils/create-event-attributes';
import EditEvent from './EditEvent';

const EditEventContainer = () => {
    const params = useParams<RouteParams>();
    const setContentType = useSetRecoilState(contentTypeState);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    const setTools = useSetRecoilState(toolsState);
    const setNavigationOpen = useSetRecoilState(navigationOpenState);
    const eventId = params.eventId!;
    const {
        isLoading: getEventLoading,
        isError: getEventError,
        data: getEventData,
    } = useRequest<typeof EEEvents.getEvent, AWSError>(
        EEEvents.getEvent,
        {},
        eventId
    );
    const {
        isLoading: getContentTemplateIsLoading,
        isError: getContentTemplateIsError,
        data: getContentTemplateData,
    } = useRequest<typeof EEEvents.getEventTemplate, AWSError>(
        EEEvents.getEventTemplate,
        {},
        defaultEventTemplateId
    );

    useEffect(() => {
        setNavigationOpen(false);
        setContentType('form');

        setTools({
            open: false,
            hide: false,
            content: (
                <HelpPanel
                    header={
                        i18nStrings.events.create.helpText.eventDetails.header
                    }>
                    {i18nStrings.events.create.helpText.eventDetails.content}
                </HelpPanel>
            ),
        });
    }, []);

    useEffect(() => {
        const breadCrumbs = [
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: 'Event delivery',
                href: routes.events,
            },
        ];

        const eventData = getEventData && {
            text: getEventData.event?.title || eventId,
            href: `${routes.events}/${getEventData.event?.eventId}`,
        };

        if (eventData) {
            breadCrumbs.push(eventData);
            breadCrumbs.push({
                text: 'Edit',
                href: '',
            });
        }

        setBreadcrumbs(breadCrumbs);
    }, [setBreadcrumbs, getEventData]);

    return (
        <NotificationsProvider>
            {getEventData?.event && getContentTemplateData?.eventTemplate && (
                <EditEvent
                    event={getEventData.event}
                    attributes={Object.freeze(
                        generateCustomAttributes(
                            getContentTemplateData.eventTemplate
                        )
                    )}
                />
            )}
            {(getEventLoading || getContentTemplateIsLoading) && (
                <LoadingComponent
                    spinnerWithLabelProps={{
                        children: 'Loading event details...',
                    }}
                />
            )}

            {(getEventError || getContentTemplateIsError) && (
                <Alert type="error">
                    {getEventError ? (
                        ErrorMessage.getMessage(
                            getEventError,
                            MessageTypes.getEvent
                        )
                    ) : (
                        <ErrorPageController type="catastrophic" />
                    )}
                </Alert>
            )}
        </NotificationsProvider>
    );
};

export default EditEventContainer;
