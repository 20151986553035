import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { useModal } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import React, { useCallback, useMemo } from 'react';

export interface PauseEventModalProps {
    event: EventEngineEventsService.EventData;
    isVisible: boolean;
    onModalClosed: (pauseConfirmed: boolean) => void;
}

const PauseEventModal = ({
    event,
    isVisible,
    onModalClosed,
}: PauseEventModalProps) => {
    const content = useMemo(
        () =>
            event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
                ? i18nStrings.events.pauseEventModal.content.workshopStudio
                : i18nStrings.events.pauseEventModal.content.customerProvided,
        [event]
    );
    const onPrimaryActionClick = useCallback(() => {
        onModalClosed(true);
    }, [onModalClosed]);
    const onSecondaryActionClick = useCallback(() => {
        onModalClosed(false);
    }, [onModalClosed]);

    const { modalComponent } = useModal({
        isVisible,
        onDismiss: () => onModalClosed(false),
        modalHeader: i18nStrings.events.pauseEventModal.header,
        actions: {
            primary: {
                text: i18nStrings.events.pauseEventModal.actionPause,
                onClick: onPrimaryActionClick,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content,
    });

    return modalComponent;
};

export default PauseEventModal;
