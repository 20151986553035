import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import React, { createContext } from 'react';

import { i18nStrings, tableConfig } from '../../../../../../../../../constants';
import QuotaEmailDisplay from './components/QuotaEmailDisplay';
import {
    QuotaReservationsTableContextValue,
    QuotaReservationTableItem,
} from './types';

export const QuotaReservationsTableContext = createContext(
    {} as QuotaReservationsTableContextValue
);

enum ColumnID {
    EMAIL = 'email',
    RESERVED = 'reserved',
    CONSUMED = 'consumed',
}

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    visibleContent: [ColumnID.EMAIL, ColumnID.RESERVED, ColumnID.CONSUMED],
};

type ColumnFieldMap = Record<ColumnID, keyof QuotaReservationTableItem>;

export const columnFieldMap: ColumnFieldMap = {
    [ColumnID.EMAIL]: 'email',
    [ColumnID.RESERVED]: 'reserved',
    [ColumnID.CONSUMED]: 'consumed',
};

const getEmailDisplay = (item: QuotaReservationTableItem) => (
    <QuotaEmailDisplay value={item.email} />
);

export const columnDefinitions: TableProps.ColumnDefinition<
    QuotaReservationTableItem
>[] = [
    {
        id: ColumnID.EMAIL,
        header: i18nStrings.events.quotas.table.columns.email,
        cell: (item) => getEmailDisplay(item),
    },
    {
        id: ColumnID.RESERVED,
        header: i18nStrings.events.quotas.table.columns.reserved,
        cell: (item) => item.reserved,
    },
    {
        id: ColumnID.CONSUMED,
        header: i18nStrings.events.quotas.table.columns.consumed,
        cell: (item) => item.consumed,
    },
];
