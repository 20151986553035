import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_STATE_INFRASTRUCTURE,
    EVENT_STATE_RUNTIME,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { formatString } from '@amzn/event-engine-js-utils';
import React from 'react';
import { SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS } from 'utils/config';

import { i18nStrings } from '../../../../../constants';
import {
    EventStates,
    EventStateStepIDLookup,
    TimelineFooterCategories,
    TimelineStepID,
} from './types';

export const EVENT_STATE_STEP_ID_MAPPING: Record<
    TimelineStepID,
    EventStates[]
> = {
    [TimelineStepID.Scheduled]: [EVENT_STATE_RUNTIME.SCHEDULED],
    [TimelineStepID.Provisioning]: [
        EVENT_STATE_INFRASTRUCTURE.PROVISION_IN_PROGRESS,
        EVENT_STATE_INFRASTRUCTURE.PROVISION_FAILED,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_IN_PROGRESS,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_FAILED,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_IN_PROGRESS,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_HALTED,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_FAILED,
        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_SUCCESS,
    ],
    [TimelineStepID.Event]: [
        EVENT_STATE_RUNTIME.START_IN_PROGRESS,
        EVENT_STATE_RUNTIME.START_SUCCESS,
        EVENT_STATE_RUNTIME.START_FAILED,
        EVENT_STATE_RUNTIME.PAUSE_IN_PROGRESS,
        EVENT_STATE_RUNTIME.PAUSE_SUCCESS,
        EVENT_STATE_RUNTIME.PAUSE_FAILED,
    ],
    [TimelineStepID.Termination]: [
        EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS,
        EVENT_STATE_RUNTIME.TERMINATE_FAILED,
    ],
};

export const EVENT_STATE_STEP_ID_LOOKUP: EventStateStepIDLookup = {} as EventStateStepIDLookup;

Object.keys(EVENT_STATE_STEP_ID_MAPPING).forEach((timelineStepId) => {
    EVENT_STATE_STEP_ID_MAPPING[timelineStepId as TimelineStepID].forEach(
        (eventStates) => {
            EVENT_STATE_STEP_ID_LOOKUP[
                eventStates
            ] = timelineStepId as TimelineStepID;
        }
    );
});

export const TimelineFooters: TimelineFooterCategories = {
    [EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO]: {
        [EVENT_STATE_RUNTIME.SCHEDULED]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .scheduled.header,
            content: (
                <SpaceBetween size="l" direction="vertical">
                    <div>
                        {
                            i18nStrings.events.eventDetails.timeline.footer
                                .wsProvided.scheduled.contentP1
                        }
                    </div>
                    <div>
                        {
                            i18nStrings.events.eventDetails.timeline.footer
                                .wsProvided.scheduled.contentP2
                        }
                    </div>
                    <Alert type="warning">
                        {formatString(
                            i18nStrings.events.eventDetails.timeline
                                .provisionStartWarning,
                            SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS
                        )}
                    </Alert>
                </SpaceBetween>
            ),
        },
        [EVENT_STATE_INFRASTRUCTURE.PROVISION_IN_PROGRESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.PROVISION_FAILED]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_IN_PROGRESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_IN_PROGRESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionInProgress.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_FAILED]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_FAILED]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionFailed.content,
        },
        [EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_SUCCESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionSuccess.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .provisionSuccess.content,
        },
        [EVENT_STATE_RUNTIME.START_SUCCESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .eventInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .eventInProgress.content,
        },
        [EVENT_STATE_RUNTIME.PAUSE_SUCCESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .eventPaused.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.wsProvided
                    .eventPaused.content,
        },
    },
    [EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED]: {
        [EVENT_STATE_RUNTIME.SCHEDULED]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .scheduled.header,
            content: (
                <SpaceBetween size="l" direction="vertical">
                    <div>
                        {
                            i18nStrings.events.eventDetails.timeline.footer
                                .customerProvided.scheduled.content
                        }
                    </div>
                    <Alert type="warning">
                        {formatString(
                            i18nStrings.events.eventDetails.timeline
                                .provisionStartWarning,
                            SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS
                        )}
                    </Alert>
                </SpaceBetween>
            ),
        },
        [EVENT_STATE_INFRASTRUCTURE.PROVISION_IN_PROGRESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .provisionInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .provisionInProgress.content,
        },
        [EVENT_STATE_RUNTIME.START_SUCCESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .eventInProgress.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .eventInProgress.content,
        },
        [EVENT_STATE_RUNTIME.PAUSE_SUCCESS]: {
            header:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .eventPaused.header,
            content:
                i18nStrings.events.eventDetails.timeline.footer.customerProvided
                    .eventPaused.content,
        },
    },
};
