import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { AWSError } from 'aws-sdk/lib/error';

export interface EventParticipantsTableProps {
    onPreferenceChange: (
        preferences: CollectionPreferencesProps.Preferences
    ) => void;
    preferences: CollectionPreferencesProps.Preferences;
    error?: AWSError;
    event?: EventEngineEventsService.EventData;
    loading?: boolean;
    participants?: EventEngineEventsService.ParticipantList;
    participantStateFilterOption?: SelectProps.Option;
    refetch?: (isBackground?: boolean) => Promise<void>;
    storageScope?: string;
}

export interface ParticipantDataTableItem
    extends EventEngineEventsService.ParticipantData {
    displayState: string;
    displayDenyReason: string;
}

export interface ProcessDataOptions {
    selectedFilters?: Record<'participantState', SelectProps.Option>;
}

export enum TestID {
    BUTTON_ACTIONS = 'participants-table-actions',
    BUTTON_REFRESH = 'participants-table-button-refresh',
    COLLECTION_SELECT_PARTICIPANT_STATE = 'collection-select-participant-state',
    FILTER_COUNT_TEXT = 'filter-count-text',
}

export enum ActionID {
    BAN = 'BAN',
    UNBAN = 'UNBAN',
    ACCESS_AWS_ACCOUNT = 'ACCESS_AWS_ACCOUNT',
}
