import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export enum FieldName {
    TIMEZONE = 'timezone',
    START_DATE = 'start_date',
    START_TIME = 'start_time',
    START_PERIOD = 'start_period',
    END_DATE = 'end_date',
    END_TIME = 'end_time',
    END_PERIOD = 'end_period',
}

export interface FormContext {
    isProvisionedStarted?: boolean;
}

export interface UpdateScheduleModalProps {
    event: EventEngineEventsService.EventData;
    isVisible: boolean;
    onModalClosed: () => void;
    refetchGetEvent: () => Promise<void>;
}

export enum NotificationID {
    UPDATE_RESPONSE = 'UPDATE_SCHEDULE_RESPONSE',
}
