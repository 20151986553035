import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { AWSError } from 'aws-sdk/lib/error';

export type StackNameCardItem = EventEngineEventsService.TeamStack;

export interface StackNameCardsProps {
    stacks: EventEngineEventsService.TeamStack[];
    onSelectionChange: (item: EventEngineEventsService.TeamStack) => void;
    error?: AWSError;
    initialSelectedItem?: EventEngineEventsService.TeamStack;
    loading?: boolean;
    onRefresh?: () => void;
}

export enum TestID {
    BUTTON_REFRESH = 'button-refresh',
    COLLECTION_SELECT_STACK_STATUS = 'collection-select-stack-status',
    FILTER_COUNT_TEXT = 'filter-count-text',
}
