import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { formatString } from '@amzn/event-engine-js-utils';
import getMaxParticipantForecast from 'pages/Events/CreateEvent/utils/get-max-participant-forecast';
import { MAX_TEAM_SIZE } from 'utils/config';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../constants';

export enum FieldName {
    MAX_EVENT_SIZE = 'max_event_size',
    EVENT_TYPE = 'event_type',
    PARTICIPANT_FORECAST = 'participant_forecast',
    TEAM_SIZE = 'team_size',
}

// TODO: use shared schema for create event form
const Schema = Yup.object({
    [FieldName.MAX_EVENT_SIZE]: Yup.number().required(),
    [FieldName.EVENT_TYPE]: Yup.string().required(),
    [FieldName.PARTICIPANT_FORECAST]: Yup.number()
        .required(i18nStrings.required)
        .integer(
            i18nStrings.events.create.fields.attendeeForecast.errorWholeNumber
        )
        .min(1, i18nStrings.events.create.fields.attendeeForecast.errorMin)
        .test((attendees: number | undefined, context) => {
            if (!attendees) {
                return context.createError({
                    message: i18nStrings.required,
                });
            }

            const eventType = context.parent[FieldName.EVENT_TYPE];
            const maxParticipantForecast = getMaxParticipantForecast(
                context.parent[FieldName.MAX_EVENT_SIZE],
                context.parent[FieldName.TEAM_SIZE]
            );

            if (attendees > maxParticipantForecast) {
                return context.createError({
                    message: formatString(
                        eventType === EVENT_TYPE.PRODUCTION
                            ? i18nStrings.events.create.fields.attendeeForecast
                                  .errorMaxProductionEvent
                            : i18nStrings.events.create.fields.attendeeForecast
                                  .errorMaxTestEvent,
                        maxParticipantForecast
                    ),
                });
            }

            return true;
        }),
    [FieldName.TEAM_SIZE]: Yup.number()
        .required(i18nStrings.required)
        .integer(i18nStrings.events.create.fields.teamSize.errorWholeNumber)
        .required(i18nStrings.required)
        .min(1, i18nStrings.events.create.fields.teamSize.errorMin)
        .max(
            MAX_TEAM_SIZE,
            formatString(
                i18nStrings.events.create.fields.teamSize.errorMax,
                MAX_TEAM_SIZE
            )
        ),
});

export type SchemaType = Yup.InferType<typeof Schema>;

export default Schema;
