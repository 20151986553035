import { CONTENT_STATE_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import StatusIndicator, {
    StatusIndicatorProps,
} from '@amzn/awsui-components-react/polaris/status-indicator';
import { ContentStateTypes } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import React from 'react';

import { i18nStrings } from '../../../../constants';

const IconIndicatorMapping: Record<
    ContentStateTypes,
    { label: string; type: StatusIndicatorProps.Type }
> = {
    [CONTENT_STATE_TYPES.REPLICA_READONLY]: {
        label: 'Readonly (replica)',
        type: 'stopped',
    },
    [CONTENT_STATE_TYPES.CREATE_IN_PROGRESS]: {
        label: 'Creating',
        type: 'in-progress',
    },
    [CONTENT_STATE_TYPES.CREATE_COMPLETE]: {
        label: 'Active',
        type: 'success',
    },
    [CONTENT_STATE_TYPES.CREATE_FAILED]: {
        label: 'Create failed',
        type: 'error',
    },
    [CONTENT_STATE_TYPES.SCHEDULED_FOR_DELETE]: {
        label: 'Scheduled for deletion',
        type: 'pending',
    },
};

export interface WorkshopStateStatusProps {
    state?: ContentStateTypes;
}

const WorkshopStateStatus = ({ state }: WorkshopStateStatusProps) => {
    if (!state) {
        return <>{i18nStrings.empty}</>;
    }

    const { label = state, type = 'error' } = IconIndicatorMapping[state] || {};

    return (
        <StatusIndicator iconAriaLabel={label} type={type}>
            {label}
        </StatusIndicator>
    );
};

export default WorkshopStateStatus;
