import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { PermissionEditComponentProps } from '@amzn/event-engine-js-utils';

export interface EditEventFacilitatorProps
    extends PermissionEditComponentProps<{
        permissionsList: EventEngineEventsService.EventPermissionsList;
        eventId: string;
        isEventTerminated: boolean;
    }> {
    onSubmit?: (values: EventEngineEventsService.EventPermissionsList) => void;
}

export enum TestID {
    ACTION_CANCEL = 'action_cancel',
    ACTION_SAVE = 'action_save',
}

export enum FieldName {
    FACILITATORS = 'facilitators',
}
