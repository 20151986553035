import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface BanParticipantModalProps {
    onDismiss: () => void;
    onUpdateSuccess: () => void;
    participant: EventEngineEventsService.ParticipantData;
    isVisible?: boolean;
}

export enum NotificationID {
    UPDATE_RESPONSE = 'BAN_UPDATE_RESPONSE',
}
