import { CardsProps } from '@amzn/awsui-components-react/polaris/cards';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';

import StackDeploymentStatus from '../StackDeploymentStatus';
import { StackNameCardItem } from './types';

enum SectionID {
    NAME = 'name',
    STATUS = 'status',
}

type SectionFieldMap = Record<SectionID, keyof StackNameCardItem>;

export const stackStatusFilterStorageKey = 'collectionSelectFilterStackStatus';
export const sectionFieldMap: SectionFieldMap = {
    [SectionID.NAME]: 'name',
    [SectionID.STATUS]: 'status',
};

const getResourceStatus = ({ status }: StackNameCardItem) => (
    <StackDeploymentStatus status={status} />
);

export const cardDefinition: CardsProps.CardDefinition<StackNameCardItem> = {
    header: (item) => item.name,
    sections: [
        {
            id: SectionID.NAME,
            content: (item) => getResourceStatus(item),
        },
    ],
};

export const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: 'and',
};

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        id: SectionID.NAME,
        propertyLabel: i18nStrings.events.teams.stackNameCards.sections.name,
        groupValuesLabel:
            i18nStrings.events.teams.stackNameCards.propertyFilter.name,
    },
    {
        id: SectionID.STATUS,
        propertyLabel: i18nStrings.events.teams.stackNameCards.sections.status,
        groupValuesLabel:
            i18nStrings.events.teams.stackNameCards.propertyFilter.status,
    },
].map(({ id, propertyLabel, groupValuesLabel }) => ({
    key: sectionFieldMap[id],
    propertyLabel,
    groupValuesLabel,
    operators: ['=', '!=', ':', '!:'],
    showOptions: true,
}));
