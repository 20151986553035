import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PaginationProps } from '@amzn/awsui-components-react/polaris/pagination';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { tableConfig } from '@amzn/event-engine-js-utils';

import i18nStrings from './i18n-strings';

export const emptyValue = i18nStrings.empty;

const loadingText = i18nStrings.loading;

type PageSelectorOptionSizes = 'small' | 'medium';
const pageSelectorOptions: Record<
    PageSelectorOptionSizes,
    CollectionPreferencesProps.PageSizeOption[]
> = {
    small: [
        { value: 5, label: `5 ${i18nStrings.item.plural}` },
        { value: 10, label: `10 ${i18nStrings.item.plural}` },
        { value: 15, label: `15 ${i18nStrings.item.plural}` },
    ],
    medium: [
        { value: 10, label: `10 ${i18nStrings.item.plural}` },
        { value: 30, label: `30 ${i18nStrings.item.plural}` },
        { value: 50, label: `50 ${i18nStrings.item.plural}` },
    ],
};

export const defaultSortPreferences: TableProps.SortingState<any> = {
    sortingColumn: {
        sortingField: '',
    },
};

const pageSizePreference: CollectionPreferencesProps.PageSizePreference = {
    title: i18nStrings.pageSize,
    options: pageSelectorOptions.medium,
};

const getPageSizePreference = (
    size: PageSelectorOptionSizes
): CollectionPreferencesProps.PageSizePreference => ({
    title: i18nStrings.pageSize,
    options: pageSelectorOptions[size],
});

const propertyFilteringI18nConstants = {
    placeholder: i18nStrings.table.placeholder,
    operationAndText: i18nStrings.table.operationAnd,
    operationNotAndText: i18nStrings.table.operationNotAnd,
    operationOrText: i18nStrings.table.operationOr,
    operationNotOrText: i18nStrings.table.operationNotOr,
    clearFiltersText: i18nStrings.table.clearFilters,
    groupPropertiesText: i18nStrings.table.properties,
    groupValuesText: i18nStrings.table.groupValues,
    filteringEmpty: i18nStrings.table.filteringEmpty,
    filteringLabel: i18nStrings.table.filtering,
    filteringStatusText: i18nStrings.loading,
};

const paginationAriaLabels: PaginationProps.Labels = {
    nextPageLabel: i18nStrings.nextPage,
    previousPageLabel: i18nStrings.previousPage,
    pageLabel: i18nStrings.pageLabel,
};

const wrapLinesPreference: CollectionPreferencesProps.WrapLinesPreference = {
    label: i18nStrings.wrapLines,
    description: i18nStrings.wrapLinesDescription,
};

const collectionPreferencesI18nConstants: Pick<
    CollectionPreferencesProps,
    'title' | 'confirmLabel' | 'cancelLabel'
> = {
    title: i18nStrings.preferences,
    confirmLabel: i18nStrings.confirm,
    cancelLabel: i18nStrings.cancel,
};

const ariaLabels: TableProps.AriaLabels<any> = {
    allItemsSelectionLabel: ({ selectedItems }) =>
        `${selectedItems.length} ${
            selectedItems.length === 1
                ? i18nStrings.item.singular
                : i18nStrings.item.plural
        } selected`,
    itemSelectionLabel: ({ selectedItems }, item) => {
        return `is ${
            selectedItems.find((selectedItem) => selectedItem === item)
                ? ''
                : 'not '
        }selected`;
    },
    selectionGroupLabel: 'Items selection',
};

const textFilterCountTextI18nConstants = i18nStrings.match;

const pollIntervalMS = 10000;

export default {
    emptyValue,
    loadingText,
    pageSelectorOptions,
    defaultPreferences: tableConfig.defaultTablePreferences,
    defaultSortPreferences,
    pageSizePreference,
    getPageSizePreference,
    propertyFilteringI18nConstants,
    paginationAriaLabels,
    wrapLinesPreference,
    collectionPreferencesI18nConstants,
    textFilterCountTextI18nConstants,
    ariaLabels,
    pollIntervalMS,
};
