import { ContentSpecSummary } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

import { DEFAULT_ACCESSIBLE_REGIONS } from './constants';

/**
 * Returns min and max constraints used in form validation
 *
 * @param {ContentSpecSummary} contentSpecSummary
 * @returns
 */
const getAccessibleRegionsConstraints = (
    contentSpecSummary?: ContentSpecSummary
) => {
    const defaultRegionsCount = DEFAULT_ACCESSIBLE_REGIONS.length;
    const fallbackConstraint = defaultRegionsCount;
    const min =
        typeof contentSpecSummary?.minAccessibleRegions === 'number'
            ? contentSpecSummary.minAccessibleRegions + defaultRegionsCount
            : fallbackConstraint;
    const max =
        typeof contentSpecSummary?.maxAccessibleRegions === 'number'
            ? contentSpecSummary.maxAccessibleRegions + defaultRegionsCount
            : fallbackConstraint;

    return {
        min,
        max,
    };
};

export default getAccessibleRegionsConstraints;
