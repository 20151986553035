/**--GENERATED FILE DO NOT MODIFY--**/
export enum EVENT_ACCOUNT_SOURCE {
    WORKSHOP_STUDIO = 'workshop_studio',
    CUSTOMER_PROVIDED = 'customer_provided',
}

export enum EVENT_TYPE {
    PRODUCTION = 'production',
    TEST = 'test',
}

export enum AWS_REGION {
    US_EAST_1 = 'us-east-1',
    US_EAST_2 = 'us-east-2',
    US_WEST_1 = 'us-west-1',
    US_WEST_2 = 'us-west-2',
    EU_CENTRAL_1 = 'eu-central-1',
    EU_WEST_1 = 'eu-west-1',
    EU_WEST_2 = 'eu-west-2',
    EU_WEST_3 = 'eu-west-3',
    AP_SOUTH_1 = 'ap-south-1',
    AP_NORTHEAST_1 = 'ap-northeast-1',
    AP_NORTHEAST_2 = 'ap-northeast-2',
    AP_SOUTHEAST_1 = 'ap-southeast-1',
    AP_SOUTHEAST_2 = 'ap-southeast-2',
    CA_CENTRAL_1 = 'ca-central-1',
    SA_EAST_1 = 'sa-east-1',
    EU_NORTH_1 = 'eu-north-1',
    AP_NORTHEAST_3 = 'ap-northeast-3',
}

export enum EVENT_STATE_RUNTIME {
    SCHEDULED = 'scheduled',
    CANCELED = 'canceled',
    NOT_STARTED = 'not_started',
    START_IN_PROGRESS = 'start_in_progress',
    START_FAILED = 'start_failed',
    START_SUCCESS = 'start_success',
    PAUSE_IN_PROGRESS = 'pause_in_progress',
    PAUSE_FAILED = 'pause_failed',
    PAUSE_SUCCESS = 'pause_success',
    TERMINATE_IN_PROGRESS = 'terminate_in_progress',
    TERMINATE_FAILED = 'terminate_failed',
    TERMINATE_SUCCESS = 'terminate_success',
}

export enum EVENT_STATE_INFRASTRUCTURE {
    NOT_APPLICABLE = 'not_applicable',
    NOT_PROVISIONED = 'not_provisioned',
    PROVISION_IN_PROGRESS = 'provision_in_progress',
    PROVISION_FAILED = 'provision_failed',
    DEPLOYMENT_CENTRAL_IN_PROGRESS = 'deployment_central_in_progress',
    DEPLOYMENT_CENTRAL_FAILED = 'deployment_central_failed',
    DEPLOYMENT_IN_PROGRESS = 'deployment_in_progress',
    DEPLOYMENT_HALTED = 'deployment_halted',
    DEPLOYMENT_FAILED = 'deployment_failed',
    DEPLOYMENT_SUCCESS = 'deployment_success',
    TERMINATE_IN_PROGRESS = 'terminate_in_progress',
    TERMINATE_FAILED = 'terminate_failed',
    TERMINATE_SUCCESS = 'terminate_success',
}

export enum PERSONA {
    WS_OPS = 'ws_ops',
    WS_PARTICIPANT = 'ws_participant',
    WS_SYSTEM = 'ws_system',
}

export enum TEAM_STATE {
    PROVISION_QUEUED = 'provision_queued',
    PROVISION_IN_PROGRESS = 'provision_in_progress',
    PROVISION_SUCCESS = 'provision_success',
    PROVISION_FAILED = 'provision_failed',
    DEPLOYMENT_QUEUED = 'deployment_queued',
    DEPLOYMENT_IN_PROGRESS = 'deployment_in_progress',
    DEPLOYMENT_HALTED = 'deployment_halted',
    DEPLOYMENT_SUCCESS = 'deployment_success',
    DEPLOYMENT_FAILED = 'deployment_failed',
    TERMINATE_IN_PROGRESS = 'terminate_in_progress',
    TERMINATE_FAILED = 'terminate_failed',
    TERMINATE_SUCCESS = 'terminate_success',
}

export enum QUOTA_ID {
    ACCOUNTS_WORKSHOP_STUDIO = 'accounts-workshop-studio',
    EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT = 'events-max-accounts-per-production-event',
    EVENTS_MAX_ACCOUNTS_PER_TEST_EVENT = 'events-max-accounts-per-test-event',
}

export enum PARTICIPANT_STATE {
    DENIED = 'denied',
    ASSIGNED = 'assigned',
    PENDING = 'pending',
    UNASSIGNED = 'unassigned',
    BANNED = 'banned',
}

export enum PARTICIPANT_DENIED_REASON {
    NOT_IN_ALLOW_LIST = 'not_in_allow_list',
    OUT_OF_CAPACITY = 'out_of_capacity',
    KICKED = 'kicked',
}

export enum EVENT_ALLOWLIST_TYPE {
    EMAIL = 'email',
}

export enum EVENT_NEXT_ACTION {
    TERMINATE = 'terminate',
    START = 'start',
    CANCEL = 'cancel',
    PROVISION = 'provision',
}

export enum QUOTA_RESOURCE_TYPE {
    CONTENT = 'content',
    EVENT = 'event',
}

export enum QUOTA_ACTION {
    CONSUME = 'consume',
    RESERVE = 'reserve',
    RELEASE = 'release',
}

export enum UPDATE_EVENT_QUOTA_RESERVATION_OPERATION {
    ADD = 'add',
    SUBTRACT = 'subtract',
}

export enum EVENT_PERMISSIONS_TYPES {
    ADMINISTRATOR = 'administrator',
    SUPPORT_STAFF = 'support_staff',
}

export enum SEND_QUOTA_OPERATION {
    TAKE = 'take',
    GIVE = 'give',
}

export enum SEND_QUOTA_TARGET {
    EVENT_ENGINE_CLASSIC = 'event-engine-classic',
}

export enum CENTRAL_ACCOUNT_DEPLOYMENT_STATE {
    PROVISION_IN_PROGRESS = 'provision_in_progress',
    PROVISION_SUCCESS = 'provision_success',
    PROVISION_FAILED = 'provision_failed',
    DEPLOYMENT_IN_PROGRESS = 'deployment_in_progress',
    DEPLOYMENT_HALTED = 'deployment_halted',
    DEPLOYMENT_SUCCESS = 'deployment_success',
    DEPLOYMENT_FAILED = 'deployment_failed',
    DEPLOYMENT_QUEUED = 'deployment_queued',
    TERMINATE_IN_PROGRESS = 'terminate_in_progress',
    TERMINATE_FAILED = 'terminate_failed',
    TERMINATE_SUCCESS = 'terminate_success',
}
