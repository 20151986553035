import {
    CONTENT_BUILD_STATE,
    IMPACT_TYPES,
} from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Button, {
    ButtonProps,
} from '@amzn/awsui-components-react/polaris/button';
import { ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { useLazyRequest } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { AWSError } from 'aws-sdk/lib/error';
import isAccountSourceDefined from 'components/AccountSourceStatus/is-account-source-defined';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import i18nStrings from 'constants/i18n-strings';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import React, {
    Fragment,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import EEContentCatalog from 'services/ee-content-catalog';
import ErrorMessage, { MessageTypes } from 'services/error-message';

import { Step } from '../../components/CreateEventForm.validation';
import { CreateEventLocationState } from '../../types';
import useEventReadinessModal from './use-event-readiness-modal';

interface useCreateEventResult {
    actionButton: ReactNode;
    actionButtonDropdownItem: ButtonDropdownProps.Item;
    modal: ReactNode;
    createEvent: () => void;
    loading?: boolean;
}

type ContentBuildDetails = Pick<
    EventEngineContentCatalogService.ContentBuildData,
    'contentId' | 'contentBuildId' | 'contentSpecSummary' | 'state'
>;

const CREATE_EVENT_PATH = `${routes.events}/${routeTokens.create}`;
export const ACTION_ID = 'create_event_action';

interface BaseUseCreateEventOptions {
    variant?: ButtonProps.Variant;
    text?: string;
}

interface ContentIdUseCreateEventOptions extends BaseUseCreateEventOptions {
    contentId?: string;
}

interface ContentBuildUseCreateEventOptions extends BaseUseCreateEventOptions {
    contentBuild?: ContentBuildDetails;
}

/**
 * There are several possible event creation flows based on the first positional parameter
 * - contentId: Initializes create event form with the specified content ID. Event readiness check will be enforced.
 * - contentBuild: Initializes a *test* event with the specified content build. Account source and build state must be valid.
 * - No parameter: Navigate to create event form
 */
function useCreateEvent(
    value?: ContentIdUseCreateEventOptions
): useCreateEventResult;
function useCreateEvent(
    value?: ContentBuildUseCreateEventOptions
): useCreateEventResult;
function useCreateEvent(
    value?: ContentIdUseCreateEventOptions | ContentBuildUseCreateEventOptions
): useCreateEventResult {
    const [
        getContent,
        { data: getContentResponse, isLoading, isError },
    ] = useLazyRequest<typeof EEContentCatalog.getContent, AWSError>(
        EEContentCatalog.getContent,
        {}
    );
    const { showNotification } = useContext(NotificationsContext);
    const history = useHistory<CreateEventLocationState>();
    const disabledReason = useMemo(() => {
        // disabled state is only applicable to content build/test event case
        if (!(value && 'contentBuild' in value && value.contentBuild)) {
            return;
        }

        const { contentBuild } = value;
        let reason: string | undefined;

        // TODO: Remove case-insensitive match after prod backend data migration
        if (
            contentBuild.state.toLowerCase() !==
            CONTENT_BUILD_STATE.SUCCESS.toLowerCase()
        ) {
            reason =
                i18nStrings.createEventAction.workshopBuildStateDisabledReason;
        } else if (
            !isAccountSourceDefined(
                contentBuild.contentSpecSummary.accountSources
            )
        ) {
            reason =
                i18nStrings.createEventAction
                    .workshopAccountSourceMissingDisabledReason;
        }

        return reason;
    }, [value]);
    const createEvent = useCallback(() => {
        if (!(value && ('contentId' in value || 'contentBuild' in value))) {
            history.push(CREATE_EVENT_PATH);
            return;
        }

        const contentId =
            ('contentId' in value && value.contentId) ||
            ('contentBuild' in value && value.contentBuild?.contentId);

        contentId && getContent(contentId);
    }, [value]);
    const text = value?.text || i18nStrings.createEventAction.createEvent;
    const disabled =
        !!disabledReason ||
        (value &&
            (('contentId' in value && !value.contentId) ||
                ('contentBuild' in value && !value.contentBuild)));
    const navigateToCreateEvent = (routerState?: CreateEventLocationState) =>
        history.push(CREATE_EVENT_PATH, routerState);
    const createProductionEventWithWorkshop = (
        content: EventEngineContentCatalogService.ContentData
    ) =>
        navigateToCreateEvent({
            createEvent: {
                [Step.SELECT_WORKSHOP]: {
                    contentId: content.contentId!,
                    contentBuildId: content.publishedBuildId!,
                    contentSpecSummary: content.contentSpecSummary!,
                    title: content.title!,
                    publishedBuildId: content.publishedBuildId,
                },
            },
        });
    const createTestEventWithWorkshopBuild = (
        {
            title = 'Test event title',
        }: EventEngineContentCatalogService.ContentData,
        { contentId, contentBuildId, contentSpecSummary }: ContentBuildDetails
    ) =>
        navigateToCreateEvent({
            createEvent: {
                [Step.SELECT_WORKSHOP]: {
                    contentId,
                    contentBuildId,
                    contentSpecSummary,
                    title,
                },
                [Step.EVENT_TYPE]: {
                    eventType: EVENT_TYPE.TEST,
                    accountSource: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
                },
            },
        });
    const { modalComponent, showModal } = useEventReadinessModal({
        content: getContentResponse?.content,
        onConfirm: createProductionEventWithWorkshop,
    });

    useEffect(() => {
        if (!(value && getContentResponse?.content)) {
            return;
        }

        const { content } = getContentResponse;

        if ('contentId' in value) {
            // check valid event readiness status
            if (content.eventReadiness.summary !== IMPACT_TYPES.READY) {
                showModal();
                return;
            }

            createProductionEventWithWorkshop(content);
        } else if ('contentBuild' in value && value.contentBuild) {
            createTestEventWithWorkshopBuild(content, value.contentBuild);
        }
    }, [getContentResponse]);

    useEffect(() => {
        isError &&
            showNotification?.(
                ErrorMessage.getMessage(isError, MessageTypes.getContent),
                {
                    type: 'error',
                }
            );
    }, [isError]);

    return {
        actionButton: (
            <Fragment key={ACTION_ID}>
                <Button
                    data-testid={ACTION_ID}
                    href={CREATE_EVENT_PATH}
                    loading={isLoading}
                    onClick={(e) => {
                        e.preventDefault();
                        createEvent();
                    }}
                    disabled={disabled}
                    variant={value?.variant}>
                    {text}
                </Button>
                {modalComponent}
            </Fragment>
        ),
        actionButtonDropdownItem: {
            id: ACTION_ID,
            text,
            disabled,
            disabledReason,
        },
        modal: modalComponent,
        createEvent,
        loading: isLoading,
    };
}

export default useCreateEvent;
