import { formatString } from '@amzn/event-engine-js-utils';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../constants';

export enum FieldName {
    MAX_CAPACITY = 'max_capacity',
    CAPACITY = 'capacity',
}

const FormSchema = Yup.object({
    // hidden fields
    [FieldName.MAX_CAPACITY]: Yup.number(),
    // visible fields
    [FieldName.CAPACITY]: Yup.number()
        .integer(
            i18nStrings.events.updateCapacity.fields.capacity.errorWholeNumber
        )
        .min(1, i18nStrings.events.updateCapacity.fields.capacity.errorMin)
        .test((capacity: number | undefined, context) => {
            if (!capacity) {
                return context.createError({
                    message: 'Required',
                });
            }

            const maxCapacity = context.parent[FieldName.MAX_CAPACITY];

            if (capacity > maxCapacity) {
                return context.createError({
                    message: formatString(
                        i18nStrings.events.updateCapacity.fields.capacity
                            .errorMax,
                        maxCapacity
                    ),
                });
            }

            return true;
        }),
});

export type SchemaType = Yup.InferType<typeof FormSchema>;
export default FormSchema;
