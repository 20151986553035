import {
    ListEventTeamOutputsRequest,
    ListEventTeamOutputsResponse,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { useLazyRequest, usePaginator } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk';
import OutputsTable from 'pages/Events/EventDetails/components/OutputsTable';
import { DEFAULT_PAGINATION_LIMIT } from 'pages/Events/EventDetails/components/OutputsTable/config';
import {
    OutputsTableContainerProps,
    OutputsTableItem,
} from 'pages/Events/EventDetails/components/OutputsTable/types';
import { TeamDetailsContext } from 'pages/Events/EventDetails/TeamDetails/constants';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EEEvents } from 'services';

const STORAGE_SCOPE = 'stackOutputsTable_03212023';

const StackOutputsTableContainer = ({
    stackName,
}: OutputsTableContainerProps) => {
    const [allData, setAllData] = useState<OutputsTableItem[]>([]);
    const { teamRequest } = useContext(TeamDetailsContext);

    const eventId = teamRequest.data?.team?.eventId;
    const teamId = teamRequest.data?.team?.teamId;

    const [getOutputs, { data, isError, isLoading }] = useLazyRequest<
        typeof EEEvents.getListEventTeamOutputs,
        AWSError
    >(EEEvents.getListEventTeamOutputs, {});

    const paginator = usePaginator<
        ListEventTeamOutputsRequest,
        ListEventTeamOutputsResponse
    >(getOutputs, DEFAULT_PAGINATION_LIMIT, 'outputs');

    const getPaginatedData = useCallback(
        (fresh = false, { eventId, teamId, stackName }) => {
            if (!stackName || !eventId || !teamId) {
                return;
            }
            return paginator.getPaginatedData<ListEventTeamOutputsRequest>(
                0,
                '*',
                {
                    fresh,
                    clientArgs: { eventId, teamId, stackName },
                }
            );
        },
        []
    );

    useEffect(() => {
        setAllData([]);
        getPaginatedData(true, { eventId, teamId, stackName });
    }, [stackName]);

    useEffect(() => {
        if (data?.outputs) {
            setAllData([...allData, ...data.outputs]);
        }
    }, [data]);

    return (
        <OutputsTable
            outputs={allData}
            loading={isLoading}
            error={isError}
            storageScope={STORAGE_SCOPE}
        />
    );
};

export default StackOutputsTableContainer;
