import { TEAM_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import EventEngineEventsService, {
    AwsStsCredentials,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';

export enum NotificationID {
    ERROR = 'team_account_access_error',
}

export type AWSSTSCredentials = Required<AwsStsCredentials>;

export const ACCOUNT_ACCESSIBLE_STATES: EventEngineEventsService.TeamState[] = [
    TEAM_STATE.DEPLOYMENT_IN_PROGRESS,
    TEAM_STATE.DEPLOYMENT_HALTED,
    TEAM_STATE.DEPLOYMENT_SUCCESS,
    TEAM_STATE.DEPLOYMENT_FAILED,
];

export type EventData = Pick<
    EventEngineEventsService.EventData,
    'eventId' | 'stateRuntime' | 'stateInfrastructure' | 'accessibleRegions'
>;
export type TeamData = Pick<
    EventEngineEventsService.TeamData,
    'teamId' | 'state'
>;
