import Alert from '@amzn/awsui-components-react/polaris/alert';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    BaseMarkdown,
    formatString,
    useLazyRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk';
import { routes } from 'components/AppRoutes/routes.constants';
import i18nStrings from 'constants/i18n-strings';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import { TabIDs } from 'pages/Workshops/utils/constants';
import React, {
    FC,
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import EEContentCatalog from 'services/ee-content-catalog';

import Schema, { FieldName, SchemaType } from './schema';

const ExternalLink = ({ node, ...props }: any) => <Link external {...props} />;
interface GenerateWorkshopStaticAssetURLsFormProps {
    contentId: string;
}
const GenerateWorkshopStaticAssetURLsForm: FC<GenerateWorkshopStaticAssetURLsFormProps> = ({
    contentId,
    children,
}) => {
    const history = useHistory();
    const { showNotification } = useContext(NotificationsContext);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [
        batchCreateContentAssetStaticUrls,
        { data, isError, isLoading },
    ] = useLazyRequest<
        typeof EEContentCatalog.batchCreateContentAssetStaticUrls,
        AWSError
    >(EEContentCatalog.batchCreateContentAssetStaticUrls, {});

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!data.failedAssets?.length) {
            showNotification?.(
                formatString(
                    i18nStrings.workshops.generateWorkshopStaticAssetURLs.form
                        .successNotification,
                    data.assetUrls?.length
                )
            );
        }

        history.push(
            `${routes.workshops}/${contentId}#${TabIDs.assetStaticUrls}`,
            { failedAssets: data?.failedAssets }
        );
    }, [data]);

    const onSubmit = (inputValues: SchemaType) => {
        batchCreateContentAssetStaticUrls({
            assets: inputValues[FieldName.SELECTED_ASSETS],
            contentId,
        });
    };

    const formik = useFormik({
        validateOnChange,
        validateOnBlur: false,
        validationSchema: Schema,
        initialValues: {
            [FieldName.SELECTED_ASSETS]: [],
        },
        onSubmit,
    });

    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, []);

    const onSecondaryActionClick = useCallback(() => {
        history.push(
            `${routes.workshops}/${contentId}#${TabIDs.assetStaticUrls}`
        );
    }, []);

    return (
        <>
            <FormikProvider value={formik}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="secondary-action"
                                formAction="none"
                                variant="link"
                                onClick={onSecondaryActionClick}>
                                {i18nStrings.cancel}
                            </Button>
                            <Button
                                data-testid="primary-action"
                                variant="primary"
                                disabled={isLoading}
                                loading={isLoading}
                                onClick={onPrimaryActionClick}>
                                {
                                    i18nStrings.workshops
                                        .generateWorkshopStaticAssetURLs.form
                                        .generate
                                }
                            </Button>
                        </SpaceBetween>
                    }
                    errorText={
                        formik.errors[FieldName.SELECTED_ASSETS] ||
                        (isError &&
                            formatString(
                                i18nStrings.workshops
                                    .generateWorkshopStaticAssetURLs.form.error,
                                isError.message
                            ))
                    }
                    header={
                        <SpaceBetween size="l">
                            <Header variant="h1">
                                {
                                    i18nStrings.workshops
                                        .generateWorkshopStaticAssetURLs.form
                                        .header
                                }
                            </Header>
                            <Alert type="info">
                                <BaseMarkdown
                                    components={{
                                        p: Fragment,
                                        a: ExternalLink,
                                    }}
                                    linkTarget="_blank">
                                    {
                                        i18nStrings.workshops
                                            .generateWorkshopStaticAssetURLs
                                            .form.alertMessage
                                    }
                                </BaseMarkdown>
                            </Alert>
                        </SpaceBetween>
                    }>
                    {children}
                </Form>
            </FormikProvider>
        </>
    );
};

export default GenerateWorkshopStaticAssetURLsForm;
