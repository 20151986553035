import { PARTICIPANT_DENIED_REASON } from '__generated__/@amzn/event-engine-events-sdk/enums';

import { i18nStrings } from '../../../../../constants';

export const PARTICIPANT_DENIED_REASON_MAP: Record<
    PARTICIPANT_DENIED_REASON,
    string
> = {
    [PARTICIPANT_DENIED_REASON.KICKED]:
        i18nStrings.events.participants.denyReasons.kicked,
    [PARTICIPANT_DENIED_REASON.NOT_IN_ALLOW_LIST]:
        i18nStrings.events.participants.denyReasons.notAllowListed,
    [PARTICIPANT_DENIED_REASON.OUT_OF_CAPACITY]:
        i18nStrings.events.participants.denyReasons.outOfCapacity,
};
