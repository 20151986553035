import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import i18nStrings from 'constants/i18n-strings';
import * as Yup from 'yup';

export enum FieldName {
    ACCOUNT_SOURCE = 'account_source',
    COUNT_FAILED_TEAMS = 'count_failed_teams',
    TERMS_ACKNOWLEDGED = 'terms_acknowledged',
}

const Schema = Yup.object({
    [FieldName.ACCOUNT_SOURCE]: Yup.mixed<
        EventEngineEventsService.EventAccountSource
    >()
        .oneOf(Object.values(EVENT_ACCOUNT_SOURCE))
        .required(),
    [FieldName.COUNT_FAILED_TEAMS]: Yup.number(),
    [FieldName.TERMS_ACKNOWLEDGED]: Yup.boolean().when(
        FieldName.ACCOUNT_SOURCE,
        {
            is: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
            then: Yup.boolean().test((value, context) => {
                const values: SchemaType = context.parent;
                const countFailedTeams =
                    values[FieldName.COUNT_FAILED_TEAMS] || 0;

                if (countFailedTeams > 0) {
                    return !value
                        ? context.createError({
                              message: i18nStrings.required,
                          })
                        : true;
                }

                return true;
            }),
        }
    ),
});

export type SchemaType = Yup.InferType<typeof Schema>;
export default Schema;
