import React, { useCallback, useMemo, useState } from 'react';

import CreateEventUnsavedChangesModal, {
    UnsavedChangesModalProps,
} from './UnsavedChangesModal';

export interface UseUnsavedChangesModal {
    modalComponent: JSX.Element;
    showModal: () => void;
    hideModal: () => void;
}

const useUnsavedChangesModal = ({
    onDismiss,
    onConfirm,
    onDecline,
}: UnsavedChangesModalProps) => {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = useCallback(() => {
        setModalVisible(true);
    }, [setModalVisible]);

    const hideModal = useCallback(() => {
        setModalVisible(false);
    }, [setModalVisible]);

    const onModalDismiss = useCallback((event) => {
        setModalVisible(false);
        onDismiss && onDismiss(event);
    }, []);

    const modalComponent = useMemo(() => {
        return modalVisible ? (
            <CreateEventUnsavedChangesModal
                onDismiss={onModalDismiss}
                onConfirm={onConfirm}
                onDecline={onDecline}
            />
        ) : null;
    }, [modalVisible]);

    return { modalComponent, showModal, hideModal };
};

export default useUnsavedChangesModal;
