import i18nStrings from 'constants/i18n-strings';
import React from 'react';

import BooleanStatus from '../BooleanStatus';
import isAccountSourceDefined from './is-account-source-defined';

export interface AccountSourceStatusProps {
    value?: string[];
}

const AccountSourceStatus = ({ value }: AccountSourceStatusProps) => {
    const v = isAccountSourceDefined(value);

    return (
        <BooleanStatus
            popoverContent={
                !v && i18nStrings.accountSourceMissingPopoverContent
            }
            value={v}
            typeOverride={!v ? 'warning' : undefined}
        />
    );
};

export default AccountSourceStatus;
