import {
    AWSCredentials,
    CredentialRoles,
    DeferredPromise,
} from '@amzn/event-engine-js-utils';
import EEContentCatalogService from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import AppSettings from 'services/app-settings';

class EEContentCatalogClient {
    private deferredClientPromise?: DeferredPromise<EEContentCatalogService>;
    private static instance?: EEContentCatalogClient;

    static getInstance(credentials: AWSCredentials): EEContentCatalogClient {
        if (!EEContentCatalogClient.instance) {
            EEContentCatalogClient.instance = new EEContentCatalogClient(
                credentials
            );
        }

        return EEContentCatalogClient.instance;
    }

    static __removeInstance__() {
        if (process.env.NODE_ENV !== 'test') {
            return;
        }
        EEContentCatalogClient.instance = undefined;
    }

    private constructor(private credentials: AWSCredentials) {}

    getClient = async (
        role: CredentialRoles
    ): Promise<EEContentCatalogService> => {
        this.credentials.currentRole = role;

        if (!this.deferredClientPromise) {
            this.deferredClientPromise = new DeferredPromise<
                EEContentCatalogService
            >();

            try {
                const settings = await AppSettings();

                this.credentials.authEndpoint = settings.authProxyEndpoint;
                this.deferredClientPromise.resolve(
                    new EEContentCatalogService({
                        credentials: this.credentials,
                        endpoint: settings.contentApiEndpoint,
                        region: settings.region,
                        maxRetries: 0,
                    })
                );
            } catch (e) {
                this.deferredClientPromise.reject(e);
            }
        }

        return await this.deferredClientPromise.promise;
    };
}

export default EEContentCatalogClient;
