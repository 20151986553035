import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import { CopyButton, ValueWithLabel } from '@amzn/event-engine-js-utils';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import InfoLink from 'components/InfoLink';
import React from 'react';

import { i18nStrings } from '../../../../constants';

type WorkshopUrlType = 'original' | 'quicklink';

export const isBuildPublished = (content?: ContentData) =>
    // The 'None' value will probably be updated to just be an empty value
    content &&
    content.publishedBuildId &&
    content.publishedBuildId !== 'None' &&
    content.publishedBuildUrl;

/**
 * Given a WorkshopUrlType, dig into the content data and return the URL.
 *
 * A content owner is able to add a quicklink to their workshop.
 * For example, instead of using  https://catalog.jcortezj.us-east-1.sandbox.workshops.aws/workshops/e2beeeb3-00e5-4858-9c27-5e06af08b962/en-US
 * they can use a quicklink of https://catalog.jcortezj.us-east-1.sandbox.workshops.aws/quick/en-US, where quick is the alias to their quicklink.
 */
export const getWorkshopUrl = (
    type: WorkshopUrlType = 'original',
    content?: ContentData
): string | null => {
    if (!isBuildPublished(content)) {
        return null;
    }

    const publishedBuildUrl = content?.publishedBuildUrl || '';

    if (type === 'quicklink') {
        return content?.publishedQuickLinkUrl ?? null;
    }

    return publishedBuildUrl;
};

/**
 * If there is a quicklink url, prioritize it over the original published URL
 */
export const getBestAvailableEventPortalWorkshopUrl = (
    content?: ContentData
) => {
    const [originalUrl, quickLinkUrl] = [
        getWorkshopUrl('original', content),
        getWorkshopUrl('quicklink', content),
    ];

    return quickLinkUrl || originalUrl || null;
};

interface WorkshopUrlsProps {
    content: ContentData;
}

const WorkshopUrl = ({
    label,
    url,
}: {
    label: string;
    url?: string | null;
}) => {
    return (
        <ValueWithLabel label={label}>
            {url ? (
                <>
                    <Box display="inline" margin={{ right: 'xxs' }}>
                        <CopyButton variant="inline-icon" content={url} />
                    </Box>
                    {url}
                </>
            ) : (
                i18nStrings.empty
            )}
        </ValueWithLabel>
    );
};

const WorkshopUrls = ({ content }: WorkshopUrlsProps) => {
    const [originalUrl, quickLinkUrl] = [
        getWorkshopUrl('original', content),
        getWorkshopUrl('quicklink', content),
    ];
    return (
        <Container
            header={
                <Header variant="h2">
                    Workshop URLs{' '}
                    <InfoLink
                        toolsContent={
                            <HelpPanel header={<h2>QuickLinks</h2>}>
                                <Box variant="p">
                                    QuickLinks allow you to use a more friendly
                                    URL for your content that can be shared.
                                    These URLs will be consistent across
                                    publishing different builds of your content.
                                    These URLs can be safely used in blog posts
                                    and other references that require them to be
                                    consistent over time.
                                </Box>
                            </HelpPanel>
                        }
                    />
                </Header>
            }>
            <ColumnLayout columns={2} variant="text-grid">
                <WorkshopUrl label="Original" url={originalUrl} />
                <WorkshopUrl label="QuickLink" url={quickLinkUrl} />
            </ColumnLayout>
        </Container>
    );
};

export default WorkshopUrls;
