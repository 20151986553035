import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import React, { PropsWithChildren, ReactNode } from 'react';

import styles from './DisplayItem.module.scss';

export enum TestIDs {
    label = 'display-item-label',
    value = 'display-item-value',
}

export type DisplayItemProps = {
    text: ReactNode;
    omitLoader?: boolean;
} & PropsWithChildren<ReactNode>;

const DisplayItem = ({
    text,
    children,
    omitLoader = false,
}: DisplayItemProps) => (
    <span className={styles.displayItem}>
        <span data-testid={TestIDs.label} className={styles.label}>
            {text}{' '}
        </span>
        {!omitLoader && (
            <span data-testid={TestIDs.value} className={styles.nowrap}>
                {children ? children : <Spinner />}
            </span>
        )}
    </span>
);

export default DisplayItem;
