/* eslint-disable react/display-name */
import { AttributeEditorProps } from '@amzn/awsui-components-react/polaris/attribute-editor';
import {
    FormikField,
    InputField,
    SelectField,
} from '@amzn/event-engine-js-utils';
import React from 'react';

import { ROLE_OPTIONS } from './../utils/constants';
import { MergedFormSchema, Step } from './CreateEventForm.validation';

export const getCreateEventFormFieldName = (
    str: keyof MergedFormSchema[Step.EVENT_DETAILS]
) => {
    return `${[
        Step.EVENT_DETAILS,
    ]}.${str}` as keyof MergedFormSchema[Step.EVENT_DETAILS];
};

export const getFacilitatorsTagsDefinition = (
    containsPrefix = true
): AttributeEditorProps.FieldDefinition<{
    key: string | undefined;
    value: string | undefined;
}>[] => {
    return [
        {
            label: 'Email',
            control: (_: any, index: number) => {
                const baseFormName = `facilitators.${index}.key` as any;
                const formName = containsPrefix
                    ? getCreateEventFormFieldName(baseFormName)
                    : baseFormName;

                return (
                    <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                        name={formName}
                        formFieldProps={{
                            stretch: true,
                            children: (
                                <InputField<
                                    MergedFormSchema[Step.EVENT_DETAILS]
                                >
                                    name={formName}
                                    placeholder="Email"
                                    inputProps={{
                                        type: 'text',
                                    }}
                                />
                            ),
                        }}
                    />
                );
            },
        },
        {
            label: 'Role',
            control: (_: any, index: number) => {
                const baseFormName = `facilitators.${index}.value` as any;
                const formName = containsPrefix
                    ? getCreateEventFormFieldName(baseFormName)
                    : baseFormName;

                return (
                    <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                        name={formName}
                        formFieldProps={{
                            stretch: true,
                            children: (
                                <SelectField<
                                    MergedFormSchema[Step.EVENT_DETAILS]
                                >
                                    name={formName}
                                    placeholder="Specify role"
                                    selectProps={{
                                        options: ROLE_OPTIONS,
                                    }}
                                />
                            ),
                        }}
                    />
                );
            },
        },
    ];
};
