import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { EventMatrix, Timeline } from '@amzn/event-engine-js-utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { i18nStrings } from '../../../../../constants';
import {
    ObserverHandler,
    TeamsPollerContext,
} from '../../contexts/TeamsPollerProvider';
import { TimelineFooters } from './constants';
import {
    stepEvent,
    stepProvisioning,
    stepScheduled,
    stepTermination,
} from './EventTimelineStep';
import { EventTimelineProps, TimelineStep, TimelineStepID } from './types';
import getActiveStepID from './utils/get-active-step-id';

const DATA_POLLER_ID = 'eventTimeline';

const EventTimeline = ({ event, eventTransition }: EventTimelineProps) => {
    const { subscribe, unsubscribe, refresh } = useContext(TeamsPollerContext);
    const [teams, setTeams] = useState<EventEngineEventsService.TeamList>();
    const accountSource = event.accountSource;
    const footer =
        TimelineFooters[accountSource]?.[event.stateRuntime] ||
        TimelineFooters[accountSource]?.[event.stateInfrastructure];
    const [activeStepId, setActiveStepId] = useState<{ value?: string }>({});
    const lastInfrastructureState = useRef<
        EventEngineEventsService.EventStateInfrastructure
    >();
    const isSubscribed = useRef(false);
    const lastActiveStepId = useRef<TimelineStepID>();
    const steps = useMemo(
        () =>
            [
                stepScheduled(event, eventTransition),
                stepProvisioning(event, eventTransition, teams),
                stepEvent(event, eventTransition),
                stepTermination(event, eventTransition),
            ].filter((step) => step) as TimelineStep[],
        [event, eventTransition, teams]
    );
    const teamsPollHandler: ObserverHandler = (teams) => {
        setTeams(teams);
    };

    useEffect(() => {
        const nextActiveStepId = getActiveStepID(event, eventTransition);

        if (lastActiveStepId.current === nextActiveStepId) {
            return;
        }

        setActiveStepId({
            value: nextActiveStepId,
        });
        lastActiveStepId.current = nextActiveStepId;
    }, [event, eventTransition]);

    useEffect(() => {
        const eventStates = {
            runtime: event.stateRuntime,
            infrastructure: event.stateInfrastructure,
        };

        if (
            EventMatrix.isEventActive(eventStates) &&
            EventMatrix.isProvisionStarted(eventStates)
        ) {
            if (!isSubscribed.current) {
                subscribe(DATA_POLLER_ID, teamsPollHandler);
                isSubscribed.current = true;
            }
        } else {
            unsubscribe(DATA_POLLER_ID);
            isSubscribed.current = false;
        }

        if (
            lastInfrastructureState.current &&
            lastInfrastructureState.current !== event.stateInfrastructure &&
            EventMatrix.isDeploymentResolved(eventStates)
        ) {
            refresh(DATA_POLLER_ID, true);
        }

        lastInfrastructureState.current = event.stateInfrastructure;
    }, [event]);

    return (
        <Timeline
            activeStep={activeStepId.value}
            header={
                <Header variant="h2">
                    {i18nStrings.events.eventDetails.timeline.title}
                </Header>
            }
            variant="container"
            steps={steps}
            footer={
                footer && (
                    <ExpandableSection
                        headerText={footer.header}
                        variant="footer">
                        {footer.content}
                    </ExpandableSection>
                )
            }
        />
    );
};

export * from './types';
export default EventTimeline;
