import { IMPACT_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import i18nStrings from 'constants/i18n-strings';

import { StatusConfig } from './types';

export const STATUS_MAP: Record<IMPACT_TYPES, StatusConfig> = {
    [IMPACT_TYPES.BLOCKED]: {
        label: i18nStrings.eventReadinessStatus.blocked,
        colorOverride: 'red',
        type: 'stopped',
    },
    [IMPACT_TYPES.READY]: {
        label: i18nStrings.eventReadinessStatus.ready,
        type: 'success',
    },
    [IMPACT_TYPES.RESTRICTED]: {
        label: i18nStrings.eventReadinessStatus.restricted,
        type: 'warning',
    },
};

export const UNKNOWN_STATE: StatusConfig = {
    type: 'stopped',
};
