import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { EMAIL_PATTERN_FIELD_DELIMITER } from '../constants';

const getEmailPatterns = (
    value?: EventEngineEventsService.EventAllowlist
): string =>
    value
        ? value
              .map(({ pattern }) => pattern)
              .join(EMAIL_PATTERN_FIELD_DELIMITER)
        : '';

export default {
    getEmailPatterns,
};
