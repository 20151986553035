import { facilitatorsSchema } from 'pages/Events/CreateEvent/components/CreateEventForm.validation';
import * as Yup from 'yup';

import { FieldName } from './types';

const FormSchema = Yup.object({
    [FieldName.FACILITATORS]: facilitatorsSchema,
});

export type FormSchemaType = Yup.InferType<typeof FormSchema>;

export default FormSchema;
