import { isValid, parse } from 'date-fns';
import { zonedTimeToUtc as dateFnsZonedTimeToUtc } from 'date-fns-tz';

import { DATE_FORMATS } from './constants';

interface ZonedTimeToUTCProps {
    format: string;
    timezone: string;
}

/**
 * Wrapper around date-fns zonedTimeToUtc.
 * Useful for constructing a UTC Date from date values obtained
 * from form fields.
 * @param {string} date
 * @param {string} time
 * @param {string|undefined} period
 * @param {ZonedTimeToUTCProps|undefined} options
 * @returns {Date|undefined}
 */
const zonedTimeToUtc = (
    date: string,
    time: string,
    period?: string,
    options?: Partial<ZonedTimeToUTCProps>
) => {
    const parsedDate = parse(
        `${date} ${time}${period ? ` ${period}` : ''}`,
        options?.format ||
            (period
                ? DATE_FORMATS.TWELVE_HOUR_STANDARD
                : DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD),
        new Date()
    );
    if (!isValid(parsedDate)) {
        return;
    }
    return dateFnsZonedTimeToUtc(parsedDate, options?.timezone || '');
};

export default zonedTimeToUtc;
