import {
    DarkModeProvider,
    DebugMenu,
    Logger,
    LogLevel,
    PollEngine,
} from '@amzn/event-engine-js-utils';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import AuthGuard from 'components/AuthGuard';
import { createBrowserHistory } from 'history';
import SignInCallback from 'pages/SignInCallback';
import React, { createContext, FC, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AppSettings, { ISettings } from 'services/app-settings';

import AuthenticatedProviders from './AuthenticatedProviders';
import AuthProvider from './AuthProvider';
import GuardrailsProvider from './GuardrailsProvider';
import HistoryProvider from './HistoryProvider';
import NotificationsProvider from './NotificationsProvider';
/**
 * disabling for now since the backend infra to support this has
 * been torn down. Will be revisiting this when we scope the analytics epic.
 */
// import UnauthenticatedProviders from './UnauthenticatedProviders';

PollEngine.setLogger(Logger.getLogger('PollEngine', LogLevel.VERBOSE));

interface AppContextValue {
    settings: ISettings;
}

export const AppContext = createContext({} as AppContextValue);

const history = createBrowserHistory();

const AppProviders: FC = ({ children }) => {
    const [appSettings, setAppSettings] = useState<ISettings>();

    useEffect(() => {
        AppSettings().then(setAppSettings);
    }, []);

    if (!appSettings) {
        return null;
    }

    return (
        <RecoilRoot>
            <Router history={history}>
                <AppContext.Provider
                    value={{
                        settings: appSettings,
                    }}>
                    <NotificationsProvider>
                        <AuthProvider>
                            <AuthGuard>
                                <HistoryProvider>
                                    <DarkModeProvider>
                                        <DebugMenu
                                            gitInformation={{
                                                sha:
                                                    process.env
                                                        .REACT_APP_GIT_SHA,
                                                branch:
                                                    process.env
                                                        .REACT_APP_GIT_BRANCH,
                                            }}
                                        />
                                        {/* <UnauthenticatedProviders> */}
                                        <AuthenticatedProviders>
                                            <GuardrailsProvider>
                                                <HelmetProvider>
                                                    {children}
                                                </HelmetProvider>
                                            </GuardrailsProvider>
                                        </AuthenticatedProviders>
                                        {/* </UnauthenticatedProviders> */}
                                    </DarkModeProvider>
                                </HistoryProvider>
                            </AuthGuard>
                        </AuthProvider>
                        <Route
                            path={`${routes.callback}/${routeTokens.login}`}
                            exact>
                            <SignInCallback />
                        </Route>
                    </NotificationsProvider>
                </AppContext.Provider>
            </Router>
        </RecoilRoot>
    );
};

export default AppProviders;
