import { usePrevious } from '@amzn/event-engine-js-utils';
import { Location } from 'history';
import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';

interface IHistoryContext {
    previousLocation: Location<unknown> | undefined;
}

export const HistoryContext = createContext<IHistoryContext>({
    previousLocation: undefined,
});

/**
 * Unfortunately, react-router-dom does not provide us with the
 * list of locations the user has navigated to. Use the usePrevious
 * hook to keep track of the last location the user has been to.
 */
const HistoryProvider = ({
    children,
}: React.PropsWithChildren<React.ReactNode>) => {
    const location = useLocation();

    const previousLocation = usePrevious<Location<unknown>>(location, location);

    return (
        <HistoryContext.Provider value={{ previousLocation }}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistoryProviderContext = () => React.useContext(HistoryContext);

export default HistoryProvider;
