import { CONTENT_STATE_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Popover, {
    PopoverProps,
} from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useTable } from '@amzn/event-engine-js-utils';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import InfoLink from 'components/InfoLink';
import i18nStrings from 'constants/i18n-strings';
import useCreateEvent from 'pages/Events/CreateEvent/hooks/use-create-event';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ErrorMessage from 'services/error-message';

import ViewPublishedBuildBtn from '../ViewPublishedBuildBtn';
import WorkshopDetailsCard from '../WorkshopDetailsCard';
import { columnDefinitionMap, filteringPropertyMap } from './config';
import { WorkshopCatalogTableContext } from './contexts/WorkshopCatalogTableProvider';
import useWorkshopDetailsLink from './hooks/use-workshop-details-link';
import {
    DetailLinkBehavior,
    TableAction,
    WorkshopCatalogTableItem,
    WorkshopCatalogTableProps,
} from './types';
import processData from './utils/process-data';

const BASE_POPOVER_PROPS: PopoverProps = {
    size: 'small',
    triggerType: 'custom',
    dismissButton: false,
};

// shared key for all workshop catalog views
const storageDedupeKey = 'v1';

const WorkshopCatalogTable = ({
    id,
    columnDefinitions,
    actions: enabledActions,
    collectionSelectFilters,
    basePath = location.pathname,
    data,
    title,
    description,
    toolsContent,
    refetch,
    loading,
    error,
    onSelectionChange,
    selectedItems: initialSelectedItems,
    detailLinkBehavior = DetailLinkBehavior.NAVIGATION,
    visibleContent,
}: WorkshopCatalogTableProps) => {
    const history = useHistory();
    const { openDetails: openWorkshopDetails } = useWorkshopDetailsLink();
    const {
        currentDetails,
        setCurrentDetails,
        setBasePath,
        setDetailLinkBehavior,
    } = useContext(WorkshopCatalogTableContext);
    // casting initialSelectedItems as WorkshopCatalogTableItem is ok here since selection is tracked by contentId
    const [selectedItems, setSelectedItems] = useState(
        (initialSelectedItems || []) as WorkshopCatalogTableItem[]
    );
    const items = useMemo(() => data && processData(data), [data]);
    const selectedItem = useMemo(
        () =>
            selectedItems.length
                ? items?.find(
                      ({ contentId }) =>
                          contentId === selectedItems[0].contentId
                  )
                : undefined,
        [items, selectedItems]
    );
    const { actionButton: createEventAction } = useCreateEvent({
        contentId: selectedItem?.contentId,
    });
    const handleSelectionChange = useCallback(
        (selectedItems: WorkshopCatalogTableItem[]) => {
            // map selection with latest data
            const latestContentData: ContentData[] = [];

            data &&
                selectedItems.forEach((selectedItem) => {
                    const c = data.find(
                        ({ contentId }) => contentId === selectedItem.contentId
                    );

                    c && latestContentData.push(c);
                });

            setSelectedItems(selectedItems);
            onSelectionChange?.(latestContentData);
        },
        [data]
    );
    const openDetail = useCallback(() => {
        if (!selectedItem) {
            return;
        }

        openWorkshopDetails(selectedItem);
    }, [selectedItem]);
    const editItem = useCallback(() => {
        if (!selectedItem) {
            return;
        }

        history.push(
            `${basePath}/${routeTokens.edit}/${selectedItem.contentId}`
        );
    }, [selectedItem]);
    const editWorkshopAction = useMemo(() => {
        const disabledPopoverContent =
            selectedItem?.state === CONTENT_STATE_TYPES.SCHEDULED_FOR_DELETE
                ? i18nStrings.workshops.popovers
                      .scheduledForDeletionEditWorkshop
                : '';
        const action = (
            <Button
                key={TableAction.EDIT_WORKSHOP}
                onClick={editItem}
                disabled={!selectedItem || !!disabledPopoverContent}>
                {i18nStrings.edit}
            </Button>
        );

        return disabledPopoverContent ? (
            <Popover
                {...BASE_POPOVER_PROPS}
                content={disabledPopoverContent}
                key={`${TableAction.EDIT_WORKSHOP}_popover`}>
                {action}
            </Popover>
        ) : (
            action
        );
    }, [editItem, selectedItem]);
    const { component: tableComponent } = useTable(items, {
        id,
        storageDedupeKey,
        info: toolsContent && <InfoLink toolsContent={toolsContent} />,
        onRefresh: refetch,
        loading,
        error,
        actions: [
            <Button
                key={TableAction.VIEW_WORKSHOP_DETAILS}
                onClick={openDetail}
                disabled={!selectedItem}>
                {i18nStrings.workshops.workshopCatalog.table.actions.details}
            </Button>,
            enabledActions?.includes(TableAction.CREATE_EVENT) &&
                createEventAction,
            enabledActions?.includes(TableAction.EDIT_WORKSHOP) &&
                editWorkshopAction,
            enabledActions?.includes(TableAction.CREATE_WORKSHOP) && (
                <Button
                    key={TableAction.CREATE_WORKSHOP}
                    data-testid="create-workshop-button"
                    onClick={() =>
                        history.push(
                            `${routes.workshops}/${routeTokens.create}`
                        )
                    }
                    variant="primary">
                    {i18nStrings.workshops.workshopCatalog.table.actions.create}
                </Button>
            ),
        ],
        i18nStrings: {
            title: title || i18nStrings.workshops.workshopCatalog.title,
            description,
            loadingText: i18nStrings.loading,
            preferences: {
                title: i18nStrings.preferences,
                confirmLabel: i18nStrings.confirm,
                cancelLabel: i18nStrings.cancel,
                pageSize: {
                    optionSuffix: i18nStrings.item.plural,
                    title: i18nStrings.pageSize,
                },
                visibleContent: {
                    title: i18nStrings.table.selectVisibleColumns,
                    optionsLabel: i18nStrings.table.properties,
                },
                wrapLines: {
                    label: i18nStrings.wrapLines,
                    description: i18nStrings.wrapLinesDescription,
                },
                contentDensity:
                    i18nStrings.collectionPreferences.contentDensity,
                stickyColumns: i18nStrings.collectionPreferences.stickyColumns,
            },
            filter: i18nStrings.propertyFilter,
            filteredItemsCountSuffix: i18nStrings.item,
            errorMessage: {
                title:
                    i18nStrings.workshops.workshopCatalog.table.stateError
                        .title,
                description: error && ErrorMessage.getMessage(error),
            },
            emptyMessage:
                i18nStrings.workshops.workshopCatalog.table.stateEmpty,
            noMatchMessage: {
                title: i18nStrings.table.noMatches,
                description: i18nStrings.table.noMatchesDescription,
                clearAllLabel: i18nStrings.table.clearAllFilters,
            },
        },
        filteringProperties: columnDefinitions.reduce(
            (acc: ExtendedFilteringProperty[], columnId) => {
                const filteringProperty = filteringPropertyMap[columnId];
                return filteringProperty ? [...acc, filteringProperty] : acc;
            },
            []
        ),
        collectionSelectFilters,
        columnDefinitions: columnDefinitions.map(
            (columnId) => columnDefinitionMap[columnId]
        ),
        visibleContent,
        trackBy: 'contentId',
        resizableColumns: true,
        selectionType: 'single',
        onSelectionChange: handleSelectionChange,
        selectedItems,
    });

    useEffect(() => {
        setBasePath(basePath);
        setDetailLinkBehavior(detailLinkBehavior);
    }, []);

    return (
        <>
            {tableComponent}
            {detailLinkBehavior === DetailLinkBehavior.MODAL && currentDetails && (
                <Modal
                    onDismiss={() => setCurrentDetails(undefined)}
                    visible={Boolean(currentDetails)}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    onClick={() => setCurrentDetails(undefined)}
                                    variant="link">
                                    {i18nStrings.close}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                    closeAriaLabel="Close workshop details"
                    header={
                        i18nStrings.workshops.workshopCatalog.detailsModal.title
                    }>
                    <SpaceBetween direction="vertical" size="l">
                        <Header
                            description={currentDetails.description}
                            actions={
                                <ViewPublishedBuildBtn
                                    content={currentDetails}
                                />
                            }
                            variant="h3">
                            {currentDetails.title}
                        </Header>
                        <WorkshopDetailsCard
                            data={currentDetails}
                            variant="inline"
                            type="public"
                        />
                    </SpaceBetween>
                </Modal>
            )}
        </>
    );
};

export default WorkshopCatalogTable;
