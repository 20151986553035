import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

export interface TeamDetailsLinkProps {
    eventId: string;
    teamId: string;
}

const TeamDetailsLink: FC<TeamDetailsLinkProps> = ({
    eventId,
    teamId,
    children,
}) => {
    const href = `${routes.events}/${eventId}/${routeTokens.teams}/${teamId}`;
    const history = useHistory();
    const onFollow: LinkProps['onFollow'] = (e) => {
        e.preventDefault();
        history.push(href);
    };

    return (
        <Link href={href} onFollow={onFollow}>
            {children}
        </Link>
    );
};

export default TeamDetailsLink;
