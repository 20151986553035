import {
    QUOTA_ID,
    SEND_QUOTA_OPERATION,
    SEND_QUOTA_TARGET,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Form from '@amzn/awsui-components-react/polaris/form';
import { InputProps } from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    formatString,
    FormikField,
    InputField,
    TileField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import EEEvents from 'services/ee-events';
import { MAX_TRANSFERABLE_QUOTA } from 'utils/config';

import { i18nStrings } from '../../../../constants';
import { SEND_QUOTA_OPERATION_OPTIONS } from './constants';
import Schema, { FieldName, SchemaType } from './schema';

export interface TransferAccountQuotaModalProps {
    onDismiss: () => void;
    onSuccess?: () => void;
    operation?: EventEngineEventsService.SendQuotaOperation;
    visible?: boolean;
}

const TransferAccountQuotaModal = ({
    onDismiss,
    onSuccess,
    operation,
    visible = false,
}: TransferAccountQuotaModalProps) => {
    const quantityInput = useRef<InputProps.Ref>();
    const { showNotification } = useContext(NotificationsContext);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [
        sendQuotaAllocation,
        {
            data: sendQuotaAllocationResponse,
            isLoading: sendQuotaAllocationLoading,
            isError: sendQuotaAllocationError,
        },
    ] = useLazyRequest<typeof EEEvents.sendQuotaAllocation, AWSError>(
        EEEvents.sendQuotaAllocation,
        {}
    );
    const formik = useFormik({
        validationSchema: Schema,
        validateOnChange,
        validateOnBlur: false,
        initialValues: {
            [FieldName.OPERATION]:
                operation || SEND_QUOTA_OPERATION_OPTIONS[0].value,
        },
        onSubmit: async (values) => {
            const castValues = Schema.cast(values);
            const operation = castValues[FieldName.OPERATION];
            const quantity = castValues[FieldName.QUANTITY];

            if (!(operation && quantity)) {
                return;
            }

            await sendQuotaAllocation({
                operation,
                quantity,
                quotaId: QUOTA_ID.ACCOUNTS_WORKSHOP_STUDIO,
                target: SEND_QUOTA_TARGET.EVENT_ENGINE_CLASSIC,
            });
        },
    });
    const { modalComponent } = useModal({
        isVisible: visible,
        onDismiss,
        modalHeader: i18nStrings.myQuotas.eventQuota.actions.transferQuota,
        actions: {
            tertiary: {
                onClick: onDismiss,
                text: i18nStrings.cancel,
            },
            primary: {
                onClick: () => {
                    formik.handleSubmit();
                    setValidateOnChange(true);
                },
                text:
                    i18nStrings.myQuotas.eventQuota.transferQuotaModal
                        .actionPrimary,
                loading: sendQuotaAllocationLoading,
                disabled: !(formik.isValid && formik.dirty),
            },
        },
        content: (
            <Form
                errorText={
                    sendQuotaAllocationError?.message
                        ? formatString(
                              i18nStrings.myQuotas.eventQuota.transferQuotaModal
                                  .alerts.error,
                              sendQuotaAllocationError?.message
                          )
                        : undefined
                }>
                <FormikProvider value={formik}>
                    <SpaceBetween direction="vertical" size="l">
                        <FormikField<SchemaType>
                            name={FieldName.OPERATION}
                            formFieldProps={{
                                stretch: true,
                                children: (
                                    <TileField<SchemaType>
                                        name={FieldName.OPERATION}
                                        tileProps={{
                                            items: SEND_QUOTA_OPERATION_OPTIONS,
                                        }}
                                    />
                                ),
                            }}
                        />
                        <FormikField<SchemaType>
                            name={FieldName.QUANTITY}
                            dataTestId={FieldName.QUANTITY}
                            formFieldProps={{
                                label:
                                    i18nStrings.myQuotas.eventQuota
                                        .transferQuotaModal.fields.quantity
                                        .label,
                                description:
                                    i18nStrings.myQuotas.eventQuota
                                        .transferQuotaModal.alerts.info,
                                constraintText: formatString(
                                    i18nStrings.myQuotas.eventQuota
                                        .transferQuotaModal.fields.quantity
                                        .constraintMax,
                                    MAX_TRANSFERABLE_QUOTA
                                ),
                                children: (
                                    <InputField<SchemaType>
                                        name={FieldName.QUANTITY}
                                        placeholder={
                                            i18nStrings.myQuotas.eventQuota
                                                .transferQuotaModal.fields
                                                .quantity.placeholder
                                        }
                                        inputProps={{
                                            type: 'number',
                                            inputMode: 'numeric',
                                            readOnly: sendQuotaAllocationLoading,
                                            // @ts-ignore
                                            ref: quantityInput,
                                        }}
                                    />
                                ),
                            }}
                        />
                    </SpaceBetween>
                </FormikProvider>
            </Form>
        ),
    });

    useEffect(() => {
        if (!sendQuotaAllocationResponse) {
            return;
        }

        const notificationMessageTemplates =
            i18nStrings.myQuotas.eventQuota.transferQuotaModal.notifications;
        const formValues = Schema.cast(formik.values);
        const requestedQuantity = formValues[FieldName.QUANTITY] || 0;
        const actualQuantityMoved = Math.abs(
            sendQuotaAllocationResponse.quantityMoved
        );
        let message = '';

        if (formik.values[FieldName.OPERATION] === SEND_QUOTA_OPERATION.GIVE) {
            message =
                actualQuantityMoved > 0
                    ? notificationMessageTemplates.successTransferToEE
                    : notificationMessageTemplates.successTransferToEEEmptyQuantity;
        } else {
            message =
                actualQuantityMoved > 0
                    ? notificationMessageTemplates.successTransferFromEE
                    : notificationMessageTemplates.successTransferFromEEEmptyQuantity;
        }

        message = formatString(message, actualQuantityMoved);

        if (
            actualQuantityMoved > 0 &&
            actualQuantityMoved < requestedQuantity
        ) {
            message += ` ${formatString(
                notificationMessageTemplates.successTransferReducedQuantity,
                requestedQuantity
            )}`;
        }

        showNotification?.(message, {
            type: actualQuantityMoved > 0 ? 'success' : 'info',
        });

        onDismiss();
        onSuccess?.();
    }, [sendQuotaAllocationResponse]);

    useEffect(() => {
        visible &&
            setTimeout(() => {
                // If an operation was selected prior to opening the modal,
                // auto-focus the quantity input.
                operation && quantityInput.current?.focus();
            });
    }, [visible, operation]);

    return modalComponent;
};

export default TransferAccountQuotaModal;
