import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { Constants } from '@amzn/event-engine-js-utils';
import { useMetric, useModal } from 'hooks';
import React, { FC, useCallback } from 'react';

import { CloudWatchMetric, i18nStrings } from './../../constants';

export interface SupportRequestModalProps {
    isVisible: boolean;
    onModalClosed: () => void;
    onComplete?: () => void;
}

const SupportRequestModal: FC<SupportRequestModalProps> = ({
    isVisible,
    onModalClosed,
    onComplete,
}: SupportRequestModalProps) => {
    const { addCounterMetric } = useMetric();

    const closeModal = () => {
        hideModal();
        onModalClosed();
    };

    const onPrimaryActionClick = useCallback(() => {
        window.open(
            Constants.ExternalLinks.submitSupportRequest,
            '_blank',
            'noopener,noreferrer'
        );
        addCounterMetric(CloudWatchMetric.SUPPORT_REQUEST_WORKSHOP_STUDIO);
        onComplete?.();
    }, []);

    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);

    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: onModalClosed,
        modalHeader: i18nStrings.supportRequestModal.modalTitle,
        actions: {
            primary: {
                text: i18nStrings.supportRequestModal.primaryActionText,
                onClick: onPrimaryActionClick,
                iconAlign: 'right',
                iconName: 'external',
            },
            tertiary: {
                text: i18nStrings.supportRequestModal.secondaryActionText,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <section>
                <SpaceBetween size="l">
                    <Alert type="warning">
                        {i18nStrings.supportRequestModal.modalAlert}
                    </Alert>
                    <Box variant="p">
                        {
                            i18nStrings.supportRequestModal
                                .modalBodyMainDescription
                        }
                    </Box>
                    <Box variant="p">
                        {i18nStrings.supportRequestModal.modalBodyMainSubtext}
                    </Box>
                </SpaceBetween>
            </section>
        ),
    });

    return modalComponent;
};

export default SupportRequestModal;
