/* istanbul ignore file */
import { CONTENT_STATE_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import { ContentStateTypes } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import AccountSourceStatus from 'components/AccountSourceStatus';
import EventReadinessStatus from 'components/EventReadinessStatus';
import React from 'react';
import getDisplayValue from 'utils/get-display-value';

import { complexityLevels } from '../../../../constants';
import { getLastPublishedAt } from '../../utils';
import WorkshopStateStatus from '../WorkshopStateStatus';
import useWorkshopDetailsLink from './hooks/use-workshop-details-link';
import { WorkshopCatalogTableItem } from './types';

interface WorkshopDetailsLinkProps<T> {
    item: T;
}

const WorkshopDetailsLink = ({
    item,
}: WorkshopDetailsLinkProps<WorkshopCatalogTableItem>) => {
    const {
        getHref,
        openDetails: openWorkshopDetails,
    } = useWorkshopDetailsLink();
    const href = getHref(item);
    const openDetail: LinkProps['onFollow'] = (e) => {
        e.preventDefault();
        openWorkshopDetails(item);
    };

    return (
        <Link
            href={href}
            ariaLabel={`Open details for workshop ${item.title}`}
            onFollow={openDetail}>
            {item.title}
        </Link>
    );
};

const getWorkshopDetailsLink = (item: WorkshopCatalogTableItem) => (
    <WorkshopDetailsLink {...{ item }} />
);

/**
 * Custom ordering of content state types
 */
export const CONTENT_STATE_TYPE_ORDER: Record<ContentStateTypes, number> = {
    [CONTENT_STATE_TYPES.REPLICA_READONLY]: -1,
    [CONTENT_STATE_TYPES.CREATE_IN_PROGRESS]: 0,
    [CONTENT_STATE_TYPES.CREATE_COMPLETE]: 1,
    [CONTENT_STATE_TYPES.CREATE_FAILED]: 2,
    [CONTENT_STATE_TYPES.SCHEDULED_FOR_DELETE]: 3,
};

const getWorkshopState = (item: WorkshopCatalogTableItem) => (
    <WorkshopStateStatus state={item.state} />
);

const AccountSourceDefinedValue = ({
    publishedBuildId,
    contentSpecSummary,
}: WorkshopCatalogTableItem) =>
    publishedBuildId ? (
        <AccountSourceStatus value={contentSpecSummary?.accountSources} />
    ) : (
        getDisplayValue()
    );

const EventReadinessStateValue = ({
    eventReadiness,
}: WorkshopCatalogTableItem) => <EventReadinessStatus value={eventReadiness} />;

export enum ColumnId {
    TITLE = 'title',
    PUBLISH_TYPE = 'published_type',
    STATE = 'state',
    TOPICS = 'topics',
    LEVEL = 'level',
    DURATION = 'duration',
    AWS_SERVICES = 'aws_services',
    PRIMARY_OWNER = 'primary_owner',
    INDUSTRY_VERTICAL = 'industry_vertical',
    ENGAGEMENT_MODEL = 'engagement_model',
    EVENT_READINESS_STATE = 'event_readiness_state',
    IS_ACCOUNT_SOURCE_DEFINED = 'is_account_source_defined',
    LAST_MODIFIED = 'last_modified',
    LAST_PUBLISHED = 'last_published',
    CONTENT_ID = 'contentId',
}

export const DEFAULT_PAGINATION_LIMIT = 500;

type ColumnFieldMap = Record<ColumnId, keyof WorkshopCatalogTableItem>;

export const columnFieldMap: ColumnFieldMap = {
    [ColumnId.TITLE]: 'title',
    [ColumnId.PUBLISH_TYPE]: 'publishedType',
    [ColumnId.STATE]: 'state',
    [ColumnId.TOPICS]: 'attributeTopics',
    [ColumnId.LEVEL]: 'attributeComplexityLevel',
    [ColumnId.DURATION]: 'attributeRecommendedDuration',
    [ColumnId.AWS_SERVICES]: 'attributeAWSServices',
    [ColumnId.PRIMARY_OWNER]: 'primaryOwner',
    [ColumnId.INDUSTRY_VERTICAL]: 'attributeIndustryVerticals',
    [ColumnId.ENGAGEMENT_MODEL]: 'attributeEngagementModels',
    [ColumnId.IS_ACCOUNT_SOURCE_DEFINED]: 'isAccountSourceDefinedDisplay',
    [ColumnId.EVENT_READINESS_STATE]: 'eventReadinessDisplayState',
    [ColumnId.LAST_MODIFIED]: 'lastUpdatedAt',
    [ColumnId.LAST_PUBLISHED]: 'lastPublishedAt',
    [ColumnId.CONTENT_ID]: 'contentId',
};

interface ColumnDefinition
    extends TableProps.ColumnDefinition<WorkshopCatalogTableItem>,
        Partial<CollectionPreferencesProps.VisibleContentOption> {}

export const columnDefinitionMap: Record<ColumnId, ColumnDefinition> = {
    [ColumnId.TITLE]: {
        id: ColumnId.TITLE,
        header: 'Title',
        cell: getWorkshopDetailsLink,
        sortingField: columnFieldMap[ColumnId.TITLE],
        sortingComparator: (a, b) =>
            (a.title?.trim() || '').localeCompare(b.title?.trim() || ''),
        editable: false,
    },
    [ColumnId.PUBLISH_TYPE]: {
        id: ColumnId.PUBLISH_TYPE,
        header: 'Published type',
        sortingField: columnFieldMap[ColumnId.PUBLISH_TYPE],
        sortingComparator: (a, b) => {
            return (a.publishedType?.trim() || '').localeCompare(
                b.publishedType?.trim() || ''
            );
        },
        cell: (item) => getDisplayValue(item.publishedType),
    },
    [ColumnId.STATE]: {
        id: ColumnId.STATE,
        header: 'State',
        cell: getWorkshopState,
        sortingField: columnFieldMap[ColumnId.STATE],
        sortingComparator: (a, b) => {
            const a1State: ContentStateTypes = a.state as ContentStateTypes;
            const a2State: ContentStateTypes = b.state as ContentStateTypes;

            const diff =
                CONTENT_STATE_TYPE_ORDER[a1State] -
                CONTENT_STATE_TYPE_ORDER[a2State];
            return diff === 0 && a2State ? -1 : diff;
        },
    },
    [ColumnId.TOPICS]: {
        id: ColumnId.TOPICS,
        header: 'Topics',
        cell: (item) => getDisplayValue(item.attributeTopics),
    },
    [ColumnId.LEVEL]: {
        id: ColumnId.LEVEL,
        header: 'Complexity level',
        cell: (item) => {
            const level = item.attributeComplexityLevel;

            return getDisplayValue(
                level
                    ? `${level}${
                          complexityLevels[level]
                              ? ` - ${complexityLevels[level]}`
                              : ''
                      }`
                    : null
            );
        },
        sortingField: columnFieldMap[ColumnId.LEVEL],
        sortingComparator: (a, b) =>
            (a.attributeComplexityLevel || '').localeCompare(
                b.attributeComplexityLevel || ''
            ),
    },
    [ColumnId.DURATION]: {
        id: ColumnId.DURATION,
        header: 'Duration (hours)',
        cell: (item) => getDisplayValue(item.attributeRecommendedDuration),
        sortingField: columnFieldMap[ColumnId.DURATION],
        sortingComparator: (a, b) =>
            (a.attributeRecommendedDuration || '').localeCompare(
                b.attributeRecommendedDuration || ''
            ),
    },
    [ColumnId.AWS_SERVICES]: {
        id: ColumnId.AWS_SERVICES,
        header: 'AWS services',
        cell: (item) => getDisplayValue(item.attributeAWSServices),
    },
    [ColumnId.PRIMARY_OWNER]: {
        id: ColumnId.PRIMARY_OWNER,
        header: 'Primary owner',
        cell: (item) => item.primaryOwner,
        sortingField: columnFieldMap[ColumnId.PRIMARY_OWNER],
        sortingComparator: (a, b) =>
            (a.primaryOwner || '').localeCompare(b.primaryOwner || ''),
    },
    [ColumnId.INDUSTRY_VERTICAL]: {
        id: ColumnId.INDUSTRY_VERTICAL,
        header: 'Industry vertical',
        cell: (item) => getDisplayValue(item.attributeIndustryVerticals),
    },
    [ColumnId.ENGAGEMENT_MODEL]: {
        id: ColumnId.ENGAGEMENT_MODEL,
        header: 'Engagement type',
        cell: (item) => getDisplayValue(item.attributeEngagementModels),
    },
    [ColumnId.IS_ACCOUNT_SOURCE_DEFINED]: {
        id: ColumnId.IS_ACCOUNT_SOURCE_DEFINED,
        header: 'Account source defined',
        cell: AccountSourceDefinedValue,
        sortingField: columnFieldMap[ColumnId.IS_ACCOUNT_SOURCE_DEFINED],
    },
    [ColumnId.EVENT_READINESS_STATE]: {
        id: ColumnId.EVENT_READINESS_STATE,
        header: 'Event readiness',
        cell: EventReadinessStateValue,
        sortingField: columnFieldMap[ColumnId.EVENT_READINESS_STATE],
    },
    [ColumnId.LAST_MODIFIED]: {
        id: ColumnId.LAST_MODIFIED,
        header: 'Last modified',
        cell: (item) => getDisplayValue(item.lastUpdatedAt),
        sortingField: columnFieldMap[ColumnId.LAST_MODIFIED],
        sortingComparator: (a, b) =>
            a.lastUpdatedAt.getTime() - b.lastUpdatedAt.getTime(),
    },
    [ColumnId.LAST_PUBLISHED]: {
        id: ColumnId.LAST_PUBLISHED,
        header: 'Last published',
        cell: (item) => getLastPublishedAt(item),
        sortingField: columnFieldMap[ColumnId.LAST_PUBLISHED],
        sortingComparator: (a, b) =>
            (a.lastPublishedAt?.getTime() || 0) -
            (b.lastPublishedAt?.getTime() || 0),
    },
    [ColumnId.CONTENT_ID]: {
        id: ColumnId.CONTENT_ID,
        header: 'Workshop ID',
        cell: (item) => getDisplayValue(item.contentId),
    },
};

export const filteringPropertyMap: Partial<Record<
    ColumnId,
    ExtendedFilteringProperty
>> = {
    [ColumnId.TITLE]: {
        key: columnFieldMap[ColumnId.TITLE],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Title',
        groupValuesLabel: 'Title values',
    },
    [ColumnId.PUBLISH_TYPE]: {
        key: columnFieldMap[ColumnId.PUBLISH_TYPE],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Published type',
        groupValuesLabel: 'Published type values',
        showOptions: true,
    },
    [ColumnId.TOPICS]: {
        key: columnFieldMap[ColumnId.TOPICS],
        defaultOperator: ':',
        operators: [':', '!:'],
        propertyLabel: 'Topic',
        groupValuesLabel: 'Topic values',
        showOptions: true,
    },
    [ColumnId.AWS_SERVICES]: {
        key: columnFieldMap[ColumnId.AWS_SERVICES],
        defaultOperator: ':',
        operators: [':', '!:'],
        propertyLabel: 'AWS service',
        groupValuesLabel: 'AWS service values',
        showOptions: true,
    },
    [ColumnId.LEVEL]: {
        key: columnFieldMap[ColumnId.LEVEL],
        operators: ['<', '<=', '>', '>=', '=', '!='],
        propertyLabel: 'Complexity level',
        groupValuesLabel: 'Complexity level values',
        showOptions: true,
    },
    [ColumnId.DURATION]: {
        key: columnFieldMap[ColumnId.DURATION],
        operators: ['<', '<=', '>', '>=', '=', '!='],
        propertyLabel: 'Duration',
        groupValuesLabel: 'Duration values',
        showOptions: true,
    },
    [ColumnId.INDUSTRY_VERTICAL]: {
        key: columnFieldMap[ColumnId.INDUSTRY_VERTICAL],
        defaultOperator: ':',
        operators: [':', '!:'],
        propertyLabel: 'Industry vertical',
        groupValuesLabel: 'Industry vertical values',
        showOptions: true,
    },
    [ColumnId.ENGAGEMENT_MODEL]: {
        key: columnFieldMap[ColumnId.ENGAGEMENT_MODEL],
        defaultOperator: ':',
        operators: [':', '!:'],
        propertyLabel: 'Engagement type',
        groupValuesLabel: 'Engagement type values',
        showOptions: true,
    },
    [ColumnId.PRIMARY_OWNER]: {
        key: columnFieldMap[ColumnId.PRIMARY_OWNER],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Primary owner',
        groupValuesLabel: 'Primary owner values',
        showOptions: true,
    },
    [ColumnId.CONTENT_ID]: {
        key: columnFieldMap[ColumnId.CONTENT_ID],
        operators: ['=', '!='],
        propertyLabel: 'Workshop ID',
        groupValuesLabel: 'Workshop ID Values',
    },
};
