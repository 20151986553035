import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import React, { useCallback, useState } from 'react';

import TerminateTeamModal from '../../components/TerminateTeamModal';
import { TEAM_TERMINATE_STATES, UseTerminateTeamOptions } from './types';

const useTerminateTeam = ({ onSuccess }: UseTerminateTeamOptions = {}) => {
    const [eventData, setEventData] = useState<
        EventEngineEventsService.EventData
    >();
    const [teamData, setTeamData] = useState<
        EventEngineEventsService.TeamData
    >();
    const isDisabled = useCallback(
        ({ state }: EventEngineEventsService.TeamData) =>
            !TEAM_TERMINATE_STATES.includes(state),
        []
    );
    const showModal = useCallback(
        (
            event: EventEngineEventsService.EventData,
            team: EventEngineEventsService.TeamData
        ) => {
            if (isDisabled(team)) {
                return;
            }

            setEventData(event);
            setTeamData(team);
        },
        []
    );

    return {
        showModal,
        isDisabled,
        modal:
            eventData && teamData ? (
                <TerminateTeamModal
                    event={eventData}
                    team={teamData}
                    isVisible={!!teamData}
                    onDismiss={() => {
                        setEventData(undefined);
                        setTeamData(undefined);
                    }}
                    onUpdateSuccess={() => onSuccess?.()}
                />
            ) : null,
    };
};

export default useTerminateTeam;
