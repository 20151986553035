import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Select, {
    SelectProps,
} from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    countText,
    EmptyMessage,
    getFilteringOptions,
    PropertyFilterConstants,
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import uniqBy from 'lodash.uniqby';
import React, { useEffect, useRef, useState } from 'react';
import ErrorMessage from 'services/error-message';

import { i18nStrings, tableConfig } from '../../../../../constants';
import {
    cardDefinition,
    defaultQuery,
    filteringProperties,
    stackStatusFilterStorageKey,
} from './config';
import styles from './StackNameCards.module.scss';
import { StackNameCardsProps, TestID } from './types';

const StackNameCards = ({
    stacks,
    onSelectionChange,
    error,
    initialSelectedItem,
    loading,
    onRefresh,
}: StackNameCardsProps) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { current: collectionSelectSavedValue } = useRef(
        StoragePartition.getItem(
            StoragePartitionKeys.userPreferences,
            stackStatusFilterStorageKey
        )
    );
    const defaultStackStatusFilterOption: SelectProps.Option = {
        label: i18nStrings.events.teams.stackNameCards.filters.stackStatus.all,
        value: undefined,
    };

    const stackStatusFilterOptions: SelectProps.Option[] = [
        defaultStackStatusFilterOption,
        ...uniqBy(stacks, 'status').map(({ status }) => ({
            label: status,
            value: status,
        })),
    ];
    const [
        stackStatusSelectedFilterOption,
        setStackStatusSelectedFilterOption,
    ] = useState<SelectProps.Option>(
        stackStatusFilterOptions.find(
            (option) => option.value === collectionSelectSavedValue
        ) || stackStatusFilterOptions[0]
    );
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(
        stacks.filter(({ status }) =>
            stackStatusSelectedFilterOption.value
                ? stackStatusSelectedFilterOption.value === status
                : true
        ),
        {
            propertyFiltering: {
                filteringProperties,
                defaultQuery,
                empty: error ? (
                    <EmptyMessage
                        title={
                            i18nStrings.events.teams.stackNameCards.errorTitle
                        }
                        description={ErrorMessage.getMessage(error)}
                    />
                ) : (
                    <EmptyMessage
                        title={
                            i18nStrings.events.teams.stackNameCards.emptyTitle
                        }
                        description={
                            i18nStrings.events.teams.stackNameCards
                                .emptyDescription
                        }
                    />
                ),
                noMatch: (
                    <EmptyMessage
                        title={i18nStrings.table.noMatches}
                        description={i18nStrings.table.noMatchesDescription}
                        actions={
                            <Button
                                onClick={() => {
                                    actions.setPropertyFiltering(defaultQuery);
                                    setStackStatusSelectedFilterOption(
                                        defaultStackStatusFilterOption
                                    );
                                }}>
                                {i18nStrings.table.clearFilters}
                            </Button>
                        }
                    />
                ),
            },
            selection: {
                defaultSelectedItems: initialSelectedItem
                    ? [initialSelectedItem]
                    : undefined,
                trackBy: 'name',
                keepSelection: true,
            },
            pagination: {
                pageSize: tableConfig.defaultPreferences.pageSize,
            },
        }
    );

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.userPreferences,
            stackStatusSelectedFilterOption.value,
            stackStatusFilterStorageKey
        );
    }, [stackStatusSelectedFilterOption]);

    useEffect(() => {
        !loading && setIsRefreshing(false);
    }, [loading]);

    return (
        <div className={styles.stackCards}>
            <Cards
                {...collectionProps}
                header={
                    <Header
                        counter={`(${stacks.length})`}
                        actions={
                            onRefresh ? (
                                <Button
                                    data-testid={TestID.BUTTON_REFRESH}
                                    disabled={isRefreshing}
                                    loading={isRefreshing}
                                    onClick={() => {
                                        setIsRefreshing(true);
                                        onRefresh();
                                    }}
                                    variant="normal"
                                    iconName="refresh"
                                    ariaLabel={i18nStrings.refreshData}
                                />
                            ) : null
                        }>
                        {i18nStrings.events.teams.stackNameCards.title}
                    </Header>
                }
                cardDefinition={cardDefinition}
                cardsPerRow={[{ cards: 1 }]}
                items={items}
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={tableConfig.paginationAriaLabels}
                    />
                }
                filter={
                    <SpaceBetween direction="vertical" size="xs">
                        <PropertyFilter
                            {...propertyFilterProps}
                            i18nStrings={PropertyFilterConstants.i18nStrings}
                            filteringOptions={getFilteringOptions(
                                stacks,
                                filteringProperties
                            )}
                        />
                        <div className={styles.filter}>
                            <Select
                                data-testid={
                                    TestID.COLLECTION_SELECT_STACK_STATUS
                                }
                                selectedOption={stackStatusSelectedFilterOption}
                                options={stackStatusFilterOptions}
                                onChange={({ detail: { selectedOption } }) =>
                                    setStackStatusSelectedFilterOption(
                                        selectedOption
                                    )
                                }
                            />
                            {propertyFilterProps.query.tokens.length ||
                            stackStatusSelectedFilterOption.value ? (
                                <span
                                    className={styles.countText}
                                    data-testid={TestID.FILTER_COUNT_TEXT}>
                                    {countText(
                                        filteredItemsCount || 0,
                                        i18nStrings.item
                                    )}
                                </span>
                            ) : null}
                        </div>
                    </SpaceBetween>
                }
                onSelectionChange={(e) => {
                    onSelectionChange?.(e.detail.selectedItems[0]);
                    collectionProps.onSelectionChange?.(e);
                }}
                loadingText={tableConfig.loadingText}
                loading={isRefreshing}
                selectionType="single"
            />
        </div>
    );
};

export default StackNameCards;
