import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { CodeBlock, ValueWithLabel } from '@amzn/event-engine-js-utils';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import useFeatureFlag from 'hooks/use-feature-flag';
import EventTeamState from 'pages/Events/EventDetails/components/EventTeamState';
import React from 'react';
import getDisplayValue from 'utils/get-display-value';
import getFormattedDuration from 'utils/get-formatted-duration';

import { i18nStrings } from '../../../../../../constants';

export interface TeamDetailsContainerProps {
    event: EventEngineEventsService.EventData;
    team: EventEngineEventsService.TeamData;
}

const TeamDetailsContainer = ({ event, team }: TeamDetailsContainerProps) => {
    // TODO: Remove once teamCodeJoining is generally available
    const teamCodeFeatureEnabled = useFeatureFlag('teamCodeJoining');
    const hasAccounts =
        event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO;
    let failureReason: string | undefined;

    if (hasAccounts && team.stateReason) {
        try {
            failureReason = JSON.stringify(
                JSON.parse(team.stateReason),
                null,
                4
            );
        } catch {
            failureReason = team.stateReason;
        }
    }

    return (
        <Container
            header={
                <Header variant="h2">
                    {i18nStrings.events.teams.detailsContainer.header}
                </Header>
            }
            footer={
                failureReason ? (
                    <ExpandableSection
                        headerText={
                            i18nStrings.events.teams.detailsContainer.values
                                .stateReason
                        }
                        variant="footer"
                        defaultExpanded>
                        <CodeBlock showCopyAction wrapLines>
                            {failureReason}
                        </CodeBlock>
                    </ExpandableSection>
                ) : null
            }>
            <ColumnLayout columns={4} variant="text-grid">
                <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.detailsContainer.values
                                .name
                        }>
                        {team.name}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.detailsContainer.values
                                .teamId
                        }>
                        {getDisplayValue(team.teamId, { copyable: true })}
                    </ValueWithLabel>
                </SpaceBetween>
                {hasAccounts && (
                    <SpaceBetween direction="vertical" size="l">
                        <ValueWithLabel
                            label={
                                i18nStrings.events.teams.detailsContainer.values
                                    .awsAccountID
                            }>
                            {getDisplayValue(team.accountId, {
                                copyable: true,
                            })}
                        </ValueWithLabel>
                    </SpaceBetween>
                )}
                {teamCodeFeatureEnabled && (
                    <SpaceBetween direction="vertical" size="l">
                        <ValueWithLabel
                            label={
                                i18nStrings.events.teams.detailsContainer.values
                                    .teamAccessCode
                            }>
                            {getDisplayValue(team.teamCode, { copyable: true })}
                        </ValueWithLabel>
                    </SpaceBetween>
                )}
                <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.detailsContainer.values
                                .state
                        }>
                        <EventTeamState state={team.state} />
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.teams.detailsContainer.values
                                .deploymentDuration
                        }>
                        {getDisplayValue(
                            team.deploymentBeginAt &&
                                team.deploymentEndAt &&
                                getFormattedDuration(
                                    differenceInMilliseconds(
                                        team.deploymentEndAt,
                                        team.deploymentBeginAt
                                    )
                                )
                        )}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
};

export default TeamDetailsContainer;
