import { PARTICIPANT_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button, {
    ButtonProps,
} from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    CodeBlock,
    EventMatrix,
    formatString,
    useModal,
    ValueWithLabel,
} from '@amzn/event-engine-js-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { getDisplayValue, pluralizeSimple } from 'utils';

import { i18nStrings } from '../../../../../../../../../constants';
import EditAllowlistModal from '../EditAllowlistModal';
import {
    DismissState,
    EditAllowlistModalProps,
} from '../EditAllowlistModal/types';

export interface AllowlistOverviewProps {
    eventData: EventEngineEventsService.EventData;
    participants: EventEngineEventsService.ParticipantList;
    refetchGetEvent: () => Promise<void>;
    onDeniedParticipantStateClick?: () => void;
}

const AllowlistOverview = ({
    eventData,
    participants,
    refetchGetEvent,
    onDeniedParticipantStateClick,
}: AllowlistOverviewProps) => {
    const [
        isEditAllowlistModalVisible,
        setIsEditAllowlistModalVisible,
    ] = useState(false);
    const [isOverflowModalVisible, setIsOverflowModalVisible] = useState(false);
    const handleEditAllowlistModalDismiss = useCallback<
        EditAllowlistModalProps['onDismiss']
    >((state) => {
        setIsEditAllowlistModalVisible(false);

        if (state === DismissState.UPDATE_SUCCESS) {
            refetchGetEvent();
        }
    }, []);
    const handleShowEditAllowlistModal = useCallback(() => {
        setIsEditAllowlistModalVisible(true);
    }, []);
    const handleModalDismiss = useCallback(() => {
        setIsOverflowModalVisible(false);
    }, []);
    const handleOverflowShow = useCallback(() => {
        setIsOverflowModalVisible(true);
    }, []);
    const handleEditClick = useCallback<NonNullable<ButtonProps['onClick']>>(
        (e) => {
            e.stopPropagation();
            handleShowEditAllowlistModal();
        },
        []
    );
    const handleDeniedParticipantStateClick = useCallback(() => {
        onDeniedParticipantStateClick && onDeniedParticipantStateClick();
    }, [onDeniedParticipantStateClick]);
    const deniedParticipantsMessage = useMemo(() => {
        const count = participants.filter(
            (participant) => participant.state === PARTICIPANT_STATE.DENIED
        ).length;

        if (!count) {
            return null;
        }

        return formatString(
            i18nStrings.events.participantAllowlist.deniedParticipants,
            count,
            pluralizeSimple(
                i18nStrings.events.participantAllowlist.participant,
                count
            )
        );
    }, [participants]);
    const emailPatterns = useMemo(
        () => eventData.allowlist?.map(({ pattern }) => pattern),
        [eventData]
    );
    const { modalComponent } = useModal({
        isVisible: isOverflowModalVisible,
        modalHeader: formatString(
            `${i18nStrings.events.participantAllowlist.title} ({0})`,
            emailPatterns?.length
        ),
        onDismiss: handleModalDismiss,
        actions: {
            tertiary: {
                text: i18nStrings.close,
                onClick: handleModalDismiss,
            },
        },
        content: (
            <CodeBlock showCopyAction>{emailPatterns?.join('\n')}</CodeBlock>
        ),
    });

    return (
        <>
            <Container
                header={
                    <Header
                        actions={
                            EventMatrix.isEventActive({
                                runtime: eventData.stateRuntime,
                                infrastructure: eventData.stateInfrastructure,
                            }) ? (
                                <Button
                                    onClick={handleEditClick}
                                    iconName="edit">
                                    {i18nStrings.edit}
                                </Button>
                            ) : null
                        }
                        variant="h2">
                        {i18nStrings.events.participantAllowlist.title}
                    </Header>
                }
                variant="stacked">
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.participantAllowlist.values.type
                        }>
                        {eventData.allowlistAllowAll
                            ? i18nStrings.events.participantAllowlist.types
                                  .allowAll
                            : i18nStrings.events.participantAllowlist.types
                                  .emailList}
                    </ValueWithLabel>
                    {!eventData.allowlistAllowAll ? (
                        <ValueWithLabel
                            label={
                                i18nStrings.events.participantAllowlist.values
                                    .emailPatterns
                            }
                            breakSpaces>
                            {getDisplayValue(emailPatterns, {
                                listDelimiter: ', ',
                                i18nStrings: {
                                    overflowAction:
                                        i18nStrings.events.participantAllowlist
                                            .overflow,
                                },
                                listOverflow: {
                                    maxItems: 3,
                                    onShowMore: handleOverflowShow,
                                },
                            })}
                        </ValueWithLabel>
                    ) : null}
                    <ValueWithLabel
                        label={
                            i18nStrings.events.participantAllowlist.values
                                .deniedParticipants
                        }>
                        {deniedParticipantsMessage ? (
                            <Link onFollow={handleDeniedParticipantStateClick}>
                                <StatusIndicator
                                    type="warning"
                                    colorOverride="grey">
                                    <Box
                                        color="text-status-info"
                                        display="inline">
                                        {deniedParticipantsMessage}
                                    </Box>
                                </StatusIndicator>
                            </Link>
                        ) : (
                            getDisplayValue()
                        )}
                    </ValueWithLabel>
                </ColumnLayout>
            </Container>
            {isEditAllowlistModalVisible && (
                <EditAllowlistModal
                    eventData={eventData}
                    isVisible={isEditAllowlistModalVisible}
                    onDismiss={handleEditAllowlistModalDismiss}
                />
            )}
            {isOverflowModalVisible && modalComponent}
        </>
    );
};

export default AllowlistOverview;
