import Alert from '@amzn/awsui-components-react/polaris/alert';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';

import { i18nStrings } from '../../../../../constants';
import ParticipantAllowlistFields from './ParticipantAllowlist.fields';
import { ParticipantAllowlistContainerProps } from './types';

const ParticipantAllowlistContainer = ({
    requireEmailPatterns,
    stepId,
}: ParticipantAllowlistContainerProps) => (
    <Container
        header={
            <Header>
                {
                    i18nStrings.events.create.wizard.steps.participantAllowlist
                        .title
                }
            </Header>
        }>
        <SpaceBetween direction="vertical" size="l">
            <ParticipantAllowlistFields
                requireEmailPatterns={requireEmailPatterns}
                stepId={stepId}
            />
            <Alert>
                {requireEmailPatterns
                    ? i18nStrings.events.create.wizard.steps
                          .participantAllowlist.alerts.noAllowAllForTestEvent
                    : i18nStrings.events.create.wizard.steps
                          .participantAllowlist.alerts.eventStartPrerequisite}
            </Alert>
        </SpaceBetween>
    </Container>
);

export default ParticipantAllowlistContainer;
