import { i18nStrings } from '../../constants';
import { BooleanValue, StatusConfig } from './types';

export const STATUS_MAP: Record<BooleanValue, StatusConfig> = {
    [BooleanValue.YES]: {
        label: i18nStrings.yes,
        type: 'success',
    },
    [BooleanValue.NO]: {
        label: i18nStrings.no,
        type: 'stopped',
    },
};

export const UNKNOWN_STATE: StatusConfig = {
    type: 'stopped',
};
