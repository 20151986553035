import Alert, { AlertProps } from '@amzn/awsui-components-react/polaris/alert';
import Box, { BoxProps } from '@amzn/awsui-components-react/polaris/box';
import {
    differenceInMilliseconds,
    formatDuration,
    intervalToDuration,
    isBefore,
    isValid,
    milliseconds,
} from 'date-fns';
import React from 'react';
import { MAX_EVENT_DURATION } from 'utils/config';

export const DELIMITER = '__';
export const DEFAULT_MAX_DISTANCE = milliseconds({ hours: MAX_EVENT_DURATION });
export const DEFAULT_TERMINATION_TIME_BUFFER = milliseconds({ minutes: 2 });

export interface TimeDistanceAlertProps {
    /**
     * Event start Date. This value is assumed to be normalized with
     * a specified timezone offset
     */
    start?: Date;
    /**
     * Event end Date.
     */
    end?: Date;
    /**
     * Minimum allowed distance between start and end date in milliseconds.
     * This may not be a strictly enforced minimum since there may be edge
     * cases were the duration is intentionally set to a small number.
     */
    min?: number;
    /**
     * Maximum allowed distance between start and end date in milliseconds.
     * This should be a strictly enforced maximum.
     */
    max?: number;
    /**
     * Whether or not the event has started. This value
     * changes the wording of the alert message
     */
    started?: boolean;
    /**
     * Optional props for wrapper Box
     */
    boxProps?: BoxProps;
}

export const getStringList = (items: string | string[]) => {
    items = typeof items === 'string' ? items.split(DELIMITER) : items;
    return items.reduce(
        (accm, item, index) =>
            `${accm}${items.length > 2 ? ',' : ''} ${
                index === items.length - 1 ? 'and' : ''
            } ${item}`
    );
};

const TimeDistanceAlert = ({
    start,
    end,
    min = DEFAULT_TERMINATION_TIME_BUFFER,
    max = DEFAULT_MAX_DISTANCE,
    started = false,
    boxProps,
}: TimeDistanceAlertProps) => {
    // Show nothing if either dates are invalid
    if (!(start && end) || !(isValid(start) && isValid(end))) {
        return null;
    }

    let message = '';
    let formatDistanceDate = '';
    let type: AlertProps.Type = 'info';

    if (isBefore(end, start)) {
        return null;
    } else if (differenceInMilliseconds(end, start) > max) {
        formatDistanceDate = formatDuration({
            hours: max / milliseconds({ hours: 1 }),
        });
        message = `Total event duration must not exceed ${formatDistanceDate}.`;
        type = 'error';
    } else if (differenceInMilliseconds(end, start) <= min) {
        formatDistanceDate = formatDuration({
            minutes: min / milliseconds({ minutes: 1 }),
        });
        message = `Total event duration of less than ${formatDistanceDate} may terminate the event earlier than expected.`;
        type = 'warning';
    } else if (started) {
        formatDistanceDate = getStringList(
            formatDuration(
                intervalToDuration({
                    start,
                    end,
                }),
                { delimiter: DELIMITER }
            )
        );
        message = `Event will be terminated in ${formatDistanceDate}.`;
    } else {
        formatDistanceDate = getStringList(
            formatDuration(
                intervalToDuration({
                    start,
                    end,
                }),
                { delimiter: DELIMITER }
            )
        );
        message = `Total event duration is ${formatDistanceDate}.`;
    }

    return (
        <Box {...boxProps}>
            <Alert type={type}>{message}</Alert>
        </Box>
    );
};

export default TimeDistanceAlert;
