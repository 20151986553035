import { useLazyRequest, useModal } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import React, { useCallback, useContext, useEffect } from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';

import { i18nStrings } from '../../../../../constants';
import { NotificationID, UnbanParticipantModalProps } from './types';

const UnbanParticipantModal = ({
    onDismiss,
    onUpdateSuccess,
    participant,
    isVisible = false,
}: UnbanParticipantModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const [
        unbanParticipant,
        {
            isError: unbanParticipantError,
            isLoading: isUnbanParticipantLoading,
            data: unbanParticipantData,
        },
    ] = useLazyRequest<typeof EEEvents.unbanParticipant, AWSError>(
        EEEvents.unbanParticipant,
        {}
    );
    const confirmBanParticipant = useCallback(() => {
        unbanParticipant({
            eventId: participant.eventId,
            participantId: participant.participantId,
        });
    }, [participant]);
    const { modalComponent } = useModal({
        isVisible,
        onDismiss,
        modalHeader:
            i18nStrings.events.participants.unbanParticipantConfirmation.header,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onDismiss,
            },
            primary: {
                text:
                    i18nStrings.events.participants.unbanParticipantConfirmation
                        .primaryAction,
                loading: isUnbanParticipantLoading,
                onClick: confirmBanParticipant,
            },
        },
        content: (
            <>
                {
                    i18nStrings.events.participants.unbanParticipantConfirmation
                        .content
                }
            </>
        ),
    });

    useEffect(() => {
        if (!unbanParticipantData) {
            return;
        }

        onUpdateSuccess();
        showNotification(
            i18nStrings.events.participants.unbanParticipantConfirmation
                .notifications.banSuccess,
            {
                id: NotificationID.UPDATE_RESPONSE,
                type: 'success',
            }
        );
    }, [unbanParticipantData]);

    useEffect(() => {
        unbanParticipantError &&
            showNotification(
                ErrorMessage.getMessage(
                    unbanParticipantError,
                    MessageTypes.unbanParticipant
                ),
                {
                    id: NotificationID.UPDATE_RESPONSE,
                    type: 'error',
                }
            );
    }, [unbanParticipantError]);

    useEffect(() => {
        if (!(unbanParticipantData || unbanParticipantError)) {
            return;
        }

        onDismiss();
    }, [unbanParticipantData, unbanParticipantError]);

    return modalComponent;
};

export default UnbanParticipantModal;
