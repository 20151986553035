import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_STATE_INFRASTRUCTURE,
    EVENT_STATE_RUNTIME,
    TEAM_STATE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import { EventData } from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { EventMatrix, formatString } from '@amzn/event-engine-js-utils';
import { addDays, addMinutes } from 'date-fns';
import React from 'react';
import {
    PROVISIONED_EVENT_AUTO_START_WINDOW_DAYS,
    SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS,
} from 'utils/config';

import { i18nStrings } from '../../../../../constants';
import styles from './EventTimelineStep.module.scss';
import { TimelineStep, TimelineStepConfig, TimelineStepID } from './types';
import getDisplayDate from './utils/get-display-date';

export const stepScheduled: TimelineStepConfig = (event: EventData) => {
    const config: TimelineStep = {
        id: TimelineStepID.Scheduled,
        status: 'success',
        i18nStrings: {
            title:
                i18nStrings.events.eventDetails.timeline.step.scheduled.title,
            description: formatString(
                i18nStrings.events.eventDetails.timeline.step.scheduled
                    .description,
                ...getDisplayDate(event.createdAt)
            ),
        },
    };

    // This step has no phases, but if that ever changes in the future,
    // config transformation should be placed here

    return config;
};

export const stepProvisioning: TimelineStepConfig = (
    event: EventData,
    { provision },
    teams
) => {
    const eventStates = {
        runtime: event.stateRuntime,
        infrastructure: event.stateInfrastructure,
    };
    const nextActionAt =
        event.nextActionAt ||
        (event.scheduledStartTime
            ? addDays(
                  event.scheduledStartTime,
                  SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS
              )
            : undefined);
    const config: TimelineStep = {
        id: TimelineStepID.Provisioning,
        i18nStrings: {
            title:
                i18nStrings.events.eventDetails.timeline.step.provisioning
                    .title,
            description: (
                <span className={styles.popoverDescription}>
                    <Popover
                        content={formatString(
                            i18nStrings.events.eventDetails.timeline
                                .provisionStartWarning,
                            SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS
                        )}
                        dismissButton={false}
                        triggerType="text">
                        {formatString(
                            i18nStrings.events.eventDetails.timeline.step
                                .provisioning.description,
                            ...getDisplayDate(nextActionAt)
                        )}
                    </Popover>
                </span>
            ),
        },
    };
    const totalTeams = teams ? teams.length : i18nStrings.empty;
    const numDeployedTeams = teams
        ? teams.filter(({ state }) => state === TEAM_STATE.DEPLOYMENT_SUCCESS)
              .length
        : i18nStrings.empty;

    if (provision.loading) {
        config.status = 'loading';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.provisioningStarting;
    } else if (provision.error) {
        config.status = 'error';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.provisioningStartingFailed;
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.CANCELED) {
        return;
    } else if (EventMatrix.isDeploymentInProgress(eventStates)) {
        config.status = 'in-progress';

        if (
            event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO &&
            !event.provisionEndAt &&
            event.centralAccountRequired &&
            EventMatrix.compareState(
                event.stateInfrastructure,
                EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_IN_PROGRESS,
                'infrastructure'
            ) < 0
        ) {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.provisioningInProgressCentralAccount.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .provisioningInProgressCentralAccount.description,
                0,
                1
            );
        } else if (
            event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO &&
            teams?.length
        ) {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.provisioningInProgressTeamAccounts.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .provisioningInProgressTeamAccounts.description,
                numDeployedTeams,
                totalTeams
            );
        } else {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.provisioningInProgress.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .provisioningInProgress.description,
                ...getDisplayDate(event.provisionBeginAt)
            );
        }
    } else if (
        EventMatrix.isDeploymentResolved(eventStates) &&
        !EventMatrix.isDeploymentSuccess(eventStates)
    ) {
        config.status = 'error';

        if (event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO) {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.accountProvisionFailedWSProvided.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .accountProvisionFailedWSProvided.description,
                numDeployedTeams,
                totalTeams
            );
        } else {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.accountProvisionFailed.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .accountProvisionFailed.description,
                ...getDisplayDate(event.provisionEndAt)
            );
        }
    } else if (EventMatrix.isProvisionStarted(eventStates)) {
        config.status = 'success';

        if (
            EventMatrix.compareState(
                event.stateRuntime,
                EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS,
                'runtime'
            ) < 0 &&
            event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
        ) {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.accountProvisionSucceededWSProvided.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .accountProvisionSucceededWSProvided.description,
                numDeployedTeams,
                totalTeams
            );
        } else {
            config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.accountProvisionSucceeded.title;
            config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .accountProvisionSucceeded.description,
                ...getDisplayDate(event.provisionEndAt)
            );
        }
    }

    return config;
};

export const stepEvent: TimelineStepConfig = (
    event: EventData,
    { start, pause, resume, terminate }
) => {
    const config: TimelineStep = {
        id: TimelineStepID.Event,
        i18nStrings: {
            title: i18nStrings.events.eventDetails.timeline.step.event.title,
            description:
                i18nStrings.events.eventDetails.timeline.step.event.description,
        },
    };

    if (
        (event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO &&
            EventMatrix.compareState(
                event.stateInfrastructure,
                EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_IN_PROGRESS,
                'infrastructure'
            ) >= 0) ||
        (event.accountSource === EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED &&
            EventMatrix.compareState(
                event.stateRuntime,
                EVENT_STATE_RUNTIME.NOT_STARTED,
                'runtime'
            ) >= 0)
    ) {
        const nextActionAt =
            event.nextActionAt ||
            (event.provisionEndAt
                ? addDays(
                      event.provisionEndAt,
                      PROVISIONED_EVENT_AUTO_START_WINDOW_DAYS
                  )
                : undefined);

        config.i18nStrings!.description = formatString(
            i18nStrings.events.eventDetails.timeline.step.event
                .descriptionAutoStart,
            ...getDisplayDate(nextActionAt)
        );
    }

    if (start.loading || pause.loading || resume.loading || terminate.loading) {
        config.status = 'loading';

        if (terminate.loading) {
            if (event.startBeginAt) {
                config.i18nStrings =
                    i18nStrings.events.eventDetails.timeline.step.endingEvent;
            } else {
                config.i18nStrings =
                    i18nStrings.events.eventDetails.timeline.step.endingEventNeverStarted;
            }
        } else if (pause.loading) {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventPauseInProgress;
        } else if (resume.loading) {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventResuming;
        } else {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventStarting;
        }
    } else if (start.error || pause.error || resume.error || terminate.error) {
        config.status = 'error';

        if (terminate.error) {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.endingEventFailed;
        } else if (pause.error) {
            // The event is still in progress even if the pause action fails
            config.status = 'in-progress';
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventPauseStartingFailed;
        } else if (resume.error) {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventResumeFailed;
        } else {
            config.i18nStrings =
                i18nStrings.events.eventDetails.timeline.step.eventStartingFailed;
        }
    } else if (
        event.stateRuntime === EVENT_STATE_RUNTIME.CANCELED ||
        (EventMatrix.compareState(
            event.stateRuntime,
            EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS,
            'runtime'
        ) >= 0 &&
            !event.startBeginAt)
    ) {
        return;
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.START_IN_PROGRESS) {
        config.status = 'loading';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.eventStarting;
    } else if (
        event.stateRuntime === EVENT_STATE_RUNTIME.START_SUCCESS ||
        event.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_FAILED
    ) {
        config.status = 'in-progress';

        if (event.resumeEndAt) {
            (config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.eventResumeSucceeded.title),
                (config.i18nStrings!.description = formatString(
                    i18nStrings.events.eventDetails.timeline.step
                        .eventResumeSucceeded.description,
                    ...getDisplayDate(event.resumeEndAt)
                ));
        } else {
            (config.i18nStrings!.title =
                i18nStrings.events.eventDetails.timeline.step.eventStartSucceeded.title),
                (config.i18nStrings!.description = formatString(
                    i18nStrings.events.eventDetails.timeline.step
                        .eventStartSucceeded.description,
                    ...getDisplayDate(event.startBeginAt)
                ));
        }
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.START_FAILED) {
        config.status = 'error';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.eventStartFailed;
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_IN_PROGRESS) {
        config.status = 'loading';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.eventPauseInProgress;
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_SUCCESS) {
        config.status = 'pending';
        config.i18nStrings!.title =
            i18nStrings.events.eventDetails.timeline.step.eventPauseSucceeded.title;
        config.i18nStrings!.description = formatString(
            i18nStrings.events.eventDetails.timeline.step.eventPauseSucceeded
                .description,
            ...getDisplayDate(event.pauseBeginAt)
        );
    } else if (
        EventMatrix.compareState(
            event.stateRuntime,
            EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS,
            'runtime'
        ) >= 0
    ) {
        config.status = 'success';
        config.i18nStrings!.title =
            i18nStrings.events.eventDetails.timeline.step.eventCompleted.title;
        config.i18nStrings!.description = formatString(
            i18nStrings.events.eventDetails.timeline.step.eventCompleted
                .description,
            ...getDisplayDate(event.terminateBeginAt)
        );
    }

    return config;
};

export const stepTermination: TimelineStepConfig = (event: EventData) => {
    const config: TimelineStep = {
        id: TimelineStepID.Termination,
        i18nStrings: {
            title:
                i18nStrings.events.eventDetails.timeline.step.termination.title,
            description:
                i18nStrings.events.eventDetails.timeline.step.termination
                    .description,
        },
    };

    if (
        EventMatrix.compareState(
            event.stateRuntime,
            EVENT_STATE_RUNTIME.START_SUCCESS,
            'runtime'
        ) >= 0
    ) {
        let nextActionAt = event.nextActionAt;

        if (!nextActionAt) {
            let startTime: Date | undefined;

            if (event.startEndAt) {
                startTime = event.startEndAt;
            } else if (event.scheduledStartTime) {
                startTime = event.scheduledStartTime;
            }

            if (startTime) {
                nextActionAt = addMinutes(startTime, event.scheduledDuration);
            }
        }

        config.i18nStrings!.description = formatString(
            i18nStrings.events.eventDetails.timeline.step.termination
                .descriptionAutoStart,
            ...getDisplayDate(nextActionAt)
        );
    }

    // The terminate loading/error states will be displayed
    // during the terminal state for the previous event step
    if (event.stateRuntime === EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS) {
        config.status = 'loading';
        (config.i18nStrings!.title =
            i18nStrings.events.eventDetails.timeline.step.terminationInProgress.title),
            (config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .terminationInProgress.description,
                ...getDisplayDate(event.terminateBeginAt)
            ));
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.TERMINATE_SUCCESS) {
        config.status = 'success';
        (config.i18nStrings!.title =
            i18nStrings.events.eventDetails.timeline.step.terminationSucceeded.title),
            (config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .terminationSucceeded.description,
                ...getDisplayDate(event.terminateEndAt)
            ));
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.TERMINATE_FAILED) {
        config.status = 'error';
        config.i18nStrings =
            i18nStrings.events.eventDetails.timeline.step.terminationFailed;
    } else if (event.stateRuntime === EVENT_STATE_RUNTIME.CANCELED) {
        config.status = 'stopped';
        (config.i18nStrings!.title =
            i18nStrings.events.eventDetails.timeline.step.terminationCanceled.title),
            (config.i18nStrings!.description = formatString(
                i18nStrings.events.eventDetails.timeline.step
                    .terminationCanceled.description,
                ...getDisplayDate(event.canceledAt)
            ));
    }

    return config;
};
