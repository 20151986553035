/* eslint-disable react/display-name */
import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { AttributeEditorProps } from '@amzn/awsui-components-react/polaris/attribute-editor';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Form from '@amzn/awsui-components-react/polaris/form';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Input from '@amzn/awsui-components-react/polaris/input';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import {
    AttributeEditorField,
    BaseMarkdown,
    DatePickerField,
    EventField,
    EventMatrix,
    formatString,
    FormikField,
    InputField,
    MultiSelectField,
    SelectField,
    TextAreaField,
    TimeInputField,
} from '@amzn/event-engine-js-utils';
import { routes } from 'components/AppRoutes/routes.constants';
import HelpPanel from 'components/HelpPanel';
import InfoLink from 'components/InfoLink';
import i18nStrings from 'constants/i18n-strings';
import { FormikContextType, useFormikContext } from 'formik';
import useFeatureFlag from 'hooks/use-feature-flag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MAX_TEAM_SIZE, SELECT_FILTERING_MIN_OPTIONS } from 'utils/config';
import { MAX_EVENT_DURATION } from 'utils/config';
import { getUTCOffsetLabel } from 'utils/date-fns';
import pluralizeSimple from 'utils/pluralize-simple';

import AccountQuotaStatus from '../../components/AccountQuotaStatus';
import { TEST_ID } from '../tests/constants';
import {
    filterRegionOptions,
    getAccessibleRegionsConstraints,
    getMaxParticipantForecast,
    getMinAccountRequirement,
    isFieldEnabled,
} from '../utils';
import {
    didSelectOtherEngagement,
    EnhancedContentTemplateAttribute,
    generateAttributeFormElements,
    otherEngagementTypeKey,
} from '../utils/create-event-attributes';
import {
    MODALITY_OPTIONS,
    PERIOD_SELECT_OPTIONS,
    REQUIRED_REGION_CATEGORIES,
    TIMEZONES,
} from './../utils/constants';
import css from './CreateEventForm.module.scss';
import {
    getCreateEventFormFieldName,
    getFacilitatorsTagsDefinition,
} from './CreateEventForm.utils';
import { MergedFormSchema, Step } from './CreateEventForm.validation';

export interface FacilitatorTemplate {
    key: string | undefined;
    value: string | undefined;
}

const TAGS_DEFINITION: AttributeEditorProps.FieldDefinition<{
    key: string | undefined;
    value: string | undefined;
}>[] = getFacilitatorsTagsDefinition();

export const facilitatorTemplateItem: FacilitatorTemplate = {
    key: undefined,
    value: undefined,
};

interface CreateEventFormOptions {
    accessibleRegions: SelectProps.Option[];
    deploymentRegions: SelectProps.Option[];
}

export interface CreateEventFormProps {
    attributes: readonly EnhancedContentTemplateAttribute[];
    /**
     * If an event is being created for the first time, editMatrix
     * has no specific event attached to it since it is being created
     * If an event is being modified, editMatrix has all knowledge about
     * an event, including the event state, account source, and event type
     */
    editMatrix: EventMatrix;
    errorText?: React.ReactNode;
    actions?: JSX.Element;
    maxEventSize?: number;
    options?: Partial<CreateEventFormOptions>;
}

const CreateEventForm = ({
    attributes,
    maxEventSize,
    actions,
    editMatrix,
    errorText,
    options: { accessibleRegions = [], deploymentRegions = [] } = {},
}: CreateEventFormProps) => {
    // TODO: Remove once multiParticipants is generally available
    const multiParticipantsFeatureEnabled = useFeatureFlag('multiParticipants');
    // TODO: Remove once centralAccounts is generally available
    const centralAccountsFeatureEnabled = useFeatureFlag('centralAccounts');
    const formik: FormikContextType<MergedFormSchema> = useFormikContext();
    const selectedWorkshop = formik.values[Step.SELECT_WORKSHOP];
    const teamSize = formik.values[Step.EVENT_DETAILS].teamSize;
    const participantForecast =
        formik.values[Step.EVENT_DETAILS].forecastedAttendees;
    const isTestEvent = useMemo(() => editMatrix.type === EVENT_TYPE.TEST, []);
    const accessibleRegionsConstraint = useMemo(() => {
        let constraint: string | undefined;
        const accessibleRegionsConstraints = getAccessibleRegionsConstraints(
            selectedWorkshop?.contentSpecSummary
        );
        const numberRequired = filterRegionOptions(
            accessibleRegions,
            REQUIRED_REGION_CATEGORIES
        ).length;
        const minAdditional = accessibleRegionsConstraints.min - numberRequired;
        const maxAdditional = accessibleRegionsConstraints.max - numberRequired;

        if (minAdditional > 0 && maxAdditional > 0) {
            if (minAdditional !== maxAdditional) {
                constraint = formatString(
                    i18nStrings.events.create.fields.accessibleRegions
                        .constraintMinMaxAdditional,
                    minAdditional,
                    pluralizeSimple('region', minAdditional),
                    maxAdditional
                );
            } else {
                constraint = formatString(
                    i18nStrings.events.create.fields.accessibleRegions
                        .constraintMinAdditional,
                    minAdditional,
                    pluralizeSimple('region', minAdditional)
                );
            }
        } else if (maxAdditional > 0) {
            constraint = formatString(
                i18nStrings.events.create.fields.accessibleRegions
                    .constraintMaxAdditional,
                maxAdditional,
                pluralizeSimple('region', maxAdditional)
            );
        }

        return constraint;
    }, [accessibleRegions]);
    const isFieldVisible = useCallback(
        (field: EventField) => isFieldEnabled(editMatrix, field),
        []
    );
    const [timezoneOptions, setTimezoneOptions] = useState<typeof TIMEZONES>(
        []
    );
    const didSelectOther: boolean = didSelectOtherEngagement(
        formik.values[Step.EVENT_DETAILS]['Engagement Type']
    );
    const attributeFormComponents =
        generateAttributeFormElements(attributes, {
            submitCount: formik.submitCount,
            setFieldTouched: formik.setFieldTouched,
            touched: formik.touched,
        }) || {};
    const requiredAccounts =
        participantForecast > 0 && teamSize > 0
            ? getMinAccountRequirement(
                  participantForecast,
                  teamSize,
                  selectedWorkshop?.contentSpecSummary.centralAccountRequired
              )
            : 0;

    useEffect(() => {
        setTimezoneOptions(
            TIMEZONES.map(({ label, value }) => ({
                label: `(${getUTCOffsetLabel(value as string, {
                    date: formik.values[Step.EVENT_DETAILS].eventDate,
                    time: formik.values[Step.EVENT_DETAILS].eventTime,
                    period: formik.values[Step.EVENT_DETAILS].period,
                })}) ${label}`,
                value,
            }))
        );
    }, [
        TIMEZONES,
        formik.values[Step.EVENT_DETAILS].eventDate,
        formik.values[Step.EVENT_DETAILS].eventTime,
        formik.values[Step.EVENT_DETAILS].period,
    ]);

    useEffect(() => {
        formik.setErrors(formik.submitCount ? formik.errors : {});
    }, []);

    return (
        <Form
            data-testid="create-event-form"
            actions={actions}
            errorText={errorText}>
            <SpaceBetween direction="vertical" size="l">
                <Container header={<Header variant="h2">Details</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        {isFieldVisible('title') && (
                            <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                                name={getCreateEventFormFieldName('title')}
                                dataTestId="title"
                                formFieldProps={{
                                    label: 'Title',
                                    description: 'Customer facing title',
                                    stretch: true,
                                    children: (
                                        <InputField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'title'
                                            )}
                                            placeholder={
                                                'Example: Amazon EC2 Spot Instances Advantage Event'
                                            }
                                            inputProps={{
                                                type: 'text',
                                            }}
                                        />
                                    ),
                                }}
                            />
                        )}
                        {isFieldVisible('description') && (
                            <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                                name={getCreateEventFormFieldName(
                                    'description'
                                )}
                                dataTestId="description"
                                formFieldProps={{
                                    label: 'Description',
                                    description: 'Customer facing description',
                                    stretch: true,
                                    children: (
                                        <TextAreaField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'description'
                                            )}
                                            placeholder="Example: This is an event facilitated to allow developers and system administrators to get hands on with Amazon Elastic Compute Cloud Spot Instances concepts, scaling, integration, placement strategies, etc."
                                            textFieldProps={{}}
                                        />
                                    ),
                                }}
                            />
                        )}
                        {isFieldVisible('eventModality') && (
                            <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                                name={getCreateEventFormFieldName('modality')}
                                dataTestId="modality"
                                formFieldProps={{
                                    label: 'Modality',
                                    stretch: true,
                                    children: (
                                        <SelectField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'modality'
                                            )}
                                            placeholder="Select modality"
                                            selectProps={{
                                                options: MODALITY_OPTIONS,
                                            }}
                                        />
                                    ),
                                }}
                            />
                        )}
                        {(isFieldVisible('attributeGeo') &&
                            attributeFormComponents['Geo']) ||
                            null}
                        {isFieldVisible('attributeEngagementType') && (
                            <SpaceBetween direction="vertical" size="s">
                                {attributeFormComponents['Engagement Type'] ||
                                    null}
                                {didSelectOther && (
                                    <FormikField
                                        dataTestId={`Engagement Type.${otherEngagementTypeKey}`}
                                        name={getCreateEventFormFieldName(
                                            `Engagement Type.${otherEngagementTypeKey}` as any
                                        )}
                                        formFieldProps={{
                                            stretch: true,
                                            label:
                                                'You selected the other engagement type, please indicate it',
                                            children: (
                                                <InputField
                                                    name={getCreateEventFormFieldName(
                                                        `Engagement Type.${otherEngagementTypeKey}` as any
                                                    )}
                                                    placeholder="Other engagement type"
                                                    inputProps={{
                                                        type: 'text',
                                                    }}
                                                />
                                            ),
                                        }}
                                    />
                                )}
                            </SpaceBetween>
                        )}
                        {(isFieldVisible('attributeOpportunityId') &&
                            attributeFormComponents[
                                'Salesforce Opportunity ID'
                            ]) ||
                            null}
                    </SpaceBetween>
                </Container>
                {(isFieldVisible('eventStartDateTime') ||
                    isFieldVisible('duration') ||
                    isFieldVisible('attendeeForecast') ||
                    isFieldVisible('reservedQuota') ||
                    isFieldVisible('regions')) && (
                    <Container
                        header={<Header variant="h2">Configuration</Header>}>
                        <ColumnLayout borders="horizontal">
                            <SpaceBetween direction="vertical" size="l">
                                {isFieldVisible('eventStartDateTime') && (
                                    <ColumnLayout
                                        columns={3}
                                        className={css.columnFormContainer}>
                                        <FormikField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'eventDate'
                                            )}
                                            dataTestId="eventDate"
                                            formFieldProps={{
                                                label: 'Start Date',
                                                info: (
                                                    <InfoLink
                                                        toolsContent={
                                                            <HelpPanel
                                                                header={
                                                                    i18nStrings
                                                                        .events
                                                                        .create
                                                                        .helpText
                                                                        .startTime
                                                                        .header
                                                                }>
                                                                {
                                                                    i18nStrings
                                                                        .events
                                                                        .create
                                                                        .helpText
                                                                        .startTime
                                                                        .content
                                                                }
                                                            </HelpPanel>
                                                        }
                                                    />
                                                ),
                                                stretch: true,
                                                children: (
                                                    <DatePickerField<
                                                        MergedFormSchema[Step.EVENT_DETAILS]
                                                    >
                                                        name={getCreateEventFormFieldName(
                                                            'eventDate'
                                                        )}
                                                        placeholder="YYYY/MM/DD"
                                                        datePickerProps={{
                                                            className:
                                                                css.unsetMaxWidth,
                                                            todayAriaLabel:
                                                                'Today',
                                                            nextMonthAriaLabel:
                                                                'Next',
                                                            previousMonthAriaLabel:
                                                                'Previous',
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="xs"
                                            className={css.groupField}>
                                            <FormikField<
                                                MergedFormSchema[Step.EVENT_DETAILS]
                                            >
                                                name={getCreateEventFormFieldName(
                                                    'eventTime'
                                                )}
                                                formFieldProps={{
                                                    label: 'Start Time',
                                                    stretch: true,
                                                    children: (
                                                        <TimeInputField<
                                                            MergedFormSchema[Step.EVENT_DETAILS]
                                                        >
                                                            name={getCreateEventFormFieldName(
                                                                'eventTime'
                                                            )}
                                                            placeholder="HH:MM"
                                                            timeInputProps={{
                                                                format: 'hh:mm',
                                                                use24Hour: false,
                                                            }}
                                                        />
                                                    ),
                                                }}
                                            />
                                            <FormikField<
                                                MergedFormSchema[Step.EVENT_DETAILS]
                                            >
                                                name={getCreateEventFormFieldName(
                                                    'period'
                                                )}
                                                dataTestId="period"
                                                formFieldProps={{
                                                    stretch: true,
                                                    label: <>&nbsp;</>,
                                                    children: (
                                                        <SelectField<
                                                            MergedFormSchema[Step.EVENT_DETAILS]
                                                        >
                                                            name={getCreateEventFormFieldName(
                                                                'period'
                                                            )}
                                                            placeholder="AM/PM"
                                                            selectProps={{
                                                                options: PERIOD_SELECT_OPTIONS,
                                                            }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </SpaceBetween>
                                        <FormikField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'timezone'
                                            )}
                                            dataTestId="timezone"
                                            formFieldProps={{
                                                stretch: true,
                                                label: 'Timezone',
                                                children: (
                                                    <SelectField<
                                                        MergedFormSchema[Step.EVENT_DETAILS]
                                                    >
                                                        name={getCreateEventFormFieldName(
                                                            'timezone'
                                                        )}
                                                        placeholder="Select timezone"
                                                        selectProps={{
                                                            options: timezoneOptions,
                                                            filteringType:
                                                                'auto',
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                    </ColumnLayout>
                                )}
                                {isFieldVisible('duration') && (
                                    <FormikField<
                                        MergedFormSchema[Step.EVENT_DETAILS]
                                    >
                                        name={getCreateEventFormFieldName(
                                            'duration'
                                        )}
                                        dataTestId="duration"
                                        formFieldProps={{
                                            label:
                                                i18nStrings.events.create.fields
                                                    .duration.label,
                                            stretch: true,
                                            description:
                                                i18nStrings.events.create.fields
                                                    .duration.description,
                                            constraintText: formatString(
                                                i18nStrings.events.create.fields
                                                    .duration.constraint,
                                                MAX_EVENT_DURATION
                                            ),
                                            info: (
                                                <InfoLink
                                                    toolsContent={
                                                        <HelpPanel
                                                            header={
                                                                i18nStrings
                                                                    .events
                                                                    .create
                                                                    .helpText
                                                                    .duration
                                                                    .header
                                                            }>
                                                            {
                                                                i18nStrings
                                                                    .events
                                                                    .create
                                                                    .helpText
                                                                    .duration
                                                                    .content
                                                            }
                                                        </HelpPanel>
                                                    }
                                                />
                                            ),
                                            children: (
                                                <InputField<
                                                    MergedFormSchema[Step.EVENT_DETAILS]
                                                >
                                                    name={getCreateEventFormFieldName(
                                                        'duration'
                                                    )}
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .duration.label
                                                    }
                                                    inputProps={{
                                                        type: 'number',
                                                    }}
                                                />
                                            ),
                                        }}
                                    />
                                )}
                            </SpaceBetween>
                            <SpaceBetween direction="vertical" size="l">
                                {isFieldVisible('attendeeForecast') && (
                                    <ColumnLayout columns={2}>
                                        <FormikField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'forecastedAttendees'
                                            )}
                                            dataTestId="forecastedAttendees"
                                            formFieldProps={{
                                                description:
                                                    i18nStrings.events.create
                                                        .fields.attendeeForecast
                                                        .description,
                                                label:
                                                    i18nStrings.events.create
                                                        .fields.attendeeForecast
                                                        .label,
                                                constraintText:
                                                    maxEventSize &&
                                                    formatString(
                                                        isTestEvent
                                                            ? i18nStrings.events
                                                                  .create.fields
                                                                  .attendeeForecast
                                                                  .constraintTest
                                                            : i18nStrings.events
                                                                  .create.fields
                                                                  .attendeeForecast
                                                                  .constraintProduction,
                                                        getMaxParticipantForecast(
                                                            maxEventSize,
                                                            teamSize
                                                        )
                                                    ),
                                                children: (
                                                    <InputField<
                                                        MergedFormSchema[Step.EVENT_DETAILS]
                                                    >
                                                        name={getCreateEventFormFieldName(
                                                            'forecastedAttendees'
                                                        )}
                                                        placeholder={
                                                            i18nStrings.events
                                                                .create.fields
                                                                .attendeeForecast
                                                                .label
                                                        }
                                                        inputProps={{
                                                            type: 'number',
                                                            inputMode:
                                                                'numeric',
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <FormikField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'teamSize'
                                            )}
                                            dataTestId="teamSize"
                                            formFieldProps={{
                                                label:
                                                    i18nStrings.events.create
                                                        .fields.teamSize.label,
                                                description:
                                                    i18nStrings.events.create
                                                        .fields.teamSize
                                                        .description,
                                                constraintText: multiParticipantsFeatureEnabled
                                                    ? formatString(
                                                          i18nStrings.events
                                                              .create.fields
                                                              .teamSize
                                                              .constraintText,
                                                          MAX_TEAM_SIZE
                                                      )
                                                    : i18nStrings.events.create
                                                          .fields.teamSize
                                                          .constraintTextFeatureFlag,
                                                children: (
                                                    <InputField<
                                                        MergedFormSchema[Step.EVENT_DETAILS]
                                                    >
                                                        name={getCreateEventFormFieldName(
                                                            'teamSize'
                                                        )}
                                                        placeholder={
                                                            i18nStrings.events
                                                                .create.fields
                                                                .teamSize.label
                                                        }
                                                        inputProps={{
                                                            type: 'number',
                                                            inputMode:
                                                                'numeric',
                                                            disabled: !multiParticipantsFeatureEnabled,
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                    </ColumnLayout>
                                )}
                                {multiParticipantsFeatureEnabled &&
                                    isFieldVisible('reservedQuota') && (
                                        <div className={css.nestedControl}>
                                            <FormField
                                                label={
                                                    i18nStrings.events.create
                                                        .fields.requiredAccounts
                                                        .label
                                                }
                                                info={
                                                    <InfoLink
                                                        label={
                                                            i18nStrings.events
                                                                .create.fields
                                                                .requiredAccounts
                                                                .infoLink
                                                        }
                                                        toolsContent={
                                                            centralAccountsFeatureEnabled ? (
                                                                <HelpPanel
                                                                    header={
                                                                        i18nStrings
                                                                            .events
                                                                            .create
                                                                            .helpText
                                                                            .requiredAccountsWithCentral
                                                                            .header
                                                                    }>
                                                                    {
                                                                        i18nStrings
                                                                            .events
                                                                            .create
                                                                            .helpText
                                                                            .requiredAccountsWithCentral
                                                                            .content
                                                                    }
                                                                </HelpPanel>
                                                            ) : (
                                                                <HelpPanel
                                                                    header={
                                                                        i18nStrings
                                                                            .events
                                                                            .create
                                                                            .helpText
                                                                            .requiredAccounts
                                                                            .header
                                                                    }>
                                                                    {
                                                                        i18nStrings
                                                                            .events
                                                                            .create
                                                                            .helpText
                                                                            .requiredAccounts
                                                                            .content
                                                                    }
                                                                </HelpPanel>
                                                            )
                                                        }
                                                    />
                                                }
                                                constraintText={
                                                    selectedWorkshop
                                                        ?.contentSpecSummary
                                                        .centralAccountRequired &&
                                                    i18nStrings.events.create
                                                        .fields.requiredAccounts
                                                        .constraintCentralAccount
                                                }
                                                data-testid={
                                                    TEST_ID.FORM_ELEMENT
                                                        .TOTAL_ACCOUNTS_NEEDED
                                                }>
                                                <Input
                                                    value={`${
                                                        requiredAccounts > 0
                                                            ? requiredAccounts
                                                            : ''
                                                    }`}
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .requiredAccounts
                                                            .placeholder
                                                    }
                                                    disabled
                                                />
                                            </FormField>
                                        </div>
                                    )}
                                {isFieldVisible('reservedQuota') && (
                                    <FormikField<
                                        MergedFormSchema[Step.EVENT_DETAILS]
                                    >
                                        name={getCreateEventFormFieldName(
                                            'initialQuotaContribution'
                                        )}
                                        dataTestId="initialQuotaContribution"
                                        formFieldProps={{
                                            label: (
                                                <>
                                                    {
                                                        i18nStrings.events
                                                            .create.fields
                                                            .initialQuotaContribution
                                                            .label
                                                    }{' '}
                                                    -{' '}
                                                    <i>
                                                        {i18nStrings.optional}
                                                    </i>
                                                </>
                                            ),
                                            description: (
                                                <BaseMarkdown
                                                    components={{
                                                        p: TextContent,
                                                    }}
                                                    linkTarget="_blank"
                                                    className={
                                                        css.inheritFontStyles
                                                    }>
                                                    {formatString(
                                                        i18nStrings.events
                                                            .create.fields
                                                            .initialQuotaContribution
                                                            .description,
                                                        routes.quotas
                                                    )}
                                                </BaseMarkdown>
                                            ),
                                            constraintText: (
                                                <AccountQuotaStatus
                                                    value={
                                                        formik.values[
                                                            Step.EVENT_DETAILS
                                                        ]
                                                            .initialQuotaContribution
                                                    }
                                                    blockDate={{
                                                        date:
                                                            formik.values[
                                                                Step
                                                                    .EVENT_DETAILS
                                                            ].eventDate,
                                                        time:
                                                            formik.values[
                                                                Step
                                                                    .EVENT_DETAILS
                                                            ].eventTime,
                                                        period:
                                                            formik.values[
                                                                Step
                                                                    .EVENT_DETAILS
                                                            ].period,
                                                        timezone:
                                                            formik.values[
                                                                Step
                                                                    .EVENT_DETAILS
                                                            ].timezone,
                                                    }}
                                                    min={requiredAccounts}
                                                    onResolve={(e) => {
                                                        typeof e?.available ===
                                                            'number' &&
                                                            formik.setFieldValue(
                                                                getCreateEventFormFieldName(
                                                                    'availableQuota'
                                                                ),
                                                                e.available
                                                            );
                                                    }}
                                                />
                                            ),
                                            children: (
                                                <InputField<
                                                    MergedFormSchema[Step.EVENT_DETAILS]
                                                >
                                                    name={getCreateEventFormFieldName(
                                                        'initialQuotaContribution'
                                                    )}
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .initialQuotaContribution
                                                            .label
                                                    }
                                                    inputProps={{
                                                        type: 'number',
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                            ),
                                            stretch: true,
                                        }}
                                    />
                                )}
                            </SpaceBetween>
                            {isFieldVisible('regions') && (
                                <SpaceBetween direction="vertical" size="l">
                                    {!!deploymentRegions.length && (
                                        <FormikField<
                                            MergedFormSchema[Step.EVENT_DETAILS]
                                        >
                                            name={getCreateEventFormFieldName(
                                                'deploymentRegions'
                                            )}
                                            dataTestId="deploymentRegions"
                                            formFieldProps={{
                                                label:
                                                    i18nStrings.events.create
                                                        .fields
                                                        .deploymentRegions
                                                        .label,
                                                stretch: true,
                                                description:
                                                    i18nStrings.events.create
                                                        .fields
                                                        .deploymentRegions
                                                        .description,
                                                info: (
                                                    <InfoLink
                                                        toolsContent={
                                                            <HelpPanel
                                                                header={
                                                                    i18nStrings
                                                                        .events
                                                                        .create
                                                                        .helpText
                                                                        .regions
                                                                        .header
                                                                }>
                                                                {
                                                                    i18nStrings
                                                                        .events
                                                                        .create
                                                                        .helpText
                                                                        .regions
                                                                        .content
                                                                }
                                                            </HelpPanel>
                                                        }
                                                    />
                                                ),
                                                children: (
                                                    <SelectField<
                                                        MergedFormSchema[Step.EVENT_DETAILS]
                                                    >
                                                        name={getCreateEventFormFieldName(
                                                            'deploymentRegions'
                                                        )}
                                                        placeholder={
                                                            i18nStrings.events
                                                                .create.fields
                                                                .deploymentRegions
                                                                .placeholder
                                                        }
                                                        selectProps={{
                                                            options: deploymentRegions,
                                                            triggerVariant:
                                                                'option',
                                                            disabled: !!selectedWorkshop
                                                                ?.contentSpecSummary
                                                                .deployableRegions
                                                                ?.required
                                                                ?.length,
                                                            filteringType:
                                                                deploymentRegions.length >
                                                                SELECT_FILTERING_MIN_OPTIONS
                                                                    ? 'auto'
                                                                    : undefined,
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                    )}
                                    <FormikField<
                                        MergedFormSchema[Step.EVENT_DETAILS]
                                    >
                                        name={getCreateEventFormFieldName(
                                            'accessibleRegions'
                                        )}
                                        dataTestId="accessibleRegions"
                                        formFieldProps={{
                                            label:
                                                i18nStrings.events.create.fields
                                                    .accessibleRegions.label,
                                            stretch: true,
                                            description:
                                                i18nStrings.events.create.fields
                                                    .accessibleRegions
                                                    .description,
                                            constraintText: accessibleRegionsConstraint,
                                            info: (
                                                <InfoLink
                                                    toolsContent={
                                                        <HelpPanel
                                                            header={
                                                                i18nStrings
                                                                    .events
                                                                    .create
                                                                    .helpText
                                                                    .regions
                                                                    .header
                                                            }>
                                                            {
                                                                i18nStrings
                                                                    .events
                                                                    .create
                                                                    .helpText
                                                                    .regions
                                                                    .content
                                                            }
                                                        </HelpPanel>
                                                    }
                                                />
                                            ),
                                            children: (
                                                <MultiSelectField<
                                                    MergedFormSchema[Step.EVENT_DETAILS]
                                                >
                                                    name={getCreateEventFormFieldName(
                                                        'accessibleRegions'
                                                    )}
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .accessibleRegions
                                                            .placeholder
                                                    }
                                                    multiSelectProps={{
                                                        options: accessibleRegions,
                                                        filteringType:
                                                            accessibleRegions.length >
                                                            SELECT_FILTERING_MIN_OPTIONS
                                                                ? 'auto'
                                                                : undefined,
                                                    }}
                                                />
                                            ),
                                        }}
                                    />
                                </SpaceBetween>
                            )}
                        </ColumnLayout>
                    </Container>
                )}
                {isFieldVisible('permissions') && (
                    <Container
                        header={
                            <Header
                                info={
                                    <InfoLink
                                        toolsContent={
                                            <HelpPanel
                                                header={
                                                    i18nStrings.events.create
                                                        .helpText.facilitators
                                                        .header
                                                }>
                                                {
                                                    i18nStrings.events.create
                                                        .helpText.facilitators
                                                        .content
                                                }
                                            </HelpPanel>
                                        }
                                    />
                                }
                                description="Add up to 50 additional facilitators"
                                variant="h2">
                                Additional facilitators
                            </Header>
                        }>
                        <FormikField<MergedFormSchema[Step.EVENT_DETAILS]>
                            name={getCreateEventFormFieldName('facilitators')}
                            dataTestId="facilitators"
                            formFieldProps={{
                                stretch: true,
                                children: (
                                    <AttributeEditorField<
                                        MergedFormSchema[Step.EVENT_DETAILS],
                                        FacilitatorTemplate
                                    >
                                        name={getCreateEventFormFieldName(
                                            'facilitators'
                                        )}
                                        attributeEditorProps={{
                                            definition: TAGS_DEFINITION,
                                            templateItem: facilitatorTemplateItem,
                                            empty:
                                                'No additional facilitators specified.',
                                            addButtonText: 'Add facilitator',
                                            removeButtonText: 'Remove',
                                        }}
                                    />
                                ),
                            }}
                        />
                    </Container>
                )}
            </SpaceBetween>
        </Form>
    );
};

export default CreateEventForm;
