import { PARTICIPANT_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import React, { ReactNode } from 'react';
import getDisplayValueUtil from 'utils/get-display-value';
import getDisplayValue from 'utils/get-display-value';

import { i18nStrings, tableConfig } from '../../../../../constants';
import EventParticipantState from '../EventParticipantState';
import TeamDetailsLink from '../TeamDetailsLink';
import { ParticipantDataTableItem } from './types';

export const columnIds = {
    email: 'email',
    firstJoinedAt: 'firstJoinedAt',
    lastJoinedAt: 'lastJoinedAt',
    lastUpdatedAt: 'lastUpdatedAt',
    participantId: 'participantId',
    state: 'state',
    teamId: 'teamId',
    teamName: 'teamName',
    deniedReason: 'deniedReason',
};

export const participantStateFilterStorageKey =
    'collectionSelectFilterParticipantState';
export const participantStateFilterOptions: SelectProps.Option[] = [
    {
        label: i18nStrings.events.participants.states.all,
        value: '',
    },
    {
        label: i18nStrings.events.participants.states.assigned,
        value: PARTICIPANT_STATE.ASSIGNED,
    },
    {
        label: i18nStrings.events.participants.states.unassigned,
        value: PARTICIPANT_STATE.UNASSIGNED,
    },
    {
        label: i18nStrings.events.participants.states.denied,
        value: PARTICIPANT_STATE.DENIED,
    },
];
export const defaultParticipantStateFilterOption =
    participantStateFilterOptions[0];

export const DEFAULT_PAGINATION_LIMIT = 100;

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    visibleContent: [
        columnIds.email,
        columnIds.state,
        columnIds.teamName,
        columnIds.firstJoinedAt,
        columnIds.lastJoinedAt,
    ],
};

type ColumnFieldMap = Record<
    keyof typeof columnIds,
    keyof ParticipantDataTableItem
>;

export const columnFieldMap: ColumnFieldMap = {
    email: 'email',
    firstJoinedAt: 'firstJoinedAt',
    lastJoinedAt: 'lastJoinedAt',
    lastUpdatedAt: 'lastUpdatedAt',
    participantId: 'participantId',
    state: 'displayState',
    teamId: 'teamId',
    teamName: 'teamName',
    deniedReason: 'deniedReason',
};

export const defaultSortPreferences: TableProps.SortingState<ParticipantDataTableItem> = {
    ...tableConfig.defaultSortPreferences,
    sortingColumn: {
        sortingField: columnFieldMap.email,
    },
};

const getParticipantState = (event: ParticipantDataTableItem) => (
    <EventParticipantState state={event.state} />
);

const getTeamDetailsLink = ({
    eventId,
    teamId,
    teamName,
}: ParticipantDataTableItem) =>
    teamId ? (
        <TeamDetailsLink eventId={eventId} teamId={teamId}>
            {teamName}
        </TeamDetailsLink>
    ) : (
        getDisplayValueUtil()
    );

export const columnDefinitions: (TableProps.ColumnDefinition<
    ParticipantDataTableItem
> &
    Partial<CollectionPreferencesProps.VisibleContentOption>)[] = [
    {
        id: columnIds.email,
        header: i18nStrings.events.participants.table.columns.email,
        cell: (item) =>
            getDisplayValue(item.email, {
                defaultValue: (
                    <Box color="text-status-inactive" tagOverride="i">
                        {i18nStrings.redacted}
                    </Box>
                ),
            }),
        sortingField: columnFieldMap.email,
        editable: false,
    },
    {
        id: columnIds.firstJoinedAt,
        header: i18nStrings.events.participants.table.columns.firstJoined,
        cell: (item) => getDisplayValue(item.firstJoinedAt),
        sortingField: columnFieldMap.firstJoinedAt,
    },
    {
        id: columnIds.lastJoinedAt,
        header: i18nStrings.events.participants.table.columns.lastJoined,
        cell: (item) => getDisplayValue(item.lastJoinedAt),
        sortingField: columnFieldMap.lastJoinedAt,
    },
    {
        id: columnIds.lastUpdatedAt,
        header: i18nStrings.events.participants.table.columns.lastUpdated,
        cell: (item) => getDisplayValue(item.lastUpdatedAt),
        sortingField: columnFieldMap.lastUpdatedAt,
    },
    {
        id: columnIds.participantId,
        header: i18nStrings.events.participants.table.columns.participantId,
        cell: (item) => getDisplayValue(item.participantId),
        sortingField: columnFieldMap.participantId,
    },
    {
        id: columnIds.state,
        header: i18nStrings.events.participants.table.columns.state,
        cell: (item) => getParticipantState(item),
        sortingField: columnFieldMap.state,
    },
    {
        id: columnIds.teamId,
        header: i18nStrings.events.participants.table.columns.teamId,
        cell: (item) => getDisplayValue(item.teamId),
        sortingField: columnFieldMap.teamId,
    },
    {
        id: columnIds.teamName,
        header: i18nStrings.events.participants.table.columns.teamName,
        cell: (item) => getTeamDetailsLink(item),
        sortingField: columnFieldMap.teamName,
        sortingComparator: (a, b) => {
            if (!a.teamName || !b.teamName) {
                return -1;
            }

            return a.teamName.localeCompare(b.teamName, 'en', {
                numeric: true,
            });
        },
    },
    {
        id: columnIds.deniedReason,
        header: i18nStrings.events.participants.table.columns.deniedReason,
        cell: (item) => getDisplayValue(item.deniedReason),
        sortingField: columnFieldMap.deniedReason,
    },
];

export const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: 'and',
};

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        key: columnFieldMap.email,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.participants.table.columns.email,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.email,
        showOptions: true,
    },
    {
        key: columnFieldMap.participantId,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel:
            i18nStrings.events.participants.table.columns.participantId,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.participantId,
    },
    {
        key: columnFieldMap.state,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.participants.table.columns.state,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.state,
        showOptions: true,
    },
    {
        key: columnFieldMap.teamId,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.participants.table.columns.teamId,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.teamId,
    },
    {
        key: columnFieldMap.teamName,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.participants.table.columns.teamName,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.teamName,
        showOptions: true,
    },
    {
        key: columnFieldMap.deniedReason,
        operators: ['=', '!=', ':', '!:'],
        propertyLabel:
            i18nStrings.events.participants.table.columns.deniedReason,
        groupValuesLabel:
            i18nStrings.events.participants.table.propertyFilter.deniedReason,
    },
];
