import { PARTICIPANT_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';

import { i18nStrings } from '../../../../../constants';
import { StatusConfig } from './types';

export const STATUS_MAP: Record<PARTICIPANT_STATE, StatusConfig> = {
    [PARTICIPANT_STATE.ASSIGNED]: {
        type: 'success',
        label: i18nStrings.events.participants.states.assigned,
    },
    [PARTICIPANT_STATE.DENIED]: {
        type: 'warning',
        label: i18nStrings.events.participants.states.denied,
        colorOverride: 'grey',
    },
    [PARTICIPANT_STATE.PENDING]: {
        type: 'pending',
        label: i18nStrings.events.participants.states.pending,
    },
    [PARTICIPANT_STATE.UNASSIGNED]: {
        type: 'stopped',
        label: i18nStrings.events.participants.states.unassigned,
    },
    [PARTICIPANT_STATE.BANNED]: {
        type: 'error',
        label: i18nStrings.events.participants.states.banned,
    },
};

export const UNKNOWN_STATE: StatusConfig = {
    type: 'stopped',
};
