import Box from '@amzn/awsui-components-react/polaris/box';
import Header, {
    HeaderProps,
} from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { ReactNode } from 'react';
import { useRecoilState } from 'recoil';

import { toolsState } from '../../atoms';

interface PageHeaderProps
    extends Pick<HeaderProps, 'actions' | 'description' | 'counter'> {
    title: ReactNode;
    toolsContent?: ReactNode;
    disableTool?: boolean;
}

export const PageHeader = ({
    title,
    actions,
    toolsContent,
    description,
    disableTool,
    counter,
}: PageHeaderProps) => {
    const [tools, setTools] = useRecoilState(toolsState);

    return (
        <Box
            margin={{
                bottom: 'm',
                top: 'xs',
            }}>
            <Header
                variant="h1"
                counter={counter}
                info={
                    !disableTool &&
                    !tools.hide && (
                        <Link
                            onFollow={() =>
                                setTools({
                                    hide: tools.hide,
                                    open: true,
                                    content: toolsContent || tools.content,
                                })
                            }
                            variant="info">
                            Info
                        </Link>
                    )
                }
                actions={
                    actions && (
                        <SpaceBetween direction="horizontal" size="xs">
                            {actions}
                        </SpaceBetween>
                    )
                }
                description={description ? description : null}>
                {title}
            </Header>
        </Box>
    );
};
