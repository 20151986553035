import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import EventTeamState from 'pages/Events/EventDetails/components/EventTeamState';
import React from 'react';
import getDisplayValue from 'utils/get-display-value';

import { i18nStrings, tableConfig } from '../../../../../../../../../constants';
import TeamDetailsLink from '../../../../../TeamDetailsLink';
import { TeamDataTableItem } from './types';

export enum ColumnID {
    NAME = 'name',
    PARTICIPANT_COUNT = 'participant_count',
    TEAM_ID = 'team_id',
    TEAM_ACCESS_CODE = 'team_access_code',
    STATE = 'state',
    DEPLOYMENT_DURATION = 'deployment_duration',
    ACCOUNT_ID = 'account_id',
    JOIN_EVENT_URL = 'join_event_url',
    LAST_JOINED_AT = 'last_joined_at',
    LAST_LOGIN_AT = 'last_login_at',
    LAST_UPDATED_AT = 'last_updated_at',
}

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    visibleContent: [
        ColumnID.NAME,
        ColumnID.TEAM_ACCESS_CODE,
        ColumnID.PARTICIPANT_COUNT,
        ColumnID.STATE,
        ColumnID.DEPLOYMENT_DURATION,
        ColumnID.ACCOUNT_ID,
        ColumnID.LAST_JOINED_AT,
        ColumnID.LAST_LOGIN_AT,
    ],
};

type ColumnFieldMap = Record<ColumnID, keyof TeamDataTableItem>;

export const columnFieldMap: ColumnFieldMap = {
    [ColumnID.NAME]: 'name',
    [ColumnID.PARTICIPANT_COUNT]: 'participantCount',
    [ColumnID.TEAM_ID]: 'teamId',
    [ColumnID.TEAM_ACCESS_CODE]: 'teamCode',
    [ColumnID.STATE]: 'displayState',
    [ColumnID.DEPLOYMENT_DURATION]: 'deploymentDuration',
    [ColumnID.ACCOUNT_ID]: 'accountId',
    [ColumnID.JOIN_EVENT_URL]: 'joinEventUrl',
    [ColumnID.LAST_JOINED_AT]: 'lastJoinedAt',
    [ColumnID.LAST_LOGIN_AT]: 'lastLoginAt',
    [ColumnID.LAST_UPDATED_AT]: 'lastUpdatedAt',
};

const getTeamDetailsLink = ({ eventId, teamId, name }: TeamDataTableItem) => (
    <TeamDetailsLink eventId={eventId} teamId={teamId}>
        {name}
    </TeamDetailsLink>
);
const getTeamState = ({ state }: TeamDataTableItem) => (
    <EventTeamState state={state} />
);

type ColumnDefinition = TableProps.ColumnDefinition<TeamDataTableItem> &
    Partial<CollectionPreferencesProps.VisibleContentOption>;

type ColumnDefinitions = Record<
    EventEngineEventsService.EventAccountSource,
    ColumnDefinition[]
>;

export const SORTING_COMPARATOR_MAP = {
    [columnFieldMap[ColumnID.NAME]]: (
        a: TeamDataTableItem,
        b: TeamDataTableItem
    ) => a.name.localeCompare(b.name, 'en', { numeric: true }),
};

export const defaultSortPreferences: TableProps.SortingState<TeamDataTableItem> = {
    ...tableConfig.defaultSortPreferences,
    sortingColumn: {
        sortingField: columnFieldMap[ColumnID.NAME],
        sortingComparator: SORTING_COMPARATOR_MAP[ColumnID.NAME],
    },
};

const COLUMN_DEFINITIONS: ColumnDefinition[] = [
    {
        id: ColumnID.NAME,
        header: i18nStrings.events.teams.table.columns.name,
        cell: (item) => getTeamDetailsLink(item),
        sortingField: columnFieldMap[ColumnID.NAME],
        sortingComparator:
            SORTING_COMPARATOR_MAP[columnFieldMap[ColumnID.NAME]],
        editable: false,
    },
    {
        id: ColumnID.TEAM_ACCESS_CODE,
        header: i18nStrings.events.teams.table.columns.teamAccessCode,
        cell: (item) => getDisplayValue(item.teamCode, { copyable: true }),
        sortingField: columnFieldMap[ColumnID.TEAM_ACCESS_CODE],
    },
    {
        id: ColumnID.TEAM_ID,
        header: i18nStrings.events.teams.table.columns.teamId,
        cell: (item) => item.teamId,
        sortingField: columnFieldMap[ColumnID.TEAM_ID],
    },
    {
        id: ColumnID.PARTICIPANT_COUNT,
        header: i18nStrings.events.teams.table.columns.participants,
        cell: (item) => getDisplayValue(item.participantCount),
        sortingField: columnFieldMap[ColumnID.PARTICIPANT_COUNT],
    },
    {
        id: ColumnID.STATE,
        header: i18nStrings.events.teams.table.columns.state,
        cell: (item) => getTeamState(item),
        sortingField: columnFieldMap[ColumnID.STATE],
    },
    {
        id: ColumnID.DEPLOYMENT_DURATION,
        header: i18nStrings.events.teams.table.columns.deploymentDuration,
        cell: ({ deploymentDuration }) => getDisplayValue(deploymentDuration),
        sortingField: columnFieldMap[ColumnID.DEPLOYMENT_DURATION],
    },
    {
        id: ColumnID.ACCOUNT_ID,
        header: i18nStrings.events.teams.table.columns.awsAccountId,
        cell: (item) => getDisplayValue(item.accountId),
        sortingField: columnFieldMap[ColumnID.ACCOUNT_ID],
    },
    {
        id: ColumnID.JOIN_EVENT_URL,
        header: i18nStrings.events.teams.table.columns.joinEventUrl,
        cell: (item) =>
            getDisplayValue(item.joinEventUrl, {
                copyable: true,
                isLink: true,
            }),
        sortingField: columnFieldMap[ColumnID.JOIN_EVENT_URL],
    },
    {
        id: ColumnID.LAST_JOINED_AT,
        header: i18nStrings.events.teams.table.columns.lastJoined,
        cell: (item) => getDisplayValue(item.lastJoinedAt),
        sortingField: columnFieldMap[ColumnID.LAST_JOINED_AT],
    },
    {
        id: ColumnID.LAST_LOGIN_AT,
        header: i18nStrings.events.teams.table.columns.lastAccountAccess,
        cell: (item) => getDisplayValue(item.lastLoginAt),
        sortingField: columnFieldMap[ColumnID.LAST_LOGIN_AT],
    },
    {
        id: ColumnID.LAST_UPDATED_AT,
        header: i18nStrings.events.teams.table.columns.lastModified,
        cell: (item) => getDisplayValue(item.lastUpdatedAt),
        sortingField: columnFieldMap[ColumnID.LAST_UPDATED_AT],
    },
];

const COLUMN_DEFINITIONS_MAP = COLUMN_DEFINITIONS.reduce(
    (acc, a) => ({
        ...acc,
        [a.id!]: a,
    }),
    {} as Record<ColumnID, ColumnDefinition>
);

export const columnDefinitions: ColumnDefinitions = {
    [EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO]: [...COLUMN_DEFINITIONS],
    [EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED]: [
        COLUMN_DEFINITIONS_MAP[ColumnID.NAME],
        COLUMN_DEFINITIONS_MAP[ColumnID.TEAM_ACCESS_CODE],
        COLUMN_DEFINITIONS_MAP[ColumnID.TEAM_ID],
        COLUMN_DEFINITIONS_MAP[ColumnID.PARTICIPANT_COUNT],
        COLUMN_DEFINITIONS_MAP[ColumnID.STATE],
        COLUMN_DEFINITIONS_MAP[ColumnID.JOIN_EVENT_URL],
        COLUMN_DEFINITIONS_MAP[ColumnID.LAST_JOINED_AT],
        COLUMN_DEFINITIONS_MAP[ColumnID.LAST_UPDATED_AT],
    ],
};

export const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: 'and',
};

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        key: columnFieldMap[ColumnID.NAME],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.teams.table.columns.name,
        groupValuesLabel: i18nStrings.events.teams.table.propertyFilter.name,
    },
    {
        key: columnFieldMap[ColumnID.TEAM_ACCESS_CODE],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.teams.table.columns.teamAccessCode,
        groupValuesLabel:
            i18nStrings.events.teams.table.propertyFilter.teamAccessCode,
    },
    {
        key: columnFieldMap[ColumnID.TEAM_ID],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.teams.table.columns.teamId,
        groupValuesLabel: i18nStrings.events.teams.table.propertyFilter.teamId,
    },
    {
        key: columnFieldMap[ColumnID.PARTICIPANT_COUNT],
        operators: ['=', '!=', '>', '>=', '<', '<='],
        propertyLabel: i18nStrings.events.teams.table.columns.participants,
        groupValuesLabel:
            i18nStrings.events.teams.table.propertyFilter.participants,
    },
    {
        key: columnFieldMap[ColumnID.STATE],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.teams.table.columns.state,
        groupValuesLabel: i18nStrings.events.teams.table.propertyFilter.state,
        showOptions: true,
    },
    {
        key: columnFieldMap[ColumnID.ACCOUNT_ID],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.events.teams.table.columns.awsAccountId,
        groupValuesLabel:
            i18nStrings.events.teams.table.propertyFilter.awsAccountId,
        showOptions: true,
    },
];
