import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import { SignInCallback } from '@amzn/event-engine-js-utils';
import { isAuthenticatedState } from 'atoms';
import SpinnerWithLabel from 'components/SpinnerWithLabel';
import React, { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { i18nStrings } from '../../constants';

const SignInCallbackContainer = () => {
    const [signInError, setSignInError] = useState(false);
    const setIsAuth = useSetRecoilState(isAuthenticatedState);
    const handleSignIn = useCallback(() => {
        setIsAuth(true);
    }, []);
    const handleSignInFailure = useCallback(() => {
        document.getElementById('raw-loader-container')?.remove();
        setSignInError(true);
    }, []);

    if (signInError) {
        return (
            <Box margin="l">
                <Alert
                    header={i18nStrings.signIn.callback.signInFailure.header}
                    type="error">
                    {i18nStrings.signIn.callback.signInFailure.content}
                </Alert>
            </Box>
        );
    }

    return (
        <SignInCallback
            onSignInFailure={handleSignInFailure}
            onSignIn={handleSignIn}>
            <SpinnerWithLabel>
                {i18nStrings.signIn.callback.signingIn}
            </SpinnerWithLabel>
        </SignInCallback>
    );
};

export default SignInCallbackContainer;
