import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import React from 'react';

export type ErrorType =
    | '404'
    | 'catastrophic'
    | 'preview-component-catastrophic-error';

interface IErrorPageController {
    type: ErrorType;
}

const NotFoundComponent = (): JSX.Element => {
    return (
        <TextContent data-testid="not-found-component">
            <h1>The page you&apos;re looking for can&apos;t be found.</h1>
        </TextContent>
    );
};

const CatastrophicComponent = (): JSX.Element => {
    return (
        <Alert
            type="error"
            dismissible={false}
            header="Something went wrong"
            data-testid="catastrophic-component"
            dismissAriaLabel={
                'Something went wrong, please refresh the page or try again later.'
            }>
            Please refresh the page or try again later.
        </Alert>
    );
};

const PreviewComponentCatastrophicError = (): JSX.Element => {
    return (
        <Alert
            type="error"
            dismissible={false}
            header="Something went wrong"
            data-testid="preview-component-catastrophic-error">
            There was a problem previewing this build. Please try again later,
            or try previewing an older build.
        </Alert>
    );
};

const ErrorPageMap: Record<ErrorType, JSX.Element> = {
    '404': <NotFoundComponent />,
    catastrophic: <CatastrophicComponent />,
    'preview-component-catastrophic-error': (
        <PreviewComponentCatastrophicError />
    ),
};

const ErrorPageController = ({ type }: IErrorPageController) => {
    const errorComponent = ErrorPageMap[type] || <NotFoundComponent />;

    return <Box padding={'m'}>{errorComponent}</Box>;
};

export default ErrorPageController;
