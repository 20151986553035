import { format } from 'date-fns';
import { getUTCOffsetLabel } from 'utils/date-fns';
import { DATE_FORMATS } from 'utils/date-fns/constants';

import { i18nStrings } from '../../../../../../constants';

const getDisplayDate = (date?: Date) => [
    date
        ? format(date, DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD)
        : i18nStrings.empty,
    // date is in UTC time and is displayed in the system local timezone
    getUTCOffsetLabel(Intl.DateTimeFormat().resolvedOptions().timeZone),
];

export default getDisplayDate;
