import { useLazyRequest, useModal } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import React, { useCallback, useContext, useEffect } from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';

import { i18nStrings } from '../../../../../constants';
import { BanParticipantModalProps, NotificationID } from './types';

const BanParticipantModal = ({
    onDismiss,
    onUpdateSuccess,
    participant,
    isVisible = false,
}: BanParticipantModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const [
        banParticipant,
        {
            isError: banParticipantError,
            isLoading: isBanParticipantLoading,
            data: banParticipantData,
        },
    ] = useLazyRequest<typeof EEEvents.banParticipant, AWSError>(
        EEEvents.banParticipant,
        {}
    );
    const confirmBanParticipant = useCallback(() => {
        banParticipant({
            eventId: participant.eventId,
            participantId: participant.participantId,
        });
    }, [participant]);
    const { modalComponent } = useModal({
        isVisible,
        onDismiss,
        modalHeader:
            i18nStrings.events.participants.banParticipantConfirmation.header,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onDismiss,
            },
            primary: {
                text:
                    i18nStrings.events.participants.banParticipantConfirmation
                        .primaryAction,
                loading: isBanParticipantLoading,
                onClick: confirmBanParticipant,
            },
        },
        content: (
            <>
                {
                    i18nStrings.events.participants.banParticipantConfirmation
                        .content
                }
            </>
        ),
    });

    useEffect(() => {
        if (!banParticipantData) {
            return;
        }

        onUpdateSuccess();
        showNotification(
            i18nStrings.events.participants.banParticipantConfirmation
                .notifications.banSuccess,
            {
                id: NotificationID.UPDATE_RESPONSE,
                type: 'success',
            }
        );
    }, [banParticipantData]);

    useEffect(() => {
        banParticipantError &&
            showNotification(
                ErrorMessage.getMessage(
                    banParticipantError,
                    MessageTypes.banParticipant
                ),
                {
                    id: NotificationID.UPDATE_RESPONSE,
                    type: 'error',
                    clearStack: false,
                }
            );
    }, [banParticipantError]);

    useEffect(() => {
        if (!(banParticipantData || banParticipantError)) {
            return;
        }

        onDismiss();
    }, [banParticipantData, banParticipantError]);

    return modalComponent;
};

export default BanParticipantModal;
