// General
export const DEFAULT_POLL_MS = 10000;
export const DEFAULT_API_PAGE_SIZE = 100;
export const SELECT_FILTERING_MIN_OPTIONS = 10;
export const RELATIVE_TIME_MAX_MS: number = 1000 * 60 * 60 * 24 * 30;
export const USER_ID_SELF = 'whoami';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const JUST_NOW_TIME_WINDOW_MS = 60000;

// Content
export const MAX_PINS = 5;

// Events
export const MAX_EVENT_DURATION = 72;
export const MAX_TEAM_SIZE = 10;
export const MAX_TRANSFERABLE_QUOTA = 200;
export const SCHEDULED_EVENT_AUTO_CANCELLATION_WINDOW_DAYS = 7;
export const EVENT_AUTOSTART_WINDOW_HOURS = 48;
export const PROVISIONED_EVENT_AUTO_START_WINDOW_DAYS = 2;
export const EVENT_DURATION_ACKNOWLEDGEMENT_THRESHOLD = 12;

// Documentation
export const DOCUMENTATION_PATHS = {
    DETAILED_DOCUMENTATION: 'detailed-documentation',
    FINDING_A_WORKSHOP: 'run-a-workshop/finding-a-workshop',
    AUTHORING_A_WORKSHOP: 'create-a-workshop/authoring-a-workshop',
    REVIEW_AND_PUBLISH_A_WORKSHOP:
        'create-a-workshop/reviewing-and-publishing-a-workshop',
    DIRECTIVE_ASSET_URL: 'detailed-documentation/directives/asset-url',
    MANAGING_WORKSHOP_ASSETS:
        'create-a-workshop/authoring-a-workshop/managing-workshop-assets',
    STATIC_ASSETS: 'detailed-documentation/workshop-assets/s3-assets',
    CONNECTING_TO_YOUR_REPOSITORY:
        'create-a-workshop/authoring-a-workshop/connecting-to-your-repository',
};
export const DOCUMENTATION_PAGE_ANCHORS = {
    ENVIRONMENT_DIFFERENCES: 'url-differences-across-environments',
    PREREQUISITES: 'prerequisites',
};
