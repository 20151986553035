import {
    AWS_REGION,
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import {
    AwsRegion,
    EventAccountSource,
    EventType,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { EventMatrix, formatString } from '@amzn/event-engine-js-utils';
import { ContentSpecSummary } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { addDays, addMinutes, addMonths, isAfter, isEqual } from 'date-fns';
import { MAX_EVENT_DURATION } from 'utils/config';
import { MAX_TEAM_SIZE } from 'utils/config';
import { zonedTimeToUtc } from 'utils/date-fns';
import pluralizeSimple from 'utils/pluralize-simple';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../constants';
import ParticipantAllowlistSchema from '../steps/ParticipantAllowlist/schema';
import {
    filterRegionOptions,
    getAccessibleRegionsConstraints,
    getMaxParticipantForecast,
    getRegionSelectOptions,
    isEventReady,
    isFieldValid,
} from '../utils';
import {
    DEFAULT_ACCESSIBLE_REGIONS,
    REQUIRED_REGION_CATEGORIES,
} from '../utils/constants';
import { AttributeFormType } from '../utils/create-event-attributes';

export interface FormContext {
    maxEventSize?: {
        test: number;
        production: number;
    };
    editMatrix?: EventMatrix;
    contentSpecSummary?: ContentSpecSummary;
}

export enum Step {
    SELECT_WORKSHOP = 'SELECT_WORKSHOP',
    EVENT_TYPE = 'EVENT_TYPE',
    EVENT_DETAILS = 'EVENT_DETAILS',
    PARTICIPANT_ALLOWLIST = 'PARTICIPANT_ALLOWLIST',
}

const BaseStringValidator = Yup.string().trim();

export const facilitatorsSchema = Yup.array()
    .max(50, 'Number of facilitators must be less than 50')
    .of(
        Yup.object()
            .shape({
                key: Yup.string()
                    .email('Invalid email')
                    .required(i18nStrings.required),
                value: Yup.string().required('Provide a role'),
            })
            .required()
    )
    .ensure();

export const RegionOptionSchema = Yup.object({
    label: Yup.string().required(),
    value: Yup.mixed<AwsRegion>().oneOf(Object.values(AWS_REGION)).required(),
});

const DeployableRegionsSchema = BaseStringValidator.test({
    message: i18nStrings.required,
    test: (value, context) => {
        const { contentSpecSummary, editMatrix }: FormContext =
            context.options.context || {};

        if (
            editMatrix?.accountSource ===
                EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED ||
            !getRegionSelectOptions(contentSpecSummary?.deployableRegions)
                .length
        ) {
            // Regions are not required for customer provided account sources.
            // Deployment region may be optional if the configuration is not
            // provided.
            return true;
        }

        return isFieldValid(context.options.context, 'regions', !!value);
    },
});

const AccessibleRegionsSchema = Yup.array()
    .of(RegionOptionSchema)
    .test({
        message: i18nStrings.required,
        test: (values, context) => {
            if (!values) {
                return isFieldValid(context.options.context, 'regions', false);
            }

            const { contentSpecSummary }: FormContext =
                context.options.context || {};
            const { min, max } = getAccessibleRegionsConstraints(
                contentSpecSummary
            );
            const requiredRegionOptions = filterRegionOptions(
                getRegionSelectOptions(
                    contentSpecSummary?.accessibleRegions,
                    DEFAULT_ACCESSIBLE_REGIONS
                ),
                REQUIRED_REGION_CATEGORIES
            );
            let requiredRegionsCount = requiredRegionOptions.length;

            // Include selected deployable region in required region count if it
            // is not one of the system default regions
            if (
                context.parent.deploymentRegions &&
                !DEFAULT_ACCESSIBLE_REGIONS.some(
                    ({ value }) => value === context.parent.deploymentRegions
                )
            ) {
                requiredRegionsCount += 1;
            }

            if (values.length < min) {
                const minRequired = min - requiredRegionsCount;
                return isFieldValid(
                    context.options.context,
                    'regions',
                    context.createError({
                        message: formatString(
                            i18nStrings.events.create.fields.accessibleRegions
                                .errorMinAdditional,
                            minRequired,
                            pluralizeSimple('region', minRequired)
                        ),
                    })
                );
            } else if (values.length > max) {
                const maxSelectable = max - requiredRegionsCount;
                let error: Yup.CreateErrorOptions = {
                    message: formatString(
                        i18nStrings.events.create.fields.accessibleRegions
                            .errorMax,
                        max,
                        pluralizeSimple('region', max)
                    ),
                };

                if (maxSelectable > 0) {
                    error = {
                        message: formatString(
                            i18nStrings.events.create.fields.accessibleRegions
                                .errorMaxAdditional,
                            maxSelectable,
                            pluralizeSimple('region', maxSelectable)
                        ),
                    };
                }

                return isFieldValid(
                    context.options.context,
                    'regions',
                    context.createError(error)
                );
            }

            return true;
        },
    });

export const RegionsSchema = Yup.object({
    deploymentRegions: DeployableRegionsSchema,
    accessibleRegions: AccessibleRegionsSchema,
});

const EventDetailsSchema = Yup.object({
    title: BaseStringValidator.max(
        100,
        'Provide a title less than 100 characters'
    )
        .required(i18nStrings.required)
        .test((value, context) =>
            isFieldValid(context.options.context, 'title', !!value)
        ),
    description: BaseStringValidator.max(
        500,
        'Provide a description less than 500 characters'
    )
        .required(i18nStrings.required)
        .test((value, context) =>
            isFieldValid(context.options.context, 'description', !!value)
        ),
    eventDate: BaseStringValidator.required(i18nStrings.required).test({
        message: i18nStrings.events.create.fields.startDateTime.errorMin,
        test: (value, context) => {
            const { editMatrix }: FormContext = context.options.context || {};
            if (value === undefined) {
                return false;
            }

            const [year, month, day] = value.split('-');

            if (!(year && month && day)) {
                return false;
            }

            const { parent: parentContext } = context;
            const chosenDate = zonedTimeToUtc(
                parentContext.eventDate,
                parentContext.eventTime,
                parentContext.period,
                {
                    timezone: parentContext.timezone,
                }
            ) as Date;
            const isTestEvent = editMatrix?.type === EVENT_TYPE.TEST;
            const minStartDate = addMinutes(new Date(), 1);
            const maxStartDate = isTestEvent
                ? addDays(new Date(), 3)
                : addMonths(new Date(), 3);
            const message = isTestEvent
                ? i18nStrings.events.create.fields.startDateTime.errorMaxTest
                : i18nStrings.events.create.fields.startDateTime.errorMaxProd;
            if (isAfter(chosenDate, maxStartDate)) {
                return context.createError({
                    message,
                });
            }

            return isFieldValid(
                context.options.context,
                'eventStartDateTime',
                isEqual(chosenDate, minStartDate) ||
                    isAfter(chosenDate, minStartDate)
            );
        },
    }),
    eventTime: BaseStringValidator.required(
        i18nStrings.required
    ).test((value, context) =>
        isFieldValid(context.options.context, 'eventStartDateTime', !!value)
    ),
    period: BaseStringValidator.required(
        i18nStrings.required
    ).test((value, context) =>
        isFieldValid(context.options.context, 'eventStartDateTime', !!value)
    ),
    timezone: BaseStringValidator.required(
        i18nStrings.required
    ).test((value, context) =>
        isFieldValid(context.options.context, 'eventStartDateTime', !!value)
    ),
    duration: Yup.number()
        .integer(i18nStrings.events.create.fields.duration.errorWholeNumber)
        .min(1, i18nStrings.events.create.fields.duration.errorMin)
        .max(
            MAX_EVENT_DURATION,
            formatString(
                i18nStrings.events.create.fields.duration.errorMax,
                MAX_EVENT_DURATION
            )
        )
        .required(i18nStrings.required)
        .test((value, context) =>
            isFieldValid(context.options.context, 'duration', !!value)
        ),
    modality: BaseStringValidator.test({
        message: i18nStrings.required,
        test: (value, context) =>
            isFieldValid(context.options.context, 'eventModality', !!value),
    }),
    forecastedAttendees: Yup.number()
        .integer(
            i18nStrings.events.create.fields.attendeeForecast.errorWholeNumber
        )
        .min(1, i18nStrings.events.create.fields.attendeeForecast.errorMin)
        .required(i18nStrings.required)
        .test((value: number | undefined, context) => {
            if (value === undefined) {
                return isFieldValid(
                    context.options.context,
                    'attendeeForecast',
                    false
                );
            }

            const { maxEventSize, editMatrix }: FormContext =
                context.options.context || {};
            const { parent: parentContext } = context;

            if (!maxEventSize) {
                return true;
            }

            let max: number | undefined;
            let message: string | undefined;

            switch (editMatrix?.type) {
                case EVENT_TYPE.TEST:
                    max = maxEventSize.test;
                    message =
                        i18nStrings.events.create.fields.attendeeForecast
                            .errorMaxTestEvent;
                    break;
                case EVENT_TYPE.PRODUCTION:
                    max = maxEventSize.production;
                    message =
                        i18nStrings.events.create.fields.attendeeForecast
                            .errorMaxProductionEvent;
                    break;
            }

            if (max !== undefined) {
                max = getMaxParticipantForecast(max, parentContext.teamSize);

                if (value > max) {
                    return isFieldValid(
                        context.options.context,
                        'attendeeForecast',
                        context.createError({
                            message: formatString(message, max),
                        })
                    );
                }
            }

            return true;
        }),
    teamSize: Yup.number()
        .integer(i18nStrings.events.create.fields.teamSize.errorWholeNumber)
        .required(i18nStrings.required)
        .min(1, i18nStrings.events.create.fields.teamSize.errorMin)
        .max(
            MAX_TEAM_SIZE,
            formatString(
                i18nStrings.events.create.fields.teamSize.errorMax,
                MAX_TEAM_SIZE
            )
        ),
    initialQuotaContribution: Yup.number()
        .integer(
            i18nStrings.events.create.fields.initialQuotaContribution
                .errorWholeNumber
        )
        .min(
            0,
            i18nStrings.events.create.fields.initialQuotaContribution.errorMin
        )
        .test((value: number | undefined, context) => {
            if (value === undefined) {
                return true;
            }

            const availableQuota: number | undefined =
                context.parent.availableQuota;
            const { maxEventSize, editMatrix }: FormContext =
                context.options.context || {};

            if (!maxEventSize) {
                return true;
            }

            let maxReservation =
                editMatrix?.type === EVENT_TYPE.TEST
                    ? maxEventSize.test
                    : maxEventSize.production;

            if (
                typeof availableQuota === 'number' &&
                availableQuota < maxReservation
            ) {
                maxReservation = availableQuota;
            }

            if (value > maxReservation) {
                return context.createError({
                    message: formatString(
                        i18nStrings.events.create.fields
                            .initialQuotaContribution.errorMax,
                        maxReservation
                    ),
                });
            }

            return true;
        })
        .optional(),
    availableQuota: Yup.number().optional(),
    facilitators: facilitatorsSchema,
}).concat(RegionsSchema);

const EditEventFormSchema = Yup.object({
    [Step.EVENT_DETAILS]: EventDetailsSchema,
});

const EventTypeSchema = Yup.object({
    eventType: Yup.mixed<EventType>()
        .oneOf(Object.values(EVENT_TYPE))
        .required('Required'),
    accountSource: Yup.mixed<EventAccountSource>()
        .oneOf(Object.values(EVENT_ACCOUNT_SOURCE))
        .required('Required'),
});

export interface SelectWorkshopValue {
    contentId: string;
    contentBuildId: string;
    contentSpecSummary: ContentSpecSummary;
    title: string;
    publishedBuildId?: string;
}

const SelectWorkshopSchema = Yup.mixed<SelectWorkshopValue>()
    .required(i18nStrings.events.create.errors.requiredWorkshop)
    .test((value, context) => {
        if (!value) {
            return context.createError({
                message: i18nStrings.events.create.errors.requiredWorkshop,
            });
        }

        if (
            !(
                value.contentId &&
                value.contentBuildId &&
                isEventReady(value.contentSpecSummary)
            )
        ) {
            return context.createError({
                message: i18nStrings.events.create.errors.badWorkshopData,
            });
        }

        return !!value;
    })
    .default(undefined);

/**
 * Validators created based off of https://code.amazon.com/reviews/CR-49843970/revisions
 */
const FormSchema = Yup.object({
    [Step.SELECT_WORKSHOP]: SelectWorkshopSchema,
    [Step.EVENT_TYPE]: EventTypeSchema,
    [Step.EVENT_DETAILS]: EventDetailsSchema,
    [Step.PARTICIPANT_ALLOWLIST]: ParticipantAllowlistSchema,
});

type FormSchemaType = Yup.InferType<typeof FormSchema>;
type MergedAttributeType = FormSchemaType[Step.EVENT_DETAILS] &
    AttributeFormType;

export type MergedFormSchema = FormSchemaType & {
    [Step.EVENT_DETAILS]: MergedAttributeType;
};

type EditEventFormType = Yup.InferType<typeof EditEventFormSchema>;
export type MergedEditEventFormSchema = EditEventFormType & {
    [Step.EVENT_DETAILS]: MergedAttributeType;
};

export { EditEventFormSchema, FormSchema };
