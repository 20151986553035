import { QUOTA_ID } from '__generated__/@amzn/event-engine-events-sdk/enums';
import StatusIndicator, {
    StatusIndicatorProps,
} from '@amzn/awsui-components-react/polaris/status-indicator';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { formatString, useLazyRequest } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { getMonth, isFuture } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { useEffect, useMemo, useState } from 'react';
import EEEvents from 'services/ee-events';
import { zonedTimeToUtc } from 'utils/date-fns';

import { i18nStrings } from '../../../../constants';
import styles from './AccountQuotaStatus.module.scss';

export interface AccountQuotaStatusProps {
    value?: number;
    debounce?: number;
    blockDate: {
        date?: string;
        time?: string;
        period?: string;
        timezone?: string;
    };
    min?: number;
    onResolve?: (data?: EventEngineEventsService.QuotaMonthly) => void;
}

const AccountQuotaStatus = ({
    value,
    debounce = 350,
    blockDate: { date, time, period, timezone },
    min,
    onResolve,
}: AccountQuotaStatusProps) => {
    const intValue = parseInt(String(value), 10);
    value = !isNaN(intValue) ? intValue : undefined;
    const [debounceTimeoutHandle, setDebounceTimeoutHandle] = useState<
        number
    >();
    const [getAccountQuota, { data, isLoading, isError }] = useLazyRequest<
        typeof EEEvents.getQuota,
        AWSError
    >(EEEvents.getQuota, {});
    const blockDate = useMemo(() => {
        if (!(date && time && period && timezone)) {
            return;
        }

        return zonedTimeToUtc(date, time, period, {
            timezone,
        });
    }, [date, time, period, timezone]);
    const clearDebounceTimeout = () => {
        window.clearTimeout(debounceTimeoutHandle);
        setDebounceTimeoutHandle(undefined);
    };
    const toUTCDate = (blockTime: Date) => utcToZonedTime(blockTime, 'UTC');
    const isValidBlockDate =
        blockDate &&
        (isFuture(blockDate) || getMonth(blockDate) >= getMonth(new Date()));
    const getQuotaMonthly = (
        quotaList: EventEngineEventsService.QuotaMonthlyList,
        blockDate: Date
    ) =>
        quotaList.find(
            ({ blockTime }) =>
                getMonth(toUTCDate(blockTime)) === getMonth(blockDate)
        );
    const statusIndicatorProps: StatusIndicatorProps = {};

    useEffect(() => {
        clearDebounceTimeout();

        if (!isValidBlockDate) {
            return;
        }

        setDebounceTimeoutHandle(
            window.setTimeout(() => {
                getAccountQuota({
                    quotaId: QUOTA_ID.ACCOUNTS_WORKSHOP_STUDIO,
                });

                clearDebounceTimeout();
            }, debounce)
        );
    }, [value, blockDate]);

    useEffect(() => {
        data?.quota?.quotaMonthly &&
            blockDate &&
            onResolve?.(getQuotaMonthly(data.quota.quotaMonthly, blockDate));
    }, [data, blockDate]);

    if (!blockDate) {
        return null;
    }

    if (!isValidBlockDate) {
        statusIndicatorProps.type = 'warning';
        statusIndicatorProps.children =
            i18nStrings.events.accountQuotaStatus.errorPreviousMonth;
    } else if (debounceTimeoutHandle || isLoading) {
        statusIndicatorProps.type = 'loading';
        statusIndicatorProps.children =
            i18nStrings.events.accountQuotaStatus.inProgress;
    } else if (isError) {
        statusIndicatorProps.type = 'warning';
        statusIndicatorProps.children =
            i18nStrings.events.accountQuotaStatus.serviceError;
    } else if (blockDate && data?.quota?.quotaMonthly) {
        const quota = getQuotaMonthly(data.quota.quotaMonthly, blockDate);

        if (!quota) {
            statusIndicatorProps.type = 'warning';
            statusIndicatorProps.children =
                i18nStrings.events.accountQuotaStatus.outOfRange;
        } else if (quota.available <= 0) {
            statusIndicatorProps.type = 'warning';
            statusIndicatorProps.children =
                i18nStrings.events.accountQuotaStatus.nothingAvailable;
        } else if (
            typeof value !== 'number' ||
            (typeof value === 'number' && value <= 0)
        ) {
            statusIndicatorProps.children = formatString(
                i18nStrings.events.accountQuotaStatus.constraint,
                quota.available
            );
        } else if (value > quota.available) {
            statusIndicatorProps.type = 'warning';
            statusIndicatorProps.children =
                quota.available > 0
                    ? formatString(
                          i18nStrings.events.accountQuotaStatus
                              .exceedsAvailable,
                          quota.available
                      )
                    : i18nStrings.events.accountQuotaStatus.nothingAvailable;
        } else if (min && min > quota.available) {
            statusIndicatorProps.type = 'info';
            statusIndicatorProps.children = formatString(
                i18nStrings.events.accountQuotaStatus
                    .exceedsParticipantForecast,
                quota.available,
                min
            );
        } else if (value <= quota.available) {
            statusIndicatorProps.type = 'success';
            statusIndicatorProps.children = formatString(
                i18nStrings.events.accountQuotaStatus.valid,
                quota.available
            );
        }
    }

    if (!statusIndicatorProps.children) {
        return null;
    }

    if (!statusIndicatorProps.type) {
        return <>{statusIndicatorProps.children}</>;
    }

    return (
        <span className={styles.status}>
            <StatusIndicator {...statusIndicatorProps} />
        </span>
    );
};

export default AccountQuotaStatus;
