import { isValid } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

import leftPad from '../left-pad';
import { DATE_FORMATS } from './constants';
import zonedTimeToUtc from './zoned-time-to-utc';

const millisecondsInMinute = 60 * 1000;
const millisecondsInHour = 60 * millisecondsInMinute;

const PREFIXES = {
    GMT: 'GMT',
    UTC: 'UTC',
};

interface UTCOffsetLabelOptions {
    date: string;
    time: string;
    period: string;
    prefix: string;
    format?: string;
}

/**
 * Gets a GMT/UTC offset string in +/-00:00 format
 * @param {string} timezone
 * @param {UTCOffsetLabelOptions} options
 * @returns {string}
 */
const getUTCOffsetLabel = (
    timezone: string,
    options?: Partial<UTCOffsetLabelOptions>
) => {
    let date: Date | undefined = undefined;
    if (options?.date && options.time && options.period) {
        date = zonedTimeToUtc(options.date, options.time, options.period, {
            format: options?.format,
            // We need a reference UTC date to pass to getTimezoneOffset in order to
            // correctly calculate offsets during DST. zonedTimeToUtc creates a UTC Date from zoned
            // date values in a specified timezone, so passing the current timezone value here is a
            // logical choice, but it yields unexpected results. To get the correct offsets during
            // DST, we need to specify UTC as the timezone. This does not really make sense since
            // the specified date/time/period values are likely not zoned to UTC/GMT, but it appears
            // this is what we need to do for now to get the correct DST offsets from getTimezoneOffset...
            timezone: 'UTC',
        }) as Date;
    } else if (options?.date && options.time) {
        date = zonedTimeToUtc(options.date, options.time, undefined, {
            format: options?.format || DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
            timezone: 'UTC',
        }) as Date;
    } else if (options?.date) {
        date = new Date(options.date);
    }
    if (!date || !isValid(date)) {
        date = new Date();
    }
    const offsetMilliseconds = getTimezoneOffset(timezone, date.getTime());
    const offsetHours = Math.abs(
        Math.floor(offsetMilliseconds / millisecondsInHour)
    );
    const offsetMinutes = Math.abs(
        Math.floor(
            (Math.abs(offsetMilliseconds) - offsetHours * millisecondsInHour) /
                millisecondsInMinute
        )
    );
    const offsetLabel = `${offsetMilliseconds < 0 ? '-' : '+'}${leftPad(
        offsetHours,
        2,
        '0'
    )}:${leftPad(offsetMinutes, 2, '0')}`;
    return `${options?.prefix || PREFIXES.GMT}${offsetLabel}`;
};

export default getUTCOffsetLabel;
