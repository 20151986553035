import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { ContentSpecRegions } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

import { i18nStrings } from '../../../../constants';

/**
 * Returns flattened list of regions based on contentspec config
 *
 * @param {ContentSpecRegions} contentRegions
 * @param {MultiselectProps.Option[]} defaultRegions
 * @returns {MultiselectProps.Option[]}
 */
const getRegionSelectOptions = (
    contentRegions?: ContentSpecRegions,
    defaultRegions: MultiselectProps.Option[] = []
) => {
    const regionOptions: MultiselectProps.Option[] = defaultRegions.map(
        ({ value, description }) => ({
            label: value,
            value,
            description,
            disabled: true,
        })
    );

    if (contentRegions) {
        const regionCategories = [
            {
                description: i18nStrings.events.regionCategories.required,
                disabled: true,
                values: contentRegions.required,
            },
            {
                description: i18nStrings.events.regionCategories.recommended,
                values: contentRegions.recommended,
            },
            {
                values: contentRegions.optional,
            },
        ];

        regionCategories.forEach(
            ({ description, disabled, values: regions }) => {
                regions &&
                    regions.forEach((region) => {
                        const existingOption = regionOptions.find(
                            (option) => option.value === region
                        );
                        if (existingOption) {
                            existingOption.description =
                                existingOption.description || description;
                            existingOption.disabled =
                                existingOption.disabled || disabled;
                        } else {
                            regionOptions.push({
                                label: region,
                                value: region,
                                description,
                                disabled,
                            });
                        }
                    });
            }
        );
    }

    return regionOptions;
};

export default getRegionSelectOptions;
