import { QUOTA_ID } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { GetQuotaResponse } from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';

export const GET_QUOTA_RESPONSE: GetQuotaResponse = {
    quota: {
        quota: {
            limit: 250,
            available: 250,
            consumed: 0,
        },
        quotaId: QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT,
        quotaMonthly: [
            {
                available: 75,
                blockTime: new Date('2099-12-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
            {
                available: 75,
                blockTime: new Date('2100-01-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
            {
                available: 75,
                blockTime: new Date('2100-02-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
            {
                available: 75,
                blockTime: new Date('2100-03-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
            {
                available: 54,
                blockTime: new Date('2100-04-01T00:00:00.000Z'),
                consumed: 1,
                limit: 75,
                reserved: 20,
            },
            {
                available: 0,
                blockTime: new Date('2100-05-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
            {
                available: 75,
                blockTime: new Date('2100-06-01T00:00:00.000Z'),
                consumed: 0,
                limit: 75,
                reserved: 0,
            },
        ],
        quotaType: 'quota',
    },
};
