import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useLazyRequest, usePaginator } from '@amzn/event-engine-js-utils';
import {
    ContentData,
    ListContentsRequest,
    ListContentsResponse,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import styles from 'assets/styles/modules/reset.module.scss';
import { AWSError } from 'aws-sdk/lib/error';
import { routes } from 'components/AppRoutes/routes.constants';
import {
    addDays,
    formatDistanceToNowStrict,
    formatDuration,
    isWithinInterval,
} from 'date-fns';
import {
    ACCOUNT_SOURCE_DEFINED_FILTER_OPTIONS,
    WORKSHOP_STATE_FILTER_OPTIONS,
} from 'pages/Workshops/utils';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { EEContentCatalog } from 'services';

import { i18nStrings } from '../../../../constants';
import { WorkshopCatalog as WorkshopCatalogTools } from '../../components/WorkshopTools';
import { ColumnId, DEFAULT_PAGINATION_LIMIT } from './config';
import WorkshopCatalogTableProvider from './contexts/WorkshopCatalogTableProvider';
import {
    CollectionFilter,
    TableAction,
    WorkshopCatalogTableProps,
} from './types';
import WorkshopCatalogTable from './WorkshopCatalogTable';

const DELETION_WINDOW_DAYS = 7;
const columnDefinitions = [
    ColumnId.TITLE,
    ColumnId.STATE,
    ColumnId.TOPICS,
    ColumnId.LEVEL,
    ColumnId.DURATION,
    ColumnId.AWS_SERVICES,
    ColumnId.PRIMARY_OWNER,
    ColumnId.INDUSTRY_VERTICAL,
    ColumnId.ENGAGEMENT_MODEL,
    ColumnId.IS_ACCOUNT_SOURCE_DEFINED,
    ColumnId.PUBLISH_TYPE,
    ColumnId.CONTENT_ID,
    ColumnId.LAST_MODIFIED,
    ColumnId.LAST_PUBLISHED,
];
const visibleContent = [
    ColumnId.TITLE,
    ColumnId.STATE,
    ColumnId.TOPICS,
    ColumnId.LEVEL,
    ColumnId.DURATION,
    ColumnId.IS_ACCOUNT_SOURCE_DEFINED,
    ColumnId.LAST_MODIFIED,
    ColumnId.LAST_PUBLISHED,
];
const collectionSelectFilters: WorkshopCatalogTableProps['collectionSelectFilters'] = [
    {
        id: CollectionFilter.WORKSHOP_STATE,
        label:
            i18nStrings.workshops.workshopCatalog.table
                .workshopStateCollectionSelect.label,
        filter: ({ state }, value) => state === value,
        options: WORKSHOP_STATE_FILTER_OPTIONS,
    },
    {
        id: CollectionFilter.EVENT_READINESS,
        label:
            i18nStrings.workshops.workshopCatalog.table
                .accountSourceDefinedStateCollectionSelect.label,
        filter: ({ isAccountSourceDefined }, value) =>
            String(isAccountSourceDefined) === value,
        options: ACCOUNT_SOURCE_DEFINED_FILTER_OPTIONS,
    },
];

const WorkshopCatalogTableContainer = () => {
    const history = useHistory();
    const loadingData = useRef<boolean>();
    const [getContents, getContentsResponse] = useLazyRequest<
        typeof EEContentCatalog.getContents,
        AWSError
    >(EEContentCatalog.getContents, { context: EEContentCatalog });
    const [data, setData] = useState<ContentData[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AWSError>();
    const [
        upcomingDeleteAlertExpanded,
        setUpcomingDeleteAlertExpanded,
    ] = useState(false);
    const upcomingDelete = useMemo(() => {
        const start = new Date();
        const end = addDays(start, DELETION_WINDOW_DAYS);
        return (data || []).filter(
            (content) =>
                content.scheduledForDeletionAt &&
                isWithinInterval(content.scheduledForDeletionAt, {
                    start,
                    end,
                })
        );
    }, [data]);
    const paginator = usePaginator<ListContentsRequest, ListContentsResponse>(
        getContents,
        DEFAULT_PAGINATION_LIMIT,
        'contents'
    );
    const setLoadingState = (isLoading: boolean) => {
        setLoading(isLoading);
        loadingData.current = isLoading;
    };
    const getDaysUntilDeleteMessage = (item: ContentData) => {
        if (!item.scheduledForDeletionAt) {
            return null;
        }

        let interval = formatDistanceToNowStrict(item.scheduledForDeletionAt, {
            unit: 'day',
        });

        if (parseInt(interval) <= 0) {
            interval = i18nStrings.today;
        } else {
            interval = `in ${interval}`;
        }

        const href = `${routes.workshops}/${item.contentId}`;

        return (
            <>
                <Link
                    href={href}
                    onFollow={(e) => {
                        e.preventDefault();
                        history.push(href);
                    }}>
                    {item.title}
                </Link>{' '}
                {i18nStrings.workshops.upcomingScheduledForDeletionAlert.messagePostfix(
                    {
                        interval,
                    }
                )}
            </>
        );
    };
    const getData = useCallback(async () => {
        if (loadingData.current) {
            return;
        }

        setError(undefined);
        setData(undefined);
        setLoadingState(true);

        await paginator.getPaginatedData<ListContentsRequest>(0, '*', {
            fresh: true,
        });
    }, []);

    useEffect(() => {
        if (!getContentsResponse.data?.contents) {
            return;
        }

        setData([...(data || []), ...getContentsResponse.data.contents]);
        !getContentsResponse.data.nextToken && setLoadingState(false);
    }, [getContentsResponse.data]);

    useEffect(() => {
        if (!getContentsResponse.isError) {
            return;
        }

        setError(getContentsResponse.isError);
        setData([]);
        setLoadingState(false);
    }, [getContentsResponse.isError]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <WorkshopCatalogTableProvider>
            <SpaceBetween direction="vertical" size="m">
                {upcomingDelete.length ? (
                    <Alert
                        action={
                            upcomingDelete.length > 1 ? (
                                <Button
                                    onClick={() =>
                                        setUpcomingDeleteAlertExpanded(
                                            !upcomingDeleteAlertExpanded
                                        )
                                    }>
                                    {upcomingDeleteAlertExpanded
                                        ? i18nStrings.collapse
                                        : i18nStrings.expand}
                                </Button>
                            ) : null
                        }
                        type="warning">
                        {upcomingDelete.length === 1 &&
                            getDaysUntilDeleteMessage(upcomingDelete[0])}
                        {upcomingDelete.length > 1 &&
                            i18nStrings.workshops.upcomingScheduledForDeletionAlert.messageMultiple(
                                {
                                    count: upcomingDelete.length,
                                    interval: formatDuration({
                                        weeks: DELETION_WINDOW_DAYS / 7,
                                    }),
                                }
                            )}
                        {upcomingDelete.length > 1 &&
                            upcomingDeleteAlertExpanded && (
                                <Box margin={{ top: 'xs' }}>
                                    <ul className={styles.resetList}>
                                        {upcomingDelete.map((item) => (
                                            <li key={item.contentId}>
                                                {getDaysUntilDeleteMessage(
                                                    item
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                    </Alert>
                ) : null}
                <WorkshopCatalogTable
                    id="my_workshops_table"
                    title={i18nStrings.myWorkshops.title}
                    columnDefinitions={columnDefinitions}
                    visibleContent={visibleContent}
                    data={data}
                    actions={[
                        TableAction.EDIT_WORKSHOP,
                        TableAction.CREATE_WORKSHOP,
                    ]}
                    collectionSelectFilters={collectionSelectFilters}
                    toolsContent={<WorkshopCatalogTools />}
                    refetch={getData}
                    loading={loading}
                    error={error}
                />
            </SpaceBetween>
        </WorkshopCatalogTableProvider>
    );
};

export default WorkshopCatalogTableContainer;
