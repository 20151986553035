import { useLogger } from '@amzn/event-engine-js-utils';
import { isAuthenticatedState } from 'atoms';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { AuthContext } from 'contexts/AuthProvider';
import React, { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export interface AuthGuardProps {
    ignorePaths?: string[];
}

/**
 * Wrapper component that conditionally renders children
 * if a user is authenticated
 */
const AuthGuard: FC<AuthGuardProps> = ({
    ignorePaths = [
        // ignore login callback route to prevent login loops
        `${routes.callback}/${routeTokens.login}`,
    ],
    children,
}) => {
    const location = useLocation();
    const { signIn, getCurrentSession } = useContext(AuthContext);
    const [logger] = useLogger('AuthGuard');
    const [isAuth, setIsAuth] = useRecoilState(isAuthenticatedState);

    useEffect(() => {
        if (ignorePaths.includes(location.pathname)) {
            logger.info(`skipping auth guard for ${location.pathname}`);
            return;
        }

        getCurrentSession().then((currentSession) => {
            setIsAuth(!!currentSession);

            if (!currentSession) {
                logger.info(
                    'User is not authenticated, redirecting to login provider'
                );
                signIn();
            }
        });
    }, []);

    if (!isAuth) {
        return null;
    }

    return <>{children}</>;
};

export default AuthGuard;
