import { UPDATE_EVENT_QUOTA_RESERVATION_OPERATION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    formatString,
    FormikField,
    InputField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { AppTopNavContext } from 'components/AppTopNav/AppTopNav.provider';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import EEEvents from 'services/ee-events';

import { i18nStrings } from '../../../../../../../../../constants';
import Schema, { FieldName, SchemaType } from './schema';

export interface ReleaseReservationModalProps {
    email: string;
    event: EventEngineEventsService.EventData;
    max: number;
    onDismiss: () => void;
    isVisible?: boolean;
    onSuccess?: () => void;
    isSelf?: boolean;
}

const ReleaseReservationModal = ({
    email,
    event,
    max,
    onDismiss,
    isVisible = false,
    onSuccess,
    isSelf,
}: ReleaseReservationModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    const [
        updateQuotaReservation,
        { data, isLoading, isError },
    ] = useLazyRequest<typeof EEEvents.updateQuotaReservation, AWSError>(
        EEEvents.updateQuotaReservation,
        {}
    );
    const [validateOnChange, setValidateOnChange] = useState(false);
    const onSubmit = useCallback(
        (values: SchemaType) => {
            const request: EventEngineEventsService.UpdateEventQuotaReservationRequest = {
                eventId: event.eventId,
                numberOfAccounts: values[FieldName.AMOUNT],
                operation: UPDATE_EVENT_QUOTA_RESERVATION_OPERATION.SUBTRACT,
            };

            if (!isSelf) {
                request.principalId = email;
            }

            updateQuotaReservation(request);
        },
        [event, email, isSelf]
    );
    const formik = useFormik({
        validationSchema: Schema,
        validateOnBlur: false,
        validateOnChange,
        initialValues: {
            [FieldName.MAX]: max,
            [FieldName.AMOUNT]: max,
        },
        onSubmit,
    });
    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, [formik]);
    const { modalComponent } = useModal({
        isVisible,
        onDismiss,
        modalHeader: i18nStrings.events.quotas.releaseReservationModal.header,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                disabled: isLoading,
                onClick: onDismiss,
            },
            primary: {
                text:
                    i18nStrings.events.quotas.releaseReservationModal
                        .primaryAction,
                loading: isLoading,
                disabled: !formik.isValid,
                onClick: onPrimaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <SpaceBetween size="l">
                    <FormikField<SchemaType>
                        name={FieldName.AMOUNT}
                        dataTestId={FieldName.AMOUNT}
                        formFieldProps={{
                            label: formatString(
                                i18nStrings.events.quotas
                                    .releaseReservationModal.fields.amount
                                    .label,
                                isSelf ? '' : ` for ${email}`
                            ),
                            constraintText: formatString(
                                i18nStrings.events.quotas
                                    .releaseReservationModal.fields.amount
                                    .constraintMax,
                                max
                            ),
                            children: (
                                <InputField<SchemaType>
                                    name={FieldName.AMOUNT}
                                    placeholder={
                                        i18nStrings.events.quotas
                                            .releaseReservationModal.fields
                                            .amount.placeholder
                                    }
                                    inputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                    }}
                                />
                            ),
                        }}
                    />
                    <Alert type="info">
                        {
                            i18nStrings.events.quotas.releaseReservationModal
                                .alerts.releaseConditions
                        }
                    </Alert>
                </SpaceBetween>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        showNotification(
            i18nStrings.events.quotas.releaseReservationModal.notifications
                .success.content,
            { type: 'success', clearStack: false }
        );
        refreshQuotaInfo();
        onSuccess?.();
        onDismiss();
    }, [data]);

    useEffect(() => {
        if (!isError) {
            return;
        }

        isError &&
            showNotification(isError.message, {
                header:
                    i18nStrings.events.quotas.releaseReservationModal
                        .notifications.error.header,
                type: 'error',
                clearStack: false,
            });
        onDismiss();
    }, [isError]);

    return modalComponent;
};

export default ReleaseReservationModal;
