import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useState,
} from 'react';

interface AppTopNavProviderValues {
    refreshQuotaInfoHandle?: boolean;
    refreshQuotaInfo: () => void;
}

export const AppTopNavContext = createContext({} as AppTopNavProviderValues);

const AppTopNavProvider = ({ children }: PropsWithChildren<ReactNode>) => {
    const [refreshQuotaInfoHandle, setRefreshQuotaInfoHandle] = useState<
        boolean | undefined
    >();
    const refreshQuotaInfo = useCallback(() => {
        setRefreshQuotaInfoHandle(!refreshQuotaInfoHandle);
    }, [refreshQuotaInfoHandle]);

    return (
        <AppTopNavContext.Provider
            value={{
                refreshQuotaInfoHandle,
                refreshQuotaInfo,
            }}>
            {children}
        </AppTopNavContext.Provider>
    );
};

export default AppTopNavProvider;
