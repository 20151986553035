import Header from '@amzn/awsui-components-react/polaris/header';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';

import { columnDefinitions } from './config';
import styles from './styles.module.scss';
import { EventReadinessTableProps } from './types';

const EventReadinessTable = ({
    items,
    variant,
    visibleColumns,
}: EventReadinessTableProps) => {
    const tableOptions: Omit<TableProps, 'items' | 'columnDefinitions'> =
        variant === 'compact'
            ? {
                  wrapLines: true,
                  variant: 'borderless',
              }
            : {};
    const table = (
        <Table
            {...tableOptions}
            header={
                variant === 'compact' ? null : (
                    <Header
                        counter={`(${items.length})`}
                        description={
                            i18nStrings.eventReadinessTable.description
                        }>
                        {i18nStrings.eventReadinessTable.title}
                    </Header>
                )
            }
            items={items}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            contentDensity={variant === 'compact' ? 'compact' : undefined}
        />
    );

    return variant === 'compact' ? (
        <div className={styles.compactTable}>{table}</div>
    ) : (
        table
    );
};

export default EventReadinessTable;
