import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export const GET_EVENT_TEMPLATE_RESPONSE: EventEngineEventsService.GetEventTemplateResponse = {
    eventTemplate: {
        attributes: [
            {
                name: 'Salesforce Opportunity ID',
                type: 'string',
                required: false,
                description:
                    'Please include a 15 or 18 character Opportunity/Campaign ID from Salesforce. You can find this in the overview section in Salesforce, or from the opportunity/campaign URL.',
            },
            {
                name: 'Engagement Type',
                type: 'single_choice',
                choiceOptions: [
                    'Immersion Day',
                    'Multi-customer Immersion Day',
                    'Gameday',
                    'Hackathon',
                    'Loft',
                    'Dev Day',
                    'Engineering Learning Series',
                    'Training & Certification',
                    'Developer Relations',
                    'Professional Services',
                    'DeepRacer',
                    'Standalone Workshop',
                    'Other',
                ],
                required: true,
                description:
                    'An Engagement Type is an established customer engagement event designed for demand generation or opportunity acceleration (e.g., Immersion Day,  Hackathon, etc).',
            },
            {
                name: 'Geo',
                type: 'multi_choice',
                choiceOptions: [
                    'AMER',
                    'EMEA',
                    'APAC',
                    'JAPN',
                    'CHNA',
                    'GLBL',
                    'Unknown',
                ],
                required: true,
                description:
                    'Please include the Geographies in which the event will be run. This is for reporting purposes only, and does not restrict access to participants in certain Geographies.',
            },
            {
                name: 'Age',
                type: 'number',
                required: false,
                description: 'Please include your age.',
            },
        ],
        eventTemplateId: '605abe26-d014-c72e-3df9-deb267e73756',
        createdAt: new Date('2021-04-07T19:10:51.000Z'),
        description: 'A Workshop with embedded documentation',
        lastUpdatedAt: new Date('2021-04-07T19:10:51.000Z'),
        title: 'Workshop',
    },
};
