/**
 * Trims a specified character from string.
 * Surrounding whitespace is removed first.
 * @param {string} input
 * @param {string|undefined} character
 * @returns {string}
 */
const trimStr = (input: string, character?: string) => {
    if (!input) {
        return '';
    }

    return input
        .trim()
        .replace(new RegExp(`^\\${character}+|\\${character}+$`, 'g'), '');
};

export default trimStr;
