import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { sortTableItems } from 'utils';

interface PermissionTableItem {
    email: string;
    roles: string[];
    rolesDisplay: string;
}

type PermissionTableItems = PermissionTableItem[];

const sortColumns: Partial<Record<
    keyof PermissionTableItem,
    keyof PermissionTableItem
>> = {
    email: 'email',
    roles: 'roles',
};

export const facilitatorRolesMapping: {
    [key in string]: string;
} = {
    administrator: 'Administrator',
    support_staff: 'Support Staff',
};

export const columnDefinitions: TableProps.ColumnDefinition<
    PermissionTableItem
>[] = [
    {
        id: 'userEmail',
        header: 'Email',
        cell: (item: PermissionTableItem): string => item.email,
        minWidth: '180px',
        sortingField: sortColumns.email,
        sortingComparator: (a: PermissionTableItem, b: PermissionTableItem) =>
            a.email.localeCompare(b.email),
    },
    {
        id: 'userRole',
        header: 'Role',
        sortingField: sortColumns.roles,
        sortingComparator(a: PermissionTableItem, b: PermissionTableItem) {
            return a.rolesDisplay.localeCompare(b.rolesDisplay);
        },
        // replace '_' with '-' to support smithy constraint, particularly here with `co_owner`
        cell: (item: PermissionTableItem): string => item.rolesDisplay,
    },
];

export const onFilterEventPermissions = (
    filteringText: string,
    sortingState: TableProps.SortingState<PermissionTableItems>,
    permissions: PermissionTableItems
) => {
    const comparator =
        sortingState.sortingColumn.sortingComparator ||
        columnDefinitions.find(
            (column) =>
                column.sortingField === sortingState.sortingColumn.sortingField
        )?.sortingComparator;

    if (comparator) {
        sortTableItems(permissions!, comparator, sortingState.isDescending);
    }
    return permissions?.filter((permission) => {
        const query = (filteringText || '').trim().toLowerCase();
        if (!query) {
            return true;
        }
        return (
            permission.email.toLowerCase().indexOf(query) > -1 ||
            permission.rolesDisplay.toLowerCase().indexOf(query) > -1
        );
    });
};
