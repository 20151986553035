import { CONTENT_PUBLISH_STATE } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { isValid } from 'date-fns';
import React from 'react';
import getFormattedDate from 'utils/get-formatted-date';

import { i18nStrings } from '../../../constants';

/**
 * Depending on the build type (hugo vs embedded), route to the appropriate link
 */
export const getContentPreviewLink = (
    item?: {
        contentId: string;
        contentBuildId: string;
    },
    isEmbedded?: boolean
) => {
    if (isEmbedded) {
        return item
            ? `${routes.preview}/${item.contentId}/${routeTokens.builds}/${item.contentBuildId}`
            : '';
    } else {
        return item
            ? `${routes.workshops}/${item.contentId}/${routeTokens.builds}/${item.contentBuildId}`
            : '';
    }
};

export const getLastPublishedAt = (
    item: Pick<ContentData, 'lastPublishedAt' | 'publishState'>
) => {
    return item.lastPublishedAt &&
        item.publishState === CONTENT_PUBLISH_STATE.PUBLISHED
        ? getFormattedDate(item.lastPublishedAt, { useRelativeTime: true })
        : i18nStrings.empty;
};
