import EventEngineEventsService, {
    CentralAccountDeploymentList,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { useLazyRequest } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk';
import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { EEEvents } from 'services';

export interface CentralAccountProviderProps {
    event: EventEngineEventsService.EventData;
}

export interface CentralAccountProviderValue {
    isLoading: boolean;
    isError?: AWSError;
    getData: () => Promise<void>;
    centralAccounts?: CentralAccountDeploymentList;
    event: EventEngineEventsService.EventData;
}

export const CentralAccountContext = createContext<CentralAccountProviderValue>(
    {} as CentralAccountProviderValue
);

const CentralAccountProvider: FC<CentralAccountProviderProps> = ({
    event,
    children,
}) => {
    const [getCentralAccounts, { data, isLoading, isError }] = useLazyRequest<
        typeof EEEvents.getCentralAccounts,
        AWSError
    >(EEEvents.getCentralAccounts, {});
    const getData = useCallback(async () => {
        await getCentralAccounts({ eventId: event.eventId });
    }, [event]);
    const [centralAccounts, setCentralAccounts] = useState<
        EventEngineEventsService.CentralAccountDeploymentList
    >();

    useEffect(() => {
        data && setCentralAccounts(data.centralAccountDeployments);
    }, [data]);

    return (
        <CentralAccountContext.Provider
            value={{
                isLoading,
                isError,
                getData,
                centralAccounts,
                event,
            }}>
            {children}
        </CentralAccountContext.Provider>
    );
};

export default CentralAccountProvider;
