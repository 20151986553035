import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { EventAttributes } from 'utils/content-attributes';

import { EnhancedContentTemplateAttribute } from './../CreateEvent/utils/create-event-attributes';

export const expandInitialAttributes = (
    eventAttributes: EventEngineEventsService.EventAttributes,
    attributes: readonly EnhancedContentTemplateAttribute[]
): EnhancedContentTemplateAttribute => {
    return attributes.reduce((acc, attribute) => {
        const attributeName = attribute.name as string;

        if (attributeName === EventAttributes['Engagement Type']) {
            const engagementType = eventAttributes.find((attribute) => {
                return attribute.name === EventAttributes['Engagement Type'];
            })?.value?.stringValue;

            if (!engagementType) {
                return acc;
            }

            const engagementTypeOptions =
                attributes.find((attr) => {
                    return attr.name === EventAttributes['Engagement Type'];
                }) || {};

            // if the user selected 'Other', use the same schema as described in
            // src/pages/Events/CreateEvent/utils/create-event-attributes.tsx
            if (engagementType) {
                if (
                    !engagementTypeOptions?.choiceOptions?.some(
                        (engagement) => engagement === engagementType
                    )
                ) {
                    return {
                        ...acc,
                        ...{
                            [attributeName]: {
                                engagementType: 'Other',
                                engagementTypeOther: engagementType,
                            },
                        },
                    };
                }
            }

            return {
                ...acc,
                ...{
                    [attributeName]: {
                        engagementType,
                    },
                },
            };
        }

        if (attributeName === EventAttributes.Geo) {
            const geoValues = eventAttributes.find((attribute) => {
                return attribute.name === EventAttributes.Geo;
            })?.value?.stringListValue;

            if (!Array.isArray(geoValues) || geoValues.length === 0) {
                return acc;
            }

            return {
                ...acc,
                [attributeName]: geoValues?.map((val) => {
                    return {
                        value: val,
                        label: val,
                    };
                }),
            };
        }

        if (attributeName === EventAttributes['Salesforce Opportunity ID']) {
            const salesforceOpportunityId = eventAttributes.find(
                (attribute) => {
                    return (
                        attribute.name ===
                        EventAttributes['Salesforce Opportunity ID']
                    );
                }
            )?.value?.stringValue;

            if (!salesforceOpportunityId) {
                return acc;
            }

            return {
                ...acc,
                [attributeName]: salesforceOpportunityId,
            };
        }

        return acc;
    }, {});
};
