import { MAX_TEAM_SIZE } from 'utils/config';

/**
 * Calculates maximum participant forecast based on team size
 *
 * @param {number} maxEventSize
 * @param {number|string} teamSize
 * @returns
 */
const getMaxParticipantForecast = (
    maxEventSize: number,
    teamSize: number | string
) => {
    teamSize = typeof teamSize === 'string' ? parseInt(teamSize, 10) : teamSize;

    return maxEventSize * Math.min(teamSize > 0 ? teamSize : 1, MAX_TEAM_SIZE);
};

export default getMaxParticipantForecast;
