import Container from '@amzn/awsui-components-react/polaris/container';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { BaseMarkdown, formatString } from '@amzn/event-engine-js-utils';
import { breadcrumbsState, contentTypeState } from 'atoms';
import { routes, SearchParamKey } from 'components/AppRoutes/routes.constants';
import { GuardrailsContext } from 'contexts/GuardrailsProvider/GuardrailsProvider';
import NotificationsProvider from 'contexts/NotificationsProvider';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { i18nStrings } from '../../constants';
import GuardrailsForm from './GuardrailsForm';

const LOCALE_DATE_FORMAT: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

const GuardrailsContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const {
        version,
        body,
        policyCreatedAt,
        acknowledgedAt,
        acknowledgementRequired,
        // setAcknowledgedAt,
        refetch,
    } = useContext(GuardrailsContext);
    const lastAcknowledgedRequired = useRef(acknowledgementRequired);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    const setContentType = useSetRecoilState(contentTypeState);
    const onSuccess = useCallback(() => {
        refetch();
    }, []);

    // Perform redirect only if the acknowledgment status changes
    useEffect(() => {
        if (
            acknowledgementRequired ||
            lastAcknowledgedRequired.current === acknowledgementRequired
        ) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        const target = searchParams.get(SearchParamKey.TARGET);

        history.replace(target || routes.landingPage);
    }, [acknowledgementRequired]);

    useEffect(() => {
        setContentType('form');
        setBreadcrumbs([
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: i18nStrings.guardrails.title,
                href: routes.guardrails,
            },
        ]);

        return () => {
            setContentType('default');
        };
    }, []);

    const content = (
        <TextContent>
            <BaseMarkdown linkTarget="_blank">{body}</BaseMarkdown>
        </TextContent>
    );

    return (
        <NotificationsProvider>
            <ContentLayout
                header={
                    <Header
                        variant="h1"
                        description={formatString(
                            i18nStrings.guardrails.lastUpdated,
                            policyCreatedAt.toLocaleDateString(
                                undefined,
                                LOCALE_DATE_FORMAT
                            )
                        )}>
                        {i18nStrings.guardrails.title}
                    </Header>
                }>
                {acknowledgementRequired ? (
                    <GuardrailsForm version={version} onSuccess={onSuccess}>
                        {content}
                    </GuardrailsForm>
                ) : (
                    <Container
                        header={
                            <Header
                                description={
                                    acknowledgedAt &&
                                    formatString(
                                        i18nStrings.guardrails.acknowledgedAt,
                                        acknowledgedAt.toLocaleDateString(
                                            undefined,
                                            LOCALE_DATE_FORMAT
                                        )
                                    )
                                }>
                                {i18nStrings.guardrails.title}
                            </Header>
                        }>
                        {content}
                    </Container>
                )}
            </ContentLayout>
        </NotificationsProvider>
    );
};

export default GuardrailsContainer;
