/**
 * Converts the specified string into a simple slug
 * containing only alpha-numeric characters separated by dashes
 * @param {string} str
 * @param {string='-'} delimiter
 * @returns {string} Slugified string
 */
const slugify = (str: string, delimiter = '-') =>
    (str || '')
        .trim()
        // Remove all non alphanumeric characters
        .replace(/[^a-zA-Z0-9\-\s]/g, '')
        // Replace one or more spaces with specified delimiter
        .replace(/\s+/g, delimiter)
        // Collapse repeated instances of delimiter
        .replace(new RegExp(`${delimiter}+`, 'g'), delimiter)
        // convert to lower case
        .toLowerCase();

export default slugify;
