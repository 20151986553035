import i18nStrings from 'constants/i18n-strings';
import {
    differenceInMilliseconds,
    format,
    formatDistance,
    isValid,
} from 'date-fns';
import {
    DATE_FORMAT,
    JUST_NOW_TIME_WINDOW_MS,
    RELATIVE_TIME_MAX_MS,
} from 'utils/config';

interface FormattedDateOptions {
    /**
     * Enables relative time formatting i.e. "about 1 minute ago".
     */
    useRelativeTime?: boolean;
    /**
     * Max window for relative time formatting. default is RELATIVE_TIME_MAX_MS (30 days)
     */
    maxRelativeTimeWindowMS?: number;
    /**
     *  Just now time window to display "Just now". default is JUST_NOW_TIME_WINDOW_MS (60 seconds)
     */
    justNowTimeWindowMS?: number;
}

const getFormattedDate = (
    date: Date,
    {
        useRelativeTime,
        maxRelativeTimeWindowMS = RELATIVE_TIME_MAX_MS,
        justNowTimeWindowMS = JUST_NOW_TIME_WINDOW_MS,
    }: FormattedDateOptions = {}
) => {
    if (!isValid(date)) {
        return null;
    }

    if (
        useRelativeTime &&
        Date.now() - date.getTime() < maxRelativeTimeWindowMS
    ) {
        if (differenceInMilliseconds(new Date(), date) < justNowTimeWindowMS) {
            // if the field was edited within justNowTimeWindowMS seconds,
            // show Just now instead of "0 seconds ago"
            return i18nStrings.justNow;
        }

        return formatDistance(date, new Date(), {
            addSuffix: true,
        });
    }

    return format(date, DATE_FORMAT);
};

export default getFormattedDate;
