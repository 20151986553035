import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';

import { RegionCategories } from './constants';

/**
 * Filter list of region options by categories
 *
 * @param {MultiselectProps.Option} options
 * @param {RegionCategories} categories
 * @returns
 */
const filterRegionOptions = (
    options: MultiselectProps.Option[],
    categories: RegionCategories
) =>
    options.filter(
        ({ description }) => description && categories.includes(description)
    );

export default filterRegionOptions;
