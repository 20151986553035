import { AccountType } from 'components/AppRoutes/routes.constants';

export interface ViewInCloudFormationProps {
    stackArn?: string;
    eventId: string;
    accountType: AccountType;
    resourceId: string;
}

export const VIEW_IN_CLOUDFORMATION_KEY = 'viewInCloudFormationKey';
