import addMilliseconds from 'date-fns/addMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';
import leftPad from 'utils/left-pad';

const getFormattedDuration = (durationMS = 0) => {
    const start = new Date();
    const {
        days = 0,
        hours = 0,
        minutes = 0,
        seconds = 0,
    } = intervalToDuration({
        start,
        end: addMilliseconds(start, durationMS),
    });
    const totalHours = days * 24 + hours;
    return `${leftPad(totalHours, 2, '0')}:${leftPad(
        minutes!,
        2,
        '0'
    )}:${leftPad(seconds!, 2, '0')}`;
};

export default getFormattedDuration;
