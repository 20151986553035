import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { EventMatrix } from '@amzn/event-engine-js-utils';
import useDismissibleAlert from 'hooks/use-dismissible-alert';
import useFeatureFlag from 'hooks/use-feature-flag';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { i18nStrings } from '../../../../../../../constants';
import {
    ObserverHandler,
    TeamsPollerContext,
} from '../../../../contexts/TeamsPollerProvider';
import EventTeamsOverviewContainer from './components/EventTeamsOverviewContainer';
import EventTeamsTable from './components/EventTeamsTable';

export interface TeamsContainerProps {
    event: EventEngineEventsService.EventData;
}

const DATA_POLLER_ID = 'teamsContainer';

const TeamsContainer = ({ event }: TeamsContainerProps) => {
    //#region TODO: Remove once multiParticipants is generally available
    const multiParticipantsFeatureEnabled = useFeatureFlag('multiParticipants');
    const { alertComponent } = useDismissibleAlert({
        id: 'comingSoonMultiParticipantTeamsAlert',
        children:
            i18nStrings.events.teams.alerts.comingSoonMultiParticipantTeams,
    });
    //#endregion
    const { refresh, subscribe, unsubscribe, isLoading, error } = useContext(
        TeamsPollerContext
    );
    const [data, setData] = useState<EventEngineEventsService.TeamList>();
    const refetch = useCallback(async (preserveCache?: boolean) => {
        await refresh(DATA_POLLER_ID, preserveCache);
    }, []);
    const isProvisioning = useMemo(
        () =>
            EventMatrix.isDeploymentInProgress({
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            }),
        []
    );
    const teamsPollHandler: ObserverHandler = (data) => {
        setData(data);
    };

    useEffect(() => {
        subscribe(DATA_POLLER_ID, teamsPollHandler);
        refetch(true);

        return () => {
            unsubscribe(DATA_POLLER_ID);
        };
    }, []);

    return (
        <SpaceBetween direction="vertical" size="l">
            {!multiParticipantsFeatureEnabled && alertComponent}
            {event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO && (
                <EventTeamsOverviewContainer
                    teams={data}
                    defaultExpanded={isProvisioning}
                />
            )}
            <EventTeamsTable
                event={event}
                teams={data}
                loading={isLoading && !data}
                error={error}
                refetch={refetch}
            />
        </SpaceBetween>
    );
};

export default TeamsContainer;
