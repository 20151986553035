import * as Yup from 'yup';

import { Step } from '../../components/CreateEventForm.validation';
import Schema from './schema';

export enum FieldName {
    ALLOW_ALL = 'allow_all',
    EMAIL_PATTERNS = 'email_patterns',
}

export interface ParticipantAllowlistContainerProps {
    requireEmailPatterns?: boolean;
    stepId?: Step;
}

export interface ParticipantAllowlistFieldsProps {
    requireEmailPatterns?: boolean;
    stepId?: Step;
}

export type SchemaType = Yup.InferType<typeof Schema>;
