import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Wizard, {
    WizardProps,
} from '@amzn/awsui-components-react/polaris/wizard';
import {
    createYupSchema,
    EventMatrix,
    formatString,
    mergeSchemas,
    useLazyRequest,
    useLogger,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { AppTopNavContext } from 'components/AppTopNav';
import HelpPanel from 'components/HelpPanel';
import InfoLink from 'components/InfoLink';
import { useUserContext } from 'contexts';
import { addHours } from 'date-fns';
import {
    FormikProvider,
    setNestedObjectValues,
    useFormik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import { useUnsavedChangesModal } from 'hooks';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { EEEvents, ErrorMessage } from 'services';
import { CreateEventErrorCode, MessageTypes } from 'services/error-message';
import { EVENT_DURATION_ACKNOWLEDGEMENT_THRESHOLD } from 'utils/config';
import { utcToZonedTime } from 'utils/date-fns';
import * as Yup from 'yup';

import { i18nStrings } from '../../../constants';
import { getCreateEventFormFieldName } from './components/CreateEventForm.utils';
import {
    FormSchema,
    MergedFormSchema,
    Step,
} from './components/CreateEventForm.validation';
import styles from './CreateEventWizard.module.scss';
import useDurationWarningModal from './hooks/use-duration-warning-modal';
import EventDetails from './steps/EventDetails';
import getEventDetailsFormFieldName from './steps/EventDetails/utils/get-form-field-name';
import EventType from './steps/EventType';
import ParticipantAllowlist, {
    ParticipantAllowlistFieldName,
} from './steps/ParticipantAllowlist';
import { getSchema as getParticipantAllowlistSchema } from './steps/ParticipantAllowlist/schema';
import ReviewAndCreate from './steps/ReviewAndCreate';
import SelectWorkshop from './steps/SelectWorkshop';
import { CreateEventWizardProps, StepConfig } from './types';
import {
    filterRegionOptions,
    getAccessibleRegionsConstraints,
    getMinAccountRequirement,
    getRegionSelectOptions,
} from './utils';
import { PERIOD_SELECT_OPTIONS, TIMEZONES } from './utils/constants';
import {
    DEFAULT_ACCESSIBLE_REGIONS,
    DEFAULT_REGION_CATEGORIES,
    REQUIRED_REGION_CATEGORIES,
} from './utils/constants';
import serializeFormData, {
    SerializeFormDataValues,
} from './utils/serialize-form-data';

const publicWorkshopsHome = `${routes.workshops}/${routeTokens.publicWorkshops}`;

const CreateEventWizard = ({
    attributes = [],
    eventTemplateId,
    maxEventSize,
    initialValues,
    stepOptions,
    hiddenSteps,
}: CreateEventWizardProps) => {
    const [eventMatrixLogger] = useLogger('CreateEventMatrixLogger');
    const { data: userData } = useUserContext();
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    // Most modern browsers support this, IE11 has partial support:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
    const { current: userDefaultTimezone } = useRef(
        TIMEZONES.find(
            (timezone) =>
                timezone.value ===
                Intl.DateTimeFormat().resolvedOptions().timeZone
        )?.value || ''
    );
    const previousConfirmedDuration = useRef<number | null>(null);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const confirmDurationStepIndex = useRef(activeStepIndex);
    const [userNavigateAway, setUserNavigateAway] = useState<
        number | undefined
    >();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const onConfirm = useCallback(() => {
        // if Number.MIN_VALUE is used, they want to exit the entire workflow
        if (userNavigateAway === Number.MIN_VALUE) {
            history.push(publicWorkshopsHome);
            return;
        }

        setActiveStepIndex(userNavigateAway as number);
        setUserNavigateAway(undefined);
        setValidateOnChange(false);
    }, [userNavigateAway]);
    const onDecline = useCallback(() => {
        setUserNavigateAway(undefined);
    }, []);
    const onDismiss = useCallback(() => {
        setUserNavigateAway(undefined);
    }, []);
    const {
        modalComponent: unsavedChangesModal,
        showModal,
        hideModal,
    } = useUnsavedChangesModal({
        onConfirm,
        onDecline,
        onDismiss,
    });
    const history = useHistory();
    const [
        createEvent,
        {
            isLoading: createEventLoading,
            isError: createEventError,
            data: createEventData,
        },
    ] = useLazyRequest<typeof EEEvents.createEvent, AWSError>(
        EEEvents.createEvent,
        {}
    );
    const isTestEventMode = useMemo(
        () => initialValues?.[Step.EVENT_TYPE]?.eventType === EVENT_TYPE.TEST,
        [initialValues]
    );
    const getValidationSchema = useCallback(
        (values?: MergedFormSchema) => {
            if (!attributes) {
                return FormSchema;
            }

            const schemas: Yup.AnyObjectSchema[] = [
                FormSchema,
                Yup.object({
                    [Step.EVENT_DETAILS]: attributes.reduce(
                        createYupSchema,
                        Yup.object({})
                    ),
                }),
            ];

            (isTestEventMode ||
                values?.[Step.EVENT_TYPE].eventType === EVENT_TYPE.TEST) &&
                schemas.push(
                    Yup.object({
                        [Step.PARTICIPANT_ALLOWLIST]: getParticipantAllowlistSchema(
                            { emailPatterns: { isRequired: true } }
                        ),
                    })
                );

            return mergeSchemas(...schemas);
        },
        [attributes, isTestEventMode]
    );
    const onSubmit = async (values: MergedFormSchema) => {
        const { contentId, contentBuildId } = values[Step.SELECT_WORKSHOP]!;
        const serializeFormDataValues: SerializeFormDataValues = {
            attributes,
            values,
            contentId,
            eventTemplateId,
        };

        // only provide the content build ID if creating an event from a
        // specific content build (e.g. create test event from content build)
        if (
            initialValues?.[Step.SELECT_WORKSHOP] &&
            hiddenSteps?.includes(Step.SELECT_WORKSHOP)
        ) {
            serializeFormDataValues.contentBuildId = contentBuildId;
        }

        await createEvent(serializeFormData(serializeFormDataValues));
    };
    const validate = useCallback(
        async (values: MergedFormSchema) => {
            try {
                await validateYupSchema<MergedFormSchema>(
                    values,
                    getValidationSchema(values),
                    true,
                    {
                        maxEventSize,
                        editMatrix: eventMatrix.current,
                        contentSpecSummary:
                            values[Step.SELECT_WORKSHOP]?.contentSpecSummary,
                    }
                );
            } catch (err) {
                return yupToFormErrors<MergedFormSchema>(err);
            }

            return {};
        },
        [maxEventSize, getValidationSchema]
    );
    const testEventDetailsValue = useMemo<
        Partial<MergedFormSchema[Step.EVENT_DETAILS]>
    >(() => {
        const selectedWorkshop = initialValues?.[Step.SELECT_WORKSHOP];

        if (!(isTestEventMode && selectedWorkshop)) {
            return {};
        }

        const { date: eventDate, time: eventTime, period } = utcToZonedTime(
            addHours(new Date(), 2),
            {
                timezone: userDefaultTimezone,
            }
        );

        return {
            title: formatString(
                i18nStrings.events.create.test.defaultValues.title,
                selectedWorkshop.title
            ),
            description: formatString(
                i18nStrings.events.create.test.defaultValues.description,
                selectedWorkshop.title
            ),
            duration: 12,
            forecastedAttendees: 1,
            eventDate,
            eventTime,
            period:
                PERIOD_SELECT_OPTIONS.find(({ label }) => {
                    return label === period;
                })?.value || '',
        };
    }, [isTestEventMode]);
    const testParticipantAllowlistValue = useMemo<
        Partial<MergedFormSchema[Step.PARTICIPANT_ALLOWLIST]>
    >(() => {
        if (!isTestEventMode) {
            return {};
        }

        return {
            [ParticipantAllowlistFieldName.EMAIL_PATTERNS]: userData?.userId
                ? `${userData.userId}\n`
                : '',
        };
    }, [userData, isTestEventMode]);
    const formInitialValues = useMemo<MergedFormSchema>(
        () => ({
            [Step.SELECT_WORKSHOP]: undefined,
            [Step.EVENT_TYPE]: {
                accountSource: undefined!,
                eventType: EVENT_TYPE.PRODUCTION,
            },
            [Step.EVENT_DETAILS]: {
                title: '',
                description: '',
                eventDate: '',
                eventTime: '',
                period: '',
                timezone: userDefaultTimezone,
                duration: undefined!,
                forecastedAttendees: undefined!,
                teamSize: 1,
                initialQuotaContribution: undefined,
                availableQuota: undefined,
                facilitators: [],
                modality: '',
                deploymentRegions: '',
                accessibleRegions: [],
                ...testEventDetailsValue,
            },
            [Step.PARTICIPANT_ALLOWLIST]: {
                [ParticipantAllowlistFieldName.ALLOW_ALL]: false,
                [ParticipantAllowlistFieldName.EMAIL_PATTERNS]: '',
                ...testParticipantAllowlistValue,
            },
            ...initialValues,
        }),
        [initialValues]
    );
    const formik = useFormik({
        validate,
        validateOnBlur: false,
        validateOnChange: validateOnChange,
        initialValues: formInitialValues,
        onSubmit,
    });
    const eventType = formik.values[Step.EVENT_TYPE].eventType;
    const accountSource = formik.values[Step.EVENT_TYPE].accountSource;
    const eventMatrix = useRef(
        new EventMatrix(
            eventType,
            accountSource,
            // at this point, there is no eventState since the event has not yet been created
            undefined,
            {
                logger: eventMatrixLogger,
            }
        )
    );
    const onCancel = useCallback(() => {
        if (formik.dirty) {
            // if the form is dirty, ask if they want to leave
            // and use Number.MIN_VALUE as a special identifier
            setUserNavigateAway(Number.MIN_VALUE);
            return;
        }
        history.push(publicWorkshopsHome);
    }, [formik.dirty, history]);
    const steps = useMemo(() => {
        const stepConfigs: StepConfig[] = [];
        const editableSteps: Step[] = [
            Step.EVENT_DETAILS,
            Step.PARTICIPANT_ALLOWLIST,
        ];

        if (!hiddenSteps?.includes(Step.SELECT_WORKSHOP)) {
            stepConfigs.push({
                id: Step.SELECT_WORKSHOP,
                wizardStep: {
                    title:
                        i18nStrings.events.create.wizard.steps.selectWorkshop
                            .title,
                    content: <SelectWorkshop />,
                    errorText:
                        typeof formik.errors[Step.SELECT_WORKSHOP] ===
                            'string' && formik.errors[Step.SELECT_WORKSHOP],
                },
            });
            editableSteps.push(Step.SELECT_WORKSHOP);
        }

        if (!hiddenSteps?.includes(Step.EVENT_TYPE)) {
            stepConfigs.push({
                id: Step.EVENT_TYPE,
                wizardStep: {
                    title:
                        i18nStrings.events.create.wizard.steps.eventType.title,
                    content: <EventType />,
                    errorText: formik.errors[Step.EVENT_TYPE]
                        ? 'Select valid account type and account source'
                        : '',
                },
            });
            editableSteps.push(Step.EVENT_TYPE);
        }

        stepConfigs.push(
            ...[
                {
                    id: Step.EVENT_DETAILS,
                    wizardStep: {
                        title:
                            i18nStrings.events.create.wizard.steps.eventDetails
                                .title,
                        info: (
                            <InfoLink
                                toolsContent={
                                    <HelpPanel
                                        header={
                                            i18nStrings.events.create.helpText
                                                .eventDetails.header
                                        }>
                                        {
                                            i18nStrings.events.create.helpText
                                                .eventDetails.content
                                        }
                                    </HelpPanel>
                                }
                            />
                        ),
                        content: (
                            <EventDetails
                                attributes={attributes}
                                editMatrix={eventMatrix.current}
                                maxEventSize={
                                    eventType === EVENT_TYPE.PRODUCTION
                                        ? maxEventSize.production
                                        : maxEventSize.test
                                }
                            />
                        ),
                        errorText: formik.errors[Step.EVENT_DETAILS]
                            ? i18nStrings.events.create.errors.step
                            : null,
                    },
                },
                {
                    id: Step.PARTICIPANT_ALLOWLIST,
                    wizardStep: {
                        title:
                            i18nStrings.events.create.wizard.steps
                                .participantAllowlist.title,
                        isOptional:
                            !stepOptions?.[Step.PARTICIPANT_ALLOWLIST]
                                ?.required && eventType !== EVENT_TYPE.TEST,
                        content: (
                            <ParticipantAllowlist
                                requireEmailPatterns={
                                    isTestEventMode ||
                                    eventType === EVENT_TYPE.TEST
                                }
                                stepId={Step.PARTICIPANT_ALLOWLIST}
                            />
                        ),
                        errorText: formik.errors[Step.PARTICIPANT_ALLOWLIST]
                            ? i18nStrings.events.create.errors.step
                            : null,
                    },
                },
                {
                    wizardStep: {
                        title: 'Review and create',
                        content: (
                            <ReviewAndCreate
                                editableSteps={editableSteps}
                                onEdit={(id) => {
                                    const stepIndex = steps.findIndex(
                                        (step) => step.id === id
                                    );
                                    stepIndex > -1 &&
                                        setActiveStepIndex(stepIndex);
                                }}
                            />
                        ),
                        errorText: createEventError
                            ? ErrorMessage.getMessage(
                                  createEventError,
                                  MessageTypes.createEvent
                              )
                            : null,
                    },
                },
            ]
        );

        return stepConfigs;
    }, [
        initialValues,
        isTestEventMode,
        attributes,
        formik.errors,
        eventType,
        createEventError,
        hiddenSteps,
        stepOptions,
    ]);

    const {
        modalComponent: durationWarningModal,
        showModal: showDurationWarningModal,
    } = useDurationWarningModal({
        duration: formik.values[Step.EVENT_DETAILS].duration,
        onModalClosed: (confirmDuration) => {
            if (confirmDuration) {
                setActiveStepIndex(confirmDurationStepIndex.current);
                previousConfirmedDuration.current =
                    formik.values[Step.EVENT_DETAILS].duration;
            }
        },
    });

    const onNavigate = useCallback(
        async ({
            detail: { requestedStepIndex },
        }: {
            detail: WizardProps.NavigateDetail;
        }) => {
            if (activeStepIndex >= requestedStepIndex) {
                setActiveStepIndex(requestedStepIndex);
                return;
            }

            let isValid = true;
            let resolvedStepIndex = 0;
            const errors = await formik.validateForm();

            for (
                let i = activeStepIndex, len = requestedStepIndex;
                i < len;
                i++
            ) {
                const stepId = steps[i].id;
                resolvedStepIndex = i;

                if (stepId && errors[stepId]) {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                const duration = formik.values[Step.EVENT_DETAILS].duration;
                const accountSource =
                    formik.values[Step.EVENT_TYPE].accountSource;
                const eventDetailsIndex = steps.findIndex(
                    (step) => step.id === Step.EVENT_DETAILS
                );

                // Disable validation on change if the user navigates forward successfully
                formik.setErrors({});
                setValidateOnChange(false);

                if (
                    duration > EVENT_DURATION_ACKNOWLEDGEMENT_THRESHOLD &&
                    duration !== previousConfirmedDuration.current &&
                    requestedStepIndex > eventDetailsIndex &&
                    accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
                ) {
                    confirmDurationStepIndex.current = requestedStepIndex;
                    showDurationWarningModal();
                    return;
                }

                setActiveStepIndex(requestedStepIndex);
            } else {
                // Enable validation on change if validation fails during a navigation attempt.
                // We need to manually "touch" all the fields in order for error UI to show
                // as expected since formik sadly does not expose a utility for this yet.
                // Submitting the form does this automatically, but we need to show field
                // errors when the user attempts to navigate to the next page, and not just
                // final submission.
                setValidateOnChange(true);
                setActiveStepIndex(resolvedStepIndex);
                formik.setTouched(
                    setNestedObjectValues(await formik.validateForm(), true)
                );
            }
        },
        [
            activeStepIndex,
            steps,
            formik.values[Step.EVENT_DETAILS].duration,
            formik.values[Step.EVENT_TYPE].accountSource,
        ]
    );

    useEffect(() => {
        if (!(initialValues && Object.keys(initialValues).length)) {
            return;
        }

        const requestedStepIndex =
            Math.max(
                ...steps.map(({ id }, index) =>
                    id && stepOptions?.[id]?.skipIfValid && initialValues[id]
                        ? index
                        : -2
                )
            ) + 1;

        requestedStepIndex >= 0 &&
            onNavigate({
                detail: {
                    requestedStepIndex,
                    reason: 'step',
                },
            });
    }, []);

    useEffect(() => {
        eventMatrix.current.accountSource = accountSource;
        eventMatrix.current.type = eventType;
    }, [accountSource, eventType]);

    useEffect(() => {
        if (eventType !== EVENT_TYPE.TEST) {
            return;
        }

        formik.setFieldValue(
            `${Step.PARTICIPANT_ALLOWLIST}.${ParticipantAllowlistFieldName.ALLOW_ALL}`,
            undefined
        );
    }, [eventType]);

    useEffect(() => {
        if (
            formik.values[Step.EVENT_TYPE].accountSource ===
            EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED
        ) {
            return;
        }

        const selectedWorkshop = formik.values[Step.SELECT_WORKSHOP];
        const deploymentRegionOptions = getRegionSelectOptions(
            selectedWorkshop?.contentSpecSummary?.deployableRegions
        );
        let selectedDeploymentRegion: string | undefined;

        if (deploymentRegionOptions.length) {
            if (deploymentRegionOptions.length === 1) {
                selectedDeploymentRegion = deploymentRegionOptions[0].value;
            } else if (
                selectedWorkshop?.contentSpecSummary.deployableRegions?.required
                    ?.length
            ) {
                selectedDeploymentRegion =
                    selectedWorkshop.contentSpecSummary.deployableRegions
                        .required[0];
            } else if (
                selectedWorkshop?.contentSpecSummary.deployableRegions
                    ?.recommended?.length
            ) {
                selectedDeploymentRegion =
                    selectedWorkshop.contentSpecSummary.deployableRegions
                        .recommended[0];
            }

            selectedDeploymentRegion &&
                formik.setFieldValue(
                    getEventDetailsFormFieldName('deploymentRegions'),
                    selectedDeploymentRegion
                );
        } else {
            formik.setFieldValue(
                getEventDetailsFormFieldName('deploymentRegions'),
                undefined
            );
        }
    }, [formik.values[Step.SELECT_WORKSHOP]]);

    useEffect(() => {
        const selectedWorkshop = formik.values[Step.SELECT_WORKSHOP];
        const selectedDeploymentRegion =
            formik.values[Step.EVENT_DETAILS].deploymentRegions;

        if (!selectedDeploymentRegion) {
            return;
        }

        const { max } = getAccessibleRegionsConstraints(
            selectedWorkshop?.contentSpecSummary
        );
        const defaultAccessibleRegions = [...DEFAULT_ACCESSIBLE_REGIONS];

        // Add selected deployment region as accessible region if
        // it is not one of the system default accessible regions.
        if (
            selectedDeploymentRegion &&
            !DEFAULT_ACCESSIBLE_REGIONS.some(
                ({ value }) => value === selectedDeploymentRegion
            )
        ) {
            defaultAccessibleRegions.push({
                value: selectedDeploymentRegion,
                description:
                    i18nStrings.events.regionCategories.deploymentRegion,
            });
        }

        const accessibleRegionOptions = getRegionSelectOptions(
            selectedWorkshop?.contentSpecSummary?.accessibleRegions,
            defaultAccessibleRegions
        );

        formik.setFieldValue(
            getEventDetailsFormFieldName('accessibleRegions'),
            filterRegionOptions(accessibleRegionOptions, [
                ...REQUIRED_REGION_CATEGORIES,
                ...DEFAULT_REGION_CATEGORIES,
            ]).slice(0, max)
        );
    }, [
        formik.values[Step.EVENT_DETAILS].deploymentRegions,
        formik.values[Step.SELECT_WORKSHOP],
    ]);

    useEffect(() => {
        if (!createEventError) {
            return;
        }

        switch (createEventError.code) {
            case CreateEventErrorCode.QuotaExceededException: {
                const formFieldName = getCreateEventFormFieldName(
                    'forecastedAttendees'
                );
                formik.setFieldError(
                    formFieldName,
                    i18nStrings.events.create.errors.insufficientAWSAccountQuota
                );
                formik.setFieldTouched(formFieldName, true);
                setValidateOnChange(true);
                break;
            }
        }
    }, [createEventError]);

    useEffect(() => {
        // after the Create Event API has completed,
        // navigate to the new route
        if (createEventData) {
            history.push(createEventData?.eventId as string);
            refreshQuotaInfo();
        }
    }, [createEventData]);

    useEffect(() => {
        if (userNavigateAway !== undefined) {
            showModal();
        }

        return () => {
            hideModal();
        };
    }, [userNavigateAway, showModal, hideModal]);

    useEffect(() => {
        const { forecastedAttendees, availableQuota, teamSize } = formik.values[
            Step.EVENT_DETAILS
        ];
        const quotaFieldName = getCreateEventFormFieldName(
            'initialQuotaContribution'
        );
        let quotaValue: number | undefined;

        if (
            forecastedAttendees > 0 &&
            availableQuota &&
            availableQuota > 0 &&
            teamSize > 0
        ) {
            quotaValue = Math.min(
                getMinAccountRequirement(
                    forecastedAttendees,
                    teamSize,
                    formik.values[Step.SELECT_WORKSHOP]?.contentSpecSummary
                        .centralAccountRequired
                ),
                availableQuota
            );
        }

        // forecast field only updates quota field when availableQuota is available
        // availableQuota depends all date and time fields to be set
        formik.setFieldValue(quotaFieldName, quotaValue, false);
    }, [
        formik.values[Step.SELECT_WORKSHOP]?.contentSpecSummary,
        formik.values[Step.EVENT_DETAILS].forecastedAttendees,
        formik.values[Step.EVENT_DETAILS].availableQuota,
        formik.values[Step.EVENT_DETAILS].teamSize,
    ]);

    return (
        <FormikProvider value={formik}>
            {unsavedChangesModal}
            {durationWarningModal}
            <Wizard
                className={styles.wizard}
                i18nStrings={{
                    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    cancelButton: 'Cancel',
                    previousButton: 'Previous',
                    nextButton: 'Next',
                    submitButton: i18nStrings.events.create.title,
                    optional: 'optional',
                }}
                onNavigate={onNavigate}
                onCancel={onCancel}
                activeStepIndex={activeStepIndex}
                steps={steps.map(({ wizardStep }) => wizardStep)}
                onSubmit={formik.submitForm}
                isLoadingNextStep={createEventLoading}
            />
        </FormikProvider>
    );
};

export default CreateEventWizard;
