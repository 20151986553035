import Box, { BoxProps } from '@amzn/awsui-components-react/polaris/box';
import Spinner, {
    SpinnerProps,
} from '@amzn/awsui-components-react/polaris/spinner';
import React from 'react';

import styles from './SpinnerWithLabel.module.scss';

interface SpinnerWithLabelProps extends SpinnerProps {
    boxVariant?: BoxProps.Variant;
    children?: React.ReactNode;
}

export const SpinnerWithLabel = (props: SpinnerWithLabelProps) => (
    <div className={styles.container}>
        <Box variant={props.boxVariant || 'h5'}>
            <Spinner variant={props.variant} size={props.size || 'normal'} />
        </Box>
        {props.children && (
            <Box margin={{ left: 's' }} variant={props.boxVariant || 'h5'}>
                {props.children}
            </Box>
        )}
    </div>
);
