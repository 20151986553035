import Alert, { AlertProps } from '@amzn/awsui-components-react/polaris/alert';
import {
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import React, { useCallback, useState } from 'react';

interface UseAlertProps
    extends Exclude<AlertProps, 'dismissible' | 'onDismiss'> {
    id: string;
}

export const STORAGE_KEY = 'dismissibleAlerts';

/**
 * useDismissibleAlert shows a dismissible alert. If a user dismisses
 * the alert, their "preference" will be saved in local storage
 * so they don't see it on refresh
 *
 * @param {AlertProps} props props drilled into Alert component
 * @param {string} props.id unique identifier to store in local storage
 */
const useDismissibleAlert = ({ id, ...alertProps }: UseAlertProps) => {
    const [visible, setVisible] = useState(
        !StoragePartition.getItem(
            StoragePartitionKeys.userPreferences,
            STORAGE_KEY,
            id
        )
    );
    const onDismiss = useCallback(() => {
        setVisible(false);
        StoragePartition.setItem(
            StoragePartitionKeys.userPreferences,
            Date.now(),
            STORAGE_KEY,
            id
        );
    }, []);

    return {
        alertComponent: visible ? (
            <Alert
                {...alertProps}
                onDismiss={onDismiss}
                data-testid={id}
                dismissible
            />
        ) : null,
    };
};

export default useDismissibleAlert;
