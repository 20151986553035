/* istanbul ignore file */
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import React, { createContext, useState } from 'react';

import { DetailLinkBehavior } from '../types';

interface WorkshopCatalogTableContextProps {
    currentDetails?: ContentData;
    setCurrentDetails: React.Dispatch<
        React.SetStateAction<ContentData | undefined>
    >;
    detailLinkBehavior: DetailLinkBehavior;
    setDetailLinkBehavior: React.Dispatch<
        React.SetStateAction<DetailLinkBehavior>
    >;
    basePath: string;
    setBasePath: React.Dispatch<React.SetStateAction<string>>;
}

export const WorkshopCatalogTableContext = createContext(
    {} as WorkshopCatalogTableContextProps
);

const WorkshopCatalogTableProvider = ({
    children,
}: React.PropsWithChildren<React.ReactNode>) => {
    const [currentDetails, setCurrentDetails] = useState<ContentData>();
    const [detailLinkBehavior, setDetailLinkBehavior] = useState<
        DetailLinkBehavior
    >(DetailLinkBehavior.NAVIGATION);
    const [basePath, setBasePath] = useState('');

    return (
        <WorkshopCatalogTableContext.Provider
            value={{
                currentDetails,
                setCurrentDetails,
                detailLinkBehavior,
                setDetailLinkBehavior,
                basePath,
                setBasePath,
            }}>
            {children}
        </WorkshopCatalogTableContext.Provider>
    );
};

export default WorkshopCatalogTableProvider;
