import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';

import getStatusConfig from './get-status-config';
import { EventImpactStatusProps } from './types';

const EventImpactStatus = ({ value, children }: EventImpactStatusProps) => {
    const config = getStatusConfig(value);

    return (
        <StatusIndicator
            type={config.type}
            data-type={config.type}
            colorOverride={config.colorOverride}
            wrapText={false}>
            {children || config.label || value}
        </StatusIndicator>
    );
};

export default EventImpactStatus;
