import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import ProgressBar, {
    ProgressBarProps,
} from '@amzn/awsui-components-react/polaris/progress-bar';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { formatString, ValueWithLabel } from '@amzn/event-engine-js-utils';
import getMinAccountRequirement from 'pages/Events/CreateEvent/utils/get-min-account-requirement';
import React from 'react';

import { i18nStrings } from '../../../../../../../../../constants';

export interface QuotaOverviewProps {
    event: EventEngineEventsService.EventData;
}

const QuotaOverview = ({ event }: QuotaOverviewProps) => {
    const requiredQuota = getMinAccountRequirement(
        event.numberOfForecastedParticipants,
        event.teamSize,
        event.centralAccountRequired
    );
    const reservedQuota = event.accountQuotaContributions?.length
        ? event.accountQuotaContributions.reduce(
              (acc, { reserved }) => acc + (reserved || 0),
              0
          )
        : 0;
    const progressValue = (reservedQuota / requiredQuota) * 100;
    const progressBarProps: ProgressBarProps = {
        variant: 'key-value',
        value: progressValue,
        additionalInfo: formatString(
            i18nStrings.events.quotas.overview.reservationProgressInfo,
            reservedQuota,
            requiredQuota
        ),
    };

    if (progressBarProps.value! >= 100) {
        progressBarProps.status = 'success';
        progressBarProps.resultText = formatString(
            i18nStrings.events.quotas.overview.reservationProgressComplete,
            parseFloat(progressValue.toFixed(1))
        );
    }

    return (
        <Container
            header={
                <Header variant="h2">
                    {i18nStrings.events.quotas.overview.header}
                </Header>
            }
            variant="stacked">
            <ColumnLayout columns={4} variant="text-grid">
                <ValueWithLabel
                    label={
                        i18nStrings.events.quotas.overview.values
                            .participantForecast
                    }>
                    {event.numberOfForecastedParticipants}
                </ValueWithLabel>
                <ValueWithLabel
                    label={i18nStrings.events.quotas.overview.values.teamSize}>
                    {event.teamSize}
                </ValueWithLabel>
                <ValueWithLabel
                    label={
                        i18nStrings.events.quotas.overview.values.requiredQuota
                    }>
                    {requiredQuota}
                    {event.centralAccountRequired && (
                        <Box
                            variant="small"
                            display="block"
                            margin={{ top: 'xxxs' }}>
                            {
                                i18nStrings.events.quotas.overview
                                    .requiredQuotaInfo
                            }
                        </Box>
                    )}
                </ValueWithLabel>
                <ValueWithLabel
                    label={
                        i18nStrings.events.quotas.overview.values
                            .totalReservations
                    }>
                    <ProgressBar {...progressBarProps} />
                </ValueWithLabel>
            </ColumnLayout>
        </Container>
    );
};

export default QuotaOverview;
