import { AWSError } from 'aws-sdk';

export enum MessageTypes {
    approveReview = 'approveReview',
    getContentBuild = 'getContentBuild',
    getContentBuilds = 'getContentBuilds',
    getContentTemplate = 'getContentTemplate',
    getContent = 'getContent',
    getContentPermissions = 'getContentPermissions',
    createContent = 'createContent',
    updateContent = 'updateContent',
    updateReview = 'updateReview',
    updateContentPermissions = 'updateContentPermissions',
    publishContent = 'publishContent',
    general = 'general',
    getEvent = 'getEvent',
    createEvent = 'createEvent',
    updateEventParticipantForecast = 'updateEventParticipantForecast',
    updateEvent = 'updateEvent',
    updateEventSchedule = 'updateEventSchedule',
    updateEventRegions = 'updateEventRegions',
    updateEventPermissions = 'updateEventPermissions',
    listContentCatalogGetQuota = 'listContentCatalogGetQuota',
    listContentCatalogQuotas = 'listContentCatalogQuotas',
    listContentCatalogQuotaHistory = 'listContentCatalogQuotaHistory',
    getEventsQuota = 'getEventsQuota',
    listEventsQuotas = 'listEventsQuotas',
    listEventsQuotaHistory = 'listEventsQuotaHistory',
    scheduleContentDeletion = 'scheduleContentDeletion',
    cancelContentDeletion = 'cancelContentDeletion',
    updateContentBuildPinnedStatus = 'updateContentBuildPinnedStatus',
    banParticipant = 'banParticipant',
    unbanParticipant = 'unbanParticipant',
    terminateTeam = 'terminateTeam',
    cancelEvent = 'cancelEvent',
    provisionEvent = 'provisionEvent',
    startEvent = 'startEvent',
    terminateEvent = 'terminateEvent',
    pauseEvent = 'pauseEvent',
    resumeEvent = 'resumeEvent',
    getTeam = 'getTeam',
}

export enum UpdateContentPermissions {
    NotAuthorizedException = 'NotAuthorizedException',
}

export enum UpdateContentServerMessage {
    ConsistencyCheckException = 'ConsistencyCheckException',
    NotAuthorizedException = 'NotAuthorizedException',
}

export enum ContentErrorCode {
    RESOURCE_IN_USE_EXCEPTION = 'ResourceInUseException',
}

export enum CreateContentServerErrorCode {
    LimitExceededException = 'LimitExceededException',
}

export enum CreateEventErrorCode {
    QuotaExceededException = 'QuotaExceededException',
}

type InferServerErrorMessage<T> = (error: AWSError) => T;

interface Message {
    [key: string]: string | InferServerErrorMessage<string | undefined>;
}

type Messages = {
    [key in MessageTypes]: Message;
};

const messages: Messages = {
    approveReview: {
        '403': (error: AWSError) => {
            if (
                // error.code ===
                //     UpdateContentServerMessage.NotAuthorizedException &&
                error.message.includes('not in valid group')
            ) {
                return 'Only active Workshop Guardians can review workshops. Refer [here](https://w.amazon.com/bin/view/AWS/Teams/SA/Customer_Engagements/workshops/workshop-guardians) for additional details on becoming a Workshop Guardian.';
            }
        },
    },
    getContentBuild: {
        '400': 'The specified build ID is invalid.',
        '404': 'The requested build no longer exists.',
    },
    getEvent: {
        '400': 'The specified event ID is invalid.',
        '404': 'The requested event no longer exists.',
    },
    getContentBuilds: {
        '403': 'You are not authorized to view builds for this content.',
    },
    getContentTemplate: {
        '404': 'The requested workshop template no longer exists.',
    },
    getContent: {
        '403':
            'You are not authorized to view this workshop. Please contact the workshop owner if you need access.',
        '404': 'The requested workshop no longer exists.',
    },
    getContentPermissions: {
        '404': 'The requested workshop no longer exists.',
    },
    createContent: {
        '403': 'You are not authorized to perform this action.',
        '422':
            'The request could not be processed because of an invalid or missing required parameter.',
        '429': (error: AWSError) => {
            if (
                error.code ===
                CreateContentServerErrorCode.LimitExceededException
            ) {
                return 'Maximum number of Workshops created. Please contact the Workshop Studio team for further assistance.';
            }
        },
    },
    updateReview: {
        '400': (error: AWSError) => {
            if (
                error.message ===
                UpdateContentServerMessage.ConsistencyCheckException
            ) {
                return 'Another reviewer has recently modified this review. Please save the work, refresh the page, and try again.';
            }
        },
    },
    updateContent: {
        '403': 'You are not authorized to perform this action.',
        '404': 'The workshop you are trying to update no longer exists.',
    },
    updateContentPermissions: {
        '403': (error: AWSError) => {
            // TODO: remove this case after backend changes are live for new error message
            if (error.message.includes('not in valid group')) {
                return 'Reviewer must be a valid email address associated with a [Workshop Guardian](https://quip-amazon.com/9NhqApMtIUNP/Workshop-Guardians-List-by-TFC).';
            }
            if (
                error.message.includes(
                    'is not part of a verified reviewer group'
                )
            ) {
                return `${error.message}. Reviewer must be a valid email address associated with a [Workshop Guardian](https://quip-amazon.com/9NhqApMtIUNP/Workshop-Guardians-List-by-TFC).`;
            }
        },
        '404': 'The workshop you are trying to update no longer exists.',
    },
    publishContent: {
        '404': 'The content or build no longer exists.',
    },
    updateEventParticipantForecast: {
        '422': 'Insufficient AWS account quota for the requested amount.',
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    updateEvent: {
        '400':
            'The request you are trying to send is missing data, or contains invalid data.',
    },
    createEvent: {
        '422': 'Insufficient AWS account quota for this event.',
    },
    updateEventSchedule: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    updateEventRegions: {
        '400': ({ message }) => message,
    },
    updateEventPermissions: {
        '400': ({ message }) => message,
    },
    listContentCatalogQuotas: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    listContentCatalogGetQuota: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    listContentCatalogQuotaHistory: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    getEventsQuota: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    listEventsQuotas: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    listEventsQuotaHistory: {
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
    scheduleContentDeletion: {
        '400':
            'Unable to schedule workshop for deletion since it is already scheduled for deletion.',
        '409':
            'Unable to schedule workshop for deletion since it is blocked by one or more related resources.',
    },
    cancelContentDeletion: {
        '400':
            'Unable to cancel workshop deletion since it is already cancelled.',
    },
    updateContentBuildPinnedStatus: {
        '422': 'Maximum number of pinned builds reached.',
        '400':
            'Unable to unpin a build that is currently associated with an active event. All current blocking resources can be found under `Related resources` tab.',
    },
    banParticipant: {
        '409': 'The participant has already been banned.',
    },
    unbanParticipant: {
        '409': 'The participant has already been unbanned.',
    },
    terminateTeam: {
        '409':
            'The team is currently terminating or has already been terminated.',
    },
    cancelEvent: {
        '409': 'The event has already been canceled.',
    },
    provisionEvent: {
        '409': 'The event is in an invalid state.',
    },
    startEvent: {
        '409': 'The event is in an invalid state.',
    },
    terminateEvent: {
        '409':
            'The event is currently terminating or has already been terminated.',
    },
    pauseEvent: {
        '409': 'The event is currently pausing or has already been paused.',
    },
    resumeEvent: {
        '409': 'The event has already been resumed.',
    },
    getTeam: {
        '404': 'The requested team does not exist.',
    },
    general: {
        '400':
            'The request you are trying to send is missing data, or contains invalid data.',
        '401': 'Your credentials are not valid.',
        '403': 'You are not authorized to perform this action.',
        '404': 'The information you requested no longer exists.',
        '409':
            'The request could not be completed since one or more resources are not in the expected state.',
        '429':
            'You have exceeded the rate limit for this request. Please wait a few minutes before trying this request again.',
        '500':
            'Could not process the request right now because of an issue with the server. Try again later.',
    },
};

/**
 * Typescript guard that will determine if a given
 * argument is a Function
 */
const isFunction = (value: unknown): value is Function => {
    return typeof value === 'function';
};

/**
 * Given a value, if it is a function try to invoke and get
 * appropriate error message, else return string if found.
 */
const invokeOrReturnValue = (
    val: string | InferServerErrorMessage<string | undefined>,
    error: AWSError
): string | undefined => {
    if (isFunction(val)) {
        return val(error);
    }
    return val;
};

class ErrorMessage {
    /**
     * Gets an error message string based on AWSError.statusCode and MessageTypes.
     * Attempts to get error message with specified status code and type. If that does
     * not exist, it will fall back to a general message with the specified status code.
     * If that also does not exist, it will return a general 500 error message.
     * @param error
     * @param type
     */
    getMessage(
        error: AWSError,
        type: keyof typeof messages = MessageTypes.general
    ): string {
        return (
            invokeOrReturnValue(messages?.[type]?.[error.statusCode], error) ||
            invokeOrReturnValue(messages.general?.[error.statusCode], error) ||
            (invokeOrReturnValue(messages.general?.['500'], error) as string)
        );
    }
}

export default new ErrorMessage();
