import {
    useLazyRequest,
    usePaginator,
    useRequest,
} from '@amzn/event-engine-js-utils';
import EventEngineContentCatalogService, {
    ListContentAssetsRequest,
    ListContentAssetsResponse,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { breadcrumbsState, navigationOpenState } from 'atoms';
import { AWSError } from 'aws-sdk';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import i18nStrings from 'constants/i18n-strings';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { EEContentCatalog } from 'services';
import { DEFAULT_API_PAGE_SIZE } from 'utils/config';

import { TabIDs } from '../utils/constants';
import GenerateWorkshopStaticAssetURLsForm from './GenerateWorkshopStaticAssetURLsForm';
import SelectWorkshopAssetsTable from './SelectWorkshopAssetsTable';

interface RouteParams {
    workshopId: string;
}

const GenerateWorkshopStaticAssetURLsContainer = () => {
    const [breadcrumbs, setBreadcrumbs] = useRecoilState(breadcrumbsState);
    const setNavigationOpen = useSetRecoilState(navigationOpenState);

    const { workshopId: contentId } = useParams<RouteParams>();

    const { data: getContentResponse } = useRequest<
        typeof EEContentCatalog.getContent,
        AWSError
    >(EEContentCatalog.getContent, {}, contentId);

    const [assets, setAssets] = useState<
        EventEngineContentCatalogService.AssetData[]
    >();
    const [loading, setIsLoading] = useState(false);
    const [getAssets, { data, isError: error }] = useLazyRequest<
        typeof EEContentCatalog.getContentAssets,
        AWSError
    >(EEContentCatalog.getContentAssets, {});
    const paginator = usePaginator<
        ListContentAssetsRequest,
        ListContentAssetsResponse
    >(getAssets, DEFAULT_API_PAGE_SIZE, 'assets');

    const getPaginatedData = useCallback((fresh = false, { contentId }) => {
        return paginator.getPaginatedData<ListContentAssetsRequest>(0, '*', {
            fresh,
            clientArgs: { contentId },
        });
    }, []);

    const refetch = useCallback(() => {
        setIsLoading(true);
        setAssets(undefined);
        getPaginatedData(true, { contentId });
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setNavigationOpen(false);

        const baseBreadcrumbs = [
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: i18nStrings.myWorkshops.title,
                href: routes.workshops,
            },
            {
                text: getContentResponse?.content?.title || contentId,
                href: `${routes.workshops}/${contentId}`,
            },
        ];

        if (!getContentResponse) {
            !breadcrumbs.length && setBreadcrumbs(baseBreadcrumbs);

            return;
        }

        setBreadcrumbs([
            ...baseBreadcrumbs,
            {
                text: i18nStrings.workshops.assetStaticUrls.title,
                href: `${routes.workshops}/${contentId}#${TabIDs.assetStaticUrls}`,
            },
            {
                text:
                    i18nStrings.workshops.generateWorkshopStaticAssetURLs.title,
                href: `${routes.workshops}/${contentId}/${routeTokens.assets}/${routeTokens.staticUrls}/${routeTokens.generate}`,
            },
        ]);
    }, [getContentResponse]);

    useEffect(() => {
        if (data?.assets) {
            setAssets([...(assets || []), ...data.assets]);
            !data.nextToken && setIsLoading(false);
        }
    }, [data]);

    if (!contentId) {
        return null;
    }

    return (
        <>
            <GenerateWorkshopStaticAssetURLsForm contentId={contentId}>
                <SelectWorkshopAssetsTable
                    assets={assets}
                    loading={loading}
                    error={error}
                    refetch={refetch}
                />
            </GenerateWorkshopStaticAssetURLsForm>
        </>
    );
};

export default GenerateWorkshopStaticAssetURLsContainer;
