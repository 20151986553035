import { CONTENT_PUBLISH_STATE } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import isAccountSourceDefined from 'components/AccountSourceStatus/is-account-source-defined';
import getBooleanStatusConfig from 'components/BooleanStatus/utils/get-status-config';
import getEventImpactStatusConfig from 'components/EventImpactStatus/get-status-config';

import { i18nStrings } from '../../../../../constants';
import {
    AttributeName,
    AttributeValueKey,
    WorkshopCatalogTableItem,
} from '../types';

const ATTRIBUTE_VALUE_MAP: Record<AttributeName, AttributeValueKey> = {
    [AttributeName.TOPICS]: AttributeValueKey.STRING_LIST_VALUE,
    [AttributeName.AWS_SERVICES]: AttributeValueKey.STRING_LIST_VALUE,
    [AttributeName.COMPLEXITY_LEVEL]: AttributeValueKey.STRING_VALUE,
    [AttributeName.RECOMMENDED_DURATION]: AttributeValueKey.STRING_VALUE,
    [AttributeName.INDUSTRY_VERTICALS]: AttributeValueKey.STRING_LIST_VALUE,
    [AttributeName.ENGAGEMENT_MODELS]: AttributeValueKey.STRING_LIST_VALUE,
};

const _getAttributeValue = (
    name: AttributeName,
    attributes: EventEngineContentCatalogService.ContentAttributes = []
) =>
    attributes.find((attribute) => attribute.name === name)?.value[
        ATTRIBUTE_VALUE_MAP[name]
    ];

export const processData = (
    contents: EventEngineContentCatalogService.ContentData[]
) => {
    const res: WorkshopCatalogTableItem[] = [];

    contents.forEach((content) => {
        const flattenedContent: Partial<WorkshopCatalogTableItem> = {
            ...content,
            get attributeTopics() {
                return _getAttributeValue(
                    AttributeName.TOPICS,
                    this.attributes
                ) as string[];
            },
            get attributeAWSServices() {
                return _getAttributeValue(
                    AttributeName.AWS_SERVICES,
                    this.attributes
                ) as string[];
            },
            get attributeComplexityLevel() {
                return _getAttributeValue(
                    AttributeName.COMPLEXITY_LEVEL,
                    this.attributes
                ) as string;
            },
            get attributeRecommendedDuration() {
                return _getAttributeValue(
                    AttributeName.RECOMMENDED_DURATION,
                    this.attributes
                ) as string;
            },
            get attributeIndustryVerticals() {
                return _getAttributeValue(
                    AttributeName.INDUSTRY_VERTICALS,
                    this.attributes
                ) as string[];
            },
            get attributeEngagementModels() {
                return _getAttributeValue(
                    AttributeName.ENGAGEMENT_MODELS,
                    this.attributes
                ) as string[];
            },
        };

        if (content.publishState === CONTENT_PUBLISH_STATE.PUBLISHED) {
            flattenedContent.publishedType = content.publishedRequiresAuth
                ? i18nStrings.workshops.publishingStates.publishedTypes.internal
                : i18nStrings.workshops.publishingStates.publishedTypes.public;
        }

        flattenedContent.isAccountSourceDefined = isAccountSourceDefined(
            content.contentSpecSummary?.accountSources
        );
        flattenedContent.isAccountSourceDefinedDisplay = getBooleanStatusConfig(
            flattenedContent.isAccountSourceDefined
        ).label;
        flattenedContent.eventReadinessDisplayState =
            getEventImpactStatusConfig(content.eventReadiness.summary).label ||
            content.eventReadiness.summary;

        res.push(flattenedContent as WorkshopCatalogTableItem);
    });

    return res;
};

export default processData;
