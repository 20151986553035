import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Form from '@amzn/awsui-components-react/polaris/form';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    formatString,
    FormikField,
    InputField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import formFieldStyles from 'assets/styles/modules/form-field.module.scss';
import { AWSError } from 'aws-sdk/lib/error';
import { AppTopNavContext } from 'components/AppTopNav';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import useFeatureFlag from 'hooks/use-feature-flag';
import getMaxParticipantForecast from 'pages/Events/CreateEvent/utils/get-max-participant-forecast';
import getMinAccountRequirement from 'pages/Events/CreateEvent/utils/get-min-account-requirement';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';
import { MAX_TEAM_SIZE } from 'utils/config';
import pluralizeSimple from 'utils/pluralize-simple';

import { i18nStrings } from '../../../../../constants';
import Schema, { FieldName, SchemaType } from './schema';

export interface UpdateParticipantForecastModalProps {
    event: Pick<
        EventEngineEventsService.EventData,
        'numberOfForecastedParticipants' | 'eventId' | 'type' | 'teamSize'
    >;
    isVisible: boolean;
    maxEventSize: number;
    onModalClosed: () => void;
    refetchGetEvent: () => Promise<void>;
}

const UpdateParticipantForecastModal: React.FC<UpdateParticipantForecastModalProps> = ({
    event,
    onModalClosed,
    maxEventSize,
    isVisible,
    refetchGetEvent,
}: UpdateParticipantForecastModalProps) => {
    // TODO: Remove once multiParticipants is generally available
    const multiParticipantsFeatureEnabled = useFeatureFlag('multiParticipants');
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    const { showNotification } = useContext(NotificationsContext);
    const [
        updateEventAttendees,
        {
            isLoading: updateEventParticipantForecastLoading,
            isError: updateEventParticipantForecastError,
            data: updateEventParticipantForecastData,
        },
    ] = useLazyRequest<
        typeof EEEvents.updateEventParticipantForecast,
        AWSError
    >(EEEvents.updateEventParticipantForecast, {});

    const onSubmit = async (inputValues: SchemaType) => {
        const values = Schema.cast(inputValues);

        await updateEventAttendees({
            numberOfForecastedParticipants: values[
                FieldName.PARTICIPANT_FORECAST
            ]!,
            // passing undefined updates participant forecast without modifying team size.
            // this will allow participant forecast to be updated regardless of the team size
            // value and multi-participants feature flag state
            teamSize: multiParticipantsFeatureEnabled
                ? values[FieldName.TEAM_SIZE]
                : undefined,
            eventId: event.eventId,
        });
    };

    const formik = useFormik({
        validateOnChange,
        validateOnBlur: false,
        validationSchema: Schema,
        initialValues: {
            [FieldName.MAX_EVENT_SIZE]: maxEventSize,
            [FieldName.EVENT_TYPE]: event.type,
            [FieldName.PARTICIPANT_FORECAST]:
                event.numberOfForecastedParticipants,
            [FieldName.TEAM_SIZE]: event.teamSize,
        },
        onSubmit,
    });
    const participantForecast = formik.values[FieldName.PARTICIPANT_FORECAST];
    const teamSize = formik.values[FieldName.TEAM_SIZE];
    // to reduce cognitive load, central account requirement will not be included in the
    // computed account requirement display to keep this view participant focused.
    const requiredAccounts =
        participantForecast && teamSize
            ? getMinAccountRequirement(participantForecast, teamSize)
            : 0;

    useEffect(() => {
        if (updateEventParticipantForecastData) {
            showNotification(
                i18nStrings.events.updateParticipantForecastModal
                    .successNotification,
                {
                    type: 'success',
                }
            );
            refetchGetEvent();
            closeModal();
            refreshQuotaInfo();
        }
    }, [updateEventParticipantForecastData]);

    const closeModal = () => {
        hideModal();
        onModalClosed();
    };

    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, []);

    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);

    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: onModalClosed,
        modalHeader: i18nStrings.events.updateParticipantForecastModal.header,
        actions: {
            primary: {
                text:
                    i18nStrings.events.updateParticipantForecastModal
                        .actionUpdate,
                onClick: onPrimaryActionClick,
                disabled: !formik.dirty || !formik.isValid,
                loading: updateEventParticipantForecastLoading,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <Form
                    errorText={
                        updateEventParticipantForecastError &&
                        ErrorMessage.getMessage(
                            updateEventParticipantForecastError,
                            MessageTypes.updateEventParticipantForecast
                        )
                    }>
                    <SpaceBetween size="l" direction="vertical">
                        <FormikField<SchemaType>
                            name={FieldName.PARTICIPANT_FORECAST}
                            dataTestId={FieldName.PARTICIPANT_FORECAST}
                            formFieldProps={{
                                description:
                                    i18nStrings.events.create.fields
                                        .attendeeForecast.description,
                                label:
                                    i18nStrings.events.create.fields
                                        .attendeeForecast.label,
                                constraintText:
                                    maxEventSize &&
                                    formatString(
                                        event.type === EVENT_TYPE.TEST
                                            ? i18nStrings.events.create.fields
                                                  .attendeeForecast
                                                  .constraintTest
                                            : i18nStrings.events.create.fields
                                                  .attendeeForecast
                                                  .constraintProduction,
                                        getMaxParticipantForecast(
                                            maxEventSize,
                                            teamSize
                                        )
                                    ),
                                stretch: true,
                                children: (
                                    <InputField<SchemaType>
                                        name={FieldName.PARTICIPANT_FORECAST}
                                        placeholder={
                                            i18nStrings.events.create.fields
                                                .attendeeForecast.label
                                        }
                                        inputProps={{
                                            disabled: updateEventParticipantForecastLoading,
                                            type: 'number',
                                        }}
                                    />
                                ),
                            }}
                        />
                        <FormikField<SchemaType>
                            name={FieldName.TEAM_SIZE}
                            dataTestId={FieldName.TEAM_SIZE}
                            formFieldProps={{
                                label:
                                    i18nStrings.events.create.fields.teamSize
                                        .label,
                                description:
                                    i18nStrings.events.create.fields.teamSize
                                        .description,
                                constraintText:
                                    multiParticipantsFeatureEnabled &&
                                    formatString(
                                        i18nStrings.events.create.fields
                                            .teamSize.constraintText,
                                        MAX_TEAM_SIZE
                                    ),
                                children: (
                                    <div className={formFieldStyles.control}>
                                        <InputField<SchemaType>
                                            name={FieldName.TEAM_SIZE}
                                            placeholder={
                                                i18nStrings.events.create.fields
                                                    .teamSize.label
                                            }
                                            inputProps={{
                                                disabled:
                                                    !multiParticipantsFeatureEnabled ||
                                                    updateEventParticipantForecastLoading,
                                                type: 'number',
                                            }}
                                        />
                                        {teamSize > 0 &&
                                            participantForecast > 0 && (
                                                <span
                                                    className={
                                                        formFieldStyles.postfix
                                                    }>
                                                    <Popover
                                                        content={
                                                            i18nStrings.events
                                                                .create.fields
                                                                .teamSize
                                                                .totalAccounts
                                                                .popoverContent
                                                        }
                                                        triggerType="text"
                                                        dismissButton={false}>
                                                        {formatString(
                                                            i18nStrings.events
                                                                .create.fields
                                                                .teamSize
                                                                .totalAccounts
                                                                .label,
                                                            requiredAccounts,
                                                            pluralizeSimple(
                                                                i18nStrings
                                                                    .events
                                                                    .create
                                                                    .fields
                                                                    .teamSize
                                                                    .totalAccounts
                                                                    .unit,
                                                                requiredAccounts
                                                            )
                                                        )}
                                                    </Popover>
                                                </span>
                                            )}
                                    </div>
                                ),
                            }}
                        />
                        {multiParticipantsFeatureEnabled && (
                            <Alert>
                                {
                                    i18nStrings.events
                                        .updateParticipantForecastModal
                                        .updateInfo
                                }
                            </Alert>
                        )}
                    </SpaceBetween>
                </Form>
            </FormikProvider>
        ),
    });

    return modalComponent;
};

export default UpdateParticipantForecastModal;
