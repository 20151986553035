import { CONTENT_BUILD_PRUNING_STATE } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { LoadingComponent, useRequest } from '@amzn/event-engine-js-utils';
import { ContentBuildData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { AWSError } from 'aws-sdk/lib/error';
import { RouteParams } from 'components/AppRoutes/routes.constants';
import { routeTokens } from 'components/AppRoutes/routes.constants';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import EEContentCatalog from 'services/ee-content-catalog';
import ErrorMessage, { MessageTypes } from 'services/error-message';

interface IValidBuild {
    success: boolean;
    latestBuild?: string;
}

const PreviewBuild = () => {
    const location = useLocation();
    const params = useParams<RouteParams>();
    const [validBuild, setValidBuild] = useState<IValidBuild>();
    const { data, isError, isLoading } = useRequest<
        typeof EEContentCatalog['getContentBuilds'],
        AWSError
    >(
        EEContentCatalog.getContentBuilds,
        { context: EEContentCatalog },
        {
            contentId: params.workshopId as string,
            pruningState: CONTENT_BUILD_PRUNING_STATE.NOT_PRUNED,
            limit: 5,
        }
    );

    useEffect(() => {
        if (data?.contentBuilds) {
            const validBuild: ContentBuildData | undefined = Array.isArray(
                data.contentBuilds
            )
                ? data.contentBuilds.find(
                      (b) => b.state.toLowerCase() === 'success'
                  )
                : undefined;

            if (validBuild) {
                setValidBuild({
                    success: true,
                    latestBuild: validBuild.contentBuildId,
                });
            } else {
                setValidBuild({
                    success: false,
                    latestBuild: '',
                });
            }
        }
    }, [data]);

    if (isError) {
        return (
            <Alert type="error">
                {ErrorMessage.getMessage(
                    isError,
                    MessageTypes.getContentBuilds
                )}
            </Alert>
        );
    }

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (!validBuild?.success) {
        return validBuild?.success === false ? (
            <Alert type="error">
                Unable to find latest build for workshop, {params.workshopId}.
                Please try again later.
            </Alert>
        ) : null;
    }

    return (
        <Redirect
            to={
                location.pathname.replace(
                    routeTokens.latest,
                    validBuild.latestBuild!
                ) + location.hash
            }
        />
    );
};

export default PreviewBuild;
