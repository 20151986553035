import Alert from '@amzn/awsui-components-react/polaris/alert';
import { LoadingComponent, useRequest } from '@amzn/event-engine-js-utils';
import {
    breadcrumbsState,
    contentTypeState,
    initialAtomStates,
    navigationOpenState,
    toolsState,
} from 'atoms';
import { AWSError } from 'aws-sdk/lib/error';
import { RouteParams, routes } from 'components/AppRoutes/routes.constants';
import NotificationsProvider from 'contexts/NotificationsProvider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ErrorMessage, MessageTypes } from 'services';
import { EEEvents } from 'services';

import { i18nStrings } from '../../../constants';
import CentralAccountProvider from './contexts/CentralAccountProvider';
import TeamsPollerProvider from './contexts/TeamsPollerProvider';
import EventDetails from './EventDetails';

const EventDetailsContainer = () => {
    const setContentType = useSetRecoilState(contentTypeState);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    const setTools = useSetRecoilState(toolsState);
    const setNavigationOpen = useSetRecoilState(navigationOpenState);
    const params = useParams<RouteParams>();
    const eventId = params.eventId!;
    const {
        isLoading: getEventLoading,
        isError: getEventError,
        data: getEventData,
        refetch: refetchGetEvent,
    } = useRequest<typeof EEEvents.getEvent, AWSError>(
        EEEvents.getEvent,
        {},
        eventId
    );

    useEffect(() => {
        setNavigationOpen(true);
        setContentType('default');
        setTools(initialAtomStates.tools);
    }, []);

    useEffect(() => {
        const breadCrumbs = [
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: i18nStrings.events.title,
                href: routes.events,
            },
        ];

        const eventData = getEventData && {
            text: getEventData.event?.title || eventId,
            href: `${routes.events}/${getEventData.event?.eventId}`,
        };

        eventData && breadCrumbs.push(eventData);

        setBreadcrumbs(breadCrumbs);
    }, [setBreadcrumbs, getEventData]);

    return (
        <NotificationsProvider>
            {getEventData?.event && (
                <TeamsPollerProvider eventId={getEventData.event.eventId}>
                    <CentralAccountProvider event={getEventData.event}>
                        <EventDetails
                            event={getEventData.event}
                            refetchGetEvent={refetchGetEvent}
                            refetchGetEventLoading={getEventLoading}
                            refetchGetEventError={!!getEventError}
                        />
                    </CentralAccountProvider>
                </TeamsPollerProvider>
            )}
            {getEventError && (
                <Alert type="error">
                    {ErrorMessage.getMessage(
                        getEventError,
                        MessageTypes.getEvent
                    )}
                </Alert>
            )}
            {getEventLoading && !getEventData && (
                <LoadingComponent
                    spinnerWithLabelProps={{
                        children: 'Loading event details...',
                    }}
                />
            )}
        </NotificationsProvider>
    );
};

export default EventDetailsContainer;
