import { AWSError } from 'aws-sdk/lib/error';
import EEEvents from 'services/ee-events';

export interface TeamDetailsRouteParams {
    eventId: string;
    teamId: string;
}

export enum ActionID {
    COPY_EVENT_LINK_ACTION = 'copy_event_link_action',
    ACCESS_AWS_ACCOUNT = 'access_aws_account',
    TERMINATE_TEAM = 'terminate_team',
}

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

interface UseRequest<T extends (...args: any[]) => void> {
    data?: Awaited<ReturnType<T>> | null;
    isError?: AWSError;
    isLoading?: boolean;
    refetch: () => Promise<void>;
}

export interface TeamDetailsContextValue {
    eventRequest: UseRequest<typeof EEEvents.getEvent>;
    teamRequest: UseRequest<typeof EEEvents.getTeam>;
    participantsRequest: UseRequest<typeof EEEvents.getParticipants>;
    isLoading?: boolean;
    error?: AWSError;
}

export enum TabID {
    STACKS = 'stacks',
    PARTICIPANTS = 'participants',
}
