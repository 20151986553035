/* istanbul ignore file */
import Box from '@amzn/awsui-components-react/polaris/box';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Link from '@amzn/awsui-components-react/polaris/link';
import React from 'react';

import { i18nStrings } from '../../../constants';
import styles from './WorkshopTools.module.scss';

export const WorkshopCatalog = () => (
    <HelpPanel header={<h2>Your workshops</h2>}>
        <Box variant="p">
            Any workshop you own or have visibility of (via workshop
            permissions) will be visible on this page.
        </Box>
    </HelpPanel>
);

export const WorkshopCatalogPublic = () => (
    <HelpPanel header={<h2>Workshop catalog</h2>}>
        <Box variant="p">
            Listing of published workshop content that has been reviewed by AWS
            Experts, and is ready to be delivered to customers for hands-on
            enablement.
        </Box>
    </HelpPanel>
);

export const WorkshopDetails = () => (
    <HelpPanel header={<h2>Workshops</h2>}>
        <Box variant="p">
            A workshop is an artifact designed to teach or introduce practical
            skills, techniques, or concepts which participants can use in their
            work or their daily lives.{' '}
        </Box>
    </HelpPanel>
);

export const TopicField = () => (
    <HelpPanel header={<h2>Topics</h2>}>
        <Box variant="p">
            The specific knowledge domain(s) that the workshop aims to enable
            participants on.
        </Box>
    </HelpPanel>
);

export const ServicesField = () => (
    <HelpPanel header={<h2>Featured AWS services</h2>}>
        <Box variant="p">
            The AWS Service(s) that will be covered as part of the workshop
        </Box>
    </HelpPanel>
);

export const IndustryVerticalField = () => (
    <HelpPanel header={<h2>Industry vertical</h2>}>
        <Box variant="p">
            The specific industry that your workshop is applicable for. Please
            select <q>Not relevant</q> if your workshop is not industry
            specific.
        </Box>
    </HelpPanel>
);

export const EngagementModelField = () => (
    <HelpPanel header={<h2>Engagement type</h2>}>
        <Box variant="p">
            An engagement type is an established customer engagement event
            designed for demand generation or opportunity acceleration (e.g.,
            Immersion Day, Hackathon, etc).
        </Box>
    </HelpPanel>
);

export const WorkshopPermissionsContainer = () => (
    <HelpPanel header={<h2>Permissions</h2>}>
        <Box>
            <Box variant="p">
                Ability to manage permissions using the following rules:
            </Box>
            <ul className={styles.bulletList}>
                <li>
                    <Box variant="strong">Owner</Box>: Original author with full
                    access
                </li>
                <li>
                    <Box variant="strong">Co-owner</Box>: Designated by owner as
                    the co-owner with full access
                </li>
                <li>
                    <Box variant="strong">Contributor</Box>: A user with
                    permissions to view and edit content
                </li>
                <li>
                    <Box variant="strong">Reviewer</Box>: A user with
                    permissions to certify the workshop so that it is ready to
                    be published. Workshops can only be reviewed by Workshop
                    Guardians. A directory of Workshop Guardians and their TFC
                    can be found{' '}
                    <Link
                        href="https://quip-amazon.com/9NhqApMtIUNP/Workshop-Guardians-List-by-TFC#GGM9CAegf7h"
                        target="_blank">
                        here
                    </Link>
                    .
                </li>
                <li>
                    <Box variant="strong">Viewer</Box>: A user with limited
                    access to view only
                </li>
            </ul>
        </Box>
    </HelpPanel>
);
