import {
    AWSCredentials,
    ClientFactory,
    CredentialRoles,
    DeferredPromise,
} from '@amzn/event-engine-js-utils';
import AppSettings from 'services/app-settings';

export type ClientFactoryClients = ReturnType<typeof ClientFactory.initialize>;

class AWSClient {
    private deferredClientPromise?: DeferredPromise<ClientFactoryClients>;
    private static instance?: AWSClient;

    static getInstance(credentials: AWSCredentials): AWSClient {
        if (!AWSClient.instance) {
            AWSClient.instance = new AWSClient(credentials);
        }

        return AWSClient.instance;
    }

    static __removeInstance__() {
        if (process.env.NODE_ENV !== 'test') {
            return;
        }

        AWSClient.instance = undefined;
    }

    private constructor(private credentials: AWSCredentials) {}

    get roleArn() {
        return this.credentials.currentRoleArn;
    }

    getClient = async (
        role: CredentialRoles
    ): Promise<ClientFactoryClients> => {
        this.credentials.currentRole = role;

        if (!this.deferredClientPromise) {
            this.deferredClientPromise = new DeferredPromise<
                ClientFactoryClients
            >();

            try {
                const {
                    region,
                    stage,
                    // cloudWatchNamespace,
                    // cloudWatchLogGroup,
                    // cloudWatchLogStream,
                } = await AppSettings();

                // TODO: Need to revisit entire analytics approach
                //  Disabling for now since associated backend infra is removed
                // this.deferredClientPromise.resolve(
                //     ClientFactory.initialize(this.credentials, {
                //         region,
                //         stage,
                //         cloudWatchNamespace,
                //         cloudWatchLogGroup,
                //         cloudWatchLogStream,
                //     })
                // );
            } catch (err) {
                this.deferredClientPromise.reject(err);
            }
        }

        return await this.deferredClientPromise.promise;
    };
}

export default AWSClient;
