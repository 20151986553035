import { UPDATE_EVENT_QUOTA_RESERVATION_OPERATION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    BaseMarkdown,
    FormikField,
    InputField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { AppTopNavContext } from 'components/AppTopNav/AppTopNav.provider';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import AccountQuotaStatus from 'pages/Events/components/AccountQuotaStatus';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';
import utcToZonedTime from 'utils/date-fns/utc-to-zoned-time';

import { i18nStrings } from '../../../../../../../../../constants';
import Schema, { FieldName, SchemaType } from './schema';

export interface AddReservationModalProps {
    event: EventEngineEventsService.EventData;
    max: number;
    onDismiss: () => void;
    isVisible?: boolean;
    onSuccess?: () => void;
    isOwner?: boolean;
}

const NoopComponent: FC = ({ children }) => <>{children}</>;

const AddReservationModal = ({
    event,
    max,
    onDismiss,
    isVisible = false,
    onSuccess,
    isOwner,
}: AddReservationModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    const startDate = useMemo(
        () =>
            utcToZonedTime(event.scheduledStartTime!, {
                timezone: event.timeZone,
            }),
        [event]
    );
    const [
        updateQuotaReservation,
        { data, isLoading, isError },
    ] = useLazyRequest<typeof EEEvents.updateQuotaReservation, AWSError>(
        EEEvents.updateQuotaReservation,
        {}
    );
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const onSubmit = useCallback(
        (values: SchemaType) => {
            updateQuotaReservation({
                eventId: event.eventId,
                numberOfAccounts: values[FieldName.AMOUNT],
                operation: UPDATE_EVENT_QUOTA_RESERVATION_OPERATION.ADD,
            });
        },
        [event]
    );
    const formik = useFormik({
        validationSchema: Schema,
        validateOnBlur: false,
        validateOnChange,
        initialValues: {
            [FieldName.MAX]: max,
            [FieldName.AMOUNT]: undefined!,
        },
        onSubmit,
    });
    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, [formik]);
    const onQuotaStatusResolve = useCallback(
        (e?: EventEngineEventsService.QuotaMonthly) => {
            if (typeof e?.available !== 'number') {
                return;
            }

            e.available < max &&
                formik.setFieldValue(FieldName.MAX, e.available);
            e.available > 0 && setCanEdit(true);
        },
        []
    );
    const { modalComponent } = useModal({
        isVisible,
        onDismiss,
        modalHeader: i18nStrings.events.quotas.addReservationModal.header,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                disabled: isLoading,
                onClick: onDismiss,
            },
            primary: {
                text:
                    i18nStrings.events.quotas.addReservationModal.primaryAction,
                loading: isLoading,
                disabled: !(formik.isValid && canEdit),
                onClick: onPrimaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <SpaceBetween size="l">
                    <TextContent>
                        <BaseMarkdown
                            linkTarget="_blank"
                            components={{ p: NoopComponent }}>
                            {
                                i18nStrings.events.quotas.addReservationModal
                                    .description
                            }
                        </BaseMarkdown>
                    </TextContent>
                    <FormikField<SchemaType>
                        name={FieldName.AMOUNT}
                        dataTestId={FieldName.AMOUNT}
                        formFieldProps={{
                            label:
                                i18nStrings.events.quotas.addReservationModal
                                    .fields.amount.label,
                            constraintText: (
                                <AccountQuotaStatus
                                    value={formik.values[FieldName.AMOUNT]}
                                    blockDate={{
                                        date: startDate.date,
                                        time: startDate.time,
                                        period: startDate.period,
                                        timezone: event.timeZone,
                                    }}
                                    onResolve={onQuotaStatusResolve}
                                />
                            ),
                            children: (
                                <InputField<SchemaType>
                                    name={FieldName.AMOUNT}
                                    placeholder={
                                        i18nStrings.events.quotas
                                            .addReservationModal.fields.amount
                                            .label
                                    }
                                    inputProps={{
                                        type: 'number',
                                        inputMode: 'numeric',
                                        disabled: !canEdit,
                                    }}
                                />
                            ),
                        }}
                    />
                    <Alert type="info">
                        {isOwner
                            ? i18nStrings.events.quotas.addReservationModal
                                  .alerts.quotaContributionInfoOwner
                            : i18nStrings.events.quotas.addReservationModal
                                  .alerts.quotaContributionInfoContributor}
                    </Alert>
                </SpaceBetween>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        showNotification(
            i18nStrings.events.quotas.addReservationModal.notifications.success
                .content,
            { type: 'success', clearStack: false }
        );
        refreshQuotaInfo();
        onSuccess?.();
        onDismiss();
    }, [data]);

    useEffect(() => {
        if (!isError) {
            return;
        }

        isError &&
            showNotification(isError.message, {
                header:
                    i18nStrings.events.quotas.addReservationModal.notifications
                        .error.header,
                type: 'error',
                clearStack: false,
            });
        onDismiss();
    }, [isError]);

    return modalComponent;
};

export default AddReservationModal;
