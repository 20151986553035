import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface OverviewTabProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
}

export enum ActionID {
    UPDATE_SCHEDULE = 'update_schedule',
    UPDATE_PARTICIPANT_FORECAST = 'update_participant_forecast',
    UPDATE_REGIONS = 'regions',
    UPDATE_CAPACITY = 'update_capacity',
}

export enum TestID {
    SECTION_DETAILS = 'section_details',
    SECTION_CONFIGURATION = 'section_configuration',
}
