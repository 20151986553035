import {
    MergedFormSchema,
    Step,
} from '../../../components/CreateEventForm.validation';
import { AttributeFormType } from '../../../utils/create-event-attributes';

const getFormFieldName = (
    name: keyof MergedFormSchema[Step.EVENT_DETAILS] | keyof AttributeFormType,
    attributeSubKey?: keyof NonNullable<AttributeFormType['Engagement Type']>
) =>
    `${Step.EVENT_DETAILS}.${name}${
        attributeSubKey ? `.${attributeSubKey}` : ''
    }`;

export default getFormFieldName;
