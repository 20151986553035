import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    CheckboxField,
    FormikField,
    TextAreaField,
} from '@amzn/event-engine-js-utils';
import { FormikContextType, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';

import { i18nStrings } from '../../../../../constants';
import { MergedFormSchema } from '../../components/CreateEventForm.validation';
import {
    FieldName,
    ParticipantAllowlistFieldsProps,
    SchemaType,
} from './types';

const ParticipantAllowlistFields = ({
    requireEmailPatterns,
    stepId,
}: ParticipantAllowlistFieldsProps) => {
    const formik: FormikContextType<
        SchemaType | MergedFormSchema
    > = useFormikContext();
    const values = useMemo(
        () =>
            (stepId
                ? (formik.values as MergedFormSchema)[stepId]
                : formik.values) as SchemaType,
        [stepId, formik.values]
    );
    const getFormFieldName = useCallback(
        (name: FieldName) =>
            [stepId, name].filter((n) => !!n).join('.') as keyof SchemaType,
        [stepId]
    );

    return (
        <SpaceBetween direction="vertical" size="l">
            <FormikField<SchemaType>
                name={getFormFieldName(FieldName.EMAIL_PATTERNS)}
                dataTestId={FieldName.EMAIL_PATTERNS}
                formFieldProps={{
                    label:
                        i18nStrings.events.create.wizard.steps
                            .participantAllowlist.fields.emailPatterns.label,
                    stretch: true,
                    description:
                        i18nStrings.events.create.wizard.steps
                            .participantAllowlist.fields.emailPatterns
                            .description,
                    children: (
                        <TextAreaField<SchemaType>
                            name={getFormFieldName(FieldName.EMAIL_PATTERNS)}
                            textFieldProps={{
                                rows: 4,
                                placeholder:
                                    i18nStrings.events.create.wizard.steps
                                        .participantAllowlist.fields
                                        .emailPatterns.label,
                                disabled: values?.[FieldName.ALLOW_ALL],
                            }}
                        />
                    ),
                }}
            />
            <FormikField<SchemaType>
                name={getFormFieldName(FieldName.ALLOW_ALL)}
                dataTestId={FieldName.ALLOW_ALL}
                formFieldProps={{
                    stretch: true,
                    children: (
                        <CheckboxField<SchemaType>
                            name={getFormFieldName(FieldName.ALLOW_ALL)}
                            checkboxProps={{
                                disabled: requireEmailPatterns,
                                children:
                                    i18nStrings.events.create.wizard.steps
                                        .participantAllowlist.fields.allowAll
                                        .label,
                                description:
                                    i18nStrings.events.create.wizard.steps
                                        .participantAllowlist.fields.allowAll
                                        .description,
                            }}
                        />
                    ),
                }}
            />
        </SpaceBetween>
    );
};

export default ParticipantAllowlistFields;
