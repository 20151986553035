import { AppSettingsClient, useLogger } from '@amzn/event-engine-js-utils';
import { Auth } from 'aws-amplify';
import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContextValue, IdentityProvider } from './types';

export const AuthContext = createContext<AuthContextValue>(
    {} as AuthContextValue
);

const AuthProvider: FC = ({ children }) => {
    const history = useHistory();
    const [isAuthSetup, setIsAuthSetup] = useState(false);
    const [authProviderLogger] = useLogger('AuthProviderLogger');

    const signIn = useCallback<AuthContextValue['signIn']>(
        async (options = {}) => {
            let redirect = options.redirect;

            if (redirect === undefined) {
                const { pathname, search = '', hash = '' } = history.location;
                redirect = `${pathname}${search}${hash}`;
            }

            await Auth.federatedSignIn({
                customProvider: IdentityProvider.AMAZON_FEDERATE,
                customState: redirect,
            });

            return;
        },
        []
    );

    const signOut = useCallback(async () => await Auth.signOut(), []);

    /**
     * Navigates to sign-out URL manually.
     * Auth.signOut does not work after the current user session is
     * invalidated (e.g. user is manually disabled in Cognito)
     */
    const signOutManual = useCallback(async () => {
        const settings = await AppSettingsClient.getInstance().get();

        if (!settings) {
            return;
        }

        const { aws_user_pools_web_client_id }: any = settings.amplify;
        const { domain, redirectSignOut }: any = settings.amplify.oauth;

        window.location.href = `https://${domain}/logout?logout_uri=${redirectSignOut}&client_id=${aws_user_pools_web_client_id}&response_type=code`;
    }, []);

    const getCurrentSession = useCallback(async () => {
        try {
            return await Auth.currentSession();
        } catch (e) {
            authProviderLogger.error(e);
            return;
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                await AppSettingsClient.getInstance().configureAuth();

                setIsAuthSetup(true);
            } catch (e) {
                authProviderLogger.error(e);
            }
        })();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                signIn,
                signOut,
                signOutManual,
                getCurrentSession,
            }}>
            {isAuthSetup ? children : null}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
