import { UserContext, UserFeatures } from 'contexts/UserProvider';
import { useLogger } from 'hooks';
import { useContext } from 'react';

export type FeatureFlagName = keyof UserFeatures;

/**
 * Checks if specified feature is enabled.
 * An undefined value is returned if feature flag
 * data has not yet resolved.
 *
 * @param {FeatureFlagName} name
 * @returns {boolean|undefined}
 */
const useFeatureFlag = (name: FeatureFlagName) => {
    const { data: userData, isError } = useContext(UserContext);
    const [logger] = useLogger('FeatureFlag');

    if (isError) {
        logger.warn(
            'UserContext failed, unable to determine if user is authorized to view content',
            isError
        );

        return false;
    }

    if (userData === undefined) {
        return;
    }

    // We assume the feature is live if the
    // flag is not included in the response
    if (!Object.prototype.hasOwnProperty.call(userData.features, name)) {
        return true;
    }

    return userData.features[name];
};

export default useFeatureFlag;
