import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { countText, ValueWithLabel } from '@amzn/event-engine-js-utils';
import { FormikContextType, useFormikContext } from 'formik';
import React from 'react';
import { getDisplayValue } from 'utils';
import {
    getUTCOffsetLabel,
    utcToZonedTime,
    zonedTimeToUtc,
} from 'utils/date-fns';
import { DATE_FORMATS } from 'utils/date-fns/constants';

import { i18nStrings } from '../../../../../constants';
import {
    MergedFormSchema,
    Step,
} from '../../components/CreateEventForm.validation';
import {
    ACCOUNT_SOURCE_OPTIONS,
    EVENT_TYPE_OPTIONS,
    MODALITY_OPTIONS,
    TIMEZONES,
} from '../../utils/constants';
import { ParticipantAllowlistFieldName } from '../ParticipantAllowlist';
import { EMAIL_PATTERN_FIELD_DELIMITER } from '../ParticipantAllowlist/constants';
import { ReviewFacilitatorsTable } from './components';
import { ReviewSections, TestID } from './types';

export interface ReviewAndCreateProps {
    onEdit: (id: Step) => void;
    editableSteps?: Step[];
}

const COLUMN_LAYOUT_COLUMNS = 2;

const ReviewAndCreate = ({ onEdit, editableSteps }: ReviewAndCreateProps) => {
    const formik: FormikContextType<MergedFormSchema> = useFormikContext();
    const values = {
        selectedWorkshop: formik.values[Step.SELECT_WORKSHOP],
        eventTypeSource: formik.values[Step.EVENT_TYPE],
        eventDetails: formik.values[Step.EVENT_DETAILS],
        participantAllowlist: formik.values[Step.PARTICIPANT_ALLOWLIST],
    };
    const isTestEvent = values.eventTypeSource.eventType === EVENT_TYPE.TEST;
    const hasAccounts =
        values.eventTypeSource.accountSource ===
        EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO;
    let engagementType = values.eventDetails['Engagement Type']?.engagementType;
    if (engagementType === 'Other') {
        const otherEngagementType =
            values.eventDetails['Engagement Type']?.engagementTypeOther;
        engagementType = otherEngagementType && `Other: ${otherEngagementType}`;
    }
    const timezone = TIMEZONES.find(
        (tz) => tz.value === values.eventDetails.timezone
    );
    const eventStartTimeUTC = zonedTimeToUtc(
        values.eventDetails.eventDate,
        values.eventDetails.eventTime,
        values.eventDetails.period,
        { timezone: timezone?.value }
    );
    const {
        date: displayEventStartDate,
        time: displayEventStartTime,
    } = utcToZonedTime(eventStartTimeUTC as Date, {
        format: DATE_FORMATS.TWENTY_FOUR_HOUR_DISPLAY,
        timezone: timezone?.value,
    });
    const timezoneLabel = `(${getUTCOffsetLabel(timezone?.value!, {
        date: values.eventDetails.eventDate,
        time: values.eventDetails.eventTime,
        period: values.eventDetails.period,
    })}) ${timezone?.label}`;
    const sections: ReviewSections[] = [
        {
            id: Step.SELECT_WORKSHOP,
            title: i18nStrings.events.create.wizard.steps.selectWorkshop.title,
            testId: TestID.SECTION_WORKSHOP,
            content: (
                <Container
                    data-testid={TestID.CONTAINER_WORKSHOP}
                    header={<Header variant="h2">Workshop information</Header>}>
                    <ColumnLayout
                        columns={COLUMN_LAYOUT_COLUMNS}
                        variant="text-grid">
                        <ValueWithLabel label="Title">
                            {getDisplayValue(values.selectedWorkshop?.title)}
                        </ValueWithLabel>
                        {isTestEvent && (
                            <ValueWithLabel label="Build ID">
                                {getDisplayValue(
                                    values.selectedWorkshop?.contentBuildId
                                )}
                            </ValueWithLabel>
                        )}
                    </ColumnLayout>
                </Container>
            ),
        },
        {
            id: Step.EVENT_TYPE,
            title: i18nStrings.events.create.wizard.steps.eventType.title,
            testId: TestID.SECTION_EVENT_TYPE,
            content: (
                <Container
                    data-testid={TestID.CONTAINER_EVENT_TYPE}
                    header={
                        <Header variant="h2">
                            Event type and account source
                        </Header>
                    }>
                    <ColumnLayout
                        columns={COLUMN_LAYOUT_COLUMNS}
                        variant="text-grid">
                        <ValueWithLabel label="Type">
                            {getDisplayValue(
                                EVENT_TYPE_OPTIONS.find(
                                    ({ value }) =>
                                        value ===
                                        values.eventTypeSource.eventType
                                )?.label
                            )}
                        </ValueWithLabel>
                        <ValueWithLabel label="Account source">
                            {getDisplayValue(
                                ACCOUNT_SOURCE_OPTIONS.find(
                                    ({ value }) =>
                                        value ===
                                        values.eventTypeSource.accountSource
                                )?.label
                            )}
                        </ValueWithLabel>
                    </ColumnLayout>
                </Container>
            ),
        },
        {
            id: Step.EVENT_DETAILS,
            title: i18nStrings.events.create.wizard.steps.eventDetails.title,
            errorText:
                formik.errors[Step.EVENT_DETAILS] &&
                i18nStrings.events.create.errors.step,
            testId: TestID.SECTION_EVENT_DETAILS,
            content: (
                <SpaceBetween direction="vertical" size="l">
                    <Container
                        data-testid={TestID.CONTAINER_EVENT_DETAILS}
                        header={<Header variant="h2">Details</Header>}
                        footer={
                            <ExpandableSection
                                header="Description"
                                variant="footer"
                                defaultExpanded>
                                {getDisplayValue(
                                    values.eventDetails.description
                                )}
                            </ExpandableSection>
                        }>
                        <ColumnLayout
                            columns={COLUMN_LAYOUT_COLUMNS}
                            variant="text-grid">
                            <SpaceBetween size="l">
                                <ValueWithLabel label="Title">
                                    {getDisplayValue(values.eventDetails.title)}
                                </ValueWithLabel>
                                {!isTestEvent && (
                                    <ValueWithLabel label="Geo">
                                        {getDisplayValue(
                                            (values.eventDetails
                                                .Geo as SelectProps.Option[])?.map(
                                                ({ label }) => label
                                            )
                                        )}
                                    </ValueWithLabel>
                                )}
                                {!isTestEvent && (
                                    <ValueWithLabel label="Opportunity/Campaign ID">
                                        {getDisplayValue(
                                            values.eventDetails[
                                                'Salesforce Opportunity ID'
                                            ]
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                            {!isTestEvent && (
                                <SpaceBetween size="l">
                                    <ValueWithLabel label="Modality">
                                        {getDisplayValue(
                                            MODALITY_OPTIONS.find(
                                                ({ value }) =>
                                                    value ===
                                                    values.eventDetails.modality
                                            )?.label
                                        )}
                                    </ValueWithLabel>
                                    <ValueWithLabel label="Engagement type">
                                        {getDisplayValue(engagementType)}
                                    </ValueWithLabel>
                                </SpaceBetween>
                            )}
                        </ColumnLayout>
                    </Container>
                    <Container
                        data-testid={TestID.CONTAINER_EVENT_CONFIG}
                        header={<Header variant="h2">Configuration</Header>}>
                        <ColumnLayout
                            columns={COLUMN_LAYOUT_COLUMNS}
                            variant="text-grid">
                            <SpaceBetween size="l">
                                <ValueWithLabel label="Start date">
                                    {displayEventStartDate}
                                </ValueWithLabel>
                                <ValueWithLabel label="Participant forecast">
                                    {getDisplayValue(
                                        values.eventDetails.forecastedAttendees
                                    )}
                                </ValueWithLabel>
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.create.fields
                                            .teamSize.label
                                    }>
                                    {getDisplayValue(
                                        values.eventDetails.teamSize
                                    )}
                                </ValueWithLabel>
                                {hasAccounts && (
                                    <ValueWithLabel label="Deployment region">
                                        {getDisplayValue(
                                            values.eventDetails
                                                .deploymentRegions
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <ValueWithLabel label="Start time">
                                    {displayEventStartTime} {timezoneLabel}
                                </ValueWithLabel>
                                {hasAccounts && (
                                    <ValueWithLabel
                                        label={
                                            i18nStrings.events.create.fields
                                                .initialQuotaContribution.label
                                        }>
                                        {getDisplayValue(
                                            values.eventDetails
                                                .initialQuotaContribution
                                        )}
                                    </ValueWithLabel>
                                )}
                                <ValueWithLabel label="Duration">
                                    {countText(values.eventDetails.duration, {
                                        singular: 'hour',
                                        plural: 'hours',
                                    })}
                                </ValueWithLabel>
                                {hasAccounts && (
                                    <ValueWithLabel label="Accessible regions">
                                        {getDisplayValue(
                                            values.eventDetails.accessibleRegions?.map(
                                                ({ label }) => label
                                            )
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                        </ColumnLayout>
                    </Container>
                    <ReviewFacilitatorsTable
                        items={values.eventDetails.facilitators}
                    />
                </SpaceBetween>
            ),
        },
        {
            id: Step.PARTICIPANT_ALLOWLIST,
            testId: TestID.SECTION_PARTICIPANT_ALLOWLIST,
            title:
                i18nStrings.events.create.wizard.steps.participantAllowlist
                    .title,
            content: (
                <Container
                    data-testid={TestID.CONTAINER_PARTICIPANT_ALLOWLIST}
                    header={
                        <Header variant="h2">
                            {
                                i18nStrings.events.create.wizard.steps
                                    .participantAllowlist.title
                            }
                        </Header>
                    }>
                    <ColumnLayout
                        columns={COLUMN_LAYOUT_COLUMNS}
                        variant="text-grid">
                        <ValueWithLabel
                            label={
                                i18nStrings.events.participantAllowlist.values
                                    .type
                            }>
                            {values.participantAllowlist[
                                ParticipantAllowlistFieldName.ALLOW_ALL
                            ]
                                ? i18nStrings.events.participantAllowlist.types
                                      .allowAll
                                : i18nStrings.events.participantAllowlist.types
                                      .emailList}
                        </ValueWithLabel>
                        {!values.participantAllowlist[
                            ParticipantAllowlistFieldName.ALLOW_ALL
                        ] ? (
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.participantAllowlist
                                        .values.emailPatterns
                                }
                                breakSpaces>
                                {getDisplayValue(
                                    values.participantAllowlist[
                                        ParticipantAllowlistFieldName
                                            .EMAIL_PATTERNS
                                    ]
                                        ?.split(EMAIL_PATTERN_FIELD_DELIMITER)
                                        .filter((p) => !!p),
                                    {
                                        listDelimiter: '\n',
                                        i18nStrings: {
                                            overflowAction:
                                                i18nStrings.events
                                                    .participantAllowlist
                                                    .overflow,
                                        },
                                        listOverflow: {
                                            maxItems: 3,
                                            onShowMore: () =>
                                                onEdit(
                                                    Step.PARTICIPANT_ALLOWLIST
                                                ),
                                        },
                                    }
                                )}
                            </ValueWithLabel>
                        ) : null}
                    </ColumnLayout>
                </Container>
            ),
        },
    ];
    const isStepEditable = (id: Step) =>
        !editableSteps || editableSteps.includes(id);

    return (
        <SpaceBetween data-testid={TestID.PAGE} direction="vertical" size="xxl">
            {sections.map((section) => (
                <SpaceBetween
                    size="xs"
                    key={section.testId}
                    data-testid={section.testId}>
                    <Header
                        actions={
                            isStepEditable(section.id) && (
                                <Button
                                    onClick={() => onEdit(section.id)}
                                    variant="normal"
                                    iconName="edit">
                                    {i18nStrings.edit}
                                </Button>
                            )
                        }
                        description={
                            section.errorText && (
                                <StatusIndicator type="error">
                                    {section.errorText}
                                </StatusIndicator>
                            )
                        }
                        variant="h3">
                        {section.title}
                    </Header>
                    {section.content}
                </SpaceBetween>
            ))}
        </SpaceBetween>
    );
};

export default ReviewAndCreate;
