import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { STATUS_MAP } from '../../EventParticipantState/constants';
import { PARTICIPANT_DENIED_REASON_MAP } from '../constants';
import { ParticipantDataTableItem, ProcessDataOptions } from '../types';

const processData = (
    data: EventEngineEventsService.ParticipantList = [],
    { selectedFilters }: ProcessDataOptions = {}
) => {
    const res: ParticipantDataTableItem[] = [];

    data.filter((item) => {
        if (selectedFilters?.participantState.value) {
            return item.state === selectedFilters.participantState.value;
        }

        return true;
    }).forEach((participants) => {
        const processedParticipant: Partial<ParticipantDataTableItem> = {
            ...participants,
        };

        if (participants.state) {
            processedParticipant.displayState =
                STATUS_MAP[participants.state].label || participants.state;
        }

        if (participants.deniedReason) {
            processedParticipant.displayDenyReason =
                PARTICIPANT_DENIED_REASON_MAP[participants.deniedReason] ||
                participants.deniedReason;
        }

        res.push(processedParticipant as ParticipantDataTableItem);
    });

    return res;
};

export default processData;
