import useFeatureFlag, { FeatureFlagName } from 'hooks/use-feature-flag';
import React, { FC, ReactNode } from 'react';

export interface FeatureFlagProps {
    name: FeatureFlagName;
    fallback?: ReactNode;
    loading?: ReactNode;
}

/**
 * Wrapper component that conditionally renders children
 * based on provided UserData
 * @param {FeatureFlagProps} props
 * @returns
 */
const FeatureFlag: FC<FeatureFlagProps> = ({
    name,
    children,
    fallback = null,
    loading = null,
}) => {
    const isEnabled = useFeatureFlag(name);

    if (isEnabled === undefined) {
        return <>{loading}</>;
    }

    if (!isEnabled) {
        return <>{fallback}</>;
    }

    return <>{children}</>;
};

export default FeatureFlag;
