import Popover from '@amzn/awsui-components-react/polaris/popover';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { BaseMarkdown } from '@amzn/event-engine-js-utils';
import React, { Fragment } from 'react';

import { BooleanStatusProps } from './types';
import getStatusConfig from './utils/get-status-config';

const BooleanStatus = ({
    value,
    typeOverride,
    popoverContent,
}: BooleanStatusProps) => {
    let { type, label } = getStatusConfig(value);
    type = typeOverride ? typeOverride : type;

    const status = (
        <StatusIndicator type={type} data-type={type}>
            {label}
        </StatusIndicator>
    );

    return popoverContent ? (
        <Popover
            content={
                typeof popoverContent === 'string' ? (
                    <TextContent>
                        <BaseMarkdown
                            components={{ p: Fragment }}
                            linkTarget="_blank">
                            {popoverContent}
                        </BaseMarkdown>
                    </TextContent>
                ) : (
                    popoverContent
                )
            }
            dismissButton={false}>
            {status}
        </Popover>
    ) : (
        status
    );
};

export default BooleanStatus;
