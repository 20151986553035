import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    useInterval,
    useLazyRequest,
    usePaginator,
    useVisibilityChange,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import React, {
    createContext,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { EEEvents } from 'services';
import { config } from 'utils';

export type ObserverHandler = (
    data?: EventEngineEventsService.TeamList
) => void;

type HandlerID = string;

export interface TeamsPollerProviderProps {
    eventId: string;
    /**
     * Poll interval in milliseconds
     */
    interval?: number;
    pageSize?: number;
}

export interface TeamsPollerProviderValue {
    isLoading: boolean;
    refresh: (id: HandlerID, preserveCache?: boolean) => Promise<void>;
    subscribe: (id: HandlerID, handler: ObserverHandler) => void;
    unsubscribe: (id: HandlerID) => void;
    error?: AWSError;
}

export const TeamsPollerContext = createContext<TeamsPollerProviderValue>(
    {} as TeamsPollerProviderValue
);

const TeamsPollerProvider: FC<TeamsPollerProviderProps> = ({
    eventId,
    children,
    interval = config.DEFAULT_POLL_MS,
    pageSize = config.DEFAULT_API_PAGE_SIZE,
}) => {
    const handlers = useRef<Record<HandlerID, ObserverHandler>>({});
    const fetchingData = useRef(false);
    const handlerCachedData = useRef<
        Record<HandlerID, EventEngineEventsService.TeamList | undefined>
    >({});
    const handlerCurrentData = useRef<
        Record<HandlerID, EventEngineEventsService.TeamList>
    >({});
    const [getTeams, { data: teamsData, isError: error }] = useLazyRequest<
        typeof EEEvents.getTeams,
        AWSError
    >(EEEvents.getTeams, {});
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const subscribe = useCallback((id: HandlerID, handler: ObserverHandler) => {
        handlers.current[id] = handler;

        const cachedData = handlerCachedData.current[id];
        if (cachedData) {
            handler(cachedData);
        } else {
            getData();
        }
    }, []);
    const unsubscribe = useCallback((id: HandlerID) => {
        delete handlers.current[id];
        handlerCurrentData.current[id] = [];
    }, []);
    const paginator = usePaginator(getTeams, pageSize, 'teams');
    const refresh = useCallback(
        async (id: HandlerID, preserveCache?: boolean) => {
            // Clear handler cache on refresh to allow streaming
            // of data as pages are received.
            if (!preserveCache) {
                handlerCachedData.current[id] = undefined;
            }

            await getData();
        },
        []
    );
    const getData = async () => {
        if (fetchingData.current) {
            return;
        }

        fetchingData.current = true;
        setIsLoading(fetchingData.current);

        // Reset data accumulators
        Object.keys(handlers.current).forEach((id) => {
            handlerCurrentData.current[id] = [];
        });

        // Emit empty start value for handler to reset views if there is a cache
        // record for the handler. Otherwise, we will only emit when all pages of
        // data have been collected. This creates a more pleasant data refresh experience
        // where pages of data are streamed in on initial load, or if there was no data
        // loaded previously. If there is data present in the table already, the
        // update should occur with minimal shifting to avoid disrupting user
        // interaction with the view (e.g. selecting a table row).
        Object.keys(handlers.current).forEach((id) => {
            const handler = handlers.current[id];
            !handlerCachedData.current[id] && handler && handler(undefined);
        });

        await paginator.getPaginatedData(0, '*', {
            fresh: true,
            clientArgs: { eventId },
        });
    };
    const emitData = (
        id: HandlerID,
        { teams, nextToken }: EventEngineEventsService.ListEventTeamsResponse
    ) => {
        const data = [...(handlerCurrentData.current[id] || []), ...teams];

        handlerCurrentData.current[id] = data;

        const handler = handlers.current[id];
        const lastDataLength = handlerCachedData.current[id]?.length;

        if (!lastDataLength || (lastDataLength && !nextToken)) {
            handler(data);
        }

        if (!nextToken) {
            handlerCachedData.current[id] = data;
        }
    };

    useVisibilityChange({
        onVisible: () => setIsVisible(true),
        onHidden: () => setIsVisible(false),
    });

    useInterval(() => {
        isVisible && Object.keys(handlers.current).length && getData();
    }, interval);

    useEffect(() => {
        if (!teamsData) {
            return;
        }

        Object.keys(handlers.current).forEach((id) => {
            emitData(id, teamsData);
        });

        if (!teamsData.nextToken) {
            fetchingData.current = false;
            setIsLoading(fetchingData.current);
        }
    }, [teamsData]);

    useEffect(() => {
        if (!error) {
            return;
        }

        fetchingData.current = false;
        setIsLoading(fetchingData.current);
    }, [error]);

    useEffect(() => {
        return () => {
            handlers.current = {};
        };
    }, []);

    return (
        <TeamsPollerContext.Provider
            value={{
                isLoading,
                refresh,
                subscribe,
                unsubscribe,
                error,
            }}>
            {children}
        </TeamsPollerContext.Provider>
    );
};

export default TeamsPollerProvider;
