import { usePaginator } from '@amzn/event-engine-js-utils';
import {
    ContentData,
    ListContentsRequest,
    ListContentsResponse,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { AWSError } from 'aws-sdk/lib/error';
import i18nStrings from 'constants/i18n-strings';
import { useLazyRequest } from 'hooks';
import { EVENT_READINESS_FILTER_OPTIONS } from 'pages/Workshops/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EEContentCatalog } from 'services';

import { WorkshopCatalogPublic as WorkshopCatalogTools } from '../../components/WorkshopTools';
import { ColumnId, DEFAULT_PAGINATION_LIMIT } from './config';
import WorkshopCatalogTableProvider from './contexts/WorkshopCatalogTableProvider';
import {
    CollectionFilter,
    DetailLinkBehavior,
    TableAction,
    WorkshopCatalogTableProps,
} from './types';
import WorkshopCatalogTable from './WorkshopCatalogTable';

type Variants = 'catalog' | 'select';

interface WorkshopCatalogTablePublicContainerProps {
    id?: string;
    variant?: Variants;
    selectedItems?: WorkshopCatalogTableProps['selectedItems'];
    onSelectionChange?: (contents: ContentData[]) => void;
    filter?: (item: ContentData) => boolean;
    basePath?: string;
    description?: string;
}

const variantConfiguration: {
    [key in Variants]: Partial<WorkshopCatalogTableProps>;
} = {
    catalog: {
        actions: [TableAction.CREATE_WORKSHOP, TableAction.CREATE_EVENT],
        collectionSelectFilters: [
            {
                id: CollectionFilter.EVENT_READINESS,
                label:
                    i18nStrings.workshops.workshopCatalog.table
                        .eventReadinessStateCollectionSelect.label,
                filter: ({ eventReadiness }, value) =>
                    eventReadiness.summary === value,
                options: EVENT_READINESS_FILTER_OPTIONS,
            },
        ],
        detailLinkBehavior: DetailLinkBehavior.NAVIGATION,
    },
    select: {
        actions: [],
        detailLinkBehavior: DetailLinkBehavior.MODAL,
    },
};
const columnDefinitions = [
    ColumnId.TITLE,
    ColumnId.STATE,
    ColumnId.PUBLISH_TYPE,
    ColumnId.TOPICS,
    ColumnId.LEVEL,
    ColumnId.DURATION,
    ColumnId.AWS_SERVICES,
    ColumnId.PRIMARY_OWNER,
    ColumnId.INDUSTRY_VERTICAL,
    ColumnId.ENGAGEMENT_MODEL,
    ColumnId.EVENT_READINESS_STATE,
    ColumnId.CONTENT_ID,
    ColumnId.LAST_MODIFIED,
    ColumnId.LAST_PUBLISHED,
];
const visibleContent = [
    ColumnId.TITLE,
    ColumnId.PUBLISH_TYPE,
    ColumnId.TOPICS,
    ColumnId.LEVEL,
    ColumnId.DURATION,
    ColumnId.EVENT_READINESS_STATE,
    ColumnId.LAST_PUBLISHED,
];

const WorkshopCatalogTablePublicContainer = ({
    variant = 'catalog',
    selectedItems,
    onSelectionChange,
    filter,
    basePath,
    description,
    id = 'workshop_catalog_table',
}: WorkshopCatalogTablePublicContainerProps) => {
    const loadingData = useRef<boolean>();
    const [getContents, getContentsResponse] = useLazyRequest<
        typeof EEContentCatalog.getContents,
        AWSError
    >(EEContentCatalog.getContents, { context: EEContentCatalog });
    const [data, setData] = useState<ContentData[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AWSError>();
    const paginator = usePaginator<ListContentsRequest, ListContentsResponse>(
        getContents,
        DEFAULT_PAGINATION_LIMIT,
        'contents'
    );
    const setLoadingState = (isLoading: boolean) => {
        setLoading(isLoading);
        loadingData.current = isLoading;
    };
    const getData = useCallback(async () => {
        if (loadingData.current) {
            return;
        }

        setError(undefined);
        setData(undefined);
        setLoadingState(true);

        await paginator.getPaginatedData<ListContentsRequest>(0, '*', {
            fresh: true,
            clientArgs: {
                publicContent: true,
            },
        });
    }, []);

    useEffect(() => {
        if (!getContentsResponse.data?.contents) {
            return;
        }

        setData([...(data || []), ...getContentsResponse.data.contents]);
        !getContentsResponse.data.nextToken && setLoadingState(false);
    }, [getContentsResponse.data]);

    useEffect(() => {
        if (!getContentsResponse.isError) {
            return;
        }

        setError(getContentsResponse.isError);
        setData([]);
        setLoadingState(false);
    }, [getContentsResponse.isError]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <WorkshopCatalogTableProvider>
            <WorkshopCatalogTable
                {...variantConfiguration[variant]}
                id={id}
                title={i18nStrings.workshops.workshopCatalog.title}
                columnDefinitions={columnDefinitions}
                visibleContent={visibleContent}
                selectedItems={selectedItems}
                data={filter && data ? data.filter(filter) : data}
                toolsContent={<WorkshopCatalogTools />}
                refetch={getData}
                loading={loading}
                error={error}
                description={description}
                onSelectionChange={onSelectionChange}
                basePath={basePath}
            />
        </WorkshopCatalogTableProvider>
    );
};

export default WorkshopCatalogTablePublicContainer;
