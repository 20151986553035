/**
 * Left pads a specified string to a specified length with a specified character
 * @param {string|number} str
 * @param {number} length
 * @param {string} character
 * @returns {string}
 */
const leftPad = (str: string | number, length: number, character: string) => {
    str = str.toString();
    while (str.length < length) {
        str = character + str;
    }
    return str;
};

export default leftPad;
