import { i18nStrings } from '../../../../../../../../../constants';
import { StatusConfig, TeamsDeploymentState } from './types';

export const STATUS_MAP: Record<TeamsDeploymentState, StatusConfig> = {
    [TeamsDeploymentState.Failed]: {
        type: 'error',
        label: i18nStrings.events.teams.overviewContainer.states.failed,
    },
    [TeamsDeploymentState.Halted]: {
        type: 'warning',
        label: i18nStrings.events.teams.overviewContainer.states.halted,
    },
    [TeamsDeploymentState.InProgress]: {
        type: 'in-progress',
        label: i18nStrings.events.teams.overviewContainer.states.inProgress,
    },
    [TeamsDeploymentState.NotStarted]: {
        type: 'stopped',
        label: i18nStrings.events.teams.overviewContainer.states.notStarted,
    },
    [TeamsDeploymentState.Success]: {
        type: 'success',
        label: i18nStrings.events.teams.overviewContainer.states.success,
    },
    [TeamsDeploymentState.SuccessPartial]: {
        type: 'warning',
        label: i18nStrings.events.teams.overviewContainer.states.successPartial,
    },
};
