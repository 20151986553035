import SideNavigation, {
    SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import {
    Constants,
    getActiveHref,
    useIsMounted,
} from '@amzn/event-engine-js-utils';
import { isAuthenticatedState } from 'atoms';
import H from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AppSettings from 'services/app-settings';
import { DOCUMENTATION_PATHS } from 'utils/config';

import { routes, routeTokens } from '../AppRoutes/routes.constants';
import { i18nStrings } from './../../constants';

// all links with the placeholder href will be filled in at a later time
const PLACEHOLDER_HREF = 'PLACEHOLDER';

const USER_GUIDE = 'Getting started guide';
const AUTHORING_A_WORKSHOP = 'Documentation';

const initNavItems: SideNavigationProps.Item[] = [
    {
        type: 'section',
        text: 'Workshops',
        items: [
            {
                type: 'link',
                text: 'My workshops',
                href: routes.workshops,
            },
            {
                type: 'link',
                text: 'Workshop catalog',
                href: `${routes.workshops}/${routeTokens.publicWorkshops}`,
            },
        ],
    },
    {
        type: 'section',
        text: i18nStrings.events.sectionHeader,
        items: [
            {
                type: 'link',
                text: i18nStrings.events.titles.active,
                href: `${routes.events}/${routeTokens.events.active}`,
            },
            {
                type: 'link',
                text: i18nStrings.events.titles.upcoming,
                href: `${routes.events}/${routeTokens.events.upcoming}`,
            },
            {
                type: 'link',
                text: i18nStrings.events.titles.past,
                href: `${routes.events}/${routeTokens.events.past}`,
            },
        ],
    },
    { type: 'divider' },
    {
        type: 'link',
        text: USER_GUIDE,
        href: PLACEHOLDER_HREF,
        external: true,
    },
    {
        type: 'link',
        text: AUTHORING_A_WORKSHOP,
        href: PLACEHOLDER_HREF,
        external: true,
    },
    {
        type: 'link',
        text: i18nStrings.links.wiki,
        href: Constants.ExternalLinks.wiki,
        external: true,
    },
    {
        type: 'link',
        text: i18nStrings.guardrails.title,
        href: routes.guardrails,
    },
];

export const AppNav = () => {
    const location = useLocation();
    const { isMounted } = useIsMounted();
    const history = useHistory();
    const [navItems, setNavItems] = useState(initNavItems);
    const isAuthenticated = useRecoilValue(isAuthenticatedState);

    const onFollow = useCallback(
        (history: H.History) => (
            e: CustomEvent<SideNavigationProps.FollowDetail>
        ) => {
            if (!e.detail.external) {
                e.preventDefault();
                history.push(e.detail.href);
            }
        },
        []
    );

    useEffect(() => {
        /**
         * This useEffect is intentionally blank.
         * It is here so that SideNavigation can re-render
         * and set a new activeHref.
         */
    }, [location]);

    useEffect(() => {
        isAuthenticated &&
            (async () => {
                const settings = await AppSettings();

                isMounted &&
                    settings.documentation &&
                    setNavItems(
                        navItems.map((navItem) => {
                            if (navItem.type === 'link') {
                                switch (navItem.text) {
                                    case USER_GUIDE:
                                        navItem.href = settings.documentation!;
                                        break;
                                    case AUTHORING_A_WORKSHOP:
                                        navItem.href = `${settings.documentation}/${DOCUMENTATION_PATHS.DETAILED_DOCUMENTATION}`;
                                        break;
                                }
                            }
                            return navItem;
                        })
                    );
            })();
    }, [isAuthenticated]);

    // only show items that have actual hrefs and skip placeholders until they have been
    // asynchronously resolved
    const filteredItems = navItems.filter((nav) =>
        nav.type === 'link' ? nav.href !== PLACEHOLDER_HREF : true
    );

    return (
        <SideNavigation
            header={{ href: '/', text: i18nStrings.appName }}
            items={filteredItems}
            onFollow={onFollow(history)}
            activeHref={getActiveHref(navItems)}
        />
    );
};
