import { TEAM_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export const TEAM_TERMINATE_STATES: EventEngineEventsService.TeamState[] = [
    TEAM_STATE.PROVISION_SUCCESS,
    TEAM_STATE.PROVISION_FAILED,
    TEAM_STATE.DEPLOYMENT_HALTED,
    TEAM_STATE.DEPLOYMENT_SUCCESS,
    TEAM_STATE.DEPLOYMENT_FAILED,
    TEAM_STATE.DEPLOYMENT_IN_PROGRESS,
];

export interface UseTerminateTeamOptions {
    onSuccess?: () => void;
}
