import { PARTICIPANT_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    StoragePartition,
    StoragePartitionKeys,
    useInterval,
    useLazyRequest,
    usePaginator,
    useVisibilityChange,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import EventParticipantsTable from 'pages/Events/EventDetails/components/EventParticipantsTable';
import {
    DEFAULT_PAGINATION_LIMIT,
    defaultPreferences,
    participantStateFilterOptions,
} from 'pages/Events/EventDetails/components/EventParticipantsTable/config';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { EEEvents } from 'services';
import { config } from 'utils';

import { i18nStrings } from '../../../../../../../constants';
import AllowlistOverview from './components/AllowlistOverview';

export interface ParticipantsContainerProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
}

const STORAGE_SCOPE = 'eventParticipantsTable_12162022';

const ParticipantsContainer = ({
    event,
    refetchGetEvent,
}: ParticipantsContainerProps) => {
    const isAllowlistValueMissing = useMemo(
        () => !(event.allowlistAllowAll || event.allowlist?.length),
        [event]
    );
    const [getParticipants, { data, isLoading, isError }] = useLazyRequest<
        typeof EEEvents.getParticipants,
        AWSError
    >(EEEvents.getParticipants, {});
    const isBackgroundRefetch = useRef(false);
    const [
        participantStateFilterOption,
        setParticipantStateFilterOption,
    ] = useState<SelectProps.Option>();
    const [isPollActive, setIsPollActive] = useState(true);
    const [participants, setParticipants] = useState<
        EventEngineEventsService.ParticipantList
    >([]);
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tablePreferences,
            STORAGE_SCOPE
        ) || defaultPreferences
    );
    const paginator = usePaginator(
        getParticipants,
        Math.max(
            preferences.pageSize || DEFAULT_PAGINATION_LIMIT,
            DEFAULT_PAGINATION_LIMIT
        ),
        'participants'
    );
    const getPaginatedData = (fresh = false) =>
        paginator.getPaginatedData(0, '*', {
            fresh,
            clientArgs: { eventId: event.eventId },
        });
    const refetch = useCallback(
        async (isBackground = false) => {
            if (isLoading) {
                return;
            }
            isBackgroundRefetch.current = isBackground;
            !isBackground && setParticipants([]);
            paginator.updateLimit(
                Math.max(
                    preferences.pageSize || DEFAULT_PAGINATION_LIMIT,
                    DEFAULT_PAGINATION_LIMIT
                )
            );
            const allData = await getPaginatedData(true);
            isBackground && setParticipants(allData?.participants || []);
        },
        [isLoading, preferences]
    );
    const setDeniedParticipantStateOption = useCallback(() => {
        setParticipantStateFilterOption({
            ...participantStateFilterOptions.find(
                ({ value }) => value === PARTICIPANT_STATE.DENIED
            ),
        });
    }, []);
    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.tablePreferences,
            preferences,
            STORAGE_SCOPE
        );
    }, [preferences]);

    useEffect(() => {
        !isBackgroundRefetch.current &&
            data?.participants &&
            setParticipants([...participants, ...data.participants]);
    }, [data]);

    useEffect(() => {
        getPaginatedData();
    }, []);

    useVisibilityChange({
        onVisible: () => {
            setIsPollActive(true);
        },
        onHidden: () => {
            setIsPollActive(false);
        },
    });

    useInterval(() => {
        isPollActive && refetch(true);
    }, config.DEFAULT_POLL_MS);

    return (
        <SpaceBetween direction="vertical" size="l">
            {isAllowlistValueMissing ? (
                <Alert type="warning">
                    {
                        i18nStrings.events.participantAllowlist.notifications
                            .missingAllowlistValue.content
                    }
                </Alert>
            ) : null}
            <section>
                <AllowlistOverview
                    eventData={event}
                    participants={participants}
                    onDeniedParticipantStateClick={
                        setDeniedParticipantStateOption
                    }
                    refetchGetEvent={refetchGetEvent}
                />
                <EventParticipantsTable
                    event={event}
                    participants={participants}
                    participantStateFilterOption={participantStateFilterOption}
                    loading={isLoading && !isBackgroundRefetch.current}
                    error={isError}
                    preferences={preferences}
                    onPreferenceChange={setPreferences}
                    refetch={refetch}
                    storageScope={STORAGE_SCOPE}
                />
            </section>
        </SpaceBetween>
    );
};

export default ParticipantsContainer;
