import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import {
    CopyButton,
    getDisplayValue as getDisplayValueUtil,
} from '@amzn/event-engine-js-utils';
import { isDate, isValid } from 'date-fns';
import React, { ReactNode } from 'react';
import getFormattedDate from 'utils/get-formatted-date';

import { i18nStrings } from '../../constants';

interface Options {
    /**
     * Display copy action for value. Only applies for string type values.
     */
    copyable?: boolean;
    /**
     * Render value as link. Only applies for string type values.
     */
    isLink?: boolean;
}
type GetDisplayValueParameters = Parameters<typeof getDisplayValueUtil>;
type GetDisplayValue = (
    value?: GetDisplayValueParameters[0] | Date,
    options?: GetDisplayValueParameters[1] & Options
) => ReturnType<typeof getDisplayValueUtil>;

const getDisplayValue: GetDisplayValue = (value, options) => {
    if (isDate(value)) {
        return isValid(value)
            ? getFormattedDate(value as Date, { useRelativeTime: true })
            : i18nStrings.empty;
    }

    let displayValue: ReactNode | undefined;

    if (options && typeof value === 'string' && value) {
        if (options.isLink) {
            displayValue = (
                <Link href={value} target="_blank">
                    {value}
                </Link>
            );
        }

        if (options.copyable) {
            return (
                <>
                    <Box margin={{ right: 'xxxs' }} display="inline-block">
                        <CopyButton variant="inline-icon" content={value} />
                    </Box>
                    {displayValue || value}
                </>
            );
        }
    }

    if (displayValue) {
        return displayValue;
    }

    return getDisplayValueUtil(value, {
        ...options,
        defaultValue: options?.defaultValue || i18nStrings.empty,
    });
};

export default getDisplayValue;
