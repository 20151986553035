import {
    CONTENT_STATE_TYPES,
    IMPACT_TYPES,
} from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';

import { i18nStrings } from '../../../constants';

export const MAX_CHARACTERS_NOTES_FIELD = 65_535;

/**
 * ID values are used in hash routing to persist current tab on page reload
 */
export enum TabIDs {
    builds = 'builds',
    permissions = 'permissions',
    review = 'review',
    relatedResources = 'relatedResources',
    assetStaticUrls = 'assetStaticUrls',
    assets = 'assets',
}

export const WORKSHOP_STATE_FILTER_OPTIONS: SelectProps.Option[] = [
    {
        label: i18nStrings.workshops.workshopState.anyState,
        value: '',
    },
    {
        label: i18nStrings.workshops.workshopState.active,
        value: CONTENT_STATE_TYPES.CREATE_COMPLETE,
    },
    {
        label: i18nStrings.workshops.workshopState.scheduledForDeletion,
        value: CONTENT_STATE_TYPES.SCHEDULED_FOR_DELETE,
    },
    {
        label: i18nStrings.workshops.workshopState.createFailed,
        value: CONTENT_STATE_TYPES.CREATE_FAILED,
    },
];

export const EVENT_READINESS_FILTER_OPTIONS: SelectProps.Option[] = [
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .eventReadinessStateCollectionSelect.options.any,
        value: '',
    },
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .eventReadinessStateCollectionSelect.options.ready,
        value: IMPACT_TYPES.READY,
    },
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .eventReadinessStateCollectionSelect.options.restricted,
        value: IMPACT_TYPES.RESTRICTED,
    },
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .eventReadinessStateCollectionSelect.options.blocked,
        value: IMPACT_TYPES.BLOCKED,
    },
];

export const ACCOUNT_SOURCE_DEFINED_FILTER_OPTIONS: SelectProps.Option[] = [
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .accountSourceDefinedStateCollectionSelect.options.any,
        value: '',
    },
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .accountSourceDefinedStateCollectionSelect.options.defined,
        value: String(true),
    },
    {
        label:
            i18nStrings.workshops.workshopCatalog.table
                .accountSourceDefinedStateCollectionSelect.options.missing,
        value: String(false),
    },
];

/**
 * ID values are mapped to the following question:
 * "Confirm this workshop is intended to be published publicly (not internally)"
 * If the answer to this question is "no" we enforce using "Internal" as the
 * option on initial publish modal.
 */
export const PUBLISH_QUESTION_IDS: string[] = [
    'fd5ceb26-35f9-4c22-9ada-bca4b35d1ae4',
    '9df70969-cbce-4a6c-8658-724fab548dba',
];
