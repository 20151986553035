import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';

const VALID_ACCOUNT_SOURCES: Set<string> = new Set(
    Object.values(EVENT_ACCOUNT_SOURCE)
);

/**
 * Checks if the provided list of account sources are all valid
 *
 * @param {string[]} accountSources
 * @returns {boolean}
 */
const isAccountSourceDefined = (accountSources?: string[]) =>
    !!accountSources?.length &&
    accountSources.every((accountSource) =>
        VALID_ACCOUNT_SOURCES.has(accountSource)
    );

export default isAccountSourceDefined;
