import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';

export enum TeamsDeploymentState {
    NotStarted = 'deployment_not_started',
    InProgress = 'deployment_in_progress',
    Success = 'deployment_success',
    SuccessPartial = 'deployment_success_partial',
    Failed = 'deployment_failed',
    Halted = 'deployment_halted',
}

export interface StatusConfig {
    type: StatusIndicatorProps.Type;
    label?: string;
}

export interface TeamsStats {
    state: TeamsDeploymentState;
    queued?: number;
    inProgress?: number;
    success?: number;
    failed?: number;
    halted?: number;
    terminated?: number;
    totalDeploymentTimeMS?: number;
    averageDeploymentDuration?: string;
    totalTeamsSuccessfulDeployment?: number;
}
