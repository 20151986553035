import {
    ContentAttribute,
    ContentAttributes,
    ContentAttributeValue,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

export const EventAttributes = {
    'Salesforce Opportunity ID': 'Salesforce Opportunity ID',
    'Engagement Type': 'Engagement Type',
    Geo: 'Geo',
};

export const WorkshopAttributes = {
    'Complexity level': 'Complexity level',
    Topics: 'Topics',
    'AWS services': 'AWS services',
    'Industry vertical': 'Industry vertical',
    'Engagement model': 'Engagement model',
    'Recommended duration': 'Recommended duration',
    'Repository name': 'Repository name',
};

/**
 * If the user doesn’t provide a value or the value is empty (is not an error or doesn’t have a valid value of 0),
 * use "–" (minus sign) for any empty values. For example in table rows or key/value pairs.
 * https://polaris.a2z.com/design/patterns/empty_states/
 */
export const placeholderValue = '–';

const extractStringList = (target: ContentAttribute) => {
    return target.value.stringListValue?.length
        ? target.value.stringListValue.join(', ')
        : placeholderValue;
};

const extractBooleanValue = (target: ContentAttribute) => {
    return target.value.booleanValue ? 'Yes' : 'No';
};

/**
 * Gets display value for specified attribute
 * @param name
 * @param attributes
 */
export const getAttributeValueByName = (
    name: string,
    attributes?: ContentAttributes,
    primaryAttributeKey?: keyof ContentAttributeValue
) => {
    if (!attributes) {
        return placeholderValue;
    }
    const target = attributes.find((attribute) => attribute.name === name);

    if (!target?.value) {
        return placeholderValue;
    }

    if (
        primaryAttributeKey &&
        Object.prototype.hasOwnProperty.call(target.value, primaryAttributeKey)
    ) {
        if (primaryAttributeKey === 'stringListValue') {
            return extractStringList(target);
        } else if (primaryAttributeKey === 'booleanValue') {
            return extractBooleanValue(target);
        }
    } else {
        if (
            Object.prototype.hasOwnProperty.call(
                target.value,
                'stringListValue'
            )
        ) {
            return extractStringList(target);
        } else if (
            Object.prototype.hasOwnProperty.call(target.value, 'booleanValue')
        ) {
            return extractBooleanValue(target);
        }
    }

    return (
        target.value.stringValue ||
        target.value.integerValue ||
        placeholderValue
    );
};
