/* istanbul ignore file */
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    BanEventParticipantRequest,
    GetEventCentralAccountDeploymentCredentialsRequest,
    GetEventCentralAccountDeploymentLoginLinkRequest,
    GetEventTeamCredentialsRequest,
    GetEventTeamLoginLinkRequest,
    GetEventTeamRequest,
    ListEventCentralAccountDeploymentsRequest,
    ListEventOutputsRequest,
    ListEventParticipantsRequest,
    ListEventsRequest,
    ListEventTeamOutputsRequest,
    ListEventTeamsRequest,
    ListQuotaHistorySummaryRequest,
    SendQuotaAllocationRequest,
    TerminateEventTeamRequest,
    UnbanEventParticipantRequest,
    UpdateEventAllowlistRequest,
    UpdateEventQuotaReservationRequest,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import { CredentialRoles } from '@amzn/event-engine-js-utils';
import { USER_ID_SELF } from 'utils/config';

export const defaultEventTemplateId = '900e39d0-9526-4bde-957a-ab0199a79fbd';
import EventEngineClientFactory from 'contexts/EventEngineClientFactory';

const eeEventsClient = EventEngineClientFactory.getClientSingleton(
    'EEEventsClient'
);

// @ts-ignore
const getEEEventsClient = async (role: CredentialRoles = 'Tenant') => {
    return eeEventsClient.getClient(role);
};

export default {
    updateEventParticipantForecast,
    getEvents,
    getEvent,
    getEventTemplate,
    getEventTemplateId,
    createEvent,
    updateEventInformation,
    updateEventSchedule,
    updateEventRegions,
    updateEventPermissions,
    getQuota,
    listQuotas,
    listQuotaHistory,
    startEvent,
    terminateEvent,
    cancelEvent,
    provisionEvent,
    pauseEvent,
    resumeEvent,
    getTeams,
    getTeam,
    getTeamCredentials,
    getTeamLoginLink,
    getParticipants,
    updateEventAllowlist,
    banParticipant,
    unbanParticipant,
    terminateTeam,
    getQuotaHistorySummary,
    updateQuotaReservation,
    sendQuotaAllocation,
    getListEventTeamOutputs,
    getUser,
    getCentralAccounts,
    getEventOutputs,
    getCentralAccountLoginLink,
    getCentralAccountCredentials,
};

///////////////// Implementation
async function getEventTemplateId() {
    return Promise.resolve(defaultEventTemplateId);
}

async function getEvents(params: ListEventsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listEvents(params).promise();
}

async function getEvent(eventId: string) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getEvent({ eventId }).promise();
}

async function getEventTemplate(eventTemplateId = defaultEventTemplateId) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getEventTemplate({ eventTemplateId }).promise();
}

async function createEvent(
    params: EventEngineEventsService.CreateEventRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.createEvent(params).promise();
}

async function updateEventInformation(
    params: EventEngineEventsService.UpdateEventInformationRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventInformation(params).promise();
}

async function updateEventParticipantForecast(
    params: EventEngineEventsService.UpdateEventParticipantForecastRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient
        .updateEventParticipantForecast(params)
        .promise();
}

async function updateEventPermissions(
    params: EventEngineEventsService.UpdateEventPermissionsRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventPermissions(params).promise();
}

async function updateEventSchedule(
    params: EventEngineEventsService.UpdateEventScheduleRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventSchedule(params).promise();
}

async function updateEventRegions(
    params: EventEngineEventsService.UpdateEventRegionsRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventRegions(params).promise();
}

async function listQuotas(params: EventEngineEventsService.ListQuotasRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listQuotas(params).promise();
}

async function getQuota(params: EventEngineEventsService.GetQuotaRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getQuota(params).promise();
}

async function listQuotaHistory(
    params: EventEngineEventsService.ListQuotaHistoryRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listQuotaHistory(params).promise();
}

async function startEvent(params: EventEngineEventsService.StartEventRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.startEvent(params).promise();
}

async function terminateEvent(
    params: EventEngineEventsService.TerminateEventRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.terminateEvent(params).promise();
}

async function cancelEvent(
    params: EventEngineEventsService.CancelEventRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.cancelEvent(params).promise();
}

async function provisionEvent(
    params: EventEngineEventsService.ProvisionEventRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.provisionEvent(params).promise();
}

async function pauseEvent(eventId: string) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.pauseEvent({ eventId }).promise();
}

async function resumeEvent(eventId: string) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.resumeEvent({ eventId }).promise();
}

async function getTeams(params: ListEventTeamsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listEventTeams(params).promise();
}

async function getTeam(params: GetEventTeamRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getEventTeam(params).promise();
}

async function getTeamCredentials(params: GetEventTeamCredentialsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getEventTeamCredentials(params).promise();
}

async function getTeamLoginLink(params: GetEventTeamLoginLinkRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getEventTeamLoginLink(params).promise();
}

async function getParticipants(params: ListEventParticipantsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listEventParticipants(params).promise();
}

async function updateEventAllowlist(params: UpdateEventAllowlistRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventAllowlist(params).promise();
}

async function banParticipant(params: BanEventParticipantRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.banEventParticipant(params).promise();
}

async function unbanParticipant(params: UnbanEventParticipantRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.unbanEventParticipant(params).promise();
}

async function terminateTeam(params: TerminateEventTeamRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.terminateEventTeam(params).promise();
}

async function getQuotaHistorySummary(params: ListQuotaHistorySummaryRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listQuotaHistorySummary(params).promise();
}

async function updateQuotaReservation(
    params: UpdateEventQuotaReservationRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.updateEventQuotaReservation(params).promise();
}

async function sendQuotaAllocation(params: SendQuotaAllocationRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.sendQuotaAllocation(params).promise();
}

async function getListEventTeamOutputs(params: ListEventTeamOutputsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listEventTeamOutputs(params).promise();
}

async function getUser(userId = USER_ID_SELF) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.getUser({ userId }).promise();
}

async function getCentralAccounts(
    params: ListEventCentralAccountDeploymentsRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient
        .listEventCentralAccountDeployments(params)
        .promise();
}

async function getEventOutputs(params: ListEventOutputsRequest) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient.listEventOutputs(params).promise();
}

async function getCentralAccountLoginLink(
    params: GetEventCentralAccountDeploymentLoginLinkRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient
        .getEventCentralAccountDeploymentLoginLink(params)
        .promise();
}

async function getCentralAccountCredentials(
    params: GetEventCentralAccountDeploymentCredentialsRequest
) {
    const eeEventsClient = await getEEEventsClient();
    return await eeEventsClient
        .getEventCentralAccountDeploymentCredentials(params)
        .promise();
}
