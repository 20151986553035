import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';

import { STATUS_MAP, UNKNOWN_STATE } from './constants';
import { EventParticipantStateProps, StatusConfig } from './types';

const EventParticipantState = ({ state }: EventParticipantStateProps) => {
    const config: StatusConfig = STATUS_MAP[state] || UNKNOWN_STATE;

    return (
        <StatusIndicator
            type={config.type}
            colorOverride={config.colorOverride}>
            {config.label || state}
        </StatusIndicator>
    );
};

export default EventParticipantState;
