import { AppSettingsClient } from '@amzn/event-engine-js-utils';

export interface ISettings {
    contentApiEndpoint: string;
    eventsApiEndpoint: string;
    authProxyEndpoint: string;
    eventPortalOrigin?: string;
    cognitoIdentityPoolId: string;
    // TODO: Need to revisit entire analytics approach
    //  Disabling for now since associated backend infra is removed
    // cloudWatchLogGroup: string;
    // cloudWatchLogStream: string;
    // cloudWatchNamespace: string;
    stage: string;
    region: string;
    lambdaStaticAssetCopierInvalidator?: string;
    amplify: any;
    documentation?: string;
    workshopAssetsBucketName?: string;
}

export const AppSettings = async (): Promise<ISettings> => {
    const appSettingsClient = AppSettingsClient.getInstance(
        process.env.NODE_ENV === 'production'
    );

    try {
        const settings = await appSettingsClient.get<ISettings>();

        AppSettingsClient.validate<ISettings>(settings, [
            'contentApiEndpoint',
            'eventsApiEndpoint',
            'authProxyEndpoint',
            'region',
        ]);

        return settings;
    } catch (err) {
        return Promise.reject(new Error('unable to load client settings'));
    }
};

export default AppSettings;
