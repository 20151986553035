import { ContentPermissionsTypes } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

const userRoles: {
    [key in ContentPermissionsTypes]: ContentPermissionsTypes;
} = {
    viewer: 'viewer',
    contributor: 'contributor',
    co_owner: 'co_owner',
    reviewer: 'reviewer',
};

export const userRoleOptions = [
    { label: 'Viewer', value: userRoles.viewer },
    { label: 'Reviewer', value: userRoles.reviewer },
    { label: 'Contributor', value: userRoles.contributor },
    { label: 'Co-owner', value: userRoles.co_owner },
];

export default userRoles;
