import { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { atom } from 'recoil';

import { ExtendedFlashbarProps } from '../models/ExtendedFlashbar';

export const initialAtomStates = {
    tools: {
        open: false,
        hide: true,
        content: <></>,
    },
    breadcrumbs: [] as BreadcrumbGroupProps.Item[],
    isReportWSBugModalOpenState: false,
    isSupportRequestModalOpenState: false,
};

/**************************************************************
 * App State Atoms
 *************************************************************/

export const breadcrumbsState = atom<BreadcrumbGroupProps.Item[]>({
    key: 'breadcrumbsState',
    default: initialAtomStates.breadcrumbs,
});

interface ToolsState {
    open: boolean;
    hide: boolean;
    content: React.ReactNode;
}

export const toolsState = atom<ToolsState>({
    key: 'toolsState',
    default: initialAtomStates.tools,
});

export const contentTypeState = atom<AppLayoutProps.ContentType | undefined>({
    key: 'contentTypeState',
    default: 'default',
});

export const useRecoilContentTypeState = () => useRecoilState(contentTypeState);

export const notificationsState = atom<ExtendedFlashbarProps[]>({
    key: 'notificationsState',
    default: [],
});

export const disableContentPaddingState = atom<boolean>({
    key: 'disableContentPaddingState',
    default: false,
});

export const hideAppNavState = atom<boolean>({
    key: 'hideAppNavState',
    default: false,
});

export const navigationOpenState = atom<boolean>({
    key: 'navigationOpenState',
    default: false,
});

export const navigationContentState = atom<React.ReactNode>({
    key: 'navigationContent',
    default: null,
});

export const maxContentWidthState = atom<number | undefined>({
    key: 'maxContentWidth',
    default: undefined,
});

export const isAuthenticatedState = atom<boolean>({
    key: 'isAuthenticated',
    default: false,
});

export const isReportWSBugModalOpenState = atom<boolean>({
    key: 'isReportWSBugModalOpenState',
    default: initialAtomStates.isReportWSBugModalOpenState,
});

export const useSetIsReportWSBugModalOpenState = () =>
    useSetRecoilState(isReportWSBugModalOpenState);

export const useIsReportWSBugModalOpenState = () =>
    useRecoilState(isReportWSBugModalOpenState);

export const isSupportRequestModalOpenState = atom<boolean>({
    key: 'isSupportRequestModalOpenState',
    default: initialAtomStates.isSupportRequestModalOpenState,
});

export const useSetIsSupportRequestModalOpenState = () =>
    useSetRecoilState(isSupportRequestModalOpenState);

export const useIsSupportRequestModalOpenState = () =>
    useRecoilState(isSupportRequestModalOpenState);

export const domElementDirectionState = atom<'ltr' | 'rtl' | undefined>({
    key: 'domElementDirection',
    default: undefined,
});
