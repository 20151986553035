import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface EditAllowlistModalProps {
    eventData: EventEngineEventsService.EventData;
    isVisible: boolean;
    onDismiss: (state: DismissState) => void;
}

export enum DismissState {
    UPDATE_SUCCESS = 'UPDATE_SUCCESS',
    UPDATE_ERROR = 'UPDATE_ERROR',
    CANCEL = 'CANCEL',
}

export enum NotificationID {
    UPDATE_RESPONSE = 'ALLOWLIST_UPDATE_RESPONSE',
}
