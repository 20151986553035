/* eslint-disable react/display-name */
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    AttributeEditorField,
    FormikField,
    useLazyRequest,
    usePermissionsContext,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import {
    FacilitatorTemplate,
    facilitatorTemplateItem,
} from 'pages/Events/CreateEvent/components/CreateEventForm';
import { getFacilitatorsTagsDefinition } from 'pages/Events/CreateEvent/components/CreateEventForm.utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';

import { i18nStrings } from '../../../../../../../../../constants';
import FormSchema, { FormSchemaType } from './schema';
import { EditEventFacilitatorProps, FieldName, TestID } from './types';

const TAGS_DEFINITION = getFacilitatorsTagsDefinition(false);

const EditFacilitators: React.FC<EditEventFacilitatorProps> = ({
    header,
    onSubmit: onSubmitProp,
    allPermissionsData,
    onSuccess,
}: EditEventFacilitatorProps) => {
    const { isEventTerminated, eventId, permissionsList } =
        allPermissionsData || {};
    const { showNotification } = useContext(NotificationsContext);
    const { setEdit } = usePermissionsContext();
    const [
        updateEventPermissions,
        {
            isLoading: updateEventPermissionsLoading,
            isError: updateEventPermissionsError,
            data: updateEventPermissionsData,
        },
    ] = useLazyRequest<typeof EEEvents.updateEventPermissions, AWSError>(
        EEEvents.updateEventPermissions,
        {}
    );
    const [validateOnChange, setValidateOnChange] = useState(false);

    const onSubmit = async (values: FormSchemaType) => {
        const permissions =
            values[FieldName.FACILITATORS]?.map((facilitator) => {
                return {
                    principal: facilitator.key,
                    roles: [facilitator.value],
                };
            }) || [];

        await updateEventPermissions({
            eventId,
            permissions,
        });
        onSubmitProp && onSubmitProp(permissions);
    };

    useEffect(() => {
        if (updateEventPermissionsData) {
            showNotification?.(
                i18nStrings.events.eventDetails.permissions.notifications
                    .updateSuccess,
                { type: 'success' }
            );
            onSuccess && onSuccess();
            setEdit?.(false);
        }
    }, [updateEventPermissionsData]);

    useEffect(() => {
        if (updateEventPermissionsError) {
            showNotification?.(
                ErrorMessage.getMessage(
                    updateEventPermissionsError,
                    MessageTypes.updateEventPermissions
                ),
                { type: 'error' }
            );
        }
    }, [updateEventPermissionsError]);

    const formik = useFormik({
        validationSchema: FormSchema,
        validateOnBlur: false,
        validateOnChange: validateOnChange,
        initialValues: {
            [FieldName.FACILITATORS]: permissionsList.map((facilitator) => {
                return {
                    key: facilitator.principal,
                    value: facilitator.roles[0],
                };
            }),
        },
        onSubmit,
    });

    useEffect(() => {
        if (formik.submitCount === 1) {
            setValidateOnChange(true);
        }
    }, [formik.submitCount]);

    const handleCancel = useCallback(() => {
        setEdit?.(false);
    }, []);

    const handleSave = useCallback(() => {
        formik.submitForm();
    }, []);

    return (
        <form
            onSubmit={(e) => e.preventDefault()}
            autoComplete="chrome-off"
            noValidate>
            <Form
                errorText={
                    Object.keys(formik.errors).length
                        ? 'The form is invalid. Please check each field for errors.'
                        : ''
                }
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={TestID.ACTION_CANCEL}
                            iconName="close"
                            disabled={updateEventPermissionsLoading}
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            data-testid={TestID.ACTION_SAVE}
                            variant="primary"
                            loading={updateEventPermissionsLoading}
                            disabled={!formik.dirty}
                            onClick={handleSave}>
                            Save
                        </Button>
                    </SpaceBetween>
                }>
                <Container header={header}>
                    <FormikProvider value={formik}>
                        <FormikField<FormSchemaType>
                            name={FieldName.FACILITATORS}
                            formFieldProps={{
                                stretch: true,
                                label: 'Facilitators',
                                description:
                                    'Add up to 50 additional facilitators',
                                children: (
                                    <AttributeEditorField<
                                        FormSchemaType,
                                        FacilitatorTemplate
                                    >
                                        name={FieldName.FACILITATORS}
                                        attributeEditorProps={{
                                            definition: TAGS_DEFINITION,
                                            templateItem: facilitatorTemplateItem,
                                            empty:
                                                'No facilitators have been added',
                                            addButtonText: 'Add facilitator',
                                            removeButtonText: 'Remove',
                                        }}
                                    />
                                ),
                            }}
                        />
                    </FormikProvider>
                </Container>
            </Form>
        </form>
    );
};

export default EditFacilitators;
