import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useRequest } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { AWSError } from 'aws-sdk/lib/error';
import SpinnerWithLabel from 'components/SpinnerWithLabel';
import React, { createContext, FC, useEffect, useState } from 'react';
import EEContentCatalog from 'services/ee-content-catalog';

import { i18nStrings } from '../../constants';

export interface GuardrailsContextValue {
    version: number;
    body: string;
    policyCreatedAt: Date;
    acknowledgedAt?: Date;
    acknowledgementRequired: boolean;
    refetch: () => Promise<void>;
}

export const GuardrailsContext = createContext({} as GuardrailsContextValue);

const GuardrailsProvider: FC = ({ children }) => {
    const [value, setValue] = useState<
        EventEngineContentCatalogService.GetGuardrailsStatusResponse
    >();
    const { data, isError, refetch } = useRequest<
        typeof EEContentCatalog.getGuardrailsStatus,
        AWSError
    >(EEContentCatalog.getGuardrailsStatus, {});

    useEffect(() => {
        if (!data) {
            return;
        }

        setValue(data);
    }, [data]);

    if (isError) {
        return (
            <Alert header={i18nStrings.guardrails.errorAlertTitle}>
                {isError.message}
            </Alert>
        );
    }

    if (!value) {
        return (
            <SpinnerWithLabel>
                {i18nStrings.guardrails.loading}
            </SpinnerWithLabel>
        );
    }

    return (
        <GuardrailsContext.Provider
            value={{
                ...value,
                refetch,
            }}>
            {children}
        </GuardrailsContext.Provider>
    );
};

export default GuardrailsProvider;
