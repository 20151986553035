import React, { useContext } from 'react';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { WorkshopCatalogTableContext } from '../../contexts/WorkshopCatalogTableProvider';
import { DetailLinkBehavior, WorkshopCatalogTableItem } from '../../types';

interface UseWorkshopDetailsLinkValue {
    getHref: (item: WorkshopCatalogTableItem) => string;
    openDetails: (item: WorkshopCatalogTableItem) => void;
}

const useWorkshopDetailsLink = (): UseWorkshopDetailsLinkValue => {
    const history = useHistory();
    const { setCurrentDetails, basePath, detailLinkBehavior } = useContext(
        WorkshopCatalogTableContext
    );
    const getHref = useCallback(
        (item: WorkshopCatalogTableItem) => `${basePath}/${item.contentId}`,
        [basePath]
    );
    const openDetails = useCallback(
        (item: WorkshopCatalogTableItem) => {
            if (detailLinkBehavior === DetailLinkBehavior.MODAL) {
                setCurrentDetails(item);
            } else {
                history.push(getHref(item));
            }
        },
        [detailLinkBehavior, getHref]
    );

    return {
        getHref,
        openDetails,
    };
};

export default useWorkshopDetailsLink;
