import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { useFormikFormBuilder } from '@amzn/event-engine-js-utils';
import { ContentTemplateAttribute } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import HelpPanel from 'components/HelpPanel';
import InfoLink from 'components/InfoLink';
import i18nStrings from 'constants/i18n-strings';
import { FormikContextType } from 'formik';
import React, { useMemo } from 'react';
import { KeyOfType } from 'types';
import { EventAttributes } from 'utils/content-attributes';
import * as Yup from 'yup';

import {
    MergedFormSchema,
    Step,
} from '../components/CreateEventForm.validation';
import { getCreateEventFormFieldName } from './../components/CreateEventForm.utils';
import isFieldValid from './is-field-valid';

const TEXT_COPY = {
    REQUIRED: 'Required',
    SALESFORCE_ERROR:
        'SFDC Opportunity/Campaign ID must be an alphanumeric value of length 15 or 18',
};
export interface EnhancedContentTemplateAttribute
    extends ContentTemplateAttribute {
    dynamicFormName?: string;
    yupValidator?: any;
}

export interface AttributeFormType {
    ['Engagement Type']?: {
        engagementType: string;
        engagementTypeOther?: string;
    };
    Geo?: SelectProps.Options;
    'Salesforce Opportunity ID'?: string;
}

export const Attributes = EventAttributes;

type AttributeTypes = KeyOfType<typeof Attributes>;

const OtherRegExp = /other/i;
// we want to give the ability for them to enter the URL or the 15/18 alphanumeric id
const OpportunityIdRegExp = /(([a-zA-Z0-9]{18}|[a-zA-Z0-9]{15})|(https?:\/\/aws-crm.)(my.salesforce.com\/|lightning.force.com\/lightning\/r\/(Opportunity|Campaign)\/)([a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}))(\/view)?/i;

export const didSelectOtherEngagement = (value: Record<string, any> = {}) => {
    return OtherRegExp.test(value.engagementType);
};

//#region Dynamic validators
const geoValidator = Yup.array()
    .of(
        Yup.object({
            label: Yup.string().required(),
            value: Yup.string().required(),
        })
    )
    .test({
        message: TEXT_COPY.REQUIRED,
        test: (values, context) =>
            isFieldValid(
                context.options.context,
                'attributeGeo',
                !!values?.length
            ),
    });

export const opportunityIdValidator = Yup.string()
    .trim()
    .test({
        message: TEXT_COPY.SALESFORCE_ERROR,
        test: (value, context) =>
            isFieldValid(
                context.options.context,
                'attributeOpportunityId',
                value ? OpportunityIdRegExp.test(value) : true
            ),
    });

export const engagementTypeKey = 'engagementType';
export const otherEngagementTypeKey = 'engagementTypeOther';

/**
 * Dynamic validator. If a user selects Other for engagement type,
 * ask them to explain why.
 */
const engagementTypeValidator = Yup.lazy((value: Record<string, any> = {}) => {
    const baseStringValidator = Yup.string().trim();

    // if user selected other, prompt them to also give a reason why
    if (didSelectOtherEngagement(value)) {
        return Yup.object().shape({
            [engagementTypeKey]: baseStringValidator,
            [otherEngagementTypeKey]: baseStringValidator.required(
                `Specify your engagement type`
            ),
        });
    }

    // if the user did not select other, use regular schema
    return Yup.object()
        .shape({ [engagementTypeKey]: baseStringValidator })
        .test({
            message: {
                [engagementTypeKey]: TEXT_COPY.REQUIRED,
            },
            test: (value, context) =>
                isFieldValid(
                    context.options.context,
                    'attributeEngagementType',
                    !!value?.[engagementTypeKey]
                ),
        });
});
//#endregion

/**
 * The backend does not give any valuable information regarding validations,
 * with the exception of required fields. If there is a requirement for an
 * attribute validator, add it manually here.
 */
export const generateCustomAttributes = (
    contentTemplate?: Pick<
        EventEngineEventsService.EventTemplateData,
        'attributes'
    >
): EnhancedContentTemplateAttribute[] => {
    return (contentTemplate?.attributes || []).map(
        (attribute: ContentTemplateAttribute) => {
            const { name } = attribute;

            if (name === Attributes['Salesforce Opportunity ID']) {
                (attribute as EnhancedContentTemplateAttribute).yupValidator = opportunityIdValidator;
            }
            if (name === Attributes['Engagement Type']) {
                (attribute as EnhancedContentTemplateAttribute).yupValidator = engagementTypeValidator;
                // Engagement Type has a Yup Schema that is of type, Object
                // Update form name to support this
                (attribute as EnhancedContentTemplateAttribute).dynamicFormName = getCreateEventFormFieldName(
                    `${name}.${engagementTypeKey}` as any
                );
            }

            if (name === Attributes['Geo']) {
                (attribute as EnhancedContentTemplateAttribute).yupValidator = geoValidator;
            }

            if (
                !(attribute as EnhancedContentTemplateAttribute).dynamicFormName
            ) {
                (attribute as EnhancedContentTemplateAttribute).dynamicFormName = getCreateEventFormFieldName(
                    name as any
                );
            }
            return {
                ...attribute,
            };
        }
    );
};

/**
 * Given a series of attributes, dynamically build out components
 * and add additional props as seen fit
 */
export const generateAttributeFormElements = (
    attributes: readonly EnhancedContentTemplateAttribute[] = [],
    formik: Pick<
        FormikContextType<any>,
        'submitCount' | 'setFieldTouched' | 'touched'
    >
): Record<AttributeTypes, JSX.Element> => {
    const formBuilder = useFormikFormBuilder<EnhancedContentTemplateAttribute>(
        attributes,
        formik
    );

    const components: Record<AttributeTypes, JSX.Element> = useMemo(() => {
        return attributes.reduce(
            (
                acc: Record<AttributeTypes, JSX.Element>,
                attribute: EnhancedContentTemplateAttribute
            ) => {
                const { name } = attribute;
                let component: JSX.Element;

                if (name === Attributes['Salesforce Opportunity ID']) {
                    component = formBuilder.buildFormElement(attribute, {
                        componentProps: {
                            inputProps: {
                                placeholder: 'Example: 9014z0000010TprABE',
                            },
                        },
                        formFieldProps: {
                            description: '',
                            label: (
                                <>
                                    Opportunity/Campaign ID - <i>optional</i>
                                </>
                            ),
                            info: (
                                <InfoLink
                                    toolsContent={
                                        <HelpPanel
                                            header={
                                                i18nStrings.events.create
                                                    .helpText
                                                    .salesforceOpportunityId
                                                    .header
                                            }>
                                            {
                                                i18nStrings.events.create
                                                    .helpText
                                                    .salesforceOpportunityId
                                                    .content
                                            }
                                        </HelpPanel>
                                    }
                                />
                            ),
                        },
                    });
                } else if (name === Attributes['Engagement Type']) {
                    component = formBuilder.buildFormElement(attribute, {
                        componentProps: {
                            selectProps: {
                                placeholder: 'Select engagement type',
                            },
                        },
                        formFieldProps: {
                            description: '',
                            info: (
                                <InfoLink
                                    toolsContent={
                                        <HelpPanel
                                            header={
                                                i18nStrings.events.create
                                                    .helpText.engagementType
                                                    .header
                                            }>
                                            {
                                                i18nStrings.events.create
                                                    .helpText.engagementType
                                                    .content
                                            }
                                        </HelpPanel>
                                    }
                                />
                            ),
                        },
                    });
                } else if (name === Attributes['Geo']) {
                    component = formBuilder.buildFormElement(attribute, {
                        componentProps: {
                            multiSelectProps: {
                                placeholder: 'Select geo(s)',
                            },
                        },
                        formFieldProps: {
                            description: '',
                            info: (
                                <InfoLink
                                    toolsContent={
                                        <HelpPanel
                                            header={
                                                i18nStrings.events.create
                                                    .helpText.geo.header
                                            }>
                                            {
                                                i18nStrings.events.create
                                                    .helpText.geo.content
                                            }
                                        </HelpPanel>
                                    }
                                />
                            ),
                        },
                    });
                } else {
                    component = formBuilder.buildFormElement(attribute);
                }

                return {
                    ...acc,
                    [name as string]: component,
                };
            },
            {} as Record<AttributeTypes, JSX.Element>
        );
    }, [attributes, formik.submitCount]);

    return components;
};

/**
 * If attributes were not explicitly processed in generateAttributeFormElements,
 * render the rest of the Attributes in the form
 */
export const renderRestOfAttributes = (
    attributeFormComponents: Record<AttributeTypes, JSX.Element>
) => {
    if (
        attributeFormComponents &&
        Object.keys(attributeFormComponents).length === 0 &&
        attributeFormComponents.constructor === Object
    ) {
        return [];
    }

    return [Object.entries(attributeFormComponents)].map(
        (elements: [string, JSX.Element][]) => {
            if (!Array.isArray(elements)) {
                return null;
            }

            return elements.map((element) => {
                const [elementName, component] = element;

                // @ts-ignore
                if (Attributes[elementName]) {
                    return null;
                }

                return React.isValidElement(component) ? component : null;
            });
        }
    );
};

/**
 * In order for the backend to be able to process form attributes, they must
 * be serialized to use the EventEngineEventsService.EventAttribute interface.
 *
 * This function serializes formik values into the desired shape & format.
 */
export const serializeFormAttributes = (
    attributes: readonly EnhancedContentTemplateAttribute[] = [],
    values: MergedFormSchema[Step.EVENT_DETAILS]
): EventEngineEventsService.EventAttribute[] => {
    const formAttributes: (EventEngineEventsService.EventAttribute | null)[] = attributes.map(
        (attribute) => {
            const { name, type } = attribute;

            // @ts-ignore
            let value = (values || {})[name as string];

            if (value && name === Attributes['Engagement Type']) {
                const engagementType = (value || {}).engagementType || '';

                value =
                    engagementType === 'Other'
                        ? (value || {}).engagementTypeOther
                        : engagementType;
            }

            if (value && type === 'multi_choice') {
                value = (value || []).map((val: SelectProps.Option) => {
                    return val.value;
                });
            }

            if (!value || (Array.isArray(value) && value.length === 0)) {
                return null;
            }

            return {
                name: name as string,
                value: {
                    ...(type === 'boolean' ? { booleanValue: !!value } : {}),
                    ...(type === 'number' ? { integerValue: +value } : {}),
                    ...(type === 'multi_choice'
                        ? { stringListValue: value }
                        : {}),
                    ...(type === 'string' || type === 'single_choice'
                        ? { stringValue: value }
                        : {}),
                },
            };
        }
    );

    const filteredAttributes: EventEngineEventsService.EventAttribute[] = formAttributes.filter(
        Boolean
    ) as EventEngineEventsService.EventAttribute[];

    return filteredAttributes;
};
