import {
    ClientFactory,
    DeferredPromise,
    Logger,
    LogLevel,
} from '@amzn/event-engine-js-utils';
import AWS from 'aws-sdk';
import AppSettings from 'services/app-settings';

export type UnauthenticatedClientFactoryClients = ReturnType<
    typeof ClientFactory.initializeUnauthenticatedClients
>;

class UnauthenticatedAWSClient {
    private deferredUnauthenticatedClientPromise?: DeferredPromise<
        UnauthenticatedClientFactoryClients
    >;
    private static instance?: UnauthenticatedAWSClient;

    static getInstance(): UnauthenticatedAWSClient {
        if (!UnauthenticatedAWSClient.instance) {
            UnauthenticatedAWSClient.instance = new UnauthenticatedAWSClient();
        }

        return UnauthenticatedAWSClient.instance;
    }

    static __removeInstance__() {
        if (process.env.NODE_ENV !== 'test') {
            return;
        }
        UnauthenticatedAWSClient.instance = undefined;
    }

    constructor() {}

    getClient = async (): Promise<UnauthenticatedClientFactoryClients> => {
        if (!this.deferredUnauthenticatedClientPromise) {
            this.deferredUnauthenticatedClientPromise = new DeferredPromise<
                UnauthenticatedClientFactoryClients
            >();

            try {
                const appSettings = await AppSettings();
                const credentials = new AWS.CognitoIdentityCredentials(
                    {
                        IdentityPoolId: appSettings.cognitoIdentityPoolId,
                    },
                    { region: appSettings.region }
                );

                // refresh to actually get some credentials
                await this.refreshCredentials(credentials);

                this.deferredUnauthenticatedClientPromise.resolve(
                    ClientFactory.initializeUnauthenticatedClients(
                        credentials,
                        appSettings.region,
                        Logger.getLogger(
                            'UnauthenticatedClientFactory',
                            LogLevel.VERBOSE
                        )
                    )
                );
            } catch (err) {
                this.deferredUnauthenticatedClientPromise.reject(err);
            }
        }

        return await this.deferredUnauthenticatedClientPromise.promise;
    };

    private refreshCredentials = (
        credentials: AWS.CognitoIdentityCredentials
    ): Promise<void> => {
        return credentials.refreshPromise();
    };
}

export default UnauthenticatedAWSClient;
