import EEEventsService from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import {
    AWSCredentials,
    CredentialRoles,
    DeferredPromise,
} from '@amzn/event-engine-js-utils';
import AppSettings from 'services/app-settings';

class EEEventsClient {
    private deferredClientPromise?: DeferredPromise<EEEventsService>;
    private static instance?: EEEventsClient;

    static getInstance(credentials: AWSCredentials): EEEventsClient {
        if (!EEEventsClient.instance) {
            EEEventsClient.instance = new EEEventsClient(credentials);
        }

        return EEEventsClient.instance;
    }

    static __removeInstance__() {
        if (process.env.NODE_ENV !== 'test') {
            return;
        }
        EEEventsClient.instance = undefined;
    }

    private constructor(private credentials: AWSCredentials) {}

    getClient = async (role: CredentialRoles): Promise<EEEventsService> => {
        this.credentials.currentRole = role;

        if (!this.deferredClientPromise) {
            this.deferredClientPromise = new DeferredPromise<EEEventsService>();

            try {
                const settings = await AppSettings();

                this.credentials.authEndpoint = settings.authProxyEndpoint;
                this.deferredClientPromise.resolve(
                    new EEEventsService({
                        credentials: this.credentials,
                        endpoint: settings.eventsApiEndpoint,
                        region: settings.region,
                        maxRetries: 0,
                    })
                );
            } catch (e) {
                this.deferredClientPromise.reject(e);
            }
        }

        return await this.deferredClientPromise.promise;
    };
}

export default EEEventsClient;
