import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import tableConfig from 'constants/table-config';
import React from 'react';
import getDisplayValue from 'utils/get-display-value';

import StackDeploymentStatus from '../StackDeploymentStatus';
import { StackDeploymentEventsTableItem } from './types';

enum ColumnID {
    DATE = 'date',
    PHYSICAL_RESOURCE_ID = 'physical_resource_id',
    LOGICAL_ID = 'logical_id',
    EVENT_ID = 'event_id',
    RESOURCE_TYPE = 'resource_type',
    RESOURCE_STATUS = 'resource_status',
    REASON = 'reason',
}

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    wrapLines: true,
    visibleContent: [
        ColumnID.DATE,
        ColumnID.LOGICAL_ID,
        ColumnID.RESOURCE_STATUS,
        ColumnID.REASON,
    ],
};

type ColumnFieldMap = Record<ColumnID, keyof StackDeploymentEventsTableItem>;

export const columnFieldMap: ColumnFieldMap = {
    [ColumnID.DATE]: 'timestamp',
    [ColumnID.PHYSICAL_RESOURCE_ID]: 'physicalResourceId',
    [ColumnID.LOGICAL_ID]: 'logicalResourceId',
    [ColumnID.EVENT_ID]: 'eventId',
    [ColumnID.RESOURCE_TYPE]: 'resourceType',
    [ColumnID.RESOURCE_STATUS]: 'resourceStatus',
    [ColumnID.REASON]: 'resourceStatusReason',
};

export const defaultSortPreferences: TableProps.SortingState<StackDeploymentEventsTableItem> = {
    ...tableConfig.defaultSortPreferences,
    sortingColumn: {
        sortingField: columnFieldMap[ColumnID.DATE],
    },
};

type ColumnDefinition = TableProps.ColumnDefinition<
    StackDeploymentEventsTableItem
> &
    Partial<CollectionPreferencesProps.VisibleContentOption>;

const getResourceStatus = ({
    resourceStatus,
}: StackDeploymentEventsTableItem) => (
    <StackDeploymentStatus status={resourceStatus} />
);

export const columnDefinitions: ColumnDefinition[] = [
    {
        id: ColumnID.DATE,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns.date,
        minWidth: 140,
    },
    {
        id: ColumnID.LOGICAL_ID,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .logicalId,
        editable: false,
        minWidth: 140,
    },
    {
        id: ColumnID.EVENT_ID,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns.eventId,
        minWidth: 140,
    },
    {
        id: ColumnID.RESOURCE_TYPE,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .resourceType,
        minWidth: 140,
    },
    {
        id: ColumnID.RESOURCE_STATUS,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .resourceStatus,
        cell: (item: StackDeploymentEventsTableItem) =>
            item.resourceStatus ? getResourceStatus(item) : getDisplayValue(),
        minWidth: 200,
    },
    {
        id: ColumnID.REASON,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns.reason,
        editable: false,
    },
    {
        id: ColumnID.PHYSICAL_RESOURCE_ID,
        header:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .physicalResourceId,
        minWidth: 140,
    },
].map(({ id, header, editable, minWidth, cell }) => ({
    id,
    header,
    cell: cell || ((item) => getDisplayValue(item[columnFieldMap[id]])),
    sortingField: columnFieldMap[id],
    minWidth,
    editable,
}));

export const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: 'and',
};

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        id: ColumnID.PHYSICAL_RESOURCE_ID,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .physicalResourceId,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .physicalResourceId,
    },
    {
        id: ColumnID.LOGICAL_ID,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .logicalId,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .logicalId,
    },
    {
        id: ColumnID.EVENT_ID,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns.eventId,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .eventId,
    },
    {
        id: ColumnID.RESOURCE_TYPE,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .resourceType,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .resourceType,
    },
    {
        id: ColumnID.RESOURCE_STATUS,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns
                .resourceStatus,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .resourceStatus,
    },
    {
        id: ColumnID.REASON,
        propertyLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.columns.reason,
        groupValuesLabel:
            i18nStrings.events.teams.stackDeploymentEventsTable.propertyFilter
                .reason,
    },
].map(({ id, propertyLabel, groupValuesLabel }) => ({
    key: columnFieldMap[id],
    propertyLabel,
    groupValuesLabel,
    operators: ['=', '!=', ':', '!:'],
    showOptions: true,
}));
