import {
    EVENT_ACCOUNT_SOURCE,
    TEAM_STATE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    BaseMarkdown,
    CheckboxField,
    Constants,
    formatString,
    FormikField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';
import ErrorMessage, { MessageTypes } from 'services/error-message';

import { i18nStrings } from '../../../../../constants';
import validationSchema, { FieldName, SchemaType } from './schema';
import { NotificationID, TerminateTeamProps } from './types';

const TerminateTeamModal = ({
    event,
    team,
    onDismiss,
    onUpdateSuccess,
    isVisible = false,
}: TerminateTeamProps) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { showNotification } = useContext(NotificationsContext);
    const onSubmit = useCallback(
        (values: Partial<SchemaType>) => {
            const data: EventEngineEventsService.TerminateEventTeamRequest = {
                eventId: team.eventId,
                teamId: team.teamId,
            };

            if (values[FieldName.BAN_PARTICIPANTS] && team.participantIds) {
                data.banParticipantList = team.participantIds;
            }

            terminateTeam(data);
        },
        [team]
    );
    const formik = useFormik({
        validationSchema,
        initialValues: {
            [FieldName.BAN_PARTICIPANTS]: false,
            [FieldName.ACCOUNT_SOURCE]: event.accountSource,
            [FieldName.TEAM_STATE]: team.state,
        },
        validateOnChange,
        validateOnBlur: false,
        onSubmit,
    });
    const confirmTerminateTeam = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, [formik.handleSubmit]);
    const [
        terminateTeam,
        {
            isError: terminateTeamError,
            isLoading: isTerminateTeamLoading,
            data: terminateTeamData,
        },
    ] = useLazyRequest<typeof EEEvents.terminateTeam, AWSError>(
        EEEvents.terminateTeam,
        {}
    );
    const { modalComponent } = useModal({
        isVisible,
        onDismiss,
        modalHeader: i18nStrings.events.teams.terminateTeamConfirmation.header,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onDismiss,
            },
            primary: {
                text:
                    i18nStrings.events.teams.terminateTeamConfirmation
                        .primaryAction,
                loading: isTerminateTeamLoading,
                disabled: !formik.isValid,
                onClick: confirmTerminateTeam,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <SpaceBetween direction="vertical" size="l">
                    <div>
                        {event.accountSource ===
                        EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
                            ? i18nStrings.events.teams.terminateTeamConfirmation
                                  .content.workshopStudioProvided
                            : i18nStrings.events.teams.terminateTeamConfirmation
                                  .content.customerProvided}
                    </div>
                    {event.accountSource ===
                        EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO &&
                    team.state === TEAM_STATE.DEPLOYMENT_FAILED ? (
                        <Alert
                            header={
                                i18nStrings.events.teams
                                    .terminateTeamConfirmation
                                    .terminateTeamWarning.header
                            }
                            type="warning">
                            <SpaceBetween direction="vertical" size="m">
                                <TextContent>
                                    {
                                        <BaseMarkdown
                                            linkTarget="_blank"
                                            components={{
                                                p: Fragment,
                                            }}>
                                            {formatString(
                                                i18nStrings.events.teams
                                                    .terminateTeamConfirmation
                                                    .terminateTeamWarning
                                                    .content,
                                                Constants.ExternalLinks
                                                    .reportBug
                                            )}
                                        </BaseMarkdown>
                                    }
                                </TextContent>
                                <FormikField<SchemaType>
                                    name={FieldName.TERMS_ACKNOWLEDGED}
                                    dataTestId={FieldName.TERMS_ACKNOWLEDGED}
                                    formFieldProps={{
                                        children: (
                                            <CheckboxField<SchemaType>
                                                name={
                                                    FieldName.TERMS_ACKNOWLEDGED
                                                }
                                                checkboxProps={{
                                                    children:
                                                        i18nStrings.events.teams
                                                            .terminateTeamConfirmation
                                                            .terminateTeamWarning
                                                            .acknowledgementField
                                                            .label,
                                                    disabled: isTerminateTeamLoading,
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            </SpaceBetween>
                        </Alert>
                    ) : null}
                    {team.participantIds?.length && (
                        <CheckboxField<SchemaType>
                            name={FieldName.BAN_PARTICIPANTS}
                            checkboxProps={{
                                children:
                                    i18nStrings.events.teams
                                        .terminateTeamConfirmation.fields
                                        .banParticipants.label,
                                description:
                                    i18nStrings.events.teams
                                        .terminateTeamConfirmation.fields
                                        .banParticipants.description,
                                disabled: isTerminateTeamLoading,
                            }}
                        />
                    )}
                </SpaceBetween>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        if (!terminateTeamData) {
            return;
        }

        onUpdateSuccess();
        showNotification?.(
            i18nStrings.events.teams.terminateTeamConfirmation.notifications
                .terminateSuccess,
            {
                id: NotificationID.UPDATE_RESPONSE,
                type: 'success',
            }
        );
    }, [terminateTeamData]);

    useEffect(() => {
        terminateTeamError &&
            showNotification?.(
                ErrorMessage.getMessage(
                    terminateTeamError,
                    MessageTypes.terminateTeam
                ),
                {
                    id: NotificationID.UPDATE_RESPONSE,
                    type: 'error',
                }
            );
    }, [terminateTeamError]);

    useEffect(() => {
        if (!(terminateTeamData || terminateTeamError)) {
            return;
        }

        onDismiss();
    }, [terminateTeamData, terminateTeamError]);

    return modalComponent;
};

export default TerminateTeamModal;
