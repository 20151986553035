import {
    AccessibleRegionList,
    AwsRegion,
    DeploymentRegionList,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';

import { DEFAULT_ACCESSIBLE_REGIONS } from './constants';

/**
 * Converts regions response data into form data shape
 *
 * @param {DeploymentRegionList} deploymentRegions
 * @param {AccessibleRegionList} accessibleRegions
 * @returns
 */
const getRegionsFormData = (
    deploymentRegions?: DeploymentRegionList,
    accessibleRegions?: AccessibleRegionList
) => ({
    deploymentRegions: deploymentRegions?.[0],
    // Ensure system default region value is set.
    // API response will not include system default region, however
    // it could be present if the system default region is the
    // selected deployment region
    accessibleRegions: accessibleRegions
        ? Array.from(
              new Set<AwsRegion>([
                  ...DEFAULT_ACCESSIBLE_REGIONS.map(
                      ({ value }) => value as AwsRegion
                  ),
                  ...accessibleRegions,
              ])
          ).map((region) => ({
              label: region,
              value: region,
          }))
        : undefined,
});

export default getRegionsFormData;
