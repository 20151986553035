import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';

interface UserAttributes {
    username: string;
    fullName: string;
    givenName: string;
    familyName: string;
    email: string;
}

interface UseUserAttributesValue {
    /**
     * Handle that will populate user attribute values when called
     */
    loadUserAttributes: () => Promise<void>;
    /**
     * User attributes
     */
    userAttributes?: UserAttributes;
}

interface UseUserAttributesProps {
    /**
     * If true, user attributes will not be loaded on mount
     */
    lazy?: boolean;
}

const useUserAttributes = ({
    lazy,
}: UseUserAttributesProps = {}): UseUserAttributesValue => {
    const [userAttributes, setUserAttributes] = useState<UserAttributes>();
    const loadUserAttributes = useCallback(async () => {
        const currentSession = await Auth.currentSession().catch(() => {
            // swallow error
        });
        if (!currentSession) {
            return;
        }
        const { payload } = currentSession.getIdToken();
        const fullName = `${payload.given_name || ''} ${
            payload.family_name || ''
        }`.trim();
        setUserAttributes({
            fullName,
            username: payload['cognito:username'],
            givenName: payload.given_name,
            familyName: payload.family_name,
            email: payload.email,
        });
    }, [setUserAttributes]);

    useEffect(() => {
        !lazy && loadUserAttributes();
    }, []);

    return {
        userAttributes,
        loadUserAttributes,
    };
};

export default useUserAttributes;
