import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    formatString,
    FormikField,
    MultiSelectField,
    SelectField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { AWSError } from 'aws-sdk/lib/error';
import {
    FormikProvider,
    useFormik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import { useNotification } from 'hooks';
import { RegionsSchema } from 'pages/Events/CreateEvent/components/CreateEventForm.validation';
import {
    DEFAULT_ACCESSIBLE_REGIONS,
    DEFAULT_REGION_CATEGORIES,
    REQUIRED_REGION_CATEGORIES,
} from 'pages/Events/CreateEvent/utils/constants';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';
import { SELECT_FILTERING_MIN_OPTIONS } from 'utils/config';
import pluralizeSimple from 'utils/pluralize-simple';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../constants';
import {
    filterRegionOptions,
    getAccessibleRegionsConstraints,
    getRegionSelectOptions,
    getRegionsFormData,
    getRegionsRequestData,
} from '../../../CreateEvent/utils';

export interface UpdateRegionsModalProps {
    event: Pick<
        EventEngineEventsService.EventData,
        'eventId' | 'deploymentRegions' | 'accessibleRegions'
    >;
    contentBuild: Pick<
        EventEngineContentCatalogService.ContentBuildData,
        'contentSpecSummary'
    >;
    isVisible: boolean;
    onModalClosed: () => void;
    refetchGetEvent: () => Promise<void>;
}

type FormSchemaType = Yup.InferType<typeof RegionsSchema>;

const UpdateRegionsModal = ({
    contentBuild,
    event,
    isVisible,
    onModalClosed,
    refetchGetEvent,
}: UpdateRegionsModalProps) => {
    const { showNotification } = useNotification();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const onSubmit = useCallback(
        (values: FormSchemaType) => {
            updateEventRegions({
                eventId: event.eventId,
                ...getRegionsRequestData(
                    values.deploymentRegions,
                    values.accessibleRegions
                ),
            });
        },
        [event]
    );
    const formik = useFormik({
        validate: async (values: FormSchemaType) => {
            try {
                await validateYupSchema<FormSchemaType>(
                    values,
                    RegionsSchema,
                    true,
                    {
                        contentSpecSummary: contentBuild.contentSpecSummary,
                    }
                );
            } catch (err) {
                return yupToFormErrors(err);
            }

            return {};
        },
        validateOnBlur: false,
        validateOnChange,
        initialValues: {
            ...getRegionsFormData(
                event.deploymentRegions,
                event.accessibleRegions
            ),
        },
        onSubmit,
    });
    const selectedDeploymentRegions = formik.values.deploymentRegions;
    const selectedAccessibleRegions = formik.values.accessibleRegions;
    const lastSelectedDeploymentRegions = useRef(selectedDeploymentRegions);
    const deploymentRegions = useMemo(
        () =>
            getRegionSelectOptions(
                contentBuild.contentSpecSummary?.deployableRegions
            ).map(({ label, value }) => ({ label, value })),
        [contentBuild]
    );
    const accessibleRegions = useMemo(() => {
        const defaultRegions = [...DEFAULT_ACCESSIBLE_REGIONS];

        // Add selected deployment region as accessible region if
        // it is not one of the system default accessible regions.
        if (
            selectedDeploymentRegions &&
            !DEFAULT_ACCESSIBLE_REGIONS.some(
                ({ value }) => value === selectedDeploymentRegions
            )
        ) {
            defaultRegions.push({
                value: selectedDeploymentRegions,
                description:
                    i18nStrings.events.regionCategories.deploymentRegion,
            });
        }

        return getRegionSelectOptions(
            contentBuild.contentSpecSummary?.accessibleRegions,
            defaultRegions
        );
    }, [selectedDeploymentRegions]);
    const accessibleRegionsConstraint = useMemo(() => {
        let constraint: string | undefined;
        const accessibleRegionsConstraints = getAccessibleRegionsConstraints(
            contentBuild.contentSpecSummary
        );
        const numberRequired = filterRegionOptions(
            accessibleRegions,
            REQUIRED_REGION_CATEGORIES
        ).length;
        const minAdditional = accessibleRegionsConstraints.min - numberRequired;
        const maxAdditional = accessibleRegionsConstraints.max - numberRequired;

        if (minAdditional > 0 && maxAdditional > 0) {
            if (minAdditional !== maxAdditional) {
                constraint = formatString(
                    i18nStrings.events.create.fields.accessibleRegions
                        .constraintMinMaxAdditional,
                    minAdditional,
                    pluralizeSimple('region', minAdditional),
                    maxAdditional
                );
            } else {
                constraint = formatString(
                    i18nStrings.events.create.fields.accessibleRegions
                        .constraintMinAdditional,
                    minAdditional,
                    pluralizeSimple('region', minAdditional)
                );
            }
        } else if (maxAdditional > 0) {
            constraint = formatString(
                i18nStrings.events.create.fields.accessibleRegions
                    .constraintMaxAdditional,
                maxAdditional,
                pluralizeSimple('region', maxAdditional)
            );
        }

        return constraint;
    }, [accessibleRegions]);
    const [
        updateEventRegions,
        {
            isLoading: updateEventRegionsLoading,
            isError: updateEventRegionsError,
            data: updateEventRegionsData,
        },
    ] = useLazyRequest<typeof EEEvents.updateEventRegions, AWSError>(
        EEEvents.updateEventRegions,
        {}
    );
    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, []);
    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);
    const closeModal = () => {
        hideModal();
        onModalClosed();
    };

    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: onModalClosed,
        modalHeader: i18nStrings.events.updateEventRegionsModal.header,
        actions: {
            primary: {
                text: i18nStrings.events.updateEventRegionsModal.actionUpdate,
                onClick: onPrimaryActionClick,
                disabled: !formik.dirty || !formik.isValid,
                loading: updateEventRegionsLoading,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    autoComplete="chrome-off"
                    noValidate>
                    <Form
                        errorText={
                            updateEventRegionsError &&
                            ErrorMessage.getMessage(
                                updateEventRegionsError,
                                MessageTypes.updateEventRegions
                            )
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormikField<FormSchemaType>
                                name="deploymentRegions"
                                dataTestId="deploymentRegions"
                                formFieldProps={{
                                    label:
                                        i18nStrings.events.create.fields
                                            .deploymentRegions.label,
                                    stretch: true,
                                    description:
                                        i18nStrings.events.create.fields
                                            .deploymentRegions.description,
                                    children: (
                                        <SelectField<FormSchemaType>
                                            name="deploymentRegions"
                                            placeholder={
                                                i18nStrings.events.create.fields
                                                    .deploymentRegions
                                                    .placeholder
                                            }
                                            selectProps={{
                                                options: deploymentRegions,
                                                filteringType:
                                                    deploymentRegions.length >
                                                    SELECT_FILTERING_MIN_OPTIONS
                                                        ? 'auto'
                                                        : undefined,
                                            }}
                                        />
                                    ),
                                }}
                            />
                            <FormikField<FormSchemaType>
                                name="accessibleRegions"
                                dataTestId="accessibleRegions"
                                formFieldProps={{
                                    label:
                                        i18nStrings.events.create.fields
                                            .accessibleRegions.label,
                                    stretch: true,
                                    description:
                                        i18nStrings.events.create.fields
                                            .accessibleRegions.description,
                                    constraintText: accessibleRegionsConstraint,
                                    children: (
                                        <MultiSelectField<FormSchemaType>
                                            name="accessibleRegions"
                                            placeholder={
                                                i18nStrings.events.create.fields
                                                    .accessibleRegions
                                                    .placeholder
                                            }
                                            multiSelectProps={{
                                                options: accessibleRegions,
                                                filteringType:
                                                    accessibleRegions.length >
                                                    SELECT_FILTERING_MIN_OPTIONS
                                                        ? 'auto'
                                                        : undefined,
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </SpaceBetween>
                    </Form>
                </form>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        if (
            selectedAccessibleRegions &&
            selectedDeploymentRegions === lastSelectedDeploymentRegions.current
        ) {
            return;
        }

        const { max } = getAccessibleRegionsConstraints(
            contentBuild.contentSpecSummary
        );

        formik.setFieldValue(
            'accessibleRegions',
            filterRegionOptions(accessibleRegions, [
                ...REQUIRED_REGION_CATEGORIES,
                ...DEFAULT_REGION_CATEGORIES,
            ]).slice(0, max)
        );

        lastSelectedDeploymentRegions.current = selectedDeploymentRegions;
    }, [selectedDeploymentRegions]);

    useEffect(() => {
        if (updateEventRegionsData) {
            showNotification('Event regions updated successfully.', {
                type: 'success',
            });
            refetchGetEvent();
            closeModal();
        }
    }, [updateEventRegionsData]);

    return modalComponent;
};

export default UpdateRegionsModal;
