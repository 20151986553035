import { AWS_REGION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { DEFAULT_ACCESSIBLE_REGIONS } from './constants';

const VALID_AWS_REGIONS = new Set(Object.values(AWS_REGION));

/**
 * Returns regions data for create and update event requests
 *
 * @param {string|undefined} deploymentRegions
 * @param {MultiselectProps.Option[]|undefined} accessibleRegions
 * @returns
 */
const getRegionsRequestData = (
    deploymentRegions?: string,
    accessibleRegions?: MultiselectProps.Option[]
) => {
    const res: Omit<
        EventEngineEventsService.UpdateEventRegionsRequest,
        'eventId'
    > = {
        deploymentRegions: [],
        accessibleRegions: [],
    };

    if (deploymentRegions) {
        res.deploymentRegions = [
            deploymentRegions as EventEngineEventsService.AwsRegion,
        ];
    }

    if (accessibleRegions) {
        res.accessibleRegions = accessibleRegions
            .map(({ value }) => value as EventEngineEventsService.AwsRegion)
            .filter(
                (region) =>
                    ![
                        // Do not include default regions
                        ...DEFAULT_ACCESSIBLE_REGIONS.map(({ value }) => value),
                        // Dedupe deployable regions
                        deploymentRegions,
                    ].includes(region) &&
                    VALID_AWS_REGIONS.has(region as AWS_REGION)
            );
    }

    return res;
};

export default getRegionsRequestData;
