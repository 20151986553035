import {
    EVENT_PERMISSIONS_TYPES,
    EVENT_TYPE,
    QUOTA_ID,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import {
    BaseMarkdown,
    EmptyMessage,
    EventMatrix,
    useRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import InfoLink from 'components/InfoLink';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { UserContext } from 'contexts/UserProvider';
import getMinAccountRequirement from 'pages/Events/CreateEvent/utils/get-min-account-requirement';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';
import ErrorMessage from 'services/error-message';

import { i18nStrings } from '../../../../../../../../../constants';
import AddReservationModal from '../AddReservationModal';
import ReleaseReservationModal from '../ReleaseReservationModal';
import { columnDefinitions, QuotaReservationsTableContext } from './config';
import { ActionID, QuotaReservationsTableProps } from './types';
import processData from './utils/process-data';

const QuotaReservationsTable = ({
    event,
    refetchGetEvent,
    refetchGetEventLoading,
    reservations,
}: QuotaReservationsTableProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const { data: userData } = useContext(UserContext);
    const [isRefreshing, setIsRefreshing] = useState<boolean>();
    const [activeAction, setActiveAction] = useState<ActionID>();
    const {
        isError: getQuotaError,
        isLoading: getQuotaLoading,
        data: getQuotaData,
    } = useRequest<typeof EEEvents.getQuota, AWSError>(
        EEEvents.getQuota,
        {},
        {
            quotaId:
                event.type === EVENT_TYPE.PRODUCTION
                    ? QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT
                    : QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_TEST_EVENT,
        }
    );
    const allItems = useMemo(() => processData(reservations), [reservations]);
    const totalReservations = reservations?.length
        ? reservations.reduce((acc, { reserved }) => acc + (reserved || 0), 0)
        : 0;
    const maxReservation = getQuotaData?.quota?.quota?.limit
        ? getQuotaData.quota.quota.limit +
          (event.centralAccountRequired ? 1 : 0) -
          totalReservations
        : 0;
    const { items, collectionProps } = useCollection(allItems, {
        selection: {
            trackBy: 'email',
        },
    });
    const { selectedItems } = collectionProps;
    const selectedItem = selectedItems?.length
        ? items.find(({ email }) => email === selectedItems[0].email)
        : undefined;
    const maxRequiredQuota = getMinAccountRequirement(
        event.numberOfForecastedParticipants,
        event.teamSize,
        event.centralAccountRequired
    );
    const canReleaseReservation = (email = '') =>
        email === event.primaryFacilitator ||
        event.permissions
            .find(({ principal }) => principal === email)
            ?.roles.includes(EVENT_PERMISSIONS_TYPES.ADMINISTRATOR) ||
        email === selectedItem?.email;
    const maxReleasable = useMemo(() => {
        if (!selectedItem) {
            return 0;
        }

        const excess = totalReservations - maxRequiredQuota;
        const releasable = selectedItem.reserved - selectedItem.consumed;

        return Math.min(excess, releasable);
    }, [selectedItem, maxRequiredQuota, totalReservations]);
    const releaseReservationDisabledReason = useMemo(() => {
        if (!selectedItem) {
            return;
        }

        if (!canReleaseReservation(userData?.userId)) {
            return i18nStrings.events.quotas.table.actionDisabledReasons
                .noPermissions;
        } else if (selectedItem.consumed >= selectedItem.reserved) {
            return i18nStrings.events.quotas.table.actionDisabledReasons
                .allConsumed;
        } else if (totalReservations <= maxRequiredQuota) {
            return i18nStrings.events.quotas.table.actionDisabledReasons
                .noExcess;
        }
    }, [maxRequiredQuota, userData, totalReservations, selectedItem]);
    const addReservationDisabledReason = useMemo(() => {
        if (maxReservation > 0) {
            return '';
        }

        return i18nStrings.events.quotas.table.actionDisabledReasons
            .maxContribution;
    }, [maxReservation, totalReservations]);
    const handleAddReservation = useCallback(() => {
        setActiveAction(ActionID.ADD_RESERVATION);
    }, []);
    const handleReleaseReservation = useCallback(() => {
        setActiveAction(ActionID.RELEASE_RESERVATION);
    }, []);
    const clearActiveAction = useCallback(() => {
        setActiveAction(undefined);
    }, []);
    const handleTableRefresh = useCallback(() => {
        refetchGetEvent();
        setIsRefreshing(true);
    }, []);
    const releaseReservationAction = (
        <Button
            data-testid={ActionID.RELEASE_RESERVATION}
            onClick={handleReleaseReservation}
            disabled={
                !selectedItem || Boolean(releaseReservationDisabledReason)
            }
            iconName={
                releaseReservationDisabledReason ? 'status-info' : undefined
            }>
            {i18nStrings.events.quotas.table.actions.releaseReservation}
        </Button>
    );
    const addReservationAction = (
        <Button
            data-testid={ActionID.ADD_RESERVATION}
            onClick={handleAddReservation}
            disabled={Boolean(addReservationDisabledReason || getQuotaError)}
            loading={getQuotaLoading}
            iconName={addReservationDisabledReason ? 'status-info' : undefined}>
            {i18nStrings.events.quotas.table.actions.addReservation}
        </Button>
    );
    const isEventActive = EventMatrix.isEventActive({
        runtime: event.stateRuntime,
        infrastructure: event.stateInfrastructure,
    });

    useEffect(() => {
        getQuotaError &&
            showNotification(ErrorMessage.getMessage(getQuotaError), {
                type: 'error',
            });
    }, [getQuotaError]);

    useEffect(() => {
        !refetchGetEventLoading && setIsRefreshing(false);
    }, [refetchGetEventLoading]);

    return (
        <QuotaReservationsTableContext.Provider value={{ event }}>
            <Table
                {...collectionProps}
                variant="stacked"
                header={
                    <Header
                        description={
                            i18nStrings.events.quotas.table.description
                        }
                        counter={`(${items.length})`}
                        info={
                            <InfoLink
                                toolsContent={
                                    <HelpPanel
                                        header={
                                            i18nStrings.events.quotas.info
                                                .quotaReservationsTable.header
                                        }>
                                        <TextContent>
                                            <BaseMarkdown>
                                                {
                                                    i18nStrings.events.quotas
                                                        .info
                                                        .quotaReservationsTable
                                                        .content
                                                }
                                            </BaseMarkdown>
                                        </TextContent>
                                    </HelpPanel>
                                }
                            />
                        }
                        actions={
                            <SpaceBetween size="xs" direction="horizontal">
                                <Button
                                    onClick={handleTableRefresh}
                                    loading={isRefreshing}
                                    iconName="refresh"
                                />
                                {isEventActive &&
                                    (releaseReservationDisabledReason ? (
                                        <Popover
                                            content={
                                                releaseReservationDisabledReason
                                            }
                                            dismissButton={false}
                                            triggerType="custom">
                                            {releaseReservationAction}
                                        </Popover>
                                    ) : (
                                        releaseReservationAction
                                    ))}
                                {isEventActive &&
                                    (addReservationDisabledReason ? (
                                        <Popover
                                            content={
                                                addReservationDisabledReason
                                            }
                                            dismissButton={false}
                                            triggerType="custom">
                                            {addReservationAction}
                                        </Popover>
                                    ) : (
                                        addReservationAction
                                    ))}
                            </SpaceBetween>
                        }>
                        {i18nStrings.events.quotas.table.header}
                    </Header>
                }
                loading={isRefreshing}
                loadingText={i18nStrings.loading}
                empty={
                    <EmptyMessage
                        title={i18nStrings.events.quotas.table.emptyTitle}
                        description={
                            i18nStrings.events.quotas.table.emptyDescription
                        }
                    />
                }
                items={items}
                columnDefinitions={columnDefinitions}
                selectionType={isEventActive ? 'single' : undefined}
            />
            {activeAction === ActionID.ADD_RESERVATION && getQuotaData && (
                <AddReservationModal
                    event={event}
                    max={maxReservation}
                    onDismiss={clearActiveAction}
                    onSuccess={refetchGetEvent}
                    isVisible={activeAction === ActionID.ADD_RESERVATION}
                    isOwner={userData?.userId === event.primaryFacilitator}
                />
            )}
            {selectedItem && activeAction === ActionID.RELEASE_RESERVATION && (
                <ReleaseReservationModal
                    email={selectedItem.email}
                    event={event}
                    max={maxReleasable}
                    onDismiss={clearActiveAction}
                    onSuccess={refetchGetEvent}
                    isVisible={activeAction === ActionID.RELEASE_RESERVATION}
                    isSelf={userData?.userId === selectedItem.email}
                />
            )}
        </QuotaReservationsTableContext.Provider>
    );
};

export default QuotaReservationsTable;
