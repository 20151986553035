const i18nStrings = {
    appName: 'Workshop Studio',
    links: {
        wiki: 'Workshop Studio Wiki',
        featureRequest: 'Submit a feature request',
        supportRequest: 'Submit a support request',
        reportBug: 'Report a bug',
        submitFeedback: 'Submit feedback',
        newTab: 'Opens link in a new tab',
    },
    info: 'Info',
    and: 'and',
    close: 'Close',
    closeWindow: 'Close window',
    actions: 'Actions',
    empty: '-',
    unknown: 'Unknown',
    deleteConfirmLabel: "Type 'delete' to confirm",
    dismiss: 'Dismiss',
    expand: 'Expand',
    collapse: 'Collapse',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    today: 'today',
    edit: 'Edit',
    required: 'Required',
    optional: 'optional',
    redacted: 'Redacted',
    hour: 'hour',
    yes: 'Yes',
    no: 'No',
    justNow: 'Just now',
    drEnvironmentWarning:
        'Event provisioning is disabled in this environment and cannot be used to run events at this time.',
    backToPrimaryEnvironment: 'Back to primary environment',
    accountSourceMissingPopoverContent:
        '[AWS account source configuration](https://catalog.workshops.aws/docs/en-US/create-a-workshop/authoring-a-workshop/aws-account-configuration) is required for event creation.',
    datePicker: {
        today: 'Today',
        next: 'Next',
        pervious: 'Previous',
    },
    eventReadinessTable: {
        title: 'Event readiness',
        description:
            'Relevant factors for this workshop that can block, restrict, or add additional requirements for event creation.',
        columns: {
            title: 'Factor',
            impact: 'Impact',
            message: 'Comments',
        },
        factors: {
            accountSource: {
                title: 'Account source',
                message:
                    '[AWS account source configuration](https://catalog.workshops.aws/docs/en-US/create-a-workshop/authoring-a-workshop/aws-account-configuration) is required for event creation.',
            },
            eventApproval: {
                title: 'Event approval',
                message:
                    'Additional approval will be required before the event can be scheduled.',
            },
            eventPermissions: {
                title: 'Create event permissions',
                message:
                    'Event creation will be limited to a specified user/group allowlist.',
            },
            publishingType: {
                title: 'Publishing type',
                message:
                    'Only [publicly published workshops](https://catalog.workshops.aws/docs/en-US/create-a-workshop/reviewing-and-publishing-a-workshop/publishing-a-workshop#what-is-publishing-and-what-are-the-options) can be used for production events.',
            },
            contentState: {
                title: 'Workshop state',
                message:
                    'Only active workshops, not scheduled for deletion, can be used to create events.',
            },
        },
    },
    eventImpactingFactors: '{0} factor(s)',
    eventReadinessStatus: {
        label: 'Event readiness',
        warningAlertBlocked:
            'There are [blocking event readiness factor(s)](#event-readiness) that will need to be addressed before you can create events with this content.',
        warningAlertRestricted:
            'There are [restricting event readiness factor(s)](#event-readiness) that apply to this content that may impact event creation and scheduling.',
        any: 'Any event readiness',
        blocked: 'Blocked',
        restricted: 'Restricted',
        ready: 'Ready',
    },
    supportRequestModal: {
        modalTitle: 'Submit a support request',
        modalAlert:
            'This is for creating a support request for Workshop Studio related issues only. For content related issues, report the issue directly from the workshop details view.',
        modalBodyMainDescription:
            'AWS Workshop Studio does not maintain the workshops that are published, as these are maintained by the workshop owner and the associated contributors. If you are attempting to report an issue with a workshop please use the "Report a content issue" button on the workshop details view. You can find the workshop details view by finding the workshop in the Workshop Catalog and clicking "View Details."',
        modalBodyMainSubtext:
            'If you are encountering issues with Workshop Studio or any associated workflows to publish/deliver content, please click "Submit support request" below to report the issue.',
        primaryActionText: 'Submit support request',
        secondaryActionText: 'Cancel',
    },
    popovers: {
        eventResource: {
            insufficientPermissionsTitle: 'Insufficient permissions',
            insufficientPermissionsDescription:
                'You do not have sufficient permissions to view this resource. You may reach out to the resource owner to grant you permissions to view this resource.',
            nonexistentResource: 'Nonexistent resource',
            nonexistentResourceDescription:
                'Details for this resource could not be found, which may mean this resource no longer exists.',
        },
        eventReadinessInternalContentWarning:
            'Internally published workshops cannot be used in production events at this time.',
    },
    guardrails: {
        title: 'Workshop Studio Guardrails',
        loading: 'Checking guardrail acceptance...',
        acknowledgeCheckbox: 'I accept the Workshop Studio Guardrails',
        acknowledgeCheckboxError: 'You must accept the guardrails to continue',
        errorAlertTitle: 'Failed to retrieve Workshop Studio guardrails',
        actionContinue: 'Continue to Workshop Studio',
        lastUpdated: 'Last updated on {0}',
        acknowledgedAt: 'Acknowledged on {0}',
        notificationAcknowledgementRequired:
            'Please read and accept the Workshop Studio Guardrails before proceeding.',
    },
    signIn: {
        signingIn: 'Signing in...',
        callback: {
            signingIn: 'Completing sign-in...',
            signInFailure: {
                header: 'Sign-in error',
                content:
                    'Failed to sign in at this time. Please try clearing cookies and refreshing the page. If the problem persists, please try refreshing the page at a later time.',
            },
        },
    },
    myWorkshops: {
        title: 'My workshops',
    },
    createWorkshop: {
        title: 'Create workshop',
    },
    editWorkshop: {
        title: 'Edit workshop',
    },
    createEventAction: {
        createEvent: 'Create event',
        createTestEvent: 'Create test event',
        workshopBuildStateDisabledReason:
            'Test events can only be created with successful builds.',
        workshopAccountSourceMissingDisabledReason:
            'An AWS account source must be specified for event creation.',
        eventReadinessRestrictedModal: {
            header: 'Review impacting event readiness factors',
            content:
                'There are restricting event readiness factor(s) that apply to this content that may impact event creation and scheduling.',
            confirmAction: 'Create event',
        },
        eventReadinessBlockedModal: {
            header: 'Blocking event readiness factors',
            content:
                'There are blocking event readiness factor(s) that will need to be addressed before you can create events with this content.',
        },
    },
    landing: {
        ctaContainer: {
            title: 'Explore Workshop Studio',
            content:
                'Discover and deliver hands-on workshops created and tested by AWS experts, or create and build your own unique workshop',
            primaryAction: 'Get started',
        },
        sections: {
            howItWorks: {
                title: 'How it works',
                discover: {
                    title: {
                        action: 'Discover',
                        target: 'AWS vetted content',
                    },
                    description:
                        'Discover a catalog of 850+ workshops that have been vetted by AWS experts.',
                },
                build: {
                    title: {
                        action: 'Build',
                        target: 'and review hands-on content',
                    },
                    description:
                        'Author and collaborate on content easily by utilizing our markdown renderer and embedded build process that generates standardized AWS-themed workshop guides.',
                },
                publish: {
                    title: {
                        action: 'Publish',
                        target:
                            'content that meets AWS quality and compliance bar',
                    },
                    description:
                        'Use our content review questionnaire to ensure that content meets the AWS quality and compliance bar before making it publicly accessible.',
                },
                deliver: {
                    title: {
                        action: 'Deliver',
                        target: 'with hands-on access to AWS accounts',
                    },
                    description:
                        'Run hands-on events, including the ability to provide access to AWS Accounts, as well as pre-provision infrastructure, to ensure participants have everything they need to get started.',
                },
            },
        },
    },
    workshops: {
        popovers: {
            scheduledForDeletionEditWorkshop:
                'Workshops scheduled for deletion can not be edited.',
        },
        scheduleContentDeletionAction: 'Schedule deletion',
        cancelContentDeletionAction: 'Cancel deletion',
        scheduleContentDeletionTitle: 'Schedule workshop deletion',
        cancelContentDeletionTitle: 'Cancel workshop deletion',
        scheduleContentDeletionContent:
            'Workshops scheduled for deletion will be deleted in 30 days. Until then, the workshop will:\n\n- Remain published, but no events may be created using this content.\n- Be readonly and no modifications may be made to the content.\n- Retain uploaded assets and generated asset static URLs, which will remain publicly accessible.\n\nYou can cancel deletion at any point within the 30 day window, after which the workshop will be permanently deleted.',
        cancelContentDeletionModalBody: {
            p1: ({ date }: { date: Date }) =>
                `This workshop is scheduled for deletion on ${date.toLocaleDateString()}. If your workshop was previously published, the workshop will:`,
            ul1: [
                'Be restored to the workshop catalog, making it publicly discoverable.',
                'Restore the last published build, making it publicly accessible.',
            ],
            p2: ' Proceed with cancelling deletion for this content?',
        },
        scheduledContentDeletionSuccess:
            'Successfully scheduled workshop for deletion.',
        scheduledForDeletionAlert: {
            header: 'Workshop deletion notice',
            messageDaysUntilDelete: ({
                timeUntilDelete,
            }: {
                timeUntilDelete: string;
            }) => `Workshop scheduled for deletion in ${timeUntilDelete}`,
            messageDeleteToday: 'Workshop scheduled for deletion today',
            readonlyMessage:
                'During this period, this workshop is readonly and may not be edited, reviewed, or published.',
            publicMessage:
                'During this period, this workshop will remain published, but may not be used in new events.',
        },
        upcomingScheduledForDeletionAlert: {
            messageMultiple: ({
                count,
                interval,
            }: {
                count: number;
                interval: string;
            }) =>
                `You have ${count} workshop${
                    count !== 1 ? 's' : ''
                } scheduled for deletion within ${interval}.`,
            messagePostfix: ({ interval }: { interval: string }) =>
                `is scheduled for deletion ${interval}.`,
        },
        cancelContentDeletionSuccess: 'Successfully cancelled deletion.',
        cancellingContentDeletion: 'Cancelling content deletion...',
        schedulingForDeletion: 'Scheduling for deletion...',
        cancellingDeletion: 'Cancelling deletion...',
        viewRelatedResources: 'View related resources',
        buildsTable: {
            title: 'Recent builds',
            columns: {
                buildId: 'Build ID',
                logs: 'Logs',
                sourceBranch: 'Source branch',
                requestedBy: 'Requested by',
                buildDate: 'Build date',
                buildState: 'State',
                accountSourceDefined: 'Account source defined',
            },
            propertyFilter: {
                buildId: 'Build ID values',
                sourceBranch: 'Source branch values',
                requestedBy: 'Requested by values',
                buildState: 'State values',
                accountSourceDefined: 'Account source defined values',
            },
            actions: {
                preview: 'Preview build',
                pin: 'Pin build',
                unpin: 'Unpin build',
            },
            actionsDisabledReason: {
                preview: 'Preview unavailable for failed builds.',
            },
            collectionSelectFilter: {
                pinnedState: {
                    all: 'All builds',
                    pinned: 'Pinned',
                    unpinned: 'Unpinned',
                },
            },
            errorTitle: 'Problem getting builds',
            emptyTitle: 'No builds created',
            emptyDescription: 'No builds have been created yet.',
            pinning: {
                errors: {
                    maximumReached: {
                        header: 'Maximum number of pins reached',
                        content:
                            'You will need to unpin an existing build to pin another build.',
                    },
                },
                unpinConfirmationModal: {
                    header: 'Confirm unpin',
                    content:
                        'A maximum of 10 builds are preserved and the oldest unpinned builds will be scheduled for pruning. Proceed with unpinning this build?',
                    primaryAction: 'Unpin',
                },
            },
        },
        buildsOverview: {
            title: 'Overview',
            values: {
                lastBuildDate: 'Last build date',
                publishedBuildId: 'Published build ID',
                publishingState: 'Publishing state',
                pinned: 'Pinned',
            },
        },
        buildStates: {
            created: 'Starting',
            error: 'Failed',
            running: 'In progress',
            success: 'Success',
        },
        buildLogSeverity: {
            info: 'Info',
            warning: 'Warning',
            error: 'Error',
        },
        publishingStates: {
            failed: 'Failed',
            published: 'Published',
            publishing: 'Publishing...',
            unpublishing: 'Unpublishing...',
            unpublished: 'Unpublished',
            publishedTypes: {
                internal: 'Internal',
                public: 'Public',
            },
        },
        buildDetails: {
            title: 'Build details',
            values: {
                workshopId: 'Workshop ID',
                workshopBuildId: 'Workshop build ID',
                buildDate: 'Build date',
                state: 'State',
                branchName: 'Branch name',
                commitId: 'Commit ID',
                uploadedAssets: 'Uploaded assets',
                accountSourceDefined: 'Account source defined',
            },
            badges: {
                pinned: 'Pinned',
            },
        },
        buildLogsTable: {
            title: 'Logs',
            columns: {
                severity: 'Severity',
                errorCode: 'Error code',
                message: 'Message',
            },
            emptyTitle: 'No build logs',
            emptyDescription: 'No build logs available for this build.',
        },
        relatedResourcesTable: {
            title: 'Related resources',
            columns: {
                resourceId: 'Resource ID',
                resourceType: 'Resource type',
                owner: 'Owner',
                impact: 'Impact',
                contentBuildId: 'Workshop Build ID',
                createdAt: 'Created at',
            },
            relationships: {
                all: 'All resources',
                nonBlocking: 'Non-blocking',
                blocking: 'Blocking deletion',
            },
            resourceTypes: {
                event: 'Event',
            },
            errorTitle: 'Problem getting related resources',
            emptyTitle: 'No related resources',
            emptyDescription:
                'No related resources could be found for this workshop.',
        },
        workshopState: {
            anyState: 'Any state',
            active: 'Active',
            scheduledForDeletion: 'Scheduled for deletion',
            createFailed: 'Create failed',
            deleteFailed: 'Delete failed',
        },
        internalPublishModal: {
            headerText: 'Publish workshop',
            primaryActionButtonText: 'Publish',
            closeAriaLabel: 'Cancel publish workshop',
            content: {
                infoAlertText:
                    'The workshop publishing method can only be chosen once and cannot be changed for subsequent publishing requests. Once you have made this selection, clicking the "Publish" button moving forward will use this selection. Workshops indicated as being internal only in the review cannot be published publicly. Similarly, Workshops indicated as being public cannot be published internally.',
                publishingPrompt: 'Publishing method',
                publishingDescription:
                    'This cannot be changed for subsequent publishing requests.',
                form: {
                    internalPublishing: {
                        label: 'Internal',
                        // TODO: Note for the future...as of 11/29/2021 we say "Amazon Employees" but will have to make this more generic once we add partners and other tenants
                        bulletPointOne:
                            'Will still be visible in the Content Catalog, but only visible to other Amazon Employees',
                        bulletPointTwo:
                            'Content will not be publicly accessible',
                        bulletPointThree: 'Used for internally focused content',
                    },
                    publicPublishing: {
                        label: 'Public',
                        bulletPointOne:
                            'Content will be visible in the Content Catalog',
                        bulletPointTwo:
                            'Content will be publicly accessible by non-Amazonians',
                        bulletPointThree:
                            'Should not be used for internally focused content',
                    },
                    selectField: {
                        label: 'Workshop build to publish',
                        description:
                            'Only successful workshop builds may be published.',
                        placeholder: 'Select a workshop build',
                        emptySelections: 'No successful builds available',
                        latestBuild: '$LATEST',
                        buildDate: 'Created {0}',
                        publishedBadge: 'Published ({0})',
                        internalBadgeText: 'Internal',
                        publicBadgeText: 'Public',
                        publishedBuildRequired: 'Build selection required',
                    },
                },
            },
        },
        unpublishedError: 'This workshop has not been published yet.',
        credentialsAction: 'Credentials',
        credentialsModal: {
            header: 'Credentials',
            descriptions: {
                general:
                    'These credentials grant you scoped-down access to this workshops code repository and static assets S3 bucket.',
                viewer:
                    'As a viewer, you will be able to access the workshop repository and create commits locally. In order to push updates to the remote repository, you will need to request contributor or co-owner permissions.',
            },
            sections: {
                repoAccess: 'Repository access instructions',
                assetAccess: 'Assets access instructions',
            },
        },
        workshopCatalog: {
            title: 'Workshop catalog',
            table: {
                actions: {
                    create: 'Create workshop',
                    details: 'View details',
                },
                stateError: {
                    title: 'Problem getting workshops',
                },
                stateEmpty: {
                    title: 'No workshops',
                    description:
                        'No workshops created yet, or you may not have sufficient permissions to view certain workshops.',
                },
                workshopStateCollectionSelect: {
                    label: 'Workshop state',
                },
                eventReadinessStateCollectionSelect: {
                    label: 'Event readiness state',
                    options: {
                        any: 'Any event readiness state',
                        ready: 'Event ready',
                        restricted: 'Event restricted',
                        blocked: 'Event blocked',
                    },
                },
                accountSourceDefinedStateCollectionSelect: {
                    label: 'Account source defined',
                    options: {
                        any: 'Any account source definition',
                        defined: 'Account source defined',
                        missing: 'Account source missing',
                    },
                },
            },
            detailsModal: {
                title: 'Workshop details',
            },
        },
        reviewForm: {
            errorTextNotesField: 'Should not exceed {0} characters',
        },
        assets: {
            title: 'Assets',
            states: {
                completed: 'Completed',
                failed: 'Internal error',
                scanInProgress: 'Scan in progress...',
                pending: 'Pending',
            },
            scanResult: {
                pass: 'Passed',
                fail: 'Failed',
            },
            overview: {
                title: 'Overview',
                values: {
                    totalAssets: 'Total assets',
                    totalScannedAssets: 'Total scanned assets',
                    completedScans: 'Assets without errors',
                    failedScans: 'Assets with errors',
                },
            },
            table: {
                errorTitle: 'Problem getting assets',
                columns: {
                    key: 'Key',
                    size: 'Size',
                    type: 'Type',
                    updatedAt: 'Last modified',
                    scanStatus: 'Scan status',
                    stagingVersionId: 'S3 version ID',
                },
                propertyFilter: {
                    key: 'Key values',
                    sizeLabel: 'Size (Bytes)',
                    size: 'Size values',
                    type: 'Type values',
                    syncStatus: 'Sync status values',
                },
                emptyMessage: {
                    title: 'No assets have been created yet',
                },
                filterStates: {
                    label: 'Scan status',
                    allAssets: 'All assets',
                    completed: 'Assets without errors',
                    failed: 'Assets with errors',
                    pending: 'Assets pending scan',
                    scanning: 'Assets scan in progress',
                },
            },
            selectTable: {
                emptyMessage: {
                    title: 'No assets available for static URL generation',
                },
            },
        },
        assetStaticUrlDetails: {
            syncFailedWarning:
                'The last sync attempt for this static URL failed and is currently not in sync with the latest asset version.',
            errorAlert: 'Could not load asset static URL details',
            details: {
                title: 'Asset details',
                values: {
                    key: 'Key',
                    size: 'Size',
                    type: 'Type',
                    lastSyncedAt: 'Last synced',
                    syncStatus: 'Sync state',
                    lastModifiedAt: 'Last modified',
                    syncFailureReason: 'Sync failure reason',
                },
            },
            regionUrlsTable: {
                title: 'Asset static URLs',
                columns: {
                    region: 'Region',
                    httpsUrl: 'HTTPS protocol URL',
                    s3Url: 'S3 protocol URL',
                },
                propertyFilter: {
                    region: 'Region values',
                    httpsUrl: 'HTTPS protocol URL values',
                    s3Url: 'S3 protocol URL values',
                },
                emptyMessage: {
                    title: 'No asset static URLs',
                    description: 'No asset static URLs have been generated yet',
                },
                errorMessage: {
                    title: 'Failed to get asset static URLs',
                },
                actions: {
                    downloadCsv: 'Download CSV',
                    singleItemDisabledReason:
                        'Multi-select download action support coming soon.',
                },
            },
        },
        assetStaticUrls: {
            title: 'Asset static URLs',
            syncFailures: {
                title: 'Sync failures',
                alert:
                    'Failed to start sync for one or more assets. Failed sync attempts can be retried anytime.',
            },
            syncForm: {
                title: 'Sync asset static URLs',
                acknowledgementFieldLabel:
                    'I understand the downstream implications of syncing changes to my existing assets.',
                constraintRequired: 'Acknowledgement required',
                warningAlert:
                    'Syncing assets which already have a static URL could be disruptive to scheduled and ongoing events that are currently using this asset',
                successNotification: 'Started sync for {0} static URL(s).',
                submitAction: 'Start sync',
                partialSyncError:
                    'Failed to start sync for one or more assets. Failed sync attempts can be retried anytime.',
                error: 'The request could not be processed: {0}.',
            },
            deleteForm: {
                title: 'Delete asset static URLs',
                acknowledgementFieldLabel:
                    'I acknowledge the potential downstream impacts of removing access to these assets via a static URL',
                constraintRequired: 'Acknowledgement required',
                warningAlert:
                    'Deleting asset static URLs could be disruptive to scheduled and ongoing events based on this workshop that are using these assets. This action only deletes the static URL and does not delete the asset itself.',
                successNotification: 'Started deletion of {0} static URL(s).',
                submitAction: 'Delete',
                disabledReason:
                    'Asset static URLs that are currently syncing, or have not successfully synced previously (no synced version ID) cannot be deleted.',
                error: 'The request could not be processed: {0}.',
            },
            states: {
                completed: 'In sync',
                syncFailed: 'Sync failed',
                outOfSync: 'Out of sync',
                syncInProgress: 'Sync in progress...',
                unsynced: 'Unsynced',
                initiated: 'Initiated',
            },
            diffStatuses: {
                deleted: 'Deleted',
                modified: 'Modified',
            },
            unknownSyncStateDetail:
                'No details found for the current sync state.',
            noAssetStaticUrls: 'No asset static URLs found.',
            overview: {
                title: 'Overview',
                values: {
                    inSync: 'In sync',
                    outOfSync: 'Out of sync',
                    syncInProgress: 'Sync in progress',
                    syncFailed: 'Sync failed',
                },
            },
            diffStatusTable: {
                title: 'Asset diff',
                description:
                    'Static URLs will also be deleted if the associated asset is deleted.',
                columns: {
                    key: 'Key',
                    status: 'Status',
                    syncFailReason: 'Sync failure reason',
                },
                emptyMessage: {
                    title: 'No asset static URLs selected',
                },
            },
            deleteStaticUrlsPreviewTable: {
                title: 'Review',
                columns: {
                    assetKey: 'Asset key',
                },
                emptyMessage: {
                    title: 'No asset static URLs selected',
                },
            },
            assetStaticUrlsTable: {
                columns: {
                    key: 'Asset key',
                    size: 'Size',
                    type: 'Type',
                    syncedVersionId: 'Synced version ID',
                    lastUpdatedAt: 'Last modified',
                    syncStatus: 'State',
                    lastSyncedAt: 'Last synced',
                },
                propertyFilter: {
                    key: 'Key values',
                    type: 'Type values',
                    syncedVersionId: 'Synced version ID values',
                    sizeLabel: 'Size (Bytes)',
                    size: 'Size values',
                    syncStatus: 'State values',
                },
                emptyMessage: {
                    title: 'No asset static URLs',
                    description: 'No asset static URLs have been created yet',
                },
                errorMessage: {
                    title: 'Failed to get asset static URLs',
                },
                syncStateCollectionSelect: {
                    label: 'Sync state',
                    options: {
                        all: 'All sync states',
                        inSync: 'In sync',
                        outOfSync: 'Out of sync',
                        syncFailed: 'Sync failed',
                    },
                },
                actions: {
                    delete: 'Delete',
                    sync: 'Sync static URLs',
                    generate: 'Generate static URLs',
                },
                disabledReasons: {
                    syncInvalidStatus:
                        'Only out of sync or failed asset static URLs that have been synced successfully previously (will have a synced version ID) can be synced.',
                },
            },
        },
        generateWorkshopStaticAssetURLs: {
            title: 'Generate asset static URLs',
            form: {
                header: 'Generate asset static URL',
                generate: 'Generate',
                alertMessage:
                    'Asset static URLs should only be used in very specific cases where the risks are understood and [magic variables](https://catalog.workshops.aws/docs/en-US/create-a-workshop/authoring-a-workshop/managing-workshop-assets/using-magic-variables ) and/or the [assets URL directive](https://catalog.workshops.aws/docs/en-US/detailed-documentation/directives/asset-url) cannot be used to access assets. All scheduled and live events using these static URLs will be impacted by any modifications to the associated assets.',
                errorMessage: 'Must select at least 1 item',
                successNotification:
                    'Started generation of {0} asset static URL(s).',
                error: 'The request could not be processed: {0}.',
            },
            table: {
                description:
                    'Only successfully uploaded and scanned assets can be used to generate asset static URLs.',
            },
        },
    },
    events: {
        title: 'Events',
        sectionHeader: 'Events',
        awsConsoleLogin: 'AWS console login',
        titles: {
            active: 'Active events',
            upcoming: 'Upcoming events',
            past: 'Past events',
        },
        types: {
            test: 'Test',
            production: 'Production',
        },
        accountQuotaStatus: {
            errorPreviousMonth: "Previous months' quotas unavailable",
            inProgress: 'Checking quota...',
            serviceError: 'Failed to retrieve quota details',
            outOfRange: 'Month out of maximum quota range',
            exceedsAvailable: 'Exceeds {0} available monthly quota',
            nothingAvailable: 'No available monthly quota',
            valid: 'Sufficient monthly quota ({0} available)',
            constraint: '{0} monthly quota available',
            exceedsParticipantForecast:
                'Insufficient monthly quota to satisfy the AWS account requirement: Have {0}, need {1}',
        },
        durationWarning: {
            modal: {
                title: 'Confirm event duration',
                close: 'Close',
                confirm: 'Confirm',
                message:
                    'AWS resources will be consumed for {0} hours as part of your event. To be frugal, consider reducing the duration and extending it later during your event as needed. Longer running events are more likely to result in fraud and abuse. As a reminder, events must NOT be left running unattended for customers to complete in their own time.',
                confirmationLabel: "Type '{0}' to confirm",
                confirmationDescription:
                    'By confirming, you have considered the costs involved with consuming AWS resources for this duration',
                durationConfirmationFieldError:
                    'Value is case sensitive and must be typed exactly',
            },
        },
        table: {
            errorTitle: 'Problem getting events',
            emptyTitle: 'No events',
            emptyDescription:
                'No events created yet, or you may not have sufficient permissions to view certain events.',
            columns: {
                title: 'Title',
                type: 'Type',
                startDate: 'Start date',
                startTime: 'Start time',
                duration: 'Duration',
                durationUnit: 'Duration (hours)',
                workshopId: 'Workshop ID',
                state: 'State',
                attendees: 'Participant forecast',
                engagementType: 'Engagement type',
                facilitator: 'Primary facilitator',
                geo: 'Geo',
                modality: 'Modality',
                deploymentRegions: 'Deployment regions',
                accessibleRegions: 'Accessible regions',
                createdAt: 'Created at',
            },
            propertyFilter: {
                title: 'Title values',
                type: 'Type values',
                duration: 'Duration values',
                workshopId: 'Workshop ID values',
                state: 'State values',
                attendees: 'Participant forecast values',
                engagementType: 'Engagement type values',
                facilitator: 'Primary facilitator values',
                geo: 'Geo values',
                modality: 'Modality values',
                deploymentRegions: 'Deployment region values',
                accessibleRegions: 'Accessible region values',
            },
            active: {
                emptyTitle: 'No active events',
            },
            upcoming: {
                emptyTitle: 'No upcoming events',
            },
            past: {
                emptyTitle: 'No past events',
                emptyDescription:
                    'No events have been cancelled or terminated yet, or you may not have sufficient permissions to view certain events.',
            },
            types: {
                all: 'All types',
                test: 'Test event',
                production: 'Production event',
            },
        },
        centralAccount: {
            detailsContainer: {
                header: 'Central account details',
                values: {
                    centralAccountId: 'Module ID',
                    name: 'Module name',
                    awsAccountID: 'AWS account ID',
                    state: 'State',
                    stateReason: 'Deployment failure reason',
                },
            },
            states: {
                provisionQueued: 'Provision queued',
                provisionInProgress: 'Provisioning...',
                provisionSuccess: 'Provisioned',
                provisionFailed: 'Provision failed',
                deploymentQueued: 'Queued for deployment',
                deploymentInProgress: 'Deploying...',
                deploymentHalted: 'Deployment halted',
                deploymentSuccess: 'Deployed',
                deploymentFailed: 'Deployment failed',
                terminateInProgress: 'Terminating...',
                terminateFailed: 'Terminate failed',
                terminateSuccess: 'Terminated',
            },
            centralAccountStacks: {
                alerts: {
                    noSuchStack: 'No stack named "{0}" found.',
                },
            },
        },
        teams: {
            title: 'Teams',
            downloadCsvAction: 'Download CSV',
            notifications: {
                teamAccountAccessError:
                    'Could not load team account access details.',
            },
            detailsContainer: {
                header: 'Team details',
                values: {
                    teamId: 'TeamID',
                    teamAccessCode: 'Team access code',
                    name: 'Name',
                    awsAccountID: 'AWS account ID',
                    state: 'State',
                    deploymentDuration: 'Deployment duration',
                    stateReason: 'Deployment failure reason',
                },
            },
            stackNameCards: {
                title: 'Stacks',
                errorTitle: 'Problem getting stacks',
                emptyTitle: 'No stacks',
                emptyDescription: 'No stacks to display.',
                sections: {
                    name: 'Name',
                    status: 'Status',
                },
                propertyFilter: {
                    name: 'Name values',
                    status: 'Status values',
                },
                filters: {
                    stackStatus: {
                        all: 'All statuses',
                    },
                },
            },
            teamStacks: {
                viewInCloudFormationAction: {
                    label: 'View in CloudFormation',
                    options: {
                        ops: 'Ops role',
                        participant: 'Participant role',
                    },
                },
                alerts: {
                    noSuchStack: 'No stack named "{0}" found.',
                },
            },
            stackDeploymentEventsTable: {
                title: 'Deployment events',
                errorTitle: 'Problem getting deployment events',
                emptyTitle: 'No deployment events',
                emptyDescription: 'No deployment events to display.',
                columns: {
                    date: 'Date',
                    physicalResourceId: 'Physical resource ID',
                    logicalId: 'Logical ID',
                    eventId: 'Stack event ID',
                    resourceType: 'Resource type',
                    resourceStatus: 'Resource status',
                    reason: 'Status reason',
                },
                propertyFilter: {
                    date: 'Date values',
                    physicalResourceId: 'Physical resource ID values',
                    logicalId: 'Logical ID values',
                    eventId: 'Stack event ID values',
                    resourceType: 'Resource type values',
                    resourceStatus: 'Resource status values',
                    reason: 'Status reason values',
                },
            },
            stackOutputsTable: {
                title: 'Outputs',
                errorTitle: 'Problem getting stack outputs',
                emptyTitle: 'No stack outputs',
                emptyDescription: 'No stack outputs to display.',
                columns: {
                    outputKey: 'Output key',
                    exportKey: 'Export key',
                    value: 'Value',
                    description: 'Description',
                    source: 'Source',
                },
                propertyFilter: {
                    outputKey: 'Output key values',
                    exportKey: 'Export key values',
                    value: 'Values',
                    description: 'Descriptions',
                    source: 'Sources',
                },
            },
            detailTabs: {
                stacks: 'Stacks',
                participants: 'Participants',
            },
            table: {
                title: 'Teams',
                errorTitle: 'Problem getting teams',
                emptyTitle: 'No teams',
                emptyDescription: 'No teams provisioned yet.',
                actions: {
                    accessAWSAccount: 'Access AWS account',
                    terminate: 'Terminate',
                },
                columns: {
                    name: 'Name',
                    teamAccessCode: 'Team access code',
                    teamId: 'Team ID',
                    participants: 'Participant count',
                    state: 'State',
                    deploymentDuration: 'Deployment duration',
                    awsAccountId: 'AWS account ID',
                    joinEventUrl: 'Join event URL',
                    lastJoined: 'Last joined',
                    lastAccountAccess: 'Last account access',
                    lastModified: 'Last modified',
                },
                propertyFilter: {
                    name: 'Name values',
                    teamAccessCode: 'Team access code values',
                    teamId: 'Team ID values',
                    participants: 'Participant count values',
                    state: 'State values',
                    awsAccountId: 'AWS account ID values',
                },
            },
            terminateTeamConfirmation: {
                header: 'Terminate team',
                content: {
                    customerProvided:
                        'Terminating a team is an irreversible action. All participants in the team will be immediately removed from the event.',
                    workshopStudioProvided:
                        'Terminating a team is an irreversible action. All participants in the team will be immediately removed from the event and access to the team AWS account will be revoked. All resources within the team AWS account will be deleted.',
                },
                primaryAction: 'Terminate',
                notifications: {
                    terminateSuccess: 'Successfully started team termination.',
                },
                fields: {
                    banParticipants: {
                        label: 'Ban team participants',
                        description:
                            'Banned participants will not be able to rejoin the event. Participants can be unbanned anytime during the event.',
                    },
                },
                terminateTeamWarning: {
                    header: 'Terminating unutilized teams',
                    content:
                        'If you are terminating a team due to deployment failures, do not terminate the team until all available troubleshooting methods to retry deployment have been exhausted, including reaching out to [Workshop Studio support]({0}).',
                    acknowledgementField: {
                        label:
                            'I acknowledge the conditions of terminating this team',
                    },
                },
            },
            states: {
                provisionQueued: 'Provision queued',
                provisionInProgress: 'Provisioning...',
                provisionSuccess: 'Provisioned',
                provisionFailed: 'Provision failed',
                deploymentQueued: 'Queued for deployment',
                deploymentInProgress: 'Deploying...',
                deploymentHalted: 'Deployment halted',
                deploymentSuccess: 'Deployed',
                deploymentFailed: 'Deployment failed',
                terminateInProgress: 'Terminating...',
                terminateFailed: 'Terminate failed',
                terminateSuccess: 'Terminated',
            },
            overviewContainer: {
                title: 'Deployment overview',
                values: {
                    state: 'State',
                    queued: 'Queued',
                    inProgress: 'In progress',
                    success: 'Success',
                    failed: 'Failed',
                    terminated: 'Terminated',
                    halted: 'Halted',
                    averageDeploymentDuration: 'Average deployment duration',
                },
                states: {
                    failed: 'Failed',
                    halted: 'Halted',
                    inProgress: 'In progress',
                    notStarted: 'Not started',
                    success: 'Success',
                    successPartial: 'Partial success',
                },
            },
            alerts: {
                comingSoonMultiParticipantTeams:
                    'Teams are currently limited to a single participant. Multi-participant teams and team request/assignment functionality will be coming soon!',
            },
        },
        participants: {
            title: 'Participants',
            table: {
                title: 'Participants',
                description:
                    'Participant emails are deleted from the system 30 days post termination of an event for compliance reasons.',
                errorTitle: 'Problem getting participants',
                emptyTitle: 'No participants',
                emptyDescription: 'No participants have joined the event',
                columns: {
                    email: 'Email',
                    firstJoined: 'First joined',
                    lastJoined: 'Last joined',
                    state: 'State',
                    teamId: 'Team ID',
                    teamName: 'Team name',
                    lastUpdated: 'Last updated',
                    participantId: 'Participant ID',
                    deniedReason: 'Denied reason',
                },
                propertyFilter: {
                    email: 'Email values',
                    participantId: 'Participant ID values',
                    state: 'State values',
                    teamId: 'Team ID values',
                    teamName: 'Team name values',
                    deniedReason: 'Denied reason values',
                },
            },
            states: {
                all: 'All states',
                assigned: 'Assigned',
                denied: 'Denied',
                pending: 'Pending',
                unassigned: 'Unassigned',
                banned: 'Banned',
            },
            denyReasons: {
                kicked: 'Kicked by operator',
                notAllowListed: 'Not allow-listed',
                outOfCapacity: 'Out of capacity',
            },
            actions: {
                ban: 'Ban',
                unban: 'Unban',
                accessAWSAccount: 'Access team AWS account',
            },
            banParticipantConfirmation: {
                header: 'Ban participant',
                content:
                    'Once banned, the participant will be immediately removed from the event and will not be able to rejoin. Banned participants can be unbanned anytime during the event.',
                primaryAction: 'Ban',
                notifications: {
                    banSuccess: 'Successfully banned participant.',
                },
            },
            unbanParticipantConfirmation: {
                header: 'Unban participant',
                content:
                    'Once unbanned, the participant will be able to rejoin the event if capacity allows.',
                primaryAction: 'Unban',
                notifications: {
                    banSuccess: 'Successfully unbanned participant.',
                },
            },
            notifications: {
                teamAccountAccessError: {
                    header: 'Failed to load team AWS account access details',
                    content: {
                        invalidState:
                            'The team AWS account cannot be accessed in the current team state.',
                        getTeamError:
                            'Could not load team details at this time.',
                    },
                },
            },
        },
        participantAllowlist: {
            title: 'Participant allowlist',
            values: {
                type: 'Type',
                emailPatterns: 'Email patterns',
                deniedParticipants: 'Denied participants',
            },
            types: {
                emailList: 'Email list',
                allowAll: 'Allow all',
            },
            overflow: 'See all (+{0} more)',
            deniedParticipants: '{0} denied {1}',
            participant: 'participant',
            updateTitle: 'Update participant allowlist',
            edit: 'Edit allowlist',
            notifications: {
                updateSuccess: {
                    content: 'Successfully updated participant allowlist.',
                },
                missingAllowlistValue: {
                    content:
                        'No participants will be able to join the event until one or more email patterns are specified in the allowlist, or "allow all" is enabled.',
                },
            },
            alerts: {
                update:
                    'Updating the allowlist to a more restrictive value will not remove participants who have already joined the event.',
            },
        },
        create: {
            title: 'Create event',
            test: {
                title: 'Create test event',
                defaultValues: {
                    title: '{0} event',
                    description: 'Test event for content {0}',
                },
            },
            helpText: {
                accountSource: {
                    header: 'Account source',
                    content:
                        'Account source allows you to specify whether customers can use Workshop Studio provided AWS Accounts or whether customers should use their own AWS Account. If you choose "Customer Provided", no AWS Accounts will be provided by Workshop Studio.',
                },
                eventDetails: {
                    header: 'Workshop Studio event',
                    content:
                        'An event is a single repeatable engagement where participants get hand-on access to an AWS account to gain practical skills.',
                },
                startTime: {
                    header: 'Event Start date and time',
                    content:
                        "Please specify the event's local timezone. It maybe different from your current timezone.",
                },
                salesforceOpportunityId: {
                    header: 'Opportunity/Campaign ID',
                    content:
                        'Please include a 15 or 18 character Opportunity/Campaign ID from Salesforce. You can find this in the overview section in Salesforce, or from the opportunity/campaign URL.',
                },
                engagementType: {
                    header: 'Engagement Type',
                    content:
                        'An Engagement Type is an established customer engagement event designed for demand generation or opportunity acceleration (e.g., Immersion Day,  Hackathon, etc).',
                },
                geo: {
                    header: 'Geography',
                    content:
                        'Please include the Geographies in which the event will be run. This is for reporting purposes only, and does not restrict access to participants in certain Geographies.',
                },
                duration: {
                    header: 'Duration',
                    content:
                        'Please include an event duration (in hours) of up to 72 hours. Please note that your event will be auto-terminated based on the specified duration after which participants will lose access to the event and AWS Accounts.',
                },
                facilitators: {
                    header: 'Facilitators',
                    content:
                        '**Administrators** - Primary facilitators have full administrative control including the ability to:\n- Modify event detail and workshop selected\n- Federate into participant accounts\n- Provision, activate, or terminate an event.\n\nAs an owner, you are automatically considered an administrator for this event.\n\n**Support Staff** - Limited permissions with read-only access to event details, content details, and the ability to federate into participant accounts for support.',
                },
                requiredAccountsWithCentral: {
                    header: 'AWS account requirement calculation',
                    content:
                        'The total AWS account requirement is calculated based on the sum of the following components:\n- The number of AWS accounts required to support the specified participant forecast and team size `ceil(participant forecast / team size)`. `ceil(x)` or `⌈x⌉` is the smallest integer greater than or equal to `x`.\n- The number of central AWS accounts required. This requirement is determined by the workshop selected for the event.\n\n---\n\n### Example scenarios\n\n#### Event 1\n\n|||\n|:-|-:|\n|Participant forecast|10|\n|Team size|2|\n|Central account required|No|\n|Total AWS account requirement|5|\n\n#### Event 2\n\n|||\n|:-|-:|\n|Participant forecast|10|\n|Team size|3|\n|Central account required|No|\n|Total AWS account requirement|4|\n\n#### Event 3\n\n|||\n|:-|-:|\n|Participant forecast|10|\n|Team size|3|\n|Central account required|Yes|\n|Total AWS account requirement|5|',
                },
                requiredAccounts: {
                    header: 'AWS account requirement calculation',
                    content:
                        'The total AWS account requirement is calculated based on the number of AWS accounts required to support the specified participant forecast and team size `ceil(participant forecast / team size)`. `ceil(x)` or `⌈x⌉` is the smallest integer greater than or equal to `x`.\n\n---\n\n### Example scenarios\n\n#### Event 1\n\n|||\n|:-|-:|\n|Participant forecast|10|\n|Team size|2|\n|Total AWS account requirement|5|\n\n#### Event 2\n\n|||\n|:-|-:|\n|Participant forecast|10|\n|Team size|3|\n|Total AWS account requirement|4|',
                },
                regions: {
                    header: 'Regions',
                    content:
                        'Please choose up to 3 region(s) to run your event (us-east-1 is available by default). Please note that participants will be locked down to these regions as part of the event.',
                },
            },
            wizard: {
                values: {
                    eventTypes: {
                        production: {
                            label: 'Production event',
                            description: 'A live event with participants.',
                        },
                        test: {
                            label: 'Test event',
                            description:
                                'Used to validate content, conduct dry runs, etc.',
                        },
                    },
                    accountSources: {
                        workshopStudio: {
                            label: 'Workshop Studio',
                            description:
                                'Temporary AWS accounts provided by Workshop Studio.',
                        },
                        customerProvided: {
                            label: 'Customer provided',
                            description:
                                'Customer owned AWS Accounts. Workshop Studio will have no awareness or access to these accounts.',
                        },
                    },
                },
                steps: {
                    selectWorkshop: {
                        title: 'Select workshop',
                        description:
                            'Subset of event ready workshops that can be used to create events. Internally published workshops can not be used for events at this time.',
                    },
                    eventType: {
                        title: 'Event type',
                        alerts: {
                            productionEvent:
                                'Production events can only be run with published workshops.',
                        },
                        fields: {
                            eventType: {
                                label: 'Event type',
                            },
                            accountSource: {
                                label: 'Account source',
                                description:
                                    'Source of AWS accounts to be used by participants during the event.',
                                placeholder: 'Select an account source',
                                constraintCentralAccount:
                                    'Workshops configured with central account infrastructure must use Workshop Studio accounts',
                            },
                        },
                    },
                    eventDetails: {
                        title: 'Event details',
                    },
                    participantAllowlist: {
                        title: 'Participant allowlist',
                        fields: {
                            emailPatterns: {
                                label: 'Email patterns',
                                description:
                                    'A newline-delimited list of participant emails. You can provide suffixes with wildcards (*@example.com or *@example.*), or specific emails (user@example.com). All emails will be considered case insensitive.',
                                errors: {
                                    template: 'Line {0}: {1}',
                                    tooManyAts:
                                        'No more than one "@" is allowed in a pattern.',
                                    tooManyWildcards:
                                        'No more than one "*" is allowed in each side of an "@".',
                                    noWildcardExpression:
                                        'A pattern must not match all emails.',
                                },
                            },
                            allowAll: {
                                label: 'Allow all',
                                description:
                                    'By selecting this option, any participant with a valid event access code may join the event.',
                            },
                        },
                        alerts: {
                            eventStartPrerequisite:
                                'You will not be able to start your event without an allowlist value.',
                            noAllowAllForTestEvent:
                                '"Allow all" is not enabled for test events to ensure limited and controlled access. At least one email pattern is required for creation.',
                        },
                    },
                    reviewAndCreate: {
                        title: 'Review and create',
                    },
                },
            },
            sections: {
                facilitators: {
                    table: {
                        title: 'Additional facilitators',
                        emptyTitle: 'No additional facilitators',
                        emptyDescription:
                            'No additional facilitators specified for this event.',
                    },
                },
            },
            alerts: {
                setupFailed: {
                    header: 'Failed to load create event wizard',
                    content:
                        'Could not load event template or quotas information. Please try reloading the page later.',
                },
            },
            errors: {
                step: 'Step contains missing or invalid values.',
                insufficientAWSAccountQuota: 'Insufficient AWS account quota',
                requiredWorkshop: 'Must select workshop before continuing',
                badWorkshopData:
                    'The selected workshop contains outdated or missing content configuration and may not be used to create new events at this time. Please reach out to the content author for further assistance.',
            },
            fields: {
                startDateTime: {
                    errorMin: 'Must be at least 1 minute in the future',
                    errorMaxProd:
                        'Must not be more than 3 months in the future',
                    errorMaxTest: 'Must not be more than 3 days in the future',
                },
                deploymentRegions: {
                    label: 'Deployment region',
                    description:
                        'AWS region where your content will be run. Workshop Studio will replicate your content assets to the selected region. To improve customer experience, please choose a region closest to where your attendees are.',
                    placeholder: 'Select deployment region',
                },
                accessibleRegions: {
                    label: 'Accessible regions',
                    description:
                        'AWS regions that participants will have IAM permissions to during this event.',
                    placeholder: 'Select accessible region(s)',
                    constraintMinMaxAdditional:
                        'Select at least {0} additional {1} up to a maximum of {2}',
                    constraintMaxAdditional: 'Select up to {0} additional {1}',
                    constraintMinAdditional: 'Select {0} additional {1}',
                    errorMinAdditional: 'Must select {0} additional {1}',
                    errorMaxAdditional: 'Must not exceed {0} additional {1}',
                    errorMax: 'Must not exceed {0} {1}',
                },
                attendeeForecast: {
                    label: 'Participant forecast',
                    description:
                        'Total number of participants gauged by event registration data.',
                    constraintProduction: 'Maximum {0} participants',
                    constraintTest: 'Maximum {0} participants for test events',
                    updateForecastAlert:
                        'After the event is created, you can update the participant forecast up until provisioning is started.',
                    updateForecastAlertWithReservations:
                        'After the event is created, you can update the participant forecast up until provisioning is started. If your current AWS account quota is insufficient for your participant forecast, you can work with other facilitators to contribute their available quota to this event.',
                    errorWholeNumber: 'Must be a whole number',
                    errorMin: 'Must have at least 1 participant',
                    errorMaxTestEvent:
                        'Must not exceed {0} participants for a test event',
                    errorMaxProductionEvent: 'Must not exceed {0} participants',
                },
                teamSize: {
                    label: 'Team size',
                    description:
                        'Number of participants that can be mapped to a single team',
                    errorWholeNumber: 'Must be a whole number',
                    constraintText: 'Maximum {0} per team',
                    constraintTextFeatureFlag:
                        'Multi-participant teams coming soon!',
                    errorMin: 'Must be at least 1',
                    errorMax: 'Must not exceed {0}',
                    totalAccounts: {
                        label: '= {0} {1}',
                        unit: 'account',
                        popoverContent:
                            'AWS account quota required to support the specified participant forecast and team size: ceil(participant forecast / team size). Additional quota may be required if the event content requires a central AWS account.',
                    },
                },
                requiredAccounts: {
                    label: 'Total AWS accounts needed',
                    placeholder:
                        'Calculated based on participant forecast and team size',
                    infoLink: 'How is this calculated?',
                    constraintCentralAccount:
                        'ℹ️ A central AWS account is required and is included this amount',
                },
                initialQuotaContribution: {
                    label: 'AWS account quota reservation',
                    description:
                        'AWS account quota to reserve for this event. If you do not have sufficient quota for the participant forecast, you can collaborate with other facilitators to contribute their available quota to this event. Refer to the [Quotas view]({0}) for the latest details on your available AWS account quota.',
                    constraintMax: 'Maximum {0} account quota reservation',
                    errorWholeNumber: 'Must be a whole number',
                    errorMin: 'Must be a positive number',
                    errorMax: 'Must not exceed {0}',
                },
                duration: {
                    label: 'Duration',
                    description:
                        'Estimated event duration in hours. In the interest of frugality, consider setting the duration to accurately reflect the length of your event.',
                    constraint: 'Maximum {0} hours',
                    errorMin: 'Must be at least 1 hour',
                    errorMax: 'Must not exceed {0} hours',
                    errorWholeNumber: 'Must be a whole number',
                },
                schedule: {
                    startDate: {
                        label: 'Start date',
                        description:
                            'The local start date and time of the event. This cannot be updated once provisioning is started.',
                        required: 'Start date is required',
                        errorMustBeFuture: 'Start date must be in the future',
                    },
                    startTime: {
                        required: 'Start time is required',
                    },
                    endDate: {
                        label: 'End date',
                        description:
                            'The local end date and time of the event. This can be extended while the event is in progress up to a maximum total duration of {0}.',
                        required: 'End date is required',
                        errorMustBeFuture: 'End date must be in the future',
                        errorMustBeAfterStart:
                            'End date must be after start date',
                    },
                    endTime: {
                        required: 'End time is required',
                    },
                    timezone: {
                        label: 'Timezone',
                        description:
                            'The local timezone of the event. This cannot be updated once provisioning is started.',
                        placeholder: 'Select a timezone',
                    },
                    date: {
                        placeholder: 'YYYY/MM/DD',
                    },
                    time: {
                        placeholder: 'HH:MM',
                    },
                    period: {
                        placeholder: 'AM/PM',
                    },
                },
            },
        },
        quotas: {
            title: 'Quotas',
            alerts: {
                warning:
                    'Insufficient AWS account quota reservations for this event. Total reservations must completely fulfill the event participant forecast.',
            },
            info: {
                quotaReservationsTable: {
                    header: 'Quota reservations',
                    content:
                        "### Adding Reservations\n- Up to 5 event administrators and facilitators of this event (see [permissions tab](#PERMISSIONS)) can contribute AWS accounts from their monthly quota towards fulfilling the quota requirements. Any unconsumed reservations will be automatically released once the event is terminated.\n- You can only add your own reservations up to a maximum number of reservations allowed for test and production events respectively.\n\n### Releasing Reservations\n- Facilitators can only release a portion of their own reservation provided the total reservations remains at or above the participant forecast.\n- Facilitators should contact the event administrator if needing to release accounts in cases where the release will lead to a deficit of accounts for the event.\n- Event administrators can choose to modify the participant forecast via actions on the 'Overview' tab in order to facilitate the release of reserved accounts.",
                },
            },
            overview: {
                header: 'Overview',
                values: {
                    participantForecast: 'Participant forecast',
                    teamSize: 'Team size',
                    requiredQuota: 'Required quota',
                    totalReservations: 'Total reservations',
                },
                reservationProgressInfo: '{0} of {1} required quota fulfilled',
                reservationProgressComplete: 'Fulfilled ({0}%)',
                requiredQuotaInfo:
                    'ℹ️ A central AWS account is required and is included this amount',
            },
            table: {
                header: 'Quota reservations',
                description:
                    'AWS account quota reserved for this event. Each event may have up to 5 unique contributors, and reservations will be consumed in the displayed order.',
                emptyTitle: 'No quota reservations',
                emptyDescription:
                    'No quota reservations contributed for this event.',
                columns: {
                    email: 'Email',
                    reserved: 'Reserved',
                    consumed: 'Consumed',
                    actions: 'Actions',
                },
                actions: {
                    releaseReservation: 'Release reservation',
                    addReservation: 'Contribute reservation',
                },
                values: {
                    owner: 'owner',
                    you: 'you',
                },
                actionDisabledReasons: {
                    noPermissions:
                        'You can only release your own reservation. Administrator permissions are required to release reservations from other users.',
                    allConsumed:
                        'No unconsumed reservations available to release.',
                    noExcess:
                        'No excess, unconsumed reservations available to release.',
                    maxContribution: 'Maximum contribution reached.',
                },
            },
            addReservationModal: {
                header: 'Contribute reservation',
                description:
                    'Specify a reservation quantity to contribute from [your monthly AWS account quota](/quotas). This is added on top of your previous contribution, and does not replace any current reservation quantity.',
                fields: {
                    amount: {
                        label: 'Quantity to reserve',
                        errorMin: 'Must be at least 1',
                        errorMax: 'Must not exceed {0}',
                    },
                },
                primaryAction: 'Add reservation',
                alerts: {
                    quotaContributionInfoOwner:
                        'Other event facilitators can contribute their own AWS account quota towards this event. You will need to work with other event facilitators if you do not have enough AWS account quota for the scheduled month.',
                    quotaContributionInfoContributor:
                        'You can release a portion of your own reservation if the total reservation quantity exceeds the current participant forecast. Any unconsumed reservations will be automatically released once the event is terminated.',
                },
                notifications: {
                    success: {
                        content: 'Successfully added reservation.',
                    },
                    error: {
                        header: 'Failed to add reservation',
                    },
                },
            },
            releaseReservationModal: {
                header: 'Release reservation',
                fields: {
                    amount: {
                        label: 'Quantity to release{0}',
                        placeholder: 'Quantity to release',
                        constraintMax: 'Max {0} releasable',
                        errorMin: 'Must be at least 1',
                        errorMax: 'Must not exceed {0}',
                    },
                },
                primaryAction: 'Release reservation',
                notifications: {
                    success: {
                        content: 'Successfully released reservation.',
                    },
                    error: {
                        header: 'Failed to release reservation',
                    },
                },
                alerts: {
                    releaseConditions:
                        'Only excess, unconsumed reservations can be released. Released AWS account quota will be restored to the original month block.',
                },
            },
        },
        updateCapacity: {
            header: 'Update participant capacity',
            fields: {
                capacity: {
                    label: 'Participant capacity to add',
                    placeholder: 'Participant capacity to add',
                    constraint: 'Maximum {0} available capacity',
                    constraintZero: 'No capacity available to add',
                    errorWholeNumber: 'Must be a whole number',
                    errorMin: 'Must specify at least 1 additional capacity',
                    errorMax: 'Not enough available capacity',
                },
            },
            primaryAction: 'Update capacity',
            notifications: {
                success: {
                    content: 'Successfully added capacity.',
                },
                error: {
                    header: 'Failed to add capacity',
                },
            },
        },
        eventDetails: {
            badges: {
                test: 'Test event',
                production: 'Production event',
                wsAccounts: 'AWS accounts provided',
                needsAccounts: 'Needs accounts',
            },
            failedProvisionEventAlert: {
                message:
                    'Event provisioning failed. Please follow the guide to [troubleshoot your event]({0}).',
            },
            timeline: {
                title: 'Timeline',
                popover: {
                    headerError: 'Error details',
                    labelErrorName: 'Name',
                    labelErrorCode: 'Code',
                    labelHttpStatus: 'HTTP status code',
                },
                provisionStartWarning:
                    'The event will be automatically cancelled if it is not provisioned within {0} days after the scheduled start date.',
                step: {
                    scheduled: {
                        title: 'Scheduled',
                        description: 'Created {0} ({1})',
                    },
                    provisioning: {
                        title: 'Provisioning',
                        description: '⚠️ Provision by {0} ({1})',
                    },
                    provisioningStarting: {
                        title: 'Provisioning',
                        description: 'Starting provisioning...',
                    },
                    provisioningStartingFailed: {
                        title: 'Provisioning',
                        description: 'Failed to start provisioning',
                    },
                    provisioningInProgressTeamAccounts: {
                        title: 'Provisioning team accounts...',
                        description: '{0}/{1} deployed successfully',
                    },
                    provisioningInProgressCentralAccount: {
                        title: 'Provisioning central account...',
                        description: '{0}/{1} deployed successfully',
                    },
                    provisioningInProgress: {
                        title: 'Provisioning...',
                        description: 'Started {0} ({1})',
                    },
                    accountProvisionFailed: {
                        title: 'Provisioning',
                        description: 'Failed to complete',
                    },
                    accountProvisionFailedWSProvided: {
                        title: 'Provisioning',
                        description: '{0}/{1} deployed successfully',
                    },
                    accountProvisionSucceededWSProvided: {
                        title: 'Provisioned',
                        description: '{0}/{1} deployed successfully',
                    },
                    accountProvisionSucceeded: {
                        title: 'Provisioned',
                        description: 'Completed {0} ({1})',
                    },
                    event: {
                        title: 'Event start',
                        description: 'Not started',
                        descriptionAutoStart:
                            '⏳ Event auto-starting {0} ({1})',
                    },
                    eventStarting: {
                        title: 'Event start',
                        description: 'Starting event...',
                    },
                    eventStartingFailed: {
                        title: 'Event start',
                        description: 'Failed to start event',
                    },
                    eventStartSucceeded: {
                        title: 'Event in progress',
                        description: 'Started {0} ({1})',
                    },
                    eventStartFailed: {
                        title: 'Event start',
                        description: 'Failed to start event',
                    },
                    eventResumeSucceeded: {
                        title: 'Event in progress',
                        description: 'Resumed {0} ({1})',
                    },
                    eventResumeFailed: {
                        title: 'Event paused',
                        description: 'Failed to resume',
                    },
                    eventCompleted: {
                        title: 'Event completed',
                        description: 'Completed {0} ({1})',
                    },
                    eventPauseInProgress: {
                        title: 'Event in progress',
                        description: 'Pausing event...',
                    },
                    eventPauseStartingFailed: {
                        title: 'Event in progress',
                        description: 'Failed to pause',
                    },
                    eventPauseSucceeded: {
                        title: 'Event paused',
                        description: 'Paused {0} ({1})',
                    },
                    eventPauseFailed: {
                        title: 'Event in progress',
                        description: 'Failed to pause',
                    },
                    eventResuming: {
                        title: 'Event paused',
                        description: 'Resuming event...',
                    },
                    endingEvent: {
                        title: 'Event in progress',
                        description: 'Ending event...',
                    },
                    endingEventNeverStarted: {
                        title: 'Event start',
                        description: 'Canceling event...',
                    },
                    endingEventFailed: {
                        title: 'Event in progress',
                        description: 'Failed to end',
                    },
                    termination: {
                        title: 'Termination',
                        description: 'Not started',
                        descriptionAutoStart: '⏳ Auto-terminating {0} ({1})',
                    },
                    terminationInProgress: {
                        title: 'Terminating...',
                        description: 'Started {0} ({1})',
                    },
                    terminationFailed: {
                        title: 'Termination',
                        description: 'Failed to complete',
                    },
                    terminationSucceeded: {
                        title: 'Terminated',
                        description: 'Completed {0} ({1})',
                    },
                    terminationCanceled: {
                        title: 'Canceled',
                        description: 'Canceled {0} ({1})',
                    },
                },
                footer: {
                    wsProvided: {
                        scheduled: {
                            header: '🗓️ Next up: Provision your event on time',
                            contentP1:
                                'As the event facilitator, you are responsible for ensuring all team AWS accounts are provisioned before the start of the event. Workshop Studio provisions team AWS accounts in batches of 10 and deployment times may vary based on the type and number of resources defined in the workshop CloudFormation template. For example, an event with 63 AWS accounts will require 7 batches for provisioning. If the average deployment time of the workshop CloudFormation template is 10 minutes, you should expect a minimum total provisioning time of 70 minutes.',
                            contentP2:
                                'Provisioning your event will allow participants to join your event; however, they will not be able to access the content or the associated AWS account until you start the event.',
                        },
                        provisionInProgress: {
                            header: 'ℹ️ Provisioning your event',
                            content:
                                'Infrastructure from the associated content is being deployed to the AWS accounts. Once provisioned, participants will be able to join your event; however, they will not be able to access the content or the associated AWS account until you start the event.',
                        },
                        provisionFailed: {
                            header:
                                'ℹ️ Actioning provisioning failures and next steps',
                            content:
                                'For teams that failed to deploy, log into the AWS accounts to review errors in the CloudFormation console. Teams that deployed successfully can still be used and accessed by participants. You can either choose to start your event with the successfully deployed teams, or you can terminate the event and remediate any content related issues before recreating. Provisioning failures are usually attributed to content related issues, and you may need to reach out to the content author for further assistance.',
                        },
                        provisionSuccess: {
                            header: '🗓️ Next up: Start your event',
                            content:
                                'Infrastructure from the associated content has been deployed to the AWS accounts. Participants will be able to join your event; however, they will not be able to access the content or the associated AWS account until you start the event.',
                        },
                        eventInProgress: {
                            header: 'ℹ️ Pausing or terminating your event',
                            content:
                                'While the event is in progress, you can choose to pause or terminate your event anytime. Pausing an event will remove access to the content and prevent subsequent logins to the associated AWS account until you resume the event. You may choose to terminate your event early if you finish before the scheduled end time. We strongly encourage you to terminate your event as soon as possible to help minimize AWS account related expenses and potential security risks.',
                        },
                        eventPaused: {
                            header: 'ℹ️ Resuming or terminating your event',
                            content:
                                'While the event is paused, participants will not be able to access the content and will not be able to log into the associated AWS account again until you resume the event. You may choose to terminate your event early if you finish before the scheduled end time. We strongly encourage you to terminate your event as soon as possible to help minimize AWS account related expenses and potential security risks.',
                        },
                    },
                    customerProvided: {
                        scheduled: {
                            header: '🗓️ Next up: Provision your event on time',
                            content:
                                'Provisioning your event will allow participants to join your event; however, they will not be able to access the content until you start the event.',
                        },
                        provisionInProgress: {
                            header: 'ℹ️ Provisioning your event',
                            content:
                                'Once provisioned, participants will be able to join your event; however, they will not be able to access the content until you start the event.',
                        },
                        provisionSuccess: {
                            header: '🗓️ Next up: Start your event',
                            content:
                                'Participants can join your event now; however, they will not be able to access the content until you start the event.',
                        },
                        eventInProgress: {
                            header: 'ℹ️ Pausing or terminating your event',
                            content:
                                'While the event is in progress, you can choose to pause or terminate your event anytime. Pausing an event will remove access to the content until you resume the event. You may choose to terminate your event early if you finish before the scheduled end time.',
                        },
                        eventPaused: {
                            header: 'ℹ️ Resuming or terminating your event',
                            content:
                                'While the event is paused, participants will not be able to access the content until you resume the event. You may choose to terminate your event early if you finish before the scheduled end time.',
                        },
                    },
                },
            },
            overview: {
                title: 'Overview',
                sections: {
                    details: {
                        title: 'Event details',
                        values: {
                            primaryFacilitator: 'Primary facilitator',
                            engagementType: 'Engagement type',
                            opportunityId: 'Opportunity/Campaign ID',
                            geo: 'Geo',
                            modality: 'Modality',
                            eventId: 'Event ID',
                            description: 'Description',
                            accessCode: 'Access code',
                        },
                    },
                    configuration: {
                        title: 'Event configuration',
                        values: {
                            startDate: 'Start date',
                            startTime: 'Start time',
                            duration: 'Duration',
                            participantForecast: 'Participant forecast',
                            teamSize: 'Team size',
                            deploymentRegions: 'Deployment regions',
                            accessibleRegions: 'Accessible regions',
                        },
                        actions: {
                            updateSchedule: 'Update schedule',
                            updateParticipantForecast:
                                'Update participant forecast',
                            updateCapacity: 'Update participant capacity',
                            updateRegions: 'Update regions',
                        },
                        disabledReasons: {
                            updateCapacityProvisionInProgress:
                                'Participant capacity cannot be updated while provisioning is in progress.',
                            updateCapacityDeploymentInProgress:
                                'Participant capacity cannot be updated while deployment is in progress.',
                            updateCapacityProvisionFailed:
                                'Participant capacity cannot be updated if provisioning failed.',
                        },
                    },
                },
            },
            contentDetails: {
                title: 'Content details',
                actions: {
                    viewWorkshop: 'View workshop',
                },
                values: {
                    workshopId: 'Workshop ID',
                    workshopBuildId: 'Workshop build ID',
                    title: 'Title',
                    complexityLevel: 'Complexity level',
                    topics: 'Topics',
                    industryVertical: 'Industry vertical',
                    featuredAWSServices: 'Featured AWS services',
                },
            },
            terminateEventDisabledReasons: {
                provisionInprogress:
                    'Event cannot be terminated while provisioning is in progress.',
            },
            allowlistRequiredDisabledReasons: {
                provisionEvent:
                    'Allowlist value is required to provision the event.',
                startEvent: 'Allowlist value is required to start the event.',
            },
            permissions: {
                notifications: {
                    updateSuccess: 'Successfully updated event facilitators.',
                },
            },
            actionsDropdown: {
                shareEventDetails: 'Share details',
                provision: 'Provision',
                cancel: 'Cancel',
                terminate: 'Terminate',
                pause: 'Pause',
                resume: 'Resume',
            },
            actions: {
                startEvent: 'Start event',
                startingEvent: 'Starting event...',
            },
            notificationCancelEvent: {
                loading: 'Canceling event...',
                success: 'Event successfully cancelled.',
            },
            notificationAccountsProvisioning: {
                loading: 'Starting accounts provisioning...',
                success: 'Successfully started provisioning accounts.',
            },
            notificationStartEvent: {
                loading: 'Starting event...',
            },
            notificationEventInProgress: {
                header: 'Event in progress',
                content: 'This event will be automatically terminated in {0}.',
            },
            notificationPausingEvent: {
                header: 'Pausing event...',
                content: 'This event will be automatically terminated in {0}.',
            },
            notificationTerminatingEvent: {
                loading: 'Ending event...',
                success: 'Successfully ended event.',
            },
            notificationEventPaused: {
                header: 'Event paused',
                content: 'This event will be automatically terminated in {0}.',
                action: 'Resume',
                actionLoading: 'Resuming...',
            },
        },
        confirmProvisionModal: {
            header: 'Confirm early provisioning',
            actionProvision: 'Start provisioning',
            actionCancel: 'Cancel',
            content:
                'The event will automatically start **48 hours after provisioning is started** regardless of the specified scheduled start time, and will terminate once the scheduled duration is reached.',
        },
        terminateEventModal: {
            header: 'Terminate event',
            actionTerminate: 'Terminate',
            content: {
                workshopStudio: {
                    notStarted:
                        'Terminating the event is an irreversible action. This will immediately end the event and start cleanup. All AWS accounts provisioned for this event are consumed and will not be restored to your quota.',
                    inProgress:
                        'Terminating the event is an irreversible action. This will immediately end the event and all participants will be logged out of the dashboard.',
                },
                customerProvided: {
                    notStarted:
                        'Terminating the event is an irreversible action. This will immediately end the event and start cleanup.',
                    inProgress:
                        'Terminating the event is an irreversible action. This will immediately end the event and all participants will be logged out of the dashboard.',
                },
                terminateTeamWarning: {
                    header: 'Terminating unutilized teams',
                    content:
                        'If you are terminating the event due to team deployment failures, do not terminate the event until all available troubleshooting methods to retry deployment have been exhausted, including reaching out to [Workshop Studio support]({0}). Depending on the size of your event, there may not be sufficient time to completely redeploy your event.',
                    acknowledgementField: {
                        label:
                            'I acknowledge the conditions of terminating this event',
                    },
                },
            },
        },
        cancelEventModal: {
            header: 'Cancel event',
            actionCancelEvent: 'Cancel event',
            actionCancel: 'Keep event',
            content:
                'Canceling the event is an irreversible action. AWS account quota for this event will be restored to the original month the quota was reserved from, and quota restored to a past month cannot be utilized for future events. Proceed with canceling the event?',
        },
        pauseEventModal: {
            header: 'Pause event',
            actionPause: 'Pause event',
            content: {
                workshopStudio:
                    'Pausing an event will prevent attendees from interacting with the dashboard until the event is restarted. This does not pause the run time of your event, and participants will continue to have access to the underlying AWS account.',
                customerProvided:
                    'Pausing an event will prevent attendees from interacting with the dashboard until the event is restarted. This does not pause the run time of your event.',
            },
        },
        shareEventModal: {
            header: 'Share event details',
            metadata: {
                name: 'Event name',
                description: 'Description',
                startDate: 'Start date',
                startTime: 'Start time',
                joinLink: 'Join link',
                duration: 'Duration',
                accessCode: 'Access code',
            },
        },
        updateParticipantForecastModal: {
            header: 'Update participant forecast',
            actionUpdate: 'Update',
            updateInfo:
                'Team size cannot be modified once provisioning is started.',
            successNotification: 'Participant forecast updated successfully.',
        },
        updateEventRegionsModal: {
            header: 'Update event regions',
            actionUpdate: 'Update',
        },
        updateEventScheduleModal: {
            header: 'Update schedule',
            actionUpdate: 'Update',
            notifications: {
                success: 'Event schedule updated successfully.',
            },
        },
        accessAWSAccountModal: {
            header: 'Access AWS account',
            participantRole: {
                title: 'Participant role',
                message:
                    'Do not share or distribute these credentials with anyone. Participants may only access Workshop Studio AWS accounts with the credentials provided from the event dashboard. Sharing or distributing these credentials enables participants to bypass the Terms & Conditions of the platform.',
            },
            opsRole: {
                title: 'Ops role',
                message:
                    'Do not share or distribute these credentials with anyone. This is an administrative login to be used only by you for debugging purposes.',
            },
            infoMessage:
                'Only use {0} as your region, unless otherwise directed by the workshop author. You can optionally set the `AWS_DEFAULT_REGION` environment variable to one of the allowed regions to set the default region for CLI commands. This value can be overridden with the `--region` command line parameter.',
        },
        regionCategories: {
            default: 'System default',
            required: 'Required',
            recommended: 'Recommended',
            deploymentRegion: 'Required: Deployment region',
        },
    },
    table: {
        noMatches: 'No matches',
        noMatchesDescription: 'We can not find a match.',
        clearFilters: 'Clear filters',
        clearAllFilters: 'Clear all filters',
        selectVisibleColumns: 'Select visible columns',
        properties: 'Properties',
        placeholder: 'Search by keyword',
        operationAnd: 'and',
        operationNotAnd: 'and not',
        operationOr: 'or',
        operationNotOr: 'or not',
        groupValues: 'Values',
        filteringEmpty: 'No items found',
        filtering: 'Filter items',
    },
    collectionPreferences: {
        contentDensity: {
            label: 'Compact mode',
            description:
                'Select to display content in a denser, more compact mode',
        },
        stickyColumns: {
            title: 'Sticky columns',
            description:
                'Keep leading column visible while horizontally scrolling the table content.',
            noneOptionLabel: 'None',
            firstOptionLabel: 'First column',
        },
    },
    propertyFilter: {
        filteringAriaLabel: 'your choice',
        dismissAriaLabel: 'Dismiss',
        filteringPlaceholder: 'Search',
        groupValuesText: 'Values',
        groupPropertiesText: 'Properties',
        operatorsText: 'Operators',
        operationAndText: 'and',
        operationOrText: 'or',
        operatorLessText: 'Less than',
        operatorLessOrEqualText: 'Less than or equal',
        operatorGreaterText: 'Greater than',
        operatorGreaterOrEqualText: 'Greater than or equal',
        operatorContainsText: 'Contains',
        operatorDoesNotContainText: 'Does not contain',
        operatorEqualsText: 'Equals',
        operatorDoesNotEqualText: 'Does not equal',
        editTokenHeader: 'Edit filter',
        propertyText: 'Property',
        operatorText: 'Operator',
        valueText: 'Value',
        cancelActionText: 'Cancel',
        applyActionText: 'Apply',
        allPropertiesLabel: 'All properties',
        tokenLimitShowMore: 'Show more',
        tokenLimitShowFewer: 'Show fewer',
        clearFiltersText: 'Clear filters',
    },
    item: {
        singular: 'item',
        plural: 'items',
    },
    match: {
        singular: 'match',
        plural: 'matches',
    },
    pageSize: 'Page size',
    refreshData: 'Refresh data',
    loading: 'Loading...',
    nextPage: 'Next page',
    previousPage: 'Previous page',
    pageLabel: (pageNumber: number) => `Page ${pageNumber} of all pages`,
    wrapLines: 'Wrap lines',
    wrapLinesDescription: 'Check to see all the text and wrap the lines',
    deleteConfirmationFieldError: 'Must be typed exactly and is case sensitive',
    confirm: 'Confirm',
    cancel: 'Cancel',
    copyJoinLink: 'Copy one-click join link',
    update: 'Update',
    cancelling: 'Cancelling...',
    preferences: 'Preferences',
    states: {
        all: 'All states',
        scheduled: 'Scheduled',
        notStarted: 'Not started',
        notApplicable: 'Not applicable',
        notProvisioned: 'Not provisioned',
        canceled: 'Canceled',
        provisionInProgress: 'Provisioning',
        provisionFailed: 'Provisioning failed',
        provisionSuccess: 'Provisioned',
        deploymentInProgress: 'Deploying',
        deploymentCentralInProgress: 'Central account deploying',
        deploymentCentralFailed: 'Central account deployment failed',
        deploymentFailed: 'Deployment failed',
        deploymentHalted: 'Deployment halted',
        deploymentSuccess: 'Deployed',
        startEventInProgress: 'Starting event',
        startEventFailed: 'Start event failed',
        startEventSuccess: 'Event in progress',
        pauseEventInProgress: 'Pausing event',
        pauseEventFailed: 'Pause event failed',
        pauseEventSuccess: 'Event paused',
        terminateInProgress: 'Terminating',
        terminateFailed: 'Terminating failed',
        terminateSuccess: 'Terminated',
        failed: 'Failed',
    },
    within3Days: 'Within 3 days',
    withinAWeek: 'Within a week',
    within2Weeks: 'Within 2 weeks',
    viewAll: 'View all',
    userMenu: {
        sections: {
            quotas: {
                title: 'Quotas',
                currentAWSAccountQuota: 'Current month AWS accounts',
                nextAWSAccountQuota: 'Next month AWS accounts',
                currentContentQuota: 'Workshops',
                quotaValueLabel: 'Available/Total',
            },
        },
    },
    feedbackMenu: {
        title: 'Support',
    },
    myQuotas: {
        title: 'My quotas',
        breadcrumbRouteText: 'My quotas',
        toolsContent: {
            header: 'My quotas',
            descriptionParagraphOne:
                'Here you can see an overview of your quotas for the various resources within Workshop Studio.',
            descriptionParagraphTwo:
                'A quota refers to a quantity-based limit such as the maximum number of a type of resource a customer can have. For example, a quota of 50 workshop resources means that a customer can have no more than 50 workshop resources at any given time.',
        },
        workshopQuota: {
            containerTitle: 'Workshops',
            quotaUsed: 'Quota used',
            maxQuota: 'Max quota',
            toolsContent: {
                header: 'Workshops quota',
                description:
                    'Each user has a maximum number of workshops they can create. Each time you create a workshop, this deducts from your overall quota for the total number of workshops you can create. If you schedule a workshop for deletion, and after the waiting period it is deleted, this then adds one back to your quota.',
                note:
                    'Note that this quota is for workshops created, not just published workshops.',
            },
        },
        eventQuota: {
            containerTitle: 'AWS accounts',
            actions: {
                transferQuota: 'Transfer quota',
                transferQuotaFromEE: 'Transfer from Event Engine',
                transferQuotaFromEEDescription:
                    'Transfer available Event Engine quota to Workshop Studio.',
                transferQuotaToEE: 'Transfer to Event Engine',
                transferQuotaToEEDescription:
                    'Transfer available Workshop Studio quota to Event Engine.',
            },
            transferQuotaModal: {
                actionPrimary: 'Transfer',
                alerts: {
                    error: 'Failed to transfer quota: {0}',
                    info:
                        'Transfers apply to the current Workshop Studio quota month block. The requested transfer may be reduced or rejected if the quantity exceeds available Workshop Studio or Event Engine limits.',
                },
                fields: {
                    quantity: {
                        label: 'Transfer quantity',
                        placeholder: 'Transfer quantity',
                        constraintMax: 'Max {0} per request',
                        errorMin: 'Must be at least 1',
                        errorMax: 'Must not exceed {0}',
                        errorWholeNumber: 'Must be a whole number',
                    },
                },
                notifications: {
                    successTransferFromEE:
                        'Successfully transferred {0} quota from Event Engine to Workshop Studio.',
                    successTransferToEE:
                        'Successfully transferred {0} quota from Workshop Studio to Event Engine.',
                    successTransferReducedQuantity:
                        'The requested amount ({0}) was reduced due to Event Engine limits.',
                    successTransferToEEEmptyQuantity:
                        'No quota was transferred from Workshop Studio, maximum limit reached in Event Engine.',
                    successTransferFromEEEmptyQuantity:
                        'No quota was transferred from Event Engine, maximum limit reached in Workshop Studio.',
                },
            },
            toolsContent: {
                header: 'AWS account quotas',
                descriptionParagraphOne:
                    'Each user receives an allotment of AWS accounts per month that they can use to run their events. These refill every month based on the provided quota. When an account is used, you do not get that account back and that consumes an account from your monthly quota.',
                descriptionParagraphTwo: 'Example:',
                descriptionParagraphThree:
                    'You have a quota of 100 accounts per month. You run an event with 50 accounts. Once this event is initialized, you have consumed 50 accounts. At this point, you have 50 accounts remaining for the month. You will be refilled back to your quota of 100 accounts in the following month. Accounts do not roll over from month to month, so in this case, the following month, you would have 100 accounts, not 150. The AWS account quotas chart provides details as to how many accounts you have consumed, or are planning to consume, and how many accounts you have left in your quota.',
            },
            awsAccountsBarChart: {
                series: {
                    quotaAvailable: 'Quota available',
                    quotaConsumed: 'Quota consumed',
                    quotaAllotted: 'Allotted quota',
                    quotaAllocated: 'Quota reserved (refundable)',
                },
                filterLabel: 'Filter displayed data',
                filterPlaceholder: 'Filter data',
                filterSelectedAriaLabel: 'selected',
                legendAriaLabel: 'Legend',
                chartAriaRoleDescription: 'line chart',
                ariaLabel: 'AWS accounts',
                errorText: 'Error loading data.',
                loadingText: 'Loading AWS accounts',
                recoveryText: 'Retry',
                currentMonthLabel: '(Current)',
                yTitle: 'Quota',
                empty: {
                    title: 'No data available',
                    description: 'There is no data available',
                },
                noMatch: {
                    title: 'No matching data',
                    description: 'There is no matching data to display',
                },
            },
        },
        table: {
            relatedEvents: {
                title: 'Related events',
                errorTitle: 'Problem getting related events',
                emptyTitle: 'No related events',
                emptyDescription: 'No related events for this time block.',
                columnHeaders: {
                    title: 'Title',
                    startDate: 'Start date',
                    startTime: 'Start time',
                    quantityReserved: 'Reserved',
                    quantityConsumed: 'Consumed',
                    state: 'State',
                },
                propertyFilterGroupValues: {
                    title: 'Title values',
                    state: 'State values',
                    quantityReserved: 'Reserved values',
                    quantityConsumed: 'Consumed values',
                },
            },
        },
    },
};

export default i18nStrings;
