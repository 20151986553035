import StatusIndicator, {
    StatusIndicatorProps,
} from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';

interface StackDeploymentStatusConfig {
    statusKeyword: string;
    type: StatusIndicatorProps.Type;
}

export interface StackDeploymentStatusProps {
    status?: string;
}

const DEPLOYMENT_STATUSES: StackDeploymentStatusConfig[] = [
    {
        statusKeyword: 'rollback',
        type: 'error',
    },
    {
        statusKeyword: 'failed',
        type: 'error',
    },
    {
        statusKeyword: 'success',
        type: 'success',
    },
    {
        statusKeyword: 'complete',
        type: 'success',
    },
    {
        statusKeyword: 'in_progress',
        type: 'info',
    },
];

const StackDeploymentStatus = ({ status = '' }: StackDeploymentStatusProps) => {
    const statusIndicatorProps = DEPLOYMENT_STATUSES.find(({ statusKeyword }) =>
        status.toLowerCase().includes(statusKeyword)
    );
    const type = statusIndicatorProps?.type || 'info';

    return (
        <StatusIndicator type={type} data-type={type}>
            {status}
        </StatusIndicator>
    );
};

export default StackDeploymentStatus;
