import { PERSONA } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Button from '@amzn/awsui-components-react/polaris/button';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import React from 'react';

import { i18nStrings } from '../../../../../constants';
import { ViewInCloudFormationProps } from './types';

const ViewInCloudFormation = ({
    stackArn,
    eventId,
    accountType,
    resourceId,
}: ViewInCloudFormationProps) => {
    return (
        <Button
            href={`${routes.events}/${eventId}/${accountType}/${resourceId}/${
                routeTokens.login
            }/${PERSONA.WS_OPS}?destination=${encodeURIComponent(
                `/cloudformation/home${
                    stackArn
                        ? `#/stacks/stackinfo?stackId=${encodeURIComponent(
                              stackArn
                          )}`
                        : ''
                }`
            )}`}
            iconName="external"
            iconAlign="right"
            target="_blank">
            {
                i18nStrings.events.teams.teamStacks.viewInCloudFormationAction
                    .label
            }
        </Button>
    );
};

export default ViewInCloudFormation;
