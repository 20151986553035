import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useLazyRequest, useModal } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import {
    ParticipantAllowlistFieldName,
    ParticipantAllowlistFields,
    ParticipantAllowlistFormatFormData,
    ParticipantAllowlistFormatRequestData,
} from 'pages/Events/CreateEvent/steps/ParticipantAllowlist';
import { getSchema } from 'pages/Events/CreateEvent/steps/ParticipantAllowlist/schema';
import { SchemaType } from 'pages/Events/CreateEvent/steps/ParticipantAllowlist/types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EEEvents, ErrorMessage } from 'services';

import { i18nStrings } from '../../../../../../../../../constants';
import { DismissState, EditAllowlistModalProps, NotificationID } from './types';

const EditAllowlistModal = ({
    eventData,
    isVisible,
    onDismiss,
}: EditAllowlistModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const [updateEventAllowlist, { isLoading, isError, data }] = useLazyRequest<
        typeof EEEvents.updateEventAllowlist,
        AWSError
    >(EEEvents.updateEventAllowlist, {});
    const [validateOnChange, setValidateOnChange] = useState(false);
    const onSubmit = useCallback(
        async (values: SchemaType) => {
            const allowlistAllowAll =
                values[ParticipantAllowlistFieldName.ALLOW_ALL];

            await updateEventAllowlist({
                eventId: eventData.eventId,
                allowlist:
                    !allowlistAllowAll &&
                    values[ParticipantAllowlistFieldName.EMAIL_PATTERNS]
                        ? ParticipantAllowlistFormatRequestData.getEmailPatterns(
                              values[
                                  ParticipantAllowlistFieldName.EMAIL_PATTERNS
                              ]!
                          )
                        : [],
                allowlistAllowAll,
            });
        },
        [eventData]
    );
    const handleCancel = useCallback(() => {
        onDismiss(DismissState.CANCEL);
    }, [onDismiss]);
    const formik = useFormik<SchemaType>({
        validationSchema: getSchema({ emailPatterns: { isRequired: true } }),
        validateOnBlur: false,
        validateOnChange,
        initialValues: {
            [ParticipantAllowlistFieldName.ALLOW_ALL]:
                eventData.allowlistAllowAll,
            [ParticipantAllowlistFieldName.EMAIL_PATTERNS]: ParticipantAllowlistFormatFormData.getEmailPatterns(
                eventData.allowlist
            ),
        },
        onSubmit,
    });
    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, [formik]);
    const { modalComponent } = useModal({
        isVisible,
        onDismiss: handleCancel,
        modalHeader: i18nStrings.events.participantAllowlist.updateTitle,
        actions: {
            tertiary: {
                text: i18nStrings.cancel,
                onClick: handleCancel,
            },
            primary: {
                text: i18nStrings.update,
                disabled: !formik.dirty || !formik.isValid,
                loading: isLoading,
                onClick: onPrimaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    autoComplete="chrome-off"
                    noValidate>
                    <Form>
                        <SpaceBetween direction="vertical" size="l">
                            <ParticipantAllowlistFields
                                requireEmailPatterns={
                                    eventData.type === EVENT_TYPE.TEST
                                }
                            />
                            <Alert>
                                {
                                    i18nStrings.events.participantAllowlist
                                        .alerts.update
                                }
                            </Alert>
                        </SpaceBetween>
                    </Form>
                </form>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        showNotification(
            i18nStrings.events.participantAllowlist.notifications.updateSuccess
                .content,
            {
                id: NotificationID.UPDATE_RESPONSE,
                type: 'success',
                clearStack: false,
            }
        );
        onDismiss(DismissState.UPDATE_SUCCESS);
    }, [data]);

    useEffect(() => {
        if (!isError) {
            return;
        }

        showNotification(ErrorMessage.getMessage(isError), {
            id: NotificationID.UPDATE_RESPONSE,
            type: 'error',
            clearStack: false,
        });
        onDismiss(DismissState.UPDATE_ERROR);
    }, [isError]);

    return modalComponent;
};

export default EditAllowlistModal;
