// Base interval time in milliseconds
export const retryBackoff = {
    interval: 1000,
    maxRetries: 5,
    exponential: 3,
};

/**
 * Gets time in milliseconds to wait until next retry
 * @param {number} retryCount
 * @return {number}
 */
const exponentialBackoff = (retryCount: number) =>
    retryBackoff.interval * Math.pow(retryBackoff.exponential, retryCount);

export default exponentialBackoff;
