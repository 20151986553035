import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { EventMatrix, PermissionsTable } from '@amzn/event-engine-js-utils';
import { notificationsState } from 'atoms';
import HelpPanel from 'components/HelpPanel';
import InfoLink from 'components/InfoLink';
import i18nStrings from 'constants/i18n-strings';
import { useUserContext } from 'contexts';
import React, { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import EditFacilitators from './components/EditFacilitators';
import {
    columnDefinitions,
    facilitatorRolesMapping,
    onFilterEventPermissions,
} from './config';

export interface FacilitatorsProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
    refetchGetEventLoading: boolean;
    refetchGetEventError: boolean;
}

const Facilitators: React.FC<FacilitatorsProps> = ({
    event,
    refetchGetEvent,
    refetchGetEventLoading,
    refetchGetEventError,
}: FacilitatorsProps) => {
    // Only show refreshing UI if user triggers the refresh action
    // manually since it's a bit jarring to see the table flip to
    // a refreshing state without any interaction.
    const [isRefreshing, setIsRefreshing] = useState(false);
    const isEventActive = useMemo(
        () =>
            EventMatrix.isEventActive({
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            }),
        [event]
    );
    const { data: user } = useUserContext();
    const onRefetch = useCallback(async () => {
        setIsRefreshing(true);
        await refetchGetEvent();
        setIsRefreshing(false);
    }, []);

    const setNotifications = useSetRecoilState(notificationsState);

    const permissions = event.permissions || [];

    const isSupportStaff: boolean = permissions.some((p) => {
        return (
            p.principal === user?.userId && p.roles.includes('support_staff')
        );
    });

    return (
        <PermissionsTable
            columnDefinitions={columnDefinitions}
            onFilter={onFilterEventPermissions}
            permissions={permissions}
            variant="event"
            id={event.eventId}
            setNotifications={setNotifications}
            onRefetch={onRefetch}
            refetchError={
                refetchGetEventError
                    ? 'Unable to retrieve facilitators, please try again'
                    : ''
            }
            refetchIsLoading={
                !event.permissions ? refetchGetEventLoading : isRefreshing
            }
            i18nStrings={{
                tableTitle: {
                    view: 'Additional facilitators',
                    edit: 'Manage additional facilitators',
                },
                editCtaText: 'Manage facilitators',
                emptyPermissions: {
                    title: 'No additional facilitators',
                    description:
                        'No facilitators have been added for this event',
                },
                permissionRetrievalErrorMessage: {
                    title: 'Problem getting event facilitators',
                    description:
                        'There was a problem getting event facilitators, please try again',
                },
            }}
            EditComponent={EditFacilitators}
            allPermissionsData={{
                permissionsList: permissions,
                eventId: event.eventId,
                isEventTerminated: !isEventActive,
            }}
            infoComponent={
                <InfoLink
                    toolsContent={
                        <HelpPanel
                            header={
                                i18nStrings.events.create.helpText.facilitators
                                    .header
                            }>
                            {
                                i18nStrings.events.create.helpText.facilitators
                                    .content
                            }
                        </HelpPanel>
                    }
                />
            }
            disableEdit={isSupportStaff}
            permissionMapping={facilitatorRolesMapping}
        />
    );
};

export default Facilitators;
