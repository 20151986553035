import HelpPanelBase, {
    HelpPanelProps as HelpPanelBaseProps,
} from '@amzn/awsui-components-react/polaris/help-panel';
import { BaseMarkdown } from '@amzn/event-engine-js-utils';
import React from 'react';

import styles from './HelpPanel.module.scss';

export interface HelpPanelProps extends HelpPanelBaseProps {}

/**
 * Polaris HelpPanel with markdown content rendering
 *
 * @param {HelpPanelProps} props
 * @returns
 */
export const HelpPanel = ({ header, children }: HelpPanelProps) => (
    <HelpPanelBase header={<h2>{header}</h2>}>
        {typeof children === 'string' ? (
            <BaseMarkdown className={styles.markdown} linkTarget="_blank">
                {children}
            </BaseMarkdown>
        ) : (
            <div>{children}</div>
        )}
    </HelpPanelBase>
);

export default HelpPanel;
