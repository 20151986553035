import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    EventMatrix,
    formatString,
    useInterval,
} from '@amzn/event-engine-js-utils';
import React, { useCallback, useState } from 'react';
import getDurationFromNowUtil from 'utils/date-fns/get-duration-from-now';

import { i18nStrings } from '../../../../../constants';

export const DEFAULT_UPDATE_INTERVAL = 1000;

export interface EventDurationMessageProps {
    event: EventEngineEventsService.EventData;
    isIntervalPaused?: boolean;
}

const EventDurationMessage = ({
    event: { nextActionAt, stateRuntime, stateInfrastructure },
    isIntervalPaused,
}: EventDurationMessageProps) => {
    const getDurationFromNow = (toDate?: Date) =>
        toDate ? getDurationFromNowUtil(toDate) : '';
    const [eventDuration, setEventDuration] = useState(
        getDurationFromNow(nextActionAt)
    );
    const updateEventDuration = useCallback(() => {
        setEventDuration(getDurationFromNow(nextActionAt));
    }, [nextActionAt]);

    useInterval(
        updateEventDuration,
        !isIntervalPaused &&
            EventMatrix.isEventInProgress({
                runtime: stateRuntime,
                infrastructure: stateInfrastructure,
            })
            ? DEFAULT_UPDATE_INTERVAL
            : null
    );

    return (
        <>
            {formatString(
                i18nStrings.events.eventDetails.notificationEventInProgress
                    .content,
                eventDuration
            )}
        </>
    );
};

export default EventDurationMessage;
