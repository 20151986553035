import { useNotification } from 'hooks';
import React, { createContext, FC, useEffect } from 'react';

export const NotificationsContext = createContext(
    {} as ReturnType<typeof useNotification>
);

const NotificationsProvider: FC = ({ children }) => {
    const useNotificationValue = useNotification();

    useEffect(() => {
        return () => {
            useNotificationValue.clearNotification();
        };
    }, []);

    return (
        <NotificationsContext.Provider value={useNotificationValue}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;
