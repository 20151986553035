import Alert from '@amzn/awsui-components-react/polaris/alert';
import Form from '@amzn/awsui-components-react/polaris/form';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    formatString,
    FormikField,
    InputField,
    useLazyRequest,
    useModal,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { routes } from 'components/AppRoutes/routes.constants';
import { AppTopNavContext } from 'components/AppTopNav';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';

import { i18nStrings } from '../../../../../constants';
import FormSchema, { FieldName, SchemaType } from './schema';

export interface UpdateCapacityModalProps {
    event: Pick<
        EventEngineEventsService.EventData,
        'eventId' | 'numberOfForecastedParticipants' | 'teamSize'
    >;
    isVisible: boolean;
    reservedQuotasAvailable: number;
    onModalClosed: () => void;
    refetchGetEvent: () => Promise<void>;
}

const UpdateCapacityModal: React.FC<UpdateCapacityModalProps> = ({
    event,
    onModalClosed,
    reservedQuotasAvailable,
    isVisible,
    refetchGetEvent,
}: UpdateCapacityModalProps) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    const { showNotification } = useContext(NotificationsContext);
    const [
        updateEventAttendees,
        {
            isLoading: updateEventAttendeesLoading,
            isError: updateEventAttendeesError,
            data: updateEventAttendeesData,
        },
    ] = useLazyRequest<
        typeof EEEvents.updateEventParticipantForecast,
        AWSError
    >(EEEvents.updateEventParticipantForecast, {});
    /**
     * Max available capacity to add is determined by the sum of the following components:
     * - Capacity from reserved quota: reserved_quota * team_size
     * - Any remaining open capacity from current teams: team_size - (current_participant_forecast (mod team_size))
     */
    const groupRemainder =
        event.numberOfForecastedParticipants % event.teamSize;
    const maxCapacity =
        reservedQuotasAvailable * event.teamSize +
        (groupRemainder > 0 ? event.teamSize - groupRemainder : 0);

    const onSubmit = async (inputValues: SchemaType) => {
        const values = FormSchema.cast(inputValues);

        await updateEventAttendees({
            eventId: event.eventId,
            numberOfForecastedParticipants:
                event.numberOfForecastedParticipants +
                (values[FieldName.CAPACITY] || 0),
        });
    };

    const formik = useFormik({
        validateOnChange,
        validateOnBlur: false,
        validationSchema: FormSchema,
        initialValues: {
            [FieldName.MAX_CAPACITY]: maxCapacity,
            [FieldName.CAPACITY]: 0,
        },
        onSubmit,
    });

    useEffect(() => {
        if (updateEventAttendeesData) {
            showNotification(
                i18nStrings.events.updateCapacity.notifications.success.content,
                {
                    type: 'success',
                }
            );
            refetchGetEvent();
            closeModal();
            refreshQuotaInfo();
        }
    }, [updateEventAttendeesData]);

    const closeModal = () => {
        hideModal();
        onModalClosed();
    };

    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, []);

    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);

    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: onModalClosed,
        modalHeader: i18nStrings.events.updateCapacity.header,
        actions: {
            primary: {
                text: i18nStrings.events.updateCapacity.primaryAction,
                onClick: onPrimaryActionClick,
                disabled: !formik.dirty || !formik.isValid,
                loading: updateEventAttendeesLoading,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <SpaceBetween direction="vertical" size="l">
                    <Form
                        errorText={
                            updateEventAttendeesError &&
                            ErrorMessage.getMessage(
                                updateEventAttendeesError,
                                MessageTypes.updateEventParticipantForecast
                            )
                        }>
                        <FormikField<SchemaType>
                            name={FieldName.CAPACITY}
                            dataTestId={FieldName.CAPACITY}
                            formFieldProps={{
                                label:
                                    i18nStrings.events.updateCapacity.fields
                                        .capacity.label,
                                constraintText:
                                    maxCapacity === 0
                                        ? i18nStrings.events.updateCapacity
                                              .fields.capacity.constraintZero
                                        : formatString(
                                              i18nStrings.events.updateCapacity
                                                  .fields.capacity.constraint,
                                              maxCapacity
                                          ),
                                stretch: true,
                                children: (
                                    <InputField<SchemaType>
                                        name={FieldName.CAPACITY}
                                        placeholder={
                                            i18nStrings.events.updateCapacity
                                                .fields.capacity.placeholder
                                        }
                                        inputProps={{
                                            disabled:
                                                updateEventAttendeesLoading ||
                                                maxCapacity === 0,
                                            type: 'number',
                                        }}
                                    />
                                ),
                            }}
                        />
                    </Form>
                    <Alert type="info">
                        Once updated, the specified capacity will be immediately
                        queued for provisioning. View the{' '}
                        <Link
                            href={`${routes.events}/${event.eventId}#QUOTAS`}
                            target="_blank">
                            Quotas tab
                        </Link>{' '}
                        to view current reservations details, and contribute
                        additional reservations if needed.
                    </Alert>
                </SpaceBetween>
            </FormikProvider>
        ),
    });

    return modalComponent;
};

export default UpdateCapacityModal;
