import { addHours, addMinutes } from 'date-fns';
import { TIMEZONES } from 'pages/Events/CreateEvent/utils/constants';
import { EVENT_AUTOSTART_WINDOW_HOURS } from 'utils/config';
import { getUTCOffsetLabel, utcToZonedTime } from 'utils/date-fns';
import { DATE_FORMATS } from 'utils/date-fns/constants';

import { EventData, ZonedTimeStrings } from './types';

const getZonedEventTimes = ({
    timeZone,
    scheduledStartTime,
    scheduledDuration,
}: EventData): ZonedTimeStrings => {
    const now = new Date();
    const timezone = TIMEZONES.find((tz) => tz.value === timeZone);
    const zonedScheduledStartTime =
        scheduledStartTime &&
        utcToZonedTime(scheduledStartTime, {
            format: DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
            timezone: timeZone,
        });
    const zonedScheduledTerminationTime =
        scheduledStartTime &&
        utcToZonedTime(addMinutes(scheduledStartTime, scheduledDuration), {
            format: DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
            timezone: timeZone,
        });
    const timezoneLabel =
        zonedScheduledStartTime &&
        `(${getUTCOffsetLabel(timeZone, {
            date: zonedScheduledStartTime?.date,
            time: zonedScheduledStartTime?.time,
        })}) ${timezone?.label}`;
    const actualStartDate = addHours(now, EVENT_AUTOSTART_WINDOW_HOURS);
    const zonedActualStartTime = utcToZonedTime(actualStartDate, {
        format: DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
        timezone: timeZone,
    });
    const zonedActualTerminationTime = utcToZonedTime(
        addMinutes(actualStartDate, scheduledDuration),
        {
            format: DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
            timezone: timeZone,
        }
    );

    return {
        scheduledStartTime: `${zonedScheduledStartTime?.date} ${zonedScheduledStartTime?.time} ${timezoneLabel}`,
        scheduledTerminationTime: `${zonedScheduledTerminationTime?.date} ${zonedScheduledTerminationTime?.time} ${timezoneLabel}`,
        actualStartTime: `${zonedActualStartTime.date} ${zonedActualStartTime.time} ${timezoneLabel}`,
        actualTerminationTime: `${zonedActualTerminationTime.date} ${zonedActualTerminationTime.time} ${timezoneLabel}`,
    };
};

export default getZonedEventTimes;
