import { EventField, EventMatrix } from '@amzn/event-engine-js-utils';

/**
 * Returns true if the field is enabled based on
 * certain event properties
 *
 * @param {EventMatrix} editMatrix
 * @param {EventField} field
 * @returns {boolean}
 */
const isFieldEnabled = (
    editMatrix: EventMatrix,
    field: EventField
): boolean => {
    const isEditing = !!editMatrix.state;
    const isFieldEnabled = editMatrix.isFieldEnabled(field);

    if (!isEditing) {
        return isFieldEnabled;
    }

    return (
        isFieldEnabled &&
        !EventMatrix.hasDiscreteUpdateApi(field) &&
        editMatrix.isFieldEditable(field)
    );
};

export default isFieldEnabled;
