import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import tableConfig from 'constants/table-config';
import React from 'react';
import getDisplayValue from 'utils/get-display-value';

import { OutputsTableItem } from './types';

enum ColumnID {
    OUTPUT_KEY = 'outputKey',
    EXPORT_KEY = 'exportKey',
    VALUE = 'value',
    DESCRIPTION = 'description',
    SOURCE = 'source',
}

export const DEFAULT_PAGINATION_LIMIT = 50;

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    wrapLines: true,
    visibleContent: [
        ColumnID.OUTPUT_KEY,
        ColumnID.EXPORT_KEY,
        ColumnID.VALUE,
        ColumnID.DESCRIPTION,
    ],
};

type ColumnFieldMap = Record<ColumnID, keyof OutputsTableItem>;

export const columnFieldMap: ColumnFieldMap = {
    [ColumnID.OUTPUT_KEY]: 'outputKey',
    [ColumnID.EXPORT_KEY]: 'exportKey',
    [ColumnID.VALUE]: 'value',
    [ColumnID.DESCRIPTION]: 'description',
    [ColumnID.SOURCE]: 'source',
};

export const defaultSortPreferences: TableProps.SortingState<OutputsTableItem> = {
    ...tableConfig.defaultSortPreferences,
    sortingColumn: {
        sortingField: columnFieldMap[ColumnID.OUTPUT_KEY],
    },
};

type ColumnDefinition = TableProps.ColumnDefinition<OutputsTableItem> &
    Partial<CollectionPreferencesProps.VisibleContentOption>;

export const columnDefinitions: ColumnDefinition[] = [
    {
        id: ColumnID.OUTPUT_KEY,
        header: i18nStrings.events.teams.stackOutputsTable.columns.outputKey,
        cell: (item) =>
            getDisplayValue(item[columnFieldMap[ColumnID.OUTPUT_KEY]]),
        sortingField: columnFieldMap[ColumnID.OUTPUT_KEY],

        editable: false,
    },
    {
        id: ColumnID.EXPORT_KEY,
        header: i18nStrings.events.teams.stackOutputsTable.columns.exportKey,
        cell: (item) =>
            getDisplayValue(item[columnFieldMap[ColumnID.EXPORT_KEY]]),
        sortingField: columnFieldMap[ColumnID.EXPORT_KEY],

        editable: false,
    },
    {
        id: ColumnID.VALUE,
        header: i18nStrings.events.teams.stackOutputsTable.columns.value,
        cell: (item) =>
            getDisplayValue(item[columnFieldMap[ColumnID.VALUE]], {
                copyable: true,
            }),
        sortingField: columnFieldMap[ColumnID.VALUE],
        editable: true,
    },
    {
        id: ColumnID.DESCRIPTION,
        header: i18nStrings.events.teams.stackOutputsTable.columns.description,
        cell: (item) =>
            getDisplayValue(item[columnFieldMap[ColumnID.DESCRIPTION]]),
        sortingField: columnFieldMap[ColumnID.DESCRIPTION],
        editable: true,
    },
    {
        id: ColumnID.SOURCE,
        header: i18nStrings.events.teams.stackOutputsTable.columns.source,
        cell: (item) => item[columnFieldMap[ColumnID.SOURCE]],
        sortingField: columnFieldMap[ColumnID.SOURCE],
        editable: true,
    },
];

export const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: 'and',
};

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        key: columnFieldMap[ColumnID.OUTPUT_KEY],
        propertyLabel:
            i18nStrings.events.teams.stackOutputsTable.columns.outputKey,
        groupValuesLabel:
            i18nStrings.events.teams.stackOutputsTable.propertyFilter.outputKey,
        operators: ['=', '!=', ':', '!:'],
        showOptions: false,
    },
    {
        key: columnFieldMap[ColumnID.EXPORT_KEY],
        propertyLabel:
            i18nStrings.events.teams.stackOutputsTable.columns.exportKey,
        groupValuesLabel:
            i18nStrings.events.teams.stackOutputsTable.propertyFilter.exportKey,
        operators: ['=', '!=', ':', '!:'],
        showOptions: false,
    },
    {
        key: columnFieldMap[ColumnID.VALUE],
        propertyLabel: i18nStrings.events.teams.stackOutputsTable.columns.value,
        groupValuesLabel:
            i18nStrings.events.teams.stackOutputsTable.propertyFilter.value,
        operators: ['=', '!=', ':', '!:'],
        showOptions: false,
    },
    {
        key: columnFieldMap[ColumnID.DESCRIPTION],
        propertyLabel:
            i18nStrings.events.teams.stackOutputsTable.columns.description,
        groupValuesLabel:
            i18nStrings.events.teams.stackOutputsTable.propertyFilter
                .description,
        operators: ['=', '!=', ':', '!:'],
        showOptions: false,
    },
    {
        key: columnFieldMap[ColumnID.SOURCE],
        propertyLabel:
            i18nStrings.events.teams.stackOutputsTable.columns.source,
        groupValuesLabel:
            i18nStrings.events.teams.stackOutputsTable.propertyFilter.source,
        operators: ['=', '!=', ':', '!:'],
        showOptions: true,
    },
];
