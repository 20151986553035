import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useRequest } from '@amzn/event-engine-js-utils';
import { breadcrumbsState } from 'atoms';
import { AWSError } from 'aws-sdk/lib/error';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import SpinnerWithLabel from 'components/SpinnerWithLabel';
import NotificationsProvider from 'contexts/NotificationsProvider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EEEvents from 'services/ee-events';
import ErrorMessage, { MessageTypes } from 'services/error-message';

import { i18nStrings } from '../../../../constants';
import { TeamDetailsContext } from './constants';
import TeamDetails from './TeamDetails';
import { TeamDetailsRouteParams } from './types';

const TeamDetailsContainer = () => {
    const { eventId, teamId } = useParams<TeamDetailsRouteParams>();
    const [lastBreadcrumbs, setBreadcrumbs] = useRecoilState(breadcrumbsState);
    const eventRequest = useRequest<typeof EEEvents.getEvent, AWSError>(
        EEEvents.getEvent,
        {},
        eventId
    );
    const teamRequest = useRequest<typeof EEEvents.getTeam, AWSError>(
        EEEvents.getTeam,
        {},
        { eventId, teamId }
    );
    const participantsRequest = useRequest<
        typeof EEEvents.getParticipants,
        AWSError
    >(EEEvents.getParticipants, {}, { eventId });
    const isLoading =
        eventRequest.isLoading ||
        teamRequest.isLoading ||
        participantsRequest.isLoading;
    const error =
        eventRequest.isError ||
        teamRequest.isError ||
        participantsRequest.isError;

    useEffect(() => {
        const breadcrumbs = [
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: i18nStrings.events.title,
                href: routes.events,
            },
        ];

        eventRequest.data &&
            breadcrumbs.push(
                {
                    text: eventRequest.data.event?.title || eventId,
                    href: `${routes.events}/${eventId}`,
                },
                {
                    text: i18nStrings.events.teams.title,
                    href: `${routes.events}/${eventId}#TEAMS`,
                },
                {
                    text: teamRequest.data?.team?.name || teamId,
                    href: `${routes.events}/${eventId}/${routeTokens.teams}/${teamId}`,
                }
            );

        breadcrumbs.length > lastBreadcrumbs.length &&
            setBreadcrumbs(breadcrumbs);
    }, [eventRequest.data, teamRequest.data]);

    if (error) {
        return (
            <Alert type="error">
                {ErrorMessage.getMessage(
                    error,
                    eventRequest.isError
                        ? MessageTypes.getEvent
                        : MessageTypes.getTeam
                )}
            </Alert>
        );
    }

    if (!(eventRequest.data && teamRequest.data)) {
        return <SpinnerWithLabel>{i18nStrings.loading}</SpinnerWithLabel>;
    }

    return (
        <NotificationsProvider>
            <TeamDetailsContext.Provider
                value={{
                    eventRequest,
                    teamRequest,
                    participantsRequest,
                    isLoading,
                    error,
                }}>
                <TeamDetails />
            </TeamDetailsContext.Provider>
        </NotificationsProvider>
    );
};

export default TeamDetailsContainer;
