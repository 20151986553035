import { useEffect, useState } from 'react';
import AppSettings from 'services/app-settings';

const useDocumentationLink = (path: string = '') => {
    const [docsBaseUrl, setDocsBaseUrl] = useState('');

    useEffect(() => {
        (async () => {
            const settings = await AppSettings();

            settings.documentation && setDocsBaseUrl(settings.documentation);
        })();
    }, []);

    return docsBaseUrl ? `${docsBaseUrl}${path}` : '';
};

export default useDocumentationLink;
