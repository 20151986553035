import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ExtendedFilteringProperty } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';
import formatBytes from 'utils/format-bytes';
import getDisplayValue from 'utils/get-display-value';

import { SelectWorkshopAssetTableItem } from './types';

enum ColumnID {
    KEY = 'key',
    SIZE = 'size',
    TYPE = 'type',
    STAGING_VERSION_ID = 'staging_version_id',
    UPDATED_AT = 'updated_at',
}

export const visibleContent: ColumnID[] = [
    ColumnID.KEY,
    ColumnID.SIZE,
    ColumnID.TYPE,
    ColumnID.UPDATED_AT,
];

const columnFieldMap: Record<ColumnID, keyof SelectWorkshopAssetTableItem> = {
    [ColumnID.KEY]: 's3Key',
    [ColumnID.SIZE]: 'size',
    [ColumnID.TYPE]: 'assetType',
    [ColumnID.STAGING_VERSION_ID]: 'stagingVersionId',
    [ColumnID.UPDATED_AT]: 'updatedAt',
};

interface ColumnDefinition
    extends TableProps.ColumnDefinition<SelectWorkshopAssetTableItem>,
        Partial<CollectionPreferencesProps.VisibleContentOption> {
    id: string;
    header: string;
}

export const columnDefinitions: ColumnDefinition[] = [
    {
        id: ColumnID.KEY,
        header: i18nStrings.workshops.assets.table.columns.key,
        cell: ({ s3Key }) => s3Key,
        editable: false,
    },
    {
        id: ColumnID.SIZE,
        header: i18nStrings.workshops.assets.table.columns.size,
        cell: ({ size }) => formatBytes(size),
    },
    {
        id: ColumnID.TYPE,
        header: i18nStrings.workshops.assets.table.columns.type,
        cell: ({ assetType }) => getDisplayValue(assetType),
    },
    {
        id: ColumnID.STAGING_VERSION_ID,
        header: i18nStrings.workshops.assets.table.columns.stagingVersionId,
        cell: ({ stagingVersionId }) => getDisplayValue(stagingVersionId),
    },
    {
        id: ColumnID.UPDATED_AT,
        header: i18nStrings.workshops.assets.table.columns.updatedAt,
        cell: ({ updatedAt }) => getDisplayValue(updatedAt),
    },
];
columnDefinitions.forEach((columnDefinition) => {
    const columnId = columnDefinition.id as ColumnID;
    columnDefinition.sortingField = columnFieldMap[columnId];
});

export const filteringProperties: ExtendedFilteringProperty[] = [
    {
        key: columnFieldMap[ColumnID.KEY],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.workshops.assets.table.columns.key,
        groupValuesLabel: i18nStrings.workshops.assets.table.propertyFilter.key,
    },
    {
        key: columnFieldMap[ColumnID.SIZE],
        operators: ['=', '!=', '>', '>=', '<', '<='],
        propertyLabel:
            i18nStrings.workshops.assets.table.propertyFilter.sizeLabel,
        groupValuesLabel:
            i18nStrings.workshops.assets.table.propertyFilter.size,
    },
    {
        key: columnFieldMap[ColumnID.TYPE],
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: i18nStrings.workshops.assets.table.columns.type,
        groupValuesLabel:
            i18nStrings.workshops.assets.table.propertyFilter.type,
    },
];

export const tableI18nStrings = {
    title: i18nStrings.workshops.assets.title,
    description:
        i18nStrings.workshops.generateWorkshopStaticAssetURLs.table.description,
    loadingText: i18nStrings.loading,
    preferences: {
        title: i18nStrings.preferences,
        confirmLabel: i18nStrings.confirm,
        cancelLabel: i18nStrings.cancel,
        pageSize: {
            optionSuffix: i18nStrings.item.plural,
            title: i18nStrings.pageSize,
        },
        visibleContent: {
            title: i18nStrings.table.selectVisibleColumns,
            optionsLabel: i18nStrings.table.properties,
        },
        wrapLines: {
            label: i18nStrings.wrapLines,
            description: i18nStrings.wrapLinesDescription,
        },
        contentDensity: i18nStrings.collectionPreferences.contentDensity,
        stickyColumns: i18nStrings.collectionPreferences.stickyColumns,
    },
    filter: i18nStrings.propertyFilter,
    filteredItemsCountSuffix: i18nStrings.item,
    emptyMessage: i18nStrings.workshops.assets.selectTable.emptyMessage,
    noMatchMessage: {
        title: i18nStrings.table.noMatches,
        description: i18nStrings.table.noMatchesDescription,
        clearAllLabel: i18nStrings.table.clearAllFilters,
    },
};
