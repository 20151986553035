import '@amzn/awsui-global-styles/polaris.css';
import '@amzn/event-engine-js-utils/esm/index.css';
import './assets/styles/index.scss';

import {
    AppVersionProvider,
    loadPolyfills,
    LocationLoggerProvider,
    Logger,
    LogLevel,
    SessionProvider,
} from '@amzn/event-engine-js-utils';
import Providers from 'contexts';
import ReactDOM from 'react-dom';

import Main from './components/Main';
import * as serviceWorker from './serviceWorker';

const polyfillLogger = Logger.getLogger(
    'Polyfill',
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
        ? LogLevel.VERBOSE
        : LogLevel.ERROR
);

const init = async () => {
    try {
        await loadPolyfills(polyfillLogger);
        initializeLoggerMetadata();

        polyfillLogger.verbose('Polyfills loaded successfully');
    } catch (e) {
        polyfillLogger.error('Unable to load polyfills', e);
    }

    ReactDOM.render(
        <Providers>
            <Main />
        </Providers>,
        document.getElementById('root')
    );
};

/**
 * Initialize Logger with metadata that will be sent with all
 * logs
 */
const initializeLoggerMetadata = () => {
    Logger.registerProvider(LocationLoggerProvider);
    Logger.registerProvider(SessionProvider);
    Logger.registerProvider(AppVersionProvider);
};

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default init;
