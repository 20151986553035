import { EventMatrix } from '@amzn/event-engine-js-utils';
import { FormikContextType, useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { i18nStrings } from '../../../../../constants';
import CreateEventForm from '../../components/CreateEventForm';
import {
    MergedFormSchema,
    Step,
} from '../../components/CreateEventForm.validation';
import { getRegionSelectOptions } from '../../utils';
import { DEFAULT_ACCESSIBLE_REGIONS } from '../../utils/constants';
import { EnhancedContentTemplateAttribute } from '../../utils/create-event-attributes';

interface EventDetailsProps {
    attributes: readonly EnhancedContentTemplateAttribute[];
    editMatrix: EventMatrix;
    maxEventSize: number;
}

const EventDetails: React.FC<EventDetailsProps> = ({
    attributes,
    editMatrix,
    maxEventSize,
}) => {
    const formik: FormikContextType<MergedFormSchema> = useFormikContext();
    const selectedWorkshop = formik.values[Step.SELECT_WORKSHOP];
    const selectedDeploymentRegions =
        formik.values[Step.EVENT_DETAILS].deploymentRegions;
    const deploymentRegions = useMemo(
        () =>
            getRegionSelectOptions(
                selectedWorkshop?.contentSpecSummary?.deployableRegions
            ),
        []
    );
    const accessibleRegions = useMemo(() => {
        const defaultRegions = [...DEFAULT_ACCESSIBLE_REGIONS];

        // Add selected deployment region as accessible region if
        // it is not one of the system default accessible regions.
        if (
            selectedDeploymentRegions &&
            !DEFAULT_ACCESSIBLE_REGIONS.some(
                ({ value }) => value === selectedDeploymentRegions
            )
        ) {
            defaultRegions.push({
                value: selectedDeploymentRegions,
                description:
                    i18nStrings.events.regionCategories.deploymentRegion,
            });
        }

        return getRegionSelectOptions(
            selectedWorkshop?.contentSpecSummary?.accessibleRegions,
            defaultRegions
        );
    }, [selectedDeploymentRegions]);

    return (
        <CreateEventForm
            editMatrix={editMatrix}
            attributes={attributes}
            maxEventSize={maxEventSize}
            options={{
                deploymentRegions,
                accessibleRegions,
            }}
        />
    );
};

export default EventDetails;
