import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { QuotaReservationTableItem } from '../types';

const processData = (
    data: EventEngineEventsService.AccountQuotaContributions = []
) => {
    const res: QuotaReservationTableItem[] = [];

    data.forEach(({ principal, reserved, consumed }) => {
        res.push({
            email: principal || '',
            reserved: reserved || 0,
            consumed: consumed || 0,
        });
    });

    return res;
};

export default processData;
