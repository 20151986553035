import { KeyOfType } from 'types';
type RouteParamKeys = KeyOfType<typeof routeParams>;
export type RouteParams = { [key in RouteParamKeys]?: string };

export enum AccountType {
    TEAMS = 'teams',
    CENTRAL = 'central',
}

// Single source of truth for route paths
export const routes = {
    landingPage: '/',
    callback: '/callback',
    events: '/events',
    profile: '/profile',
    workshops: '/workshops',
    logout: '/logout',
    preview: '/preview',
    quotas: '/quotas',
    guardrails: '/guardrails',
};

export const routeTokens = {
    assets: 'assets',
    staticUrls: 'static-urls',
    generate: 'generate',
    edit: 'edit',
    create: 'create',
    publicWorkshops: 'public',
    latest: '$LATEST',
    builds: 'builds',
    teams: 'teams',
    central: 'central',
    login: 'login',
    events: {
        active: 'active',
        upcoming: 'upcoming',
        past: 'past',
    },
};

export const routeParams = {
    eventId: 'eventId',
    workshopId: 'workshopId',
    buildNumber: 'buildNumber',
    previewId: 'previewId',
    localeCode: 'localeCode',
    teamId: 'teamId',
    persona: 'persona',
    accountType: 'accountType',
    resourceId: 'resourceId',
    assetKey: 'assetKey',
};

export enum SearchParamKey {
    TARGET = 'target',
}
