import {
    EVENT_STATE_INFRASTRUCTURE,
    EVENT_STATE_RUNTIME,
    EVENT_TYPE,
    QUOTA_ID,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown, {
    ButtonDropdownProps,
} from '@amzn/awsui-components-react/polaris/button-dropdown';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    CopyButton,
    EventMatrix,
    LogLevel,
    SinkType,
    useLazyRequest,
    useLogger,
    ValueWithLabel,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { minutesToHours } from 'date-fns';
import {
    MODALITY_OPTIONS,
    TIMEZONES,
} from 'pages/Events/CreateEvent/utils/constants';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { EEContentCatalog, EEEvents, ErrorMessage } from 'services';
import {
    EventAttributes,
    getAttributeValueByName,
} from 'utils/content-attributes';
import { getUTCOffsetLabel, utcToZonedTime } from 'utils/date-fns';
import { DATE_FORMATS } from 'utils/date-fns/constants';
import getDisplayValue from 'utils/get-display-value';
import pluralizeSimple from 'utils/pluralize-simple';

import { i18nStrings } from '../../../../../../../constants';
import getRegionsFormData from '../../../../../CreateEvent/utils/get-regions-form-data';
import UpdateCapacityModal from '../../../UpdateCapacityModal';
import UpdateParticipantForecastModal from '../../../UpdateParticipantForecastModal';
import UpdateRegionsModal from '../../../UpdateRegionsModal';
import UpdateScheduleModal from '../../../UpdateScheduleModal';
import { ActionID, OverviewTabProps, TestID } from './types';

const OverviewTab: React.FC<OverviewTabProps> = ({
    event,
    refetchGetEvent,
}: OverviewTabProps) => {
    const [
        getContentBuild,
        {
            data: contentBuildData,
            isLoading: isGetContentBuildLoading,
            isError: getContentBuildError,
        },
    ] = useLazyRequest<typeof EEContentCatalog.getContentBuild, AWSError>(
        EEContentCatalog.getContentBuild,
        {}
    );
    const [
        getQuota,
        {
            isError: getQuotaError,
            isLoading: getQuotaLoading,
            data: getQuotaData,
        },
    ] = useLazyRequest<typeof EEEvents.getQuota, AWSError>(
        EEEvents.getQuota,
        {}
    );
    const regionsData = getRegionsFormData(
        event.deploymentRegions,
        event.accessibleRegions
    );
    const { showNotification } = useContext(NotificationsContext);
    const [eventMatrixLogger] = useLogger(
        'EventMatrixLogger',
        process.env.NODE_ENV === 'development'
            ? LogLevel.VERBOSE
            : LogLevel.ERROR,
        [SinkType.CloudWatchLogSink]
    );
    const [maxEventSize, setMaxEventSize] = useState<number>();
    const [, setEventStateUpdated] = useState<number>(0);
    const [activeAction, setActiveAction] = useState<ActionID>();
    const nextAction = useRef<ActionID>();
    const clearActiveAction = useCallback(() => {
        nextAction.current = undefined;
        setActiveAction(undefined);
    }, []);
    const eventMatrix = useRef(
        new EventMatrix(
            event.type,
            event.accountSource,
            {
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            },
            {
                logger: eventMatrixLogger,
            }
        )
    );
    const history = useHistory();
    const onEditEventDetails = useCallback(() => {
        history.push(`${routes.events}/${routeTokens.edit}/${event.eventId}`);
    }, []);
    const onModifyEventConfigurationClick = useCallback(
        ({ detail: { id } }) => {
            switch (id) {
                case ActionID.UPDATE_SCHEDULE:
                    setActiveAction(ActionID.UPDATE_SCHEDULE);
                    break;
                case ActionID.UPDATE_PARTICIPANT_FORECAST:
                    nextAction.current = ActionID.UPDATE_PARTICIPANT_FORECAST;
                    getQuota({
                        quotaId:
                            event.type === EVENT_TYPE.PRODUCTION
                                ? QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT
                                : QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_TEST_EVENT,
                    });
                    break;
                case ActionID.UPDATE_CAPACITY:
                    nextAction.current = ActionID.UPDATE_CAPACITY;
                    getQuota({
                        quotaId:
                            event.type === EVENT_TYPE.PRODUCTION
                                ? QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT
                                : QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_TEST_EVENT,
                    });
                    break;
                case ActionID.UPDATE_REGIONS:
                    getContentBuild({
                        contentBuildId: event.contentBuildId,
                    });
                    break;
            }
        },
        [event]
    );
    const configurationActions = useMemo(() => {
        const actions: ButtonDropdownProps.Item[] = [
            {
                text:
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.actions.updateSchedule,
                id: ActionID.UPDATE_SCHEDULE,
                disabled: !(
                    eventMatrix.current.isFieldEditable('duration') &&
                    eventMatrix.current.isFieldEnabled('duration')
                ),
            },
        ];
        const eventState = {
            runtime: event.stateRuntime as EVENT_STATE_RUNTIME,
            infrastructure: event.stateInfrastructure as EVENT_STATE_INFRASTRUCTURE,
        };

        if (!EventMatrix.isProvisionStarted(eventState)) {
            actions.push({
                text:
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.actions.updateParticipantForecast,
                id: ActionID.UPDATE_PARTICIPANT_FORECAST,
                disabled: !(
                    eventMatrix.current.isFieldEditable('attendeeForecast') &&
                    eventMatrix.current.isFieldEnabled('attendeeForecast')
                ),
            });
        } else {
            let disabledReason = '';

            if (
                [
                    EVENT_STATE_INFRASTRUCTURE.PROVISION_IN_PROGRESS,
                    EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_CENTRAL_IN_PROGRESS,
                ].includes(eventState.infrastructure)
            ) {
                disabledReason =
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.disabledReasons
                        .updateCapacityProvisionInProgress;
            } else if (
                eventState.infrastructure ===
                EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_IN_PROGRESS
            ) {
                disabledReason =
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.disabledReasons
                        .updateCapacityDeploymentInProgress;
            } else if (
                !(
                    EventMatrix.isDeploymentSuccess(eventState) ||
                    [
                        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_HALTED,
                        EVENT_STATE_INFRASTRUCTURE.DEPLOYMENT_FAILED,
                    ].includes(eventState.infrastructure)
                )
            ) {
                disabledReason =
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.disabledReasons
                        .updateCapacityProvisionFailed;
            }

            actions.push({
                text:
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.actions.updateCapacity,
                id: ActionID.UPDATE_CAPACITY,
                disabledReason,
                disabled: !!disabledReason,
            });
        }

        if (
            eventMatrix.current.isFieldEditable('regions') &&
            eventMatrix.current.isFieldEnabled('regions')
        ) {
            actions.push({
                text:
                    i18nStrings.events.eventDetails.overview.sections
                        .configuration.actions.updateRegions,
                id: ActionID.UPDATE_REGIONS,
                disabled: !(
                    eventMatrix.current.isFieldEditable('regions') &&
                    eventMatrix.current.isFieldEnabled('regions')
                ),
            });
        }

        return actions;
    }, [event]);

    const reservationsAvailable = useMemo(() => {
        return event.accountQuotaContributions?.length
            ? event.accountQuotaContributions.reduce(
                  (acc, { reserved, consumed }) =>
                      acc + ((reserved || 0) - (consumed || 0)),
                  0
              )
            : 0;
    }, [event]);

    const durationHours = minutesToHours(event.scheduledDuration);

    const { date: startDate, time: startTime } = utcToZonedTime(
        event.scheduledStartTime!,
        {
            timezone: event.timeZone,
            format: DATE_FORMATS.TWENTY_FOUR_HOUR_STANDARD,
        }
    );

    const timezone = TIMEZONES.find((tz) => tz.value === event.timeZone);

    const timezoneLabel = `(${getUTCOffsetLabel(event.timeZone, {
        date: startDate,
        time: startTime,
    })}) ${timezone?.label}`;

    useEffect(() => {
        if (
            eventMatrix.current.compareState(event.stateRuntime, 'runtime') ===
                0 &&
            eventMatrix.current.compareState(
                event.stateInfrastructure,
                'infrastructure'
            ) === 0
        ) {
            return;
        }

        eventMatrix.current.state = {
            runtime: event.stateRuntime,
            infrastructure: event.stateInfrastructure,
        };

        // we need to make a re-render since eventMatrix is a ref
        setEventStateUpdated((s) => {
            eventMatrixLogger.info('Event state updated, updating eventMatrix');
            return s + 1;
        });
    }, [event]);

    useEffect(() => {
        const maxEventSize = getQuotaData?.quota?.quota?.limit;

        if (!maxEventSize) {
            return;
        }

        setMaxEventSize(maxEventSize);
        setActiveAction(nextAction.current);
    }, [getQuotaData]);

    useEffect(() => {
        contentBuildData?.contentBuild &&
            setActiveAction(ActionID.UPDATE_REGIONS);
    }, [contentBuildData]);

    useEffect(() => {
        getQuotaError &&
            showNotification(ErrorMessage.getMessage(getQuotaError), {
                type: 'error',
            });
    }, [getQuotaError]);

    useEffect(() => {
        getContentBuildError &&
            showNotification(ErrorMessage.getMessage(getContentBuildError), {
                type: 'error',
            });
    }, [getContentBuildError]);

    return (
        <>
            {activeAction === ActionID.UPDATE_PARTICIPANT_FORECAST &&
                maxEventSize && (
                    <UpdateParticipantForecastModal
                        event={event}
                        isVisible={
                            activeAction ===
                            ActionID.UPDATE_PARTICIPANT_FORECAST
                        }
                        maxEventSize={maxEventSize}
                        onModalClosed={clearActiveAction}
                        refetchGetEvent={refetchGetEvent}
                    />
                )}
            {activeAction === ActionID.UPDATE_CAPACITY && (
                <UpdateCapacityModal
                    event={event}
                    isVisible={activeAction === ActionID.UPDATE_CAPACITY}
                    reservedQuotasAvailable={reservationsAvailable}
                    onModalClosed={clearActiveAction}
                    refetchGetEvent={refetchGetEvent}
                />
            )}
            {activeAction === ActionID.UPDATE_SCHEDULE && (
                <UpdateScheduleModal
                    event={event}
                    isVisible={activeAction === ActionID.UPDATE_SCHEDULE}
                    onModalClosed={clearActiveAction}
                    refetchGetEvent={refetchGetEvent}
                />
            )}
            {activeAction === ActionID.UPDATE_REGIONS &&
                contentBuildData?.contentBuild && (
                    <UpdateRegionsModal
                        event={event}
                        contentBuild={contentBuildData.contentBuild}
                        isVisible={activeAction === ActionID.UPDATE_REGIONS}
                        onModalClosed={clearActiveAction}
                        refetchGetEvent={refetchGetEvent}
                    />
                )}

            <SpaceBetween direction="vertical" size="xl">
                <Container
                    data-testid={TestID.SECTION_DETAILS}
                    header={
                        <Header
                            variant="h2"
                            actions={
                                <Button
                                    onClick={onEditEventDetails}
                                    iconName="edit"
                                    variant="normal">
                                    {i18nStrings.edit}
                                </Button>
                            }>
                            {
                                i18nStrings.events.eventDetails.overview
                                    .sections.details.title
                            }
                        </Header>
                    }
                    footer={
                        <ExpandableSection
                            variant="footer"
                            headerText={
                                i18nStrings.events.eventDetails.overview
                                    .sections.details.values.description
                            }
                            defaultExpanded>
                            {event.description}
                        </ExpandableSection>
                    }>
                    <SpaceBetween direction="vertical" size="xl">
                        <ColumnLayout columns={3} variant="text-grid">
                            <SpaceBetween size="l">
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.eventDetails.overview
                                            .sections.details.values.eventId
                                    }>
                                    <Box
                                        display="inline"
                                        margin={{ right: 'xxs' }}>
                                        <CopyButton
                                            variant="inline-icon"
                                            content={event.eventId}
                                        />
                                    </Box>
                                    {event.eventId}
                                </ValueWithLabel>
                                {eventMatrix.current.isFieldEnabled(
                                    'attributeGeo'
                                ) && (
                                    <ValueWithLabel
                                        label={
                                            i18nStrings.events.eventDetails
                                                .overview.sections.details
                                                .values.geo
                                        }>
                                        {getAttributeValueByName(
                                            EventAttributes['Geo'],
                                            event.attributes,
                                            'stringListValue'
                                        )}
                                    </ValueWithLabel>
                                )}
                                {eventMatrix.current.isFieldEnabled(
                                    'eventModality'
                                ) && (
                                    <ValueWithLabel
                                        label={
                                            i18nStrings.events.eventDetails
                                                .overview.sections.details
                                                .values.modality
                                        }>
                                        {getDisplayValue(
                                            MODALITY_OPTIONS.find(
                                                (modality) =>
                                                    event.modality ===
                                                    modality.value
                                            )?.label
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.eventDetails.overview
                                            .sections.details.values
                                            .primaryFacilitator
                                    }>
                                    {event.primaryFacilitator}
                                </ValueWithLabel>
                                {eventMatrix.current.isFieldEnabled(
                                    'attributeOpportunityId'
                                ) && (
                                    <ValueWithLabel
                                        label={
                                            i18nStrings.events.eventDetails
                                                .overview.sections.details
                                                .values.opportunityId
                                        }>
                                        {getAttributeValueByName(
                                            EventAttributes[
                                                'Salesforce Opportunity ID'
                                            ],
                                            event.attributes,
                                            'stringValue'
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.eventDetails.overview
                                            .sections.details.values.accessCode
                                    }>
                                    <Box
                                        display="inline"
                                        margin={{ right: 'xxs' }}>
                                        <CopyButton
                                            variant="inline-icon"
                                            content={event.eventCode}
                                        />
                                    </Box>
                                    {event.eventCode}
                                </ValueWithLabel>
                                {eventMatrix.current.isFieldEnabled(
                                    'attributeEngagementType'
                                ) && (
                                    <ValueWithLabel
                                        label={
                                            i18nStrings.events.eventDetails
                                                .overview.sections.details
                                                .values.engagementType
                                        }>
                                        {getAttributeValueByName(
                                            EventAttributes['Engagement Type'],
                                            event.attributes,
                                            'stringValue'
                                        )}
                                    </ValueWithLabel>
                                )}
                            </SpaceBetween>
                        </ColumnLayout>
                    </SpaceBetween>
                </Container>

                <Container
                    data-testid={TestID.SECTION_CONFIGURATION}
                    header={
                        <Header
                            variant="h2"
                            actions={
                                EventMatrix.isEventActive({
                                    runtime: event.stateRuntime,
                                    infrastructure: event.stateInfrastructure,
                                }) ? (
                                    <ButtonDropdown
                                        loading={
                                            getQuotaLoading ||
                                            isGetContentBuildLoading
                                        }
                                        onItemClick={
                                            onModifyEventConfigurationClick
                                        }
                                        items={configurationActions}>
                                        {i18nStrings.actions}
                                    </ButtonDropdown>
                                ) : null
                            }>
                            {
                                i18nStrings.events.eventDetails.overview
                                    .sections.configuration.title
                            }
                        </Header>
                    }>
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.eventDetails.overview
                                        .sections.configuration.values.startDate
                                }>
                                {startDate}
                            </ValueWithLabel>
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.eventDetails.overview
                                        .sections.configuration.values
                                        .participantForecast
                                }>
                                {event.numberOfForecastedParticipants}
                            </ValueWithLabel>
                            {eventMatrix.current.isFieldEnabled('regions') && (
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.eventDetails.overview
                                            .sections.configuration.values
                                            .deploymentRegions
                                    }>
                                    {getDisplayValue(event.deploymentRegions)}
                                </ValueWithLabel>
                            )}
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.eventDetails.overview
                                        .sections.configuration.values.startTime
                                }>
                                {startTime} {timezoneLabel}
                            </ValueWithLabel>
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.eventDetails.overview
                                        .sections.configuration.values.teamSize
                                }>
                                {event.teamSize}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                label={
                                    i18nStrings.events.eventDetails.overview
                                        .sections.configuration.values.duration
                                }>
                                {`${durationHours} ${pluralizeSimple(
                                    'hour',
                                    durationHours
                                )}`}
                            </ValueWithLabel>
                            {eventMatrix.current.isFieldEnabled('regions') && (
                                <ValueWithLabel
                                    label={
                                        i18nStrings.events.eventDetails.overview
                                            .sections.configuration.values
                                            .accessibleRegions
                                    }>
                                    {getDisplayValue(
                                        regionsData.accessibleRegions?.map(
                                            ({ value }) => value
                                        )
                                    )}
                                </ValueWithLabel>
                            )}
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </>
    );
};

export default OverviewTab;
