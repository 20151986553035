import {
    EVENT_TYPE,
    QUOTA_ID,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { LoadingComponent, useRequest } from '@amzn/event-engine-js-utils';
import {
    breadcrumbsState,
    contentTypeState,
    navigationOpenState,
    toolsState,
} from 'atoms';
import { AWSError } from 'aws-sdk/lib/error';
import { routes } from 'components/AppRoutes/routes.constants';
import HelpPanel from 'components/HelpPanel';
import NotificationsProvider from 'contexts/NotificationsProvider';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import EEEvents, { defaultEventTemplateId } from 'services/ee-events';

import { i18nStrings } from '../../../constants';
import { Step } from './components/CreateEventForm.validation';
import CreateEventWizard from './CreateEventWizard';
import { CreateEventLocationState } from './types';
import { generateCustomAttributes } from './utils/create-event-attributes';

const CreateEventWizardContainer = () => {
    const location = useLocation<CreateEventLocationState | undefined>();
    const locationState = useRef(
        location.state?.createEvent ? location.state.createEvent : undefined
    );
    const isTestEvent = useRef(
        locationState.current?.[Step.EVENT_TYPE]?.eventType === EVENT_TYPE.TEST
    );
    const [maxEventSize, setMaxEventSize] = useState<{
        test: number;
        production: number;
    }>();
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    const setTools = useSetRecoilState(toolsState);
    const setContentType = useSetRecoilState(contentTypeState);
    const setNavigationOpen = useSetRecoilState(navigationOpenState);
    const {
        isLoading: getContentTemplateIsLoading,
        isError: getContentTemplateIsError,
        data: getContentTemplateData,
    } = useRequest<typeof EEEvents.getEventTemplate, AWSError>(
        EEEvents.getEventTemplate,
        {},
        defaultEventTemplateId
    );
    const {
        isError: getQuotaErrorTest,
        isLoading: getQuotaLoadingTest,
        data: getQuotaDataTest,
    } = useRequest<typeof EEEvents.getQuota, AWSError>(
        EEEvents.getQuota,
        {},
        {
            quotaId: QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_TEST_EVENT,
        }
    );
    const {
        isError: getQuotaErrorProduction,
        isLoading: getQuotaLoadingProduction,
        data: getQuotaDataProduction,
    } = useRequest<typeof EEEvents.getQuota, AWSError>(
        EEEvents.getQuota,
        {},
        {
            quotaId: QUOTA_ID.EVENTS_MAX_ACCOUNTS_PER_PRODUCTION_EVENT,
        }
    );

    useEffect(() => {
        setNavigationOpen(false);
        setContentType('wizard');
        setBreadcrumbs([
            {
                text: i18nStrings.appName,
                href: routes.landingPage,
            },
            {
                text: isTestEvent.current
                    ? i18nStrings.events.create.test.title
                    : i18nStrings.events.create.title,
                href: routes.events,
            },
        ]);
        setTools({
            open: false,
            hide: false,
            content: (
                <HelpPanel
                    header={
                        i18nStrings.events.create.helpText.eventDetails.header
                    }>
                    {i18nStrings.events.create.helpText.eventDetails.content}
                </HelpPanel>
            ),
        });
        window.history.replaceState({}, document.title);
    }, []);

    useEffect(() => {
        const test = getQuotaDataTest?.quota?.quota?.limit;
        const production = getQuotaDataProduction?.quota?.quota?.limit;

        test && production && setMaxEventSize({ test, production });
    }, [getQuotaDataTest, getQuotaDataProduction]);

    if (
        getContentTemplateIsLoading ||
        getQuotaLoadingTest ||
        getQuotaLoadingProduction
    ) {
        return <LoadingComponent />;
    }

    if (
        getContentTemplateIsError ||
        getQuotaErrorTest ||
        getQuotaErrorProduction
    ) {
        return (
            <Alert
                header={i18nStrings.events.create.alerts.setupFailed.header}
                type="error">
                {i18nStrings.events.create.alerts.setupFailed.content}
            </Alert>
        );
    }

    return (
        <form
            onSubmit={(e) => e.preventDefault()}
            autoComplete="chrome-off"
            noValidate>
            {getContentTemplateData && maxEventSize && (
                <NotificationsProvider>
                    <CreateEventWizard
                        attributes={Object.freeze(
                            generateCustomAttributes(
                                getContentTemplateData.eventTemplate
                            )
                        )}
                        eventTemplateId={defaultEventTemplateId}
                        maxEventSize={maxEventSize}
                        initialValues={locationState.current}
                        stepOptions={{
                            [Step.SELECT_WORKSHOP]: {
                                skipIfValid: true,
                            },
                            [Step.PARTICIPANT_ALLOWLIST]: {
                                required: isTestEvent.current,
                            },
                        }}
                        hiddenSteps={
                            isTestEvent.current
                                ? [Step.SELECT_WORKSHOP]
                                : undefined
                        }
                    />
                </NotificationsProvider>
            )}
        </form>
    );
};

export default CreateEventWizardContainer;
