import {
    EVENT_ACCOUNT_SOURCE,
    TEAM_STATE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import i18nStrings from 'constants/i18n-strings';
import * as Yup from 'yup';

export enum FieldName {
    BAN_PARTICIPANTS = 'ban_participants',
    ACCOUNT_SOURCE = 'account_source',
    TEAM_STATE = 'team_state',
    TERMS_ACKNOWLEDGED = 'terms_acknowledged',
}

export type SchemaType = Yup.InferType<typeof Schema>;

const Schema = Yup.object({
    [FieldName.BAN_PARTICIPANTS]: Yup.boolean().optional(),
    [FieldName.ACCOUNT_SOURCE]: Yup.mixed<
        EventEngineEventsService.EventAccountSource
    >()
        .oneOf(Object.values(EVENT_ACCOUNT_SOURCE))
        .required(),
    [FieldName.TEAM_STATE]: Yup.mixed<EventEngineEventsService.TeamState>()
        .oneOf(Object.values(TEAM_STATE))
        .required(),
    [FieldName.TERMS_ACKNOWLEDGED]: Yup.boolean().when(
        FieldName.ACCOUNT_SOURCE,
        {
            is: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
            then: Yup.boolean().test((value, context) => {
                const values: SchemaType = context.parent;

                if (
                    values[FieldName.TEAM_STATE] ===
                    TEAM_STATE.DEPLOYMENT_FAILED
                ) {
                    return !value
                        ? context.createError({
                              message: i18nStrings.required,
                          })
                        : true;
                }

                return true;
            }),
        }
    ),
});

export default Schema;
