import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';

import { Step } from './../components/CreateEventForm.validation';
import { ContentFixtures } from './../fixtures';

export const EVENT_TEMPLATE_ID = '900e39d0-9526-4bde-957a-ab0199a79fbd';
export const EVENT_ID = 'ecd005f4-ec2e-4cc6-898d-86cabb410c48';
export const CONTENT_ID = 'd8eb7eb7-9b4c-421a-81e1-96740bce4609';

export const MOCK_CONTENT_DATA: ContentData =
    ContentFixtures.LIST_CONTENT_DATA[0];

export const ATTRIBUTES = [
    {
        name: 'Salesforce Opportunity ID',
        value: {
            stringValue: '50130000000014c',
        },
    },
    {
        name: 'Engagement Type',
        value: {
            stringValue: 'Yellow Elephants',
        },
    },
    {
        name: 'Geo',
        value: {
            stringListValue: ['AMER'],
        },
    },
];

export const MOCK_INITIAL_VALUES = {
    [Step.SELECT_WORKSHOP]: {
        contentId: 'test-content-id',
        contentBuildId: 'test-content-build-id',
        contentSpecSummary: {
            minAccessibleRegions: 1,
            maxAccessibleRegions: 3,
            accountSources: [EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO],
            deployableRegions: {
                required: ['us-west-2'],
                optional: [],
                recommended: [],
            },
            accessibleRegions: {
                required: ['ap-southeast-1'],
                optional: [],
                recommended: [],
            },
        },
        title: 'DynamoDB day',
    },
    [Step.EVENT_TYPE]: {
        eventType: EVENT_TYPE.TEST,
        accountSource: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
    },
};

export const MOCK_USER_DATA = {
    userId: 'user@domain.com',
    features: {},
};

export const EXPECTED_FORM_VALUES_NO_ATTRIBUTES: EventEngineEventsService.Types.CreateEventRequest = {
    attributes: undefined,
    contentId: CONTENT_ID,
    eventTemplateId: EVENT_TEMPLATE_ID,
    title: 'Event Title',
    description: 'This is the description of the event.',
    scheduledStartTime: new Date('2100-01-01T22:00:00.000Z'),
    scheduledDuration: 60,
    timeZone: 'Etc/GMT+12',
    modality: 'in-person',
    numberOfForecastedParticipants: 20,
    initialQuotaContribution: 0,
    teamSize: 1,
    deploymentRegions: ['ap-southeast-1'],
    accessibleRegions: ['us-west-2', 'us-east-2'],
    permissions: [
        {
            principal: 'test@amazon.com',
            roles: ['administrator'],
        },
    ],
    type: EVENT_TYPE.PRODUCTION,
    accountSource: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
    allowlistAllowAll: false,
};

export const TEST_ID = {
    CREATE_EVENT_FORM: 'create-event-form',
    EVENT_TYPE_FORM: 'event-type-form',
    MODAL_CONFIRM_LEAVE: 'unsaved-confirm',
    MODAL_DECLINE_LEAVE: 'unsaved-cancel',
    FORM_ELEMENT: {
        TITLE: 'title',
        TITLE_ERROR: 'error-EVENT_DETAILS.title',
        DESCRIPTION: 'description',
        DESCRIPTION_ERROR: 'error-EVENT_DETAILS.description',
        PERIOD: 'period',
        PERIOD_ERROR: 'error-EVENT_DETAILS.period',
        EVENT_DATE: 'eventDate',
        EVENT_DATE_ERROR: 'error-EVENT_DETAILS.eventDate',
        TIMEZONE: 'timezone',
        TIMEZONE_ERROR: 'error-EVENT_DETAILS.timezone',
        DURATION: 'duration',
        DURATION_ERROR: 'error-EVENT_DETAILS.duration',
        MODALITY: 'modality',
        MODALITY_ERROR: 'error-EVENT_DETAILS.modality',
        FORECASTED_ATTENDEES: 'forecastedAttendees',
        FORECASTED_ATTENDEES_ERROR: 'error-EVENT_DETAILS.forecastedAttendees',
        TEAM_SIZE: 'teamSize',
        TEAM_SIZE_ERROR: 'error-EVENT_DETAILS.teamSize',
        QUOTA_RESERVATION: 'initialQuotaContribution',
        TOTAL_ACCOUNTS_NEEDED: 'totalAccountsNeeded',
        DEPLOYABLE_REGIONS: 'deploymentRegions',
        ACCESSIBLE_REGIONS: 'accessibleRegions',
        FACILITATORS: 'facilitators',
        GEO: 'dynamic-field-EVENT_DETAILS.Geo',
        GEO_ERROR: 'error-EVENT_DETAILS.Geo',
        ENGAGEMENT_TYPE:
            'dynamic-field-EVENT_DETAILS.Engagement Type.engagementType',
        ENGAGEMENT_TYPE_ERROR:
            'error-EVENT_DETAILS.Engagement Type.engagementType',
        ENGAGEMENT_TYPE_OTHER: 'Engagement Type.engagementTypeOther',
        ENGAGEMENT_TYPE_OTHER_ERROR:
            'error-EVENT_DETAILS.Engagement Type.engagementTypeOther',
        SALESFORCE: 'dynamic-field-EVENT_DETAILS.Salesforce Opportunity ID',
        SALESFORCE_ERROR: 'error-EVENT_DETAILS.Salesforce Opportunity ID',
    },
};
