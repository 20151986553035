import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { hoursToMinutes } from 'date-fns';
import { zonedTimeToUtc } from 'utils/date-fns';

import {
    MergedFormSchema,
    Step,
} from '../components/CreateEventForm.validation';
import {
    ParticipantAllowlistFieldName,
    ParticipantAllowlistFormatRequestData,
} from '../steps/ParticipantAllowlist';
import {
    EnhancedContentTemplateAttribute,
    serializeFormAttributes,
} from './create-event-attributes';
import getRegionsRequestData from './get-regions-request-data';

export interface SerializeFormDataValues {
    values: Pick<
        MergedFormSchema,
        Step.EVENT_TYPE | Step.EVENT_DETAILS | Step.PARTICIPANT_ALLOWLIST
    >;
    attributes: readonly EnhancedContentTemplateAttribute[];
    contentId?: string;
    contentBuildId?: string;
    eventTemplateId?: string;
}

function serializeFormData(
    values: SerializeFormDataValues,
    eventId?: undefined
): EventEngineEventsService.CreateEventRequest;
function serializeFormData(
    values: SerializeFormDataValues,
    eventId?: string
): EventEngineEventsService.UpdateEventInformationRequest;
function serializeFormData(
    {
        values,
        attributes,
        contentId,
        eventTemplateId,
        contentBuildId,
    }: SerializeFormDataValues,
    eventId?: string
) {
    const eventTypeValues = values[Step.EVENT_TYPE];
    const eventDetailsValues = values[Step.EVENT_DETAILS];
    const serializedFormAttributes = serializeFormAttributes(
        attributes,
        eventDetailsValues
    );
    const commonEventData: Pick<
        | EventEngineEventsService.CreateEventRequest
        | EventEngineEventsService.UpdateEventInformationRequest,
        'title' | 'description' | 'modality' | 'attributes'
    > = {
        title: eventDetailsValues.title,
        description: eventDetailsValues.description,
    };

    if (eventTypeValues.eventType === EVENT_TYPE.PRODUCTION) {
        commonEventData.modality = eventDetailsValues.modality;

        if (serializedFormAttributes.length) {
            commonEventData.attributes = serializedFormAttributes;
        }
    }

    if (eventId) {
        return {
            ...commonEventData,
            eventId,
        };
    }

    const participantAllowlistValues = values[Step.PARTICIPANT_ALLOWLIST];
    const createEventData: Partial<EventEngineEventsService.CreateEventRequest> = {
        ...commonEventData,
        eventTemplateId,
        contentId,
        contentBuildId,
        type: eventTypeValues.eventType,
        accountSource: eventTypeValues.accountSource,
        allowlistAllowAll:
            participantAllowlistValues[ParticipantAllowlistFieldName.ALLOW_ALL],
        scheduledStartTime: zonedTimeToUtc(
            eventDetailsValues.eventDate,
            eventDetailsValues.eventTime,
            eventDetailsValues.period,
            {
                timezone: eventDetailsValues.timezone,
            }
        ),
        scheduledDuration: hoursToMinutes(+eventDetailsValues.duration),
        timeZone: eventDetailsValues.timezone,
        numberOfForecastedParticipants: +eventDetailsValues.forecastedAttendees,
        teamSize: eventDetailsValues.teamSize,
        permissions: eventDetailsValues.facilitators?.map((facilitator) => {
            return {
                principal: facilitator.key,
                roles: [facilitator.value],
            };
        }),
    };

    if (
        eventTypeValues.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
    ) {
        const { deploymentRegions, accessibleRegions } = getRegionsRequestData(
            eventDetailsValues.deploymentRegions,
            eventDetailsValues.accessibleRegions
        );

        createEventData.deploymentRegions = deploymentRegions;
        createEventData.accessibleRegions = accessibleRegions;
        createEventData.initialQuotaContribution = +(
            eventDetailsValues.initialQuotaContribution || 0
        );
    }

    if (
        !participantAllowlistValues[ParticipantAllowlistFieldName.ALLOW_ALL] &&
        participantAllowlistValues[ParticipantAllowlistFieldName.EMAIL_PATTERNS]
    ) {
        createEventData.allowlist = ParticipantAllowlistFormatRequestData.getEmailPatterns(
            participantAllowlistValues[
                ParticipantAllowlistFieldName.EMAIL_PATTERNS
            ]!
        );
    }

    return createEventData;
}

export default serializeFormData;
