import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
    countText,
    EmptyMessage,
    getFilteringOptions,
    PropertyFilterConstants,
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import React, { useEffect, useState } from 'react';
import ErrorMessage from 'services/error-message';

import { i18nStrings, tableConfig } from '../../../../../constants';
import {
    columnDefinitions,
    defaultPreferences,
    defaultQuery,
    defaultSortPreferences,
    filteringProperties,
} from './config';
import {
    StackDeploymentEventsTableItem,
    StackDeploymentEventsTableProps,
    TestID,
} from './types';

const StackDeploymentEventsTable = ({
    stack,
    error,
    loading,
    additionalActions,
    storageScope,
    onRefresh,
}: StackDeploymentEventsTableProps) => {
    const deploymentEvents = stack?.failedEvents || [];
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sorting, setSorting] = useState<
        TableProps.SortingState<StackDeploymentEventsTableItem>
    >(
        (storageScope &&
            StoragePartition.getItem(
                StoragePartitionKeys.tableSortColumns,
                storageScope
            )) ||
            defaultSortPreferences
    );
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences
    >(
        (storageScope &&
            StoragePartition.getItem(
                StoragePartitionKeys.tablePreferences,
                storageScope
            )) ||
            defaultPreferences
    );
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(deploymentEvents, {
        propertyFiltering: {
            filteringProperties,
            defaultQuery,
            empty: error ? (
                <EmptyMessage
                    title={
                        i18nStrings.events.teams.stackDeploymentEventsTable
                            .errorTitle
                    }
                    description={ErrorMessage.getMessage(error)}
                />
            ) : (
                <EmptyMessage
                    title={
                        i18nStrings.events.teams.stackDeploymentEventsTable
                            .emptyTitle
                    }
                    description={
                        i18nStrings.events.teams.stackDeploymentEventsTable
                            .emptyDescription
                    }
                />
            ),
            noMatch: (
                <EmptyMessage
                    title={i18nStrings.table.noMatches}
                    description={i18nStrings.table.noMatchesDescription}
                    actions={
                        <Button
                            onClick={() =>
                                actions.setPropertyFiltering(defaultQuery)
                            }>
                            {i18nStrings.table.clearFilters}
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: sorting,
        },
    });

    useEffect(() => {
        storageScope &&
            StoragePartition.setItem(
                StoragePartitionKeys.tableSortColumns,
                sorting,
                storageScope
            );
    }, [sorting]);

    useEffect(() => {
        storageScope &&
            StoragePartition.setItem(
                StoragePartitionKeys.tablePreferences,
                preferences,
                storageScope
            );
    }, [preferences]);

    useEffect(() => {
        !loading && setIsRefreshing(false);
    }, [loading]);

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    counter={`(${deploymentEvents.length})`}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {onRefresh ? (
                                <Button
                                    data-testid={TestID.BUTTON_REFRESH}
                                    disabled={isRefreshing}
                                    loading={isRefreshing}
                                    onClick={() => {
                                        setIsRefreshing(true);
                                        onRefresh();
                                    }}
                                    variant="normal"
                                    iconName="refresh"
                                    ariaLabel={i18nStrings.refreshData}
                                />
                            ) : null}
                            {additionalActions}
                        </SpaceBetween>
                    }>
                    {i18nStrings.events.teams.stackDeploymentEventsTable.title}
                </Header>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={tableConfig.paginationAriaLabels}
                />
            }
            filter={
                <PropertyFilter
                    {...propertyFilterProps}
                    i18nStrings={PropertyFilterConstants.i18nStrings}
                    filteringOptions={getFilteringOptions(
                        deploymentEvents,
                        filteringProperties
                    )}
                    countText={countText(
                        filteredItemsCount || 0,
                        i18nStrings.item
                    )}
                />
            }
            preferences={
                <CollectionPreferences
                    {...tableConfig.collectionPreferencesI18nConstants}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                    visibleContentPreference={{
                        title: i18nStrings.table.selectVisibleColumns,
                        options: [
                            {
                                label: i18nStrings.table.properties,
                                options: columnDefinitions.map((column) => ({
                                    id: column.id as string,
                                    label: column.header as string,
                                    editable: column.editable,
                                })),
                            },
                        ],
                    }}
                    pageSizePreference={tableConfig.pageSizePreference}
                    wrapLinesPreference={tableConfig.wrapLinesPreference}
                />
            }
            loadingText={tableConfig.loadingText}
            loading={isRefreshing}
            onSortingChange={(e) => {
                setSorting(e.detail);
                collectionProps.onSortingChange &&
                    collectionProps.onSortingChange(e);
            }}
            wrapLines={preferences.wrapLines}
        />
    );
};

export default StackDeploymentEventsTable;
