import {
    CONTENT_BUILD_PRUNING_STATE,
    CONTENT_BUILD_STATE,
} from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {
    ErrorBoundary,
    lazyPreviewComponentImport,
    LoadingComponent,
    LogLevel,
    SinkType,
    useLogger,
    useMetric,
    useRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import AppNav from 'components/AppNav';
import { RouteParams } from 'components/AppRoutes/routes.constants';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import ErrorPageController from 'components/ErrorPageController';
import i18nStrings from 'constants/i18n-strings';
import React, {
    ComponentProps,
    PropsWithChildren,
    ReactNode,
    Suspense,
    useCallback,
    useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import EEContentCatalog from 'services/ee-content-catalog';
import ErrorMessage, { MessageTypes } from 'services/error-message';

import {
    breadcrumbsState,
    contentTypeState,
    domElementDirectionState,
    maxContentWidthState,
    navigationContentState,
    navigationOpenState,
    toolsState,
} from '../../atoms';
import PreviewTools from './PreviewTools';

interface PreviewComponentErrorBoundaryProps {
    children: PropsWithChildren<ReactNode>;
}

const LazyPreviewComponent = React.lazy(lazyPreviewComponentImport);
type LazyPreviewComponentProps = ComponentProps<typeof LazyPreviewComponent>;

export const PreviewComponentErrorBoundary: React.FC<PreviewComponentErrorBoundaryProps> = ({
    children,
}: PreviewComponentErrorBoundaryProps) => {
    const { addCounterMetric } = useMetric();
    const [
        errorBoundaryLogger,
    ] = useLogger('PreviewComponentErrorBoundaryLogger', LogLevel.CRITICAL, [
        SinkType.CloudWatchLogSink,
    ]);

    return (
        <ErrorBoundary
            logger={errorBoundaryLogger}
            addCounterMetric={addCounterMetric}
            fallback={
                <ErrorPageController type="preview-component-catastrophic-error" />
            }>
            {children}
        </ErrorBoundary>
    );
};

const Preview = () => {
    const setTools = useSetRecoilState(toolsState);
    const history = useHistory();
    const [previewLogger] = useLogger('PreviewComponentLogger', LogLevel.INFO);
    const params = useParams<RouteParams>();
    const setNavigationOpen = useSetRecoilState(navigationOpenState);
    // a user will be able to navigate back to the workshop details via this URL
    const workshopDetailsUrl = `${routes.workshops}/${params.workshopId}`;
    const { data, isError } = useRequest<
        typeof EEContentCatalog['getContentBuilds'],
        AWSError
    >(
        EEContentCatalog.getContentBuilds,
        { context: EEContentCatalog },
        {
            contentId: params.workshopId!,
            pruningState: CONTENT_BUILD_PRUNING_STATE.NOT_PRUNED,
        }
    );
    const getContentRequest = useCallback<
        NonNullable<LazyPreviewComponentProps['getContentArtifact']>
    >(
        ({ contentBuildId }) =>
            EEContentCatalog.getContentBuild({
                contentBuildId: contentBuildId!,
            }).then(({ contentBuild }) => ({
                artifact: contentBuild!.artifact,
                contentId: contentBuild!.contentId,
                contentBuildId: contentBuild!.contentBuildId,
            })),
        []
    );
    const contentBuildSelection = data?.contentBuilds
        ? {
              contentBuilds: data.contentBuilds
                  .filter(
                      (d) =>
                          d.state?.toLowerCase() === CONTENT_BUILD_STATE.SUCCESS
                  )
                  .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                  .map((d) => d.contentBuildId),
              onContentBuildChange: (
                  contentBuildId: string,
                  locale: string
              ) => {
                  history.push(
                      `${routes.preview}/${params.workshopId}/${routeTokens.builds}/${contentBuildId}/${locale}`
                  );
              },
          }
        : undefined;

    useEffect(() => {
        setNavigationOpen(true);
        setTools({
            open: false,
            hide: false,
            content: <PreviewTools />,
        });

        return () => {
            setTools({
                open: false,
                hide: true,
                content: null,
            });
        };
    }, []);

    if (isError) {
        return (
            <Alert type="error">
                {ErrorMessage.getMessage(
                    isError,
                    MessageTypes.getContentBuilds
                )}
            </Alert>
        );
    }

    return (
        <PreviewComponentErrorBoundary>
            <Suspense
                fallback={
                    <LoadingComponent
                        containerProps={{ margin: 'm' }}
                        spinnerWithLabelProps={{
                            children: 'Loading...',
                        }}
                    />
                }>
                <LazyPreviewComponent
                    logger={previewLogger}
                    contentBuildId={params.previewId}
                    getContentArtifact={getContentRequest}
                    defaultAppNav={<AppNav />}
                    navigationState={navigationContentState}
                    breadcrumbsState={breadcrumbsState}
                    maxContentWidthState={maxContentWidthState}
                    contentTypeState={contentTypeState}
                    domElementDirectionState={domElementDirectionState}
                    exitNavigation={{
                        type: 'link',
                        text: 'Workshop Details',
                        href: workshopDetailsUrl,
                    }}
                    contentBuildSelection={contentBuildSelection}
                    defaultAppTitleName={i18nStrings.appName}
                />
            </Suspense>
        </PreviewComponentErrorBoundary>
    );
};

export default Preview;
