import Link from '@amzn/awsui-components-react/polaris/link';
import i18nStrings from 'constants/i18n-strings';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import { toolsState } from '../../atoms';

export interface InfoLinkProps {
    label?: string;
    toolsContent: React.ReactNode;
}

const InfoLink = ({ label, toolsContent }: InfoLinkProps) => {
    const setTools = useSetRecoilState(toolsState);

    return (
        <Link
            onFollow={() =>
                setTools({
                    open: true,
                    hide: false,
                    content: toolsContent as any,
                })
            }
            variant="info">
            {label || i18nStrings.info}
        </Link>
    );
};

export default InfoLink;
