import { useTable } from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from 'services';

import { FieldName } from '../GenerateWorkshopStaticAssetURLsForm/schema';
import {
    columnDefinitions,
    filteringProperties,
    tableI18nStrings,
    visibleContent,
} from './config';
import processData from './process-data';
import {
    SelectWorkshopAssetsTableProps,
    SelectWorkshopAssetTableItem,
} from './types';

const TABLE_ID = 'SelectWorkshopAssetsTable';
const DEDUPE_KEY = '06012023';

const SelectWorkshopAssetsTable = ({
    assets,
    loading,
    error,
    refetch,
}: SelectWorkshopAssetsTableProps) => {
    const formik = useFormikContext();
    const items = useMemo(() => assets && processData(assets), [assets]);
    const [selectedItems, setSelectedItems] = useState<
        SelectWorkshopAssetTableItem[]
    >();
    const { component } = useTable(items, {
        id: TABLE_ID,
        onRefresh: refetch,
        loading,
        error,
        columnDefinitions,
        visibleContent,
        storageDedupeKey: DEDUPE_KEY,
        i18nStrings: {
            ...tableI18nStrings,
            errorMessage: {
                title: i18nStrings.workshops.assets.table.errorTitle,
                description: error && ErrorMessage.getMessage(error),
            },
            pagination: {
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber: number) =>
                    `Page ${pageNumber} of all pages`,
            },
        },
        filteringProperties,
        selectionType: 'multi',
        selectedItems,
        onSelectionChange: (selectedItems) => {
            setSelectedItems(selectedItems);
            formik.setFieldValue(
                FieldName.SELECTED_ASSETS,
                selectedItems.map(({ s3Key, stagingVersionId }) => ({
                    s3Key,
                    stagingVersionId,
                }))
            );
        },
        trackBy: 's3Key',
    });

    useEffect(() => {
        if (!(items && selectedItems?.length)) {
            return;
        }

        const existingSelectedItems = items.filter(
            ({ s3Key }) =>
                selectedItems.findIndex(
                    (selectedItem) => selectedItem.s3Key === s3Key
                ) > -1
        );

        // ensure removed items are removed from selection
        existingSelectedItems.length !== selectedItems.length &&
            setSelectedItems(existingSelectedItems);

        // ensure form values contain latest data
        formik.setFieldValue(
            FieldName.SELECTED_ASSETS,
            existingSelectedItems.map(({ s3Key, stagingVersionId }) => ({
                s3Key,
                stagingVersionId,
            }))
        );
    }, [items]);

    return component;
};

export default SelectWorkshopAssetsTable;
