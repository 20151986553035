import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';

import getAccountSourceOptions from './get-account-source-options';

/**
 * Checks if the specified contentspec has all the
 * required fields to create an event.
 *
 * @param {EventEngineContentCatalogService.ContentSpecSummary|undefined} contentSpecSummary
 * @returns {boolean}
 */
const isEventReady = (
    contentSpecSummary?: EventEngineContentCatalogService.ContentSpecSummary
) => {
    // Must not be empty
    if (!(contentSpecSummary && Object.keys(contentSpecSummary).length)) {
        return false;
    }

    // Must have at least one valid account source
    if (!getAccountSourceOptions(contentSpecSummary.accountSources).length) {
        return false;
    }

    return true;
};

export default isEventReady;
