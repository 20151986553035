import { SEND_QUOTA_OPERATION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { formatString } from '@amzn/event-engine-js-utils';
import { MAX_TRANSFERABLE_QUOTA } from 'utils/config';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../constants';

export enum FieldName {
    OPERATION = 'operation',
    QUANTITY = 'quantity',
}

export type SchemaType = Yup.InferType<typeof Schema>;

const Schema = Yup.object({
    [FieldName.OPERATION]: Yup.mixed<
        EventEngineEventsService.SendQuotaOperation
    >()
        .required()
        .oneOf(Object.values(SEND_QUOTA_OPERATION)),
    [FieldName.QUANTITY]: Yup.number()
        .required(i18nStrings.required)
        .positive(
            i18nStrings.myQuotas.eventQuota.transferQuotaModal.fields.quantity
                .errorMin
        )
        .integer(
            i18nStrings.myQuotas.eventQuota.transferQuotaModal.fields.quantity
                .errorWholeNumber
        )
        .max(
            MAX_TRANSFERABLE_QUOTA,
            formatString(
                i18nStrings.myQuotas.eventQuota.transferQuotaModal.fields
                    .quantity.errorMax,
                MAX_TRANSFERABLE_QUOTA
            )
        ),
});

export default Schema;
