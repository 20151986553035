type ComplexityLevels = '100' | '200' | '300' | '400' | string;

const complexityLevels: Record<ComplexityLevels, string> = {
    '100': 'Foundational',
    '200': 'Intermediate',
    '300': 'Advanced',
    '400': 'Expert',
};

export default complexityLevels;
