/* istanbul ignore file */
import { withRetry } from '@amzn/event-engine-js-utils';
import { LoadingComponent } from '@amzn/event-engine-js-utils';
import { disableContentPaddingState } from 'atoms';
import FeatureFlag from 'components/FeatureFlag';
import exponentialBackoff, { retryBackoff } from 'constants/retry-backoff';
import { GuardrailsContext } from 'contexts/GuardrailsProvider/GuardrailsProvider';
import CreateEventWizard from 'pages/Events/CreateEvent';
import EditEvent from 'pages/Events/EditEvent';
import EventDetails from 'pages/Events/EventDetails';
import TeamDetails from 'pages/Events/EventDetails/TeamDetails';
import Guardrails from 'pages/Guardrails';
import LandingPage from 'pages/LandingPage';
import PreviewComponent from 'pages/Preview/Preview';
import LatestPreviewBuildComponent from 'pages/Preview/PreviewBuild';
import GenerateWorkshopStaticAssetURLsContainer from 'pages/Workshops/GenerateWorkshopStaticAssetURLs';
import React, { Suspense, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { REGULAR_EXPRESSIONS } from '../../constants';
import {
    AccountType,
    routeParams,
    routes,
    routeTokens,
    SearchParamKey,
} from './routes.constants';

const commonRetryProps = {
    retryFn: exponentialBackoff,
    interval: retryBackoff.interval,
};

// Events Pages
const ListEventsActive = React.lazy(() =>
    withRetry(
        () => import('../../pages/Events/ListEvents/ListEvents.active'),
        commonRetryProps
    )
);
const ListEventsUpcoming = React.lazy(() =>
    withRetry(
        () => import('../../pages/Events/ListEvents/ListEvents.upcoming'),
        commonRetryProps
    )
);
const ListEventsPast = React.lazy(() =>
    withRetry(
        () => import('../../pages/Events/ListEvents/ListEvents.past'),
        commonRetryProps
    )
);

// Quota Pages
const Quotas = React.lazy(() =>
    withRetry(() => import('../../pages/Quotas'), commonRetryProps)
);

// Workshops Pages
const WorkshopCatalog = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopCatalog'),
        commonRetryProps
    )
);
const WorkshopCatalogPublic = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopCatalogPublic'),
        commonRetryProps
    )
);
const CreateWorkshop = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/CreateWorkshop'),
        commonRetryProps
    )
);
const WorkshopDetails = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopDetails.container'),
        commonRetryProps
    )
);
const WorkshopDetailLogs = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopDetailLogs.container'),
        commonRetryProps
    )
);
const WorkshopDetailsPublic = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopDetailsPublic'),
        commonRetryProps
    )
);
const AwsAccountLogin = React.lazy(() =>
    withRetry(
        () =>
            import(
                '../../pages/Events/EventDetails/components/AwsAccountLogin'
            ),
        commonRetryProps
    )
);
const WorkshopAssetStaticUrlDetails = React.lazy(() =>
    withRetry(
        () => import('../../pages/Workshops/WorkshopAssetStaticUrlDetails')
    )
);

const AppRoutes = () => {
    const location = useLocation();
    const { acknowledgementRequired } = useContext(GuardrailsContext);
    const disableContentPaddings = useRecoilValue(disableContentPaddingState);

    return (
        <Suspense
            fallback={
                <LoadingComponent
                    containerProps={{
                        margin: disableContentPaddings ? 'l' : undefined,
                    }}
                    spinnerWithLabelProps={{ boxVariant: 'h1', size: 'big' }}
                />
            }>
            <Switch>
                {/* guardrails */}
                <Route path={routes.guardrails}>
                    <Guardrails />
                </Route>

                {acknowledgementRequired && (
                    <Route path="*">
                        <Redirect
                            to={{
                                pathname: routes.guardrails,
                                search: `${
                                    SearchParamKey.TARGET
                                }=${encodeURIComponent(
                                    location.pathname + location.hash
                                )}`,
                            }}
                        />
                    </Route>
                )}

                {/* events */}
                <Route
                    exact
                    path={`${routes.events}/${routeTokens.events.active}`}>
                    <ListEventsActive />
                </Route>
                <Route
                    exact
                    path={`${routes.events}/${routeTokens.events.upcoming}`}>
                    <ListEventsUpcoming />
                </Route>
                <Route
                    exact
                    path={`${routes.events}/${routeTokens.events.past}`}>
                    <ListEventsPast />
                </Route>
                <Route exact path={`${routes.events}/${routeTokens.create}`}>
                    <CreateEventWizard />
                </Route>
                <Route
                    exact
                    path={`${routes.events}/${routeTokens.edit}/:${routeParams.eventId}(${REGULAR_EXPRESSIONS.uuid})`}>
                    <EditEvent />
                </Route>
                <Route
                    exact
                    path={`${routes.events}/:${routeParams.eventId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.teams}/:${routeParams.teamId}`}>
                    <TeamDetails />
                </Route>
                <Route
                    path={`${routes.events}/:${routeParams.eventId}(${
                        REGULAR_EXPRESSIONS.uuid
                    })/:${routeParams.accountType}(${Object.values(
                        AccountType
                    ).join('|')})/:${routeParams.resourceId}/${
                        routeTokens.login
                    }/:${routeParams.persona}`}>
                    <AwsAccountLogin />
                </Route>
                <Route
                    exact
                    path={`${routes.events}/:${routeParams.eventId}(${REGULAR_EXPRESSIONS.uuid})`}>
                    <EventDetails />
                </Route>
                <Route exact path={routes.events}>
                    <Redirect
                        to={`${routes.events}/${routeTokens.events.active}`}
                    />
                </Route>

                {/* quotas */}
                <Route exact path={routes.quotas}>
                    <Quotas />
                </Route>

                {/* workshops */}
                <Route path={routes.workshops} exact>
                    <WorkshopCatalog />
                </Route>
                <Route
                    path={`${routes.workshops}/${routeTokens.publicWorkshops}`}
                    exact>
                    <WorkshopCatalogPublic />
                </Route>
                <Route path={`${routes.workshops}/${routeTokens.create}`} exact>
                    <CreateWorkshop />
                </Route>
                <Route
                    path={`${routes.workshops}/${routeTokens.edit}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})`}
                    exact>
                    <CreateWorkshop />
                </Route>
                <Route
                    path={`${routes.workshops}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})`}
                    exact>
                    <WorkshopDetails />
                </Route>
                <Route
                    path={`${routes.workshops}/${routeTokens.publicWorkshops}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})`}
                    exact>
                    <WorkshopDetailsPublic />
                </Route>
                <Route
                    exact
                    path={`${routes.workshops}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.builds}/:${routeParams.buildNumber}(${REGULAR_EXPRESSIONS.uuid})`}>
                    <WorkshopDetailLogs />
                </Route>
                <Route
                    exact
                    path={`${routes.workshops}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.assets}/${routeTokens.staticUrls}/${routeTokens.generate}`}>
                    <FeatureFlag
                        fallback={<Redirect to="/" />}
                        name="contentAssetStaticUrls">
                        <GenerateWorkshopStaticAssetURLsContainer />
                    </FeatureFlag>
                </Route>
                <Route
                    path={`${routes.workshops}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.assets}/${routeTokens.staticUrls}/:${routeParams.assetKey}`}>
                    <FeatureFlag
                        fallback={<Redirect to="/" />}
                        name="contentAssetStaticUrls">
                        <WorkshopAssetStaticUrlDetails />
                    </FeatureFlag>
                </Route>

                {/* build preview */}
                <Route
                    path={`${routes.preview}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.builds}/:${routeParams.previewId}(${REGULAR_EXPRESSIONS.uuid})`}>
                    <PreviewComponent />
                </Route>
                <Route
                    path={`${routes.preview}/:${routeParams.workshopId}(${REGULAR_EXPRESSIONS.uuid})/${routeTokens.builds}/${routeTokens.latest}`}>
                    <LatestPreviewBuildComponent />
                </Route>

                {/* homepage and catch all */}
                <Route path={routes.landingPage} exact>
                    <LandingPage />
                </Route>
                <Route path="*">
                    <Redirect to={routes.landingPage} />
                </Route>
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
