import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_STATE_INFRASTRUCTURE,
    EVENT_STATE_RUNTIME,
    EVENT_TYPE,
    TEAM_STATE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Badge, { BadgeProps } from '@amzn/awsui-components-react/polaris/badge';
import Button, {
    ButtonProps,
} from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown, {
    ButtonDropdownProps,
} from '@amzn/awsui-components-react/polaris/button-dropdown';
import Link from '@amzn/awsui-components-react/polaris/link';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    BaseMarkdown,
    EventMatrix,
    formatString,
    StoragePartition,
    StoragePartitionKeys,
    useInterval,
    useLazyRequest,
    usePaginator,
    useVisibilityChange,
} from '@amzn/event-engine-js-utils';
import headerStyles from 'assets/styles/modules/header.module.scss';
import { AWSError } from 'aws-sdk/lib/error';
import { AppTopNavContext } from 'components/AppTopNav/AppTopNav.provider';
import PageHeader from 'components/PageHeader';
import i18nStrings from 'constants/i18n-strings';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { addHours, isAfter } from 'date-fns';
import { useDocumentationLink } from 'hooks';
import getMinAccountRequirement from 'pages/Events/CreateEvent/utils/get-min-account-requirement';
import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';
import ErrorMessage, { MessageTypes } from 'services/error-message';
import {
    DEFAULT_API_PAGE_SIZE,
    EVENT_AUTOSTART_WINDOW_HOURS,
} from 'utils/config';

import CancelEventModal from './components/CancelEventModal';
import CopyJoinEventLinkBtn from './components/CopyJoinEventLinkBtn';
import EventDurationMessage from './components/EventDurationMessage';
import EventTimeline from './components/EventTimeline';
import PauseEventModal from './components/PauseEventModal';
import Tabs from './components/Tabs';
import EditAllowlistModal, {
    DismissState,
    EditAllowlistModalProps,
} from './components/Tabs/components/Participants/components/EditAllowlistModal';
import TerminateEventModal from './components/TerminateEventModal';
import {
    EVENT_DETAILS_REFETCH_INTERVAL,
    EVENT_DURATION_MODAL_SNOOZE,
} from './constants';
import useConfirmProvisioning from './hooks/use-confirm-provisioning';
import {
    ActionID,
    EventDetailsProps,
    NotificationID,
    StoragePartitionName,
} from './types';

const ExternalDocLink = ({ node, ...props }: any) => (
    <Link external {...props} />
);

const EventDetails: React.FC<EventDetailsProps> = ({
    event,
    refetchGetEvent,
    refetchGetEventLoading,
    refetchGetEventError,
}: EventDetailsProps) => {
    const troubleshootingLink = useDocumentationLink(
        '/run-a-workshop/troubleshooting-events-users'
    );
    const [countFailedTeams, setCountFailedTeams] = useState<number>();
    const [isEditAllowlistModalVisible, setIsAllowlistValueVisible] = useState(
        false
    );
    const [isWindowFocused, setIsWindowFocused] = useState(true);
    const [
        eventDurationNotificationEnabled,
        setEventDurationNotificationEnabled,
    ] = useState(false);
    const [eventData, setEventData] = useState<
        EventEngineEventsService.EventData
    >(event);
    const [activeAction, setActiveAction] = useState<ActionID>();
    const clearActiveAction = useCallback(() => {
        setActiveAction(undefined);
    }, []);
    const { refreshQuotaInfo } = useContext(AppTopNavContext);
    const { showNotification, clearNotification } = useContext(
        NotificationsContext
    );
    const isAllowlistValueSpecified = useMemo(
        () => eventData.allowlistAllowAll || !!eventData.allowlist?.length,
        [eventData]
    );
    const needsAccounts = useMemo(() => {
        if (eventData.accountSource !== EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO) {
            return false;
        }

        const totalQuotaContribution = eventData.accountQuotaContributions
            ?.length
            ? eventData.accountQuotaContributions.reduce(
                  (acc, { reserved }) => acc + (reserved || 0),
                  0
              )
            : 0;

        return (
            totalQuotaContribution <
            getMinAccountRequirement(
                eventData.numberOfForecastedParticipants,
                eventData.teamSize,
                eventData.centralAccountRequired
            )
        );
    }, [eventData]);
    /**
     * States that transition automatically after a long-running
     * sync workflow should be placed here so that the timeline
     * can display semi-real time state updates.
     */
    const shouldPollEventDetails = useMemo(
        () =>
            EventMatrix.isTransitoryState({
                runtime: eventData.stateRuntime,
                infrastructure: eventData.stateInfrastructure,
            }),
        [eventData]
    );
    const isEventInProgress = useMemo(
        () =>
            EventMatrix.isEventInProgress({
                runtime: eventData.stateRuntime,
                infrastructure: eventData.stateInfrastructure,
            }),
        [eventData]
    );
    const enableEventDurationNotification = () => {
        setEventDurationNotificationEnabled(true);
        StoragePartition.setItem(
            StoragePartitionKeys.userPreferences,
            null,
            StoragePartitionName.NOTIFICATION_EVENT_DURATION,
            event.eventId
        );
    };
    useVisibilityChange({
        onVisible: () => {
            setIsWindowFocused(true);
        },
        onHidden: () => {
            setIsWindowFocused(false);
        },
    });
    useInterval(
        () => refetchGetEvent(),
        isWindowFocused && shouldPollEventDetails && !refetchGetEventError
            ? EVENT_DETAILS_REFETCH_INTERVAL
            : null
    );
    const [
        cancelEvent,
        {
            data: cancelEventData,
            isError: cancelEventIsError,
            isLoading: cancelEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.cancelEvent, AWSError>(
        EEEvents.cancelEvent,
        {}
    );
    const [
        provisionEvent,
        {
            data: provisionEventData,
            isError: provisionEventIsError,
            isLoading: provisionEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.provisionEvent, AWSError>(
        EEEvents.provisionEvent,
        {}
    );
    const [
        startEvent,
        {
            data: startEventData,
            isError: startEventIsError,
            isLoading: startEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.startEvent, AWSError>(
        EEEvents.startEvent,
        {}
    );
    const [
        terminateEvent,
        {
            data: terminateEventData,
            isError: terminateEventIsError,
            isLoading: terminateEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.terminateEvent, AWSError>(
        EEEvents.terminateEvent,
        {}
    );
    const [
        pauseEvent,
        {
            data: pauseEventData,
            isError: pauseEventIsError,
            isLoading: pauseEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.pauseEvent, AWSError>(
        EEEvents.pauseEvent,
        {}
    );
    const [
        resumeEvent,
        {
            data: resumeEventData,
            isError: resumeEventIsError,
            isLoading: resumeEventIsLoading,
        },
    ] = useLazyRequest<typeof EEEvents.resumeEvent, AWSError>(
        EEEvents.resumeEvent,
        {}
    );
    const [getTeams, { isLoading: isGetTeamsLoading }] = useLazyRequest<
        typeof EEEvents.getTeams,
        AWSError
    >(EEEvents.getTeams, {});
    const paginator = usePaginator(getTeams, DEFAULT_API_PAGE_SIZE, 'teams');
    const actions = useMemo(() => {
        const actions: ButtonDropdownProps.Item[] = [];
        const eventStates = {
            runtime: eventData.stateRuntime,
            infrastructure: eventData.stateInfrastructure,
        };

        if (!EventMatrix.isProvisionStarted(eventStates)) {
            let provisionDisabledReason: string | undefined = undefined;

            if (needsAccounts) {
                provisionDisabledReason =
                    i18nStrings.events.quotas.alerts.warning;
            } else if (!isAllowlistValueSpecified) {
                provisionDisabledReason =
                    i18nStrings.events.eventDetails
                        .allowlistRequiredDisabledReasons.provisionEvent;
            }

            actions.push(
                {
                    id: ActionID.PROVISION,
                    text:
                        i18nStrings.events.eventDetails.actionsDropdown
                            .provision,
                    disabled:
                        !!provisionDisabledReason || provisionEventIsLoading,
                    disabledReason: provisionDisabledReason,
                },
                {
                    id: ActionID.CANCEL,
                    text:
                        i18nStrings.events.eventDetails.actionsDropdown.cancel,
                    disabled: cancelEventIsLoading,
                }
            );
        } else {
            let disabledReason = '';

            if (
                eventData.stateInfrastructure ===
                EVENT_STATE_INFRASTRUCTURE.PROVISION_IN_PROGRESS
            ) {
                disabledReason =
                    i18nStrings.events.eventDetails
                        .terminateEventDisabledReasons.provisionInprogress;
            }

            actions.push({
                id: ActionID.TERMINATE,
                text: i18nStrings.events.eventDetails.actionsDropdown.terminate,
                disabled: terminateEventIsLoading || !!disabledReason,
                disabledReason,
            });
        }

        if (isEventInProgress) {
            if (eventData.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_SUCCESS) {
                actions.push({
                    id: ActionID.RESUME,
                    text:
                        i18nStrings.events.eventDetails.actionsDropdown.resume,
                    disabled: resumeEventIsLoading,
                });
            } else {
                actions.push({
                    id: ActionID.PAUSE,
                    text: i18nStrings.events.eventDetails.actionsDropdown.pause,
                    disabled: pauseEventIsLoading,
                });
            }
        }

        return actions;
    }, [
        eventData,
        needsAccounts,
        isEventInProgress,
        provisionEventIsLoading,
        cancelEventIsLoading,
        terminateEventIsLoading,
        pauseEventIsLoading,
        resumeEventIsLoading,
        isAllowlistValueSpecified,
    ]);
    const badges = useMemo(() => {
        const result: BadgeProps[] = [];

        if (eventData.type === EVENT_TYPE.PRODUCTION) {
            result.push({
                children: i18nStrings.events.eventDetails.badges.production,
                color: 'blue',
            });
        } else {
            result.push({
                children: i18nStrings.events.eventDetails.badges.test,
                color: 'grey',
            });
        }

        if (eventData.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO) {
            result.push({
                children: i18nStrings.events.eventDetails.badges.wsAccounts,
                color: 'green',
            });

            needsAccounts &&
                result.push({
                    children:
                        i18nStrings.events.eventDetails.badges.needsAccounts,
                    color: 'red',
                });
        }

        return result;
    }, [eventData, needsAccounts]);
    const provisionEventStart = (eventId: string) => {
        provisionEvent({ eventId });
        showNotification(
            i18nStrings.events.eventDetails.notificationAccountsProvisioning
                .loading,
            {
                id: NotificationID.ACCOUNTS_PROVISIONING_START,
                clearStack: false,
                loading: true,
            }
        );
    };
    const {
        modalComponent: confirmProvisioningModal,
        showModal: showConfirmProvisionModal,
    } = useConfirmProvisioning({
        event: eventData,
        onConfirm: () => {
            provisionEventStart(eventData.eventId);
        },
    });
    const onProvisionClick = useCallback(() => {
        if (
            eventData.scheduledStartTime &&
            isAfter(
                eventData.scheduledStartTime,
                addHours(new Date(), EVENT_AUTOSTART_WINDOW_HOURS)
            )
        ) {
            showConfirmProvisionModal();
        } else {
            provisionEventStart(eventData.eventId);
        }
    }, [eventData]);
    const onResumeClick = useCallback(() => {
        resumeEvent(eventData.eventId);
    }, [eventData]);
    const onStartEventClick = useCallback(() => {
        startEvent({
            eventId: eventData.eventId,
        });
        showNotification(
            i18nStrings.events.eventDetails.notificationStartEvent.loading,
            {
                id: NotificationID.EVENT_START,
                loading: true,
            }
        );
    }, [eventData]);
    const onTerminateClick = useCallback(async () => {
        if (eventData.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO) {
            const teams = await paginator.getPaginatedData(0, '*', {
                fresh: true,
                clientArgs: { eventId: event.eventId },
            });

            teams &&
                setCountFailedTeams(
                    teams.teams.filter(
                        ({ state }) => state === TEAM_STATE.DEPLOYMENT_FAILED
                    ).length
                );
        }

        setActiveAction(ActionID.TERMINATE);
    }, [eventData]);
    const handleActionClick = useCallback(
        ({
            detail: { id },
        }: {
            detail: ButtonDropdownProps.ItemClickDetails;
        }) => {
            switch (id) {
                case ActionID.TERMINATE:
                    onTerminateClick();
                    break;
                case ActionID.PROVISION:
                    onProvisionClick();
                    break;
                case ActionID.CANCEL:
                    setActiveAction(ActionID.CANCEL);
                    break;
                case ActionID.PAUSE:
                    setActiveAction(ActionID.PAUSE);
                    break;
                case ActionID.RESUME:
                    onResumeClick();
                    break;
            }
        },
        [eventData]
    );
    const onTerminateModalDismissed = useCallback(
        (shouldTerminateEventModal?: boolean) => {
            clearActiveAction();

            if (shouldTerminateEventModal) {
                terminateEvent({
                    eventId: eventData.eventId,
                });
                showNotification(
                    i18nStrings.events.eventDetails.notificationTerminatingEvent
                        .loading,
                    {
                        id: NotificationID.EVENT_TERMINATE,
                        loading: true,
                    }
                );
            }
        },
        [eventData]
    );
    const onCancelModalDismissed = useCallback(
        (shouldCancelEvent?: boolean) => {
            clearActiveAction();

            if (shouldCancelEvent) {
                cancelEvent({
                    eventId: eventData.eventId,
                });
                showNotification(
                    i18nStrings.events.eventDetails.notificationCancelEvent
                        .loading,
                    {
                        id: NotificationID.EVENT_CANCEL,
                        loading: true,
                    }
                );
            }
        },
        [eventData]
    );
    const onPauseModalDismissed = useCallback(
        (pauseConfirmed: boolean) => {
            clearActiveAction();

            if (pauseConfirmed) {
                pauseEvent(eventData.eventId);
                enableEventDurationNotification();
            }
        },
        [eventData]
    );
    const onEditAllowlistModalDismiss = useCallback<
        EditAllowlistModalProps['onDismiss']
    >((state) => {
        setIsAllowlistValueVisible(false);

        if (state === DismissState.UPDATE_SUCCESS) {
            refetchGetEvent();
        }
    }, []);
    const showEditAllowlistModal = useCallback(() => {
        setIsAllowlistValueVisible(true);
    }, []);
    const showErrorNotification = (
        error?: AWSError,
        id = NotificationID.ERROR,
        messageType?: MessageTypes
    ) => {
        error &&
            showNotification(ErrorMessage.getMessage(error, messageType), {
                id,
                clearStack: false,
                type: 'error',
            });
    };
    const startEventAction = useMemo(() => {
        if (
            !EventMatrix.isEventReadyToStart({
                runtime: eventData.stateRuntime,
                infrastructure: eventData.stateInfrastructure,
            })
        ) {
            return null;
        }

        const buttonProps: ButtonProps = {
            variant: 'primary',
            loading: startEventIsLoading,
            disabled: !isAllowlistValueSpecified,
            onClick: onStartEventClick,
            children: startEventIsLoading
                ? i18nStrings.events.eventDetails.actions.startingEvent
                : i18nStrings.events.eventDetails.actions.startEvent,
        };

        if (!isAllowlistValueSpecified) {
            return (
                <Popover
                    content={
                        i18nStrings.events.eventDetails
                            .allowlistRequiredDisabledReasons.startEvent
                    }
                    dismissButton={false}
                    triggerType="custom"
                    size="small">
                    <Button {...buttonProps} iconName="status-warning" />
                </Popover>
            );
        }

        return <Button {...buttonProps} />;
    }, [
        eventData.stateRuntime,
        eventData.stateInfrastructure,
        startEventIsLoading,
        isAllowlistValueSpecified,
        onStartEventClick,
    ]);

    useEffect(() => {
        const eventDurationNotificationLastSnooze = StoragePartition.getItem(
            StoragePartitionKeys.userPreferences,
            StoragePartitionName.NOTIFICATION_EVENT_DURATION,
            event.eventId
        );

        if (
            !eventDurationNotificationLastSnooze ||
            +eventDurationNotificationLastSnooze + EVENT_DURATION_MODAL_SNOOZE <
                Date.now()
        ) {
            enableEventDurationNotification();
        }
    }, []);

    useEffect(() => {
        event && setEventData(event);
    }, [event]);

    useEffect(() => {
        if (
            !(
                eventDurationNotificationEnabled &&
                isEventInProgress &&
                !terminateEventIsLoading &&
                (eventData.nextActionAt
                    ? eventData.nextActionAt > new Date()
                    : false)
            )
        ) {
            clearNotification(NotificationID.EVENT_DURATION);
            return;
        }

        let messageI18nStrings =
            i18nStrings.events.eventDetails.notificationEventInProgress;

        if (pauseEventIsLoading) {
            messageI18nStrings =
                i18nStrings.events.eventDetails.notificationPausingEvent;
        } else if (
            eventData.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_SUCCESS
        ) {
            messageI18nStrings =
                i18nStrings.events.eventDetails.notificationEventPaused;
        }

        showNotification(<EventDurationMessage event={eventData} />, {
            id: NotificationID.EVENT_DURATION,
            header: messageI18nStrings.header,
            clearStack: false,
            loading: pauseEventIsLoading,
            action:
                eventData.stateRuntime === EVENT_STATE_RUNTIME.PAUSE_SUCCESS ? (
                    <Button
                        loading={resumeEventIsLoading}
                        onClick={onResumeClick}>
                        {resumeEventIsLoading
                            ? i18nStrings.events.eventDetails
                                  .notificationEventPaused.actionLoading
                            : i18nStrings.events.eventDetails
                                  .notificationEventPaused.action}
                    </Button>
                ) : undefined,
            onDismiss: () => {
                setEventDurationNotificationEnabled(false);
                StoragePartition.setItem(
                    StoragePartitionKeys.userPreferences,
                    Date.now(),
                    StoragePartitionName.NOTIFICATION_EVENT_DURATION,
                    eventData.eventId
                );
            },
        });
    }, [
        eventDurationNotificationEnabled,
        eventData.nextActionAt?.getTime(),
        eventData.stateRuntime,
        eventData.stateInfrastructure,
        isEventInProgress,
        resumeEventIsLoading,
        pauseEventIsLoading,
        terminateEventIsLoading,
    ]);

    useEffect(() => {
        showErrorNotification(
            cancelEventIsError,
            NotificationID.EVENT_CANCEL,
            MessageTypes.cancelEvent
        );
    }, [cancelEventIsError]);
    useEffect(() => {
        showErrorNotification(
            provisionEventIsError,
            NotificationID.ACCOUNTS_PROVISIONING_START,
            MessageTypes.provisionEvent
        );

        // Refetch event data to update possible state transition.
        // Data poller does not run while the event is scheduled.
        provisionEventIsError && refetchGetEvent();
    }, [provisionEventIsError]);
    useEffect(() => {
        showErrorNotification(
            startEventIsError,
            NotificationID.EVENT_START,
            MessageTypes.startEvent
        );
    }, [startEventIsError]);
    useEffect(() => {
        showErrorNotification(
            terminateEventIsError,
            NotificationID.EVENT_TERMINATE,
            MessageTypes.terminateEvent
        );
    }, [terminateEventIsError]);
    useEffect(() => {
        showErrorNotification(
            pauseEventIsError,
            NotificationID.ERROR,
            MessageTypes.pauseEvent
        );
    }, [pauseEventIsError]);
    useEffect(() => {
        showErrorNotification(
            resumeEventIsError,
            NotificationID.ERROR,
            MessageTypes.resumeEvent
        );
    }, [resumeEventIsError]);

    useEffect(() => {
        if (!cancelEventData?.event) {
            return;
        }

        refreshQuotaInfo();
        setEventData(cancelEventData.event);
        showNotification(
            i18nStrings.events.eventDetails.notificationCancelEvent.success,
            {
                id: NotificationID.EVENT_CANCEL,
                type: 'success',
            }
        );
    }, [cancelEventData]);
    useEffect(() => {
        if (!provisionEventData?.event) {
            return;
        }

        setEventData(provisionEventData.event);
        showNotification(
            i18nStrings.events.eventDetails.notificationAccountsProvisioning
                .success,
            {
                id: NotificationID.ACCOUNTS_PROVISIONING_START,
                type: 'success',
            }
        );
    }, [provisionEventData]);
    useEffect(() => {
        if (!startEventData?.event) {
            return;
        }

        setEventData(startEventData.event);
        clearNotification(NotificationID.EVENT_START);
    }, [startEventData]);
    useEffect(() => {
        if (!terminateEventData?.event) {
            return;
        }

        setEventData(terminateEventData.event);
        showNotification(
            i18nStrings.events.eventDetails.notificationTerminatingEvent
                .success,
            {
                id: NotificationID.EVENT_TERMINATE,
                type: 'success',
            }
        );
    }, [terminateEventData]);
    useEffect(() => {
        if (!pauseEventData?.event) {
            return;
        }

        setEventData(pauseEventData.event);
        enableEventDurationNotification();
    }, [pauseEventData]);
    useEffect(() => {
        resumeEventData?.event && setEventData(resumeEventData.event);
    }, [resumeEventData]);

    useEffect(() => {
        if (
            EventMatrix.compareState(
                eventData.stateRuntime,
                EVENT_STATE_RUNTIME.TERMINATE_IN_PROGRESS,
                'runtime'
            ) >= 0 ||
            !EventMatrix.isEventActive({
                runtime: eventData.stateRuntime,
                infrastructure: eventData.stateInfrastructure,
            }) ||
            isAllowlistValueSpecified
        ) {
            clearNotification(NotificationID.ALLOWLIST_VALUE_MISSING);
            return;
        }

        showNotification(
            i18nStrings.events.participantAllowlist.notifications
                .missingAllowlistValue.content,
            {
                id: NotificationID.ALLOWLIST_VALUE_MISSING,
                action: (
                    <Button onClick={showEditAllowlistModal}>
                        {i18nStrings.events.participantAllowlist.edit}
                    </Button>
                ),
                dismissible: false,
                clearStack: false,
            }
        );
    }, [eventData, isAllowlistValueSpecified]);

    return (
        <>
            {activeAction === ActionID.TERMINATE && (
                <TerminateEventModal
                    event={eventData}
                    isVisible={activeAction === ActionID.TERMINATE}
                    onModalClosed={onTerminateModalDismissed}
                    countFailedTeams={countFailedTeams}
                />
            )}
            {activeAction === ActionID.CANCEL && (
                <CancelEventModal
                    isVisible={activeAction === ActionID.CANCEL}
                    onModalClosed={onCancelModalDismissed}
                />
            )}
            {activeAction === ActionID.PAUSE && (
                <PauseEventModal
                    event={eventData}
                    isVisible={activeAction === ActionID.PAUSE}
                    onModalClosed={onPauseModalDismissed}
                />
            )}
            {isEditAllowlistModalVisible && (
                <EditAllowlistModal
                    eventData={eventData}
                    isVisible={isEditAllowlistModalVisible}
                    onDismiss={onEditAllowlistModalDismiss}
                />
            )}
            {confirmProvisioningModal}
            <PageHeader
                disableTool
                title={eventData.title}
                actions={
                    EventMatrix.isEventActive({
                        runtime: eventData.stateRuntime,
                        infrastructure: eventData.stateInfrastructure,
                    }) && (
                        <SpaceBetween direction="horizontal" size="xs">
                            <ButtonDropdown
                                items={actions}
                                loading={isGetTeamsLoading}
                                onItemClick={handleActionClick}>
                                {i18nStrings.actions}
                            </ButtonDropdown>
                            <CopyJoinEventLinkBtn code={eventData.eventCode} />
                            {EventMatrix.isEventReadyToStart({
                                runtime: eventData.stateRuntime,
                                infrastructure: eventData.stateInfrastructure,
                            }) && startEventAction}
                        </SpaceBetween>
                    )
                }
                description={
                    badges.length ? (
                        <span className={headerStyles.descriptionBadges}>
                            {badges.map((badgeProps, index) => (
                                <Badge key={index} {...badgeProps} />
                            ))}
                        </span>
                    ) : null
                }
            />
            <SpaceBetween direction="vertical" size="m">
                {!!troubleshootingLink &&
                    EventMatrix.isDeploymentResolved({
                        runtime: event.stateRuntime,
                        infrastructure: event.stateInfrastructure,
                    }) &&
                    !EventMatrix.isDeploymentSuccess({
                        runtime: event.stateRuntime,
                        infrastructure: event.stateInfrastructure,
                    }) && (
                        <Alert
                            data-testid="failed-provision-alert"
                            type="error">
                            <BaseMarkdown
                                components={{
                                    p: Fragment,
                                    a: ExternalDocLink,
                                }}
                                linkTarget="_blank">
                                {formatString(
                                    i18nStrings.events.eventDetails
                                        .failedProvisionEventAlert.message,
                                    troubleshootingLink
                                )}
                            </BaseMarkdown>
                        </Alert>
                    )}
                <EventTimeline
                    event={eventData}
                    eventTransition={{
                        cancel: {
                            loading: cancelEventIsLoading,
                            error: cancelEventIsError,
                        },
                        provision: {
                            loading: provisionEventIsLoading,
                            error: provisionEventIsError,
                        },
                        start: {
                            loading: startEventIsLoading,
                            error: startEventIsError,
                        },
                        pause: {
                            loading: pauseEventIsLoading,
                            error: pauseEventIsError,
                        },
                        resume: {
                            loading: resumeEventIsLoading,
                            error: resumeEventIsError,
                        },
                        terminate: {
                            loading: terminateEventIsLoading,
                            error: terminateEventIsError,
                        },
                    }}
                />
                <Tabs
                    event={eventData}
                    refetchGetEvent={refetchGetEvent}
                    refetchGetEventLoading={refetchGetEventLoading}
                    refetchGetEventError={refetchGetEventError}
                    warnings={{
                        QUOTAS: needsAccounts,
                        PARTICIPANTS: !isAllowlistValueSpecified,
                    }}
                />
            </SpaceBetween>
        </>
    );
};

export default EventDetails;
