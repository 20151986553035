import { isBefore, isValid, parse } from 'date-fns';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../constants';
import { FieldName, FormContext } from './types';

const DATE_STRING_FORMAT = 'yyyy-MM-dd hh:mm a';
const stringSchema = Yup.string().trim();
const requiredStringSchema = stringSchema.required(i18nStrings.required);
const startDateSchema = stringSchema
    .required(i18nStrings.events.create.fields.schedule.startDate.required)
    .test({
        message:
            i18nStrings.events.create.fields.schedule.startDate
                .errorMustBeFuture,
        test: (_, context) => {
            const { isProvisionedStarted }: FormContext =
                context.options.context || {};

            if (isProvisionedStarted) {
                return true;
            }

            const values: FormSchemaType = context.parent;

            if (!values[FieldName.START_TIME]) {
                return context.createError({
                    message:
                        i18nStrings.events.create.fields.schedule.startTime
                            .required,
                });
            }

            const parsedStartDate = parse(
                `${values[FieldName.START_DATE]} ${
                    values[FieldName.START_TIME]
                } ${values[FieldName.START_PERIOD]}`,
                DATE_STRING_FORMAT,
                new Date()
            );

            return parsedStartDate >= new Date();
        },
    });
export const endDateSchema = stringSchema
    .required(i18nStrings.events.create.fields.schedule.endDate.required)
    .test({
        message:
            i18nStrings.events.create.fields.schedule.endDate.errorMustBeFuture,
        test: (_, context) => {
            const values: FormSchemaType = context.parent;

            const parsedStartDate = parse(
                `${values[FieldName.START_DATE]} ${
                    values[FieldName.START_TIME]
                } ${values[FieldName.START_PERIOD]}`,
                DATE_STRING_FORMAT,
                new Date()
            );

            if (!values[FieldName.END_TIME]) {
                return context.createError({
                    message:
                        i18nStrings.events.create.fields.schedule.endTime
                            .required,
                });
            }

            const parsedEndDate = parse(
                `${values[FieldName.END_DATE]} ${values[FieldName.END_TIME]} ${
                    values[FieldName.END_PERIOD]
                }`,
                DATE_STRING_FORMAT,
                new Date()
            );

            if (parsedEndDate < new Date()) {
                return false;
            } else if (!isValid(parsedStartDate)) {
                return true;
            } else if (isBefore(parsedEndDate, parsedStartDate)) {
                return context.createError({
                    message:
                        i18nStrings.events.create.fields.schedule.endDate
                            .errorMustBeAfterStart,
                });
            }

            return true;
        },
    });

export const FormSchema = Yup.object({
    [FieldName.TIMEZONE]: requiredStringSchema,
    [FieldName.START_DATE]: startDateSchema,
    [FieldName.START_TIME]: requiredStringSchema,
    [FieldName.START_PERIOD]: requiredStringSchema,
    [FieldName.END_DATE]: endDateSchema,
    [FieldName.END_TIME]: requiredStringSchema,
    [FieldName.END_PERIOD]: requiredStringSchema,
});

export type FormSchemaType = Yup.InferType<typeof FormSchema>;
