import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    StoragePartition,
    StoragePartitionKeys,
    useIsDarkMode,
    useIsMounted,
} from '@amzn/event-engine-js-utils';
import { useSetIsReportWSBugModalOpenState } from 'atoms';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { useHistoryProviderContext } from 'contexts/HistoryProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AppSettings, { ISettings } from 'services/app-settings';

import {
    breadcrumbsState,
    contentTypeState,
    disableContentPaddingState,
    initialAtomStates,
    isAuthenticatedState,
    navigationOpenState,
    toolsState,
} from '../../atoms';
import { i18nStrings } from '../../constants';
import LandingPageConfig, {
    externalLinkProps,
    ILandingPageConfig,
    LANDING_PAGE_LOCATION_STORAGE_KEY,
} from './LandingPage.config';
import styles from './LandingPage.module.scss';
import { LandingPageProps, TestID } from './types';

const { config } = LandingPageConfig;

//#region LandingPageComponents

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({
    href,
    text,
}: {
    href: string;
    text: string;
}) => (
    <Link
        href={href}
        data-testid={`${TestID.EXTERNAL_LINK}_${text}`}
        ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
        target="_blank">
        {text}
    </Link>
);

const CallToActionComponent = () => {
    const history = useHistory();
    const workshopCatalogUrl = `${routes.workshops}/${routeTokens.publicWorkshops}`;
    const onCtaClick = useCallback((e) => {
        e.preventDefault();
        history.push(workshopCatalogUrl);
    }, []);

    return (
        <Container>
            <SpaceBetween size="l">
                <Box variant="h2" padding="n">
                    {i18nStrings.landing.ctaContainer.title}
                </Box>
                <Box variant="p">
                    {i18nStrings.landing.ctaContainer.content}
                </Box>
                <Button
                    data-testid={TestID.PRIMARY_CTA}
                    href={workshopCatalogUrl}
                    onClick={onCtaClick}
                    variant="primary">
                    {i18nStrings.landing.ctaContainer.primaryAction}
                </Button>
            </SpaceBetween>
        </Container>
    );
};
//#endregion

const LandingPage = ({ target }: LandingPageProps) => {
    const isDarkMode = useIsDarkMode(target);
    const location = useLocation();
    const { previousLocation } = useHistoryProviderContext();
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const setReportWSBugModalOpenState = useSetIsReportWSBugModalOpenState();

    useEffect(() => {
        // determine whether or not the user has seen the landing page before
        const hasVisitedPage = StoragePartition.getItem(
            StoragePartitionKeys.userPreferences,
            LANDING_PAGE_LOCATION_STORAGE_KEY
        );

        // determine if this was the first page the user visited
        const isFirstVisitedPage =
            location.pathname === previousLocation?.pathname;

        // Was this the first page the user landed on? If so redirect.
        // If the user explicitly clicked on a link to go to the landing
        // page, do not redirect.
        if (isFirstVisitedPage) {
            if (!hasVisitedPage) {
                StoragePartition.setItem(
                    StoragePartitionKeys.userPreferences,
                    Date.now(),
                    LANDING_PAGE_LOCATION_STORAGE_KEY
                );
            } else {
                setShouldRedirect(true);
            }
        }
    }, []);

    const [documentationList, setDocumentationList] = useState<
        ILandingPageConfig['config']['documentation']['links']
    >(config.documentation.links);

    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const setNavigationOpen = useSetRecoilState(navigationOpenState);
    const setDisableContentPadding = useSetRecoilState(
        disableContentPaddingState
    );
    const setTools = useSetRecoilState(toolsState);
    const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
    const setContentType = useSetRecoilState(contentTypeState);

    useEffect(() => {
        setContentType('default');
        setNavigationOpen(false);
        setDisableContentPadding(true);
        setBreadcrumbs([]);
        setTools(initialAtomStates.tools);

        return () => {
            setDisableContentPadding(false);
        };
    }, []);

    useEffect(() => {
        isAuthenticated &&
            (async () => {
                const settings: ISettings = await AppSettings();

                isMounted.isMounted.current &&
                    settings.documentation &&
                    setDocumentationList(
                        documentationList.map((item) => ({
                            ...item,
                            href: `${settings.documentation}/${item.path}`,
                        }))
                    );
            })();
    }, [isAuthenticated]);

    /**
     * Initially, the href for the documentation list will be empty.
     * Once the async AppSettings call gets resolved in the useEffect,
     * the links will be populated.
     */
    const documentationListItems = documentationList
        .map((docs) => {
            if (!docs.href) {
                return null;
            }

            return <ExternalLinkItem key={docs.id} {...docs} />;
        })
        .filter(Boolean);

    return (
        <>
            {shouldRedirect && <Redirect to={routes.workshops} />}
            <Box
                data-testid={`${TestID.DARK_MODE}_${!!isDarkMode}`}
                margin={{ bottom: 'l' }}>
                <div className={styles.header}>
                    <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
                        <Grid
                            gridDefinition={[
                                {
                                    colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                                    offset: { l: 2, xxs: 1 },
                                },

                                {
                                    colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
                                    offset: { s: 0, xxs: 1 },
                                },
                            ]}>
                            <div className={styles.headerText}>
                                <Box
                                    variant="h1"
                                    fontWeight="bold"
                                    padding={{ bottom: 's', top: 'xxl' }}
                                    fontSize="display-l"
                                    color="inherit">
                                    {config.headerTitle}
                                </Box>
                                <Box
                                    fontWeight="light"
                                    padding="n"
                                    fontSize="display-l"
                                    color="inherit">
                                    {config.headerDescription}
                                </Box>
                            </div>
                            <Box margin={{ bottom: 'xxl' }}>
                                <CallToActionComponent />
                            </Box>
                        </Grid>
                    </Box>
                </div>
                <Box
                    margin={{ top: 's' }}
                    padding={{ top: 's', horizontal: 's' }}>
                    <Grid
                        gridDefinition={[
                            {
                                colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                                offset: { l: 2, xxs: 1 },
                            },
                            {
                                colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
                                offset: { s: 0, xxs: 1 },
                            },
                        ]}>
                        <div className={styles.mainContent}>
                            <SpaceBetween size="xl">
                                <section>
                                    <Box
                                        fontSize="heading-xl"
                                        fontWeight="normal"
                                        variant="h2"
                                        margin={{ bottom: 'xs' }}>
                                        {
                                            i18nStrings.landing.sections
                                                .howItWorks.title
                                        }
                                    </Box>
                                    <Container>
                                        <SpaceBetween
                                            size="l"
                                            direction="vertical">
                                            {LandingPageConfig.config.sections.howItWorks.map(
                                                ({
                                                    iconUrl,
                                                    title,
                                                    description,
                                                }) => (
                                                    <section
                                                        className={
                                                            styles.imageSection
                                                        }
                                                        key={title.action}>
                                                        <div>
                                                            <img
                                                                src={
                                                                    isDarkMode
                                                                        ? iconUrl.dark
                                                                        : iconUrl.light
                                                                }
                                                                alt={`${title.action} image`}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Box
                                                                variant="h3"
                                                                padding={{
                                                                    vertical:
                                                                        'n',
                                                                }}
                                                                margin={{
                                                                    vertical:
                                                                        'n',
                                                                }}>
                                                                <strong>
                                                                    {
                                                                        title.action
                                                                    }
                                                                </strong>{' '}
                                                                {title.target}
                                                            </Box>
                                                            <Box
                                                                variant="p"
                                                                color="text-body-secondary">
                                                                {description}
                                                            </Box>
                                                        </div>
                                                    </section>
                                                )
                                            )}
                                        </SpaceBetween>
                                    </Container>
                                </section>
                            </SpaceBetween>
                        </div>
                        <div className={styles.secondaryContent}>
                            <SpaceBetween size="xxl">
                                <Container
                                    header={
                                        <Header variant="h2">
                                            {config.documentation.title}
                                        </Header>
                                    }>
                                    <ColumnLayout borders="horizontal">
                                        {documentationListItems}
                                    </ColumnLayout>
                                </Container>

                                <Container
                                    header={
                                        <Header variant="h2">
                                            {config.moreResources.title}
                                        </Header>
                                    }>
                                    <ColumnLayout borders="horizontal">
                                        {config.moreResources.resources
                                            .map((resource) => (
                                                <ExternalLinkItem
                                                    key={resource.id}
                                                    {...resource}
                                                />
                                            ))
                                            .filter(Boolean)}
                                        <Link
                                            onFollow={() =>
                                                setReportWSBugModalOpenState(
                                                    true
                                                )
                                            }>
                                            {i18nStrings.links.reportBug}
                                        </Link>
                                    </ColumnLayout>
                                </Container>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default LandingPage;
