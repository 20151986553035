import { SpaceBetween } from '@amzn/awsui-components-react';
import {
    formatString,
    FormikField,
    InputField,
    useModal,
} from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';

import validationSchema, { FieldName, SchemaType } from './schema';

export interface DurationWarningModalOptions {
    duration: number;
    onModalClosed: (confirmDuration: boolean) => void;
}

const useDurationWarningModal = ({
    duration,
    onModalClosed,
}: DurationWarningModalOptions) => {
    const confirmationText = useMemo(() => `${duration} hours`, [duration]);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const onSubmit = (values: Partial<SchemaType>) => {
        closeModal(true);
    };
    const formik = useFormik({
        validationSchema,
        initialValues: {
            [FieldName.CONFIRM]: '',
        },
        validateOnChange,
        validateOnBlur: false,
        onSubmit,
    });

    const closeModal = (confirmDuration: boolean) => {
        onModalClosed(confirmDuration);
        useModalResult.hideModal();
    };

    const showModal = useCallback(() => {
        setValidateOnChange(false);
        formik.resetForm({
            values: {
                [FieldName.CONFIRMATION_STRING]: confirmationText,
            },
        });
        useModalResult.showModal();
    }, [confirmationText]);

    const useModalResult = useModal({
        modalHeader: i18nStrings.events.durationWarning.modal.title,
        actions: {
            primary: {
                text: i18nStrings.events.durationWarning.modal.confirm,
                onClick: () => {
                    setValidateOnChange(true);
                    formik.handleSubmit();
                },
                disabled: !formik.isValid,
            },
            tertiary: {
                text: i18nStrings.events.durationWarning.modal.close,
                onClick: () => {
                    closeModal(false);
                },
            },
        },
        optionalModalProps: {
            size: 'large',
        },
        onDismiss: () => closeModal(false),
        content: (
            <SpaceBetween size="l">
                <div>
                    {formatString(
                        i18nStrings.events.durationWarning.modal.message,
                        duration
                    )}
                </div>
                <FormikProvider value={formik}>
                    <SpaceBetween direction="vertical" size="m">
                        <FormikField<SchemaType>
                            name={FieldName.CONFIRM}
                            dataTestId={FieldName.CONFIRM}
                            formFieldProps={{
                                label: formatString(
                                    i18nStrings.events.durationWarning.modal
                                        .confirmationLabel,
                                    confirmationText
                                ),
                                description:
                                    i18nStrings.events.durationWarning.modal
                                        .confirmationDescription,
                                children: (
                                    <InputField<SchemaType>
                                        name={FieldName.CONFIRM}
                                        inputProps={{
                                            type: 'text',
                                        }}
                                        placeholder={confirmationText}
                                    />
                                ),
                            }}
                        />
                    </SpaceBetween>
                </FormikProvider>
            </SpaceBetween>
        ),
    });

    return { modalComponent: useModalResult.modalComponent, showModal };
};

export default useDurationWarningModal;
