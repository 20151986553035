import { SEND_QUOTA_OPERATION } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { TilesProps } from '@amzn/awsui-components-react/polaris/tiles';
import React from 'react';

import { i18nStrings } from '../../../../constants';
import TransferGraphic from './TransferGraphic';

export const SEND_QUOTA_OPERATION_OPTIONS: TilesProps.TilesDefinition[] = [
    {
        value: SEND_QUOTA_OPERATION.GIVE,
        label: i18nStrings.myQuotas.eventQuota.actions.transferQuotaToEE,
        description:
            i18nStrings.myQuotas.eventQuota.actions
                .transferQuotaToEEDescription,
        image: <TransferGraphic operation={SEND_QUOTA_OPERATION.GIVE} />,
    },
    {
        value: SEND_QUOTA_OPERATION.TAKE,
        label: i18nStrings.myQuotas.eventQuota.actions.transferQuotaFromEE,
        description:
            i18nStrings.myQuotas.eventQuota.actions
                .transferQuotaFromEEDescription,
        image: <TransferGraphic operation={SEND_QUOTA_OPERATION.TAKE} />,
    },
];
