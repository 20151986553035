import { formatString } from '@amzn/event-engine-js-utils';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../../../../../constants';

export enum FieldName {
    MAX = 'max',
    AMOUNT = 'amount',
}

export type SchemaType = Yup.InferType<typeof Schema>;

const Schema = Yup.object({
    [FieldName.MAX]: Yup.number().required(),
    [FieldName.AMOUNT]: Yup.number()
        .min(
            1,
            i18nStrings.events.quotas.addReservationModal.fields.amount.errorMin
        )
        .required(i18nStrings.required)
        .test((value: number | undefined, context) => {
            const max = context.parent[FieldName.MAX];

            if (value! > max) {
                return context.createError({
                    message: formatString(
                        i18nStrings.events.quotas.addReservationModal.fields
                            .amount.errorMax,
                        max
                    ),
                });
            }

            return true;
        }),
});

export default Schema;
