import { PERSONA } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    AWSAccountAccessModal,
    EventMatrix,
    formatString,
    getStringList,
    useLazyRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import React, {
    ComponentProps,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';

import { i18nStrings } from '../../../../../constants';
import {
    ACCOUNT_ACCESSIBLE_STATES,
    AWSSTSCredentials,
    EventData,
    NotificationID,
    TeamData,
} from './types';

const useTeamAccountAccess = () => {
    const eventData = useRef<EventData>();
    const teamData = useRef<TeamData>();
    const isDisabled = useCallback(
        (
            { stateRuntime, stateInfrastructure }: EventData,
            { state }: TeamData
        ) =>
            !(
                EventMatrix.isEventActive({
                    runtime: stateRuntime,
                    infrastructure: stateInfrastructure,
                }) && ACCOUNT_ACCESSIBLE_STATES.includes(state)
            ),
        []
    );
    const { showNotification, clearNotification } = useContext(
        NotificationsContext
    );
    const [
        awsAccountAccessRoleConfigs,
        setAWSAccountAccessRoleConfigs,
    ] = useState<ComponentProps<typeof AWSAccountAccessModal>['roleConfigs']>();
    const [
        getTeamRoleCredentials,
        {
            data: teamRoleCredentials,
            isLoading: teamRoleCredentialsLoading,
            isError: teamRoleCredentialsError,
        },
    ] = useLazyRequest<typeof EEEvents.getTeamCredentials, AWSError>(
        EEEvents.getTeamCredentials,
        {}
    );
    const [
        getOpsRoleCredentials,
        {
            data: opsRoleCredentials,
            isLoading: opsRoleCredentialsLoading,
            isError: opsRoleCredentialsError,
        },
    ] = useLazyRequest<typeof EEEvents.getTeamCredentials, AWSError>(
        EEEvents.getTeamCredentials,
        {}
    );
    const [
        getTeamLoginLink,
        {
            data: teamLoginLink,
            isLoading: teamLoginLinkLoading,
            isError: teamLoginLinkError,
        },
    ] = useLazyRequest<typeof EEEvents.getTeamLoginLink, AWSError>(
        EEEvents.getTeamLoginLink,
        {}
    );
    const [
        getOpsLoginLink,
        {
            data: opsLoginLink,
            isLoading: opsLoginLinkLoading,
            isError: opsLoginLinkError,
        },
    ] = useLazyRequest<typeof EEEvents.getTeamLoginLink, AWSError>(
        EEEvents.getTeamLoginLink,
        {}
    );
    const showModal = useCallback((event: EventData, team: TeamData) => {
        clearNotification?.(NotificationID.ERROR);

        eventData.current = event;
        teamData.current = team;

        if (isDisabled(event, team)) {
            return;
        }

        const baseRequestParams = {
            eventId: event.eventId,
            teamId: team.teamId,
        };

        getTeamRoleCredentials({
            ...baseRequestParams,
            persona: PERSONA.WS_PARTICIPANT,
        });
        getOpsRoleCredentials({
            ...baseRequestParams,
            persona: PERSONA.WS_OPS,
        });
        getTeamLoginLink({
            ...baseRequestParams,
            persona: PERSONA.WS_PARTICIPANT,
        });
        getOpsLoginLink({
            ...baseRequestParams,
            persona: PERSONA.WS_OPS,
        });
    }, []);
    const dismissModal = useCallback(() => {
        setAWSAccountAccessRoleConfigs(undefined);
    }, []);

    useEffect(() => {
        if (
            !(
                teamData.current &&
                eventData.current &&
                teamRoleCredentials?.credentials &&
                opsRoleCredentials?.credentials &&
                teamLoginLink?.loginLink &&
                opsLoginLink?.loginLink
            )
        ) {
            return;
        }

        const consoleLoginBaseUrl = `${routes.events}/${eventData.current.eventId}/${routeTokens.teams}/${teamData.current.teamId}/${routeTokens.login}`;

        setAWSAccountAccessRoleConfigs([
            {
                id: PERSONA.WS_OPS,
                message:
                    i18nStrings.events.accessAWSAccountModal.opsRole.message,
                name: i18nStrings.events.accessAWSAccountModal.opsRole.title,
                credentials: opsRoleCredentials.credentials as AWSSTSCredentials,
                federatedLoginUrl: opsLoginLink?.loginLink,
                consoleLoginUrl: `${consoleLoginBaseUrl}/${PERSONA.WS_OPS}`,
            },
            {
                id: PERSONA.WS_PARTICIPANT,
                message:
                    i18nStrings.events.accessAWSAccountModal.participantRole
                        .message,
                name:
                    i18nStrings.events.accessAWSAccountModal.participantRole
                        .title,
                credentials: teamRoleCredentials.credentials as AWSSTSCredentials,
                federatedLoginUrl: teamLoginLink?.loginLink,
                consoleLoginUrl: `${consoleLoginBaseUrl}/${PERSONA.WS_PARTICIPANT}`,
            },
        ]);
    }, [teamRoleCredentials, opsRoleCredentials, teamLoginLink, opsLoginLink]);

    useEffect(() => {
        if (
            !(
                teamRoleCredentialsError ||
                opsRoleCredentialsError ||
                teamLoginLinkError ||
                opsLoginLinkError
            )
        ) {
            return;
        }

        showNotification?.(
            i18nStrings.events.teams.notifications.teamAccountAccessError
        );
    }, [
        teamRoleCredentialsError,
        opsRoleCredentialsError,
        teamLoginLinkError,
        opsLoginLinkError,
    ]);

    return {
        showModal,
        isDisabled,
        loading:
            teamRoleCredentialsLoading ||
            opsRoleCredentialsLoading ||
            teamLoginLinkLoading ||
            opsLoginLinkLoading,
        modal: awsAccountAccessRoleConfigs ? (
            <AWSAccountAccessModal
                header={i18nStrings.events.accessAWSAccountModal.header}
                roleConfigs={awsAccountAccessRoleConfigs}
                visible={!!awsAccountAccessRoleConfigs}
                onDismiss={dismissModal}
                infoMessage={
                    eventData.current?.accessibleRegions &&
                    formatString(
                        i18nStrings.events.accessAWSAccountModal.infoMessage,
                        getStringList(
                            eventData.current.accessibleRegions.map(
                                (region) => `\`${region}\``
                            ),
                            i18nStrings.and
                        )
                    )
                }
                size="large"
            />
        ) : null,
    };
};

export default useTeamAccountAccess;
