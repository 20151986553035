import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_STATE_INFRASTRUCTURE,
    EVENT_STATE_RUNTIME,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { TimelineProps } from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { ReactNode } from 'react';

export type EventStates = EVENT_STATE_RUNTIME | EVENT_STATE_INFRASTRUCTURE;

export interface EventTimelineProps {
    event: EventEngineEventsService.EventData;
    eventTransition: EventTransition;
}

export enum TimelineStepID {
    Scheduled = 'SCHEDULED',
    Provisioning = 'PROVISIONING',
    Event = 'EVENT',
    Termination = 'TERMINATION',
}

/**
 * Transient states that occur before actual event states are transitioned.
 * These will usually be API loading and error states.
 */
export interface EventTransitionStates {
    loading: boolean;
    error?: AWSError;
}

export interface EventTransition {
    cancel: EventTransitionStates;
    provision: EventTransitionStates;
    start: EventTransitionStates;
    pause: EventTransitionStates;
    resume: EventTransitionStates;
    terminate: EventTransitionStates;
}

export type TimelineStep = TimelineProps['steps'][0];

export type TimelineFooterCategories = {
    [key in EVENT_ACCOUNT_SOURCE]?: TimelineFooterCategory;
};

export type TimelineFooterCategory = {
    [key in EventStates]?: TimelineFooter;
};

export interface TimelineFooter {
    header: ReactNode;
    content: ReactNode;
}

export type TimelineStepConfig = (
    event: EventEngineEventsService.EventData,
    transitions: EventTransition,
    teams?: EventEngineEventsService.TeamList
) => TimelineStep | undefined;

export type EventStateStepIDLookup = Record<EventStates, TimelineStepID>;
