import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { EmptyMessage } from '@amzn/event-engine-js-utils';
import React, { useState } from 'react';

import { TestID } from '../../types';
import {
    columnDefinitions,
    defaultPreferences,
    defaultSortPreferences,
    i18nStrings,
} from './config';
import { ReviewFacilitatorsTableItem } from './types';

export interface ReviewFacilitatorsTableProps {
    items?: ReviewFacilitatorsTableItem[];
}

const ReviewFacilitatorsTable = ({
    items: inputItems,
}: ReviewFacilitatorsTableProps) => {
    const [sorting, setSorting] = useState<
        TableProps.SortingState<ReviewFacilitatorsTableItem>
    >(defaultSortPreferences);
    const { items, collectionProps } = useCollection(inputItems || [], {
        sorting: {
            defaultState: sorting,
        },
    });

    return (
        <Table
            {...collectionProps}
            data-testid={TestID.CONTAINER_FACILITATORS}
            header={
                <Header counter={`(${inputItems ? inputItems.length : 0})`}>
                    {
                        i18nStrings.events.create.sections.facilitators.table
                            .title
                    }
                </Header>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={defaultPreferences.visibleContent}
            items={items}
            onSortingChange={(e) => {
                setSorting(e.detail);
                collectionProps.onSortingChange &&
                    collectionProps.onSortingChange(e);
            }}
            wrapLines={defaultPreferences.wrapLines}
            empty={
                <EmptyMessage
                    title={
                        i18nStrings.events.create.sections.facilitators.table
                            .emptyTitle
                    }
                    description={
                        i18nStrings.events.create.sections.facilitators.table
                            .emptyDescription
                    }
                />
            }
            resizableColumns
        />
    );
};

export default ReviewFacilitatorsTable;
