import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Button from '@amzn/awsui-components-react/polaris/button';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Tabs, { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import useFeatureFlag from 'hooks/use-feature-flag';
import CopyJoinEventLinkBtn from 'pages/Events/EventDetails/components/CopyJoinEventLinkBtn';
import EventParticipantsTable from 'pages/Events/EventDetails/components/EventParticipantsTable';
import { defaultPreferences } from 'pages/Events/EventDetails/components/EventParticipantsTable/config';
import useTeamAccountAccess from 'pages/Events/EventDetails/hooks/use-team-account-access';
import useTerminateTeam from 'pages/Events/EventDetails/hooks/use-terminate-team';
import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { i18nStrings } from '../../../../constants';
import TeamDetailsContainer from './components/TeamDetailsContainer';
import TeamStacks from './components/TeamStacks';
import {
    STORAGE_SCOPE_TEAM_PARTICIPANTS_TABLE,
    TeamDetailsContext,
} from './constants';
import { ActionID, TabID } from './types';

const TeamDetails = () => {
    // TODO: Remove once teamCodeJoining is generally available
    const teamCodeFeatureEnabled = useFeatureFlag('teamCodeJoining');
    const history = useHistory();
    const location = useLocation();
    const {
        eventRequest,
        teamRequest,
        participantsRequest,
        isLoading,
    } = useContext(TeamDetailsContext);
    const onTerminateSuccess = useCallback(() => {
        eventRequest.refetch();
        teamRequest.refetch();
    }, []);
    const {
        showModal: showTeamAccountAccessModal,
        isDisabled: isTeamAccountAccessDisabled,
        loading: isTeamAccountAccessModelOpening,
        modal: teamAccountAccessModal,
    } = useTeamAccountAccess();
    const {
        showModal: showTerminateTeamConfirmationModal,
        isDisabled: isTerminateTeamDisabled,
        modal: terminateTeamConfirmationModal,
    } = useTerminateTeam({
        onSuccess: onTerminateSuccess,
    });
    const hashActiveTabId = location.hash.replace(/^#/, '') as TabID;
    const [activeTabId, setActiveTabId] = useState<TabID>(
        Object.values(TabID).includes(hashActiveTabId)
            ? hashActiveTabId
            : TabID.STACKS
    );
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tablePreferences,
            STORAGE_SCOPE_TEAM_PARTICIPANTS_TABLE
        ) || defaultPreferences
    );
    const teamParticipants = useMemo(() => {
        const res: EventEngineEventsService.ParticipantData[] = [];

        if (
            !(
                participantsRequest.data?.participants.length &&
                teamRequest.data?.team?.participantIds?.length
            )
        ) {
            return res;
        }

        return participantsRequest.data.participants.filter(
            ({ participantId }) =>
                teamRequest.data?.team?.participantIds?.includes(participantId)
        );
    }, [participantsRequest.data, teamRequest.data]);

    if (!(eventRequest.data?.event && teamRequest.data?.team)) {
        return null;
    }

    const actions: ReactNode[] = [];
    const tabs: TabsProps.Tab[] = [
        {
            id: TabID.PARTICIPANTS,
            label: i18nStrings.events.teams.detailTabs.participants,
            content: (
                <EventParticipantsTable
                    participants={teamParticipants}
                    event={eventRequest.data.event}
                    preferences={preferences}
                    onPreferenceChange={setPreferences}
                    error={participantsRequest.isError}
                    loading={participantsRequest.isLoading}
                    refetch={participantsRequest.refetch}
                    storageScope={STORAGE_SCOPE_TEAM_PARTICIPANTS_TABLE}
                />
            ),
        },
    ];

    if (
        eventRequest.data.event.accountSource ===
        EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
    ) {
        tabs.unshift({
            id: TabID.STACKS,
            label: i18nStrings.events.teams.detailTabs.stacks,
            content: <TeamStacks />,
        });

        !isTeamAccountAccessDisabled(
            eventRequest.data.event,
            teamRequest.data.team
        ) &&
            actions.push(
                <Button
                    key={ActionID.ACCESS_AWS_ACCOUNT}
                    data-testid={ActionID.ACCESS_AWS_ACCOUNT}
                    disabled={isLoading}
                    onClick={() =>
                        showTeamAccountAccessModal(
                            eventRequest.data?.event!,
                            teamRequest.data?.team!
                        )
                    }
                    loading={isTeamAccountAccessModelOpening}
                    iconName="key">
                    {i18nStrings.events.teams.table.actions.accessAWSAccount}
                </Button>
            );
    }

    if (!isTerminateTeamDisabled(teamRequest.data.team)) {
        teamCodeFeatureEnabled &&
            actions.unshift(
                <CopyJoinEventLinkBtn
                    key={ActionID.COPY_EVENT_LINK_ACTION}
                    code={teamRequest.data.team.teamCode}
                />
            );

        actions.unshift(
            <Button
                key={ActionID.TERMINATE_TEAM}
                data-testid={ActionID.TERMINATE_TEAM}
                disabled={isLoading}
                onClick={() =>
                    showTerminateTeamConfirmationModal(
                        eventRequest.data?.event!,
                        teamRequest.data?.team!
                    )
                }>
                {i18nStrings.events.teams.table.actions.terminate}
            </Button>
        );
    }

    useEffect(() => {
        tabs.length > 1 &&
            history.replace({
                ...location,
                hash: activeTabId,
            });
    }, [activeTabId]);

    return (
        <>
            <ContentLayout
                header={
                    <Header
                        variant="h1"
                        actions={
                            actions.length ? (
                                <SpaceBetween direction="horizontal" size="xs">
                                    {actions}
                                </SpaceBetween>
                            ) : null
                        }>
                        {teamRequest.data.team.name}
                    </Header>
                }>
                <SpaceBetween direction="vertical" size="l">
                    <TeamDetailsContainer
                        event={eventRequest.data.event}
                        team={teamRequest.data.team}
                    />
                    {tabs.length > 1 ? (
                        <Tabs
                            activeTabId={activeTabId}
                            onChange={({ detail: { activeTabId } }) =>
                                setActiveTabId(activeTabId as TabID)
                            }
                            tabs={tabs}
                        />
                    ) : (
                        tabs[0].content
                    )}
                </SpaceBetween>
            </ContentLayout>
            {teamAccountAccessModal}
            {terminateTeamConfirmationModal}
        </>
    );
};

export default TeamDetails;
