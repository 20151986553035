import Alert from '@amzn/awsui-components-react/polaris/alert';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import {
    BaseMarkdown,
    formatString,
    useInterval,
    ValueWithLabel,
} from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import { useModal } from 'hooks';
import React, { Fragment, useEffect, useState } from 'react';

import getZonedEventTimes from './get-zoned-event-times';
import { UseConfirmProvisioningOptions, ZonedTimeStrings } from './types';

const TIME_UPDATE_INTERVAL_MS = 5000;

const useConfirmProvisioning = ({
    event,
    onConfirm,
}: UseConfirmProvisioningOptions) => {
    const [zonedTimes, setZonedTimes] = useState<ZonedTimeStrings>();
    const { modalComponent, hideModal, showModal, visible } = useModal({
        modalHeader: i18nStrings.events.confirmProvisionModal.header,
        actions: {
            tertiary: {
                text: i18nStrings.events.confirmProvisionModal.actionCancel,
                onClick: () => {
                    hideModal();
                },
            },
            primary: {
                text: i18nStrings.events.confirmProvisionModal.actionProvision,
                onClick: () => {
                    onConfirm?.();
                    hideModal();
                },
            },
        },
        content: zonedTimes && (
            <Alert type="warning">
                <SpaceBetween direction="vertical" size="l">
                    <BaseMarkdown components={{ p: Fragment }}>
                        {formatString(
                            i18nStrings.events.confirmProvisionModal.content,
                            zonedTimes.scheduledStartTime,
                            zonedTimes.actualStartTime,
                            zonedTimes.actualTerminationTime
                        )}
                    </BaseMarkdown>
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween direction="vertical" size="l">
                            <ValueWithLabel label="Scheduled start time">
                                {zonedTimes.scheduledStartTime}
                            </ValueWithLabel>
                            <ValueWithLabel label="Scheduled termination time">
                                {zonedTimes.scheduledTerminationTime}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween direction="vertical" size="l">
                            <ValueWithLabel label="Actual start time">
                                <StatusIndicator type="warning">
                                    {zonedTimes.actualStartTime}
                                </StatusIndicator>
                            </ValueWithLabel>
                            <ValueWithLabel label="Actual termination time">
                                <StatusIndicator type="warning">
                                    {zonedTimes.actualTerminationTime}
                                </StatusIndicator>
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </SpaceBetween>
            </Alert>
        ),
    });

    useInterval(
        () => {
            setZonedTimes(getZonedEventTimes(event));
        },
        visible ? TIME_UPDATE_INTERVAL_MS : null
    );

    useEffect(() => {
        visible && setZonedTimes(getZonedEventTimes(event));
    }, [visible]);

    return {
        modalComponent,
        showModal,
        visible,
    };
};

export default useConfirmProvisioning;
