import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Table from '@amzn/awsui-components-react/polaris/table';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
    countText,
    EmptyMessage,
    getFilteringOptions,
    PropertyFilterConstants,
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import tableConfig from 'constants/table-config';
import React, { useEffect, useState } from 'react';
import ErrorMessage from 'services/error-message';

import {
    columnDefinitions,
    defaultPreferences,
    defaultQuery,
    defaultSortPreferences,
    filteringProperties,
} from './config';
import { OutputsTableItem, OutputsTableProps } from './types';

const OutputsTable = ({
    outputs,
    error,
    loading,
    storageScope,
}: OutputsTableProps) => {
    const [sorting, setSorting] = useState<
        TableProps.SortingState<OutputsTableItem>
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tableSortColumns,
            storageScope
        ) || defaultSortPreferences
    );
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tablePreferences,
            storageScope
        ) || defaultPreferences
    );
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(outputs, {
        propertyFiltering: {
            filteringProperties,
            defaultQuery,
            empty: error ? (
                <EmptyMessage
                    title={
                        i18nStrings.events.teams.stackOutputsTable.errorTitle
                    }
                    description={ErrorMessage.getMessage(error)}
                />
            ) : (
                <EmptyMessage
                    title={
                        i18nStrings.events.teams.stackOutputsTable.emptyTitle
                    }
                    description={
                        i18nStrings.events.teams.stackOutputsTable
                            .emptyDescription
                    }
                />
            ),
            noMatch: (
                <EmptyMessage
                    title={i18nStrings.table.noMatches}
                    description={i18nStrings.table.noMatchesDescription}
                    actions={
                        <Button
                            onClick={() =>
                                actions.setPropertyFiltering(defaultQuery)
                            }>
                            {i18nStrings.table.clearFilters}
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: sorting,
        },
    });

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.tableSortColumns,
            sorting,
            storageScope
        );
    }, [sorting]);

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.tablePreferences,
            preferences,
            storageScope
        );
    }, [preferences]);

    return (
        <Table
            {...collectionProps}
            header={
                <Header counter={`(${outputs.length})`}>
                    {i18nStrings.events.teams.stackOutputsTable.title}
                </Header>
            }
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={tableConfig.paginationAriaLabels}
                />
            }
            filter={
                <PropertyFilter
                    {...propertyFilterProps}
                    i18nStrings={PropertyFilterConstants.i18nStrings}
                    filteringOptions={getFilteringOptions(
                        outputs,
                        filteringProperties
                    )}
                    countText={countText(
                        filteredItemsCount || 0,
                        i18nStrings.item
                    )}
                />
            }
            preferences={
                <CollectionPreferences
                    {...tableConfig.collectionPreferencesI18nConstants}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                    visibleContentPreference={{
                        title: i18nStrings.table.selectVisibleColumns,
                        options: [
                            {
                                label: i18nStrings.table.properties,
                                options: columnDefinitions.map((column) => ({
                                    id: column.id as string,
                                    label: column.header as string,
                                    editable: column.editable,
                                })),
                            },
                        ],
                    }}
                    pageSizePreference={tableConfig.pageSizePreference}
                    wrapLinesPreference={tableConfig.wrapLinesPreference}
                />
            }
            loadingText={tableConfig.loadingText}
            loading={loading && !outputs.length}
            onSortingChange={(e) => {
                setSorting(e.detail);
                collectionProps.onSortingChange &&
                    collectionProps.onSortingChange(e);
            }}
            wrapLines={preferences.wrapLines}
        />
    );
};

export default OutputsTable;
