import {
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
    FormikField,
    SelectField,
    TileField,
} from '@amzn/event-engine-js-utils';
import HelpPanel from 'components/HelpPanel';
import InfoLink from 'components/InfoLink';
import { FormikContextType, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { i18nStrings } from '../../../../../constants';
import {
    MergedFormSchema,
    Step,
} from '../../components/CreateEventForm.validation';
import { EVENT_TYPE_OPTIONS } from '../../utils/constants';
import getAccountSourceOptions from '../../utils/get-account-source-options';
import getFormFieldName from './utils/get-form-field-name';

const EventType = () => {
    const formik: FormikContextType<MergedFormSchema> = useFormikContext();
    const content = formik.values[Step.SELECT_WORKSHOP];
    const configuredAccountSources = content?.contentSpecSummary.accountSources;
    const eventType = formik.values[Step.EVENT_TYPE]?.eventType;
    const accountSource = formik.values[Step.EVENT_TYPE]?.accountSource;
    const isPublished = content?.publishedBuildId === content?.contentBuildId;
    const eventTypeOptions = useMemo(() => {
        if (isPublished) {
            return EVENT_TYPE_OPTIONS;
        }

        const options: typeof EVENT_TYPE_OPTIONS = [];

        EVENT_TYPE_OPTIONS.forEach((option) => {
            options.push({
                ...option,
                disabled: option.value !== EVENT_TYPE.TEST,
            });
        });

        return options;
    }, []);
    const accountSourceOptions = useMemo(
        () => getAccountSourceOptions(configuredAccountSources),
        [configuredAccountSources]
    );

    useEffect(() => {
        if (!isPublished) {
            formik.setFieldValue(
                getFormFieldName('eventType'),
                EVENT_TYPE.TEST
            );
        }

        if (accountSourceOptions.find(({ value }) => value === accountSource)) {
            return;
        }

        let accountSourceValue: string | undefined;

        if (accountSourceOptions.length === 1) {
            accountSourceValue = accountSourceOptions[0].value;
        } else if (accountSourceOptions.length > 1) {
            accountSourceValue = accountSourceOptions.find(
                ({ value }) => value === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
            )?.value;

            // only set fallback IFF central account is not required.
            // central account content must use WS account source
            if (
                !accountSourceValue &&
                !content?.contentSpecSummary.centralAccountRequired
            ) {
                accountSourceValue = accountSourceOptions[0].value;
            }
        }

        formik.setFieldValue(
            getFormFieldName('accountSource'),
            accountSourceValue
        );
    }, []);

    return (
        <section data-testid="event-type-form">
            <SpaceBetween size="l">
                <Container
                    header={
                        <Header variant="h2">
                            {
                                i18nStrings.events.create.wizard.steps.eventType
                                    .title
                            }
                        </Header>
                    }>
                    <SpaceBetween size="l">
                        <FormikField<MergedFormSchema[Step.EVENT_TYPE]>
                            name={getFormFieldName('eventType')}
                            formFieldProps={{
                                label: <></>,
                                stretch: true,
                                description: <></>,
                                children: (
                                    <TileField<
                                        MergedFormSchema[Step.EVENT_TYPE]
                                    >
                                        name={getFormFieldName('eventType')}
                                        tileProps={{
                                            items: eventTypeOptions,
                                        }}
                                    />
                                ),
                            }}
                        />
                        <FormikField<MergedFormSchema[Step.EVENT_TYPE]>
                            name={getFormFieldName('accountSource')}
                            formFieldProps={{
                                label:
                                    i18nStrings.events.create.wizard.steps
                                        .eventType.fields.accountSource.label,
                                stretch: true,
                                description:
                                    i18nStrings.events.create.wizard.steps
                                        .eventType.fields.accountSource
                                        .description,
                                info: (
                                    <InfoLink
                                        toolsContent={
                                            <HelpPanel
                                                header={
                                                    i18nStrings.events.create
                                                        .helpText.accountSource
                                                        .header
                                                }>
                                                {
                                                    i18nStrings.events.create
                                                        .helpText.accountSource
                                                        .content
                                                }
                                            </HelpPanel>
                                        }
                                    />
                                ),
                                constraintText:
                                    content?.contentSpecSummary
                                        .centralAccountRequired &&
                                    i18nStrings.events.create.wizard.steps
                                        .eventType.fields.accountSource
                                        .constraintCentralAccount,
                                children: (
                                    <SelectField<
                                        MergedFormSchema[Step.EVENT_TYPE]
                                    >
                                        name={getFormFieldName('accountSource')}
                                        placeholder={
                                            i18nStrings.events.create.wizard
                                                .steps.eventType.fields
                                                .accountSource.placeholder
                                        }
                                        selectProps={{
                                            options: accountSourceOptions,
                                            disabled:
                                                !eventType ||
                                                content?.contentSpecSummary
                                                    .centralAccountRequired,
                                        }}
                                    />
                                ),
                            }}
                        />
                    </SpaceBetween>
                </Container>
                {!isPublished && (
                    <Alert type="info">
                        {
                            i18nStrings.events.create.wizard.steps.eventType
                                .alerts.productionEvent
                        }
                    </Alert>
                )}
            </SpaceBetween>
        </section>
    );
};

export default EventType;
