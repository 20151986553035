import { EVENT_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    LoadingComponent,
    LogLevel,
    SinkType,
    useLogger,
    useRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import React, { useEffect } from 'react';
import { EEContentCatalog, ErrorMessage } from 'services';
import { MessageTypes } from 'services/error-message';

import ContentDetails from './ContentDetails';

export interface ContentDetailsTabContainerProps {
    event: Pick<
        EventEngineEventsService.EventData,
        'contentId' | 'contentBuildId' | 'type'
    >;
}

const ContentDetailsTabContainer: React.FC<ContentDetailsTabContainerProps> = ({
    event,
}) => {
    const [contentDetailTabLogger] = useLogger(
        'contentDetailTabLogger',
        LogLevel.ERROR,
        [SinkType.CloudWatchLogSink]
    );
    const {
        data: getContentData,
        isError: getContentIsError,
        isLoading: getContentIsLoading,
    } = useRequest<typeof EEContentCatalog.getContent, AWSError>(
        EEContentCatalog.getContent,
        {
            context: EEContentCatalog,
        },
        event.contentId
    );

    useEffect(() => {
        if (getContentIsError) {
            contentDetailTabLogger.error(
                'received an error when getting content: ',
                getContentIsError
            );
        }
    }, [getContentIsError]);

    return (
        <>
            {getContentData?.content && (
                <ContentDetails
                    content={getContentData.content}
                    contentBuildId={
                        getContentData.principalCanView
                            ? event.contentBuildId
                            : undefined
                    }
                />
            )}
            {getContentIsError && (
                <Alert type="error">
                    {ErrorMessage.getMessage(
                        getContentIsError,
                        MessageTypes.getContent
                    )}
                </Alert>
            )}
            {getContentIsLoading && (
                <LoadingComponent
                    spinnerWithLabelProps={{
                        children: 'Loading content details...',
                    }}
                />
            )}
        </>
    );
};

export default ContentDetailsTabContainer;
