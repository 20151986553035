import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown, {
    ButtonDropdownProps,
} from '@amzn/awsui-components-react/polaris/button-dropdown';
import CollectionPreferences, {
    CollectionPreferencesProps,
} from '@amzn/awsui-components-react/polaris/collection-preferences';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
    countText,
    EmptyMessage,
    getFilteringOptions,
    PropertyFilterConstants,
    StoragePartition,
    StoragePartitionKeys,
} from '@amzn/event-engine-js-utils';
import { AppContext } from 'contexts/AppProviders';
import { format } from 'date-fns';
import useFeatureFlag from 'hooks/use-feature-flag';
import CopyJoinEventLinkBtn from 'pages/Events/EventDetails/components/CopyJoinEventLinkBtn';
import useTeamAccountAccess from 'pages/Events/EventDetails/hooks/use-team-account-access';
import useTerminateTeam from 'pages/Events/EventDetails/hooks/use-terminate-team';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import ErrorMessage from 'services/error-message';

import { i18nStrings, tableConfig } from '../../../../../../../../../constants';
import TeamsCsvDownloadAction from '../TeamsCsvDownloadAction';
import {
    columnDefinitions,
    ColumnID,
    defaultPreferences,
    defaultQuery,
    defaultSortPreferences,
    filteringProperties,
    SORTING_COMPARATOR_MAP,
} from './config';
import {
    ActionID,
    EventTeamsTableProps,
    TeamDataTableItem,
    TestID,
} from './types';
import processData from './utils/process-data';

export const STORAGE_SCOPE = 'eventTeamsTable_08232023';

// TODO: Remove once teamCodeJoining is generally available
const TEAM_COLUMN_IDs = [ColumnID.TEAM_ACCESS_CODE, ColumnID.JOIN_EVENT_URL];

const EventTeamsTable = ({
    error,
    event,
    loading,
    refetch,
    teams: data,
}: EventTeamsTableProps) => {
    const { settings } = useContext(AppContext);
    const teamCodeFeatureEnabled = useFeatureFlag('teamCodeJoining');
    const eventColumnDefinitions = useMemo(
        () =>
            columnDefinitions[
                event?.accountSource || EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED
            ].filter((columnDefinition) =>
                TEAM_COLUMN_IDs.includes(columnDefinition.id as ColumnID)
                    ? teamCodeFeatureEnabled
                    : true
            ),
        [teamCodeFeatureEnabled]
    );
    const teams = useMemo(() => processData(data, { settings }), [data]);
    const defaultSortingState = useMemo<
        TableProps.SortingState<TeamDataTableItem>
    >(() => {
        const storedSortingState = StoragePartition.getItem(
            StoragePartitionKeys.tableSortColumns,
            STORAGE_SCOPE
        );

        if (!storedSortingState?.sortingColumn.sortingField) {
            return defaultSortPreferences;
        }

        const sortingComparator =
            SORTING_COMPARATOR_MAP[
                storedSortingState.sortingColumn.sortingField
            ];

        // default sorting logic will not run if the "sortingComparator" key is present, even if the value is "undefined"
        if (sortingComparator) {
            storedSortingState.sortingColumn.sortingComparator = sortingComparator;
        }

        return storedSortingState;
    }, []);
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tablePreferences,
            STORAGE_SCOPE
        ) || defaultPreferences
    );
    const handleActionUpdateSuccess = useCallback(() => {
        refetch && refetch(true);
    }, [refetch]);
    const {
        showModal: showTeamAccountAccessModal,
        isDisabled: isTeamAccountAccessDisabled,
        loading: isTeamAccountAccessModelOpening,
        modal: teamAccountAccessModal,
    } = useTeamAccountAccess();
    const {
        showModal: showTerminateTeamConfirmationModal,
        isDisabled: isTerminateTeamDisabled,
        modal: terminateTeamConfirmationModal,
    } = useTerminateTeam({
        onSuccess: handleActionUpdateSuccess,
    });
    const handleRefresh = useCallback(() => {
        refetch && refetch();
    }, [refetch]);
    const {
        items,
        allPageItems,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(teams, {
        propertyFiltering: {
            filteringProperties,
            defaultQuery,
            empty: error ? (
                <EmptyMessage
                    title={i18nStrings.events.teams.table.errorTitle}
                    description={ErrorMessage.getMessage(error)}
                />
            ) : (
                <EmptyMessage
                    title={i18nStrings.events.teams.table.emptyTitle}
                    description={
                        i18nStrings.events.teams.table.emptyDescription
                    }
                />
            ),
            noMatch: (
                <EmptyMessage
                    title={i18nStrings.table.noMatches}
                    description={i18nStrings.table.noMatchesDescription}
                    actions={
                        <Button
                            onClick={() =>
                                actions.setPropertyFiltering(defaultQuery)
                            }>
                            {i18nStrings.table.clearFilters}
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: defaultSortingState,
        },
        selection: {
            keepSelection: true,
            trackBy: 'teamId',
        },
    });
    const selectedItems = collectionProps.selectedItems;
    const handleActionClick = useCallback<
        (
            arg: Parameters<NonNullable<ButtonDropdownProps['onItemClick']>>[0]
        ) => void
    >(
        ({ detail: { id } }) => {
            const selectedTeam = teams.find(
                ({ teamId }) => teamId === selectedItems?.[0].teamId
            );

            if (!selectedTeam) {
                return;
            }

            switch (id) {
                case ActionID.TerminateTeam:
                    event &&
                        showTerminateTeamConfirmationModal(event, selectedTeam);
                    break;
            }
        },
        [event, teams, selectedItems]
    );
    const counterText = useMemo(() => {
        const totalCount = teams.length;
        const selectionCount = selectedItems?.length;

        return `(${selectionCount ? `${selectionCount}/` : ''}${totalCount})`;
    }, [teams, selectedItems]);
    const rowActions = useMemo<ButtonDropdownProps.Items>(() => {
        const actions: ButtonDropdownProps.Item[] = [];

        if (!event) {
            return actions;
        }

        const selectedTeamId = selectedItems?.[0]?.teamId;
        const selectedItem = items.find(
            ({ teamId }) => teamId === selectedTeamId
        );

        actions.push({
            id: ActionID.TerminateTeam,
            text: i18nStrings.events.teams.table.actions.terminate,
            disabled: !(selectedItem && !isTerminateTeamDisabled(selectedItem)),
        });

        return actions;
    }, [selectedItems, items, event]);

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.tablePreferences,
            preferences,
            STORAGE_SCOPE
        );
    }, [preferences]);

    return (
        <>
            <Table
                {...collectionProps}
                header={
                    <Header
                        counter={counterText}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {refetch ? (
                                    <Button
                                        data-testid={TestID.ButtonRefresh}
                                        disabled={loading}
                                        loading={loading}
                                        onClick={handleRefresh}
                                        variant="normal"
                                        iconName="refresh"
                                        ariaLabel={i18nStrings.refreshData}
                                    />
                                ) : null}
                                {rowActions.length ? (
                                    <ButtonDropdown
                                        data-testid={TestID.ButtonActions}
                                        items={rowActions}
                                        onItemClick={handleActionClick}
                                        disabled={loading}>
                                        {i18nStrings.actions}
                                    </ButtonDropdown>
                                ) : null}
                                {event && (
                                    <TeamsCsvDownloadAction
                                        accountSource={event?.accountSource}
                                        filename={`${event.title}_teams${
                                            event.scheduledStartTime
                                                ? `_${format(
                                                      event.scheduledStartTime,
                                                      'yyyy-MM-dd'
                                                  )}`
                                                : ''
                                        }`}
                                        teams={[...allPageItems]}
                                    />
                                )}
                                {teamCodeFeatureEnabled && (
                                    <CopyJoinEventLinkBtn
                                        code={selectedItems?.[0]?.teamCode}
                                    />
                                )}
                                {event && (
                                    <Button
                                        data-testid={TestID.AccessAWSAccount}
                                        disabled={
                                            loading ||
                                            !(
                                                selectedItems?.[0] &&
                                                !isTeamAccountAccessDisabled(
                                                    event,
                                                    selectedItems[0]
                                                ) &&
                                                selectedItems[0].accountId
                                            )
                                        }
                                        onClick={() =>
                                            showTeamAccountAccessModal(
                                                event!,
                                                teams.find(
                                                    ({ teamId }) =>
                                                        teamId ===
                                                        selectedItems?.[0]
                                                            .teamId
                                                )!
                                            )
                                        }
                                        loading={
                                            isTeamAccountAccessModelOpening
                                        }
                                        iconName="key">
                                        {
                                            i18nStrings.events.teams.table
                                                .actions.accessAWSAccount
                                        }
                                    </Button>
                                )}
                            </SpaceBetween>
                        }>
                        {i18nStrings.events.teams.table.title}
                    </Header>
                }
                columnDefinitions={eventColumnDefinitions}
                visibleColumns={preferences.visibleContent}
                items={items}
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={tableConfig.paginationAriaLabels}
                    />
                }
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        i18nStrings={PropertyFilterConstants.i18nStrings}
                        filteringOptions={getFilteringOptions(
                            teams,
                            filteringProperties
                        )}
                        countText={countText(
                            filteredItemsCount || 0,
                            i18nStrings.item
                        )}
                    />
                }
                preferences={
                    <CollectionPreferences
                        {...tableConfig.collectionPreferencesI18nConstants}
                        preferences={preferences}
                        onConfirm={({ detail }) => setPreferences(detail)}
                        visibleContentPreference={{
                            title: i18nStrings.table.selectVisibleColumns,
                            options: [
                                {
                                    label: i18nStrings.table.properties,
                                    options: eventColumnDefinitions.map(
                                        (column) => ({
                                            id: column.id as string,
                                            label: column.header as string,
                                            editable: column.editable,
                                        })
                                    ),
                                },
                            ],
                        }}
                        pageSizePreference={tableConfig.pageSizePreference}
                        wrapLinesPreference={tableConfig.wrapLinesPreference}
                    />
                }
                loadingText={tableConfig.loadingText}
                loading={loading && !teams.length}
                onSortingChange={(e) => {
                    StoragePartition.setItem(
                        StoragePartitionKeys.tableSortColumns,
                        e.detail,
                        STORAGE_SCOPE
                    );
                    collectionProps.onSortingChange &&
                        collectionProps.onSortingChange(e);
                }}
                wrapLines={preferences.wrapLines}
                selectionType="single"
                resizableColumns
            />
            {teamAccountAccessModal}
            {terminateTeamConfirmationModal}
        </>
    );
};

export * from './types';
export default EventTeamsTable;
