import {
    SCAN_RESULT,
    SCAN_STATUS,
    SYNC_STATUS,
} from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';

import { SelectWorkshopAssetTableItem } from './types';

const processData = (data: EventEngineContentCatalogService.AssetData[]) => {
    const res: SelectWorkshopAssetTableItem[] = [];

    data.forEach((item) => {
        if (
            item.scanStatus === SCAN_STATUS.COMPLETED &&
            item.scanResult === SCAN_RESULT.PASS &&
            item.syncStatus !== SYNC_STATUS.SYNCING &&
            !item.syncedVersionId
        ) {
            res.push(item);
        }
    });

    return res;
};

export default processData;
