import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    BaseMarkdown,
    CheckboxField,
    Constants,
    EventMatrix,
    formatString,
    FormikField,
} from '@amzn/event-engine-js-utils';
import i18nStrings from 'constants/i18n-strings';
import { FormikProvider, useFormik } from 'formik';
import { useModal } from 'hooks';
import React, {
    FC,
    Fragment,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import getDurationFromNow from 'utils/date-fns/get-duration-from-now';

import validationSchema, { FieldName, SchemaType } from './schema';

export interface TerminateEventModalProps {
    event: EventEngineEventsService.EventData;
    isVisible: boolean;
    onModalClosed: (terminateEvent?: boolean) => void;
    countFailedTeams?: number;
}

const TerminateEventModal: React.FC<TerminateEventModalProps> = ({
    event,
    onModalClosed,
    isVisible,
    countFailedTeams = 0,
}: TerminateEventModalProps) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const formik = useFormik({
        validationSchema,
        initialValues: {
            [FieldName.ACCOUNT_SOURCE]: event.accountSource,
            [FieldName.COUNT_FAILED_TEAMS]: countFailedTeams,
        },
        validateOnChange,
        validateOnBlur: false,
        onSubmit: () => {
            closeModal(true);
        },
    });
    // We won't do a live countdown here since the changing length of the message
    // may contribute to a jarring experience on narrow viewports when the height of
    // the modal changes due to text wrapping.
    const { current: eventDuration } = useRef(
        event.nextActionAt && getDurationFromNow(event.nextActionAt)
    );
    const content = useMemo(
        () =>
            event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
                ? i18nStrings.events.terminateEventModal.content.workshopStudio
                : i18nStrings.events.terminateEventModal.content
                      .customerProvided,
        [event]
    );
    const isEventInProgress = useMemo(
        () =>
            EventMatrix.isEventInProgress({
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            }),
        [event]
    );
    const closeModal = (terminateEvent?: boolean) => {
        hideModal();
        onModalClosed(terminateEvent);
    };

    const onPrimaryActionClick = useCallback(() => {
        setValidateOnChange(true);
        formik.handleSubmit();
    }, [formik]);

    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);

    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: () => onModalClosed(false),
        modalHeader: i18nStrings.events.terminateEventModal.header,
        actions: {
            primary: {
                text: i18nStrings.events.terminateEventModal.actionTerminate,
                onClick: onPrimaryActionClick,
                disabled: !formik.isValid,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <SpaceBetween size="m">
                    <div>
                        {isEventInProgress
                            ? content.inProgress
                            : content.notStarted}
                    </div>
                    {event.accountSource ===
                        EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO &&
                    countFailedTeams > 0 ? (
                        <Alert
                            header={
                                i18nStrings.events.terminateEventModal.content
                                    .terminateTeamWarning.header
                            }
                            type="warning">
                            <SpaceBetween direction="vertical" size="m">
                                <TextContent>
                                    {
                                        <BaseMarkdown
                                            linkTarget="_blank"
                                            components={{
                                                p: Fragment,
                                            }}>
                                            {formatString(
                                                i18nStrings.events
                                                    .terminateEventModal.content
                                                    .terminateTeamWarning
                                                    .content,
                                                Constants.ExternalLinks
                                                    .reportBug
                                            )}
                                        </BaseMarkdown>
                                    }
                                </TextContent>
                                <FormikField<SchemaType>
                                    name={FieldName.TERMS_ACKNOWLEDGED}
                                    dataTestId={FieldName.TERMS_ACKNOWLEDGED}
                                    formFieldProps={{
                                        children: (
                                            <CheckboxField<SchemaType>
                                                name={
                                                    FieldName.TERMS_ACKNOWLEDGED
                                                }
                                                checkboxProps={{
                                                    children:
                                                        i18nStrings.events
                                                            .terminateEventModal
                                                            .content
                                                            .terminateTeamWarning
                                                            .acknowledgementField
                                                            .label,
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            </SpaceBetween>
                        </Alert>
                    ) : null}
                    {isEventInProgress ? (
                        <Alert type="info">
                            {formatString(
                                i18nStrings.events.eventDetails
                                    .notificationEventInProgress.content,
                                eventDuration
                            )}
                        </Alert>
                    ) : null}
                </SpaceBetween>
            </FormikProvider>
        ),
    });

    return modalComponent;
};

export default TerminateEventModal;
