import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Tabs, { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import { SpinnerWithLabel } from 'components/SpinnerWithLabel/SpinnerWithLabel';
import i18nStrings from 'constants/i18n-strings';
import useFeatureFlag from 'hooks/use-feature-flag';
import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContentDetails from './components/ContentDetails';
import Facilitators from './components/Facilitators';
import Overview from './components/Overview';
import Participants from './components/Participants';
import Quotas from './components/Quotas';
import Teams from './components/Teams';

export interface EventDetailTabsProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
    refetchGetEventLoading: boolean;
    refetchGetEventError: boolean;
    warnings?: Partial<Record<TabID, boolean>>;
}

export enum TabID {
    OVERVIEW = 'OVERVIEW',
    CONTENT_DETAILS = 'CONTENT_DETAILS',
    PERMISSIONS = 'PERMISSIONS',
    PARTICIPANTS = 'PARTICIPANTS',
    TEAMS = 'TEAMS',
    QUOTAS = 'QUOTAS',
    CENTRAL = 'CENTRAL',
}

const defaultTab = TabID.OVERVIEW;
const warningIndicator = (
    <Box color="inherit" margin={{ right: 'xs' }}>
        <Icon name="status-warning" />
    </Box>
);
const CentralAccountDetails = lazy(
    () => import('./components/CentralAccountDetails')
);

const EventDetailTabs: React.FC<EventDetailTabsProps> = ({
    event,
    refetchGetEvent,
    refetchGetEventLoading,
    refetchGetEventError,
    warnings = {},
}: EventDetailTabsProps) => {
    const history = useHistory();
    const location = useLocation();
    const [activeTabId, setActiveTabId] = useState<TabID>(TabID.OVERVIEW);
    const centralAccountsFeatureEnabled = useFeatureFlag('centralAccounts');

    const setTabHash = useCallback((activeTabId: TabID) => {
        history.replace(`#${activeTabId}`);
    }, []);

    const handleTabChange = useCallback((activeTabId: TabID) => {
        setTabHash(activeTabId);
        setActiveTabId(activeTabId);
    }, []);

    useEffect(() => {
        const hash = location.hash.replace(/^#/, '') as TabID;
        Object.values(TabID).includes(hash) && setActiveTabId(hash);
    }, [location.hash]);

    useEffect(() => {
        let tabIDFromPath: TabID = (history.location.hash || '').replace(
            '#',
            ''
        ) as TabID;

        // If hash is invalid, reset to default tab
        if (!TabID[tabIDFromPath]) {
            tabIDFromPath = defaultTab;
            setTabHash(tabIDFromPath);
        }

        setActiveTabId(tabIDFromPath);
    }, []);

    const tabs: TabsProps.Tab[] = useMemo(
        () => [
            {
                id: TabID.OVERVIEW,
                label: 'Overview',
                content: (
                    <Overview event={event} refetchGetEvent={refetchGetEvent} />
                ),
            },
            {
                id: TabID.CONTENT_DETAILS,
                label: 'Content',
                content: <ContentDetails event={event} />,
            },
            {
                id: TabID.PERMISSIONS,
                label: 'Permissions',
                content: (
                    <Facilitators
                        event={event}
                        refetchGetEvent={refetchGetEvent}
                        refetchGetEventLoading={refetchGetEventLoading}
                        refetchGetEventError={refetchGetEventError}
                    />
                ),
            },
            ...(event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO
                ? [
                      {
                          id: TabID.QUOTAS,
                          label: (
                              <>
                                  {warnings[TabID.QUOTAS]
                                      ? warningIndicator
                                      : null}
                                  {i18nStrings.events.quotas.title}
                              </>
                          ),
                          content: (
                              <Quotas
                                  event={event}
                                  refetchGetEvent={refetchGetEvent}
                                  refetchGetEventLoading={
                                      refetchGetEventLoading
                                  }
                              />
                          ),
                      },
                  ]
                : []),
            {
                id: TabID.PARTICIPANTS,
                label: (
                    <>
                        {warnings[TabID.PARTICIPANTS] ? warningIndicator : null}
                        {i18nStrings.events.participants.title}
                    </>
                ),
                content: (
                    <Participants
                        refetchGetEvent={refetchGetEvent}
                        event={event}
                    />
                ),
            },
            {
                id: TabID.TEAMS,
                label: 'Teams',
                content: <Teams event={event} />,
            },
            // TODO: Remove once centralAccounts is generally available
            ...(centralAccountsFeatureEnabled && event.centralAccountRequired
                ? [
                      {
                          id: TabID.CENTRAL,
                          label: 'Central infrastructure',
                          content: (
                              <Suspense
                                  fallback={
                                      <SpinnerWithLabel>
                                          {i18nStrings.loading}
                                      </SpinnerWithLabel>
                                  }>
                                  <CentralAccountDetails />
                              </Suspense>
                          ),
                      },
                  ]
                : []),
        ],
        [event, refetchGetEventLoading, refetchGetEventError]
    );

    return (
        <Tabs
            ariaLabel="Additional event details"
            activeTabId={activeTabId}
            onChange={({ detail: { activeTabId } }) =>
                handleTabChange(activeTabId as TabID)
            }
            tabs={tabs}
        />
    );
};

export default EventDetailTabs;
