import * as Yup from 'yup';

import { i18nStrings } from '../../constants';

export enum FieldName {
    ACKNOWLEDGE = 'acknowledge',
}

const Schema = Yup.object({
    [FieldName.ACKNOWLEDGE]: Yup.boolean()
        .oneOf([true], i18nStrings.guardrails.acknowledgeCheckboxError)
        .required(i18nStrings.guardrails.acknowledgeCheckboxError),
});

export type SchemaType = Yup.InferType<typeof Schema>;
export default Schema;
