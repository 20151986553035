import Alert from '@amzn/awsui-components-react/polaris/alert';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import getMinAccountRequirement from 'pages/Events/CreateEvent/utils/get-min-account-requirement';
import React, { useMemo } from 'react';

import { i18nStrings } from '../../../../../../../constants';
import QuotaOverview from './components/QuotaOverview';
import QuotaReservationsTable from './components/QuotaReservationsTable';

export interface QuotasProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
    refetchGetEventLoading?: boolean;
}

const Quotas = ({
    event,
    refetchGetEvent,
    refetchGetEventLoading,
}: QuotasProps) => {
    const needsAccounts = useMemo(() => {
        const totalQuotaContribution = event.accountQuotaContributions?.length
            ? event.accountQuotaContributions.reduce(
                  (acc, { reserved }) => acc + (reserved || 0),
                  0
              )
            : 0;

        return (
            totalQuotaContribution <
            getMinAccountRequirement(
                event.numberOfForecastedParticipants,
                event.teamSize,
                event.centralAccountRequired
            )
        );
    }, [event]);

    return (
        <SpaceBetween size="l">
            {needsAccounts ? (
                <Alert type="warning">
                    {i18nStrings.events.quotas.alerts.warning}
                </Alert>
            ) : null}
            <section>
                <QuotaOverview event={event} />
                <QuotaReservationsTable
                    event={event}
                    refetchGetEvent={refetchGetEvent}
                    refetchGetEventLoading={refetchGetEventLoading}
                    reservations={event.accountQuotaContributions}
                />
            </section>
        </SpaceBetween>
    );
};

export default Quotas;
