import { CONTENT_PUBLISH_STATE } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ValueWithLabel } from '@amzn/event-engine-js-utils';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import AccountSourceStatus from 'components/AccountSourceStatus';
import EventImpactingFactors from 'components/EventImpactingFactors';
import EventImpactStatus from 'components/EventImpactStatus';
import { format as dateFnsFormat, isValid } from 'date-fns';
import { getLastPublishedAt } from 'pages/Workshops/utils/utils';
import React from 'react';
import { getAttributeValueByName } from 'utils/content-attributes';

import { complexityLevels } from '../../../constants';
import WorkshopStateStatus from './WorkshopStateStatus';

type Variants = 'default' | 'inline';

interface WorkshopDetailsCardProps {
    header?: string;
    data: ContentData;
    actions?: React.ReactNode;
    variant?: Variants;
    type?: 'public' | 'internal';
}

/**
 * If the user doesn’t provide a value or the value is empty (is not an error or doesn’t have a valid value of 0),
 * use "–" (minus sign) for any empty values. For example in table rows or key/value pairs.
 * https://polaris.a2z.com/design/patterns/empty_states/
 */
const placeholderValue = '–';

/**
 * Returns value with specified singular or plural postfix
 * @param value
 * @param singular
 * @param plural
 */
const getAttributeValueWithPostfix = (
    value: any,
    singular: string,
    plural?: string
) => {
    if (value === placeholderValue) {
        return value;
    }
    return `${value} ${value > 1 ? plural || singular : singular}`;
};

const getAttributeValueWithLabel = (
    value: any,
    labelMap: any,
    delimiter = '-'
) => {
    if (value === placeholderValue) {
        return value;
    }
    return `${value}${labelMap[value] ? ` - ${labelMap[value]}` : ''}`;
};

const WorkshopDetailsCard = ({
    data,
    actions,
    header,
    variant = 'default',
    type = 'internal',
}: WorkshopDetailsCardProps) => {
    const content = (
        <ColumnLayout columns={4} variant="text-grid">
            <SpaceBetween size="l">
                <ValueWithLabel label="Workshop ID">
                    {data?.contentId}
                </ValueWithLabel>
                <ValueWithLabel label="Repository name">
                    {data.repositoryName}
                </ValueWithLabel>
                <ValueWithLabel label="Industry vertical">
                    {getAttributeValueByName(
                        'Industry vertical',
                        data?.attributes
                    )}
                </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
                {type === 'internal' && (
                    <ValueWithLabel label="State">
                        <WorkshopStateStatus state={data.state} />
                    </ValueWithLabel>
                )}
                <ValueWithLabel label="Primary owner">
                    {data?.primaryOwner}
                </ValueWithLabel>
                <ValueWithLabel label="Recommended duration">
                    {getAttributeValueWithPostfix(
                        getAttributeValueByName(
                            'Recommended duration',
                            data?.attributes
                        ) as number,
                        'hour',
                        'hours'
                    )}
                </ValueWithLabel>
                {type === 'public' &&
                data.publishedRequiresAuth !== undefined ? (
                    <ValueWithLabel label="Published type">
                        {data.publishedRequiresAuth ? 'Internal' : 'Public'}
                    </ValueWithLabel>
                ) : null}
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Complexity level">
                    {getAttributeValueWithLabel(
                        getAttributeValueByName(
                            'Complexity level',
                            data?.attributes
                        ),
                        complexityLevels
                    )}
                </ValueWithLabel>
                <ValueWithLabel label="Engagement model">
                    {getAttributeValueByName(
                        'Engagement model',
                        data?.attributes
                    )}
                </ValueWithLabel>
                {data.lastPublishedAt && (
                    <ValueWithLabel label="Last published">
                        {getLastPublishedAt(data)}
                    </ValueWithLabel>
                )}
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Topics">
                    {getAttributeValueByName('Topics', data?.attributes)}
                </ValueWithLabel>
                <ValueWithLabel label="AWS services">
                    {getAttributeValueByName('AWS services', data?.attributes)}
                </ValueWithLabel>
                {type === 'internal' &&
                    data.publishState === CONTENT_PUBLISH_STATE.PUBLISHED && (
                        <ValueWithLabel label="Account source defined">
                            <AccountSourceStatus
                                value={data.contentSpecSummary?.accountSources}
                            />
                        </ValueWithLabel>
                    )}
                {data.publishState === CONTENT_PUBLISH_STATE.PUBLISHED &&
                    (type === 'public' ? (
                        <ValueWithLabel label="Event readiness">
                            <EventImpactStatus
                                value={data.eventReadiness.summary}
                            />
                        </ValueWithLabel>
                    ) : (
                        <ValueWithLabel label="Event impacting factors">
                            <EventImpactingFactors
                                value={data.eventReadiness}
                            />
                        </ValueWithLabel>
                    ))}
            </SpaceBetween>
            {data.scheduledForDeletionAt &&
                isValid(data.scheduledForDeletionAt) && (
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Scheduled deletion date">
                            {dateFnsFormat(
                                data.scheduledForDeletionAt,
                                'LL/dd/yyyy'
                            )}
                        </ValueWithLabel>
                    </SpaceBetween>
                )}
            {data.scheduledForDeletionBy && (
                <SpaceBetween size="l">
                    <ValueWithLabel label="Scheduled for deletion by">
                        {data.scheduledForDeletionBy}
                    </ValueWithLabel>
                </SpaceBetween>
            )}
        </ColumnLayout>
    );

    return (
        <>
            {variant === 'inline' && content}
            {variant !== 'inline' && (
                <Container
                    header={
                        <Header variant="h2" actions={actions ? actions : null}>
                            {header || 'Workshop details'}
                        </Header>
                    }
                    footer={
                        <ExpandableSection
                            header="Description"
                            variant="footer"
                            defaultExpanded={!!data?.description}>
                            {data?.description}
                        </ExpandableSection>
                    }>
                    {content}
                </Container>
            )}
        </>
    );
};

export default WorkshopDetailsCard;
