const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const B_IN_KB = 1024;

/**
 * Display a human readable size from bytes
 * Based off of https://stackoverflow.com/a/18650828
 * @param {number} bytes
 * @param {number} [decimals=2]
 * @returns {string}
 */
const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return `0 ${SIZES[0]}`;
    }

    const precision = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(B_IN_KB));

    return (
        parseFloat((bytes / Math.pow(B_IN_KB, i)).toFixed(precision)) +
        ' ' +
        SIZES[i < SIZES.length ? i : SIZES.length - 1]
    );
};

export default formatBytes;
