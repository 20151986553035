import i18nStrings from 'constants/i18n-strings';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';

export interface CancelEventModalProps {
    isVisible: boolean;
    onModalClosed: (cancelEvent?: boolean) => void;
}

const CancelEventModal: React.FC<CancelEventModalProps> = ({
    onModalClosed,
    isVisible,
}: CancelEventModalProps) => {
    const closeModal = (cancelEvent?: boolean) => {
        hideModal();
        onModalClosed(cancelEvent);
    };
    const onPrimaryActionClick = useCallback(() => {
        closeModal(true);
    }, []);
    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);
    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: () => onModalClosed(false),
        modalHeader: i18nStrings.events.cancelEventModal.header,
        actions: {
            primary: {
                text: i18nStrings.events.cancelEventModal.actionCancelEvent,
                onClick: onPrimaryActionClick,
            },
            tertiary: {
                text: i18nStrings.events.cancelEventModal.actionCancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: i18nStrings.events.cancelEventModal.content,
    });

    return modalComponent;
};

export default CancelEventModal;
