import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { TeamDataTableItem } from '../../EventTeamsTable/types';
import { ACCOUNT_SOURCE_EXPORT_COLUMNS, TEAM_KEYS } from '../constants';

const processData = (
    data: TeamDataTableItem[],
    accountSource: EventEngineEventsService.EventAccountSource = EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED,
    // TODO: Remove once teamCodeJoining is generally available
    teamCodeFeatureEnabled?: boolean
) => {
    const exportColumns = ACCOUNT_SOURCE_EXPORT_COLUMNS[accountSource];
    const res: Record<
        typeof exportColumns[number]['header'],
        TeamDataTableItem[typeof exportColumns[number]['key']]
    >[] = [];

    data.forEach((team) => {
        res.push(
            exportColumns.reduce((acc, a) => {
                if (TEAM_KEYS.includes(a.key) && !teamCodeFeatureEnabled) {
                    return acc;
                }

                let value = team[a.key] || '';

                if (Array.isArray(value)) {
                    value = value.join('\n');
                }

                return {
                    ...acc,
                    [a.header]: value,
                };
            }, {})
        );
    });

    return res;
};

export default processData;
