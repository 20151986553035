import { Auth } from 'aws-amplify';

/**
 * Gets Amplify username without ID prefix
 * @param prefix
 * @returns {string} Username/alias
 */
const getAmplifyUsername = async (prefix = 'AmazonFederate') => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const username = user.getUsername() || '';
        if (prefix) {
            const prefixRegExp = new RegExp(`^${prefix}_`);
            return username.replace(prefixRegExp, '');
        }
        return username;
    } catch {
        return '';
    }
};

export default getAmplifyUsername;
