import { i18nStrings } from '../../../../../../constants';
import { EMAIL_DELIMITER, WILDCARD_EXPRESSION } from '../constants';

interface CheckEmailPatternValue {
    isValid: boolean;
    error?: string;
}

/**
 * @see {@link https://code.amazon.com/packages/EEEventsLambda/blobs/mainline/--/event/api_op_UpdateEventAllowlist.go|code.amazon.com}
 * @param {string} pattern
 * @returns {CheckEmailPatternValue}
 */
const checkEmailPattern = (pattern: string) => {
    const result: CheckEmailPatternValue = {
        isValid: false,
    };
    const segments = pattern.split(EMAIL_DELIMITER);
    const uniqueChars = new Set(pattern);

    // No patterns that reduce to wildcard expressions (e.g "**", "*@*", "**@**", etc..)
    if (
        (uniqueChars.has(WILDCARD_EXPRESSION) && uniqueChars.size === 1) ||
        segments
            .map((s) => new Set(s))
            .every((s) => s.size === 1 && s.has(WILDCARD_EXPRESSION))
    ) {
        result.error =
            i18nStrings.events.create.wizard.steps.participantAllowlist.fields.emailPatterns.errors.noWildcardExpression;
    } else if (segments.length > 2) {
        result.error =
            i18nStrings.events.create.wizard.steps.participantAllowlist.fields.emailPatterns.errors.tooManyAts;
    }

    if (result.error) {
        return result;
    }

    for (const segment of segments) {
        if (
            (segment.match(new RegExp(`\\${WILDCARD_EXPRESSION}`, 'g')) || [])
                .length > 1
        ) {
            result.error =
                i18nStrings.events.create.wizard.steps.participantAllowlist.fields.emailPatterns.errors.tooManyWildcards;
            return result;
        }
    }

    result.isValid = true;

    return result;
};

export default checkEmailPattern;
