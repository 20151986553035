import {
    AWS_REGION,
    EVENT_ACCOUNT_SOURCE,
    EVENT_TYPE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { TilesProps } from '@amzn/awsui-components-react/polaris/tiles';
import {
    AwsRegion,
    EventAccountSource,
    EventType,
} from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';

import { i18nStrings } from '../../../../constants';

export interface RegionOption {
    label: string;
    value: AwsRegion;
}

/**
 * All of the constants had to be hard coded here
 * because we cannot infer the type of enums from
 * the Smithy Model.
 */

export const DEFAULT_REGION: RegionOption & {
    disabled: boolean;
} = {
    label: 'us-east-1 (default)',
    value: 'us-east-1',
    disabled: true,
};

export const ADMINISTRATOR_VALUE = 'administrator';

/**
 * We will not auto-select any regions by default for now.
 * Any system default region will be added in the backend.
 */
export const DEFAULT_ACCESSIBLE_REGIONS: SelectProps.Option[] = [
    // {
    //     value: AWS_REGION.US_EAST_1,
    //     description: i18nStrings.events.regionCategories.default,
    // },
];

export const AWS_REGIONS: SelectProps.Options = Object.values(AWS_REGION).map(
    (region) => {
        const isDefaultRegion = !!DEFAULT_ACCESSIBLE_REGIONS.find(
            ({ value }) => value === region
        );

        return {
            label: isDefaultRegion ? `${region} (default)` : region,
            value: region,
            disabled: isDefaultRegion,
        };
    }
);

export const TIMEZONES: SelectProps.Option[] = [
    {
        label: 'International Date Line West',
        value: 'Etc/GMT+12',
    },
    {
        label: 'Midway Island, Samoa',
        value: 'Pacific/Midway',
    },
    {
        label: 'Hawaii',
        value: 'Pacific/Honolulu',
    },
    {
        label: 'Alaska',
        value: 'America/Anchorage',
    },
    {
        label: 'Pacific Time (US & Canada)',
        value: 'America/Los_Angeles',
    },
    {
        label: 'Tijuana, Baja California',
        value: 'America/Tijuana',
    },
    {
        label: 'Arizona',
        value: 'America/Phoenix',
    },
    {
        label: 'Chihuahua, La Paz, Mazatlan',
        value: 'America/Chihuahua',
    },
    {
        label: 'Mountain Time (US & Canada)',
        value: 'America/Denver',
    },
    {
        label: 'Central America',
        value: 'America/Managua',
    },
    {
        label: 'Central Time (US & Canada)',
        value: 'America/Chicago',
    },
    {
        label: 'Guadalajara, Mexico City, Monterrey',
        value: 'America/Mexico_City',
    },
    {
        label: 'Saskatchewan',
        value: 'Canada/Saskatchewan',
    },
    {
        label: 'Bogota, Lima, Quito, Rio Branco',
        value: 'America/Bogota',
    },
    {
        label: 'Eastern Time (US & Canada)',
        value: 'America/New_York',
    },
    {
        label: 'Indiana (East)',
        value: 'America/Indiana/Indianapolis',
    },
    {
        label: 'Atlantic Time (Canada)',
        value: 'Canada/Atlantic',
    },
    {
        label: 'Caracas, La Paz',
        value: 'America/Caracas',
    },
    {
        label: 'Manaus',
        value: 'America/Manaus',
    },
    {
        label: 'Santiago',
        value: 'America/Santiago',
    },
    {
        label: 'Newfoundland',
        value: 'Canada/Newfoundland',
    },
    {
        label: 'Brasilia',
        value: 'America/Sao_Paulo',
    },
    {
        label: 'Buenos Aires, Georgetown',
        value: 'America/Argentina/Buenos_Aires',
    },
    {
        label: 'Greenland',
        value: 'America/Godthab',
    },
    {
        label: 'Montevideo',
        value: 'America/Montevideo',
    },
    {
        label: 'Mid-Atlantic',
        value: 'America/Noronha',
    },
    {
        label: 'Cape Verde Is.',
        value: 'Atlantic/Cape_Verde',
    },
    {
        label: 'Azores',
        value: 'Atlantic/Azores',
    },
    {
        label: 'Casablanca, Monrovia, Reykjavik',
        value: 'Africa/Casablanca',
    },
    {
        label: 'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
        value: 'Etc/Greenwich',
    },
    {
        label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: 'Europe/Amsterdam',
    },
    {
        label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: 'Europe/Belgrade',
    },
    {
        label: 'Brussels, Copenhagen, Madrid, Paris',
        value: 'Europe/Brussels',
    },
    {
        label: 'Sarajevo, Skopje, Warsaw, Zagreb',
        value: 'Europe/Sarajevo',
    },
    {
        label: 'West Central Africa',
        value: 'Africa/Lagos',
    },
    {
        label: 'Amman',
        value: 'Asia/Amman',
    },
    {
        label: 'Athens, Bucharest, Istanbul',
        value: 'Europe/Athens',
    },
    {
        label: 'Beirut',
        value: 'Asia/Beirut',
    },
    {
        label: 'Cairo',
        value: 'Africa/Cairo',
    },
    {
        label: 'Harare, Pretoria',
        value: 'Africa/Harare',
    },
    {
        label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: 'Europe/Helsinki',
    },
    {
        label: 'Jerusalem',
        value: 'Asia/Jerusalem',
    },
    {
        label: 'Minsk',
        value: 'Europe/Minsk',
    },
    {
        label: 'Windhoek',
        value: 'Africa/Windhoek',
    },
    {
        label: 'Kuwait, Riyadh, Baghdad',
        value: 'Asia/Kuwait',
    },
    {
        label: 'Moscow, St. Petersburg, Volgograd',
        value: 'Europe/Moscow',
    },
    {
        label: 'Nairobi',
        value: 'Africa/Nairobi',
    },
    {
        label: 'Tbilisi',
        value: 'Asia/Tbilisi',
    },
    {
        label: 'Tehran',
        value: 'Asia/Tehran',
    },
    {
        label: 'Abu Dhabi, Muscat',
        value: 'Asia/Muscat',
    },
    {
        label: 'Baku',
        value: 'Asia/Baku',
    },
    {
        label: 'Yerevan',
        value: 'Asia/Yerevan',
    },
    {
        label: 'Kabul',
        value: 'Asia/Kabul',
    },
    {
        label: 'Yekaterinburg',
        value: 'Asia/Yekaterinburg',
    },
    {
        label: 'Islamabad, Karachi, Tashkent',
        value: 'Asia/Karachi',
    },
    {
        label: 'Chennai, Kolkata, Mumbai, New Delhi, Sri Jayawardenapura',
        value: 'Asia/Calcutta',
    },
    {
        label: 'Kathmandu',
        value: 'Asia/Katmandu',
    },
    {
        label: 'Almaty, Novosibirsk',
        value: 'Asia/Almaty',
    },
    {
        label: 'Astana, Dhaka',
        value: 'Asia/Dhaka',
    },
    {
        label: 'Yangon (Rangoon)',
        value: 'Asia/Rangoon',
    },
    {
        label: 'Bangkok, Hanoi, Jakarta',
        value: 'Asia/Bangkok',
    },
    {
        label: 'Krasnoyarsk',
        value: 'Asia/Krasnoyarsk',
    },
    {
        label: 'Beijing, Chongqing, Hong Kong, Urumqi',
        value: 'Asia/Hong_Kong',
    },
    {
        label: 'Kuala Lumpur, Singapore',
        value: 'Asia/Kuala_Lumpur',
    },
    {
        label: 'Irkutsk, Ulaan Bataar',
        value: 'Asia/Irkutsk',
    },
    {
        label: 'Perth',
        value: 'Australia/Perth',
    },
    {
        label: 'Taipei',
        value: 'Asia/Taipei',
    },
    {
        label: 'Osaka, Sapporo, Tokyo',
        value: 'Asia/Tokyo',
    },
    {
        label: 'Seoul',
        value: 'Asia/Seoul',
    },
    {
        label: 'Yakutsk',
        value: 'Asia/Yakutsk',
    },
    {
        label: 'Adelaide',
        value: 'Australia/Adelaide',
    },
    {
        label: 'Darwin',
        value: 'Australia/Darwin',
    },
    {
        label: 'Brisbane',
        value: 'Australia/Brisbane',
    },
    {
        label: 'Canberra, Melbourne, Sydney',
        value: 'Australia/Canberra',
    },
    {
        label: 'Hobart',
        value: 'Australia/Hobart',
    },
    {
        label: 'Guam, Port Moresby',
        value: 'Pacific/Guam',
    },
    {
        label: 'Vladivostok',
        value: 'Asia/Vladivostok',
    },
    {
        label: 'Magadan, Solomon Is., New Caledonia',
        value: 'Asia/Magadan',
    },
    {
        label: 'Auckland, Wellington',
        value: 'Pacific/Auckland',
    },
    {
        label: 'Fiji, Kamchatka, Marshall Is.',
        value: 'Pacific/Fiji',
    },
    {
        label: "Nuku'alofa",
        value: 'Pacific/Tongatapu',
    },
];

export const MODALITY_OPTIONS: SelectProps.Option[] = [
    { label: 'In-Person', value: 'in-person' },
    { label: 'Virtual', value: 'virtual' },
    { label: 'Hybrid', value: 'hybrid' },
];

export const ROLE_OPTIONS: SelectProps.Options = [
    { label: 'Administrator', value: ADMINISTRATOR_VALUE },
    { label: 'Support Staff', value: 'support_staff' },
];

export const PERIOD_SELECT_OPTIONS: SelectProps.Option[] = [
    { label: 'AM', value: 'am' },
    { label: 'PM', value: 'pm' },
];

/**
 * NOTE: Production events can only be created with published workshops
 */
export type AccountSourceTypes = EventAccountSource;

export const ACCOUNT_SOURCE_OPTIONS: SelectProps.Option[] = [
    {
        label:
            i18nStrings.events.create.wizard.values.accountSources
                .workshopStudio.label,
        description:
            i18nStrings.events.create.wizard.values.accountSources
                .workshopStudio.description,
        value: EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO,
    },
    {
        label:
            i18nStrings.events.create.wizard.values.accountSources
                .customerProvided.label,
        description:
            i18nStrings.events.create.wizard.values.accountSources
                .customerProvided.description,
        value: EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED,
    },
];

export type EventTypeTypes = EventType;

export const EVENT_TYPE_OPTIONS: TilesProps.TilesDefinition[] = [
    {
        label:
            i18nStrings.events.create.wizard.values.eventTypes.production.label,
        description:
            i18nStrings.events.create.wizard.values.eventTypes.production
                .description,
        value: EVENT_TYPE.PRODUCTION,
    },
    {
        label: i18nStrings.events.create.wizard.values.eventTypes.test.label,
        description:
            i18nStrings.events.create.wizard.values.eventTypes.test.description,
        value: EVENT_TYPE.TEST,
    },
];

export type RegionCategories = string[];
export const REQUIRED_REGION_CATEGORIES: RegionCategories = [
    i18nStrings.events.regionCategories.default,
    i18nStrings.events.regionCategories.required,
    i18nStrings.events.regionCategories.deploymentRegion,
];
export const DEFAULT_REGION_CATEGORIES: RegionCategories = [
    i18nStrings.events.regionCategories.recommended,
];
