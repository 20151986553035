import { DEFAULT_POLL_MS } from 'utils/config';

export const EVENT_DETAILS_REFETCH_INTERVAL = DEFAULT_POLL_MS / 2;

/**
 * Determines how long to suppress the event duration countdown message.
 * The decision to show the message only occurs when the component mounts
 * for a less jarring experience.
 */
export const EVENT_DURATION_MODAL_SNOOZE = 60 * 60 * 1000;
