import { AWSCredentials } from '@amzn/event-engine-js-utils';

import {
    AWSClient,
    EEContentCatalogClient,
    EEEventsClient,
    UnauthenticatedAWSClient,
} from './clients';

type TypeName =
    | 'EEEventsClient'
    | 'EEContentCatalogClient'
    | 'AWSClient'
    | 'UnauthenticatedAWSClient';

type ClientType<T> = T extends 'EEEventsClient'
    ? EEEventsClient
    : T extends 'EEContentCatalogClient'
    ? EEContentCatalogClient
    : T extends 'AWSClient'
    ? AWSClient
    : T extends 'UnauthenticatedAWSClient'
    ? UnauthenticatedAWSClient
    : never;

class EventEngineClientFactory {
    credentials = new AWSCredentials();

    constructor() {}

    public getClientSingleton = <T extends TypeName>(
        type: T
    ): ClientType<T> => {
        if (type === 'EEEventsClient') {
            return EEEventsClient.getInstance(this.credentials) as ClientType<
                EEEventsClient
            >;
        } else if (type === 'EEContentCatalogClient') {
            return EEContentCatalogClient.getInstance(
                this.credentials
            ) as ClientType<EEContentCatalogClient>;
        } else if (type === 'AWSClient') {
            return AWSClient.getInstance(this.credentials) as ClientType<
                AWSClient
            >;
        } else if (type === 'UnauthenticatedAWSClient') {
            return UnauthenticatedAWSClient.getInstance() as ClientType<
                UnauthenticatedAWSClient
            >;
        }

        return undefined as ClientType<undefined>;
    };
}

export default new EventEngineClientFactory();
