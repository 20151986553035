import { IMPACT_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import EventReadinessTable from 'components/EventReadinessTable';
import { ColumnID } from 'components/EventReadinessTable/config';
import React from 'react';

import EventImpactStatus from '../EventImpactStatus';
import { EventReadinessStatusProps } from './types';

const visibleColumns = [ColumnID.TITLE, ColumnID.IMPACT, ColumnID.MESSAGE];

const EventReadinessStatus = ({ value }: EventReadinessStatusProps) => {
    const status = <EventImpactStatus value={value.summary} />;
    const items = value.factors
        ? Object.values(
              value.factors as Record<
                  keyof EventEngineContentCatalogService.Factors,
                  EventEngineContentCatalogService.Factor
              >
          ).filter(({ impact }) => impact !== IMPACT_TYPES.READY)
        : [];

    return items.length ? (
        <Popover
            content={
                <EventReadinessTable
                    items={items}
                    visibleColumns={visibleColumns}
                    variant="compact"
                />
            }
            dismissButton={false}
            size="large">
            {status}
        </Popover>
    ) : (
        status
    );
};

export default EventReadinessStatus;
