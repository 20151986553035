import {
    Browser,
    ClientProvider,
    ClientProviderContext,
    CloudWatchLogSink,
    Logger,
    SessionProvider,
} from '@amzn/event-engine-js-utils';
import { isAuthenticatedState } from 'atoms';
import { AppTopNavProvider } from 'components/AppTopNav';
import EventEngineClientFactory from 'contexts/EventEngineClientFactory';
import React, { ContextType, FC, useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import AppSettings from 'services/app-settings';

import { AuthContext } from '../AuthProvider';
import UserProvider from '../UserProvider';

type ClientContextType = ContextType<typeof ClientProviderContext>;

const AuthenticatedProviders: FC = ({ children }) => {
    const { signOutManual } = useContext(AuthContext);
    const isAuth = useRecoilValue(isAuthenticatedState);
    const [isCredentialsHydrated, setIsCredentialsHydrated] = useState(false);
    const [clients, setClients] = useState<ClientContextType>();

    /**
     * Application logic that needs to be initialized after
     * being successfully authenticated.
     */
    useEffect(() => {
        if (!isAuth) {
            return;
        }

        // Hydrate credentials for API clients
        (async () => {
            const settings = await AppSettings();

            EventEngineClientFactory.credentials.authEndpoint =
                settings.authProxyEndpoint;

            try {
                await EventEngineClientFactory.credentials.getPromise();

                setIsCredentialsHydrated(true);
            } catch {
                signOutManual();
            }
        })();

        (async () => {
            const awsClient = EventEngineClientFactory.getClientSingleton(
                'AWSClient'
            );
            const authenticatedClients = await awsClient.getClient('Tenant');

            setClients(authenticatedClients);

            const timingData = Browser.getTimingData();

            SessionProvider.addAsyncData?.({
                identifierRoleArn: awsClient.roleArn,
                ...(timingData && { timing: timingData }),
            });

            Logger.addAvailableSink(
                new CloudWatchLogSink(authenticatedClients.cloudWatchLogsClient)
            );
        })();
    }, [isAuth]);

    if (!isCredentialsHydrated) {
        return null;
    }

    return (
        <ClientProvider
            metricsClient={clients?.metricsClient}
            s3Client={clients?.s3Client}>
            <UserProvider>
                <AppTopNavProvider>{children}</AppTopNavProvider>
            </UserProvider>
        </ClientProvider>
    );
};

export default AuthenticatedProviders;
