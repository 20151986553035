import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { CopyButton, ValueWithLabel } from '@amzn/event-engine-js-utils';
import { EventEngineContentCatalogService } from '@amzn/event-engine-sdk';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import { getBestAvailableEventPortalWorkshopUrl } from 'pages/Workshops/components/WorkshopUrls';
import { getContentPreviewLink } from 'pages/Workshops/utils';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getAttributeValueByName,
    WorkshopAttributes,
} from 'utils/content-attributes';

import { complexityLevels, i18nStrings } from '../../../../../../../constants';

export enum TestID {
    WORKSHOP_DETAILS_LINK = 'workshop-details-link',
    WORKSHOP_BUILD_DETAILS_LINK = 'workshop-build-details-link',
}

export interface ContentDetailsProps {
    content: EventEngineContentCatalogService.ContentData;
    /**
     * Content build ID associated with the event.
     * This should be specified for test events.
     */
    contentBuildId?: string;
}

const ContentDetails: FC<ContentDetailsProps> = ({
    content,
    contentBuildId,
}) => {
    const history = useHistory();
    // Use full details path for test events since the user will
    // have permissions to view this page. Use the public details
    // path for production events.
    const workshopDetailsHref = `${routes.workshops}/${
        contentBuildId ? '' : `${routeTokens.publicWorkshops}/`
    }${content.contentId}`;
    const complexityLevel = getAttributeValueByName(
        WorkshopAttributes['Complexity level'],
        content.attributes
    );
    const openLink = useCallback<NonNullable<LinkProps['onFollow']>>((e) => {
        e.preventDefault();

        e.detail.href && history.push(e.detail.href);
    }, []);

    // Link to the published workshop by default (long link or QuickLink)
    let workshopUrl = getBestAvailableEventPortalWorkshopUrl(content);

    // Link to build preview if the event content build ID is not published
    if (contentBuildId && content.publishedBuildId !== contentBuildId) {
        workshopUrl = getContentPreviewLink(
            {
                contentId: content.contentId!,
                contentBuildId,
            },
            true
        );
    }

    return (
        <Container
            header={
                <Header
                    actions={
                        workshopUrl ? (
                            <Button
                                href={workshopUrl}
                                iconName="external"
                                iconAlign="right"
                                target="_blank">
                                {
                                    i18nStrings.events.eventDetails
                                        .contentDetails.actions.viewWorkshop
                                }
                            </Button>
                        ) : null
                    }
                    variant="h2">
                    {i18nStrings.events.eventDetails.contentDetails.title}
                </Header>
            }>
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.workshopId
                        }>
                        <Box display="inline" margin={{ right: 'xxs' }}>
                            <CopyButton
                                variant="inline-icon"
                                content={content.contentId}
                            />
                        </Box>
                        <Link
                            href={workshopDetailsHref}
                            onFollow={openLink}
                            data-testid={TestID.WORKSHOP_DETAILS_LINK}>
                            {content.contentId}
                        </Link>
                    </ValueWithLabel>
                    {contentBuildId && (
                        <ValueWithLabel
                            label={
                                i18nStrings.events.eventDetails.contentDetails
                                    .values.workshopBuildId
                            }>
                            <Box display="inline" margin={{ right: 'xxs' }}>
                                <CopyButton
                                    variant="inline-icon"
                                    content={contentBuildId}
                                />
                            </Box>
                            <Link
                                href={`${routes.workshops}/${content.contentId}/${routeTokens.builds}/${contentBuildId}`}
                                onFollow={openLink}
                                data-testid={
                                    TestID.WORKSHOP_BUILD_DETAILS_LINK
                                }>
                                {contentBuildId}
                            </Link>
                        </ValueWithLabel>
                    )}
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.featuredAWSServices
                        }>
                        {getAttributeValueByName(
                            WorkshopAttributes['AWS services'],
                            content.attributes
                        )}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.title
                        }>
                        {content.title}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.topics
                        }>
                        {getAttributeValueByName(
                            WorkshopAttributes['Topics'],
                            content.attributes
                        )}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.complexityLevel
                        }>
                        {`${complexityLevel} - ${complexityLevels[complexityLevel]}`}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label={
                            i18nStrings.events.eventDetails.contentDetails
                                .values.industryVertical
                        }>
                        {getAttributeValueByName(
                            WorkshopAttributes['Industry vertical'],
                            content.attributes
                        )}
                    </ValueWithLabel>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
};

export default ContentDetails;
