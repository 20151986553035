import { QUOTA_ID as CONTENT_QUOTA_ID } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { QUOTA_ID as EVENT_QUOTA_ID } from '__generated__/@amzn/event-engine-events-sdk/enums';
import Box from '@amzn/awsui-components-react/polaris/box';
import { ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';
import { QuotaMonthly } from '@amzn/event-engine-events-sdk/clients/eventengineeventsservice';
import {
    AppTopNavigation,
    Constants,
    useRequest,
} from '@amzn/event-engine-js-utils';
import {
    useSetIsReportWSBugModalOpenState,
    useSetIsSupportRequestModalOpenState,
} from 'atoms';
import { routes } from 'components/AppRoutes/routes.constants';
import { GuardrailsContext } from 'contexts/GuardrailsProvider/GuardrailsProvider';
import { utcToZonedTime } from 'date-fns-tz';
import { useUserAttributes } from 'hooks';
import TransferAccountQuotaModal from 'pages/Quotas/components/TransferAccountQuotaModal';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { EEContentCatalog, EEEvents } from 'services';

import { i18nStrings } from '../../constants';
import { AppTopNavContext } from './AppTopNav.provider';
import { DisplayItem } from './components';
import { ItemID } from './types';

export interface AppTopNavProps {
    className?: string;
    id?: string;
    isAuthenticated?: boolean;
}

const AppTopNav = ({ className, id, isAuthenticated }: AppTopNavProps) => {
    const setReportWSBugModalOpenState = useSetIsReportWSBugModalOpenState();
    const setSupportRequestModalOpenState = useSetIsSupportRequestModalOpenState();
    const [transferQuotaModalVisible, setTransferQuotaModalVisible] = useState(
        false
    );
    const { acknowledgementRequired } = useContext(GuardrailsContext);
    const { refreshQuotaInfoHandle } = useContext(AppTopNavContext);
    const {
        data: getEventQuotaData,
        isError: getEventQuotaDataError,
        refetch: getWorkshopStudioAccountsQuota,
    } = useRequest(
        EEEvents.getQuota,
        {},
        { quotaId: EVENT_QUOTA_ID.ACCOUNTS_WORKSHOP_STUDIO }
    );
    const {
        data: getContentQuotaData,
        isError: getContentQuotaDataError,
        refetch: getWorkshopContentQuota,
    } = useRequest(
        EEContentCatalog.getQuota,
        { context: EEEvents },
        { quotaId: CONTENT_QUOTA_ID.CONTENT }
    );
    const history = useHistory();
    const { userAttributes, loadUserAttributes } = useUserAttributes({
        lazy: true,
    });
    const onAppHeaderClick = useCallback(
        (e) => {
            e.preventDefault();
            history.push(routes.landingPage);
        },
        [history]
    );
    const utilities = useMemo<TopNavigationProps.Utility[]>(() => {
        if (!userAttributes) {
            return [];
        }

        const emptyValue = `${i18nStrings.empty}/${i18nStrings.empty}`;
        const accountMonthlyQuotas = getEventQuotaData?.quota?.quotaMonthly;
        const quotaMenuItems: ButtonDropdownProps.Item[] = [];
        const currentMonth = new Date().getMonth();
        const accountCurrentMonthQuotaIndex = accountMonthlyQuotas
            ? accountMonthlyQuotas.findIndex(
                  (accountQuota) =>
                      // blockTime was a RFC3339 time string that was constructed into a Date object
                      // by the AWS SDK. Using the month value directly may not be accurate since
                      // quota blocks are keyed off of the UTC month and not the local TZ month.
                      utcToZonedTime(
                          accountQuota.blockTime,
                          'UTC'
                      ).getMonth() === currentMonth
              )
            : -2;
        const quotas: Record<'current' | 'next', QuotaMonthly | undefined> = {
            current: accountMonthlyQuotas?.[accountCurrentMonthQuotaIndex],
            next: accountMonthlyQuotas?.[accountCurrentMonthQuotaIndex + 1],
        };
        quotaMenuItems.push({
            id: ItemID.QUOTA_CURRENT_AWS_ACCOUNTS,
            text: ((
                <DisplayItem
                    text={
                        i18nStrings.userMenu.sections.quotas
                            .currentAWSAccountQuota
                    }>
                    {getEventQuotaDataError
                        ? emptyValue
                        : quotas.current &&
                          `${quotas.current.available}/${quotas.current.limit}`}
                </DisplayItem>
            ) as unknown) as string,
            disabled: true,
        });
        quotaMenuItems.push({
            id: ItemID.QUOTA_NEXT_AWS_ACCOUNTS,
            text: ((
                <DisplayItem
                    text={
                        i18nStrings.userMenu.sections.quotas.nextAWSAccountQuota
                    }>
                    {getEventQuotaDataError
                        ? emptyValue
                        : quotas.next &&
                          `${quotas.next.available}/${quotas.next.limit}`}
                </DisplayItem>
            ) as unknown) as string,
            disabled: true,
        });

        const contentMonthlyQuotas = getContentQuotaData?.quota?.quota;
        quotaMenuItems.push({
            id: ItemID.QUOTA_WORKSHOPS,
            text: ((
                <DisplayItem
                    text={
                        i18nStrings.userMenu.sections.quotas.currentContentQuota
                    }>
                    {getContentQuotaDataError
                        ? emptyValue
                        : contentMonthlyQuotas &&
                          `${contentMonthlyQuotas.available}/${contentMonthlyQuotas.limit}`}
                </DisplayItem>
            ) as unknown) as string,
            disabled: true,
        });

        return [
            {
                type: 'menu-dropdown',
                text: i18nStrings.feedbackMenu.title,
                onItemClick: (e) => {
                    if (e.detail.external) {
                        return;
                    }
                    switch (e.detail.id) {
                        case ItemID.REPORT_BUG:
                            setReportWSBugModalOpenState(true);
                            break;
                        case ItemID.SUBMIT_SUPPORT_REQUEST:
                            setSupportRequestModalOpenState(true);
                            break;
                    }
                },
                items: [
                    {
                        id: ItemID.SUBMIT_FEEDBACK,
                        text: i18nStrings.links.submitFeedback,
                        href: Constants.ExternalLinks.submitFeedback,
                        externalIconAriaLabel: i18nStrings.links.newTab,
                        external: true,
                    },
                    {
                        id: ItemID.SUBMIT_FEATURE_REQUEST,
                        text: i18nStrings.links.featureRequest,
                        href: Constants.ExternalLinks.featureRequest,
                        externalIconAriaLabel: i18nStrings.links.newTab,
                        external: true,
                    },
                    {
                        id: ItemID.SUBMIT_SUPPORT_REQUEST,
                        text: i18nStrings.links.supportRequest,
                    },
                    {
                        id: ItemID.REPORT_BUG,
                        text: i18nStrings.links.reportBug,
                    },
                ],
            },
            {
                type: 'menu-dropdown',
                text: userAttributes.fullName || userAttributes.username,
                description: userAttributes.email,
                iconName: 'user-profile',
                onItemClick: (e) => {
                    e.preventDefault();

                    if (e.detail.href) {
                        history.push(e.detail.href);
                    } else {
                        switch (e.detail.id) {
                            case ItemID.TRANSFER_QUOTA:
                                setTransferQuotaModalVisible(true);
                                break;
                        }
                    }
                },
                items: acknowledgementRequired
                    ? []
                    : [
                          {
                              id: ItemID.GROUP_QUOTAS,
                              text: ((
                                  <DisplayItem
                                      text={
                                          i18nStrings.userMenu.sections.quotas
                                              .title
                                      }>
                                      <Box variant="small">
                                          {
                                              i18nStrings.userMenu.sections
                                                  .quotas.quotaValueLabel
                                          }
                                      </Box>
                                  </DisplayItem>
                              ) as unknown) as string,
                              items: quotaMenuItems,
                          },
                          {
                              id: ItemID.MY_QUOTAS,
                              text: i18nStrings.myQuotas.title,
                              href: routes.quotas,
                          },
                          {
                              id: ItemID.TRANSFER_QUOTA,
                              text:
                                  i18nStrings.myQuotas.eventQuota.actions
                                      .transferQuota,
                          },
                      ],
            },
        ];
    }, [
        userAttributes,
        getEventQuotaData,
        getContentQuotaData,
        getEventQuotaDataError,
        getContentQuotaDataError,
        acknowledgementRequired,
    ]);
    const refreshQuotaInfo = useCallback(() => {
        getWorkshopStudioAccountsQuota();
        getWorkshopContentQuota();
    }, []);

    useEffect(() => {
        isAuthenticated && loadUserAttributes();
    }, [isAuthenticated]);

    useEffect(() => {
        refreshQuotaInfoHandle !== undefined && refreshQuotaInfo();
    }, [refreshQuotaInfoHandle]);

    return (
        <>
            <AppTopNavigation
                className={className}
                id={id}
                identity={{
                    href: routes.landingPage,
                    onFollow: onAppHeaderClick,
                }}
                utilities={utilities}
                sticky
            />
            {transferQuotaModalVisible && (
                <TransferAccountQuotaModal
                    visible={transferQuotaModalVisible}
                    onDismiss={() => setTransferQuotaModalVisible(false)}
                    onSuccess={refreshQuotaInfo}
                />
            )}
        </>
    );
};

export default AppTopNav;
