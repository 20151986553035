import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface EventDetailsProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
    refetchGetEventLoading: boolean;
    refetchGetEventError: boolean;
}

export enum ActionID {
    PROVISION = 'provision',
    TERMINATE = 'terminate',
    CANCEL = 'cancel',
    PAUSE = 'pause',
    RESUME = 'resume',
}

export enum NotificationID {
    EVENT_DURATION = 'event_duration',
    EVENT_CANCEL = 'event-cancel',
    ACCOUNTS_PROVISIONING_START = 'accounts_provisioning_start',
    EVENT_START = 'event_start',
    EVENT_TERMINATE = 'event_terminate',
    ERROR = 'request_error',
    ALLOWLIST_VALUE_MISSING = 'allowlist_value_missing',
}

export enum StoragePartitionName {
    NOTIFICATION_EVENT_DURATION = 'NOTIFICATION_EVENT_DURATION',
}
