import Box from '@amzn/awsui-components-react/polaris/box';
import { UserContext } from 'contexts/UserProvider';
import React, { useContext } from 'react';

import { QuotaReservationsTableContext } from '../../config';
import { QUOTA_USER_ROLE_DISPLAY } from './constants';
import { QuotaEmailDisplayProps, QuotaUserRole } from './types';

const QuotaEmailDisplay = ({ value }: QuotaEmailDisplayProps) => {
    const { data: userData } = useContext(UserContext);
    const { event } = useContext(QuotaReservationsTableContext);
    let userRole = '';

    if (userData?.userId === value) {
        userRole = QUOTA_USER_ROLE_DISPLAY[QuotaUserRole.SELF];
    }

    if (event.primaryFacilitator === value) {
        userRole = userRole
            ? `${userRole} (${QUOTA_USER_ROLE_DISPLAY[QuotaUserRole.OWNER]})`
            : QUOTA_USER_ROLE_DISPLAY[QuotaUserRole.OWNER];
    }

    return (
        <>
            {value}
            {userRole && (
                <>
                    {' '}
                    <Box tagOverride="em" color="text-status-inactive">
                        {userRole}
                    </Box>
                </>
            )}
        </>
    );
};

export default QuotaEmailDisplay;
