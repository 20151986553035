import { EVENT_ALLOWLIST_TYPE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

import { EMAIL_PATTERN_FIELD_DELIMITER } from '../constants';

const getEmailPatterns = (
    value: string
): EventEngineEventsService.EventAllowlist =>
    value
        .split(EMAIL_PATTERN_FIELD_DELIMITER)
        .map((pattern) => ({
            pattern: pattern.trim(),
            type: EVENT_ALLOWLIST_TYPE.EMAIL,
        }))
        .filter(({ pattern }) => !!pattern);

export default {
    getEmailPatterns,
};
