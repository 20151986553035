import { ContentPermissionsUserGroupList } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { useIsMounted } from 'hooks';
import { useEffect, useState } from 'react';
import { getAmplifyUsername } from 'utils';

import { userRoles } from '../../constants';

class UserPermissions {
    // A user who can publish content
    canPublish?: boolean;
    // A user who can push commits to the content CodeCommit repository
    canContribute?: boolean;
    // A user who can delete content
    canDeleteContent?: boolean;

    constructor(data?: UserPermissions) {
        Object.assign(this, data);
    }
}

/**
 * Utility hook that will return an object with user permissions.
 * To avoid redundant API calls, this hook does not fetch data.
 * This hook may be deprecated once permissions is fully implemented on the backend
 * @param {string} contentOwner
 * @param {ContentPermissionsUserGroupList} userGroupList
 */
const useContentPermissions = (
    contentOwner?: string,
    userGroupList?: ContentPermissionsUserGroupList
) => {
    const { isMounted } = useIsMounted();
    const [userPermissions, setUserPermissions] = useState(
        new UserPermissions()
    );

    useEffect(() => {
        if (!contentOwner || !userGroupList) {
            return;
        }

        getAmplifyUsername().then((username) => {
            const roles = userGroupList.find((user) =>
                user.principal.startsWith(`${username}@`)
            )?.roles;
            const isOwner = contentOwner.startsWith(`${username}@`);
            const isCoOwner = Boolean(roles?.includes(userRoles.co_owner));
            const isContributor = Boolean(
                roles?.includes(userRoles.contributor)
            );
            isMounted.current &&
                setUserPermissions(
                    new UserPermissions({
                        // Content owners and co-owners can publish
                        canPublish: isOwner || isCoOwner,
                        // Content owners, co-owners, and contributors can contribute
                        canContribute: isOwner || isCoOwner || isContributor,
                        canDeleteContent: isOwner || isCoOwner,
                    })
                );
        });
    }, [contentOwner, userGroupList]);

    return userPermissions;
};

export default useContentPermissions;
