import { CognitoUserSession } from 'amazon-cognito-identity-js';

export enum IdentityProvider {
    AMAZON_FEDERATE = 'AmazonFederate',
}

export interface AuthIdentity {
    dateCreated: string;
    issuer: string | null;
    primary: string;
    providerName: IdentityProvider;
    providerType: string;
    userId: string;
}

export interface DecodedToken {
    acl_email_allowed: string;
    at_hash: string;
    aud: string;
    auth_time: number;
    'cognito:username': string;
    email: string;
    email_verified: boolean;
    exp: number;
    family_name?: string;
    given_name?: string;
    hat_id: string;
    iat: number;
    identities?: AuthIdentity[];
    iss: string;
    jti: string;
    name: string;
    nonce: string;
    origin_jti: string;
    public_provider_name: IdentityProvider;
    sub: string;
    token_use: string;
    vibe_user_id: string;
}

interface AuthenticateOptions {
    redirect?: string;
}

export interface AuthContextValue {
    signIn: (options?: AuthenticateOptions) => Promise<void>;
    signOut: () => Promise<void>;
    signOutManual: () => Promise<void>;
    getCurrentSession: () => Promise<CognitoUserSession | undefined>;
}
