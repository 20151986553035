const links = {
    immersionDay: {
        gettingStarted: {
            contribute:
                'https://immersionday.com/how-to-contribute-immersion-day-content',
        },
    },
};

export default links;
