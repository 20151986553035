import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';

export interface QuotaReservationTableItem {
    email: string;
    reserved: number;
    consumed: number;
}

export interface QuotaReservationsTableProps {
    event: EventEngineEventsService.EventData;
    refetchGetEvent: () => Promise<void>;
    refetchGetEventLoading?: boolean;
    reservations?: EventEngineEventsService.AccountQuotaContributions;
}

export enum ActionID {
    ADD_RESERVATION = 'add_reservation',
    RELEASE_RESERVATION = 'release_reservation',
}

export interface QuotaReservationsTableContextValue {
    event: EventEngineEventsService.EventData;
}
