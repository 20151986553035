import { TEAM_STATE } from '__generated__/@amzn/event-engine-events-sdk/enums';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import isValid from 'date-fns/isValid';
import getFormattedDuration from 'utils/get-formatted-duration';

import { TeamsDeploymentState, TeamsStats } from '../types';

const getTeamsStats = (data?: EventEngineEventsService.TeamList) => {
    const stats: TeamsStats = {
        state: TeamsDeploymentState.NotStarted,
    };

    if (!data) {
        return stats;
    }

    stats.failed = 0;
    stats.halted = 0;
    stats.inProgress = 0;
    stats.queued = 0;
    stats.success = 0;
    stats.terminated = 0;
    stats.totalDeploymentTimeMS = 0;
    stats.totalTeamsSuccessfulDeployment = 0;

    data.forEach(({ state, deploymentBeginAt, deploymentEndAt }) => {
        switch (state) {
            case TEAM_STATE.DEPLOYMENT_FAILED:
                stats.failed! += 1;
                break;
            case TEAM_STATE.PROVISION_FAILED:
                stats.failed! += 1;
                break;
            case TEAM_STATE.DEPLOYMENT_HALTED:
                stats.halted! += 1;
                break;
            case TEAM_STATE.DEPLOYMENT_IN_PROGRESS:
                stats.inProgress! += 1;
                break;
            case TEAM_STATE.PROVISION_IN_PROGRESS:
                stats.inProgress! += 1;
                break;
            case TEAM_STATE.DEPLOYMENT_QUEUED:
                stats.queued! += 1;
                break;
            case TEAM_STATE.DEPLOYMENT_SUCCESS:
                stats.success! += 1;
                break;
            case TEAM_STATE.TERMINATE_SUCCESS:
                stats.terminated! += 1;
                break;
        }
        if (
            deploymentBeginAt &&
            deploymentEndAt &&
            isValid(deploymentBeginAt) &&
            isValid(deploymentEndAt)
        ) {
            stats.totalTeamsSuccessfulDeployment! += 1;
            stats.totalDeploymentTimeMS! += differenceInMilliseconds(
                deploymentEndAt,
                deploymentBeginAt
            );
        }
    });

    if (stats.totalTeamsSuccessfulDeployment > 0) {
        stats.averageDeploymentDuration = getFormattedDuration(
            Math.round(
                stats.totalDeploymentTimeMS /
                    stats.totalTeamsSuccessfulDeployment
            )
        );
    }

    if (stats.inProgress > 0 || stats.queued > 0) {
        stats.state = TeamsDeploymentState.InProgress;
    } else if (stats.success > 0 && (stats.failed > 0 || stats.halted > 0)) {
        stats.state = TeamsDeploymentState.SuccessPartial;
    } else if (stats.failed > 0) {
        stats.state = TeamsDeploymentState.Failed;
    } else if (stats.halted > 0) {
        stats.state = TeamsDeploymentState.Halted;
    } else if (stats.success > 0) {
        stats.state = TeamsDeploymentState.Success;
    }

    return stats;
};

export default getTeamsStats;
