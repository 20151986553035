import Alert from '@amzn/awsui-components-react/polaris/alert';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventMatrix, formatString } from '@amzn/event-engine-js-utils';
import { AccountType } from 'components/AppRoutes/routes.constants';
import i18nStrings from 'constants/i18n-strings';
import StackDeploymentEventsTable from 'pages/Events/EventDetails/components/StackDeploymentEventsTable';
import StackNameCards from 'pages/Events/EventDetails/components/StackNameCards/StackNameCards';
import { StackNameCardsProps } from 'pages/Events/EventDetails/components/StackNameCards/types';
import { VIEW_IN_CLOUDFORMATION_KEY } from 'pages/Events/EventDetails/components/ViewInCloudFormation/types';
import ViewInCloudFormation from 'pages/Events/EventDetails/components/ViewInCloudFormation/ViewInCloudFormation';
import useTerminateTeam from 'pages/Events/EventDetails/hooks/use-terminate-team';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TeamDetailsContext } from '../../constants';
import StackOutputsTable from './components/StackOutputsTable';

export enum SearchParamKey {
    STACK_NAME = 'stack_name',
}
const STORAGE_SCOPE = 'stackDeploymentEventsTable_11072022';

const TeamStacks = () => {
    const history = useHistory();
    const location = useLocation();
    const { teamRequest, eventRequest } = useContext(TeamDetailsContext);
    const { isDisabled: isTerminateTeamDisabled } = useTerminateTeam();
    const searchParams = new URLSearchParams(location.search);
    const updateSearchParams = (name: string) => {
        searchParams.set(SearchParamKey.STACK_NAME, name);
        history.replace({
            ...location,
            search: searchParams.toString(),
        });
    };
    const stackName = searchParams.get(SearchParamKey.STACK_NAME);
    const initialSelectedStack = teamRequest.data?.team?.stacks?.find(
        ({ name }) =>
            name === (stackName || teamRequest.data?.team?.stacks?.[0].name)
    );
    const [selectedStack, setSelectedStack] = useState(initialSelectedStack);
    const onSelectionChange = useCallback<
        StackNameCardsProps['onSelectionChange']
    >(
        (item) => {
            setSelectedStack(item);
            item.name && updateSearchParams(item.name);
        },
        [location]
    );

    useEffect(() => {
        const defaultStackName = teamRequest.data?.team?.stacks?.[0]?.name;

        if (stackName || !defaultStackName) {
            return;
        }

        updateSearchParams(defaultStackName);
    }, [location]);

    if (!teamRequest.data?.team) {
        return null;
    }

    return (
        <Grid
            gridDefinition={[
                { colspan: { default: 12, xs: 6, s: 4, l: 3, xl: 2 } },
                { colspan: { default: 12, xs: 6, s: 8, l: 9, xl: 10 } },
            ]}>
            <StackNameCards
                stacks={teamRequest.data.team.stacks || []}
                initialSelectedItem={selectedStack}
                onSelectionChange={onSelectionChange}
                error={teamRequest.isError}
                loading={teamRequest.isLoading}
                onRefresh={teamRequest.refetch}
            />
            {selectedStack || !stackName ? (
                <SpaceBetween direction="vertical" size="l">
                    <StackDeploymentEventsTable
                        stack={selectedStack}
                        error={teamRequest.isError}
                        loading={teamRequest.isLoading}
                        storageScope={STORAGE_SCOPE}
                        additionalActions={
                            selectedStack &&
                            eventRequest.data?.event?.eventId &&
                            !isTerminateTeamDisabled(teamRequest.data.team) &&
                            EventMatrix.isEventActive({
                                runtime: eventRequest.data.event.stateRuntime,
                                infrastructure:
                                    eventRequest.data.event.stateInfrastructure,
                            })
                                ? [
                                      <ViewInCloudFormation
                                          key={VIEW_IN_CLOUDFORMATION_KEY}
                                          eventId={
                                              eventRequest.data.event.eventId
                                          }
                                          resourceId={
                                              teamRequest.data.team.teamId
                                          }
                                          accountType={AccountType.TEAMS}
                                          stackArn={selectedStack?.arn}
                                      />,
                                  ]
                                : undefined
                        }
                        onRefresh={teamRequest.refetch}
                    />
                    <StackOutputsTable stackName={selectedStack?.name} />
                </SpaceBetween>
            ) : (
                <Alert type="error">
                    {formatString(
                        i18nStrings.events.teams.teamStacks.alerts.noSuchStack,
                        stackName
                    )}
                </Alert>
            )}
        </Grid>
    );
};

export default TeamStacks;
