import { formatString } from '@amzn/event-engine-js-utils';
import * as Yup from 'yup';

import { i18nStrings } from '../../../../../constants';
import { EMAIL_PATTERN_FIELD_DELIMITER } from './constants';
import { FieldName } from './types';
import checkEmailPattern from './utils/check-email-pattern';

interface GetSchemaOptions {
    emailPatterns?: {
        isRequired?: boolean;
    };
}

export const getSchema = (options: GetSchemaOptions = {}) => {
    const emailPatternsSchema = Yup.string().test((value, context) => {
        const patterns = value?.split(EMAIL_PATTERN_FIELD_DELIMITER);

        if (patterns?.length) {
            const validations = patterns.map(checkEmailPattern);
            const firstErrorIndex = validations.findIndex(
                ({ isValid }) => !isValid
            );
            if (firstErrorIndex > -1) {
                return context.createError({
                    message: formatString(
                        i18nStrings.events.create.wizard.steps
                            .participantAllowlist.fields.emailPatterns.errors
                            .template,
                        firstErrorIndex + 1,
                        validations[firstErrorIndex].error
                    ),
                });
            }
        }

        return true;
    });

    return Yup.object({
        [FieldName.ALLOW_ALL]: Yup.boolean(),
        [FieldName.EMAIL_PATTERNS]: Yup.string().when(FieldName.ALLOW_ALL, {
            is: (value: boolean) => !value,
            then: Yup.lazy(() => {
                if (options.emailPatterns?.isRequired) {
                    return emailPatternsSchema
                        .required(i18nStrings.required)
                        .test((value, context) => {
                            if (!value?.trim()) {
                                return context.createError({
                                    message: i18nStrings.required,
                                });
                            }

                            return true;
                        });
                }

                return emailPatternsSchema;
            }),
        }),
    });
};

export default getSchema();
