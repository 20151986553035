import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import BreadcrumbGroup, {
    BreadcrumbGroupProps,
} from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import Button from '@amzn/awsui-components-react/polaris/button';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import {
    AuthTokenExceptionCopyText,
    AuthTokenExceptionModal,
    AuthTokenExceptions,
    ClientProviderContext,
    Constants,
    Cookies,
    ErrorBoundary,
    EventEmitter,
    Footer as AppFooter,
    LogLevel,
    ReportBugModal,
    SinkType,
    useLogger,
    useMetric,
    useScrollPadding,
} from '@amzn/event-engine-js-utils';
import {
    domElementDirectionState,
    isAuthenticatedState,
    useIsReportWSBugModalOpenState,
    useIsSupportRequestModalOpenState,
} from 'atoms';
import AppRoutes from 'components/AppRoutes';
import ErrorPageController from 'components/ErrorPageController';
import SupportRequestModal from 'components/SupportRequestModal';
import i18nStrings from 'constants/i18n-strings';
import { CloudWatchMetric } from 'constants/metrics';
import useFeatureFlag from 'hooks/use-feature-flag';
import useNotification from 'hooks/use-notification';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
    breadcrumbsState,
    contentTypeState,
    disableContentPaddingState,
    hideAppNavState,
    maxContentWidthState,
    navigationContentState,
    navigationOpenState,
    notificationsState,
    toolsState,
} from '../../atoms';
import AppNav from '../AppNav';
import AppTopNav from '../AppTopNav';

const cookies = Cookies.getInstance();
const componentIds = {
    header: 'app-header',
    footer: 'app-footer',
    notifications: 'app-notifications',
};

export const Main = () => {
    const { updateScrollPadding } = useScrollPadding();
    const [authTokenExceptionType, setAuthTokenExceptionType] = useState<
        AuthTokenExceptions
    >();
    const { showNotification } = useNotification();
    const { addCounterMetric } = useMetric();
    const [errorBoundaryLogger] = useLogger(
        'ErrorBoundaryEEWebConsole',
        LogLevel.ERROR,
        [SinkType.CloudWatchLogSink]
    );

    const { metricsClient } = useContext(ClientProviderContext);
    const [appNavContent, setAppNavContent] = useRecoilState(
        navigationContentState
    );
    const [
        isReportWSBugModalOpenState,
        setReportWSBugModalOpenState,
    ] = useIsReportWSBugModalOpenState();
    const [
        isSupportRequestModalOpenState,
        setSupportRequestModalOpenState,
    ] = useIsSupportRequestModalOpenState();
    const [tools, setTools] = useRecoilState(toolsState);
    const [navigationOpen, setNavigationOpen] = useRecoilState(
        navigationOpenState
    );
    const breadcrumbs = useRecoilValue(breadcrumbsState);
    const contentType = useRecoilValue(contentTypeState);
    const notifications = useRecoilValue(notificationsState);
    const disableContentPadding = useRecoilValue(disableContentPaddingState);
    const maxContentWidth = useRecoilValue(maxContentWidthState);
    const hideAppNav = useRecoilValue(hideAppNavState);
    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const domElementDirection = useRecoilValue(domElementDirectionState);
    const history = useHistory();
    const eventEmitter = useRef(EventEmitter.getInstance());
    const navigateBreadcrumb = (
        event: CustomEvent<BreadcrumbGroupProps.ClickDetail>
    ) => {
        event.preventDefault();
        if (!event.detail.href) {
            return;
        }
        history.push(event.detail.href);
    };
    const provisioningEnabled = useFeatureFlag('provisioning');

    useEffect(() => {
        if (provisioningEnabled === undefined) {
            return;
        }

        !provisioningEnabled &&
            showNotification(i18nStrings.drEnvironmentWarning, {
                type: 'warning',
                action: (
                    <Button
                        href="https://studio.workshops.aws"
                        iconName="external"
                        iconAlign="right">
                        {i18nStrings.backToPrimaryEnvironment}
                    </Button>
                ),
            });
    }, [provisioningEnabled]);

    useEffect(() => {
        // when the main bundle.js has been downloaded, remove the raw loader
        // from the view.
        const rawLoader = document.getElementById('raw-loader-container');
        rawLoader?.classList?.add('hide-raw-loader-container');

        // check for cookie consent once app loads
        cookies.checkForConsent();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        eventEmitter.current.on(Constants.EVENTS.NOT_AUTHENTICATED, () => {
            setAuthTokenExceptionType('AmazonCorporateSSOToken');
            showNotification(
                AuthTokenExceptionCopyText.AmazonCorporateSSOToken.body,
                { type: 'warning' }
            );
        });

        eventEmitter.current.on(Constants.EVENTS.NETWORK_ERROR, () => {
            setAuthTokenExceptionType('NetworkError');
            showNotification(AuthTokenExceptionCopyText.NetworkError.body, {
                type: 'warning',
            });
        });

        setAppNavContent(<AppNav />);
    }, [isAuthenticated]);

    useEffect(() => {
        metricsClient &&
            addCounterMetric(CloudWatchMetric.AUTHENTICATED_APP_INITIALIZED);
    }, [metricsClient]);

    useEffect(() => {
        updateScrollPadding();
    }, [notifications]);

    const onCloseModal = useCallback(() => {
        setReportWSBugModalOpenState(false);
    }, []);

    return (
        <div dir={domElementDirection}>
            {isReportWSBugModalOpenState && (
                <ReportBugModal
                    isVisible={isReportWSBugModalOpenState}
                    onModalClosed={onCloseModal}
                    onComplete={onCloseModal}
                />
            )}
            {isSupportRequestModalOpenState && (
                <SupportRequestModal
                    isVisible={isSupportRequestModalOpenState}
                    onModalClosed={() => {
                        setSupportRequestModalOpenState(false);
                    }}
                    onComplete={() => {
                        setSupportRequestModalOpenState(false);
                    }}
                />
            )}
            <AppTopNav
                id={componentIds.header}
                isAuthenticated={isAuthenticated}
            />
            <AppLayout
                breadcrumbs={
                    breadcrumbs?.length ? (
                        <BreadcrumbGroup
                            ariaLabel="Breadcrumbs"
                            items={breadcrumbs}
                            onClick={navigateBreadcrumb}
                        />
                    ) : null
                }
                maxContentWidth={maxContentWidth}
                navigation={appNavContent}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail: { open } }) =>
                    setNavigationOpen(open)
                }
                navigationHide={!isAuthenticated || hideAppNav}
                tools={tools.content}
                toolsHide={tools.hide}
                toolsOpen={tools.open}
                onToolsChange={({ detail: { open } }) =>
                    setTools({ ...tools, open })
                }
                content={
                    <ErrorBoundary
                        addCounterMetric={addCounterMetric}
                        logger={errorBoundaryLogger}
                        fallback={<ErrorPageController type="catastrophic" />}>
                        {isAuthenticated && authTokenExceptionType && (
                            <AuthTokenExceptionModal
                                type={authTokenExceptionType}
                            />
                        )}
                        <AppRoutes />
                    </ErrorBoundary>
                }
                notifications={
                    notifications?.length ? (
                        <div id={componentIds.notifications}>
                            <Flashbar items={notifications} />
                        </div>
                    ) : null
                }
                contentType={contentType}
                headerSelector={`#${componentIds.header}`}
                footerSelector={`#${componentIds.footer}`}
                disableContentPaddings={disableContentPadding}
                stickyNotifications
            />
            <AppFooter id={componentIds.footer} sticky dir="ltr" />
        </div>
    );
};

export default Main;
