import i18nStrings from 'constants/i18n-strings';
import * as Yup from 'yup';

export enum FieldName {
    SELECTED_ASSETS = 'selected assets',
}

export type SchemaType = Yup.InferType<typeof Schema>;

const Schema = Yup.object({
    [FieldName.SELECTED_ASSETS]: Yup.array()
        .of(
            Yup.object().shape({
                s3Key: Yup.string().required(),
                stagingVersionId: Yup.string().required(),
            })
        )
        .min(
            1,
            i18nStrings.workshops.generateWorkshopStaticAssetURLs.form
                .errorMessage
        )
        .required(
            i18nStrings.workshops.generateWorkshopStaticAssetURLs.form
                .errorMessage
        ),
});

export default Schema;
