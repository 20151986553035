import { IMPACT_TYPES } from '__generated__/@amzn/event-engine-content-catalog-sdk/enums';
import { ContentData } from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { useRecoilContentTypeState } from 'atoms';
import { routes, routeTokens } from 'components/AppRoutes/routes.constants';
import i18nStrings from 'constants/i18n-strings';
import { FormikContextType, useFormikContext } from 'formik';
import { WorkshopCatalogTablePublicContainer } from 'pages/Workshops/components/WorkshopCatalogTable';
import React, { useCallback, useEffect, useMemo } from 'react';

import {
    MergedFormSchema,
    Step,
} from '../../components/CreateEventForm.validation';

const SelectWorkshop = () => {
    const formik: FormikContextType<MergedFormSchema> = useFormikContext();
    const [contentState, setContentTypeState] = useRecoilContentTypeState();
    const selectedItems = useMemo(() => {
        const selectedWorkshop = formik.values[Step.SELECT_WORKSHOP];

        return selectedWorkshop ? [selectedWorkshop] : undefined;
    }, [formik.values]);
    const onSelectionChange = useCallback((contentData: ContentData[]) => {
        const content = contentData[0];

        formik.setFieldValue(Step.SELECT_WORKSHOP, {
            contentId: content.contentId!,
            contentBuildId: content.publishedBuildId!,
            contentSpecSummary: content.contentSpecSummary!,
            title: content.title!,
            publishedBuildId: content.publishedBuildId,
        });
    }, []);

    useEffect(() => {
        /**
         * Special Requirement:
         *
         * The table in Step 1 is difficult to read using the wizard content type.
         * Use the table content type for only step 1, and use the recommended
         * wizard for the rest of the steps.
         */
        setContentTypeState('table');

        return () => {
            setContentTypeState('wizard');
        };
    }, []);

    if (contentState !== 'table') {
        return null;
    }

    return (
        <WorkshopCatalogTablePublicContainer
            id="select_workshop_table"
            variant="select"
            description={
                i18nStrings.events.create.wizard.steps.selectWorkshop
                    .description
            }
            selectedItems={selectedItems}
            onSelectionChange={onSelectionChange}
            filter={({ eventReadiness }) =>
                eventReadiness.summary !== IMPACT_TYPES.BLOCKED
            }
            basePath={`${routes.workshops}/${routeTokens.publicWorkshops}`}
        />
    );
};

export default SelectWorkshop;
