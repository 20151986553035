import Icon from '@amzn/awsui-components-react/polaris/icon';
import { Constants } from '@amzn/event-engine-js-utils';
import darkBuildIcon from 'assets/images/dark_build.png';
import darkDeliverIcon from 'assets/images/dark_deliver.png';
import darkDiscoverIcon from 'assets/images/dark_discover.png';
import darkPublishIcon from 'assets/images/dark_publish.png';
import lightBuildIcon from 'assets/images/light_build.png';
import lightDeliverIcon from 'assets/images/light_deliver.png';
import lightDiscoverIcon from 'assets/images/light_discover.png';
import lightPublishIcon from 'assets/images/light_publish.png';
import React from 'react';
import { DOCUMENTATION_PATHS } from 'utils/config';

import { i18nStrings } from '../../constants';

export interface ILandingPageConfig {
    config: {
        headerTitle: string;
        headerDescription: string;
        sections: {
            howItWorks: {
                iconUrl: {
                    light: string;
                    dark: string;
                };
                title: {
                    action: string;
                    target: string;
                };
                description: string;
            }[];
        };
        documentation: {
            title: JSX.Element;
            links: {
                id: number;
                text: string;
                /**
                 * Initially, href is empty until it is populated by the API call
                 * from AppSettings
                 */
                href: string;
                /**
                 * Path refers to the location that the particular piece of content
                 * lives under in the EE2 documentation link
                 */
                path: string;
            }[];
        };
        moreResources: {
            title: JSX.Element;
            resources: {
                id: number;
                text: string;
                href: string;
            }[];
        };
    };
}

// if we want to bust the cache of the landing page, update the date.
const LOCAL_STORAGE_PREFIX = '07_16_2021';
export const LANDING_PAGE_LOCATION_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}_LANDING_PAGE_LOCATION_STORAGE_KEY`;

export enum CallToActionOption {
    BROWSE_CATALOG = 'BROWSE_CATALOG',
    CREATE_WORKSHOP = 'CREATE_WORKSHOP',
}

const LandingPageConfig: ILandingPageConfig = {
    config: {
        headerTitle: `AWS ${i18nStrings.appName}`,
        headerDescription:
            'Build, review, publish, and discover hands-on content',
        sections: {
            howItWorks: [
                {
                    iconUrl: {
                        dark: darkDiscoverIcon,
                        light: lightDiscoverIcon,
                    },
                    title: {
                        action:
                            i18nStrings.landing.sections.howItWorks.discover
                                .title.action,
                        target:
                            i18nStrings.landing.sections.howItWorks.discover
                                .title.target,
                    },
                    description:
                        i18nStrings.landing.sections.howItWorks.discover
                            .description,
                },
                {
                    iconUrl: {
                        dark: darkBuildIcon,
                        light: lightBuildIcon,
                    },
                    title: {
                        action:
                            i18nStrings.landing.sections.howItWorks.build.title
                                .action,
                        target:
                            i18nStrings.landing.sections.howItWorks.build.title
                                .target,
                    },
                    description:
                        i18nStrings.landing.sections.howItWorks.build
                            .description,
                },
                {
                    iconUrl: {
                        dark: darkPublishIcon,
                        light: lightPublishIcon,
                    },
                    title: {
                        action:
                            i18nStrings.landing.sections.howItWorks.publish
                                .title.action,
                        target:
                            i18nStrings.landing.sections.howItWorks.publish
                                .title.target,
                    },
                    description:
                        i18nStrings.landing.sections.howItWorks.publish
                            .description,
                },
                {
                    iconUrl: {
                        dark: darkDeliverIcon,
                        light: lightDeliverIcon,
                    },
                    title: {
                        action:
                            i18nStrings.landing.sections.howItWorks.deliver
                                .title.action,
                        target:
                            i18nStrings.landing.sections.howItWorks.deliver
                                .title.target,
                    },
                    description:
                        i18nStrings.landing.sections.howItWorks.deliver
                            .description,
                },
            ],
        },
        documentation: {
            title: (
                <>
                    Documentation <Icon alt="Documentation" name="external" />
                </>
            ),
            links: [
                {
                    id: 0,
                    text: 'Finding a workshop',
                    href: '',
                    path: DOCUMENTATION_PATHS.FINDING_A_WORKSHOP,
                },
                {
                    id: 1,
                    text: 'Authoring a workshop',
                    href: '',
                    path: DOCUMENTATION_PATHS.AUTHORING_A_WORKSHOP,
                },
                {
                    id: 2,
                    text: 'Reviewing and Publishing a workshop',
                    href: '',
                    path: DOCUMENTATION_PATHS.REVIEW_AND_PUBLISH_A_WORKSHOP,
                },
            ],
        },
        moreResources: {
            title: (
                <>
                    More resources <Icon alt="More resources" name="external" />
                </>
            ),
            resources: [
                {
                    id: 0,
                    text: `${i18nStrings.appName} Wiki`,
                    href: Constants.ExternalLinks.wiki,
                },
                {
                    id: 1,
                    text: 'Submit a Feature request',
                    href: Constants.ExternalLinks.featureRequest,
                },
                {
                    id: 3,
                    text: 'Submit Feedback',
                    href: Constants.ExternalLinks.submitFeedback,
                },
            ],
        },
    },
};

export const externalLinkProps = {
    external: true,
    externalIconAriaLabel: 'Opens in a new tab',
};

export default LandingPageConfig;
