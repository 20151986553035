import i18nStrings from 'constants/i18n-strings';
import * as Yup from 'yup';

export enum FieldName {
    CONFIRM = 'confirm',
    CONFIRMATION_STRING = 'confirmation_string',
}

const Schema = Yup.object({
    [FieldName.CONFIRMATION_STRING]: Yup.string().required(),
    [FieldName.CONFIRM]: Yup.string()
        .trim()
        .required(i18nStrings.required)
        .test({
            test: (value, context) =>
                value === context.parent[FieldName.CONFIRMATION_STRING],
            message:
                i18nStrings.events.durationWarning.modal
                    .durationConfirmationFieldError,
        })
        .ensure(),
});

export type SchemaType = Yup.InferType<typeof Schema>;
export default Schema;
