import Button from '@amzn/awsui-components-react/polaris/button';
import useFeatureFlag from 'hooks/use-feature-flag';
import csvDownload from 'json-to-csv-export';
import React from 'react';

import { i18nStrings } from '../../../../../../../../../constants';
import { TeamsCsvDownloadActionProps } from './types';
import normalizeFileName from './utils/normalize-file-name';
import processData from './utils/process-data';

const TeamsCsvDownloadAction = ({
    accountSource,
    filename,
    teams,
}: TeamsCsvDownloadActionProps) => {
    // TODO: Remove once teamCodeJoining is generally available
    const teamCodeFeatureEnabled = useFeatureFlag('teamCodeJoining');
    const handleClick = () => {
        teams &&
            csvDownload({
                data: processData(teams, accountSource, teamCodeFeatureEnabled),
                filename: normalizeFileName(filename),
                delimiter: ',',
            });
    };

    return (
        <Button
            onClick={handleClick}
            disabled={!teams?.length}
            iconName="download">
            {i18nStrings.events.teams.downloadCsvAction}
        </Button>
    );
};

export default TeamsCsvDownloadAction;
