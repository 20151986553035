import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';

import { i18nStrings, tableConfig } from '../../../../../../../constants';
import { ROLE_OPTIONS } from '../../../../utils/constants';
import { ReviewFacilitatorsTableItem } from './types';

const ROLE_OPTIONS_LOOKUP: Record<string, typeof ROLE_OPTIONS[0]> = {};
ROLE_OPTIONS.forEach((option: SelectProps.Option) => {
    ROLE_OPTIONS_LOOKUP[option.value as string] = option;
});

export const columnIds = {
    email: 'email',
    role: 'role',
};

type ColumnFieldMap = Record<
    keyof typeof columnIds,
    keyof ReviewFacilitatorsTableItem
>;

export const columnFieldMap: ColumnFieldMap = {
    email: 'key',
    role: 'value',
};

export const defaultPreferences: CollectionPreferencesProps.Preferences = {
    ...tableConfig.defaultPreferences,
    visibleContent: [columnIds.email, columnIds.role],
};

export const defaultSortPreferences: TableProps.SortingState<ReviewFacilitatorsTableItem> = {
    ...tableConfig.defaultSortPreferences,
    sortingColumn: {
        sortingField: columnFieldMap.email,
    },
};

export const columnDefinitions: TableProps.ColumnDefinition<
    ReviewFacilitatorsTableItem
>[] = [
    {
        id: columnIds.email,
        header: 'Email',
        cell: (item) => item.key,
        sortingField: columnFieldMap.email,
    },
    {
        id: columnIds.role,
        header: 'Role',
        cell: (item) =>
            ROLE_OPTIONS_LOOKUP[item.value].label || i18nStrings.empty,
        sortingField: columnFieldMap.role,
    },
];

export { i18nStrings, tableConfig };
