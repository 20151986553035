/**
 * Simple pluralize implementation
 *
 * @param {string} label
 * @param {number} count
 * @returns {string}
 */
const pluralizeSimple = (label: string, count: number) =>
    count === 1 ? label : `${label}s`;

export default pluralizeSimple;
