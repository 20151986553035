import Button from '@amzn/awsui-components-react/polaris/button';
import Link from '@amzn/awsui-components-react/polaris/link';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import {
    Constants,
    LogLevel,
    SinkType,
    useExponentialInterval,
    useLogger,
} from '@amzn/event-engine-js-utils';
import {
    ContentData,
    ContentPublishState,
} from '@amzn/event-engine-sdk/clients/eventenginecontentcatalogservice';
import { AWSError } from 'aws-sdk/lib/error';
import { useIsMounted, useLazyRequest } from 'hooks';
import { getBestAvailableEventPortalWorkshopUrl } from 'pages/Workshops/components/WorkshopUrls';
import React, { useCallback, useEffect, useState } from 'react';
import { EEContentCatalog } from 'services';

interface ViewPublishedBuildBtnProps {
    content?: ContentData;
    onComplete?: () => void;
}

interface ViewWorkshopButtonProps {
    isLoading: boolean;
    content?: ContentData;
}

const publishingTerminalStates: ContentPublishState[] = ['published', 'failed'];
/**
 * With the exponential backoff, we will wait a total of 18.21 minutes before we stop
 * polling.
 *
 * Attempt #1 1 second
 * Attempt #2 4 seconds
 * Attempt #3 13 seconds
 * Attempt #4 40 seconds
 * Attempt #5 2 minutes
 * Attempt #6 6 minutes
 * Attempt #7 18 minutes
 */
export const maxRetries = 7;

/**
 * Checks if build was published
 * @param {ContentData} content
 * @returns {boolean} true iFF build is published
 */
const isBuildPublished = (content?: ContentData) =>
    // The 'None' value will probably be updated to just be aan empty value
    content && content.publishedBuildId && content.publishedBuildId !== 'None';

const EmptyProvider = ({ children }: { children: React.ReactNode }) => {
    return <>{children} </>;
};

const ViewWorkshopButton = ({
    content,
    isLoading,
}: ViewWorkshopButtonProps) => {
    const publishedBuildUrl =
        getBestAvailableEventPortalWorkshopUrl(content) || undefined;

    return (
        <Button
            disabled={!publishedBuildUrl}
            href={publishedBuildUrl}
            iconAlign={publishedBuildUrl ? 'right' : 'left'}
            iconName={publishedBuildUrl ? 'external' : 'status-info'}
            loading={isLoading}
            key="preview"
            target="_blank">
            View workshop
        </Button>
    );
};

const ViewPublishedBuildBtn = ({
    content,
    onComplete,
}: ViewPublishedBuildBtnProps) => {
    const [
        viewPublishedBuildBtnLogger,
    ] = useLogger('viewPublishedBuildBtnLogger', LogLevel.ERROR, [
        SinkType.CloudWatchLogSink,
    ]);
    const { isMounted } = useIsMounted();
    const [publishedContent, setPublishedContent] = useState<{
        isPublished: boolean;
        isPublishedErr: boolean;
        isValidated: boolean;
    }>({
        /**
         * Boolean that determines that the workshop has been successfully
         * published
         */
        isPublished: false,
        /**
         * Boolean that determines that the workshop has timed out (we attempt 5 polls),
         * or the workshop failed to publish
         */
        isPublishedErr: false,
        /**
         * Boolean that indicates that we have reached a terminal state. Either the poll
         * has expired, or the workshop has published successfully/failure
         */
        isValidated: false,
    });

    const [
        getContent,
        { data: getContentData, isError: getContentError },
    ] = useLazyRequest<typeof EEContentCatalog.getContent, AWSError>(
        EEContentCatalog.getContent,
        { context: EEContentCatalog }
    );

    useEffect(() => {
        if (getContentError) {
            viewPublishedBuildBtnLogger.error(
                'received an error when getting content: ',
                getContentError
            );
        }
    }, [getContentError]);

    /**
     * Each piece of content contains a publishState property. If it is published,
     * update the state of the React component.
     */
    const validatePublishedContent = useCallback((content?: ContentData) => {
        if (!content) {
            return false;
        }

        if (
            isMounted.current &&
            publishingTerminalStates.some(
                (state) => state === content?.publishState
            )
        ) {
            setPublishedContent({
                isPublished:
                    content?.publishState?.toLowerCase() === 'published',
                isPublishedErr:
                    content?.publishState?.toLowerCase() === 'failed',
                isValidated: true,
            });
            stopExponentialInterval();
            return true;
        }

        return false;
    }, []);

    useEffect(() => {
        if (publishedContent.isPublished) {
            onComplete?.();
        }
    }, [publishedContent]);

    const {
        startExponentialInterval,
        stopExponentialInterval,
    } = useExponentialInterval(
        async ({ runCount = 0 }) => {
            if (isMounted.current && runCount >= maxRetries) {
                // if maxRetries intervals have elapsed and the build has not yet published
                // show the user an error
                stopExponentialInterval();

                setPublishedContent({
                    isPublished: false,
                    isPublishedErr: true,
                    isValidated: true,
                });
                return;
            }

            const updatedContent = await getContent(
                content!.contentId as string
            );

            isMounted.current &&
                validatePublishedContent(updatedContent?.content);

            return () => {
                stopExponentialInterval();
            };
        },
        {
            ...Constants.retryBackoff,
            maxRetries,
        },
        false
    );

    useEffect(() => {
        if (!content?.contentId) {
            return;
        }

        // if there was a new contentId passed in, poll until the workshop
        // has been published
        if (!validatePublishedContent(content) && isMounted.current) {
            setPublishedContent({
                isPublished: false,
                isPublishedErr: false,
                isValidated: false,
            });
            stopExponentialInterval();
            startExponentialInterval();
        }
    }, [content?.contentId, content?.publishedBuildId]);

    // If build is not published, don't render the button
    if (!isBuildPublished(getContentData?.content || content)) {
        return null;
    }

    // If there was an error and we were unable to retrieve the workshop build within the allotted
    // retry count, render a popover that shows an error to the user
    const Provider = publishedContent.isPublishedErr ? Popover : EmptyProvider;

    return (
        <Provider
            dismissAriaLabel={'Close'}
            content={
                <>
                    Could not load workshop URL. Please wait a minute and try
                    refreshing the page. If the problem persists, please{' '}
                    <Link href={Constants.ExternalLinks.reportBug} external>
                        report the issue
                    </Link>
                    .
                </>
            }
            size={'small'}
            position={'left'}
            triggerType={'custom'}>
            <ViewWorkshopButton
                content={getContentData?.content || content}
                isLoading={!publishedContent.isValidated}
            />
        </Provider>
    );
};

export default ViewPublishedBuildBtn;
