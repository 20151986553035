import { ReactNode } from 'react';

import { Step } from '../../components/CreateEventForm.validation';

export interface ReviewSections {
    id: Step;
    title: ReactNode;
    content: ReactNode;
    testId: string;
    errorText?: ReactNode;
    stepIndex?: number;
}

export enum TestID {
    PAGE = 'review-and-create',
    SECTION_WORKSHOP = 'section-workshop',
    SECTION_EVENT_TYPE = 'section-event-type',
    SECTION_EVENT_DETAILS = 'section-event-details',
    SECTION_PARTICIPANT_ALLOWLIST = 'section-participant-allowlist',
    CONTAINER_WORKSHOP = 'container-workshop',
    CONTAINER_EVENT_TYPE = 'container-event-type',
    CONTAINER_EVENT_DETAILS = 'container-event-details',
    CONTAINER_EVENT_CONFIG = 'container-event-config',
    CONTAINER_FACILITATORS = 'container-facilitators',
    CONTAINER_PARTICIPANT_ALLOWLIST = 'container-participant-allowlist',
}
