export enum ItemID {
    GROUP_QUOTAS = 'group_quotas',
    QUOTA_CURRENT_AWS_ACCOUNTS = 'quota_current_aws_accounts',
    QUOTA_NEXT_AWS_ACCOUNTS = 'quota_next_aws_accounts',
    QUOTA_WORKSHOPS = 'quota_workshops',
    MY_QUOTAS = 'my_quotas',
    SUBMIT_FEATURE_REQUEST = 'submit_feature_request',
    SUBMIT_SUPPORT_REQUEST = 'submit_support_request',
    SUBMIT_FEEDBACK = 'submit_feedback',
    REPORT_BUG = 'report_bug',
    TRANSFER_QUOTA = 'transfer_quota',
}
