import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { ReactNode } from 'react';

export enum BooleanValue {
    YES = 'yes',
    NO = 'no',
}

export interface BooleanStatusProps {
    value?: boolean;
    typeOverride?: StatusIndicatorProps.Type;
    popoverContent?: ReactNode;
}

export interface StatusConfig {
    type: StatusIndicatorProps.Type;
    label?: string;
}

export type GetStatusConfig = (value?: boolean) => StatusConfig;
