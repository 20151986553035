import { SelectProps } from '@amzn/awsui-components-react/polaris/select';

import { ACCOUNT_SOURCE_OPTIONS } from './constants';

/**
 * Get account source options based on contentspec
 * configuration
 *
 * @param {string[]} configuredAccountSources
 * @returns {SelectProps.Option[]}
 */
const getAccountSourceOptions = (configuredAccountSources?: string[]) => {
    if (!configuredAccountSources) {
        return [];
    }

    return ACCOUNT_SOURCE_OPTIONS.filter(({ value }) =>
        configuredAccountSources.includes(value!)
    );
};

export default getAccountSourceOptions;
