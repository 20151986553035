import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import React from 'react';

import { STATUS_MAP, UNKNOWN_STATE } from './EventTeamState.constants';
import { EventTeamStateProps } from './EventTeamState.interface';

const EventTeamState = ({ state }: EventTeamStateProps) => {
    const config = STATUS_MAP[state] || UNKNOWN_STATE;

    return (
        <StatusIndicator type={config.type}>
            {config.label || state}
        </StatusIndicator>
    );
};

export * from './EventTeamState.interface';
export default EventTeamState;
