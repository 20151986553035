import { CopyButton } from '@amzn/event-engine-js-utils';
import React from 'react';
import AppSettings from 'services/app-settings';

import { i18nStrings } from '../../../../../constants';

interface CopyJoinEventLinkProps {
    code?: string;
}

const CopyJoinEventLinkBtn = ({ code }: CopyJoinEventLinkProps) => {
    const handleDeferredContent = async () => {
        const settings = await AppSettings();
        return `${settings.eventPortalOrigin}/join?access-code=${code}`;
    };

    return (
        <CopyButton disabled={!code} deferredContent={handleDeferredContent}>
            {i18nStrings.copyJoinLink}
        </CopyButton>
    );
};

export default CopyJoinEventLinkBtn;
