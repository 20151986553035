import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import React, { useEffect, useState } from 'react';
import AppSettings from 'services/app-settings';
import { DOCUMENTATION_PAGE_ANCHORS, DOCUMENTATION_PATHS } from 'utils/config';

const PreviewTools = () => {
    const [docsBaseUrl, setDocsBaseUrl] = useState('');

    useEffect(() => {
        (async () => {
            const settings = await AppSettings();

            settings.documentation && setDocsBaseUrl(settings.documentation);
        })();
    }, []);

    return (
        <HelpPanel header={<h2>Build previews</h2>}>
            <p>
                Preview and review content builds on Workshop Studio before
                publishing.
            </p>
            <h3>Asset URL differences</h3>
            <p>
                In this preview environment, you will notice that links to
                assets in the workshop repository or Amazon S3 are different
                compared to what they look like in publicly published builds. In
                local development environments, asset URLs are shorter absolute
                URLs whereas in build previews on Workshop Studio, they are much
                longer since they include additional signed query parameters to
                protect content not ready for public access. For more details
                about these signed URLs, please see our documentation{' '}
                <a
                    href={`${docsBaseUrl}/${DOCUMENTATION_PATHS.DIRECTIVE_ASSET_URL}#${DOCUMENTATION_PAGE_ANCHORS.ENVIRONMENT_DIFFERENCES}`}
                    target="_blank"
                    rel="noreferrer"
                    data-testid="asset-url-differences-link">
                    here
                </a>
                .
            </p>
        </HelpPanel>
    );
};

export default PreviewTools;
