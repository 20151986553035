import {
    EVENT_ACCOUNT_SOURCE,
    PARTICIPANT_STATE,
} from '__generated__/@amzn/event-engine-events-sdk/enums';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown, {
    ButtonDropdownProps,
} from '@amzn/awsui-components-react/polaris/button-dropdown';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Select, {
    SelectProps,
} from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
    countText,
    EmptyMessage,
    EventMatrix,
    getFilteringOptions,
    PropertyFilterConstants,
    StoragePartition,
    StoragePartitionKeys,
    useLazyRequest,
} from '@amzn/event-engine-js-utils';
import styles from 'assets/styles/modules/collection-select-filter.module.scss';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import useTeamAccountAccess from 'pages/Events/EventDetails/hooks/use-team-account-access';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import EEEvents from 'services/ee-events';
import ErrorMessage from 'services/error-message';

import { i18nStrings, tableConfig } from '../../../../../constants';
import BanParticipantModal from '../BanParticipantModal';
import UnbanParticipantModal from '../UnbanParticipantModal';
import {
    columnDefinitions,
    defaultParticipantStateFilterOption,
    defaultQuery,
    defaultSortPreferences,
    filteringProperties,
    participantStateFilterOptions,
    participantStateFilterStorageKey,
} from './config';
import {
    ActionID,
    EventParticipantsTableProps,
    ParticipantDataTableItem,
    TestID,
} from './types';
import processData from './utils/process-data';

const EventParticipantsTable = ({
    event,
    onPreferenceChange,
    participants: data,
    participantStateFilterOption,
    preferences,
    error,
    loading,
    refetch,
    storageScope = 'eventParticipantsTable',
}: EventParticipantsTableProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const [
        getTeam,
        {
            data: getTeamResponse,
            isLoading: getTeamRequestLoading,
            isError: getTeamErrorResponse,
        },
    ] = useLazyRequest<typeof EEEvents.getTeam, AWSError>(EEEvents.getTeam, {});
    const {
        isDisabled: isTeamAccountAccessDisabled,
        loading: isTeamAccountAccessModelOpening,
        showModal: showTeamAccountAccessModal,
        modal: teamAccountAccessModal,
    } = useTeamAccountAccess();
    const { current: collectionSelectSavedValue } = useRef(
        StoragePartition.getItem(
            StoragePartitionKeys.userPreferences,
            participantStateFilterStorageKey
        )
    );
    const [activeAction, setActiveAction] = useState<ActionID>();
    const [sorting, setSorting] = useState<
        TableProps.SortingState<ParticipantDataTableItem>
    >(
        StoragePartition.getItem(
            StoragePartitionKeys.tableSortColumns,
            storageScope
        ) || defaultSortPreferences
    );
    const [
        participantStateSelectedFilterOption,
        setParticipantStateSelectedFilterOption,
    ] = useState<SelectProps.Option>(
        participantStateFilterOptions.find(
            (option) => option.value === collectionSelectSavedValue
        ) || defaultParticipantStateFilterOption
    );
    const participants = useMemo(
        () =>
            processData(data, {
                selectedFilters: {
                    participantState: participantStateSelectedFilterOption,
                },
            }),
        [data, participantStateSelectedFilterOption]
    );
    const noMatchMessage = (
        <EmptyMessage
            title={i18nStrings.table.noMatches}
            description={i18nStrings.table.noMatchesDescription}
            actions={
                <Button
                    onClick={() => {
                        actions.setPropertyFiltering(defaultQuery);
                        setParticipantStateSelectedFilterOption(
                            defaultParticipantStateFilterOption
                        );
                    }}>
                    {i18nStrings.table.clearAllFilters}
                </Button>
            }
        />
    );
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(participants, {
        propertyFiltering: {
            filteringProperties,
            defaultQuery,
            empty: error ? (
                <EmptyMessage
                    title={i18nStrings.events.participants.table.errorTitle}
                    description={ErrorMessage.getMessage(error)}
                />
            ) : data?.length ? (
                noMatchMessage
            ) : (
                <EmptyMessage
                    title={i18nStrings.events.participants.table.emptyTitle}
                    description={
                        i18nStrings.events.participants.table.emptyDescription
                    }
                />
            ),
            noMatch: noMatchMessage,
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: sorting,
        },
        selection: {
            keepSelection: true,
            trackBy: 'participantId',
        },
    });
    const selectedItems = collectionProps.selectedItems;
    const handleParticipantStateFilterChange = useCallback<
        NonNullable<SelectProps['onChange']>
    >(({ detail: { selectedOption } }) => {
        setParticipantStateSelectedFilterOption(selectedOption);
    }, []);
    const rowActions = useMemo(() => {
        const actions: ButtonDropdownProps.Item[] = [];

        if (!event) {
            return actions;
        }

        const selectedParticipantId = selectedItems?.[0]?.participantId;
        const selectedItem = items.find(
            ({ participantId }) => participantId === selectedParticipantId
        );
        const disabled = !(
            EventMatrix.isEventActive({
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            }) && selectedItem
        );

        if (event.accountSource === EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO) {
            actions.push({
                id: ActionID.ACCESS_AWS_ACCOUNT,
                text: i18nStrings.events.participants.actions.accessAWSAccount,
                disabled,
            });
        }

        if (selectedItem?.state === PARTICIPANT_STATE.BANNED) {
            actions.push({
                id: ActionID.UNBAN,
                text: i18nStrings.events.participants.actions.unban,
                disabled,
            });
        } else {
            actions.push({
                id: ActionID.BAN,
                text: i18nStrings.events.participants.actions.ban,
                disabled,
            });
        }

        return actions;
    }, [selectedItems, items, event]);
    const counterText = useMemo(() => {
        const totalCount = participants.length;
        const selectionCount = selectedItems?.length;

        return `(${selectionCount ? `${selectionCount}/` : ''}${totalCount})`;
    }, [participants, selectedItems]);
    const handleActionClick = useCallback<
        (
            arg: Parameters<NonNullable<ButtonDropdownProps['onItemClick']>>[0]
        ) => void
    >(
        ({ detail: { id } }) => {
            switch (id) {
                case ActionID.BAN:
                    setActiveAction(ActionID.BAN);
                    break;
                case ActionID.UNBAN:
                    setActiveAction(ActionID.UNBAN);
                    break;
                case ActionID.ACCESS_AWS_ACCOUNT: {
                    const selectedItem = selectedItems?.[0];
                    event &&
                        selectedItem?.teamId &&
                        getTeam({
                            eventId: event.eventId,
                            teamId: selectedItem.teamId,
                        });
                    break;
                }
            }
        },
        [selectedItems]
    );
    const clearActiveAction = useCallback(() => {
        setActiveAction(undefined);
    }, []);
    const handleActionUpdateSuccess = useCallback(() => {
        refetch && refetch(true);
    }, [refetch]);

    useEffect(() => {
        participantStateFilterOption &&
            setParticipantStateSelectedFilterOption(
                participantStateFilterOption
            );
    }, [participantStateFilterOption]);

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.tableSortColumns,
            sorting,
            storageScope
        );
    }, [sorting]);

    useEffect(() => {
        StoragePartition.setItem(
            StoragePartitionKeys.userPreferences,
            participantStateSelectedFilterOption.value,
            participantStateFilterStorageKey
        );
    }, [participantStateSelectedFilterOption]);

    useEffect(() => {
        if (!(getTeamResponse?.team && event)) {
            return;
        }

        if (isTeamAccountAccessDisabled(event, getTeamResponse.team)) {
            showNotification(
                i18nStrings.events.participants.notifications
                    .teamAccountAccessError.content.invalidState,
                {
                    header:
                        i18nStrings.events.participants.notifications
                            .teamAccountAccessError.header,
                    type: 'error',
                }
            );
            return;
        }

        showTeamAccountAccessModal(event, getTeamResponse.team);
    }, [getTeamResponse]);

    useEffect(() => {
        getTeamErrorResponse &&
            showNotification(
                i18nStrings.events.participants.notifications
                    .teamAccountAccessError.content.getTeamError,
                {
                    header:
                        i18nStrings.events.participants.notifications
                            .teamAccountAccessError.header,
                    type: 'error',
                }
            );
    }, [getTeamErrorResponse]);

    return (
        <>
            <Table
                {...collectionProps}
                variant="stacked"
                header={
                    <Header
                        counter={counterText}
                        description={
                            i18nStrings.events.participants.table.description
                        }
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {refetch ? (
                                    <Button
                                        data-testid={TestID.BUTTON_REFRESH}
                                        disabled={loading}
                                        loading={loading}
                                        onClick={() => refetch()}
                                        variant="normal"
                                        iconName="refresh"
                                        ariaLabel={i18nStrings.refreshData}
                                    />
                                ) : null}
                                {rowActions.length ? (
                                    <ButtonDropdown
                                        data-testid={TestID.BUTTON_ACTIONS}
                                        items={rowActions}
                                        loading={
                                            getTeamRequestLoading ||
                                            isTeamAccountAccessModelOpening
                                        }
                                        onItemClick={handleActionClick}>
                                        {i18nStrings.actions}
                                    </ButtonDropdown>
                                ) : null}
                            </SpaceBetween>
                        }>
                        {i18nStrings.events.participants.table.title}
                    </Header>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                items={items}
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={tableConfig.paginationAriaLabels}
                    />
                }
                filter={
                    <div className={styles.collectionSelectFilter}>
                        <PropertyFilter
                            {...propertyFilterProps}
                            className={styles.filter}
                            i18nStrings={PropertyFilterConstants.i18nStrings}
                            filteringOptions={getFilteringOptions(
                                participants,
                                filteringProperties
                            )}
                        />
                        <Select
                            data-testid={
                                TestID.COLLECTION_SELECT_PARTICIPANT_STATE
                            }
                            className={styles.select}
                            selectedOption={
                                participantStateSelectedFilterOption
                            }
                            options={participantStateFilterOptions}
                            onChange={handleParticipantStateFilterChange}
                        />
                        {propertyFilterProps.query.tokens.length ||
                        participantStateSelectedFilterOption.value !==
                            defaultParticipantStateFilterOption.value ? (
                            <span
                                data-testid={TestID.FILTER_COUNT_TEXT}
                                className={styles.countText}>
                                {countText(
                                    filteredItemsCount || 0,
                                    i18nStrings.item
                                )}
                            </span>
                        ) : null}
                    </div>
                }
                preferences={
                    <CollectionPreferences
                        {...tableConfig.collectionPreferencesI18nConstants}
                        preferences={preferences}
                        onConfirm={({ detail }) => onPreferenceChange(detail)}
                        visibleContentPreference={{
                            title: i18nStrings.table.selectVisibleColumns,
                            options: [
                                {
                                    label: i18nStrings.table.properties,
                                    options: columnDefinitions.map(
                                        (column) => ({
                                            id: column.id as string,
                                            label: column.header as string,
                                            editable: column.editable,
                                        })
                                    ),
                                },
                            ],
                        }}
                        pageSizePreference={tableConfig.pageSizePreference}
                        wrapLinesPreference={tableConfig.wrapLinesPreference}
                    />
                }
                loadingText={tableConfig.loadingText}
                loading={loading && !participants.length}
                onSortingChange={(e) => {
                    setSorting(e.detail);
                    collectionProps.onSortingChange &&
                        collectionProps.onSortingChange(e);
                }}
                wrapLines={preferences.wrapLines}
                selectionType="single"
                resizableColumns
            />
            {selectedItems && (
                <BanParticipantModal
                    isVisible={activeAction === ActionID.BAN}
                    onDismiss={clearActiveAction}
                    onUpdateSuccess={handleActionUpdateSuccess}
                    participant={selectedItems[0]}
                />
            )}
            {selectedItems && (
                <UnbanParticipantModal
                    isVisible={activeAction === ActionID.UNBAN}
                    onDismiss={clearActiveAction}
                    onUpdateSuccess={handleActionUpdateSuccess}
                    participant={selectedItems[0]}
                />
            )}
            {teamAccountAccessModal}
        </>
    );
};

export default EventParticipantsTable;
