import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    DatePickerField,
    EventMatrix,
    formatString,
    FormikField,
    SelectField,
    TimeInputField,
    useLazyRequest,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import {
    addMinutes,
    differenceInMinutes,
    formatDuration,
    hoursToMinutes,
    isValid,
} from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { useModal } from 'hooks';
import {
    PERIOD_SELECT_OPTIONS,
    TIMEZONES,
} from 'pages/Events/CreateEvent/utils/constants';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';
import { MAX_EVENT_DURATION } from 'utils/config';
import {
    getUTCOffsetLabel,
    utcToZonedTime,
    zonedTimeToUtc,
} from 'utils/date-fns';

import { i18nStrings } from '../../../../../constants';
import { TimeDistanceAlert } from '../../../components/TimeDistanceAlert';
import { FormSchema, FormSchemaType } from './schema';
import {
    FieldName,
    FormContext,
    NotificationID,
    UpdateScheduleModalProps,
} from './types';
import css from './UpdateScheduleModal.module.scss';

const UpdateScheduleModal: React.FC<UpdateScheduleModalProps> = ({
    event,
    onModalClosed,
    isVisible,
    refetchGetEvent,
}: UpdateScheduleModalProps) => {
    const { showNotification } = useContext(NotificationsContext);
    const isProvisionedStarted = useMemo(
        () =>
            EventMatrix.isProvisionStarted({
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            }),
        [event]
    );
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [
        updateEventSchedule,
        {
            isLoading: updateEventScheduleLoading,
            isError: updateEventScheduleError,
            data: updateEventScheduleData,
        },
    ] = useLazyRequest<typeof EEEvents.updateEventSchedule, AWSError>(
        EEEvents.updateEventSchedule,
        {}
    );
    const onSubmit = useCallback(
        async (values: FormSchemaType) => {
            const startTime = zonedTimeToUtc(
                values[FieldName.START_DATE],
                values[FieldName.START_TIME],
                values[FieldName.START_PERIOD],
                { timezone: values[FieldName.TIMEZONE] }
            )!;
            const endTime = zonedTimeToUtc(
                values[FieldName.END_DATE],
                values[FieldName.END_TIME],
                values[FieldName.END_PERIOD],
                { timezone: values[FieldName.TIMEZONE] }
            )!;
            const request: EventEngineEventsService.UpdateEventScheduleRequest = {
                eventId: event.eventId,
                scheduledDuration: differenceInMinutes(endTime, startTime),
            };

            if (!isProvisionedStarted) {
                request.scheduledStartTime = zonedTimeToUtc(
                    values[FieldName.START_DATE],
                    values[FieldName.START_TIME],
                    values[FieldName.START_PERIOD],
                    { timezone: values[FieldName.TIMEZONE] }
                );
                request.timeZone = values[FieldName.TIMEZONE];
            }

            await updateEventSchedule(request);
        },
        [isProvisionedStarted]
    );
    const {
        date: startDate,
        time: startTime,
        period: startPeriod,
    } = utcToZonedTime(event.scheduledStartTime!, {
        timezone: event.timeZone,
    });
    const {
        date: endDate,
        time: endTime,
        period: endPeriod,
    } = utcToZonedTime(
        addMinutes(event.scheduledStartTime!, event.scheduledDuration),
        { timezone: event.timeZone }
    );
    const formik = useFormik({
        validate: async (values: FormSchemaType) => {
            try {
                await validateYupSchema<FormSchemaType>(
                    values,
                    FormSchema,
                    true,
                    {
                        isProvisionedStarted,
                    } as FormContext
                );
            } catch (err) {
                return yupToFormErrors<FormSchemaType>(err);
            }

            return {};
        },
        validateOnBlur: false,
        validateOnChange,
        initialValues: {
            timezone:
                TIMEZONES.find((timezone) => {
                    return timezone.value === event.timeZone;
                })?.value || '',
            [FieldName.START_DATE]: startDate,
            [FieldName.START_TIME]: startTime,
            [FieldName.START_PERIOD]:
                PERIOD_SELECT_OPTIONS.find((period) => {
                    return period.label === startPeriod;
                })?.value || '',
            [FieldName.END_DATE]: endDate,
            [FieldName.END_TIME]: endTime,
            [FieldName.END_PERIOD]:
                PERIOD_SELECT_OPTIONS.find((period) => {
                    return period.label === endPeriod;
                })?.value || '',
        },
        onSubmit,
    });
    const startDateUTC = useMemo(
        () =>
            zonedTimeToUtc(
                formik.values[FieldName.START_DATE],
                formik.values[FieldName.START_TIME],
                formik.values[FieldName.START_PERIOD]
            ),
        [
            formik.values[FieldName.START_DATE],
            formik.values[FieldName.START_TIME],
            formik.values[FieldName.START_PERIOD],
        ]
    );
    const endDateUTC = useMemo(
        () =>
            zonedTimeToUtc(
                formik.values[FieldName.END_DATE],
                formik.values[FieldName.END_TIME],
                formik.values[FieldName.END_PERIOD]
            ),
        [
            formik.values[FieldName.END_DATE],
            formik.values[FieldName.END_TIME],
            formik.values[FieldName.END_PERIOD],
        ]
    );
    const isDurationValid = useMemo(() => {
        if (!(isValid(startDateUTC) && isValid(endDateUTC))) {
            // Duration is technically invalid, but user should be able to
            // attempt to submit the form to show validation errors
            return true;
        }

        return (
            differenceInMinutes(endDateUTC!, startDateUTC!) <=
            hoursToMinutes(MAX_EVENT_DURATION)
        );
    }, [startDateUTC, endDateUTC]);
    const [timezoneOptions, setTimezoneOptions] = useState<typeof TIMEZONES>(
        []
    );
    const closeModal = () => {
        hideModal();
        onModalClosed();
        formik.resetForm();
    };
    const onPrimaryActionClick = useCallback(() => {
        formik.handleSubmit();
    }, []);
    const onSecondaryActionClick = useCallback(() => {
        closeModal();
    }, []);
    const { modalComponent, hideModal } = useModal({
        isVisible,
        onDismiss: onModalClosed,
        modalHeader: i18nStrings.events.updateEventScheduleModal.header,
        actions: {
            primary: {
                text: i18nStrings.events.updateEventScheduleModal.actionUpdate,
                onClick: onPrimaryActionClick,
                disabled: !(isDurationValid && formik.dirty && formik.isValid),
                loading: updateEventScheduleLoading,
            },
            tertiary: {
                text: i18nStrings.cancel,
                onClick: onSecondaryActionClick,
            },
        },
        content: (
            <FormikProvider value={formik}>
                <Form
                    errorText={
                        updateEventScheduleError &&
                        ErrorMessage.getMessage(
                            updateEventScheduleError,
                            MessageTypes.updateEventSchedule
                        )
                    }>
                    <SpaceBetween direction="vertical" size="l">
                        <FormikField<FormSchemaType>
                            name={FieldName.TIMEZONE}
                            dataTestId={FieldName.TIMEZONE}
                            formFieldProps={{
                                stretch: true,
                                label:
                                    i18nStrings.events.create.fields.schedule
                                        .timezone.label,
                                description:
                                    i18nStrings.events.create.fields.schedule
                                        .timezone.description,
                                children: (
                                    <SelectField<FormSchemaType>
                                        name={FieldName.TIMEZONE}
                                        placeholder={
                                            i18nStrings.events.create.fields
                                                .schedule.timezone.placeholder
                                        }
                                        selectProps={{
                                            options: timezoneOptions,
                                            disabled: isProvisionedStarted,
                                            filteringType: 'auto',
                                        }}
                                    />
                                ),
                            }}
                        />
                        <FormikField<FormSchemaType>
                            name={FieldName.START_DATE}
                            dataTestId={FieldName.START_DATE}
                            formFieldProps={{
                                label:
                                    i18nStrings.events.create.fields.schedule
                                        .startDate.label,
                                description:
                                    i18nStrings.events.create.fields.schedule
                                        .startDate.description,
                                stretch: true,
                                children: (
                                    <div className={css.fieldGroup}>
                                        <DatePickerField<FormSchemaType>
                                            name={FieldName.START_DATE}
                                            placeholder={
                                                i18nStrings.events.create.fields
                                                    .schedule.date.placeholder
                                            }
                                            datePickerProps={{
                                                readOnly: isProvisionedStarted,
                                                className: css.unsetMaxWidth,
                                                todayAriaLabel:
                                                    i18nStrings.datePicker
                                                        .today,
                                                nextMonthAriaLabel:
                                                    i18nStrings.datePicker.next,
                                                previousMonthAriaLabel:
                                                    i18nStrings.datePicker
                                                        .pervious,
                                            }}
                                        />
                                        <div
                                            className={css.fieldGroup}
                                            data-testid={FieldName.START_TIME}>
                                            <TimeInputField<FormSchemaType>
                                                name={FieldName.START_TIME}
                                                placeholder={
                                                    i18nStrings.events.create
                                                        .fields.schedule.time
                                                        .placeholder
                                                }
                                                timeInputProps={{
                                                    readOnly: isProvisionedStarted,
                                                    format: 'hh:mm',
                                                    use24Hour: false,
                                                }}
                                            />
                                            <div
                                                className={css.noStretch}
                                                data-testid={
                                                    FieldName.START_PERIOD
                                                }>
                                                <SelectField<FormSchemaType>
                                                    name={
                                                        FieldName.START_PERIOD
                                                    }
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .schedule.period
                                                            .placeholder
                                                    }
                                                    selectProps={{
                                                        disabled: isProvisionedStarted,
                                                        options: PERIOD_SELECT_OPTIONS,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ),
                            }}
                        />
                        <FormikField<FormSchemaType>
                            name={FieldName.END_DATE}
                            dataTestId={FieldName.END_DATE}
                            formFieldProps={{
                                label:
                                    i18nStrings.events.create.fields.schedule
                                        .endDate.label,
                                description: formatString(
                                    i18nStrings.events.create.fields.schedule
                                        .endDate.description,
                                    formatDuration({
                                        hours: MAX_EVENT_DURATION,
                                    })
                                ),
                                stretch: false,
                                children: (
                                    <div className={css.fieldGroup}>
                                        <DatePickerField<FormSchemaType>
                                            name={FieldName.END_DATE}
                                            placeholder={
                                                i18nStrings.events.create.fields
                                                    .schedule.date.placeholder
                                            }
                                            datePickerProps={{
                                                className: css.unsetMaxWidth,
                                                todayAriaLabel:
                                                    i18nStrings.datePicker
                                                        .today,
                                                nextMonthAriaLabel:
                                                    i18nStrings.datePicker.next,
                                                previousMonthAriaLabel:
                                                    i18nStrings.datePicker
                                                        .pervious,
                                            }}
                                        />
                                        <div
                                            className={css.fieldGroup}
                                            data-testid={FieldName.END_TIME}>
                                            <TimeInputField<FormSchemaType>
                                                name={FieldName.END_TIME}
                                                placeholder={
                                                    i18nStrings.events.create
                                                        .fields.schedule.time
                                                        .placeholder
                                                }
                                                timeInputProps={{
                                                    format: 'hh:mm',
                                                    use24Hour: false,
                                                }}
                                            />
                                            <div
                                                className={css.noStretch}
                                                data-testid={
                                                    FieldName.END_PERIOD
                                                }>
                                                <SelectField<FormSchemaType>
                                                    name={FieldName.END_PERIOD}
                                                    placeholder={
                                                        i18nStrings.events
                                                            .create.fields
                                                            .schedule.period
                                                            .placeholder
                                                    }
                                                    selectProps={{
                                                        options: PERIOD_SELECT_OPTIONS,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ),
                            }}
                        />
                        {!updateEventScheduleError && (
                            <TimeDistanceAlert
                                start={startDateUTC}
                                end={endDateUTC}
                            />
                        )}
                    </SpaceBetween>
                </Form>
            </FormikProvider>
        ),
    });

    useEffect(() => {
        setTimezoneOptions(
            TIMEZONES.map(({ label, value }) => ({
                label: `(${getUTCOffsetLabel(value as string, {
                    date: formik.values[FieldName.START_DATE],
                    time: formik.values[FieldName.START_TIME],
                    period: formik.values[FieldName.START_PERIOD],
                })}) ${label}`,
                value,
            }))
        );
    }, [
        formik.values[FieldName.START_DATE],
        formik.values[FieldName.START_TIME],
        formik.values[FieldName.START_PERIOD],
    ]);

    useEffect(() => {
        if (updateEventScheduleData) {
            showNotification(
                i18nStrings.events.updateEventScheduleModal.notifications
                    .success,
                {
                    id: NotificationID.UPDATE_RESPONSE,
                    type: 'success',
                }
            );
            refetchGetEvent();
            closeModal();
        }
    }, [updateEventScheduleData]);

    useEffect(() => {
        if (formik.submitCount === 1) {
            setValidateOnChange(true);
        }
    }, [formik.submitCount]);

    return modalComponent;
};

export default UpdateScheduleModal;
