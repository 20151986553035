import { EVENT_ACCOUNT_SOURCE } from '__generated__/@amzn/event-engine-events-sdk/enums';

import { TeamDataTableItem } from '../EventTeamsTable/types';
import { ExportColumnConfig } from './types';

// TODO: Remove once teamCodeJoining is generally available
export const TEAM_KEYS: ExportColumnConfig['key'][] = [
    'teamCode',
    'joinEventUrl',
];

const EXPORT_COLUMNS: ExportColumnConfig[] = [
    {
        header: 'Team name',
        key: 'name',
    },
    {
        header: 'Team code',
        key: 'teamCode',
    },
    {
        header: 'Participant count',
        key: 'participantCount',
    },
    {
        header: 'Participant IDs',
        key: 'participantIds',
    },
    {
        header: 'State',
        key: 'displayState',
    },
    {
        header: 'Deployment duration',
        key: 'deploymentDuration',
    },
    {
        header: 'AWS account ID',
        key: 'accountId',
    },
    {
        header: 'Join event URL',
        key: 'joinEventUrl',
    },
    {
        header: 'Last joined',
        key: 'lastJoinedAt',
    },
    {
        header: 'Last account login',
        key: 'lastLoginAt',
    },
    {
        header: 'Last updated',
        key: 'lastUpdatedAt',
    },
];

const EXPORT_COLUMNS_MAP = EXPORT_COLUMNS.reduce(
    (acc, a) => ({ ...acc, [a.key]: a }),
    {} as Record<keyof TeamDataTableItem, ExportColumnConfig>
);

export const ACCOUNT_SOURCE_EXPORT_COLUMNS: Record<
    EVENT_ACCOUNT_SOURCE,
    ExportColumnConfig[]
> = {
    [EVENT_ACCOUNT_SOURCE.WORKSHOP_STUDIO]: [...EXPORT_COLUMNS],
    [EVENT_ACCOUNT_SOURCE.CUSTOMER_PROVIDED]: [
        EXPORT_COLUMNS_MAP.name,
        EXPORT_COLUMNS_MAP.teamCode,
        EXPORT_COLUMNS_MAP.participantCount,
        EXPORT_COLUMNS_MAP.participantIds,
        EXPORT_COLUMNS_MAP.displayState,
        EXPORT_COLUMNS_MAP.joinEventUrl,
        EXPORT_COLUMNS_MAP.lastJoinedAt,
        EXPORT_COLUMNS_MAP.lastUpdatedAt,
    ],
};
