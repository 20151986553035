import { format as dateFnsFormat, isValid } from 'date-fns';
import { utcToZonedTime as dateFnsUtcToZonedTime } from 'date-fns-tz';

import { DATE_FORMATS } from './constants';

interface UTCToZonedTimeProps {
    format: string;
    timezone: string;
}

class UTCToZonedTimeResult {
    date = '';
    time = '';
    period = '';

    constructor(data?: Partial<UTCToZonedTimeResult>) {
        Object.assign(this, data);
    }
}

/**
 * Wrapper around date-fns utcToZonedTime and format.
 * Returns segments of a formatted date string.
 * @param {Date} date
 * @param {UTCToZonedTimeProps|undefined} options
 * @returns {UTCToZonedTimeResult}
 */
const utcToZonedTime = (
    date: Date,
    options?: Partial<UTCToZonedTimeProps>
): UTCToZonedTimeResult => {
    if (!isValid(date)) {
        return new UTCToZonedTimeResult();
    }
    const eventStartTime = dateFnsUtcToZonedTime(date, options?.timezone || '');
    const formattedDate = dateFnsFormat(
        eventStartTime,
        options?.format || DATE_FORMATS.TWELVE_HOUR_STANDARD
    );
    const [readableDate, readableTime, readablePeriod] = formattedDate.split(
        /\s+/
    );
    return new UTCToZonedTimeResult({
        date: readableDate,
        time: readableTime,
        period: readablePeriod,
    });
};

export default utcToZonedTime;
