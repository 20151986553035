import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { EventEngineEventsService } from '@amzn/event-engine-events-sdk';
import {
    createYupSchema,
    EventMatrix,
    LogLevel,
    mergeSchemas,
    SinkType,
    useLazyRequest,
    useLogger,
} from '@amzn/event-engine-js-utils';
import { AWSError } from 'aws-sdk/lib/error';
import { NotificationsContext } from 'contexts/NotificationsProvider';
import { minutesToHours } from 'date-fns';
import {
    FormikProvider,
    useFormik,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { EEEvents, ErrorMessage, MessageTypes } from 'services';
import { utcToZonedTime } from 'utils/date-fns';
import * as Yup from 'yup';

import { routes } from '../../../components/AppRoutes/routes.constants';
import {
    EditEventFormSchema,
    MergedEditEventFormSchema,
    MergedFormSchema,
    Step,
} from '../CreateEvent/components/CreateEventForm.validation';
import {
    ParticipantAllowlistFieldName,
    ParticipantAllowlistFormatFormData,
} from '../CreateEvent/steps/ParticipantAllowlist';
import getRegionsFormData from '../CreateEvent/utils/get-regions-form-data';
import serializeFormData from '../CreateEvent/utils/serialize-form-data';
import CreateEventForm from './../CreateEvent/components/CreateEventForm';
import { PERIOD_SELECT_OPTIONS } from './../CreateEvent/utils/constants';
import { EnhancedContentTemplateAttribute } from './../CreateEvent/utils/create-event-attributes';
import { expandInitialAttributes } from './EditEvent.utils';

export const TEST_IDS = {
    SAVE_BUTTON: 'edit_event_save_button',
    CANCEL_BUTTON: 'edit_event_cancel_button',
};

export interface EditEventContainerProps {
    event: EventEngineEventsService.EventData;
    attributes: readonly EnhancedContentTemplateAttribute[];
}

const EditEventContainer = ({
    attributes = [],
    event,
}: EditEventContainerProps) => {
    const history = useHistory();
    const { showNotification } = useContext(NotificationsContext);

    const [editEventLogger] = useLogger('EditEventLogger', LogLevel.ERROR, [
        SinkType.CloudWatchLogSink,
    ]);

    const [eventMatrixLogger] = useLogger('EventMatrixLogger', LogLevel.ERROR, [
        SinkType.CloudWatchLogSink,
    ]);

    const eventMatrix = useRef(
        new EventMatrix(
            event.type,
            event.accountSource,
            {
                runtime: event.stateRuntime,
                infrastructure: event.stateInfrastructure,
            },
            {
                logger: eventMatrixLogger,
            }
        )
    );
    const [validateOnChange, setValidateOnChange] = React.useState(false);

    const [
        updateEventInformation,
        {
            isLoading: isUpdateEventInformationLoading,
            isError: updateEventInformationError,
            data: updateEventInformationData,
        },
    ] = useLazyRequest<typeof EEEvents.updateEventInformation, AWSError>(
        EEEvents.updateEventInformation,
        {}
    );

    const attributeSchema = useMemo(
        () => attributes?.reduce(createYupSchema, Yup.object({})),
        [attributes]
    );

    const validationSchema = useMemo(
        () =>
            attributeSchema
                ? mergeSchemas(
                      EditEventFormSchema,
                      Yup.object({
                          [Step.EVENT_DETAILS]: attributeSchema,
                      })
                  )
                : EditEventFormSchema,
        [attributeSchema]
    );

    const onSubmit = async (
        values: Pick<MergedEditEventFormSchema, Step.EVENT_DETAILS>
    ) => {
        const combinedFormSchema = {
            ...values,
            [Step.EVENT_TYPE]: {
                eventType: event.type,
                accountSource: event.accountSource,
            },
            [Step.PARTICIPANT_ALLOWLIST]: {
                [ParticipantAllowlistFieldName.ALLOW_ALL]:
                    event.allowlistAllowAll,
                [ParticipantAllowlistFieldName.EMAIL_PATTERNS]: ParticipantAllowlistFormatFormData.getEmailPatterns(
                    event.allowlist
                ),
            },
        };

        // TODO: Will want to eventually create a dedicated schema
        //  for update since most of the fields in the create schema
        //  are not needed.
        await updateEventInformation(
            serializeFormData(
                {
                    values: combinedFormSchema,
                    attributes,
                },
                event.eventId
            )
        );
    };

    const {
        date: startDate,
        time: fullTime,
        period: formattedPeriod,
        // Assume scheduledStartTime will always be a valid date.
        // See: https://code.amazon.com/packages/EEEventsModel/blobs/2364ac689b99677abcab653a3cfca1aac535188a/--/model/resources/event.smithy#L49
    } = utcToZonedTime(event.scheduledStartTime!, {
        timezone: event.timeZone,
    });

    const attributeValues: EnhancedContentTemplateAttribute = expandInitialAttributes(
        event.attributes,
        attributes
    );

    useEffect(() => {
        if (updateEventInformationError) {
            editEventLogger.error(
                `There was an error updating event, ${event.eventId}`,
                updateEventInformationError
            );
        }
    }, [updateEventInformationError]);

    useEffect(() => {
        if (updateEventInformationData) {
            showNotification('Event successfully updated!', {
                type: 'success',
            });
            navigateHome();
        }
    }, [updateEventInformationData]);

    const formik = useFormik({
        validate: async (
            values: Pick<MergedFormSchema, Step.EVENT_DETAILS>
        ) => {
            try {
                await validateYupSchema<
                    Pick<MergedFormSchema, Step.EVENT_DETAILS>
                >(values, validationSchema, true, {
                    editMatrix: eventMatrix.current,
                });
            } catch (err) {
                return yupToFormErrors(err);
            }

            return {};
        },
        validateOnBlur: false,
        validateOnChange: validateOnChange,
        initialValues: {
            [Step.EVENT_DETAILS]: {
                title: event.title,
                description: event.description,
                eventDate: startDate,
                eventTime: fullTime,
                period:
                    PERIOD_SELECT_OPTIONS.find((period) => {
                        return period.label === formattedPeriod;
                    })?.value || '',
                timezone: event.timeZone,
                duration: minutesToHours(event.scheduledDuration),
                forecastedAttendees: event.numberOfForecastedParticipants,
                teamSize: event.teamSize,
                initialQuotaContribution: undefined,
                availableQuota: undefined,
                facilitators: event.permissions.map((permission) => ({
                    key: permission.principal,
                    value: permission.roles[0],
                })),
                modality: event.modality,
                ...getRegionsFormData(
                    event.deploymentRegions,
                    event.accessibleRegions
                ),
                ...attributeValues,
            },
        },
        onSubmit,
    });

    useEffect(() => {
        if (formik.submitCount === 1) {
            setValidateOnChange(true);
        }
    }, [formik.submitCount]);

    const navigateHome = () => {
        history.push(`${routes.events}/${event.eventId}`);
    };

    const handleCancel = useCallback(() => {
        formik.resetForm();

        navigateHome();
    }, []);

    const onUpdateEvent = useCallback(() => {
        formik.handleSubmit();
    }, []);

    return (
        <FormikProvider value={formik}>
            <form
                onSubmit={(e) => e.preventDefault()}
                autoComplete="chrome-off"
                noValidate>
                <CreateEventForm
                    editMatrix={eventMatrix.current}
                    attributes={attributes}
                    errorText={
                        updateEventInformationError &&
                        ErrorMessage.getMessage(
                            updateEventInformationError,
                            MessageTypes.createContent
                        )
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid={TEST_IDS.CANCEL_BUTTON}
                                iconName="close"
                                disabled={isUpdateEventInformationLoading}
                                onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                data-testid={TEST_IDS.SAVE_BUTTON}
                                variant="primary"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={isUpdateEventInformationLoading}
                                onClick={onUpdateEvent}>
                                {isUpdateEventInformationLoading
                                    ? 'Saving'
                                    : 'Save'}
                            </Button>
                        </SpaceBetween>
                    }
                />
            </form>
        </FormikProvider>
    );
};

export default EditEventContainer;
