import { useNotification } from '@amzn/event-engine-js-utils';
import { notificationsState } from 'atoms';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

interface UseNotificationHook {
    clearNotificationsOnUnmount?: boolean;
}

const useNotificationHook = ({
    clearNotificationsOnUnmount,
}: UseNotificationHook = {}): ReturnType<typeof useNotification> => {
    const setNotifications = useSetRecoilState(notificationsState);
    const useNotificationOutput = useNotification({
        setNotifications,
    });

    useEffect(() => {
        return () => {
            if (clearNotificationsOnUnmount) {
                useNotificationOutput.clearNotification();
            }
        };
    }, [clearNotificationsOnUnmount]);

    return useNotificationOutput;
};

export default useNotificationHook;
